import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet4'));

export const Chapter25 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      While many State officials resisted President Trump’s demands, some eagerly joined the President’s efforts.
    </Typography>

    <Typography className="indent">
      President Trump routinely coordinated with Pennsylvania State Senator Doug Mastriano, whose request led to the November 25, 2020, hotel “hearing” in Gettysburg, and who traveled to Washington to meet with the President afterward.
      <Citation number={258} /> Senator Mastriano, who would later charter and pay for buses to Washington for the President’s “Stop the Steal” rally on January 6th and was near the Capitol during the attack, quickly rose to favor with the President.
      <Citation number={259} />
    </Typography>

    <Image
      src="/images/image-042.jpg"
      alt='President Trump, shaking hands'
      credit="(Photo by Spencer Platt/Getty Images)"
    />

    <Typography className="indent">
      On November 30th, President Trump called Mastriano, interrupting him during a radio interview and telling listeners that “Doug is the absolute hero” and people are “really angry in Pennsylvania.”
      <Citation number={260} />
    </Typography>

    <Typography className="indent">
      On December 5th, Senator Mastriano sent an email to President Trump’s executive assistant, Molly Michael, with a Supreme Court Amicus Brief for the President that the pair “discussed yesterday,” related to a case brought by Representative Mike Kelly (R–PA) against his own State, which the Supreme Court rejected just a few days later.
      <Citation number={261} />
    </Typography>

    <Typography className="indent">
      On December 14th, President Trump’s executive assistant sent Mastriano an email “From POTUS” with talking points promoting a conspiracy theory about election machines.
      <Citation number={262} />
    </Typography>

    <Typography className="indent">
      And on December 21st, Mastriano sent another email for President Trump, in which he wrote: “Dear Mr. President—attached please find the ‘killer letter’ on the Pennsylvania election that we discussed last night” that “I only just completed.”
      <Citation number={263} />
      This letter recapped the Gettysburg hotel hearing on November 25th, and claimed that “there is rampant election fraud in Pennsylvania that must be investigated, remedied and rectified.”
      <Citation number={264} />
      President Trump sent that letter to John Eastman, Acting Attorney General Jeffrey Rosen, Acting Deputy Attorney General Richard Donoghue, Rush Limbaugh, former Florida Attorney General Pam Bondi, Lou Dobbs, and others.
      <Citation number={265} />
    </Typography>

    <Typography className="indent">
      As January 6th approached, Senator Mastriano’s involvement in attempts to overturn the election only grew. On December 23rd, he led a second group of Pennsylvania State senators for a meeting with President Trump in the Oval Office, which Giuliani claimed “swayed about 20” of them.
      <Citation number={266} />
      Neither Speaker Cutler nor Senate President Corman participated.
    </Typography>

    <Typography className="indent">
      Mastriano also sent emails indicating that he spoke with President Trump on December 27th, 28th, and 30th, along with files that President Trump had requested or that he had promised to him.
      <Citation number={267} />
      One of these was a pair of letters from State senators asking U.S. Senate Majority Leader Mitch McConnell and House Minority Leader Kevin McCarthy to reject Pennsylvania’s electoral votes on January 6th.
      <Citation number={268} />
      President Trump’s executive assistant notified the White House’s Director of Legislative Affairs that “[t]he President would like the below attached letters to be sent to Mitch and Kevin and all GOP house and senate members,” but was told in reply, “[g]iven the political nature of the letters, would you mind sending them?”
      <Citation number={269} />
    </Typography>

    <Typography className="indent">
      On January 5th, President Trump spoke again with Mastriano and then notified the White House operator that Mastriano “will be calling in for the Vice President” soon.
      <Citation number={270} />
      That evening Senator Mastriano sent two more emails for the President. One was a letter addressed to Vice President Pence on behalf of nearly 100 legislators from various States; the other was a letter directed to McConnell and McCarthy from Pennsylvania lawmakers, this time asking Congress to postpone acting on the 6th.
      <Citation number={271} />
      President Trump tweeted the letter that night, captioning it “BIG NEWS IN PENNSYLVANIA!” and, after midnight, he retweeted that “Pennsylvania is going to Trump. The legislators have spoken.”
      <Citation number={272} />
      As described elsewhere in this report, that letter, and letters like it, were used in the effort to convince Vice President Pence that he could and should affect the outcome of the joint session of Congress on January 6th.
    </Typography>

    <Typography className="indent">
      The Select Committee subpoenaed Senator Mastriano to testify about these interactions with President Trump and his advisors, among other matters. Unlike numerous other witnesses who complied with subpoenas and provided deposition testimony to the Select Committee, Mastriano did not; he logged in to a virtual deposition at the appointed time but logged out before answering any substantive questions or even taking the oath to the tell the truth.
      <Citation number={273} />
    </Typography>

    <Typography className="indent">
      The President apparently got what he wanted in State officials like Senator Mastriano, but not those who dared question or outright reject his anti-democratic efforts to overturn the election. In some cases, those who questioned him made the President and his advisors dig in and push harder. On January 1st, Campaign Senior Advisor Jason Miller asked for a “blast text and Twitter blast out” that would urge President Trump’s supporters to “Contact House Speaker Bryan Cutler & Senate President Pro Tem Jake Corman!” to “Demand a vote on certification.”
      <Citation number={274} />
      Senior Campaign attorneys, however, replied that this might violate Pennsylvania’s “very stringent” lobbying laws and get them prosecuted or fined.
      <Citation number={275} />
      Instead, they agreed on a similar call to action aimed at Arizona Governor Doug Ducey and Arizona House Speaker Rusty Bowers rather than Speaker Cutler and President <i>Pro Tempore</i> Corman in Pennsylvania.
      <Citation number={276} />
    </Typography>
  </Page>
)
