import { createContext, useCallback, useEffect, useState } from 'react';
import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { theme, darkTheme } from '../theme';

export const DarkModeContext = createContext([] as any);

export const AppThemeProvider = ({ children }: any) => {
  const darkModeState = useState(DarkMode.initial);
  const [darkMode, setDarkMode] = darkModeState;

  const setState = useCallback((state: any) => {
    setDarkMode(state);
    DarkMode.local = state;
  }, [setDarkMode]);

  useEffect(() => {
    // Add listener to update styles
    DarkMode.system.addEventListener('change', e => setState(e.matches));

    // Remove listener
    return () => {
      DarkMode.system.removeEventListener('change', () => {});
    }
  }, [setState]);

  return (
    <DarkModeContext.Provider value={[darkMode, setState]}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={darkMode ? darkTheme : theme}>
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </DarkModeContext.Provider>
  )
}

class DarkMode {
  static get initial(): boolean {
    const local = DarkMode.local;
    return local !== undefined ? local : DarkMode.system.matches;
  }

  static get system(): MediaQueryList {
    return window.matchMedia('(prefers-color-scheme: dark)');
  }

  static get local(): boolean {
    const local = window.localStorage.getItem('darkMode');
    return local ? JSON.parse(local) : undefined;
  }

  static set local(state: boolean) {
    window.localStorage.setItem('darkMode', JSON.stringify(state));
  }
}

