import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { Page } from '../../components/Page';

const ResponsiveDiv = styled('div')(({ theme }) => `
  display: block;
  overflow: hidden;

  img {
    margin-right: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
    max-width: 100%;
  }

  @media (min-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    display: flex;
    align-items: center;
  }
`);

export const ForewordsChairman = ({ ...args }: any) => {
  return (
    <Page {...args}>
      <Typography className="indent">
        We were told to remove our lapel pins. At the start of every new Congress, House Members are presented with lapel pins. They are about the size of a quarter and carry a seal of a bald eagle.
      </Typography>
      <Typography className="indent">
        On a routine day in the Capitol, there are thousands of tourists, advocates, and workers. Typically, the pins are an easy way to spot House Members.
      </Typography>
      <Typography className="indent">
        However, on January 6, 2021, the pin that once was a badge of honor and distinction turned into a bullseye.
      </Typography>
      <Typography className="indent">
        On that day, tear gas fogged the air as gunfire rang out, and a violent mob crashed against the sealed doors. Concerned for our safety, Capitol Police officers told us that our lapel pins would make us a target for rioters.
      </Typography>
      <Typography className="indent">
        As the Capitol Police rushed Members of Congress and staff to safety, that simple and, in context, sensible warning stuck with me. On January 6, 2021, my colleagues and I came to work with the intent of fulfilling our oaths of office and constitutional duty to carry out the peaceful transfer of power. We were the people’s representatives in the people’s House doing the people’s business. Sadly, on that day, the danger was too great for our work to continue and for us to remain in the Capitol. It was too dangerous to be identified as a representative of the American people.
      </Typography>
      <Typography className="indent">
        I’ve been a Member of the House for nearly 30 years. In that time, there’s not a day that goes by that I don’t feel a profound sense of duty and responsibility to the men and women who sent me to Congress to be their voice. After all, I’m from a part of the country where, in my lifetime, Black people were excluded entirely from political processes. Jim Crow laws prevented my father from registering to vote, and tragically during his life, he never cast a vote.
      </Typography>
      <Typography className="indent">
        For generations, the people in communities I represent have struggled to have their voices heard by their government. Therefore, I take my duties and responsibilities seriously, advocating for greater economic opportunity, robust infrastructure, better schools, and safer housing for my constituents.
      </Typography>
      <Typography className="indent">
        However, that long struggle to overcome oppression and secure basic civil and human rights continues to be my highest priority. I am always mindful of the journey that brought me to Washington as a member of Congress to be the voice of the women and men of Mississippi. As a violent mob stormed the Capitol trying to take away people’s votes, rioters carried the battle flag from a failed rebellion of confederate states. This moment resonated deeply with me because of my personal history. Additionally, I continually think about the ongoing struggle to ensure justice and equality for all Americans.
      </Typography>
      <Typography className="indent">
        The Capitol building itself is a fixture in our country’s history, of both good and bad. After all, this structure is among the most recognizable symbols of American democracy. The Capitol’s shining dome, topped with the statue of goddess Freedom, was built partially by the labor of enslaved people in the 18th and 19th centuries. Dark chapters of America’s history are written into the building’s marble, sandstone, and mortar. And yet in the halls and chambers of this building, leaders of courage passed amendments to our Constitution and enacted the laws that banned slavery, guaranteed equal rights under the law, expanded the vote, promoted equality, and moved our country, and her people, forward. The Capitol Building itself is a symbol of our journey toward a more perfect union. It is a temple to our democracy.
      </Typography>
      <Typography className="indent">
        Those great moments in our history have come when men and women put loyalty to our country and Constitution ahead of politics and party. They did the right thing. The work of the Select Committee certainly originates from the same tradition. Our bipartisan membership has moved politics to the side and focused on the facts, circumstances, and causes of January 6th.
      </Typography>
      <Typography className="indent">
        When I think back to January 6th, after nearly a year and a half of investigation, I am frightened about the peril our democracy faced. Specifically, I think about what that mob was there to do: to block the peaceful transfer of power from one president to another based on a lie that the election was rigged and tainted with widespread fraud.
      </Typography>
      <Typography className="indent">
        I also think about why the rioters were there, besieging the legislative branch of our government. The rioters were inside the halls of Congress because the head of the executive branch of our government, the thenPresident of the United States, told them to attack. Donald Trump summoned that mob to Washington, DC. Afterward, he sent them to the Capitol to try to prevent my colleagues and me from doing our Constitutional duty to certify the election. They put our very democracy to the test.
      </Typography>
      <Typography className="indent">
        Trump’s mob came dangerously close to succeeding. Courageous law enforcement officers put their lives on the line for hours while Trump sat in the White House, refusing to tell the rioters to go home, while watching the assault on our republic unfold live on television.
      </Typography>
      <Typography className="indent">
        When it was clear the insurrection would fail, Trump finally called off the mob, telling them, “We love you.” Afterward, Congress was able to return to this Capitol Building and finish the job of counting the Electoral College votes and certifying the election.
      </Typography>
      <Typography className="indent">
        This is the key conclusion of the Select Committee, all nine of us, Republicans and Democrats alike.
      </Typography>
      <Typography className="indent">
        But who knows what would have happened if Trump’s mob had succeeded in stopping us from doing our job? Who knows what sort of constitutional grey zone our country would have slid into? Who would have been left to correct that wrong?
      </Typography>
      <Typography className="indent">
        As required by House Resolution 503, which established the Select Committee, we’ve explored in great detail the facts, circumstances, and causes of the attack. This report will provide new details that supplement those findings the committee already presented during our hearings.
      </Typography>
      <Typography className="indent">
        But there are some questions for which there are still no clear answers, even if all the facts, circumstances, and causes are brought to bear. The “What If?” questions. For the good of American democracy, those questions must never again be put to the test. So, while it’s important that this report lays out what happened, it’s just as important to focus on how to make sure that January 6th was a one-time event—to identify the ongoing threats that could lead us down that dangerous path again—with hopes and humble prayers that the committee’s work is carried on through corrective action.
      </Typography>
      <Typography className="indent">
        This report will provide greater detail about the multistep effort devised and driven by Donald Trump to overturn the 2020 election and block the transfer of power. Building on the information presented in our hearings earlier this year, we will present new findings about Trump’s pressure campaign on officials from the local level all the way up to his Vice President, orchestrated and designed solely to throw out the will of the voters and keep him in office past the end of his elected term.
      </Typography>
      <Typography className="indent">
        As we’ve shown previously, this plan faltered at several points because of the courage of officials (nearly all of them Republicans) who refused to go along with it. Donald Trump appeared to believe that anyone who shared his partisan affiliation would also share the same callous disregard for his or her oath to uphold the rule of law. Fortunately, he was wrong.
      </Typography>
      <Typography className="indent">
        The failure of Trump’s plan was not assured. To the contrary, Trump’s plan was successful at several turns. When his scheme to stay in power through political pressure hit roadblocks, he relentlessly pushed ahead with a parallel plan: summoning a mob to gather in Washington, DC on January 6th, promising things “will be wild!”
      </Typography>
      <Typography className="indent">
        That mob showed up. They were armed. They were angry. They believed the “Big Lie” that the election had been stolen. And when Donald Trump pointed them toward the Capitol and told them to “fight like hell,” that’s exactly what they did.
      </Typography>
      <Typography className="indent">
        Donald Trump lit that fire. But in the weeks beforehand, the kindling he ultimately ignited was amassed in plain sight.
      </Typography>
      <Typography className="indent">
        That’s why as part of the Select Committee’s investigation, we took a hard look at whether enough was done to mitigate that risk. Our investigative teams focused on the way intelligence was gathered, shared, and assessed. We probed preparations by law enforcement agencies and security responses on the day of the attack. We followed the money, to determine who paid for a number of events in the run-up to the attack and to gain a clearer understanding of the way the former President’s campaign apparatus cashed in on the big lie. And we pulled back the curtain at certain major social media companies to determine if their policies and protocols were up to the challenge when the President spread a message of violence and his supporters began to plan and coordinate their descent on Washington.
      </Typography>
      <Typography className="indent">
        The Select Committee’s conclusion on these matters—particularly dealing with intelligence and law enforcement—is consistent with our broader findings about the causes of January 6th. Were agencies perfect in their preparations for January 6th and their responses as the violence unfolded? Of course not. Relevant oversight committees and watchdogs should continue to find efficiencies and improvements, some of which are laid out in Committee’s recommendations.
      </Typography>
      <Typography className="indent">
        But the shortfall of communications, intelligence and law enforcement around January 6th was much less about what they did or did not know. It was more about what they could not know. The President of the United States inciting a mob to march on the Capitol and impede the work of Congress is not a scenario our intelligence and law enforcement communities envisioned for this country. Prior to January 6th, it was unimaginable. Whatever weaknesses existed in the policies, procedures, or institutions, they were not to blame for what happened on that day.
      </Typography>
      <Typography className="indent">
        And so, when I think about the ongoing threats—when I think about how to avoid having to confront those “What-Ifs?” in the future—my concerns are less with the mechanics of intelligence gathering and security posture, as important as those questions are. My concerns remain first and foremost with those who continue to seek power at the expense of American democracy.
      </Typography>
      <Typography className="indent">
        What if those election officials had given in to Donald Trump’s pressure? What if the Justice Department had gone along with Trump’s scheme to declare the 2020 election fraudulent? What if the Vice President had tried to throw out electoral votes? What if the rioters bent on stopping the peaceful transfer of power hadn’t been repelled?
      </Typography>
      <Typography className="indent">
        To cast a vote in the United States of America is an act of both hope and faith. When you drop that ballot in the ballot box, you do so with the confidence that every person named on that ballot will hold up their end of the bargain. The person who wins must swear an oath and live up to it. The people who come up short must accept the ultimate results and abide by the will of the voters and the rule of law. This faith in our institutions and laws is what upholds our democracy.
      </Typography>
      <Typography className="indent">
        If that faith is broken—if those who seek power accept only the results of elections that they win—then American democracy, only a few centuries old, comes tumbling down.
      </Typography>
      <Typography className="indent">
        That’s the danger.
      </Typography>
      <Typography className="indent">
        What’s the solution?
      </Typography>
      <Typography className="indent">
        The Committee believes a good starting point is the set of recommendations we set forth in our report, pursuant to House Resolution 503. Driven by our investigative findings, these recommendations will help strengthen the guardrails of our democracy.
      </Typography>
      <Typography className="indent">
        Beyond what we recommend, in my view and as I said during our hearings, the best way to prevent another January 6th is to ensure accountability for January 6th. Accountability at all levels.
      </Typography>
      <Typography className="indent">
        I have confidence in our Department of Justice and institutions at the state and local level to ensure accountability under the law. As this report is released, we see those processes moving forward.
      </Typography>
      <Typography className="indent">
        But preventing another January 6th will require a broader sort of accountability. Ultimately, the American people chart the course for our country’s future. The American people decide whom to give the reins of power. If this Select Committee has accomplished one thing, I hope it has shed light on how dangerous it would be to empower anyone whose desire for authority comes before their commitment to American democracy and the Constitution.
      </Typography>
      <Typography className="indent">
        I believe most Americans will turn their backs on those enemies of democracy.
      </Typography>
      <Typography className="indent">
        But some will rally to the side of the election deniers, and when I think about who some of those people are, it troubles me deep inside. White supremacists. Violent extremists. Groups that subscribe to racism, antiSemitism, and violent conspiracy theories; those who would march through the halls of the Capitol waving the Confederate battle flag.
      </Typography>
      <Typography className="indent">
        These are people who want to take America backward, not toward some imagined prior greatness, but toward repression. These are people who want to roll back what we’ve accomplished. I believe that those who aligned with the scheme to overturn the election heeded Donald Trump’s call to march on the Capitol because they thought taking up Donald Trump’s cause was a way to advance their vile ambitions.
      </Typography>
      <Typography className="indent">
        That is why I did not remove my lapel pin on January 6th.
      </Typography>
      <Typography className="indent">
        Our country has come too far to allow a defeated President to turn himself into a successful tyrant by upending our democratic institutions, fomenting violence, and, as I saw it, opening the door to those in our country whose hatred and bigotry threaten equality and justice for all Americans.
      </Typography>
      <Typography className="indent">
        We can never surrender to democracy’s enemies. We can never allow America to be defined by forces of division and hatred. We can never go backward in the progress we have made through the sacrifice and dedication of true patriots. We can never and will never relent in our pursuit of a more perfect union, with liberty and justice for all Americans.
      </Typography>
      <Typography className="indent">
        I pray that God continues to bless the United States of America.
      </Typography>

      <br />

      <ResponsiveDiv>
        <img src="/images/image-005.jpg" alt="Chairman Bennie G. Thompson" />

        <div>
          <img src="/images/image-004.jpg" alt="Chairman Bennie G. Thompson, signature" />
          <Typography>
            <strong>BENNIE G. THOMPSON</strong>
          </Typography>
          <Typography>
            Chairman
          </Typography>
        </div>
      </ResponsiveDiv>
    </Page>
  )
}
