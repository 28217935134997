import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet6'));

export const Chapter410 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      The next day, Rosen, Donoghue, and Engel had a meeting with Mark Meadows, Pat Cipollone, and Cipollone’s deputy, Pat Philbin, in the White House Chief of Staff’s office.
      <Citation number={222} />
      While the meeting dealt primarily with the Presidential transition, the group discussed a draft civil complaint modeled after <i>Texas v. Pennsylvania</i> that the President wanted the Department of Justice to file challenging the results of the Presidential election, tentatively called <i>United States v. Pennsylvania</i>.
      <Citation number={223} />
      The DOJ officials said that they had not had time to thoroughly review the proposed suit, but initially indicated that it appeared to be flawed and did not seem “viable” for DOJ to file.
      <Citation number={224} />
      Meadows suggested that the DOJ leadership meet with William Olson and Kurt Olsen, the two attorneys affiliated with the Trump Campaign that had been working on the proposed suit, and added that Eastman and a retired judge from North Carolina named Mark Martin both had views about the lawsuit.
      <Citation number={225} />
    </Typography>

    <Typography className="indent">
      In this meeting, Meadows also raised a new and outrageous allegation of election fraud: that an Italian company had been involved in changing votes in the Presidential election.
      <Citation number={226} />
      According to Meadows, there was a man, whom Donoghue later learned was in an Italian prison, who claimed to have information supporting the allegation and that CIA officers stationed in Rome were either aware of the plot to interfere in the election or had participated in it.
      <Citation number={227} />
      Donoghue described how it was apparent that Meadows was not clear on the specifics of the allegation but passed them along to DOJ to investigate, nonetheless.
      <Citation number={228} />
      Following the meeting Donoghue provided the information to the FBI, which quickly determined that the allegations were not credible.
      <Citation number={229} />
      Meadows and other senior officials in the Trump administration, however, pressed DOJ to investigate every allegation of fraud regardless of how absurd or specious.
    </Typography>

    <Typography className="indent">
      In the days after the December 29th meeting with Meadows, the senior DOJ officials more closely examined the proposed <i>United States v. Pennsylvania</i> lawsuit and determined that DOJ could not file it.
      <Citation number={230} />
      Engel was principally tasked with examining the veracity of the suit and summarized his analysis in a series of talking points that he provided to Donoghue on December 31st.
      <Citation number={231} />
      Engel concluded that for multiple reasons, the proposed lawsuit lacked merit. First, the U.S. Government did not have standing to challenge how a State administered its election.
      <Citation number={232} />
      Such a challenge could only be brought by President Trump as a candidate and his campaign, or, possibly, an aggrieved electoral college elector.
      <Citation number={233} />
      Second, there was no identified precedent in the history of the Supreme Court establishing that such a lawsuit could be filed by the U.S. Government.
      <Citation number={234} />
      Third, by late December, States had already certified the results of their elections and the electoral college had met, so suing States by this point would not impact the results of the election.
      <Citation number={235} />
      Finally, unlike <i>Texas v. Pennsylvania</i>, which was one State suing another State, this lawsuit would not automatically be heard by the Supreme Court, so it should have been filed in a Federal district court months prior—if at all—to have any possibility of impacting the outcome of the election.
      <Citation number={236} />
    </Typography>

    <Typography className="indent">
      When asked about it during his interview with the Select Committee, Engel described <i>United States v. Pennsylvania</i> as “a meritless lawsuit” and said, “there was never a question” about whether “the Department was going to file” it.
      <Citation number={237} />
      As senior DOJ officials had already explained to President Trump multiple times in November and December 2020, the Department of Justice was strictly limited in what election-related actions it could take. It could not oversee States’ actions in administering their elections, and it could not support litigation filed by President Trump’s campaign.
      <Citation number={238} />
      Nonetheless, President Trump continued to push DOJ to file this lawsuit over the following days and essentially act as an arm of his political campaign.
    </Typography>

    <Image
      src="/images/image-060.jpg"
      alt="Steven Engel testifies before the Select Committee on June 23, 2022."
      caption="Steven Engel testifies before the Select Committee on June 23, 2022."
      credit="(Photo by House Creative Services)"
    />
  </Page>
)
