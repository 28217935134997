import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet3'));

export const Chapter16 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      President Trump’s campaign team quickly realized that none of the significant fraud claims were real. Bill Stepien testified that, as of November 5th, the Trump Campaign had not found any proof of fraudulent activity. There were “allegations and reports,” but “nothing hard [and] fast” that drew the results of the election into question.
      <Citation number={57} />
    </Typography>

    <Typography className="indent">
      The Trump Campaign continued to investigate claims of fraud into the second week after the election. According to Stepien, as people shared “wild allegations” with the President, the campaign team was forced to review the facts and then serve as a “truth telling squad” to the President regarding why the claims “didn’t prove to be true.”
      <Citation number={58} />
      For example, Stepien recalled someone alleging that thousands of illegal votes had been cast in Arizona. That wasn’t true. The votes had been submitted by overseas voters (such as military deployed or stationed abroad) who were obviously eligible to participate in the election.
      <Citation number={59} />
    </Typography>

    <Typography className="indent">
      Alex Cannon was a lawyer for the Trump Campaign and previously worked for the Trump Organization. After the election, Cannon was tasked with looking into allegations of voter fraud in the 2020 election—including the claim that thousands of ineligible votes had been cast in Arizona.
      <Citation number={60} />
      Cannon recalled that Vice President Pence asked him what he was finding. “And I said that I didn't believe we were finding it, or I was not personally finding anything sufficient to alter the results of the election,” Cannon responded. Vice President Pence thanked him.
      <Citation number={61} />
    </Typography>

    <Typography className="indent">
      Cannon reported his assessment to Mark Meadows, the White House Chief of Staff, as well. In mid to late-November 2020, Meadows asked Cannon what his investigation had turned up. “And I remember sharing with him that we weren't finding anything that would be sufficient to change the results in any of the key states,” Cannon told Meadows. “So there is no there, there?” Meadows replied.
      <Citation number={62} />
    </Typography>

    <Typography className="indent">
      Jason Miller, a senior advisor to the Trump Campaign, pushed claims of election fraud in public. In private, however, Miller says that he told President Trump a different story, informing him numerous times that there was not enough election fraud to have changed the election:
    </Typography>

    <Blockquote>
      <Typography>
       <strong>Miller</strong>: My understanding is that I think there are still very valid questions and concerns with the rules that were changed under the guise of COVID, but, specific to election day fraud and irregularities, there were not enough to overturn the election.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: And did you give your opinion on that to the President?
      </Typography>
      <Typography>
        <strong>Miller</strong>: Yes.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: What was his reaction when you told him that?
      </Typography>
      <Typography>
        <strong>Miller</strong>: “You haven't seen or heard”—I’m paraphrasing, but—“you haven't seen or heard all the different concerns and questions that have been raised.”
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: How many times did you have this conversation with the President?
      </Typography>
      <Typography>
        <strong>Miller</strong>: I couldn't put a specific number on it, though.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: But more than one?
      </Typography>
      <Typography>
        <strong>Miller</strong>: Correct.
        <Citation number={63} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Matthew Morgan, the Trump Campaign’s top lawyer, came to a similar conclusion. Nearly two months after the election, on January 2nd, Morgan met with the Vice President’s staff. According to Morgan, the consensus in the room was that even if all the claims of fraud and irregularities were “aggregated and read most favorably to the campaign... it was not sufficient to be outcome determinative.”
      <Citation number={64} />
    </Typography>

    <Typography className="indent">
      As far as the Trump Campaign’s professional leadership was concerned, there was no evidence that the election had been “stolen” from President Trump. To the contrary, they had seen ample evidence that President Trump simply lost—and told the President so.
    </Typography>

    <Typography className="indent">
      On November 6th, Jared Kushner arranged for the senior campaign staff to brief President Trump in the Oval Office on the state of the race.
      <Citation number={65} />
      Since election day, Matt Oczkowski, the Campaign’s leading data expert, had tracked voting returns in the swing States to analyze the campaign’s odds of success.
      <Citation number={66} />
      Miller texted such updates on data from key States to Meadows.
      <Citation number={67} />
      The Trump Campaign’s data did not add up to victory. Oczkowski “delivered to the President in pretty blunt terms that he was going to lose” the election.
      <Citation number={68} />
      There were not enough outstanding votes in the battleground States for President Trump to overcome Biden’s lead. President Trump disagreed and insisted that he would still prevail through legal challenges.
      <Citation number={69} />
    </Typography>

    <Image
      src="/images/image-029.jpg"
      alt="Joe Biden, on screen"
      credit="(Photo by Alex Wong/Getty Images)"
    />

    <Typography className="indent">
      But the data did not lie.
    </Typography>

    <Typography className="indent">
      On November 7th, the Associated Press called Pennsylvania and the overall presidential election for former Vice President Biden.
      <Citation number={70} />
      At that point, a small team of the President’s campaign advisors including Stepien met with the President and told him that his path to victory was virtually non-existent.
      <Citation number={71} />
      The campaign team conveyed to the President that his chance of success was only “5, maybe 10 percent,” which Stepien explained to the Committee was a “very, very, very bleak” assessment.
      <Citation number={72} />
    </Typography>

    <Typography className="indent">
      In retrospect, the campaign’s estimate of a 5 to 10 percent chance of winning, as of November 7th, was far too optimistic. In one of the most favorable possible scenarios, for example, President Trump and his team would need to win recounts in Arizona and Georgia, while also prevailing in litigation challenging absentee or vote by mail ballots in Wisconsin, or possibly Michigan or Pennsylvania.
      <Citation number={73} />
      But the election wasn’t even close enough to trigger automatic recounts in Arizona or Georgia.
    </Typography>

    <Typography className="indent">
      The narrowest margin of total votes between the two candidates was in Arizona, where former Vice President Biden won by more than 10,000 votes. This may seem like a small number of votes, but it was more than enough to avoid an automatic recount. As Benjamin Ginsberg, a longtime Republican elections lawyer, explained to the Select Committee, “the 2020 election was not close.”
      <Citation number={74} />
      Previous campaigns had successfully challenged vote differentials in the hundreds—not thousands—of votes.
      <Citation number={75} />
      Ginsberg explained, “you just don't make up those sorts of numbers in recounts.”
      <Citation number={76} />
      Georgia performed a hand recount of all the ballots anyway, confirming within weeks of the election that Biden had won the State.
      <Citation number={77} />
      Also, by January 6th, Arizona and New Mexico had conducted statutory post-election audits of voting machines or randomly-selected, representative samples of ballots at the Stateor county-level that affirmed the accuracy of their election results.
      <Citation number={78} />
    </Typography>

    <Typography className="indent">
      Chris Stirewalt, who led the elections desk at Fox News at the time, concurred with Ginsberg’s analysis. Asked what President Trump’s odds of victory were as of November 7th, Stirewalt replied: “None.”
      <Citation number={79} />
    </Typography>

    <Typography className="indent">
      Meanwhile, the Trump Campaign continued to crunch the numbers. On the morning of November 12th, Oczkowski circulated among top campaign advisors a presentation describing what happened in each of the battleground States the campaign was monitoring.
      <Citation number={80} />
      This analysis by the data team examined the turnout and margins on a county-by-county basis in a dozen States while also analyzing demographic changes that impacted the results.
      <Citation number={81} />
      Among the States were Arizona, Georgia, Michigan, Pennsylvania, Nevada, and Wisconsin.
      <Citation number={82} />
      Oczkowski’s team determined that President Trump lost each of those six States because Biden had performed better than President Trump in certain areas like population centers or suburbs.
      <Citation number={83} />
      Yet, in the weeks that followed, President Trump and his new legal team— the “clown car”—went to great lengths to challenge the results of the election in these six states, spreading multiple conspiracy theories.
    </Typography>

    <Typography className="indent">
      The voting data told a clear story: President Trump lost. But, regardless of the facts, the President had no intention of conceding defeat.
    </Typography>

    <Typography className="indent">
      On election night, President Trump and Rudy Giuliani agreed that the President should just declare victory—even though he had no basis for doing so. Giuliani also told the Select Committee that President Trump asked him on November 4th to take over his campaign’s legal operation.
      <Citation number={84} />
      Giuliani thought the only way that it would work would be for the President to call the existing campaign team to announce Giuliani’s takeover because, in Giuliani’s words, “they are going to be extraordinarily resentful, because they don’t like me already, and I don’t trust them.”
      <Citation number={85} />
      He said that the President agreed.
      <Citation number={86} />
    </Typography>

    <Typography className="indent">
      Although Giuliani wouldn’t assume leadership of the Campaign’s legal operations until mid-November, the former New York City mayor quickly began to butt heads with “Team Normal.”
    </Typography>

    <Typography className="indent">
      On November 6th, Giuliani and his team met with the Trump Campaign’s leadership at its headquarters in Arlington, Virginia.
      <Citation number={87} />
    </Typography>

    <Typography className="indent">
      “Team Normal” was not impressed. Stepien told the Select Committee the campaign team was concerned that Giuliani would be a distraction to them and to President Trump.
      <Citation number={88} />
      When Giuliani suggested traveling to Pennsylvania to assist in the campaign’s efforts, the campaign team “didn’t dissuade him from doing so.”
      <Citation number={89} />
      After just 10 to 15 minutes in the conference room, Stepien and other staffers left the meeting.
      <Citation number={90} />
    </Typography>

    <Typography className="indent">
      That same day, President Trump discussed the Campaign’s legal strategy in the Oval Office with Giuliani, Clark, and Matt Morgan, the Trump Campaign’s General Counsel.
      <Citation number={91} />
      Prior to the election, Morgan was responsible for the Campaign’s litigation strategy.
      <Citation number={92} />
      Morgan and his team filed lawsuits challenging the changes States made to voting practices during the coronavirus pandemic.
      <Citation number={93} />
      Morgan also studied previous elections to determine the types of cases that were likely to succeed.
      <Citation number={94} />
      Clark described how the Campaign’s original legal strategy was based on his general theory for election cases: “to look at what do you think, what do you know, and what can you prove” and then determine which cases to file from there.
      <Citation number={95} />
    </Typography>

    <Typography className="indent">
      Giuliani had other ideas and advocated to President Trump that he be put in charge of the Campaign’s legal operation so that he could pursue his preferred strategy.
      <Citation number={96} />
      “Mr. Giuliani didn’t seem bound by those cases or by those precedents. He felt he could press forward on anything that he thought was wrong with the election and bring a strategy around that,” Morgan explained.
      <Citation number={97} />
      “Rudy was just chasing ghosts,” Clark said.
      <Citation number={98} />
      Morgan and Clark excused themselves from the meeting because it “was going nowhere.”
      <Citation number={99} />
    </Typography>

    <Typography className="indent">
      The next day, November 7th, Giuliani held a press conference at Four Seasons Total Landscaping in Philadelphia, Pennsylvania. He immediately began making outlandish claims, arguing that the Democrats had conspired to steal the election. “As you know from the very beginning, mail-in ballots were a source of some degree of skepticism, if not a lot of skepticism, as being innately prone to fraud,” Giuliani said. “Those mail-in ballots could have been written the day before by the Democratic Party hacks that were all over the Convention Center.”
      <Citation number={100} />
      Giuliani offered no evidence to support his shocking and baseless allegation. Echoes of President Trump’s relentless campaign against mail-in balloting, and his decision to exploit the Red Mirage, were easy to hear.
    </Typography>

    <Image
      src="/images/image-030.jpg"
      alt="Rudy Giuliani, speaking"
      credit="(Photo by Chris McGrath/Getty Images)"
    />

    <Typography className="indent">
      On November 10th, Giuliani and Kerik met with President Trump in the Oval Office to discuss their investigation into voter fraud. White House Counsel Pat Cipollone and White House Senior Advisor Eric Herschmann were also in attendance. After Giuliani’s presentation, President Trump asked Cipollone whether he had spoken to Attorney General Barr about the allegations of fraud.
      <Citation number={101} />
      One day before, Barr had issued a memorandum outlining a shift in DOJ policy that allowed Federal prosecutors to investigate claims of voting irregularities without waiting for the results to be certified.
      <Citation number={102} />
      President Trump’s question was an early indication that he was going to pressure the DOJ to endorse his phony fraud claims.
    </Typography>

    <Typography className="indent">
      Days later, Giuliani and Justin Clark engaged in a screaming match during a meeting in the Oval Office.
      <Citation number={103} />
      Giuliani was urging President Trump to file a lawsuit in Georgia, but Clark pointed out that a hand recount was already being conducted and argued it was better to wait.
      <Citation number={104} />
      Giuliani told President Trump that Clark was lying to him.
      <Citation number={105} />
      A formal changing of the guard would follow.
    </Typography>

    <Typography className="indent">
      On November 14th, President Trump announced on Twitter that Giuliani was now the head of his campaign’s legal team.
      <Citation number={106} />
      “Team Normal” saw drastic changes to their roles on the newly-structured campaign team—some self-imposed—and many outside law firms that had signed up to support the campaign’s legal efforts disengaged completely.
      <Citation number={107} />
    </Typography>

    <Typography className="indent">
      “I didn’t think what was happening was necessarily honest or professional at that point in time,” Stepien explained. “This wasn’t a fight that I was comfortable with,” he added.
      <Citation number={108} />
    </Typography>

    <Typography className="indent">
      On the day the leadership change was announced, Giuliani participated in a “surrogate” briefing to coordinate messaging by Trump loyalists during their media appearances.
      <Citation number={109} />
      Giuliani announced that the messaging strategy should be “to go hard on Dominion/Smartmatic, bringing up Chavez and Maduro.”
      <Citation number={110} />
      Giuliani claimed that additional lawsuits would soon be filed “to invalidate upwards of 1M ballots.”
      <Citation number={111} />
    </Typography>

    <Typography className="indent">
      Consistent with the messaging advanced by the new campaign team, President Trump in mid-November remained dug-in, still refusing to concede defeat. President Trump continued to insist that he was cheated out of victory, endorsing one wild conspiracy theory after another to deny the simple fact that he lost.
    </Typography>
  </Page>
)