import { createTheme } from '@mui/material/styles';

import { components } from './_components';
import { palette } from './_palette';
import { typography } from './_typography';

const themeProps = {
  components,
  palette,
  typography,

  spacing: 8,

  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
};

let _theme = createTheme(themeProps);

let _darkTheme = createTheme({
  ...themeProps,

  palette: {
    ...themeProps.palette,

    mode: 'dark',



  }
});

const makeResponsiveTheme = (theme: any) => ({
  ...theme,

  typography: {
    ...theme.typography,

    h1: {
      ...theme.typography.h1,

      margin: `${theme.spacing(.5)} 0 ${theme.spacing(1)}`,

      [theme.breakpoints.down('sm')]: {
        fontSize: '1em',
      },
    },
    h2: {
      ...theme.typography.h2,

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.3em',
      },
    },
    h3: {
      ...theme.typography.h3,

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2em',
      },
    },
    h4: {
      ...theme.typography.h4,

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.1em',
      },
    },
    overline: {
      ...theme.typography.overline,

      marginTop: theme.spacing(1),
    }
  }
});

export const theme = makeResponsiveTheme(_theme);
export const darkTheme = makeResponsiveTheme(_darkTheme);