import { lazy } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet7'));

export const Chapter51 = ({ ...args }: any) => (
  <Page {...args}>
    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        THE INTELLECTUAL FRAMEWORK FOR THE THEORY THAT THE VICE PRESIDENT COULD CHANGE THE OUTCOME OF THE ELECTION AT THE JOINT SESSION EMERGED FROM DISCUSSIONS AMONG THE LAWYERS WORKING WITH THE TRUMP CAMPAIGN AFTER THE 2020 ELECTION.
      </Typography>
    </Box>

    <Typography className="indent">
      When the electoral college met to cast votes for the certified winner in each State on December 14, 2020, any possibility of President Trump reversing his defeat came to an end. The contest was decided well before then, but December 14th marked what should have been the formal end of the Trump campaign. Former Vice President Biden had won the election and his victory was cemented by the States’ electoral votes. Instead of bowing to this reality, some pro-Trump lawyers had already begun scheming ways to deny the inevitable. Over the course of the post-election period, as their other plans each failed, the importance of January 6th and the need to pressure Vice President Pence increased. These same lawyers concluded that the Vice President could help President Trump subvert the election on January 6th, but they would need Vice President Pence to set aside history and the law to do so. They’d need him to violate the Electoral Count Act of 1887 (“the ECA”). The ECA had governed the joint session for 130 years, but it was an inconvenient barrier for President Trump’s plan to stay in office.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        KENNETH CHESEBRO ARTICULATED A “PRESIDENT OF THE SENATE” STRATEGY IN EARLY DECEMBER, WHEN THE TRUMP CAMPAIGN WAS CONVENING “ALTERNATE” ELECTORS IN KEY STATES PRESIDENT TRUMP LOST.
      </Typography>
    </Box>

    <Typography className="indent">
      On December 13, 2020, Kenneth Chesebro, a pro-Trump lawyer, sent a memo to Rudolph Giuliani, the President’s lead outside counsel, upon request from Trump Campaign official Boris Epshteyn.
      <Citation number={21} />
      Chesebro laid out a “‘President of the Senate’ strategy,” arguing that the “President of the Senate” (“he, and he alone”) is charged with “making judgments about what to do if there are conflicting votes.”
      <Citation number={22} />
      Chesebro argued that when the joint session met on January 6th, the President of the Senate should not count Arizona’s electoral college votes for former Vice President Biden, “[b]ecause there are two slates of votes.”
      <Citation number={23} />
      Of course,there were not two legitimate “slates of votes” from Arizona. There were the official electors, certified by the State, and a group of fake electors convened by the Trump campaign.
    </Typography>

    <Typography className="indent">
      Chesebro’s memo set President Trump’s pressure campaign on a course to target the Vice President on January 6.
      <Citation number={24} />
      Judge Carter found that the “draft memo pushed a strategy that knowingly violated the Electoral Count Act” and “is both intimately related to and clearly advanced the plan to obstruct the Joint Session of Congresson January 6, 2021.”
      <Citation number={25} />
      That plan was also advanced by John Eastman.
      <Citation number={26} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        ON DECEMBER 23, JOHN EASTMAN DRAFTED THE FIRST OF HIS TWO “JANUARY 6TH SCENARIO” MEMOS, ARTICULATING THE ARGUMENT THAT UNDER THE CONSTITUTION THE VICE PRESIDENT IS THE “ULTIMATE ARBITER.”
      </Typography>
    </Box>

    <Typography className="indent">
      On December 23, 2020, Eastman wrote a two-page memo summarizing ways to ensure that “President Trump is re-elected.”
      <Citation number={27} />
      Eastman suggested that Vice President Pence could refuse to count the electoral college votes from seven States: Arizona, Georgia, Michigan, Nevada, New Mexico, Pennsylvania, and Wisconsin. According to Eastman, Vice President Pence could simply reject these States’ electoral college votes. At that point, President Trump would have 232 electoral college votes compared to former Vice President Biden’s 222. This was sufficient, in Eastman’s view, to guarantee President Trump’s victory, because he would have a majority of the electoral college votes. “Pence then gavels President Trump as re-elected,” Eastman wrote.
    </Typography>

    <Typography className="indent">
      Eastman considered the possibility that Democrats in Congress would object, stating the plain truth that 270 electoral college votes are necessary to win. In that event, according to Eastman, the election could be sent to the House of Representatives.
      <Citation number={28} />
      The Republican-majority of delegations in the House would then re-elect Trump as president. Eastman concluded: “The main thing here is that Pence should do this without asking for permission—either from a vote of the joint session or from the Court.... The fact is that the Constitution assigns this power to the Vice President as the ultimate arbiter. We should take all of our actions with that in mind.”
      <Citation number={29} />
    </Typography>

    <Typography className="indent">
      From the start, President Trump was looped in on Eastman’s proposal. The same day Eastman started preparing the memo, he sent an email to President Trump’s assistant Molly Michael, at 1:32 p.m.: “Is the President available for a very quick call today at some point? Just want to update him on our overall strategic thinking.”
      <Citation number={30} />
      Only five minutes later, Eastman received a call from the White House switchboard; according to his phone records, the conversation lasted for almost 23 minutes.
      <Citation number={31} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        EASTMAN CHANGED HIS EVALUATION OF THE 12TH AMENDMENT, AND THE ROLE OF THE VICE PRESIDENT, AFTER PRESIDENT TRUMP LOST THE ELECTION.
      </Typography>
    </Box>

    <Typography className="indent">
      In Eastman’s theory, which was the foundation of President Trump’s January 6th plot, the Vice President of the United States is the “ultimate arbiter” and could unilaterally decide the victor of the 2020 Presidential election.
      <Citation number={32} />
      However, just before the 2020 presidential election, Eastman had acknowledged in writing that the Vice President had no such expansive power.
    </Typography>

    <Typography className="indent">
      In the course of a lengthy exchange of ideas and emails throughout the preand post-election period with an individual named Bruce Colbert, Eastman provided comments on a letter Colbert was drafting to President Trump.
      <Citation number={33} />
      The draft letter purported to provide recommendations of “crucial legal actions” for the Trump Campaign to take “to help secure your election victory as President of the United States.”
      <Citation number={34} />
      One of the draft letter’s recommendations was that “the President of the Senate decides authoritatively what ‘certificates’ from the states to ‘open.’” In response, Eastman wrote on October 17, 2020, “I don’t agree with this” and continued, “[t]he 12th Amendment only says that the President of the Senate opens the ballots in the joint session and then, in the passive voice, that the votes shall then be counted. 3 USC § 12 says merely that he is the presiding officer, and then it spells out specific procedures, presumptions, and default rules for which slates will be counted. <i>Nowhere does it suggest that the President of the Senate gets to make the determination on his own</i>. § 15 doesn’t, either.”
      <Citation number={35} />
    </Typography>

    <Typography className="indent">
      By the first week of December, Eastman’s correspondence with this same individual illustrates that he was open to advocating for the very point he had rejected before the election—that is, that “the 12th Amendment confers dispositive authority on the President of the Senate to decide which slate to count.”
      <Citation number={36} />
      And on December 5, 2020, Eastman wrote to Colbert, “I have spoken directly with folks at the top of the chain of command on this. They are now aware of the issues.”
      <Citation number={37} />
    </Typography>

    <Typography className="indent">
      The emails also signaled another idea that Eastman would continue to repeat in the coming weeks: that the Vice President could act without getting permission from a court. Specifically, he argued that they could take the position that the Vice President’s authority was a “non-justiciable political question”—in other words, that Vice President Pence could just act, and no court would have jurisdiction to rule on the issue.
      <Citation number={38} />
      As Eastman’s emails later in the month make clear, he thought there was an important reason to keep this issue out of the courts—they would rule that the theory was unlawful.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        EASTMAN’S “JANUARY 6 SCENARIO” CLEARLY REQUIRED THE VICE PRESIDENT TO VIOLATE THE ELECTORAL COUNT ACT, THE FEDERAL LAW GOVERNING THE CERTIFICATION OF PRESIDENTIAL ELECTIONS.
      </Typography>
    </Box>

    <Typography className="indent">
      There are other parts of Eastman’s two-page December 23rd memo worthy of attention. Eastman wrote that Vice President Pence could recuse himself from presiding over the joint session of Congress on January 6th. In that event, the session would be overseen by the Senate President Pro Tempore, Senator Charles Grassley. Eastman was clearly arguing that Vice President Pence (or Senator Grassley) should violate the Electoral Count Act. “When he gets to Arizona, he announces that he has multiple slates of electors, and so is going to defer decision on that until finishing the other States,” Eastman wrote.
      <Citation number={39} />
      “This would be the first break with the procedure set out in the Act.”
      <Citation number={40} />
      This “break” with “procedure” that Eastman’s memo was openly advocating for was in other words the Vice President breaking the law. When Chesebro read Eastman’s memo, he commented favorably, declaring it “[r]eally awesome.”
      <Citation number={41} />
    </Typography>

    <Typography className="indent">
      At this point, Eastman continued, Congress would likely follow the “process” set forth in the Electoral Count Act, and “the two houses [would] break into their separate chambers” for debate.
      <Citation number={42} />
      But Eastman advised “we should not allow the Electoral Count Act constraint on debate to control” and the Trump team “should demand normal rules (which includes the filibuster).”
      <Citation number={43} />
      Eastman thought this would create a “stalemate,” giving “the state legislatures more time to weigh in to formally support the alternate slate of electors, if they had not already done so.”
      <Citation number={44} />
      As discussed previously in this report, at the time he drafted this memo—and throughout the post-election period—Eastman, Giuliani, President Trump and others were simultaneously working to replace certified electors for former Vice President Biden in certain States. Eastman, Giuliani, and President Trump all pressured State legislators to name their own separate electors or to certify the campaign’s fake electors.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        EASTMAN’S “JANUARY 6 SCENARIO” CLEARLY REQUIRED THE VICE PRESIDENT TO VIOLATE THE ELECTORAL COUNT ACT, THE FEDERAL LAW GOVERNING THE CERTIFICATION OF PRESIDENTIAL ELECTIONS.
      </Typography>
    </Box>

    <Typography className="indent">
      Eric Herschmann, an attorney working for President Trump in the White House, met with Eastman to discuss his memo. Herschmann thought Eastman’s plan was “crazy.” Herschmann summarized the conversation to the Select Committee:
    </Typography>

    <Blockquote>
      <Typography>
        And I said to him, hold on a second, I want to understand what you’re saying. You’re saying you believe the Vice President, acting as President of the Senate, can be the sole decisionmaker as to, under your theory, who becomes the next President of the United States? And he said, yes. And I said, are you out of your F’ing mind, right? And that was pretty blunt. I said, you’re completely crazy. You’re going to turn around and tell 78 plus million people in this country that your theory is, this is how you’re going to invalidate their votes because you think the election was stolen? I said, they’re not going to tolerate that. I said, you’re going to cause riots in the streets. And he said words to the effect of there’s been violence in this history of our country to protect the democracy or to protect the [R]epublic.
        <Citation number={45} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      As recounted by Herschmann, Eastman was shockingly unconcerned with the prospect of violence should Vice President Pence follow his and President Trump’s recommended course.
    </Typography>

    <Typography className="indent">
      Herschmann asked a straightforward question—if the States wanted to recertify their electors, then why weren’t they doing it themselves? “Why aren’t they already coming into session and saying, we want to change the [S]tates, and why do you need the VP to go down this path[?]”
      <Citation number={46} />
      Eastman had no response. In addition to being “crazy,” Herschmann “didn’t think there was any chance in the world ”that Eastman’s plan“ could work.”
      <Citation number={47} />
    </Typography>

    <Typography className="indent">
      Herschmann pressed Eastman further, asking if he had “any precedent at all for the VP or anyone acting in the capacity as the President of the Senate declaring some statute invalid.”
      <Citation number={48} />
      Eastman replied “no,” but argued that “these are unprecedented times.”
      <Citation number={49} />
      Herschmann was unimpressed, calling this a “ridiculous” answer.
      <Citation number={50} />
    </Typography>

    <Typography className="indent">
      White House Counsel Pasquale Anthony “Pat” Cipollone thought the Eastman plan was “nutty.”
      <Citation number={51} />
      Trump Campaign official Jason Miller testified that the Campaign’s General Counsel, Matt Morgan, and Deputy Campaign Manager, Justin Clark, thought Eastman was “crazy,” understood that there was “no validity to [his theory] in any way, shape, or form,” and shared their views with “anyone who would listen.”
      <Citation number={52} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        THE VICE PRESIDENT’S CONCLUSION THAT HE DID NOT HAVE THE ABILITY TO AFFECT THE OUTCOME OF THE ELECTION
      </Typography>
    </Box>

    <Typography className="indent">
      Vice President Pence’s counsel, Greg Jacob, was simultaneously researching the role of the Vice President during the joint session. The Office of the Vice President produced a preliminary staff memo on the subject on October 26, 2020.
      <Citation number={53} />
      Jacob then discussed the matter with Marc Short on election day or the day before.
    </Typography>

    <Typography className="indent">
      This wasn’t the first time Jacob would be required to write a memo about the Vice President’s role in the electoral process. Before the election, Short explained to him that some in the White House were encouraging President Trump to prematurely declare victory on election night.
      <Citation number={54} />
      Of course, that is exactly what President Trump did. Jacob and Short wanted to avoid the Vice President getting drawn in to any such declarations, and Jacob pointed to his role in presiding over the counting of the electoral votes on January 6th as a reason not to. Jacob sent a memo to Short on election day reflecting this advice.
      <Citation number={55} />
    </Typography>

    <Typography className="indent">
      Then, on December 7, 2020, the Lincoln Project aired a provocative ad taunting President Trump, saying that Vice President Pence “Will Put the Nail in Your Political Coffin” during the joint session on January 6th.
      <Citation number={56} />
      This prompted a discussion between Jacob and Vice President Pence.
      <Citation number={57} />
      Jacob authored another memo, dated December 8, 2020.
      <Citation number={58} />
      Jacob continued researching the Vice President’s role during the joint session into early January. Jacob told the Select Committee that his view of the matter was not fully formed until then.
      <Citation number={59} />
    </Typography>

    <Typography className="indent">
      Jacob did extensive research on and historical analysis of both the Electoral Count Act of 1887 and the 12th Amendment to the U.S. Constitution.
      <Citation number={60} />
      The 12th Amendment contains a single relevant line: “The President of the Senate shall, in the Presence of the Senate and House of Representatives, open all the Certificates, and the Votes shall then be counted.”
      <Citation number={61} />
      Though Jacob concluded that this line was “inartfully drafted,” it said nothing about resolving disputes over electoral votes.
      <Citation number={62} />
    </Typography>

    <Typography className="indent">
      Jacob concluded that the Vice President must adhere to the Electoral Count Act.
      <Citation number={63} />
      The ECA has been followed for 130 years and “every single time that there has been any objection to electors, it has been resolved in accordance with the Electoral Count Act procedures,” Jacob testified.
      <Citation number={64} />
      After reviewing the history and relevant cases, Jacob found that “[t]here is no justifiable basis to conclude that the Vice President has that kind of authority” to affect the outcome of the presidential election.
      <Citation number={65} />
      Jacob stated that his “review of text, history, and, frankly, just common sense” all confirmed that the Vice President had no such power.
      <Citation number={66} />
    </Typography>

    <Image
      src="/images/image-065.jpg"
      alt="Greg Jacob testifies before the Select Committee on June 16, 2022."
      caption="Greg Jacob testifies before the Select Committee on June 16, 2022."
      credit="(Photo by House Creative Services)"
    />

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        PRESIDENT TRUMP’S ALLIES FILED LAWSUITS SEEKING A COURT ORDER DIRECTING VICE PRESIDENT PENCE NOT TO COUNT CERTAIN ELECTORAL VOTES.
      </Typography>
    </Box>

    <Typography className="indent">
      One of President Trump’s congressional allies, Representative Louie Gohmert (R–TX), pushed a version of Eastman’s theory in the courts. On December 27, 2020, Representative Gohmert and several of the Trump Campaign’s fake electors for the State of Arizona (including Republican Party Chair Kelli Ward) filed suit against Vice President Pence in the U.S. District Court for the Eastern District of Texas.
      <Citation number={67} />
      As Ward explained to Marc Short in a phone call the day the suit was filed, President Trump was aware of the lawsuit and had signed off on it: “We wouldn’t have done that without the president telling us it was okay,” she told him.
      <Citation number={68} />
    </Typography>

    <Image
      src="/images/image-066.jpg"
      alt="Representative Louie Gohmert outside the Capitol on March 17, 2021."
      caption="Representative Louie Gohmert outside the Capitol on March 17, 2021."
      credit="(Photo by Chip Somodevilla/Getty Images)"
    />

    <Typography className="indent">
      In the suit, the Plaintiffs alleged that there were “competing slates” of electors from five States.
      <Citation number={69} />
      They asked the court to rule that portions of the Electoral Count Act of 1887 were unconstitutional and that “the Twelfth Amendment contains the exclusive dispute resolution mechanisms” for determining an objection raised by a Member of Congress to the electors submitted by any State.
      <Citation number={70} />
      Essentially, Representative Gohmert was asking the court to tell Vice President Pence that he was prohibited from following the procedures set forth in the Electoral Count Act. Much like Eastman’s theory, the Gohmert plaintiffs asserted that the Vice President has the “exclusive authority and sole discretion” to determine which electoral votes to count.
      <Citation number={71} />
    </Typography>

    <Typography className="indent">
      Although the Gohmert suit was premised on the same theory Eastman advocated, Eastman did not agree with the decision to file suit. Eastman argued that filing a suit against the Vice President had “close[ ] to zero” chance of succeeding, and there was a “very high” risk that the court would issue an opinion stating that “Pence has no authority to reject the Biden-certified ballots.”
      <Citation number={72} />
      As highlighted by Judge Carter, Eastman’s theory was that Vice President Pence should take this action “without asking for permission” from Congress or the courts.
      <Citation number={73} />
      Another attorney, Bill Olson, stated that getting a judicial determination “that Pence is constrained by [the Electoral Count Act]” could “completely tank the January 6 strategy.”
      <Citation number={74} />
      Those who were advocating to press on with the Eastman scheme did not want to bring it before a Federal judge because of the high risk that a court’s determination that the scheme was illegal would stop the plan to overturn the election dead in its tracks.
    </Typography>

    <Image
      src="/images/image-067.jpg"
      alt="John McEntee, February 28, 2020."
      caption="John McEntee, February 28, 2020."
      credit="(Photo by Alex Wong/Getty Images)"
    />

    <Typography className="indent">
      Eastman himself pushed this cavalier attitude towards the courts and compliance with the law during a call with Arizona House Speaker Rusty Bowers on January 4th. During this call, just two days before the joint session, Eastman pressed Speaker Bowers to bring the Arizona House into session to certify Trump electors or decertify the Biden electors.
      <Citation number={75} />
      Speaker Bowers responded as he had previously responded to similar entreaties by Giuliani and President Trump: by explaining that doing so would require him to violate his oaths to the U.S. and Arizona Constitutions and that he “wasn’t going to take such an action.”
      <Citation number={76} />
      Undeterred, Eastman still pushed Speaker Bowers to “just do it and let the courts sort it through.”
      <Citation number={77} />
    </Typography>

    <Typography className="indent">
      ltimately, Representative Gohmert’s legal gambit failed; a U.S. district judge dismissed the case quickly.
      <Citation number={78} />
      The judge’s ruling was upheld by the Supreme Court, which rejected Gohmert’s appeal without further consideration.
      <Citation number={79} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        OTHER INDIVIDUALS ADVISING PRESIDENT TRUMP AND HIS CAMPAIGN ALSO ADVOCATED FOR A ROLE FOR THE VICE PRESIDENT AT THE JOINT SESSION.
      </Typography>
    </Box>

    <Typography className="indent">
      Other individuals inside and outside the White House also advanced versions of the theory that the Vice President had agency in the joint session. The issue of Vice President Pence’s role came up during a December meeting in the Oval Office. Either President Trump or his chief of staff, Mark Meadows, tasked John McEntee, the director of the Presidential Personnel Office, with researching the matter further.
      <Citation number={80} />
      Though McEntee was one of President Trump’s close advisors, he was not a lawyer and had no relevant experience. Yet, he wrote a one-page memo claiming that “the VP has substantial discretion to address issues with the electoral process.”
      <Citation number={81} />
    </Typography>

    <Typography className="indent">
      This wasn’t the only one-page analysis drafted by McEntee before January 6th.
      <Citation number={82} />
      He later proposed a “middle path” in which he envisioned the Vice President accepting only half the electoral votes from six disputed States (specifically, Wisconsin, Michigan, Pennsylvania, Georgia, Arizona and Nevada).
      <Citation number={83} />
      McEntee portrayed this as a way to avoid “disenfranchis[ing]” States while still achieving the desired result: delivering a second term to President Trump. McEntee conveyed this memo to the President with a cover note reading, “This is probably our only realistic option because it would give Pence an out.”
      <Citation number={84} />
      McEntee told the Select Committee that this judgment was based on his assessment that “it was, like, pretty obvious [the Vice President] wasn’t going to just reject... the electors or whatever was being asked of him at that time.”
      <Citation number={85} />
    </Typography>

    <Typography className="indent">
      Another advocate of a plan for the Vice President to play a role in the joint session was Jenna Ellis, a lawyer working for the Trump Campaign. She argued in two memos that Vice President Pence had the power to delay the counting of certified electoral votes. In the first memo, addressed to President Trump and dated December 31, 2020, Ellis advised that Vice President Pence should “not open any of the votes” from six States that “currently have electoral delegates in dispute.”
      <Citation number={86} />
      Ellis asserted that this “dispute” provided “sufficient rational and legal basis to question whether the [S]tate law and Constitution was followed.” Ellis proposed a delay of ten days, as the Vice President and Congress awaited a “response from the [S]tate legislatures, which would then need to meet in an emergency electoral session.” If any of the State legislatures “fails to provide a timely response, no electoral votes can be opened and counted from that [S]tate.” Ellis claimed that Vice President Pence would not be “exercising discretion nor establishing new precedent,” but instead “simply asking for clarification from the constitutionally appointed authority.”
      <Citation number={87} />
    </Typography>

    <Image
      src="/images/image-068.jpg"
      alt="Jenna Ellis on December 2, 2020 in Lansing, Michigan."
      caption="Jenna Ellis on December 2, 2020 in Lansing, Michigan."
      credit="(Photo by Rey Del Rio/Getty Images)"
    />

    <Typography className="indent">
      Ellis sent the substance of this memorandum in an email to Fox News host Jeanine Pirro on January 1, 2021, under the subject line “Constitutional option.”
      <Citation number={88} />
      And, on January 4, 2021, she sent the same substance to Fox News contributor John Solomon under the subject line “Pence option.”
      <Citation number={89} />
    </Typography>

    <Typography className="indent">
      Ellis addressed a second memo, dated January 5, 2021, to Jay Sekulow, an outside attorney who represented President Trump during his first impeachment proceedings and in other litigation.
      <Citation number={90} />
      Ellis again claimed that Vice President Pence had the power to delay the certification of the vote. Ellis recommended that the Vice President should, when he arrived at the first contested State (Arizona), “simply stop the count” on the basis that the States had not made a “final determination of ascertainment of electors.” “The [S]tates would therefore have to act.”
      <Citation number={91} />
    </Typography>

    <Typography className="indent">
      Sekulow clearly disagreed. “Some have speculated that the Vice President could simply say, ‘I’m not going to accept these electors,’ that he has the authority to do that under the Constitution,” Sekulow said during an episode of his radio show.
      <Citation number={92} />
      “I actually don’t think that’s what the Constitution has in mind.” Sekulow added that the Vice President serves a merely “ministerial, procedural function.”
      <Citation number={93} />
    </Typography>

    <Typography className="indent">
      In addition, Herschmann discussed this memo with Sekulow. They agreed that Ellis did not have the “qualifications or the experience to be giving advice on this” or to be “litigating the challenges” that President Trump’s team was filing in court.
      <Citation number={94} />
      Herschmann did not think that Sekulow shared the memo with the President.
      <Citation number={95} />
    </Typography>
  </Page>
)
