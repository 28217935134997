import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet9'));

export const Chapter70 = ({ ...args }: any) => (
  <Page {...args}>
    <Image
      src="/images/image-087.jpg"
      alt="President Trump speaks at the January 6th Ellipse rally."
      caption="President Trump speaks at the January 6th Ellipse rally."
      credit="(Photo by Tasos Katopodis/Getty Images)"
      style={{ marginTop: 0 }}
    />

    <Typography className="indent">
      At 1:10 p.m. on January 6th, President Trump concluded his speech at the Ellipse. By that time, the attack on the U.S. Capitol had already begun. But it was about to get much worse. The President told thousands of people in attendance to march down Pennsylvania Avenue to the Capitol. He told them to “fight like hell” because if they didn’t, they were “not going to have a country anymore.” Not everyone who left the Ellipse did as the Commander-in-Chief ordered, but many of them did. The fighting intensified during the hours that followed.
      <Citation number={1} />
    </Typography>

    <Typography className="indent">
      By 1:21 p.m., President Trump was informed that the Capitol was under attack. He could have interceded immediately. But the President chose not to do so. It was not until 4:17 p.m. that President Trump finally tweeted a video in which he told the rioters to go home.
    </Typography>

    <Typography className="indent">
      The 187 minutes between the end of President Trump’s speech and when he finally told the mob to leave the U.S. Capitol was a dereliction of duty. In the U.S. military, a service member is deemed to be “derelict in the performance of duties when that person willfully or negligently fails to perform that person’s duties or when that person performs them in a culpably inefficient manner.”
      <Citation number={2} />
      As Commander-in-Chief, President Trump had the power—more than any other American—to muster the U.S. Government’s resources and end the attack on the U.S. Capitol. He willfully remained idle even as others, including his own Vice President, acted.
    </Typography>

    <Typography className="indent">
      President Trump could have called top officials at the Department of Justice, the Department of Homeland Security, the Department of Defense, the F.B.I., the Capitol Police Department, or the DC Mayor’s Office to ensure that they quelled the violence. He made no such calls. Instead, President Trump reached out to Rudolph Giuliani and friendly Members of Congress, seeking their assistance in delaying the joint session of Congress. And the President tweeted at 2:24 p.m., at the height of the violence, that his own Vice President lacked the “courage” to act—a statement that could only further enrage the mob. Meanwhile, Vice President Michael Pence assumed the duties of the President, requesting the assistance of top officials, even though he was not in the chain of command and had no constitutional power to issue orders.
    </Typography>

    <Typography className="indent">
      In testimony before the Select Committee, Chairman of the Joint Chiefs of Staff General Mark Milley explained that President Trump did “[n]othing,” “[z]ero” to marshal the Government’s resources during the assault on the U.S. Capitol.
      <Citation number={3} />
      In contrast, Vice President Pence had “two or three calls” with General Milley and other military officials—even as the mob hunted him. During those calls, Vice President Pence was “very animated” and “issued very explicit, very direct, unambiguous orders.” The Vice President told Acting Secretary of Defense Chris Miller to “get the military down here, get the [National] [G]uard down here,” and “put down this situation.”
      <Citation number={4} />
      President Trump could have made those same demands. He chose not to do so—a damning fact that President Trump’s own Chief of Staff, Mark Meadows, quickly tried to cover up.
    </Typography>

    <Typography className="indent">
      “We have to kill the narrative that the Vice President is making all the decisions,” General Milley recalled Meadows as saying. “We need to establish the narrative, you know, that the President is still in charge and that things are steady or stable,” Meadows said, which General Milley described as a “[r]ed flag.”
      <Citation number={5} />
      In his testimony, General Milley also reflected on what it meant for a President not to be taking action in a time of crisis:
    </Typography>

    <Blockquote>
      <Typography>
        You know, you’re the Commander in Chief. You’ve got an assault going on on the Capitol of the United States of America, and there’s nothing? No call? Nothing? Zero? And it’s not my place to, you know, pass judgment or—I’m the, you know—but no attempt to call the Secretary of Defense? No attempt to call the Vice President of the United States of America, who’s down on the scene? To my knowledge, it wasn’t—I just noted it.
        <Citation number={6} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      President Trump’s closest advisors—both inside and out of the White House—implored him to act sooner. Earlier in the week, two of the President’s most trusted aides, Eric Herschmann and Hope Hicks, both wanted President Trump to emphasize that January 6th would be a peaceful protest. President Trump refused.
      <Citation number={7} />
    </Typography>

    <Typography className="indent">
      On the 6th, as the riot began to escalate, a colleague texted Hicks and wrote, “Hey, I know you’re seeing this. But he really should tweet something about Being NON-violent.”
      <Citation number={8} />
      “I’m not there,” Hicks replied. “I suggested it several times Monday and Tuesday and he refused.”
      <Citation number={9} />
    </Typography>

    <Typography className="indent">
      Once the attack was underway, President Trump initially ignored the counsel of his own family, members of his administration, Republican elected officials, and friendly Fox News personalities. Both Ivanka Trump and Donald Trump, Jr. wanted their father to tell the rioters to go home sooner. The President delayed. At 2:38 p.m., President Trump sent this tweet: “Please support our Capitol Police and Law Enforcement. They are truly on the side of our Country. Stay peaceful!”
      <Citation number={10} />
      Sarah Matthews, the White House Deputy Press Secretary, told the Select Committee that President Trump resisted using the word “peaceful.” The President added the words “Stay peaceful!” only after Ivanka Trump suggested the phrase.
      <Citation number={11} />
      Trump, Jr. quickly recognized that his father’s tweet was insufficient. “He’s got to condem [sic] this shit. Asap. The captiol [sic] police tweet is not enough,” Trump, Jr. wrote in a text to White House Chief of Staff Mark Meadows.
      <Citation number={12} />
      President Trump did not tell the rioters to disperse in either his 2:38 p.m. tweet, or another tweet at 3:13 p.m.
      <Citation number={13} />
    </Typography>

    <Typography className="indent">
      Multiple witnesses told the Select Committee that Minority Leader Kevin McCarthy contacted the President and others around him, desperately trying to get him to act. McCarthy’s entreaties led nowhere. “I guess they’re just more upset about the election theft than you are,” President Trump told McCarthy.
      <Citation number={14} />
      Top lawyers in the White House Counsel’s Office attempted to intercede. Two Fox News primetime personalities, always so obsequious, begged those around the President to get him to do more. But President Trump was unmoved.
    </Typography>

    <Typography className="indent">
      There’s no question that President Trump had the power to end the insurrection. He was not only the Commander-in-Chief of the U.S. military, but also of the rioters.
    </Typography>

    <Typography className="indent">
      One member of the mob, Stephen Ayres, told the Select Committee that he and others quickly complied as soon as President Trump finally told them to go home. “[W]e literally left right after [President Trump’s 4:17 p.m. video] come out. You know, to me if he would have done that earlier in the day, 1:30 [p.m.]... maybe we wouldn’t be in this bad of a situation or something,” Ayres said.
      <Citation number={15} />
      Another rioter, Jacob Chansley, commonly referred to as the “QAnon Shaman,” was one of the first 30 rioters to enter the U.S. Capitol. Chansley told a reporter that he left the building because “Trump asked everybody to go home.”
      <Citation number={16} />
      At 4:25 p.m., just eight minutes after President Trump tweeted his video, an Oath Keeper named Ed Vallejo messaged other members of his group, a fair number of whom were at the Capitol: “Gentleman [sic], Our Commander-in-Chief has just ordered us to go home. Comments?”
      <Citation number={17} />
    </Typography>

    <Image
      src="/images/image-090.jpg"
      alt="President Trump appears on a monitor in the White House briefing room depicting a video he released instructing rioters to go home."
      caption="President Trump appears on a monitor in the White House briefing room depicting a video he released instructing rioters to go home."
      credit="(Photo by Joshua Roberts/Getty Images)"
    />

    <Typography className="indent">
      Even then, President Trump did not disavow the rioters. He endorsed their cause, openly sympathized with them, and repeated his Big Lie once again. “I know your pain, I know you’re hurt. We had an election that was stolen from us,” President Trump said at the beginning of his 4:17 p.m. video. “It was a landslide election, and everyone knows it, especially the other side. But you have to go home now. We have to have peace. We have to have law and order. We have to respect our great people in law and order. We don’t want anybody hurt.” The President portrayed the violence as something his political foes would use against him, saying: “This was a fraudulent election, but we can’t play into the hands of these people.”
      <Citation number={18} />
    </Typography>

    <Typography className="indent">
      The President concluded his short video by again praising the men and women who had overrun the U.S. Capitol. “We have to have peace. So go home. We love you. You’re very special,” President Trump said. “You’ve seen what happens. You see the way others are treated that are so bad and so evil. I know how you feel, but go home, and go home in peace.”
      <Citation number={19} />
    </Typography>

    <Typography className="indent">
      Just after 6:00 p.m. on January 6th, President Trump issued his final tweet of the day, again lauding the rioters and justifying their cause. President Trump made excuses for the riot, saying this is what happens “when a sacred landslide election victory is so unceremoniously & viciously stripped away from great patriots who have been badly & unfairly treated for so long.” The President added: “Go home with love & in peace. Remember this day forever!”
      <Citation number={20} />
    </Typography>

    <Typography className="indent">
      The following day, President Trump’s advisors encouraged him to deliver a short speech denouncing the attack on the U.S. Capitol. The President struggled to deliver his prepared remarks. According to Cassidy Hutchinson, President Trump wanted to say that he would pardon the rioters. Lawyers in the White House Counsel’s Office objected, so this language was not included.
      <Citation number={21} />
      John McEntee, the Director of the White House Presidential Personnel Office, also testified that in the days following the attack, he heard President Trump mention the possibility of a “blanket pardon” for all those involved in the events of January 6th.
      <Citation number={22} />
    </Typography>

    <Typography className="indent">
      President Trump never did give up on the prospect. Since leaving office, the now former President has said he would consider “full pardons with an apology to many” of the January 6th defendants if he is reelected.
      <Citation number={23} />
    </Typography>
  </Page>
)
