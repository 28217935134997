import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet8'));

export const Chapter617 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      On the evening of January 5th, the President edited the speech he would deliver the next day at the Ellipse. The President’s speechwriting team had only started working on his remarks the day before.
      <Citation number={476} />
      Despite concerns from the speechwriting team, unfounded claims coming from Giuliani and others made their way into the draft.
      <Citation number={477} />
    </Typography>

    <Typography className="indent">
      The initial draft circulated on January 5th emphasized that the crowd would march to the U.S. Capitol.
      <Citation number={478} />
      Based on what they had heard from others in the White House, the speechwriting team expected President Trump to use his address to tell people to go to the Capitol.
      <Citation number={479} />
    </Typography>

    <Typography className="indent">
      That evening, President Trump convened an impromptu gathering in the Oval Office with members of his staff, primarily his press team
      <Citation number={480} />
      and White House Deputy Chief of Staff Dan Scavino, who was in charge of President Trump’s personal Twitter account.
      <Citation number={481} />
      Despite the bitter cold, the President ordered his staff to keep the door to the Rose Garden open so he could hear the music and cheering from his supporters at Freedom Plaza.
      <Citation number={482} />
      The music playing at Freedom Plaza was so loud “you could feel it shaking in the Oval.”
      <Citation number={483} />
    </Typography>

    <Typography className="indent">
      As President Trump listened, he was tweeting, at one point telling his supporters he could hear them from the Oval Office.
      <Citation number={484} />
      His speechwriters incorporated those tweets into a second draft of the speech that was circulated later that evening.
      <Citation number={485} />
      The following appeared in both tweet form
      <Citation number={486} />
      and was adapted into the speech:
    </Typography>

    <Blockquote>
      <Typography>
        “All of us here today do not want to see our election victory stolen by emboldened Radical Left Democrats. Our Country has had enough, they won’t take it anymore! Together, we will STOP THE STEAL.”
        <Citation number={487} />
      </Typography>
    </Blockquote>

    <Image
      src="/images/image-085.jpg"
      alt="President Trump and members of his staff in the Oval Office on the evening of January 5, 2021."
      caption="President Trump and members of his staff in the Oval Office on the evening of January 5, 2021."
      credit="(Photo provided to the Select Committee by the National Archives and Records Administration)"
    />

    <Typography className="indent">
      In speaking with staff, he still seemed optimistic that “Congress would take some sort of action in his favor.”
      <Citation number={488} />
      The White House photographer, who was also in attendance, recalled that President Trump again remarked that he should go to the Capitol the next day, and even asked about the best route to get there.
      <Citation number={489} />
      The President peppered staff for ideas concerning how “we could make the RINOs do the right thing” and make the next day “big.”
      <Citation number={490} />
      Deputy Press Secretary Sarah Matthews, who was present in the Oval Office that evening, understood that President Trump wanted to get Republican Members of Congress to send the electoral votes back to the States, rather than certify the election.
      <Citation number={491} />
      Matthews recalled that initially no one spoke up in response, since they were trying to “process” what he had said.
      <Citation number={492} />
    </Typography>

    <Typography className="indent">
      Eventually, Deere suggested that President Trump should focus his speech on his administration’s accomplishments, rather than on his claim that the election had been stolen.
      <Citation number={493} />
      But the President told Deere that while they had accomplished a lot, the crowd was going to be “fired up” and “angry” the next day because they believed the election had been stolen and was rigged.
      <Citation number={494} />
      President Trump knew the crowd was angry because he could hear them.
      <Citation number={495} />
      Of course, President Trump was responsible, more than any other party, for ginning up their anger.
    </Typography>

    <Typography className="indent">
      President Trump ended the evening by asking an aide how many people were going to be at the rally. The aide responded that he was not sure but told President Trump that he saw videos on Twitter of “pro-trump people chanting on planes heading to DC,” which he asked to be shared with Scavino.
      <Citation number={496} />
    </Typography>

    <Typography className="indent">
      “We will not let them silence your voices,” the President told the crowd from the podium at the Ellipse. “We're not going to let it happen, I'm not going to let it happen.”
      <Citation number={497} />
      His supporters started chanting, “fight for Trump!” The President thanked them.
      <Citation number={498} />
    </Typography>

    <Typography className="indent">
      President Trump knew not only that his supporters were angry, but also that some of them were armed.
      <Citation number={499} />
      At times, he ad-libbed, deliberately stoking their rage even more. At one point he said: “And we fight. We fight like hell. And if you don’t fight like hell, you’re not going to have a country anymore.”
      <Citation number={500} />
      The word “fight,” or a variation thereof, appeared only twice in the prepared text.
      <Citation number={501} />
      President Trump would go on to utter the word twenty times during his speech at the Ellipse.
      <Citation number={502} />
    </Typography>

    <Typography className="indent">
      President Trump had summoned a mob, including armed extremists and conspiracy theorists, to Washington, DC on the day the joint session of Congress was to meet. He then told that same mob to march on the U.S. Capitol and “fight.” They clearly got the message.
    </Typography>
  </Page>
)
