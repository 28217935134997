import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Replay10Icon from '@mui/icons-material/Replay10';
import Forward10Icon from '@mui/icons-material/Forward10';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { alpha, styled, useTheme } from '@mui/material/styles';

import { GlobalPlayerStateContext, PlayerSpeeds } from './GlobalPlayerStateProvider';

const StyledControls = styled('div')(({ theme}) => `
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${theme.spacing(.5)};
  padding: ${theme.spacing(1)};
  border-radius: ${theme.spacing(2)};
  background: ${
    theme.palette.mode === 'dark'
      ? theme.palette.primary.main
      : theme.palette.secondary.light
  } !important;

  color: ${
    theme.palette.getContrastText(theme.palette.common.white)
  };

  & > * {
    order: 1;
  }

  .volume-slider {
    flex-shrink: 1;
  }

  @media (max-width: ${theme.breakpoints.values.sm}${theme.breakpoints.unit}) {
    flex-wrap: wrap;
    justify-content: space-between;

    .volume-slider {
      order: 0;
      flex-shrink: 0;
    }
  }
`);

const BlackSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: theme.palette.grey[300],

    '&.Mui-checked': {
      color: theme.palette.getContrastText(theme.palette.common.white),
      '&:hover': {
        backgroundColor: alpha(theme.palette.getContrastText(theme.palette.common.white), theme.palette.action.hoverOpacity),
      },
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.getContrastText(theme.palette.common.white),
  },
}));

const BlackSlider = styled(Slider)(({ theme }: any) => ({
  color: theme.palette.getContrastText(theme.palette.common.white),

  '& .MuiSlider-thumb': {
    backgroundColor: theme.palette.getContrastText(theme.palette.common.white),

    '&:focus, &:hover, &.Mui-focusVisible': {
      boxShadow: `
        0 0 0
        ${theme.spacing(1)}
        ${alpha(theme.palette.getContrastText(theme.palette.common.white), .1)}
      `,
    },

    '&.Mui-active': {
      boxShadow: `
        0 0 0
        ${theme.spacing(1.25)}
        ${alpha(theme.palette.getContrastText(theme.palette.common.white), .1)}
      `,
    }
  },
}));

export const Player = ({ src, next }: any) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const playerRef: any = useRef();
  const speedRef: any = useRef();

  const [globalPlayerState, setGlobalPlayerState] = useContext(GlobalPlayerStateContext);

  const [playerState, setPlayerState] = useState({
    showControls: false,
    showSpeed: false,
    isSeeking: false,

    playing: false,
    currentTime: 0,
    duration: 0,
  });

  const stopSeek = useCallback(() => {
    setPlayerState((s: any) => ({ ...s, isSeeking: false }));
  }, [setPlayerState]);

  useEffect(() => {
    setPlayerState((s: any) => ({ ...s, showControls: true }));
  }, []);

  useEffect(() => {
    window.addEventListener('mouseup', stopSeek);
    window.addEventListener('touchend', stopSeek);

    return () => {
      window.removeEventListener('mouseup', stopSeek);
      window.removeEventListener('touchend', stopSeek);
    }
  }, [stopSeek]);

  useEffect(() => {
    playerRef.current.playbackRate = PlayerSpeeds[globalPlayerState.speed];
  }, [globalPlayerState.speed]);

  useEffect(() => {
    playerRef.current.volume = globalPlayerState.muted ? 0 : 1;
  }, [globalPlayerState.muted]);

  const formatSeconds = (seconds: any) => {
    if (!seconds) {
      return '0:00';
    }

    const date = new Date(0);
    date.setSeconds(seconds);

    return date.toISOString().substring(11, 19).replace(/^0+:0?/, '');
  }

  return (
    <>
      <audio
        ref={playerRef}
        src={src ? `/audio/${src}` : ''}
        autoPlay={globalPlayerState.autoplay}
        onPause={(event: any) => setPlayerState((s: any) => ({ ...s, playing: false }))}
        onPlay={(event: any) => setPlayerState((s: any) => ({ ...s, playing: true }))}
        onLoadedMetadata={(event: any) => setPlayerState((s: any) => ({ ...s, duration: playerRef.current.duration }))}
        onEnded={(event: any) => globalPlayerState.autoplay && !playerState.isSeeking && navigate(next.route)}
        onTimeUpdate={(event: any) => {
          if (playerState.isSeeking) {
            return;
          }

          setPlayerState((s: any) => ({ ...s, currentTime: playerRef.current.currentTime }));
        }}
      />

      {
        playerState.showControls && (
          <StyledControls>
            <IconButton
              color="inherit"
              size="small"
              onClick={() => playerRef.current.currentTime = playerRef.current.currentTime - 10}
            >
              <Replay10Icon fontSize="small" />
            </IconButton>
            <IconButton color="inherit" size="small" onClick={() => (
              playerState.playing
                ? playerRef.current.pause()
                : playerRef.current.play()
            )}>
              {
                playerState.playing
                  ? <PauseIcon fontSize="medium" />
                  : <PlayArrowIcon fontSize="medium" />
              }
            </IconButton>
            <IconButton
              color="inherit"
              size="small"
              onClick={() => playerRef.current.currentTime = playerRef.current.currentTime + 10}
            >
              <Forward10Icon fontSize="small" />
            </IconButton>

            <FormControlLabel
              control={
                <BlackSwitch
                  checked={globalPlayerState.autoplay}
                  onChange={({ target: { checked }}: any) => (
                    setGlobalPlayerState((s: any) => ({ ...s, autoplay: checked }))
                  )}
                  size="small"
                />
              }
              label="Autoplay"
              labelPlacement="bottom"
              style={{
                margin: 0,
                transform: "translateY(0.25em)",
              }}
              slotProps={{
                typography: {
                  variant: 'overline',
                  style: {
                    fontSize: '8px',
                    fontWeight: 'bold',
                    marginTop: '0',
                  }
                }
              }}
            />

            <Stack
              className="volume-slider"
              spacing={2}
              direction="row"
              alignItems="center"
              width="100%"
              style={{ padding: `0 ${theme.spacing(1)}`}}
            >
              <Typography variant="caption">
                { formatSeconds(playerState.currentTime) }
              </Typography>
              <BlackSlider
                aria-label="Position"
                onChange={(e: any) => setPlayerState((s: any) => ({ ...s, currentTime: e.target.value }))}
                value={playerState.currentTime}
                max={playerState.duration}
                size="small"
                slotProps={{
                  root: {
                    onMouseDown: () => setPlayerState((s: any) => ({ ...s, isSeeking: true })),
                    onTouchStart: () => setPlayerState((s: any) => ({ ...s, isSeeking: true })),
                    onMouseUp: () => playerRef.current.currentTime = playerState.currentTime,
                    onTouchEnd: () => playerRef.current.currentTime = playerState.currentTime,
                  }
                }}
              />
              <Typography variant="caption">
                { formatSeconds(playerState.duration) }
              </Typography>
            </Stack>

            <ClickAwayListener onClickAway={() => setPlayerState((s: any) => ({ ...s, showSpeed: false }))}>
              <div>
                <Button variant="text" size="small" ref={speedRef}
                  onClick={() => setPlayerState((s: any) => ({ ...s, showSpeed: !s.showSpeed }))}
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    minWidth: 0,
                    textTransform: 'none',
                    fontSize: '.8em'
                  }}
                >
                  { PlayerSpeeds[globalPlayerState.speed] }x
                </Button>

                { speedRef.current && (
                  <Menu
                    id="basic-menu"
                    anchorEl={speedRef.current}
                    open={playerState.showSpeed}
                    onClose={() => setPlayerState((s: any) => ({ ...s, showSpeed: false }))}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    PaperProps={{ style: { background: theme.palette.grey[300], color: "inherit" }}}
                  >
                    {
                      PlayerSpeeds.map((speed: any, index: any) => (
                        <MenuItem
                          key={index}
                          selected={index === globalPlayerState.speed}
                          onClick={() => {
                            setGlobalPlayerState((s: any) => ({ ...s, speed: index }));
                            setPlayerState((s: any) => ({ ...s, showSpeed: false }));
                          }}
                        >
                          <Typography component="span" fontSize=".75em" color="inherit">
                            {speed}x
                          </Typography>
                        </MenuItem>
                      ))
                    }
                  </Menu>
                ) }
              </div>
            </ClickAwayListener>

            <IconButton
              color="inherit"
              size="small"
              onClick={() => setGlobalPlayerState((s: any) => ({ ...s, muted: !s.muted }))}
            >
              {
                globalPlayerState.muted
                 ? <VolumeOffIcon fontSize="small" />
                 : <VolumeUpIcon fontSize="small" />
              }
            </IconButton>
          </StyledControls>
        )
      }

    </>
  )
}
