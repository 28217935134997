import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet9'));

export const Chapter79 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      President Trump’s 2:38 p.m. tweet did not condemn the violence at the Capitol. It did not tell rioters to leave the building.
    </Typography>

    <Image
      src="/images/image-097.jpg"
      alt="Testimony footage of former White House Press Secretary Kayleigh McEnany is played during a January 6th Select Committee hearing."
      caption="Testimony footage of former White House Press Secretary Kayleigh McEnany is played during a January 6th Select Committee hearing."
      credit="(Photo by Pool/Getty Images)"
    />

    <Typography className="indent">
      In the minutes before the tweet, Fox News—on the President’s screen—relayed that the Capitol was on lockdown;
      <Citation number={220} />
      that Capitol police officers were injured; that rioters were in the building and “just feet from the House chamber.”
      <Citation number={221} />
      In the minutes afterward, networks would report there was tear gas in the Capitol, forcing Members of Congress to evacuate in protective masks.
      <Citation number={222} />
      At 2:39 p.m., Secret Service agents reported that “[m]ore just got in.”
      <Citation number={223} />
    </Typography>

    <Typography className="indent">
      “I don’t know how they’re gonna retake the Capitol building back at this point,” one agent wrote to others two minutes later.
      <Citation number={224} />
    </Typography>

    <Typography className="indent">
      At 2:44 p.m., a Capitol police officer shot a rioter named Ashli Babbitt.
      <Citation number={225} />
      A handwritten note—dashed off onto a White House pocket card and preserved by the National Archives—read: “1x civilian gunshot wound to chest @ door of House cha[m]ber.”
      <Citation number={226} />
      One White House employee saw the note on the dining table in front of President Trump.
      <Citation number={227} />
    </Typography>

    <Typography className="indent">
      A barrage of text messages inundated Meadows’s phone with a consistent plea.
      <Citation number={228} />
      Everyone from conservative media personalities to Republican allies in Congress—and even the President’s own family—urged the President to do more:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Representative Marjorie Taylor Greene, 2:28 p.m.</strong>: “Mark I was just told there is an active shooter on the first floor of the Capitol Please tell the President to calm people[.] This isn’t the way to solve anything.”
        <Citation number={229} />
      </Typography>
    </Blockquote>

    <Blockquote>
      <Typography>
        <strong>Laura Ingraham, 2:32 p.m.</strong>: “Hey Mark, The [sic] president needs to tell people in the Capitol to go home.”
      </Typography>
      <Typography>
        “This is hurting all of us.”
      </Typography>
      <Typography>
        “He is destroying his legacy and playing into every stereotype... we lose all credibility against the BLM/Antifa crowd if things go South.”
      </Typography>
      <Typography>
        “You can tell him I said this.”
        <Citation number={230} />
      </Typography>
    </Blockquote>

    <Blockquote>
      <Typography>
        <strong>Mick Mulvaney, 2:35 p.m.</strong>: “Mark: he needs to stop this, now. Can I do anything to help?”
        <Citation number={231} />
      </Typography>
    </Blockquote>

    <Blockquote>
      <Typography>
        <strong>Representative Barry Loudermilk, 2:44 p.m.</strong>: “It’s really bad up here on the hill.”
      </Typography>
      <Typography>
        “They have breached the Capitol.”
        <Citation number={232} />
      </Typography>
      <Typography>
        <strong>At 2:48 p.m., Meadows responded</strong>: “POTUS is engaging.”
        <Citation number={233} />
      </Typography>
      <Typography>
        <strong>At 2:49 p.m., Loudermilk responded</strong>: “Thanks. This doesn’t help our cause.”
        <Citation number={234} />
      </Typography>
    </Blockquote>

    <Blockquote>
      <Typography>
        <strong>Representative William Timmons, 2:46 p.m.</strong>: “The president needs to stop this ASAP.”
        <Citation number={235} />
      </Typography>
      <Typography>
        <strong>At 2:49 p.m., Meadows responded</strong>: “We are doing it.”
        <Citation number={236} />
      </Typography>
    </Blockquote>

    <Blockquote>
      <Typography>
        <strong>Donald Trump, Jr., 2:53 p.m.</strong>: “He’s got to condem [sic] this shit. Asap. The captiol [sic] police tweet is not enough.”
        <Citation number={237} />
      </Typography>
      <Typography>
        <strong>Meadows responded</strong>: “I am pushing it hard. I agree.”
        <Citation number={238} />
      </Typography>
      <Typography>
      <strong>Later, Trump, Jr., continued</strong>: “This his [sic] one you go to the mattresses on. They will try to fuck his entire legacy on this if it gets worse.”
      <Citation number={239} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      White House staff discussed issuing yet another, stronger statement to address the ongoing—and escalating—violence. Around 3:00 p.m., one proposal was written in block capital letters on a pocket card from the chief of staff’s office:
    </Typography>

    <Blockquote>
      <Typography>
        ANYONE WHO ENTERED THE CAPITOL <del>ILLEGALLY</del> WITHOUT PROPER AUTHORITY SHOULD LEAVE IMMEDIATELY[.]
        <Citation number={240} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The handwriting appears to have been scrawled quickly and somewhat messily. Hutchinson recalled Meadows returning from the dining room with the note in hand and placing it on her desk.
      <Citation number={241} />
      The word “illegally” had been newly crossed out.
      <Citation number={242} />
    </Typography>

    <Typography className="indent">
      But there would be no further action, Meadows told her.
      <Citation number={243} />
    </Typography>

    <Image
      src="/images/image-098.jpg"
      alt="Guns are drawn in the House Chamber on January 6th as rioters attempt to break in."
      caption="Guns are drawn in the House Chamber on January 6th as rioters attempt to break in."
      credit="(Photo by Drew Angerer/Getty Images)"
    />

    <Typography className="indent">
      At 3:13 p.m., 35 minutes after his last tweet, the President issued another tweet. Rather than coming out with a stronger statement, the 3:13 p.m. tweet largely parroted the one preceding it:
    </Typography>

    <Blockquote>
      <Typography>
        I am asking for everyone at the U.S. Capitol to remain peaceful. No violence! Remember, WE are the Party of Law & Order—respect the Law and our great men and women in Blue. Thank you!
        <Citation number={244} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Ivanka Trump—who was in the room when her father published the message—told the Select Committee that “the gravity of the situation” made her feel “that it would be helpful to tweet again.”
      <Citation number={245} />
      “The [earlier] tweet didn’t stop the violence,” Herschmann said.
      <Citation number={246} />
    </Typography>

    <Typography className="indent">
      This tweet—like the last one—didn’t tell the rioters to go home. It suggested that they “remain” at the Capitol, albeit peacefully.
    </Typography>
  </Page>
)
