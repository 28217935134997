import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet7'));

export const Chapter55 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Eastman called Herschmann on January 7th to discuss litigation on behalf of the Trump Campaign in Georgia.
      <Citation number={326} />
      This gave Herschmann another opportunity to lay into Eastman. “[Are] you out of your F’ing mind?” Herschmann asked. “I only want to hear two words coming out of your mouth from now on: orderly transition.” Herschmann said. After some berating, Eastman repeated after Herschmann: “Orderly transition.” “Now I’m going to give you the best free legal advice you’re ever getting in your life,” Herschmann said. “Get a great F’ing criminal defense lawyer, you’re going to need it.”
      <Citation number={327} />
      Days afterward, Eastman sent an email to Giuliani, making a request that tacitly acknowledged just how much trouble he was in: “I’ve decided that I should be on the pardon list, if that is still in the works.”
      <Citation number={328} />
    </Typography>

    <Typography className="indent">
      Vice President Pence and his team never bowed to President Trump’s relentless pressure. They began January 6, 2021, with a prayer. The attack on the U.S. Capitol delayed the peaceful transfer of power. The joint session did not end until early in the morning on January 7th.
    </Typography>

    <Typography className="indent">
      At 3:50 a.m. that morning, Short texted Vice President Pence a passage from Second Timothy, chapter 4, verse 7: “I fought the good fight. I finished the race. I have kept the faith.”
      <Citation number={329} />
    </Typography>
  </Page>
)
