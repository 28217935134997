import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet2'));

export const RatherThanConcede = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      President Trump disregarded the rulings of the courts and rejected the findings and conclusions and advice from his Justice Department, his campaign experts, and his White House and Cabinet advisors. He chose instead to try to overturn the election on January 6th and took a series of very specific steps to attempt to achieve that result.
    </Typography>

    <Typography className="indent">
      A central element of Donald Trump’s plan to overturn the election relied upon Vice President Mike Pence. As Vice President, Pence served as the President of the Senate, the presiding officer for the joint session of Congress on January 6th. Beginning in December, and with greater frequency as January 6th approached, Trump repeatedly and unlawfully pressured Pence in private and public to prevent Congress from counting lawful electoral votes from several States.
    </Typography>

    <Typography className="indent">
      To understand the plan President Trump devised with attorney and law professor John Eastman, it is necessary to understand the constitutional structure for selecting our President.
    </Typography>

    <Typography className="indent">
      At the Constitutional Convention 233 years ago, the framers considered but rejected multiple proposals that Congress itself vote to select the President of the United States.
      <Citation number={143} />
      Indeed the Framers voiced very specific concerns with Congress selecting the President. They viewed it as important that the electors, chosen for the specific purpose of selecting the President, should make the determination rather than Congress:
    </Typography>

    <Blockquote>
      <Typography>
        It was desireable, that the sense of the people should operate in the choice of the person to whom so important a trust was to be confided. This end will be answered by committing the right of making it, not to any pre-established body, but to men, chosen by the people for the special purpose, and at the particular conjuncture.
       <Citation number={144} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The Framers understood that a thoughtful structure for the appointment of the President was necessary to avoid certain evils: “Nothing was more to be desired, than that every practicable obstacle should be opposed to cabal, intrigue and corruption.”
      <Citation number={145} />
      They were careful to ensure that “those who from situation might be suspected of too great devotion to the president in office” “were not among those that chose the president.”
      <Citation number={146} />
      For that reason, “[n]o senator, representative, or other person holding a place of trust or profit under the United States, can be of the number of the electors.”
      <Citation number={147} />
    </Typography>

    <Typography className="indent">
      Article II of our Constitution, as modified by the Twelfth Amendment, governs election of the President. Article II created the electoral college, providing that the States would select electors in the manner provided by State legislatures, and those electors would in turn vote for the President. Today, every State selects Presidential electors by popular vote, and each State’s laws provide for procedures to resolve election disputes, including through lawsuits if necessary. After any election issues are resolved in State or Federal court, each State’s government transmits a certificate of the ascertainment of the appointed electors to Congress and the National Archives.
    </Typography>

    <Typography className="indent">
      The electoral college meets in mid-December to cast their votes, and all of these electoral votes are then ultimately counted by Congress on January 6th. The Vice President, as President of the Senate, presides over the joint session of Congress to count votes. The Twelfth Amendment provides this straight forward instruction: “The president of the Senate shall, in the presence of the Senate and House of Representatives, open all the certificates and the votes shall then be counted; The person having the greatest number of votes for President shall be the President...” The Vice President has only a ministerial role, opening the envelopes and ensuring that the votes are counted. Likewise, the Electoral Count Act of 1887 provides no substantive role for the Vice President in counting votes, reinforcing that he or she can only act in a ministerial fashion—the Vice President may not choose, for example, to decline to count particular votes. In most cases (e.g., when one candidate has a majority of votes submitted by the States) Congress has only a ministerial role, as well. It simply counts electoral college votes provided by each State’s governor. Congress is not a court and cannot overrule State and Federal court rulings in election challenges.
    </Typography>

    <Typography className="indent">
      As January 6th approached, John Eastman and others devised a plan whereby Vice President Pence would, as the presiding officer, declare that certain electoral votes from certain States could not be counted at the joint session.
      <Citation number={148} />
      John Eastman knew before proposing this plan that it was not legal. Indeed, in a pre-election document discussing Congress’s counting of electoral votes, Dr. Eastman specifically disagreed with a colleague’s proposed argument that the Vice President had the power to choose which envelopes to “open” and which votes to “count.” Dr. Eastman wrote:
    </Typography>

    <Blockquote>
      <Typography>
        I don’t agree with this. The 12th Amendment only says that the President of the Senate opens the ballots in the joint session then, in the passive voice, that the votes shall then be counted. 3 USC § 12 [of the Electoral Count Act] says merely that he is the presiding officer, and then it spells out specific procedures, presumptions, and default rules for which slates will be counted. Nowhere does it suggest that the president of the Senate gets to make the determination on his own. § 15 [of the Electoral Count Act] doesn’t either.
        <Citation number={149} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Despite recognizing prior to the 2020 election that the Vice President had no power to refuse to count certain electoral votes, Eastman nevertheless drafted memoranda two months later proposing that Pence could do exactly that on January 6th—refuse to count certified electoral votes from Arizona, Georgia, Michigan, Nevada, New Mexico, Pennsylvania and Wisconsin.
      <Citation number={150} />
    </Typography>

    <Blockquote>
      <Typography>
        Article II [of the Constitution] says the electors are appointed “in such manner as the Legislature thereof may direct,” but I don’t think that entitles the Legislature to change the rules after the election and appoint a different slate of electors in a manner different than what was in place on election day. And 3 U.S.C. §15 [of the Electoral Count Act] gives dispositive weight to the slate of electors that was certified by the Governor in accord with 3 U.S.C. §5.
        <Citation number={151} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Even after Eastman proposed the theories in his December and January memoranda, he acknowledged in conversations with Vice President Pence’s counsel Greg Jacob that Pence could not lawfully do what his own memoranda proposed.
      <Citation number={152} />
      Eastman admitted that the U.S. Supreme Court would unanimously reject his legal theory. “He [Eastman] had acknowledged that he would lose 9-0 at the Supreme Court.”
      <Citation number={153} />
      Moreover, Eastman acknowledged to Jacob that he didn’t think Vice President Al Gore had that power in 2001, nor did he think Vice President Kamala Harris should have that power in 2025.
      <Citation number={154} />
    </Typography>

    <Typography className="indent">
      In testimony before the Select Committee, Jacob described in detail why the Trump plan for Pence was illegal:
    </Typography>

    <Blockquote>
      <Typography>
        [T]he Vice President’s first instinct, when he heard this theory, was that there was no way that our Framers, who abhorred concentrated power, who had broken away from the tyranny of George III, would ever have put one person—particularly not a person who had a direct interest in the outcome because they were on the ticket for the election—in a role to have decisive impact on the outcome of the election. And our review of text, history, and, frankly, just common sense, all confirmed the Vice President’s first instinct on that point. There is no justifiable basis to conclude that the Vice President has that kind of authority.
        <Citation number={155} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      This is how the Vice President later described his views in a public speech:
    </Typography>

    <Blockquote>
      <Typography>
        I had no right to overturn the election. The Presidency belongs to the American people, and the American people alone. And frankly, there is no idea more un-American than the notion that any one person could choose the American President. Under the Constitution, I had no right to change the outcome of our election.
        <Citation number={156} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      But as January 6th approached, President Trump nevertheless embraced the new Eastman theories, and attempted to implement them. In a series of meetings and calls, President Trump attempted to pressure Pence to intervene on January 6th to prevent Congress from counting multiple States’ electoral votes for Joe Biden. At several points in the days before January 6th, President Trump was told directly that Vice President Pence could not legally do what Trump was asking. For example, at a January 4th meeting in the Oval Office, Eastman acknowledged that any variation of his proposal— whether rejecting electoral votes outright or delaying certification to send them back to the States—would violate several provisions of the Electoral Count Act. According to Greg Jacob:
    </Typography>

    <Blockquote>
      <Typography>
        In the conversation in the Oval Office on the 4th, I had raised the fact that... [Eastman’s] preferred course had issues with the Electoral Count Act, which he had acknowledged was the case, that there would be an inconsistency with the Electoral Count Act
        <Citation number={157} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Jacob recorded Eastman’s admission in an internal memo he drafted for Vice President Pence on the evening of January 4th: “Professor Eastman acknowledges that his proposal violates several provisions of statutory law.”
      <Citation number={158} />
      And, during a phone call with President Trump and Eastman on the evening of January 5, 2021, Eastman again acknowledged that his proposal also would violate several provisions of the Electoral Count Act.
    </Typography>

    <Blockquote>
      <Typography>
        [W]e did have an in-depth discussion about [the Electoral Count Act] in the subsequent phone calls as I walked him through provision after provision on the recess and on the fact that... Congressmen and Senators are supposed to get to object and debate. And he acknowledged, one after another, that those provisions would—in order for us to send it back to the States, we couldn’t do those things as well. We can’t do a 10-day, send it back to the States, and honor an Electoral Count Act provision that says you can’t recess for more than one day and, once you get to the 5th, you have to stay continuously in session.
        <Citation number={159} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      As Pence’s Chief of Staff, Marc Short, testified that the Vice President also repeatedly informed President Trump that the Vice President’s role on January 6th was only ministerial.
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: But just to pick up on that, Mr. Short, was it your impression that the Vice President had directly conveyed his position on these issues to the President, not just to the world through a Dear Colleague Letter, but directly to President Trump?
      </Typography>
      <Typography>
        <strong>Marc Short</strong>: Many times.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: And had been consistent in conveying his position to the President?
      </Typography>
      <Typography>
        <strong>Short</strong>: Very consistent.
        <Citation number={160} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      As the situation grew increasingly acrimonious, Vice President Pence’s private counsel Richard Cullen contacted former Fourth Circuit Judge Michael Luttig, a renowned conservative judge for whom Eastman had previously clerked, and asked Luttig to make a public statement. On January 5th, Luttig wrote the following on Twitter: “The only responsibility and power of the Vice President under the Constitution is to faithfully count the electoral college votes as they have been cast.”
      <Citation number={161} />
      As Judge Luttig testified in the Committee’s hearings, “there was no basis in the Constitution or laws of the United States at all for the theory espoused by Eastman—at all. None.”
      <Citation number={162} />
      Judge Luttig completely rejected Eastman’s “blueprint to overturn the 2020 election” as “constitutional mischief” and ‘the most reckless, insidious, and calamitous failure[ ] in both legal and political judgment in American history.”
      <Citation number={163} />
    </Typography>

    <Typography className="indent">
      Contemporaneous written correspondence also confirms both that: (1) Eastman himself recognized Pence could not lawfully refuse to count electoral votes, and (2) President Trump also knew this. While sheltering in a loading dock with the Vice President during the violent January 6th attack, Greg Jacob asked Eastman in an email, “Did you advise the President that in your professional judgment the Vice President DOES NOT have the power to decide things unilaterally?” Eastman’s response stated that the President had “been so advised,” but then indicated that President Trump continued to pressure the Vice President to act illegally: “But you know him—once he gets something in his head, it is hard to get him to change course.”
      <Citation number={164} />
    </Typography>

    <Typography className="indent">
      To be absolutely clear, no White House lawyer believed Pence could lawfully refuse to count electoral votes. White House Counsel Pat Cipollone told the Select Committee this:
    </Typography>

    <Blockquote>
      <Typography>
        I thought that the Vice President did not have the authority to do what was being suggested under a proper reading of the law. I conveyed that, ok? I think I actually told somebody, you know, in the Vice President’s—“Just blame me.” You know this is—I’m not a politician, you know... but, you know, I just said, “I’m a lawyer. This is my legal opinion.”
        <Citation number={165} />
      </Typography>
    </Blockquote>

    <Image
      src="/images/image-012.jpg"
      alt="Greg Jacob and Judge Michael Luttig testify at January 6th Select Committee hearing."
      caption="Greg Jacob and Judge Michael Luttig testify at January 6th Select Committee hearing."
      credit="(Photo by House Creative Services)"
    />

    <Typography className="indent">
      Cipollone also testified that he was “sure [he] conveyed” his views.
      <Citation number={166} />
      Indeed, other testimony from Cipollone indicates that Trump knew of Cipollone’s view and suggests that Trump purposely excluded Cipollone from the meeting with Pence and Pence’s General Counsel on January 4th.
      <Citation number={167} />
      Indeed, at one point, Cipollone confronted Eastman in the hallway outside the Oval Office and expressed his disapproval of and anger with Eastman’s position. According to Jason Miller, “Pat Cipollone thought the idea was nutty and had at one point confronted Eastman basically with the same sentiment” outside the Oval Office.
      <Citation number={168} />
      Pat Cipollone did not deny having an angry confrontation with Eastman outside of the Oval Office— though he said he didn’t have a specific recollection, he had no reason to contradict what Jason Miller said and, moreover, said that Eastman was aware of his views.
      <Citation number={169} />
    </Typography>

    <Typography className="indent">
      Likewise, Eric Herschmann, another White House lawyer, expressed the same understanding that Eastman’s plan “obviously made no sense” and “had no practical ability to work.”
      <Citation number={170} />
      Herschmann also recounted telling Eastman directly that his plan was “completely crazy”:
    </Typography>

    <Blockquote>
      <Typography>
        And I said to [Eastman], hold on a second, I want to understand what you’re saying. You’re saying you believe the Vice President, acting as President of the Senate, can be the sole decisionmaker as to, under your theory, who becomes the next President of the United States? And he said, yes. And I said, are you out of your F’ing mind, right. And that was pretty blunt. I said, you’re completely crazy.
        <Citation number={171} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Deputy White House Counsel Pat Philbin also had the same understanding.
      <Citation number={172} />
      Indeed, as Herschmann testified, even Rudolph Giuliani doubted that Vice President Mike Pence had any legal ability to do what Eastman had proposed.
      <Citation number={173} />
    </Typography>

    <Typography className="indent">
      Despite all this opposition from all White House lawyers, Trump nevertheless continued to exert immense pressure on Pence to refuse to count electoral votes.
    </Typography>

    <Typography className="indent">
      The pressure began before the January 4th Oval Office meeting with Pence, Eastman, Jacob, Short and Trump, but became even more intense thereafter. On the evening of January 5, 2021, the New York Times published an article reporting that “Vice President Mike Pence told President Trump on Tuesday that he did not believe he had the power to block congressional certification of Joseph R. Biden, Jr.’s victory in the Presidential election despite President Trump’s baseless insistence that he did.”
      <Citation number={174} />
      This reporting was correct—both as to the Vice President’s power and as to Vice President Pence having informed President Trump that he did not have the authority to change the outcome of the election. But in response to that story, late in the evening before the January 6th joint session, President Trump dictated to Jason Miller a statement falsely asserting, “The Vice President and I are in total agreement that the Vice President has the power to act.”
      <Citation number={175} />
      This statement was released at President Trump’s direction and was false.
      <Citation number={176} />
    </Typography>

    <Typography className="indent">
      Thereafter, Trump continued to apply public pressure in a series of tweets. At 1:00 a.m. on January 6th, “[i]f Vice President @Mike_Pence comes through for us, we will win the Presidency. Many States want to decertify the mistake they made in certifying incorrect & even fraudulent numbers in a process NOT approved by their State Legislatures (which it must be). Mike can send it back!”
      <Citation number={177} />
      At 8:17 a.m. on January 6th, he tweeted again: “States want to correct their votes, which they now know were based on irregularities and fraud, plus corrupt process never received legislative approval. All Mike Pence has to do is send them back to the States, AND WE WIN. Do it Mike, this is a time for extreme courage!”
      <Citation number={178} />
    </Typography>

    <Image
      src="/images/image-013.jpg"
      alt="President Trump speaks with Vice President Pence over the phone in the Oval Office on the morning of January 6th."
      caption="President Trump speaks with Vice President Pence over the phone in the Oval Office on the morning of January 6th."
      credit="(Photo provided to the Select Committee by the National Archives and Records Administration)"
    />

    <Typography className="indent">
      President Trump tried to reach the Vice President early in the morning of January 6th, but the Vice President did not take the call. The President finally reached the Vice President later that morning, shouting from the Oval Office to his assistants to “get the Vice President on the phone.”
      <Citation number={179} />
      After again telling the Vice President that he had “the legal authority to send [electoral votes] back to the respective states,” President Trump grew very heated.
      <Citation number={180} />
      Witnesses in the Oval Office during this call told the Select Committee that the President called Vice President Pence a “wimp,”
      <Citation number={181} />
      told him it would be “a political career killer” to certify the lawful electoral votes electing President Biden,
      <Citation number={182} />
      and accused him of “not [being] tough enough to make the call.”
      <Citation number={183} />
      As Ivanka Trump would recount to her chief of staff moments later, her father called the Vice President “the p-word” for refusing to overturn the election.
      <Citation number={184} />
    </Typography>

    <Typography className="indent">
      In response, Vice President Pence again refused to take any action other than counting the lawfully certified electoral votes of the States. But President Trump was angry and undeterred. After the conclusion of this call, he edited his speech for the Ellipse to insert language to which his lawyers objected—targeting Vice President Pence directly.
      <Citation number={185} />
    </Typography>

    <Typography className="indent">
      Earlier that morning, Eric Herschmann had tried to remove the reference to Vice President Pence from the speech. As he told speechwriter Stephen Miller, he “didn’t concur with the legal analysis” that John Eastman had advanced and believed it “wouldn’t advance the ball” to discuss it publicly.
      <Citation number={186} />
      But after the call with Vice President Pence, speechwriters were instructed to reinsert the line. Although the final written draft of his speech referred to Pence just once—a line President Trump didn’t end up reading
      <Citation number={187} />
      —the President went off-script five different times to pressure the Vice President:
    </Typography>

    <Blockquote>
      <Typography>
        “I hope Mike is going to do the right thing. I hope so. Because if Mike Pence does the right thing, we win the election,” Trump first told the crowd.
        <Citation number={188} />
      </Typography>
      <Typography>
        “Mike Pence is going to have to come through for us,” Trump later said, “and if he doesn’t, that will be a, a sad day for our country because you’re sworn to uphold our Constitution.”
        <Citation number={189} />
      </Typography>
      <Typography>
        Addressing Pence directly, Trump told the assembled crowd: “Mike Pence, I hope you’re going to stand up for the good of our Constitution and for the good of our country.” Trump said at another point, “And if you’re not, I’m going to be very disappointed in you. I will tell you right now. I’m not hearing good stories.”
        <Citation number={190} />
      </Typography>
      <Typography>
        “So I hope Mike has the courage to do what he has to do. And I hope he doesn’t listen to the RINOs and the stupid people that he’s listening to,” Trump said.
        <Citation number={191} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      These statements to the assembled crowd at the Ellipse had Trump’s intended effect—they produced substantial anger against Pence. When Pence released a statement confirming that he would not act to prevent Congress from counting electoral votes, the crowd’s reaction was harshly negative.
    </Typography>

    <Blockquote>
      <Typography>
        “I’m telling you what, I’m hearing that Pence—hearing the Pence just caved. No. Is that true? I didn’t hear it. I’m hear — I’m hearing reports that Pence caved. No way. I’m telling you, if Pence caved, we’re going to drag motherfuckers through the streets. You fucking politicians are going to get fucking drug through the streets.”
        <Citation number={192} />
      </Typography>
      <Typography>
        Pence voted against Trump. [Interviewer: “Ok. And that’s when all this started?”] Yup. That’s when we marched on the Capitol.
        <Citation number={193} />
      </Typography>
      <Typography>
        “We just heard that Mike Pence is not going to reject any fraudulent electoral votes. [Other speaker: “Boo. You’re a traitor!”] That's right. You’ve heard it here first. Mike Pence has betrayed the United States of America. [Other speaker: “Fuck you, Mike Pence!”] Mike Pence has betrayed this President and he has betrayed the people of the United States and we will never, ever forget.” [Cheers]
        <Citation number={194} />
      </Typography>
      <Typography>
        “This woman cames [<i>sic</i>] up to the side of us and she says Pence folded. So it was kind of, like, Ok, well — in my mind I was thinking, well that’s it. You know. Well, my son-in-law looks at me and he says I want to go in.”
        <Citation number={195} />
      </Typography>
      <Typography>
        <strong>[Q]</strong>: “What percentage of the crowd is going to the Capitol?”
        <br />
        <strong>[A]</strong>: [Oath Keeper Jessica Watkins]: “One hundred percent. It has, it has spread like wildfire that Pence has betrayed us, and everybody’s marching on the Capitol. All million of us. it’s insane.”
        <Citation number={196} />
      </Typography>
      <Typography>
        “Bring him out. Bring out Pence. Bring him out. Bring out Pence. Bring him out. Bring out Pence. Bring him out. Bring out Pence.”
        <Citation number={197} />
      </Typography>
      <Typography>
        “Hang Mike Pence. Hang Mike Pence. Hang Mike Pence. Hang Mike Pence. Hang Mike Pence.”
        <Citation number={198} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Once Trump returned to the White House, he was informed almost immediately that violence and lawlessness had broken out at the Capitol among his supporters.
      <Citation number={199} />
      At 2:24 p.m., President Trump applied yet further pressure to Pence (see infra), posting a tweet accusing Vice President Mike Pence of cowardice for not using his role as President of the Senate to change the outcome of the election: “Mike Pence didn’t have the courage to do what should have been done to protect our Country and our Constitution, giving States a chance to certify a corrected set of facts, not the fraudulent or inaccurate ones which they were asked to previously certify. USA demands the truth!”
      <Citation number={200} />
      Almost immediately thereafter, the crowd around the Capitol surged, and more individuals joined the effort to confront police and break further into the building.
    </Typography>

    <Typography className="indent">
      The sentiment expressed in President Trump’s 2:24 p.m. tweet, already present in the crowd, only grew more powerful as the President’s words spread. Timothy Hale-Cusanelli—a white supremacist who expressed Nazi sympathies—heard about the tweet while in the Crypt around 2:25 p.m., and he, according to the Department of Justice, “knew what that meant.” Vice President Pence had decided not to keep President Trump in power.
      <Citation number={201} />
      Other rioters described what happened next as follows:
    </Typography>

    <Blockquote>
      <Typography>
        Once we found out Pence turned on us and that they had stolen the election, like officially, the crowd went crazy. I mean, it became a mob. We crossed the gate.
        <Citation number={202} />
      </Typography>
      <Typography>
        Then we heard the news on [P]ence... And lost it... So we stormed.
        <Citation number={203} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      They’re making an announcement right now saying if Pence betrays us you better get your mind right because we’re storming that building.
      <Citation number={204} />
    </Typography>

    <Typography className="indent">
      Minutes after the tweet—at 2:35 p.m.—rioters continued their surge and broke a security line of the DC Metropolitan Police Department, resulting in the first fighting withdrawal in the history of that force.
      <Citation number={205} />
    </Typography>

    <Typography className="indent">
      President Trump issued this tweet after he had falsely claimed to the angry crowd that Vice President Mike Pence could “do the right thing” and ensure a second Trump term, after that angry crowd had turned into a violent mob assaulting the Capitol while chanting, “Hang Mike Pence!”
      <Citation number={206} />
      and after the U.S. Secret Service had evacuated the Vice President from the Senate floor.
      <Citation number={207} />
      One minute after the President’s tweet, at 2:25 p.m., the Secret Service determined they could no longer protect the Vice President in his ceremonial office near the Senate Chamber, and evacuated the Vice President and his family to a secure location, missing the violent mob by a mere 40 feet.
      <Citation number={208} />
    </Typography>

    <Typography className="indent">
      Further evidence presented at our hearing shows the violent reaction following President Trump’s 2:24 p.m. tweet and the efforts to protect Vice President Pence in the time that followed.
      <Citation number={209} />
    </Typography>

    <Typography className="indent">
      The day after the attack on the Capitol, Eastman called Eric Herschmann to talk about continuing litigation on behalf of the Trump Presidential Campaign in Georgia. Herschmann described his reaction to Eastman this way:
    </Typography>

    <Blockquote>
      <Typography>
        And I said to him, are you out of your F'ing mind? Right? I said, because I only want to hear two words coming out of your mouth from now on: Orderly transition. I said, I don't want to hear any other F'ing words coming out of your mouth, no matter what, other than orderly transition. Repeat those words to me.”
        <Citation number={210} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Herschmann concluded the call by telling Eastman: “Now I’m going to give you the best free legal advice you’re ever getting in your life. Get a great F’ing criminal defense lawyer, you’re going to need it,” and hanging up the phone.
      <Citation number={211} />
    </Typography>

    <Typography className="indent">
      In the course of investigating this series of facts, the Select Committee subpoenaed Eastman’s emails from his employer, Chapman University.
      <Citation number={212} />
      Eastman sued to prevent Chapman from producing the emails, arguing that the emails were attorney-client privileged. Federal District Court Judge David Carter reviewed Eastman’s emails in camera to determine, among other things, whether the emails had to be produced because they likely furthered a crime committed by one of Eastman’s clients or by Eastman himself. In addition to reviewing the emails themselves, Judge Carter reviewed substantial additional evidence presented by the Select Committee and by Eastman.
    </Typography>

    <Typography className="indent">
      After reciting a series of factual findings regarding President Trump’s multi-part plan to overturn the election, Judge Carter concluded that President Trump likely violated two criminal statutes: 18 U.S.C. § 1512(c) (corruptly obstructing, impeding or influencing Congress’s official proceeding to count electoral votes); and 18 U.S.C. § 371 (conspiring to defraud the United States). The Court also concluded that John Eastman likely violated at least one of these criminal laws. As to §1512(c), Judge Carter explained:
    </Typography>

    <Blockquote>
      <Typography>
        Taken together, this evidence demonstrates that President Trump likely knew the electoral count plan had no factual justification.
      </Typography>
      <Typography>
        The plan not only lacked factual basis but also legal justification....
      </Typography>
      <Typography>
        The illegality of the plan was obvious. Our nation was founded on the peaceful transition of power, epitomized by George Washington laying down his sword to make way for democratic elections. Ignoring this history, President Trump vigorously campaigned for the Vice President to single-handedly determine the results of the 2020 election.... Every American—and certainly the President of the United States—knows that in a democracy, leaders are elected, not installed. With a plan this “BOLD,” President Trump knowingly tried to subvert this fundamental principle. Based on the evidence, the Court finds it more likely than not that President Trump corruptly attempted to obstruct the Joint Session of Congress on January 6, 2021.
        <Citation number={213} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      As to 18 U.S.C. § 371, Judge Carter identified evidence demonstrating that both President Trump and John Eastman knew their electoral count plan was illegal, and knew it could not “survive judicial scrutiny” in any of its iterations:
    </Typography>

    <Blockquote>
      <Typography>
        Dr. Eastman himself repeatedly recognized that his plan had no legal support.... Dr. Eastman likely acted deceitfully and dishonestly each time he pushed an outcome-driven plan that he knew was unsupported by the law.
        <Citation number={214} />
      </Typography>
      <Typography>
        Finally, Judge Carter concluded:
      </Typography>
      <Typography>
        Dr. Eastman and President Trump launched a campaign to overturn a democratic election, an action unprecedented in American history. Their campaign was not confined to the ivory tower—it was a coup in search of a legal theory. The plan spurred violent attacks on the seat of our nation’s government, led to the deaths of several law enforcement officers, and deepened public distrust in our political process.
        <Citation number={215} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Judge Luttig reached similar conclusions during his live hearing testimony: “I have written, as you said, Chairman Thompson, that, today, almost two years after that fateful day in January 2021, that, still, Donald Trump and his allies and supporters are a clear and present danger to American democracy.”
      <Citation number={216} />
    </Typography>

    <Typography className="indent">
      During the hearing, Judge Luttig took issue with certain of Greg Jacob’s characterizations of the 12th Amendment’s text, explaining that the applicable text was not ambiguous in any way. The Committee agrees with Judge Luttig: the application of the Twelfth Amendment’s text is plain in this context; it does not authorize Congress to second-guess State and Federal courts and refuse to count State electoral votes based on concerns about fraud. See infra. Although Jacob did not discuss his position in great detail during the hearing, his private testimony gives more insight on his actual views:
    </Typography>

    <Blockquote>
      <Typography>
        In my view, a lot has been said about the fact that the role of the Vice President in the electoral count on January 6th is purely ministerial, and that is a correct conclusion. But if you look at the constitutional text, the role of Congress is purely ministerial as well. You open the certificates and you count them. Those are the only things provided for in the Constitution.
        <Citation number={217} />
      </Typography>
    </Blockquote>
  </Page>
);
