import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet2'));

export const FailureToComply = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      During the course of the Select Committee’s investigation of President Trump’s efforts to subvert the election, the Committee learned that various Members of Congress had information relevant to the investigation. Accordingly, the Committee wrote letters to a number of Members involved in that activity inviting them to participate voluntarily in the Select Committee’s investigation. None of the members was willing to provide information, which forced the Select Committee to consider alternative means of securing evidence about the conduct of these Members and the information they might have. On May 12, 2022, the Select Committee subpoenaed several members of Congress—including House Minority Leader Kevin McCarthy, Representative Jim Jordan, Representative Scott Perry, and Representative Andy Biggs—to obtain information related to the Committee’s investigation.
    </Typography>

    <Typography className="indent">
      This was a significant step, but it was one that was warranted by the certain volume of information these Members possessed that was relevant to the Select Committee’s investigation, as well as the centrality of their efforts to President Trump’s multi-part plan to remain in power.
    </Typography>

    <Typography className="indent">
      Representative McCarthy, among other things, had multiple communications with President Trump, Vice President Pence, and others on and related to January 6th. For example, during the attack on the Capitol, Representative McCarthy urgently requested that the former President issue a statement calling off the rioters, to which President Trump responded by “push[ing] back” and said: “Well, Kevin, I guess these people are more upset about the election than you are.”
      <Citation number={658} />
      And, after the attack, Representative McCarthy spoke on the House floor and said that, “[t]here is absolutely no evidence” that Antifa caused the attack on the Capitol and instead called on President Trump to “accept his share of responsibility” for the violence.
      <Citation number={659} />
      As noted above, Representative McCarthy privately confided in colleagues that President Trump accepted some responsibility for the attack on the Capitol.
      <Citation number={660} />
    </Typography>

    <Typography className="indent">
      Representative Jordan was a significant player in President Trump’s efforts. He participated in numerous post-election meetings in which senior White House officials, Rudolph Giuliani, and others, discussed strategies for challenging the election, chief among them claims that the election had been tainted by fraud. On January 2, 2021, Representative Jordan led a conference call in which he, President Trump, and other Members of Congress discussed strategies for delaying the January 6th joint session. During that call, the group also discussed issuing social media posts encouraging President Trump’s supporters to “march to the Capitol” on the 6th.
      <Citation number={661} />
      An hour and a half later, President Trump and Representative Jordan spoke by phone for 18 minutes.
      <Citation number={662} />
      The day before January 6th, Representative Jordan texted Mark Meadows, passing along advice that Vice President Pence should “call out all the electoral votes that he believes are unconstitutional as no electoral votes at all.”
      <Citation number={663} />
      He spoke with President Trump by phone at least twice on January 6th, though he has provided inconsistent public statements about how many times they spoke and what they discussed.
      <Citation number={664} />
      He also received five calls from Rudolph Giuliani that evening, and the two connected at least twice, at 7:33 p.m. and 7:49 p.m.
      <Citation number={665} />
      During that time, Giuliani has testified, he was attempting to reach Members of Congress after the joint session resumed to encourage them to continue objecting to Joe Biden’s electoral votes.
      <Citation number={666} />
      And, in the days following January 6th, Representative Jordan spoke with White House staff about the prospect of Presidential pardons for Members of Congress.
      <Citation number={667} />
    </Typography>

    <Typography className="indent">
      Like Representative Jordan, Representative Perry was also involved in early post-election messaging strategy. Both Representative Jordan and Representative Perry were involved in discussions with White House officials about Vice President Pence’s role on January 6th as early as November 2020.
      <Citation number={668} />
      Representative Perry was present for conversations in which the White House Counsel’s Office informed him and others that President Trump’s efforts to submit fake electoral votes were not legally sound.
      <Citation number={669} />
      But perhaps most pivotally, he was involved in President Trump’s efforts to install Jeffrey Clark as the Acting Attorney General in December 2020 and January 2021. Beginning in early December 2020, Representative Perry suggested Clark as a candidate to Mark Meadows,
      <Citation number={670} />
      then introduced Clark to President Trump.
      <Citation number={671} />
      In the days before January 6th, Representative Perry advocated for President Trump to speak at the Capitol during the joint session, speaking to Mark Meadows on at least one occasion about it.
      <Citation number={672} />
      He was also a participant in the January 2, 2021, call in which Representative Jordan, President Trump, and others discussed issuing social media posts to encourage Trump supporters to march to the Capitol on January 6th.
      <Citation number={673} />
      After January 6th, Representative Perry reached out to White House staff asking to receive a Presidential pardon.
      <Citation number={674} />
    </Typography>

    <Typography className="indent">
      Representative Biggs was involved in numerous elements of President Trump’s efforts to contest the election results. As early as November 6, 2020, Representative Biggs texted Mark Meadows, urging him to “encourage the state legislatures to appoint [electors].”
      <Citation number={675} />
      In the following days, Representative Biggs told Meadows not to let President Trump concede his loss.
      <Citation number={676} />
      Between then and January 6th, Representative Biggs coordinated with Arizona State Representative Mark Finchem to gather signatures from Arizona lawmakers endorsing fake Trump electors.
      <Citation number={677} />
      He also contacted fake Trump electors in at least one State seeking evidence related to voter fraud.
      <Citation number={678} />
    </Typography>

    <Typography className="indent">
      To date, none of the subpoenaed Members has complied with either voluntary or compulsory requests for participation.
    </Typography>

    <Typography className="indent">
      Representative McCarthy initially responded to the Select Committee’s subpoena in two letters on May 27 and May 30, 2022, in which he objected to the Select Committee’s composition and validity of the subpoena and offered to submit written interrogatories in lieu of deposition testimony. Although the Select Committee did not release Representative McCarthy from his subpoena obligations, Representative McCarthy failed to appear for his scheduled deposition on May 31, 2022. The Select Committee responded to Representative McCarthy’s letters this same day, rejecting his proposal to participate via written interrogatories and compelling his appearance for deposition testimony no later than June 11, 2022. Although Representative McCarthy again responded via letter on June 9, 2022, he did not appear for deposition testimony on or before the specified June 11, 2022, deadline.
    </Typography>

    <Typography className="indent">
      Representative Jordan also responded to the Select Committee’s subpoena just before his scheduled deposition in a letter on May 25, 2022, containing a variety of objections. Representative Jordan also requested material from the Select Committee, including all materials referencing him in the Select Committee’s possession and all internal legal analysis related to the constitutionality of Member subpoenas. Although the Select Committee did not release Representative Jordan from his subpoena obligations, Representative Jordan failed to appear for his scheduled deposition on May 27, 2022. On May 31, 2022, the Select Committee responded to the substance of Representative Jordan’s May 25th letter and indicated that Representative Jordan should appear for deposition testimony no later than June 11, 2022. On June 9, 2022, Representative Jordan again wrote to reiterate the points from his May 25th letter. That same day, Representative Jordan sent out a fundraising email with the subject line: “I’VE BEEN SUBPOENED.”
      <Citation number={679} />
      Representative Jordan did not appear before the Select Committee on or before the June 11, 2022, deadline.
    </Typography>

    <Typography className="indent">
      Representative Perry likewise responded to the Select Committee’s subpoena on May 24, 2022, in a letter, “declin[ing] to appear for deposition” and requesting that the subpoena be “immediately withdrawn.”
      <Citation number={680} />
      Although the Select Committee did not release Representative Perry from his subpoena obligations, Representative Perry failed to appear on May 26, 2022, for his scheduled deposition. Representative Perry sent a second letter to the Select Committee on May 31, 2022, with additional objections. That same day, the Select Committee responded to Representative Perry’s letters and stated that he should appear before the Select Committee no later than June 11, 2022, for deposition testimony. Representative Perry responded via letter on June 10, 2022, maintaining his objections. He did not appear before the June 11, 2022, deadline.
    </Typography>

    <Typography className="indent">
      Representative Biggs issued a press release on the day the Select Committee issued its subpoena, calling the subpoena “illegitimate” and “pure political theater.” The day before his scheduled deposition, Representative Biggs sent a letter to the Select Committee with a series of objections and an invocation of Speech or Debate immunity. Although the Select Committee did not release Representative Biggs from his subpoena obligations, Representative Biggs did not appear for his scheduled deposition on May 26, 2022. On May 31, 2022, the Select Committee responded to the substance of Representative Biggs’ May 25th letter and indicated that Representative Biggs should appear for deposition testimony no later than June 11, 2022. Although Representative Biggs responded with another letter on June 9th, he did not appear before the June 11, 2022, deadline.
    </Typography>

    <Typography className="indent">
      Despite the Select Committee’s repeated attempts to obtain information from these Members and the issuance of subpoenas, each has refused to cooperate and failed to comply with a lawfully issued subpoena. Accordingly, the Select Committee is referring their failure to comply with the subpoenas issued to them to the Ethics Committee for further action. To be clear, this referral is only for failure to comply with lawfully issued subpoenas.
    </Typography>

    <Typography className="indent">
      The Rules of the House of Representatives make clear that their willful noncompliance violates multiple standards of conduct and subjects them to discipline. Willful non-compliance with compulsory congressional committee subpoenas by House Members violates the spirit and letter of House rule XXIII, clause 1, which requires House Members to conduct themselves “at all times in a manner that shall reflect creditably on the House.” As a previous version of the House Ethics Manual explained, this catchall provision encompasses “‘flagrant’ violations of the law that reflect on ‘Congress as a whole,’ and that might otherwise go unpunished.”
      <Citation number={681} />
      The subpoenaed House Members’ refusal to comply with their subpoena obligations satisfies these criteria. A House Member’s willful failure to comply with a congressional subpoena also reflects discredit on Congress. If left unpunished, such behavior undermines Congress’s longstanding power to investigate in support of its lawmaking authority and suggests that Members of Congress may disregard legal obligations that apply to ordinary citizens.
    </Typography>

    <Typography className="indent">
      For these reasons, the Select Committee refers Leader McCarthy and Representatives Jordan, Perry, and Biggs for sanction by the House Ethics Committee for failure to comply with subpoenas. The Committee also believes that each of these individuals, along with other Members who attended the December 21st planning meeting with President Trump at the White House,
      <Citation number={682} />
      should be questioned in a public forum about their advance knowledge of and role in President Trump’s plan to prevent the peaceful transition of power.
    </Typography>
  </Page>
)
