import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet6'));

export const Chapter46 = ({ ...args }: any) => (
  <Page {...args}>
    <Image
      src="/images/image-058.jpg"
      alt="Former Assistant Attorney General Jeffrey Clark appears on a screen during a Select Committee hearing on June 23, 2022."
      caption="Former Assistant Attorney General Jeffrey Clark appears on a screen during a Select Committee hearing on June 23, 2022."
      credit="(Photo by Mandel Ngan-Pool/Getty Images)"
      style={{ marginTop: 0 }}
    />

    <Typography className="indent">
      On December 21, 2020, 11 House Republicans met with President Trump at the White House to discuss their plans for objecting to the certification of the electoral college vote on January 6th.
      <Citation number={97} />
      After the meeting, Mark Meadows tweeted: “Several members of Congress just finished a meeting in the Oval Office with @realDonaldTrump preparing to fight back against mounting evidence of voter fraud. Stay tuned.”
      <Citation number={98} />
      Among those in attendance was Congressman Scott Perry, a Republican from Pennsylvania.
      <Citation number={99} />
    </Typography>

    <Typography className="indent">
      By the next day, Representative Perry had introduced a little-known DOJ official named Jeffrey Clark to the President.
      <Citation number={100} />
      At the time, Clark was the Acting Head of the Civil Division and Head of the Environmental and Natural Resources Division at the Department of Justice.
      <Citation number={101} />
      Clark had no experience in, or responsibilities related to, investigating allegations of election fraud.
    </Typography>

    <Typography className="indent">
      President Trump called Acting Attorney General Rosen “virtually every day” between December 23rd and January 3rd.
      <Citation number={102} />
      The President usually discussed his “dissatisfaction” with DOJ, claiming the Department was not doing enough to investigate election fraud.
      <Citation number={103} />
      On Christmas Eve, Trump brought up Jeffrey Clark’s name. Rosen found it “peculiar,” telling the Select Committee: “I was quizzical as to how does the President even know Mr. Clark?”
      <Citation number={104} />
    </Typography>

    <Typography className="indent">
      Rosen then spoke directly with Clark on December 26th.
      <Citation number={105} />
      Clark revealed that he had met with the President in the Oval Office several days prior.
      <Citation number={106} />
      Clark had told the President that if he were to change the leadership at the Department of Justice, “then the Department might be able to do more” to support the President’s claims that the election had been stolen from him.
      <Citation number={107} />
    </Typography>

    <Typography className="indent">
      In his discussion with Acting Attorney General Rosen, Clark was “defensive” and “apologetic,” claiming that the meeting with President Trump was “inadvertent and it would not happen again, and that if anyone asked him to go to such a meeting, he would notify Rich Donoghue and me [Rosen].”
      <Citation number={108} />
      Of course, Clark had good reasons to be defensive. His meeting with President Trump and Representative Perry on December 22nd was a clear violation of Department policy, which limits interactions between the White House and the Department’s staff. As Steven Engel, former Assistant Attorney General for the Office of Legal Counsel, explained to the Select Committee, “it’s critical that the Department of Justice conducts its criminal investigations free from either the reality or any appearance of political interference.”
      <Citation number={109} />
      For that reason, the Department has longstanding polices in place, across administrations, to “keep these communications as infrequent and at the highest levels as possible, just to make sure that people who are less careful about it, who don't really understand these implications, such as Mr. Clark, don’t run afoul of the of those contact policies.”
      <Citation number={110} />
      Rosen added that only the Attorney General or Deputy Attorney General “can have conversations about criminal matters with the White House,” or they can “authorize” someone from within DOJ to do so.
      <Citation number={111} />
      Clark had no such authorization.
    </Typography>

    <Typography className="indent">
      Representative Perry continued to advocate on Clark’s behalf. The Congressman texted Meadows on December 26th, writing: “Mark, just checking in as time continues to count down. 11 days to 1/6 and 25 days to inauguration. We gotta get going!”
      <Citation number={112} />
      Representative Perry followed up: “Mark, you should call Jeff. I just got off the phone with him and he explained to me why the principal deputy [position] won’t work especially with the FBI. They will view it as not having the authority to enforce what needs to be done.”
      <Citation number={113} />
      Meadows responded: “I got it. I think I understand. Let me work on the deputy position.”
      <Citation number={114} />
      Representative Perry then sent additional texts: “Roger. Just sent you something on Signal”, “Just sent you an updated file”, and “Did you call Jeff Clark?”
      <Citation number={115} />
    </Typography>
  </Page>
)
