import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet8'));

export const Chapter611 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      In the days following President Trump’s tweet, rally organizers secured permits for about one dozen events in Washington, DC on January 5th and 6th.
      <Citation number={372} />
      At 7:12 a.m., not even 6 hours after President Trump’s tweet, Cindy Chafian, an executive at Women for America First (WFAF), emailed the National Park Service (NPS) about an event that had been planned to coincide with President-elect Biden’s inauguration on January 20, 2021.
      <Citation number={373} />
      Chafian’s ask was simple: “Can I change the date to January 6th?”
      <Citation number={374} />
    </Typography>

    <Typography className="indent">
      WFAF was founded in 2019 by Amy and Kylie Kremer, a mother-daughter pair who were longtime supporters of the President.
      <Citation number={375} />
      WFAF became a significant player in the “Stop the Steal” movement.
      <Citation number={376} />
      The Kremers started a “Stop the Steal” Facebook group that gathered some 365,000 members in less than 24 hours.
      <Citation number={377} />
      Their online organizing coincided with their on-the-ground mobilization activities. The Kremers organized a bus tour to promote the Big Lie, in addition to events in Washington, DC on November 14, 2020, and December 12, 2020.
      <Citation number={378} />
      After President Trump’s December 19th tweet, the Kremers focused on January 6th. Kylie Kremer proudly declared their support on Twitter: “The calvary [<i>sic</i>] is coming, President! JANUARY 6th | Washington, DC TrumpMarch.com #MarchForTrump #StopTheSteal.”
      <Citation number={379} />
      After the date of their permit was revised, WFAF ultimately provided President Trump the stage on the Ellipse where he would direct the crowd to march on the Capitol.
      <Citation number={380} />
    </Typography>

    <Typography className="indent">
      The Kremers were not alone in responding quickly to the President’s tweet. Ali Alexander, the founder of Stop the Steal, LLC,
      <Citation number={381} />
      was eager to get ahead of other organizers. On the morning of December 19th, Alexander told his event planner, “Everyone is trying to get the jump on us so I’d like to get the court side of the capitol (lawn) and I’d like to get capitol steps and court.”
      <Citation number={382} />
      Alexander told his event planner to “grab whatever we can. All of it.”
      <Citation number={383} />
      Alexander’s team did just that: they registered and launched a new website, WildProtest.com,
      <Citation number={384} />
      which advertised planned events for January 6th under a banner that read: “President Trump Wants You in DC January 6.”
      <Citation number={385} />
    </Typography>

    <Typography className="indent">
      Still other organizers were quick to seize on the President’s tweet. Arina Grossu and Robert Weaver, co-founders of the self-proclaimed “Judeo-Christian” Jericho March organization,
      <Citation number={386} />
      held a rally in Washington, DC on December 12, 2020. Oath Keepers leader Stewart Rhodes, Flynn, Jones, Alexander, and others shared a stage at that event.
      <Citation number={387} />
      Grossu and Weaver exchanged emails just a few hours after President Trump’s first mention of January 6th. In an email on the morning of December 19th, Weaver told Grossu to “enjoy the peace before the storm” and said, “Trump has called for a protest on 1/6, FYI.”
      <Citation number={388} />
      The Jericho March’s website used President Trump’s “Be there, will be wild!” language to advertise additional events between January 2nd and January 6, 2021.
      <Citation number={389} />
    </Typography>

    <Typography className="indent">
      Marsha Lessard, the leader of a vaccine-skeptic group, Virginia Freedom Keepers, worked to stage an event with Bianca Gracia, the leader of Latinos for Trump on January 6th.
      <Citation number={390} />
      The women had ties to the Oath Keepers
      <Citation number={391} />
      and Proud Boys,
      <Citation number={392} />
      respectively—two groups central to the violence on January 6. Latinos for Trump reportedly advertised their January 6th event with the same QAnon-inspired banner, “Operation Occupy the Capitol.”
      <Citation number={393} />
      Another conservative group, Moms for America, worked with Alexander before securing a permit for an event on January 5th.
      <Citation number={394} />
    </Typography>
  </Page>
)
