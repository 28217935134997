import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';
import { StarDivider } from '../../../components/StarDivider';

const Citation = lazy(() => import('../../Citations/CitationSheet2'));

export const EffortsToCorrupt = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      In the weeks after the 2020 election, Attorney General Barr advised President Trump that the Department of Justice had not seen any evidence to support Trump’s theory that the election was stolen by fraud. Acting Attorney General Jeffrey Rosen and his Deputy repeatedly reinforced to President Trump that his claims of election fraud were false when they took over in mid-December. Also in mid-December 2020, Attorney General Barr announced his plans to resign. Between that time and January 6th, Trump spoke with Acting Attorney General Jeff Rosen and Acting Deputy Richard Donoghue repeatedly, attempting to persuade them and the Department of Justice to find factual support for his stolen election claims and thereby to assist his efforts to reverse election results.
    </Typography>

    <Typography className="indent">
      As Rosen publicly testified, “...between December 23rd and January 3rd, the President either called me or met with me virtually every day, with one or two exceptions, like Christmas Day.”
      <Citation number={267} />
      As discussed earlier, Justice Department investigations had demonstrated that the stolen election claims were false; both Rosen and Donoghue told President Trump this comprehensively and repeatedly.
    </Typography>

    <Typography className="indent">
      One of those conversations occurred on December 27th, when President Trump called Rosen to go through a “stream of allegations” about the election.
      <Citation number={268} />
      Donoghue described that call as an “escalation of the earlier conversations” they had.
      <Citation number={269} />
      Initially, President Trump called Rosen directly. When Donoghue joined the call, he sought to “make it clear to the President [that] these allegations were simply not true.”
      <Citation number={270} />
    </Typography>

    <Blockquote>
      <Typography>
        So [the President] went through [the allegations]—in what for me was a 90-minute conversation or so, and what for the former Acting AG was a 2-hour conversation—as the President went through them I went piece by piece to say “no, that’s false, that is not true,” and to correct him really in a serial fashion as he moved from one theory to another.
        <Citation number={271} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The President raised, among others, debunked claims about voting machines in Michigan, a truck driver who allegedly moved ballots from New York to Pennsylvania, and a purported election fraud at the State Farm Arena in Georgia.
      <Citation number={272} />
      None of the allegations were credible, and Rosen and Donoghue said so to the President.
      <Citation number={273} />
    </Typography>

    <Typography className="indent">
      At one point during the December 27th call in which Donoghue refuted President Trump’s fraud allegations, Donoghue recorded in handwritten notes a request President Trump made specifically to him and Acting Attorney General Rosen: “Just say the election was corrupt and leave the rest to me and the Republican Congressmen.”
      <Citation number={274} />
      Donoghue explained: “[T]he Department had zero involvement in anyone’s political strategy,” and “he wanted us to say that it was corrupt.”
      <Citation number={275} />
      “We told him we were not going to do that.”
      <Citation number={276} />
      At the time, neither Rosen nor Donoghue knew the full extent to which Republican Congressmen, including Representative Scott Perry, were attempting to assist President Trump to overturn the election results.
    </Typography>

    <Typography className="indent">
      The Committee’s investigation has shown that Congressman Perry was working with one Department of Justice official, Jeffrey Clark, regarding the stolen election claims. Perry was working with Clark and with President Trump and Chief of Staff Mark Meadows with this goal: to enlist Clark to reverse the Department of Justice’s findings regarding the election and help overturn the election outcome.
      <Citation number={277} />
    </Typography>

    <Typography className="indent">
      After introducing Clark to the President, Perry sent multiple text messages to Meadows between December 26th and December 28th, pressing that Clark be elevated within the Department. Perry reminded Meadows that there are only “11 days to 1/6.... We gotta get going!,” and, as the days went on, one asking, “Did you call Jeff Clark?”
      <Citation number={278} />
    </Typography>

    <Typography className="indent">
      Acting Attorney General Rosen first learned about Clark’s contact with President Trump in a call on Christmas Eve. On that call, President Trump mentioned Clark to Rosen, who was surprised to learn that Trump knew Clark and had met with him. Rosen later confronted Clark about the contact: “Jeff, anything going on that you think I should know about?”
      <Citation number={279} />
      Clark didn’t “immediately volunteer” the fact that he had met with the President, but ultimately “acknowledged that he had been at a meeting with the President in the Oval Office, not alone, with other people.”
      <Citation number={280} />
      Clark was “kind of defensive” and “somewhat apologetic,” “casting it as that he had had a meeting with Congressman Perry from Pennsylvania and that, to his surprise, or, you know, he hadn’t anticipated it, that they somehow wound up at a meeting in the Oval Office.”
      <Citation number={281} />
      Clark’s contact with President Trump violated both Justice Department and White House policies designed to prevent political pressure on the Department.
      <Citation number={282} />
    </Typography>

    <Typography className="indent">
      While Clark initially appeared apologetic and assured Rosen that “[i]t won’t happen again,”
      <Citation number={283} />
      he nevertheless continued to work and meet secretly with President Trump and Congressman Perry. Less than five days after assuring Rosen that he would comply with the Department’s White House contacts policy, Clark told Rosen and Donoghue that he had again violated that policy. Donoghue confronted him: “I reminded him that I was his boss and that I had directed him to do otherwise.”
      <Citation number={284} />
    </Typography>

    <Typography className="indent">
      Around the same time, Representative Perry called Acting Deputy Attorney General Donoghue, criticized the FBI, and suggested that the Department hadn’t been doing its job. Perry told Donoghue that Clark “would do something about this.”
      <Citation number={285} />
    </Typography>

    <Typography className="indent">
      On December 28th, Clark worked with a Department employee named Kenneth Klukowski—a political appointee who had earlier worked with John Eastman—to produce a draft letter from the Justice Department to the State legislature of Georgia.
      <Citation number={286} />
      That letter mirrored a number of the positions President Trump and Eastman were taking at the time.
      <Citation number={287} />
      (Although both Clark and Eastman refused to answer questions by asserting their Fifth Amendment right against self-incrimination, evidence shows that Clark and Eastman were in communication in this period leading up to January 6th.
      <Citation number={288} />
      The draft letter to Georgia was intended to be one of several Department letters to State legislatures in swing States that had voted for Biden.
      <Citation number={289} />
    </Typography>

    <Typography className="indent">
      The letter read: “The Department of Justice is investigating various irregularities in the 2020 election for President of the United States.”
      <Citation number={290} />
      Clark continued: “The Department will update you as we are able on investigatory progress, but at this time we have identified significant concerns that may have impacted the outcome of the election in multiple States, including the State of Georgia.”
      <Citation number={291} />
      This was <i>affirmatively untrue</i>. The Department had conducted many investigations of election fraud allegations by that point, but it absolutely did not have “significant concerns” that fraud “may have impacted the outcome of the election” in any State. Jeff Clark knew this; Donoghue confirmed it again in an email responding to Clark’s letter: “[W]e simply do not currently have a basis to make such a statement. Despite dramatic claims to the contrary, we have not seen the type of fraud that calls into question the reported (and certified) results of the election.”
      <Citation number={292} />
    </Typography>

    <Typography className="indent">
      The letter also explicitly recommended that Georgia’s State legislature should call a special session to evaluate potential election fraud. “In light of these developments, the Department recommends that the Georgia General Assembly should convene in special session so that its legislators are in a special position to take additional testimony, receive new evidence, and deliberate on the matter consistent with its duties under the U.S. Constitution.”
      <Citation number={293} />
    </Typography>

    <Typography className="indent">
      Clark’s draft letter also referenced the fake electors that President Trump and his campaign organized—arguing falsely that there were currently two competing slates of legitimate Presidential electors in Georgia:
      <Citation number={294} />
    </Typography>

    <Blockquote>
      <Typography>
        The Department believes that in Georgia and several other States, both a slate of electors supporting Joseph R. Biden, Jr., and a separate slate of electors supporting Donald J. Trump, gathered on [December 14, 2020] at the proper location to cast their ballots, and that both sets of those ballots have been transmitted to Washington, D.C., to be opened by Vice President Pence.
        <Citation number={295} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      This, of course, was part of Donald Trump and John Eastman’s plan for January 6th. This letter reflects an effort to use the Department of Justice to help overturn the election outcome in Georgia and elsewhere. Rosen and Donoghue reacted immediately to this draft letter:
    </Typography>

    <Blockquote>
      <Typography>
        [T]here’s no chance that I would sign this letter or anything remotely like this,” Donoghue wrote.
        <Citation number={296} />
        The plan set forth by Clark was “not even within the realm of possibility,”
        <Citation number={297} />
        and Donoghue warned that if they sent Clark’s letter, it “would be a grave step for the Department to take and it could have tremendous Constitutional, political and social ramifications for the country.”
        <Citation number={298} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      As Richard Donoghue testified when describing his response to Clark’s proposed letter:
    </Typography>

    <Blockquote>
      <Typography>
        Well, I had to read both the email and the attached letter twice to make sure I really understood what he was proposing because it was so extreme to me I had a hard time getting my head around it initially.
      </Typography>
      <Typography>
        But I read it, and I did understand it for what he intended, and I had to sit down and sort of compose what I thought was an appropriate response....
      </Typography>
      <Typography>
        In my response I explained a number of reasons this is not the Department’s role to suggest or dictate to State legislatures how they should select their electors. But more importantly, this was not based on fact. This was actually contrary to the facts as developed by Department investigations over the last several weeks and months.
      </Typography>
      <Typography>
        So, I respond to that. And for the department to insert itself into the political process this way I think would have had grave consequences for the country. It may very well have spiraled us into a constitutional crisis.
        <Citation number={299} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Rosen and Donoghue also met with Clark about the letter. Their conversation “was a very difficult and contentious” one, according to Donoghue.
      <Citation number={300} />
      “What you’re proposing is nothing less than the United States Justice Department meddling in the outcome of a Presidential election,” Donoghue admonished Clark, to which Clark indignantly responded, “I think a lot of people have meddled in this election.”
      <Citation number={301} />
    </Typography>

    <Typography className="indent">
      Both Rosen and Donoghue refused to sign the letter, and confronted Clark with the actual results of the Department’s investigations.
      <Citation number={302} />
      They also permitted Clark access to a classified briefing from the Office of the Director of National Intelligence (“ODNI”) showing Clark that allegations he made to Rosen and Donoghue about foreign interference with voting machines were not true. According to Rosen, the decision to give Clark the briefing at that point “was a difficult question because, if he’s going to brief the President, I reluctantly think it’s probably better that he’s heard from Director Ratcliffe than that he not, even if—I don’t think he should brief the President. But, at this point, he’s telling me that this is happening whether I agree with it or not. So, so I let him have that briefing.”
      <Citation number={303} />
    </Typography>

    <Typography className="indent">
      After Clark received the ODNI briefing, “he acknowledged [to Donoghue] that there was nothing in that briefing that would have supported his earlier suspicion about foreign involvement.”
      <Citation number={304} />
      While Clark then dropped his claims about foreign interference, he continued to press to send the letter to Georgia and other States, despite being told that the Department of Justice investigations had found no fraud sufficient to overturn the election outcome in Georgia or any other States. This was an intentional choice by Jeff Clark to contradict specific Department findings on election fraud, and purposely insert the Department into the Presidential election on President Trump’s behalf and risk creating or exacerbating a constitutional crisis.
    </Typography>

    <Typography className="indent">
      By this point, President Trump recognized that neither Rosen nor Donoghue would sign the letter or support his false election claims. President Trump and his team then communicated further with Clark and offered him the job of Acting Attorney General. On January 2nd, Clark told Rosen that he “would turn down the President’s offer if [Rosen] reversed [his] position and signed the letter” that he and Klukowski had drafted.
      <Citation number={305} />
      The next day, Clark decided to accept and informed Rosen, who then called White House Counsel to seek a meeting directly with President Trump. As Rosen put it, “I wasn’t going to accept being fired by my subordinate, so I wanted to talk to the President directly.”
      <Citation number={306} />
    </Typography>

    <Typography className="indent">
      On January 3rd, that meeting was convened. Although contemporaneous White House documents suggest that Clark had already been appointed as the Acting Attorney General,
      <Citation number={307} />
      all the participants in the meeting other than Clark and President Trump aggressively opposed Clark’s appointment.
    </Typography>

    <Typography className="indent">
      At that point, Rosen decided to “broaden the circle” and ask that his subordinates inform all the other Assistant Attorneys General (AAGs) what was afoot.
      <Citation number={308} />
      Rosen wanted to know how the AAGs would respond if Jeff Clark was installed as the Acting Attorney General. Pat Hovakimian, who worked for Rosen, then set up a conference call. The AAGs almost immediately agreed that they would resign if Rosen was removed from office.
      <Citation number={309} />
    </Typography>

    <Typography className="indent">
      Rosen, Donoghue, and Steve Engel, the Assistant Attorney General for the Office of Legal Counsel, attended the meeting. White House lawyers Pat Cipollone, Eric Herschmann and Pat Philbin joined as well.
    </Typography>

    <Typography className="indent">
      When the meeting started, Clark attempted to defend his appointment. Clark declared that this was the “last opportunity to sort of set things straight with this defective election,” and he had the “intelligence,” the “will,” and “desire” to “pursue these matters in the way that the President thought most appropriate.”
      <Citation number={310} />
      Everyone else present disagreed that Clark could conceivably accomplish these things.
    </Typography>

    <Typography className="indent">
      White House Counsel Pat Cipollone threatened to resign as well, describing Clark’s letter as a “murder-suicide pact.”
      <Citation number={311} />
      Cipollone warned that the letter would “damage everyone who touches it” and no one should have anything to do with it.
      <Citation number={312} />
    </Typography>

    <Typography className="indent">
      President Trump asked Donoghue and Engel what they would do if Clark took office. Both confirmed they would resign.
      <Citation number={313} />
      Steve Engel recalled that the President next asked if he would resign:
    </Typography>

    <Blockquote>
      <Typography>
        At some point, [] I believe Rich Donoghue said that senior Department officials would all resign if Mr. Clark were put in, and the President turned to me and said, “Steve, you wouldn’t resign, would you?” I said, “Well, Mr. President, I’ve been with you through four Attorneys General, including two Acting Attorneys General, and I just couldn’t be part of this if Mr. Clark were here.” And I said, “And I believe that the other senior Department officials would resign as well. And Mr. Clark would be here by himself with a hostile building, those folks who remained, and nothing would get done.”
        <Citation number={314} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Donoghue added that they would not be the only ones to resign. “You should understand that your entire Department leadership will resign,” Donoghue recalled saying. This included every Assistant Attorney General. “Mr. President, these aren’t bureaucratic leftovers from another administration,” Donoghue reminded Trump, “You picked them. This is your leadership team.” Donoghue added, “And what happens if, within 48 hours, we have hundreds of resignations from your Justice Department because of your actions? What does that say about your leadership?”
      <Citation number={315} />
      Steve Engel then reinforced Donoghue’s point, saying that Clark would be leading a “graveyard.”
    </Typography>

    <Typography className="indent">
      Faced with mass resignations and recognizing that the “breakage” could be too severe, Donald Trump decided to rescind his offer to Clark and drop his plans to use the Justice Department to aid in his efforts to overturn the election outcome.
      <Citation number={316} />
      The President looked at Clark and said, “I appreciate your willingness to do it. I appreciate you being willing to suffer the abuse. But the reality is, you’re not going to get anything done. These guys are going to quit. Everyone else is going to resign. It’s going to be a disaster. The bureaucracy will eat you alive. And no matter how much you want to get things done in the next few weeks, you won’t be able to get it done, and it’s not going to be worth the breakage.”
      <Citation number={317} />
    </Typography>

    <StarDivider>***</StarDivider>

    <Image src="/images/image-017.jpg" alt="Trump tweet." />

    <Typography className="indent">
      Evidence gathered by the Committee also suggests that President Trump offered Sidney Powell the position of Special Counsel for election related matters during a highly charged White House meeting on December 18, 2020.
      <Citation number={318} />
      White House lawyers vehemently opposed Powell’s appointment, and it also was not ultimately made formal.
    </Typography>
  </Page>
);