import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet6'));

export const Chapter49 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      On December 28, 2020, Clark sent a 5-page draft letter to Donoghue and Rosen.
      <Citation number={181} />
      The letter was addressed to three Georgia State officials: Governor Brian Kemp, Speaker of the House David Ralston, and President Pro Tempore of the Senate Butch Miller. It contained places for Rosen and Donoghue to affix their signatures, which they steadfastly refused to do.
      <Citation number={182} />
      The letter, if signed and sent, may very well have provoked a constitutional crisis.
      <Citation number={183} />
    </Typography>

    <Typography className="indent">
      The letter was attached to an email from Clark, in which he requested authorization to attend a classified briefing by the Office of the Director of National Intelligence (ODNI) “led by DNI Ratcliffe on foreign election interference issues.”
      <Citation number={184} />
      ODNI did not find any foreign interference in the voting process or counting,
      <Citation number={185} />
      but Clark apparently believed some of the conspiracy theories that had been floated. Specifically, Clark claimed that “hackers have evidence (in the public domain) that a Dominion machine accessed the internet through a smart thermostat with a net connection trail leading back to China.” Clark added: “ODNI may have additional classified evidence.”
      <Citation number={186} />
      This crackpot claim had been shared by other Trump officials and associates as well.
      <Citation number={187} />
      Ultimately, after Clark received the ODNI briefing, “he acknowledged [to Donoghue] that there was nothing in that briefing that would have supported his earlier suspicion about foreign involvement.”
      <Citation number={188} />
    </Typography>

    <Typography className="indent">
      Clark intended to send the letter to officials in Georgia and several other contested States that President Trump needed to flip if he was going to overturn the election results. “The Department of Justice is investigating various irregularities in the 2020 election for President of the United States,” Clark wrote.
      <Citation number={189} />
      Clark continued: “The Department will update you as we are able on investigatory progress, but at this time we have identified significant concerns that may have impacted the outcome of the election in multiple States, including the State of Georgia.”
      <Citation number={190} />
    </Typography>

    <Typography className="indent">
      Clark continued by arguing that Georgia’s State legislature should call a special session. “In light of these developments, the Department recommends that the Georgia General Assembly should convene in special session so that its legislators are in a special position to take additional testimony, receive new evidence, and deliberate on the matter consistent with its duties under the U.S. Constitution,” Clark wrote.
      <Citation number={191} />
      Clark referenced the fake electors that the President and his campaign organized and argued that there were two competing slates of electors, both of which were legitimate.
      <Citation number={192} />
      “The Department believes that in Georgia and several other States, both a slate of electors supporting Joseph R. Biden, Jr., and a separate slate of electors supporting Donald J. Trump, gathered on [December 14, 2020] at the proper location to cast their ballots, and that both sets of those ballots have been transmitted to Washington, D.C., to be opened by Vice President Pence,” Clark wrote.
      <Citation number={193} />
    </Typography>

    <Typography className="indent">
      The letter was a lie. Senior DOJ officials—Barr, Rosen and Donoghue— had repeatedly stated the opposite. They found no evidence of fraud that would have impacted the election’s results—none. But since midNovember, the Trump Campaign’s legal team under Giuliani attempted to execute its dual-track strategy of both filing lawsuits and convincing state legislatures in contested states to appoint separate slates of Presidential electors for President Trump.
      <Citation number={194} />
      By late December, however, the dual-track approach had largely failed, and no legislatures had sent a second lawful slate of electors for Trump to Congress. Clearly, President Trump and his campaign team could not get the job done. So, the President and those around him sought to use the hefty imprimatur of the U.S. Department of Justice to achieve the same thing. No doubt, a letter coming from the Department of Justice is different from a meandering call from Giuliani or one of his associates. And, because it was December 28th and there was little more than a week until the January 6th joint session of Congress, President Trump needed more, and soon. Clark’s letter, which laid out a plan that was almost identical to what President Trump and his team had pressured State officials to carry out virtually since election day, could have been just what President Trump needed.
    </Typography>

    <Typography className="indent">
      Several examples demonstrate the parallels between President Trump’s and Rudolph Giuliani’s approach to overturning the election in November and December, and what Clark proposed in this letter. First, the letter sought to have the Georgia State legislature convene a special session to focus on allegations of fraud in the election.
      <Citation number={195} />
       Giuliani and his team had been making calls to State legislatures and telling them in both official and unofficial State legislature committee hearings that State legislatures should convene in special sessions.
      <Citation number={196} />
      They also argued that State legislatures had the authority to convene a special session themselves, despite limitations in State law requiring such a session to be convened by the governor.
      <Citation number={197} />
      Clark included the same argument in his draft letter.
      <Citation number={198} />
    </Typography>

    <Typography className="indent">
      Additionally, the draft letter recommended that the Georgia legislature consider choosing the alternate—fake—slate of electoral college electors that sent fake electoral college votes to Congress and Vice President Pence.
      <Citation number={199} />
      Having State legislatures choose Trump electors in States where President Trump lost was one of the Trump team’s early goals immediately after the election, but it didn’t work.
      <Citation number={200} />
      When no State legislature appointed its own set of electors before December 14th, the Trump Campaign arranged for electors to meet in contested States anyway and cast fake electoral college votes.
      <Citation number={201} />
      This letter, with the Department of Justice seal at the top, was just one more way that President Trump and those close to him could pressure State officials to send competing electoral college votes to Congress for consideration during the joint session, despite former Vice President Biden’s certified victory in each of the contested States.
    </Typography>

    <Typography className="indent">
      Despite the similarities between the requests in Clark’s proposed letter and the requests that President Trump and his team made to State officials for nearly 2 months, the extent to which Clark directly coordinated his actions with the Trump Campaign and its outside advisors is unclear. Clark asserted his Fifth Amendment rights and various other privileges to avoid answering the Select Committee’s questions about these and other topics.
      <Citation number={202} />
      When Giuliani was asked during his Select Committee deposition whether he remembered discussing DOJ issuing a letter like Clark’s, Giuliani refused to answer because it implicated attorney-client privilege with President Trump, but when asked if he recalled ever recommending that Clark be given election-related responsibilities at DOJ, Giuliani said, “I do recall saying to people that somebody should be put in charge of the Justice Department who isn’t frightened of what’s going to be done to their reputation, because the Justice Department was filled with people like that.”
      <Citation number={203} />
      And the investigation has also revealed that Clark and John Eastman were in communication throughout this period.
      <Citation number={204} />
    </Typography>

    <Typography className="indent">
      One person who had worked with Eastman and others in his circle was a lawyer installed to work with Clark at the Department of Justice in mid-December—the final weeks of the Trump administration—named Ken Klukowski.
      <Citation number={205} />
      Klukowski was a Trump administration political appointee serving as a senior counsel under Clark in DOJ’s Civil Division.
      <Citation number={206} />
      After serving as a lawyer in the Office and Management and Budget (OMB) for more than a year and volunteering as a lawyer for the Trump Campaign after election day, Klukowski only joined the Department when the administration’s personnel staff “expedite[d]” his appointment because the White House’s Presidential Personnel Office “want[ed] him in soon.”
      <Citation number={207} />
    </Typography>

    <Typography className="indent">
      On the morning of December 28th, Clark asked Klukowski to draft the Georgia letter for him.
      <Citation number={208} />
      Clark dictated the substantive key points of the letter to Klukowski and told him exactly what to include.
      <Citation number={209} />
      After several meetings with Clark throughout the day to update him on progress, Klukowski turned in his assignment and gave the letter to Clark, which Clark sent along to Acting Attorney General Rosen and Acting Deputy Attorney General Donoghue, as described above.
      <Citation number={210} />
    </Typography>

    <Typography className="indent">
      Donoghue quickly responded to Clark’s email, stating “there is no chance that I would sign this letter or anything remotely like this.”
      <Citation number={211} />
      The plan set forth by Clark was “not even within the realm of possibility.”
      <Citation number={212} />
      Donoghue warned that if they sent Clark’s letter, it “would be a grave step for the Department to take and it could have tremendous Constitutional, political and social ramifications for the country.”
      <Citation number={213} />
      Contrary to President Trump’s and Clark’s wild claims about the election, Donoghue stressed that DOJ’s ongoing investigations related to matters of such a “small scale that they simply would not impact the outcome of the Presidential Election.”
      <Citation number={214} />
      Clark’s assertion to the contrary was baseless.
    </Typography>

    <Typography className="indent">
      Donoghue and Rosen reaffirmed their strong opposition to the draft letter in a “contentious” meeting with Clark on December 28th.
      <Citation number={215} />
      “What you are doing is nothing less than the United States Justice Department meddling in the outcome of a presidential election,” Donoghue admonished Clark, to which Clark indignantly responded, “I think a lot of people have meddled in this election.”
      <Citation number={216} />
    </Typography>

    <Typography className="indent">
      Under questioning by Rosen and Donoghue, Clark eventually also revealed that he had been in a meeting in the Oval Office with President Trump. Donoghue demanded to know, “Why the hell are we hearing your name from the President of the United States and a Congressman?”
      <Citation number={217} />
      When Clark was reminded that meeting the President without authorization or informing his superiors was a clear violation of the White House contacts policy, he retorted, “It’s a policy, there’s a lot more at stake here than a policy.”
      <Citation number={218} />
      In fact, the contacts policy was designed for situations just like this where political figures might try to influence criminal investigations or legal actions taken by the Department of Justice, as President Trump attempting to do.
      <Citation number={219} />
    </Typography>

    <Typography className="indent">
      In the days that followed, Clark called witnesses, got a briefing from ODNI and pursued his own investigations. Acting Deputy Attorney General Donoghue was “shocked” to learn that Clark did not cease his efforts even after learning there was “no foreign interference.”
      <Citation number={220} />
      Instead of adhering to the facts, Clark “doubled down.” During a follow-up meeting on January 2nd, Clark acknowledged he had received the ODNI briefing, and he acknowledged that there was nothing in the briefing that would have supported his earlier suspicion about foreign involvement, but he nevertheless “spewed out some of these theories, some of which we’d heard from the President, but others which were floating around the internet and media, and just kept insisting that the Department needed to act and needed to send those letters.”
      <Citation number={221} />
    </Typography>
  </Page>
)
