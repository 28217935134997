import { styled } from '@mui/material/styles';

const StyledContainer = styled('div')(({ theme }) => `
  padding: 0 ${theme.spacing(2)} 0 ${theme.spacing(4)};

  &.blockquote {
    padding: 0 0 0 ${theme.spacing(4)};
  }

  &.citation {
    padding: 0 0 0 ${theme.spacing(4)};
  }

  @media (min-width: ${theme.breakpoints.values.sm}${theme.breakpoints.unit}) {
    padding-left: ${theme.spacing(6)};

    &.blockquote {
      padding: 0 0 0 ${theme.spacing(6)};
    }
  }

  @media (min-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    padding-left: ${theme.spacing(8)};

    &.blockquote {
      padding: 0 ${theme.spacing(2)} 0 ${theme.spacing(6)};
    }

    &.citation {
      padding: 0 ${theme.spacing(2)} 0 ${theme.spacing(4)};
    }
  }

  @media (min-width: ${theme.breakpoints.values.lg}${theme.breakpoints.unit}) {
    padding-left: ${theme.spacing(8)};

    &.blockquote {
      padding: 0 ${theme.spacing(4)} 0 ${theme.spacing(8)};
    }

    &.citation {
      padding: 0 ${theme.spacing(4)};
    }
  }
`);

export const StyledListContainer = ({ children, blockquote, citation }: any) => (
  <StyledContainer className={blockquote ? 'blockquote' : citation ? 'citation' : ''}>
    {children}
  </StyledContainer>
);
