import { lazy } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { Blockquote } from '../../components/Blockquote';
import { Page } from '../../components/Page';
import { StarDivider } from '../../components/StarDivider';

const Citation = lazy(() => import('../Citations/CitationSheet1'));

const ResponsiveDiv = styled('div')(({ theme }) => `
  display: block;
  overflow: hidden;

  img {
    margin-right: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
    max-width: 100%;
  }

  @media (min-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    display: flex;
    align-items: center;
  }
`);

export const ForewordsViceChair = ({ ...args }: any) => {
  return (
    <Page {...args}>
      <Typography className="indent">
        In April 1861, when Abraham Lincoln issued the first call for volunteers for the Union Army, my great-great grandfather, Samuel Fletcher Cheney, joined the 21st Ohio Volunteer Infantry. He fought through all four years of the Civil War, from Chickamauga to Stones River to Atlanta. He marched with his unit in the Grand Review of Troops up Pennsylvania Avenue in May 1865, past a reviewing stand where President Johnson and General Grant were seated.
      </Typography>
      <Typography className="indent">
        Silas Canfield, the regimental historian of the 21st OVI, described the men in the unit this way:
      </Typography>

      <Blockquote>
        <Typography>
          Industry had taught them perseverance, and they had learned to turn aside for no obstacle. Their intelligence gave them a just appreciation of the value and advantage of free government, and the necessity of defending and maintaining it, and they enlisted prepared to accept all the necessary labors, fatigues, exposures, dangers, and even death for the unity of our Nation, and the perpetuity of our institutions.
          <Citation number={1} />
        </Typography>
      </Blockquote>

      <Typography className="indent">
        I have found myself thinking often, especially since January 6th, of my great-great grandfather, and all those in every generation who have sacrificed so much for “the unity of our Nation and the perpetuity of our institutions.”
      </Typography>
      <Typography className="indent">
        At the heart of our Republic is the guarantee of the peaceful transfer of power. Members of Congress are reminded of this every day as we pass through the Capitol Rotunda. There, eight magnificent paintings detail the earliest days of our Republic. Four were painted by John Trumbull, including one depicting the moment in 1793 when George Washington resigned his commission, handing control of the Continental Army back to Congress. Trumbull called this, “one of the highest moral lessons ever given the world.” With this noble act, George Washington established the indispensable example of the peaceful transfer of power in our nation.
      </Typography>
      <Typography className="indent">
        Standing on the West Front of the Capitol in 1981, President Ronald Reagan described it this way:
      </Typography>

      <Blockquote>
        <Typography>
          To a few of us here today, this is a solemn and most momentous occasion, and yet in the history of our nation it is a commonplace occurrence. The orderly transfer of authority as called for in the Constitution routinely takes place, as it has for almost two centuries, and few of us stop to think how unique we really are. In the eyes of many in the world, this every-4-year ceremony we accept as normal is nothing less than a miracle.
        </Typography>
      </Blockquote>

      <Typography className="indent">
        Every President in our history has defended this orderly transfer of authority, except one. January 6, 2021 was the first time one American President refused his Constitutional duty to transfer power peacefully to the next.
      </Typography>
      <Typography className="indent">
        In our work over the last 18 months, the Select Committee has recognized our obligation to do everything we can to ensure this never happens again. At the outset of our investigation, we recognized that tens of millions of Americans had been persuaded by President Trump that the 2020 Presidential election was stolen by overwhelming fraud. We also knew this was flatly false, and that dozens of state and federal judges had addressed and resolved all manner of allegations about the election. Our legal system functioned as it should, but our President would not accept the outcome.
      </Typography>
      <Typography className="indent">
        What most of the public did not know before our investigation is this: Donald Trump’s own campaign officials told him early on that his claims of fraud were false. Donald Trump’s senior Justice Department officials—each appointed by Donald Trump himself—investigated the allegations and told him repeatedly that his fraud claims were false. Donald Trump’s White House lawyers also told him his fraud claims were false. From the beginning, Donald Trump’s fraud allegations were concocted nonsense, designed to prey upon the patriotism of millions of men and women who love our country.
      </Typography>
      <Typography className="indent">
        Most Americans also did not know exactly how Donald Trump, along with a handful of others, planned to defeat the transfer of Presidential power on January 6th. This was not a simple plan, but it was a corrupt one. This report lays that plan out in detail—a plan that ultimately had seven parts, anticipating that Vice President Pence, serving in his role as President of the Senate, would refuse to count official Biden electoral slates from multiple states. We understood from the beginning that explaining all the planning and machinations would be complex and would require many hours of public presentations and testimony. We also understood that our presentations needed to be organized into a series of hearings that presented the key evidence for the American public to watch live or streamed over a reasonable time period, rather than rely on second-hand accounts as reported by media organizations with their own editorial biases. We organized our hearings in segments to meet that goal. Tens of millions of Americans watched.
      </Typography>
      <Typography className="indent">
        Among the most shameful findings from our hearings was this: President Trump sat in the dining room off the Oval Office watching the violent riot at the Capitol on television. For hours, he would not issue a public statement instructing his supporters to disperse and leave the Capitol, despite urgent pleas from his White House staff and dozens of others to do so. Members of his family, his White House lawyers, virtually all those around him knew that this simple act was critical. For hours, he would not do it. During this time, law enforcement agents were attacked and seriously injured, the Capitol was invaded, the electoral count was halted and the lives of those in the Capitol were put at risk. In addition to being unlawful, as described in this report, this was an utter moral failure—and a clear dereliction of duty. Evidence of this can be seen in the testimony of his White House Counsel and several other White House witnesses. No man who would behave that way at that moment in time can ever serve in any position of authority in our nation again. He is unfit for any office.
      </Typography>

      <StarDivider>*****</StarDivider>

      <Typography className="indent">
        In presenting all of the information in our hearings, we decided that the vast majority of our witnesses needed to be Republicans. They were. We presented evidence from two former Trump Administration Attorneys General, a former White House Counsel, many former Trump-appointed White House, Justice Department, and Trump Campaign staff, a respected former conservative judge, the former Secretary of Labor, and many others.
      </Typography>
      <Typography className="indent">
        Like our hearings, this report is designed to deliver our findings in detail in a format that is accessible for all Americans. We do so in an executive summary, while also providing immense detail for historians and others. We are also releasing transcripts and evidence for the public to review, consistent with a small number of security and privacy concerns. A section of this report also explains the legal conclusions we draw from the evidence, and our concerns about efforts to obstruct our investigation.
      </Typography>
      <Typography className="indent">
        The Committee recognizes that this investigation is just a beginning; it is only an initial step in addressing President Trump’s effort to remain in office illegally. Prosecutors are considering the implications of the conduct we describe in this report. As are voters. John Adams wrote in 1761, “The very ground of our liberties is the freedom of elections.” Faith in our elections and the rule of law are paramount to our Republic. Election-deniers— those who refuse to accept lawful election results—purposely attack the rule of law and the foundation of our country.
      </Typography>
      <Typography className="indent">
        As you read this report, please consider this: Vice President Pence, along with many of the appointed officials who surrounded Donald Trump, worked to defeat many of the worst parts of Trump’s plan to overturn the election. This was not a certainty. It is comforting to assume that the institutions of our Republic will always withstand those who try to defeat our Constitution from within. But our institutions are only strong when those who hold office are faithful to our Constitution. We do not know what would have happened if the leadership of the Department of Justice declared, as Donald Trump requested, that the election was “corrupt,” if Jeff Clark’s letters to State Legislatures had been sent, if Pat Cipollone, Jeff Rosen, Richard Donoghue, Steve Engel and others were not serving as guardrails on Donald Trump’s abuses.
      </Typography>
      <Typography className="indent">
        Part of the tragedy of January 6th is the conduct of those who knew that what happened was profoundly wrong, but nevertheless tried to downplay it, minimize it or defend those responsible. That effort continues every day. Today, I am perhaps most disappointed in many of my fellow conservatives who know better, those who stood against the threats of communism and Islamic terrorism but concluded that it was easier to appease Donald Trump, or keep their heads down. I had hoped for more from them.
      </Typography>
      <Typography className="indent">
        The late Charles Krauthammer wrote, “The lesson of our history is that the task of merely maintaining strong and sturdy the structures of a constitutional order is unending, the continuing and ceaseless work of every generation.” This task is unending because democracy can be fragile and our institutions do not defend themselves.
      </Typography>
      <Typography className="indent">
        The history of our time will show that the bravery of a handful of Americans, doing their duty, saved us from an even more grave Constitutional crisis. Elected officials, election workers, and public servants stood against Donald Trump’s corrupt pressure. Many of our witnesses showed selfless patriotism and their words and courage will be remembered.
      </Typography>
      <Typography className="indent">
        The brave men and women of the Capitol Police, Metropolitan Police and all the other law enforcement officers who fought to defend us that day undoubtedly saved lives and our democracy.
      </Typography>
      <Typography className="indent">
        Finally, I wish to thank all who honorably contributed to the work of the Committee and to this Report. We accomplished much over a relatively short period of time, and many of you sacrificed for the good of your nation. You have helped make history and, I hope, helped right the ship.
      </Typography>

      <br />

      <ResponsiveDiv>
        <img src="/images/image-007.jpg" alt="Vice Chair Liz Cheney" />

        <div>
          <img src="/images/image-006.jpg" alt="Vice Chair Liz Cheney, signature" />
          <Typography>
            <strong>LIZ CHENEY</strong>
          </Typography>
          <Typography>
            Vice Chair
          </Typography>
        </div>
      </ResponsiveDiv>
    </Page>
  )
}
