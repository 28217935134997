import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet2'));

export const InciteInsurrection = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Section 2383 of Title 18 of the United States Code applies to anyone who “incites, sets on foot, assists, or engages in any rebellion or insurrection against the authority of the United States or the laws thereof, or gives aid or comfort thereto.”
      <Citation number={632} />
      The Committee recognizes that section 2383 does not require evidence of an “agreement” between President Trump and the violent rioters to establish a violation of that provision; instead, the President need only have incited, assisted, or aided and comforted those engaged in violence or other lawless activity in an effort to prevent the peaceful transition of the Presidency under our Constitution. A Federal court has already concluded that President Trump’s statements during his Ellipse speech were “plausibly words of incitement not protected by the First Amendment.”
      <Citation number={633} />
      Moreover, President Trump was impeached for “Incitement of Insurrection,” and a majority of the Senate voted to convict, with many more suggesting they might have voted to convict had President Trump still been in office at the time.
      <Citation number={634} />
    </Typography>

    <Typography className="indent">
      As explained throughout this Report and in this Committee’s hearings, President Trump was directly responsible for summoning what became a violent mob to Washington, DC, urging them to march to the Capitol, and then further provoking the already violent and lawless crowd with his 2:24 p.m. tweet about the Vice President. Even though President Trump had repeatedly been told that Vice President Pence had no legal authority to stop the certification of the election, he asserted in his speech on January 6th that if the Vice President “comes through for us” that he could deliver victory to Trump: “[I]f Mike Pence does the right thing, we win the election.” This created a desperate and false expectation in President Trump’s mob that ended up putting the Vice President and his entourage and many others at the Capitol in physical danger. When President Trump tweeted at 2:24 p.m., he knew violence was underway. His tweet exacerbated that violence.
      <Citation number={635} />
    </Typography>

    <Typography className="indent">
      During the ensuing riot, the President refused to condemn the violence or encourage the crowd to disperse despite repeated pleas from his staff and family that he do so. The Committee has evidence from multiple sources establishing these facts, including testimony from former White House Counsel Pat Cipollone. Although Cipollone’s testimony did not disclose a number of direct communications with President Trump in light of concerns about executive privilege, the Department now appears to have obtained a ruling that Cipollone can testify before a grand jury about these communications. Based on the information it has obtained, the Committee believes that Cipollone and others can provide direct testimony establishing that President Trump refused repeatedly, for multiple hours, to make a public statement directing his violent and lawless supporters to leave the Capitol. President Trump did not want his supporters (who had effectively halted the vote counting) to disperse. Evidence obtained by the Committee also indicates that President Trump did not want to provide security assistance to the Capitol during that violent period.
      <Citation number={636} />
      This appalling behavior by our Commander in Chief occurred despite his affirmative constitutional duty to act to ensure that the laws are faithfully executed.
      <Citation number={637} />
    </Typography>

    <Typography className="indent">
      The Committee believes that sufficient evidence exists for a criminal referral of President Trump for “assist[ing]” or “ai[ding] and comfort[ing]” those at the Capitol who engaged in a violent attack on the United States. The Committee has developed significant evidence that President Trump intended to disrupt the peaceful transition of power and believes that the Department of Justice can likely elicit testimony relevant to an investigation under section 2383.
    </Typography>

    <Typography className="indent">
      For example, Chief of Staff Mark Meadows told White House Counsel Pat Cipollone that the President “doesn’t want to do anything” to stop the violence.
      <Citation number={638} />
      Worse, at 2:24 p.m., the President inflamed and exacerbated the mob violence by sending a tweet stating that the Vice President “didn’t have the courage to do what should have been done.”
      <Citation number={639} />
      The President threw gasoline on the fire despite knowing that there was a violent riot underway at the Capitol. Indeed, video and audio footage from the attack shows that many of the rioters specifically mentioned Vice President Pence.
      <Citation number={640} />
      And immediately after President Trump sent his tweet, the violence escalated. Between 2:25 p.m. and 2:28 p.m., rioters breached the East Rotunda doors, other rioters breached the police line in the Capitol Crypt, Vice President Pence had to be evacuated from his Senate office, and Leader McCarthy was evacuated from his Capitol office.
      <Citation number={641} />
    </Typography>

    <Typography className="indent">
      Evidence developed in the Committee’s investigation showed that the President, when told that the crowd was chanting “Hang Mike Pence,” responded that perhaps the Vice President deserved to be hanged.
      <Citation number={642} />
      And President Trump rebuffed pleas from Leader McCarthy to ask that his supporters leave the Capitol stating, “Well, Kevin, I guess these people are more upset about the election than you are.” After hours of deadly riot, President Trump eventually released a videotaped statement encouraging the crowd to disperse, though openly professing his “love” for the members of the mob and empathizing with their frustration at the “stolen” election. President Trump has since expressed a desire to pardon those involved in the attack.
      <Citation number={643} />
    </Typography>

    <Typography className="indent">
      Both the purpose and the effect of the President’s actions were to mobilize a large crowd to descend on the Capitol. Several defendants in pending criminal cases identified the President's allegations about the “stolen election” as the key motivation for their activities at the Capitol. Many of them specifically cited the President’s tweets asking his supporters to come to Washington, DC, on January 6th. For example, one defendant who later pleaded guilty to threatening House Speaker Nancy Pelosi texted a family member on January 6th to say: “[Trump] wants heads and I'm going to deliver.”
      <Citation number={644} />
      Another defendant released a statement through his attorney stating: “I was in Washington, DC on January 6, 2021, because I believed I was following the instructions of former President Trump and he was my President and the commander-in-chief. His statements also had me believing the election was stolen from him.”
      <Citation number={645} />
    </Typography>

    <Typography className="indent">
      As the violence began to subside and law enforcement continued to secure the Capitol, President Trump tweeted again, at 6:01 pm to justify the actions of the rioters: “These are the things and events that happen,” he wrote, when his so-called victory was “so unceremoniously & viciously stripped away....”
      <Citation number={646} />
      When he wrote those words, he knew exactly what he was doing. Before President Trump issued the tweet, a White House staffer cautioned him that the statement would imply that he “had something to do with the events that happened at the Capitol”—but he tweeted it anyway.
      <Citation number={647} />
      The final words of that tweet leave little doubt about President Trump’s sentiments toward those who invaded the Capitol: “Remember this day forever!”
      <Citation number={648} />
    </Typography>
  </Page>
)
