import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet4'));

export const Chapter20 = ({ ...args }: any) => (
  <Page {...args}>
    <Image
      src="/images/image-034.jpg"
      alt='"Look, we need only 11,000 votes."'
      credit="(Photo by Alex Wong/Getty Images)"
      style={{ marginTop: 0 }}
    />

    <Typography className="indent">
      In a now infamous telephone call on January 2, 2021, President Trump pressured Georgia Secretary of State Brad Raffensperger for more than an hour. The President confronted him with multiple conspiracy theories about the election—none of which were true. Raffensperger and other Georgia officials debunked these allegations, one after another, during their call. Under Raffensperger’s leadership, Georgia had, by that time, already conducted a statewide hand recount of all ballots. That recount and other post-election reviews proved that there was no widespread fraud, and that voting machines didn’t alter the outcome of the election.
      <Citation number={1} />
      This should have put President Trump’s allegations to rest. But, undeterred by the facts, the President badgered Raffensperger to overturn the Georgia results.
    </Typography>

    <Typography className="indent">
      President Trump insisted that “the ballots are corrupt” and someone was “shredding” them.
      <Citation number={2} />
      He issued a thinly veiled threat, telling Raffensperger, “it is more illegal for you than it is for them because you know what they did and you’re not reporting it.”
      <Citation number={3} />
      Of course, the Georgia officials weren’t doing anything “illegal,” and there was nothing to “report.” Even so, President Trump suggested that both Raffensperger and his general counsel, Ryan Germany, could face criminal jeopardy.
      <Citation number={4} />
      “That’s a criminal, that’s a criminal offense. And you can’t let that happen,” the President said.
      <Citation number={5} />
      “That’s a big risk to you and to Ryan, your lawyer... I’m notifying you that you’re letting it happen.”
      <Citation number={6} />
    </Typography>

    <Typography className="indent">
      And then the President made his demand. “So look. All I want to do is this. I just want to find 11,780 votes, which is one more than we have,” President Trump told Raffensperger.
      <Citation number={7} />
    </Typography>

    <Typography className="indent">
      It was a stunning moment. The President of the United States was asking a State’s chief election officer to “find” enough votes to declare him the winner of an election he lost.
    </Typography>

    <Typography className="indent">
      Raffensperger saw the President’s warning to him on January 2nd as a threat. “I felt then—and I still believe today—that this was a threat,” Raffensperger wrote in his book.
      <Citation number={8} />
      And this threat was multifaceted: first, the President “notifying” Raffensperger and his team of criminal activity could be understood as directing the law-enforcement power of the Federal Government against them. While Raffensperger did not know for certain whether President Trump was threatening such an investigation, he knew Trump had “positional power” as President and appeared to be promising to “make [my] life miserable.”
      <Citation number={9} />
    </Typography>

    <Typography className="indent">
      But the threat was also of a more insidious kind. As Raffensperger wrote in his book: “Others obviously thought [it was a threat], too, because some of Trump’s more radical followers have responded as if it was their duty to carry out this threat.”
      <Citation number={10} />
      Raffensperger's deputy held a press conference and publicly warned all Americans, including President Trump, that President Trump's rhetoric endangered innocent officials and private citizens, and fueled death threats against Georgia election workers, sexualized threats directed towards Raffensperger's wife, and harassment at the homes of Georgia election officials.
      <Citation number={11} />
      The January 2nd call promised more of the same. The upshot of President Trump’s message to Raffensperger was: do what I ask, or you will pay.
    </Typography>

    <Typography className="indent">
      President Trump’s phone call with Secretary Raffensperger received widespread coverage after it was leaked. But Georgia was not the only State targeted by President Trump and his allies. The call was one element of a larger and more comprehensive effort—much of it unseen by and unknown to the general public—to overturn the votes cast by millions of American citizens across several States.
    </Typography>

    <Typography className="indent">
      As Chapter 1 explained, the root of this effort was the “Big Lie”: President Trump and his allies publicly claiming that the election was rife with fraud that could have changed the result, even though the President’s own advisors, and the Department of Justice, told the President time and time again that this was not the case.
      <Citation number={12} />
      But in parallel with this strategy, President Trump and his allies zeroed in on key battleground States the President had lost, leaning on Republican State officials to overrule voters, disregard valid vote counts, and deliver the States’ electoral votes to the losing candidate. Had this scheme worked, President Trump could have, for the first time in American history, subverted the results of a lawful election to stay in power. His was a deeply anti-democratic plan: to co-opt State legislatures—through appeals to debunked theories of election fraud, or pure partisan politics—to replace Biden electors with Trump electors, so President Trump would win the electoral vote count in the joint session of Congress on January 6th.
    </Typography>

    <Typography className="indent">
      Had enough State officials gone along with President Trump’s plot, his attempt to stay in power might have worked. It is fortunate that a critical mass of honorable officials withstood President Trump’s pressure to participate in this scheme. They and others who stood up to him closed off avenues for thwarting the election so that, by noon on January 6th, President Trump was left with one desperate, final gambit for holding on to power: sending his armed, angry supporters to the U.S. Capitol.
    </Typography>
  </Page>
)
