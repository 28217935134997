import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet6'));

export const Chapter412 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      On December 31st, 2020, President Trump suddenly returned to Washington, DC, from Florida, where he had been celebrating Christmas. Shortly after Air Force One landed, Rosen and Donoghue were summoned to the Oval Office once again. They met with the President that afternoon. President Trump “was a little more agitated than he had been in the meeting on the 15th,” according to Donoghue.
      <Citation number={243} />
      The President remained “adamant that the election has been stolen, that he won, that the American people were being harmed by fraud, and that he believed the Justice Department should be doing something about it.”
      <Citation number={244} />
    </Typography>

    <Typography className="indent">
      The President once again raised the prospect of naming Clark the Acting Attorney General.
      <Citation number={245} />
      Donoghue and Rosen repeated what they had told the President previously—that he “should have the leaders that” he wanted, “but it’s really not going to change anything.”
      <Citation number={246} />
    </Typography>

    <Typography className="indent">
      President Trump again asked why DOJ would not file a complaint with the Supreme Court, alleging that the election was fraudulent. Rosen and Donoghue explained, once more, that the DOJ did not have standing.
      <Citation number={247} />
      DOJ represents the Federal government, not the American people. President Trump was incredulous and became “very animated.”
      <Citation number={248} />
      The President kept repeating the same questions, “How is that possible? How can that possibly be?”
      <Citation number={249} />
    </Typography>

    <Typography className="indent">
      President Trump also floated the prospect of naming a special counsel, suggesting Ken Cuccinelli from the Department of Homeland Security as a possible candidate.
      <Citation number={250} />
      “This sounds like the kind of thing that would warrant appointment of a special counsel,” Donoghue recalled the President saying.
      <Citation number={251} />
      The President did not order the DOJ to name a special counsel, but he was clearly still thinking about it. Donoghue and Rosen “didn’t say a lot” in response, but simply pointed out that there was no evidence to support the many individual allegations that had been made, so there was “no evidence that would warrant appointing a special counsel.”
      <Citation number={252} />
    </Typography>

    <Typography className="indent">
      President Trump again raised the Antrim County, Michigan allegations.
      <Citation number={253} />
      As mentioned above, bipartisan election officials in Antrim County completed a hand recount of all ballots on December 17th.
      <Citation number={254} />
      This should have resolved the matter once and for all. There was simply no evidence that Dominion’s machines had manipulated the result. But President Trump would not accept this reality.
    </Typography>

    <Typography className="indent">
      During the December 31st meeting, the President also raised the prospect of seizing the voting machines. “Why don’t you guys seize machines?” he asked.
      <Citation number={255} /> “You guys should seize machines because there will be evidence,” Donoghue recalled President Trump saying.
      <Citation number={256} /> Rosen pushed back, saying the DOJ had no basis to seize voting machines from the States. They needed a search warrant, but there was no evidence to justify one.
      <Citation number={257} /> Rosen explained to President Trump again that the DOJ has no responsibility for oversight, as the States conduct the elections. Rosen added that to the extent that any Federal agency is involved, it is the Department of Homeland Security, which ensures “software selection and quality control.”
      <Citation number={258} /> At that point, the President called Ken Cuccinelli.
      <Citation number={259} /> Donoghue recalled the President saying something along the lines of, “Ken, the Acting Attorney General is telling me it’s your job to seize machines.”
      <Citation number={260} />
      Rosen had said nothing of the sort, but Cuccinelli quickly shot down the President’s line of inquiry, making it clear that the Department of Homeland Security had no such authority.
      <Citation number={261} />
      White House Counsel Pat Cipollone was also in attendance and supported the DOJ leadership throughout the meeting.
      <Citation number={262} />
    </Typography>

    <Typography className="indent">
      When Rosen spoke to Clark by phone on December 31st or January 1st, Clark revealed that he had spoken to the President again, despite previously promising Rosen and Donoghue that he would inform them of any other contact that he received from the White House.
      <Citation number={263} />
      Clark told Rosen that President Trump had offered Clark the position of Acting Attorney General and asked him to respond by Monday, January 4th. Clark, however, said that he needed to do some “due diligence” related to claims of election fraud before deciding whether he would accept the President’s offer.
      <Citation number={264} />
    </Typography>
  </Page>
)
