import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet4'));

export const Chapter24 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Beyond asking State officials to not certify, to decertify, or to appoint Trump electors for consideration during the joint session, President Trump and some of his closest advisors inserted themselves directly into the counting of ballots and asked, outright, for enough votes to win.
    </Typography>

    <Typography className="indent">
      White House Chief of Staff Mark Meadows did this. Not only did he place calls on behalf of the President to election officials in Georgia, Meadows traveled there to personally visit election officials and volunteers, coordinated with Members of Congress, and even suggested that the President send election workers Trump memorabilia like presidential challenge coins and autographed MAGA hats, a suggestion that his assistant Cassidy Hutchinson thought could be problematic and, ultimately, did not act on.
      <Citation number={240} />
    </Typography>

    <Typography className="indent">
      When Meadows made a visit on short notice to examine the audit of absentee ballots in Cobb County, Georgia, he spoke to Deputy Secretary of State Jordan Fuchs and Frances Watson, the Secretary of State’s chief investigator. Ultimately, Meadows connected Watson with the President, who claimed that he had won the election and pressed her to say that he had won. The Select Committee obtained a copy of their recorded call, which is detailed below.
    </Typography>

    <Typography className="indent">
      The President told Watson that he had “won Georgia... by a lot,” told her, “you have the most important job in the country right now,” and suggested, “when the right answer comes out you’ll be praised.”
      <Citation number={241} />
      Four days later, Meadows texted Deputy Secretary of State Fuchs, in which he asked, “[i]s there a way to speed up Fulton county signature verification in order to have results before Jan 6 if the trump campaign assist[s] financially.”
      <Citation number={242} />
      Fuchs wrote in response that she “Will answer ASAP.”
      <Citation number={243} />
    </Typography>

    <Typography className="indent">
      Meadows also played a central role in the lead up to the President’s January 2, 2021, call with Georgia Secretary of State Brad Raffensperger. In fact, it was Meadows who originally sent text messages to Raffensperger and requested to speak: On November 19th, he texted “Mr Secretary. Mark Meadows here. If you could give me a brief call at your convenience. Thank you”.
      <Citation number={244} />
      And on December 5th, Meadows texted, “mr Secretary. Can you call the White House switchboard at [phone number]. For a call. Your voicemail is full.”
      <Citation number={245} />
      Then, on December 11th, Meadows texted, “Thanks so much” to a number that apparently belongs to United States Representative Jody Hice (R-GA) after Rep. Hice told him that he had just made a statement “regarding a recall on Raffensperger. If this is something Potus wants to know and help push....”
      <Citation number={246} />
    </Typography>

    <Image
      src="/images/image-041.jpg"
      alt='unknown'
      credit="(Photo by House Creative Services)"
    />

    <Typography className="indent">
      All of that led to the remarkable January 2nd call between President Trump and his advisors on one side, and Secretary of State Brad Raffensperger and his advisors on the other. By January 2nd, the President had tried to speak by phone with Raffensperger at least 18 times.
      <Citation number={247} />
      Raffensperger, for his part, had avoided talking to the President because of ongoing litigation with the President’s Campaign.
      <Citation number={248} />
      Despite Raffensperger’s reluctance, the two spoke, with their respective lawyers on the line. During the call, President Trump went through his litany of false election-fraud claims and then asked Raffensperger to deliver him a second term by “finding” just enough votes to ensure victory. The President said, “I just want to find 11,780 votes, which is one more than we have because we won the State.”
      <Citation number={249} />
      He reiterated it several different ways: “fellas, I need 11,000 votes. Give me a break. You know, we have that in spades already. Or we can keep going, but that’s not fair to the voters of Georgia because they’re going to see what happened.”
      <Citation number={250} />
    </Typography>

    <Typography className="indent">
      When it was clear that Raffensperger and his advisors would not agree to the President’s request, the President ramped up the pressure by accusing them of committing crimes: “the ballots are corrupt. And you are going to find that they are—which is totally illegal—it is more illegal for you than it is for them because, you know, what they did and you’re not reporting it. That’s a criminal, that’s a criminal offense. And you can’t let that happen. That’s a big risk to you and to Ryan, your lawyer... I’m notifying you that you’re letting it happen.”
      <Citation number={251} />
    </Typography>

    <Typography className="indent">
      The President would stop at nothing to win Georgia. Separate from asking Raffensperger to alter, without justification, the election results in Georgia, he also attacked election workers. In that call, President Trump mentioned Ruby Freeman’s name 18 times, referred to her daughter Shaye Moss several of those times, and accused them of crimes.
      <Citation number={252} />
      Raffensperger and his aides rebutted President Trump’s false claims of fraud on the call and explained why they were wrong, but they did not deliver the one thing President Trump wanted most: the 11,780 votes he asked for.
      <Citation number={253} />
    </Typography>

    <Typography className="indent">
      The next day, President Trump tweeted about his phone call with Raffensperger, falsely claiming that “[Secretary Raffensperger] was unwilling, or unable, to answer questions such as the ‘ballots under table’ scam.... He has no clue!”
      <Citation number={254} />
      He added that Raffensperger, Governor Kemp, and Lt. Governor Duncan “are a disgrace” and “have done less than nothing” about rampant political corruption.
      <Citation number={255} />
    </Typography>

    <Typography className="indent">
      Even though Raffensperger and his team repeatedly told the President why his specific allegations of election fraud in Georgia were wrong,
      <Citation number={256} />
      President Trump met the next day with the top leadership of the Justice Department in an effort to convince them to send a letter falsely claiming that the Department had “identified significant concerns” affecting the election results in Georgia and calling on Governor Kemp, Speaker Ralston, and Senate President Pro Tempore Miller to convene a special session.
      <Citation number={257} />
      It was only after a showdown in the Oval Office, described in Chapter 4 during which the White House Counsel and others threatened to resign that President Trump decided against replacing Department of Justice leadership and issuing that letter.
    </Typography>
  </Page>
)
