import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';
import { StyledListContainer } from '../../../components/StyledListContainer';

const Citation = lazy(() => import('../../Citations/CitationSheet2'));

export const ImmediateAftermath = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      In days following January 6th, President Trump’s family and staff attempted repeatedly to persuade him not to repeat his election fraud allegations, to concede defeat, and to allow the transition to President Biden to proceed. Trump did make two video recordings, which initially appeared contrite. But evidence suggests that these statements were designed at least in part to ward off other potential consequences of January 6th, such as invocation of the 25th Amendment or impeachment.
    </Typography>

    <Typography className="indent">
      In fact, Minority Leader Kevin McCarthy indicated after the attack, in a discussion with House Republican leaders, that he would ask President Trump to resign:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Rep. Cheney</strong>: I guess there’s a question when we were talking about the 25th Amendment resolution, and you asked what would happen after he’s gone? Is there any chance? Are you hearing that he might resign? Is there any reason to think that might happen?
      </Typography>
      <Typography>
        <strong>Leader McCarthy</strong>: I’ve had a few discussions. My gut tells me no. I’m seriously thinking of having that discussion with him tonight. I haven’t talked to him in a couple of days. From what I know of him, I mean, you guys all know him too, do you think he’d ever back away? But what I think I’m going to do is I’m going to call him. This is what I think. We know [the 25th Amendment resolution] will pass the House. I think there’s a chance it will pass the Senate, even when he’s gone. And I think there’s a lot of different ramifications for that.... Again, the only discussion I would have with him is that I think this will pass, and it would be my recommendation you should resign.
        <Citation number={559} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Before January 6th, Fox News personality Sean Hannity warned that January 6th could be disastrous:
    </Typography>

    <Blockquote>
      <Typography>
        Dec. 31, 2020 text from Sean Hannity to Mark Meadows: “We can’t lose the entire WH counsels office. I do NOT see January 6 happening the way he is being told. After the 6 th [<i>sic</i>]. He should announce will lead the nationwide effort to reform voting integrity. Go to Fl and watch Joe mess up daily. Stay engaged. When he speaks people will listen.”
        <Citation number={560} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      January 5, 2021 texts from Sean Hannity to Mark Meadows:
    </Typography>

    <Blockquote>
      <Typography>
        “Im very worried about the next 48 hours”
      </Typography>
      <Typography>
        “Pence pressure. WH counsel will leave.”
      </Typography>
      <Typography>
        “Sorry, I can’t talk right now.”
      </Typography>
      <Typography>
        “On with boss”
        <Citation number={561} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      A member of the Republican Freedom caucus also warned, on December 31, 2020, and on January 1, 2021:
    </Typography>

    <Blockquote>
      <Typography>
        The President should call everyone off. It’s the only path. If we substitute the will of states through electors with a vote by Congress every 4 years... we have destroyed the electoral college... Respectfully.
        <Citation number={562} />
        If POTUS allows this to occur... we’re driving a stake in the heart of the federal republic...
        <Citation number={563} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      After January 6th, Hannity worked to persuade President Trump to stop talking about election fraud, proposed that Trump pardon Hunter Biden, and discussed attending the Inauguration:
    </Typography>

    <StyledListContainer>
      <ol>
        <li>
          <Typography>
            No more stolen election talk.
          </Typography>
        </li>
        <li>
          <Typography>
            Yes, impeachment and 25th amendment are real, and many people will quit.
          </Typography>
        </li>
        <li>
          <Typography>
            He was intrigued by the Pardon idea!! (Hunter)
          </Typography>
        </li>
        <li>
          <Typography>
            Resistant but listened to Pence thoughts, to make it right.
          </Typography>
        </li>
        <li>
          <Typography>
            Seemed to like attending Inauguration talk.
            <Citation number={564} />
          </Typography>
        </li>
      </ol>
    </StyledListContainer>

    <Typography className="indent">
      Ultimately, President Trump took little of the advice from Hannity and his White House staff. A few days later, Hannity wrote again to Meadows and Jim Jordan:
    </Typography>

    <Blockquote>
      <Typography>
        Guys, we have a clear path to land the plane in 9 days. He can’t mention the election again. Ever. I did not have a good call with him today. And worse, I’m not sure what is left to do or say, and I don t like not knowing if it’s truly understood. Ideas?
        <Citation number={565} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Likewise, despite her many contrary public statements, Republican Congresswoman Marjorie Taylor Greene privately texted her concerns on January 6th about a continuing and real threat of violence.
    </Typography>

    <Blockquote>
      <Typography>
        Mark I was just told there is an active shooter on the first floor of the Capitol Please tell the President to calm people This isn’t the way to solve anything
        <Citation number={566} />
      </Typography>
    </Blockquote>

    <Image
      src="/images/image-023.jpg"
      alt="Kevin McCarthy speaks at a press conference at the Capitol building on August 27, 2021."
      caption="Kevin McCarthy speaks at a press conference at the Capitol building on August 27, 2021."
      credit="(Photo by Anna Moneymaker/Getty Images)"
    />

    <Typography className="indent">
      Donald Trump was impeached on January 13th. In a speech that day, Republican Leader Kevin McCarthy made this statement from the House floor, but voted against impeachment:
    </Typography>

    <Blockquote>
      <Typography>
        The President bears responsibility for Wednesday’s attack on Congress by mob rioters. He should have immediately denounced the mob when he saw what was unfolding. These facts require immediate action by President Trump, accept his share of responsibility, quell the brewing unrest and ensure President-elect Biden is able to successfully begin his term. The President’s immediate action also deserves congressional action, which is why I think a fact-finding commission and a censure resolution would be prudent.
        <Citation number={567} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Later, McCarthy told members of the House Republican conference that Trump had acknowledged that he was at least partially responsible for the January 6th attack.
    </Typography>

    <Blockquote>
      <Typography>
        I asked him personally today, does he hold responsibility for what happened? Does he feel bad about what happened? He told me he does have some responsibility for what happened. And he need to acknowledge that.
        <Citation number={568} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Since January 6th, President Trump has continued to claim falsely that the 2020 Presidential election was stolen. Not only that, he has urged other politicians to push this argument as well. Representative Mo Brooks has issued a public statement appearing to represent Trump’s private views and intentions:
    </Typography>

    <Blockquote>
      <Typography>
        President Trump asked me to rescind the 2020 elections, immediately remove Joe Biden from the White House, immediately put President Trump back in the White House, and hold a new special election for the presidency.
        <Citation number={569} />
      </Typography>
    </Blockquote>
  </Page>
);