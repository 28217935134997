import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const StyledDivider = styled('div')(({ theme }) => `
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 400px;
  font-weight: bold !important;
  margin: ${theme.spacing(4)} auto ${theme.spacing(2)};
  color: ${
    theme.palette.mode === 'dark'
      ? theme.palette.primary.dark
      : theme.palette.secondary.main
  };
`);

export const StarDivider = ({ children }: any) => (
  <StyledDivider>
    {
      children.split('').map((c: any, i: any) => (
        <Typography key={i} fontWeight="inherit">{c}</Typography>
      ))
    }
  </StyledDivider>
);
