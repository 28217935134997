import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet6'));

export const Chapter43 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Attorney General Barr had been clear that DOJ was investigating claims of fraud. The Department simply was not turning up any real evidence of malfeasance, and certainly nothing that would overturn the election. Just as Barr feared, the President turned on DOJ anyway.
    </Typography>

    <Typography className="indent">
      On November 29, 2020, Fox News’s Maria Bartiromo interviewed President Trump. It was his first TV interview since he lost his bid for reelection. The President claimed the election was “rigged” and rife with “fraud.”
      <Citation number={37} />
      President Trump repeated various conspiracy theories, leading with the claim that Dominion’s voting machines had “glitches,” which moved “thousands of votes from my account to Biden’s account.”
      <Citation number={38} />
      President Trump pointed to “dumps of votes,”a reference to the batches of mail-in ballots that had been tabulated later in the counting process.
      <Citation number={39} />
      He rambled off various other, spurious allegations, including that dead people voted in significant numbers.
      <Citation number={40} />
    </Typography>

    <Typography className="indent">
      “This is total fraud,” the President said.
      <Citation number={41} />
      “And how the FBI and Department of Justice—I don’t know—maybe they’re involved, but how people are getting away with this stuff—it’s unbelievable. This election was rigged. This election was a total fraud.”
      <Citation number={42} />
    </Typography>

    <Typography className="indent">
      “Where is the DOJ and the FBI in all of this, Mr. President?” Bartiromo asked.
      <Citation number={43} />
      “You have laid out some serious charges here. Shouldn’t this be something that the FBI is investigating? Are they? Is the DOJ investigating?” Bartiromo asked incredulously.
      <Citation number={44} />
    </Typography>

    <Typography className="indent">
      “Missing in action,” the President replied, “can’t tell you where they are.”
      <Citation number={45} />
      He conceded that when he asked if DOJ and FBI were investigating, “everyone says yes, they’re looking at it.”
      <Citation number={46} />
      But he didn’t leave it there. “You would think if you’re in the FBI or Department of Justice, this is, this is the biggest thing you could be looking at,” President Trump said. “Where are they? I’ve not seen anything. I mean, just keep moving along. They go onto the next President.”
      <Citation number={47} />
      He claimed the FBI was not even investigating Dominion, adding that votes processed in its machines “are counted in foreign countries.”
      <Citation number={48} />
    </Typography>

    <Typography className="indent">
      None of this was true. Just 6 days earlier, Attorney General Barr had explained to President Trump how DOJ and FBI were investigating fraud claims. Barr also made it a point to emphasize that the Dominion claims were nonsense. The President simply lied. The “crazy stuff,” as Barr put it, was all Trump could cite.
    </Typography>

    <Typography className="indent">
      Attorney General Barr then decided to speak out. He invited Michael Balsamo, an <i>Associated Press (AP)</i> reporter, to lunch on December 1st. Barr told the journalist that “to date, we have not seen fraud on a scale that could have effected a different outcome in the election.”
      <Citation number={49} />
    </Typography>

    <Typography className="indent">
      That made the President irate.
    </Typography>

    <Typography className="indent">
      Later that evening, Attorney General Barr met with President Trump at the White House. It was their second face-to-face meeting after the November election.
      <Citation number={50} />
      At first, President Trump didn’t even look at Attorney General Barr.
      <Citation number={51} />
      The President “was as mad as I’ve ever seen him, and he was trying to control himself,” Barr said.
      <Citation number={52} />
      The President finally “shoved a newspaper” with the AP quote in Barr’s face.
      <Citation number={53} />
    </Typography>

    <Typography className="indent">
      “Well, this is, you know, killing me. You didn’t have to say this. You must’ve said this because you hate Trump—you hate Trump,” Barr remembered him saying.
      <Citation number={54} />
      “No, I don’t hate you, Mr. President,” Barr replied. “You know, I came in at a low time in your administration. I’ve tried to help your administration. I certainly don’t hate you.”
      <Citation number={55} />
    </Typography>

    <Typography className="indent">
      President Trump peppered him with unsupported conspiracy theories.
      <Citation number={56} />
      Because he had authorized DOJ and FBI to investigate fraud claims, Attorney General Barr was familiar with the conspiracy theories raised by the President. The “big ones” he investigated included claims such as: Dominion voting machines switched votes, votes had been “dumped at the end of the night in Milwaukee and Detroit,” non-residents voted in Nevada, the number of ballots counted in Pennsylvania exceeded the number of votes cast, as well as a story about a truck driver supposedly driving thousands of pre-filled ballots from New York to Pennsylvania, among others.
      <Citation number={57} />
      Under Attorney General Barr, DOJ would also investigate a false claim that a video feed in Fulton County captured multiple runs of ballots for former Vice President Biden. As explained in detail in Chapter 1 of this report, there was no truth to any of these allegations, but that didn’t stop President Trump from repeatedly citing these fictional accounts.
    </Typography>

    <Typography className="indent">
      “And I told him that the stuff that his people were shoveling out to the public was bullshit, I mean, that the claims of fraud were bullshit,” Barr recalled about the December 1st meeting.
      <Citation number={58} />
      “And, you know, he was indignant about that. And I reiterated that they wasted a whole month of these claims on the Dominion voting machines and they were idiotic claims.”
      <Citation number={59} />
    </Typography>

    <Typography className="indent">
      President Trump repeated that there had been a “big vote dump” in Detroit.
      <Citation number={60} />
      But Attorney General Barr quickly parried this claim.
      <Citation number={61} />
      There was nothing suspicious in how the votes flowed into a central location, Barr explained, because that is how votes are always counted in Wayne County.
      <Citation number={62} />
      Moreover, Barr pointed out that the President performed better in Detroit in 2020 than he had in 2016. “I mean, there’s no indication of fraud in Detroit,” Barr said.
      <Citation number={63} />
      Barr explained that the “thing about the truck driver is complete, you know, nonsense.”
      <Citation number={64} />
      DOJ and FBI had investigated the matter, including by interviewing the relevant witnesses.
      <Citation number={65} />
      There was no truck filled with ballots.
    </Typography>

    <Typography className="indent">
      Nothing that Attorney General Barr said during that meeting could satisfy President Trump. So, the President shifted the focus to Barr. He complained that the Attorney General hadn’t indicted former FBI Director James Comey and that U.S. Attorney John Durham’s investigation into the origins of the FBI’s Crossfire Hurricane investigation hadn’t made more progress.
      <Citation number={66} />
      “Look, I know that you’re dissatisfied with me,” Barr said, “and I’mg lad to offer my resignation.”
      <Citation number={67} />
      President Trump pounded the table in front of him with his fist and said, “Accepted.”
      <Citation number={68} />
    </Typography>

    <Typography className="indent">
      White House lawyers Pat Cipollone and Eric Herschmann tracked Barr down in the parking lot after he left.
      <Citation number={69} />
      They convinced Barr to stay in the administration.
      <Citation number={70} />
      But his days as Attorney General were numbered. President Trump was not going to stop spreading conspiracy theories. Nor would the President cease in his effort to co-opt DOJ for his own corrupt political purposes.
    </Typography>

    <Typography className="indent">
      President Trump released a video on Facebook the very next day.
      <Citation number={71} />
      He repeated many of the same lies, including the conspiracy theory about Dominion voting machines switching votes. The President also offered charts, falsely claiming that fraudulent “vote dumps” had swung the election against him.
      <Citation number={72} />
      Among the examples he cited was the supposed “vote dump” in Detroit, Michigan.
      <Citation number={73} />
      In fact, Barr had already debunked this and other claims.
    </Typography>

    <Typography className="indent">
      On December 3, 2020, Rudolph Giuliani appeared before the Georgia Senate Government Oversight Committee to allege that massive cheating had occurred during the election.
      <Citation number={74} />
      Giuliani offered a video recorded on election night at the State Farm Arena in Atlanta, Georgia, as a key piece of evidence.
      <Citation number={75} />
      Giuliani alleged that the video showed a secret suitcase of ballots being double- and triple-counted after Republican poll watchers had been inappropriately dismissed.
      <Citation number={76} />
      The video was selectively edited and showed nothing of the sort. The Georgia Secretary of State’s Office investigated and immediately debunked the claim, finding that the secret suitcase was just a secure box and nothing nefarious had occurred.
      <Citation number={77} />
      President Trump, Giuliani and others continued to push the lie anyway.
    </Typography>

    <Typography className="indent">
      On December 4th, Attorney General Barr asked Byung J. (“BJay”) Pak, who was then the U.S. Attorney for the Northern District of Georgia, to independently investigate the State Farm claim. Barr told Pak that this was a “priority,” because “he was going to go to the White House for a meeting” and the “issue might come up.” Barr asked Pak to “try to substantiate the allegation made by Mr. Giuliani.”
      <Citation number={78} />
    </Typography>

    <Typography className="indent">
      Pak watched the video from State Farm Arena and asked the FBI to investigate the matter further. Pak told the Select Committee that FBI agents “interviewed the individuals” shown in the video who were supposedly “double, triple counting” the ballots, and “determined that nothing irregular happened in the counting and the allegations made by Mr. Giuliani werefalse.”
      <Citation number={79} />
      And, as noted above, the supposed “suitcase” was a secure storage container used to store ballots. With this evidence in hand, Pak told Attorney General Barr that there was no substance to the allegations.
      <Citation number={80} />
    </Typography>
  </Page>
)
