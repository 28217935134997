import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet2'));

export const CommitteesConcerns = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
    	The Committee has substantial concerns regarding potential efforts to obstruct its investigation, including by certain counsel (some paid by groups connected to the former President) who may have advised clients to provide false or misleading testimony to the Committee.
      <Citation number={654} />
      Such actions could violate 18 U.S.C. §§ 1505, 1512. The Committee is aware that both the U.S. Department of Justice and the Fulton County District Attorney’s Office have already obtained information relevant to these matters, including from the Committee directly. We urge the Department of Justice to examine the facts to discern whether prosecution is warranted. The Committee’s broad concerns regarding obstruction and witness credibility are addressed in the Executive Summary to this Report.
    </Typography>
  </Page>
)
