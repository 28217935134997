import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet9'));

export const Chapter713 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      The President did not, by any account, express grief or regret for what happened at the Capitol. Neither did he appear to grasp the gravity of what he had set in motion.
    </Typography>

    <Typography className="indent">
      In his last phone call of the night, the President spoke with Johnny McEntee, his Director of Personnel.
      <Citation number={328} />
    </Typography>

    <Typography className="indent">
      “[T]his is a crazy day,” the President told him. McEntee said his tone was one of “[l]ike, wow, can you believe this shit . . .?”
      <Citation number={329} />
    </Typography>

    <Typography className="indent">
      Did he express sadness over the violence visited upon the Capitol?
    </Typography>

    <Typography className="indent">
      “No,” McEntee said. “I mean, I think he was shocked by, you know, it getting a little out of control, but I don’t remember sadness, specifically.”
      <Citation number={330} />
    </Typography>

    <Typography className="indent">
      President Trump didn’t make any other phone calls for the rest of the night.
      <Citation number={331} />
      The President didn’t call Vice President Pence. In fact, President Trump never called to check on his Vice President’s safety that day. He didn’t call the heads of any of the Federal law enforcement agencies. He didn’t call the leadership—neither Republican nor Democrat—of the legislative branch of government that had just been overrun by a mob.
      <Citation number={332} />
    </Typography>

    <Typography className="indent">
      Only two days after the riot, by January 8th, the President was over the whole thing.
    </Typography>

    <Typography className="indent">
      He “just didn’t want to talk about it anymore,” he told his press aides. “[H]e was tired of talking about it.”
      <Citation number={333} />
    </Typography>

    <Typography className="indent">
      Ivanka Trump claimed to the Select Committee that her father was “disappointed and surprised” by the attack, but she could not name a specific instance of him expressly saying it.
    </Typography>

    <Typography className="indent">
      “He—I just felt that,” she said. “I know him really well.”
      <Citation number={334} />
    </Typography>

    <Typography className="indent">
      Here’s what she could definitively say:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: Has he ever expressed to you any sentiment that he did or did not do the right thing in how he responded on the day of the 6th?
      </Typography>
      <Typography>
        <strong>Ms. Trump</strong>: No.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: Has he ever expressed any sentiment about something that he wished he had done on the day of the 6th?
      </Typography>
      <Typography>
        <strong>Ms. Trump</strong>: No.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: Has he ever said anything to you about the people who were injured or who died that day?
      </Typography>
      <Typography>
        <strong>Ms. Trump</strong>: No.
        <Citation number={335} />
      </Typography>
    </Blockquote>
  </Page>
)
