import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet3'));

export const Chapter17 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      “We’ve proven” the election was stolen, but “no judge, including the Supreme Court of the United States, has had the courage to allow it to be heard.”
      <Citation number={112} />
      That was how President Trump described efforts to overturn the election in court one day before the electoral college met on December 14, 2020. That was false.
    </Typography>

    <Typography className="indent">
      Judges across the nation did evaluate President Trump’s claims that the election was stolen. As longtime Republican election attorney Benjamin Ginsberg testified before the Select Committee, the President’s camp “did have their day in court,” it’s just that “in no instance did a court find that the charges of fraud were real.”
      <Citation number={113} />
      In total, the Trump Campaign and allies of President Trump filed 62 separate lawsuits between November 4, 2020, and January 6, 2021, calling into question or seeking to overturn the election results.
      <Citation number={114} />
      Out of 62 cases, only one case resulted in a victory for the President Trump or his allies, which affected relatively few votes, did not vindicate any underlying claims of fraud, and would not have changed the outcome in Pennsylvania.
      <Citation number={115} />
      Thirty of the cases were dismissed by a judge after a hearing on the merits.
      <Citation number={116} />
    </Typography>

    <Typography className="indent">
      In every State in which claims were brought, one or more judges specifically explained as part of their dismissal orders that they had evaluated the plaintiffs’ allegations or supposed proof of widespread election fraud or other irregularities, and found the claims to be entirely unconvincing. In Arizona, for example, the plaintiffs in Bowyer v. Ducey alleged that the election was tainted by the introduction of “hundreds of thousands of illegal, ineligible, duplicate or purely fictitious ballots.”
      <Citation number={117} />
      A Federal judge dismissed their suit, finding it “void of plausible allegations” and “sorely wanting of relevant or reliable evidence.”
      <Citation number={118} />
      Likewise, in Ward v. Jackson, an Arizona State-court judge dismissed a lawsuit by the State GOP chair following a two-day trial, finding no evidence of misconduct, fraud, or illegal votes.
      <Citation number={119} />
      This ruling was unanimously upheld by the State supreme court, where all seven justices were appointed by GOP governors.
      <Citation number={120} />
    </Typography>

    <Image src="/images/image-031.jpg" alt="court cases" />

    <Typography className="indent">
      In Georgia, a State court dismissed Boland v. Raffensperger, which alleged that tens of thousands of illegal ballots were cast by out-of-State voters or with invalid signature matches.
      <Citation number={121} />
      The judge found that “the Complaint’s factual allegations... rest on speculation rather than duly pled facts” and “do not support... a conclusion that sufficient illegal votes were cast to change or place in doubt the result of the election.”
      <Citation number={122} />
      The judge who issued this decision had been appointed by a Republican governor, as had seven of the eight justices of the State supreme court who upheld her ruling.
      <Citation number={123} />
      Likewise, a Federal judge denied relief to the plaintiff in Wood v. Raffensperger, which alleged that new procedures for checking absentee ballot signatures spoiled the result by making it harder to reject illegal ballots, finding “no basis in fact or law to grant him the relief he seeks.”
      <Citation number={124} />
      The judge wrote that “[t]his argument is belied by the record” because absentee ballots were actually rejected for signature issues at the same rate as in 2018.
      <Citation number={125} />
    </Typography>

    <Typography className="indent">
      In Michigan, a Federal judge found in King v. Whitmer that the plaintiffs’ claims of “massive election fraud” were based on “nothing but speculation and conjecture that votes for President Trump were destroyed, discarded or switched to votes for Vice President Biden....”
      <Citation number={126} />
      Similarly, a State-court judge rejected plaintiffs’ claims in two cases brought against Detroit and the surrounding county that accused them of systematic fraud in how absentee ballots were counted; the judge found that one group of plaintiffs “...offered no evidence to support their assertions,”
      <Citation number={127} />
      and that the other group’s “interpretation of events is incorrect” and “decidedly contradicted” by “highly-respected” election experts.
      <Citation number={128} />
    </Typography>

    <Typography className="indent">
      In Nevada, a State-court judge rejected a litany of claims of systematic election fraud in Law v. Whitmer, ruling that plaintiffs “did not prove under any standard of proof that illegal votes were cast and counted, or legal votes were not counted at all, due to voter fraud” or “for any other improper or illegal reason.”
      <Citation number={129} />
      The ruling was unanimously upheld by the Nevada Supreme Court.
      <Citation number={130} />
    </Typography>

    <Typography className="indent">
      In Pennsylvania, a Federal judge dismissed Donald Trump for President v. Boockvar, finding that the Trump Campaign had presented nothing but “strained legal arguments without merit and speculative accusations unpled in the operative complaint and unsupported by evidence.”
      <Citation number={131} />
      The dismissal was upheld by the United States Court of Appeals for the Third Circuit, which held: “[C]alling an election unfair does not make it so. Charges require specific allegations and then proof. We have neither here.”
      <Citation number={132} />
      That opinion was authored by another Trump appointee.
      <Citation number={133} />
    </Typography>

    <Typography className="indent">
      Lastly, in Wisconsin, another judge dismissed a lawsuit accusing the Wisconsin Elections Commission of “constitutional violations” that “likely tainted more than 50,000 ballots.”
      <Citation number={134} />
      The judge ruled: “This Court has allowed plaintiff the chance to make his case and he has lost on the merits,” failing to show that the outcome was affected by Commission rules about drop boxes, ballot addresses, or individuals who claimed “indefinitely confined” status to vote from home.
      <Citation number={135} />
      The ruling was upheld by a three-judge panel of the United States Court of Appeals for the Seventh Circuit, all of whom were Republican appointees, including one appointed by President Trump himself.
      <Citation number={136} />
    </Typography>

    <Typography className="indent">
      In all, the judges who heard these post-election cases included 22 Federal judges appointed by Republican presidents.
      <Citation number={137} />
    </Typography>

    <Typography className="indent">
      President Trump and his lawyers were well-aware that courts were consistently rejecting his claims. During a December 18th meeting in the Oval Office with President Trump, Sidney Powell and others, White House Senior Advisor Eric Herschmann pointed out that President Trump’s lawyers had their opportunity to prove their case in court, and failed. Powell fired back that “the judges are corrupt.” Herschmann responded: “Every one? Every single case that you’ve done in the country you guys lost, every one of them is corrupt, even the ones we appointed?”
      <Citation number={138} />
    </Typography>

    <Typography className="indent">
      President Trump was faced with another choice after having his day in court. He could accept that there was no real evidence of voter fraud, or he could continue to amplify conspiracy theories and lies. He chose the latter.
    </Typography>
  </Page>
)