import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet2'));

export const SummoningAMob = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      In the early morning hours of December 19th, shortly after the contentious December 18th White House meeting with Sidney Powell and others, Donald Trump sent a tweet urging his supporters to travel to Washington for January 6th. In that tweet, President Trump attached false allegations that the election was stolen and promised a “wild” time on January 6th.
      <Citation number={319} />
      This Twitter invitation was followed by over a dozen other instances in which he used Twitter to encourage supporters to rally for him in Washington, DC on January 6th.
      <Citation number={320} />
    </Typography>

    <Typography className="indent">
      The Committee has assembled detailed material demonstrating the effects of these communications on members of far-right extremist groups, like the Proud Boys, Oath Keepers, Three Percenters, and others, and on individuals looking to respond to their president’s call to action. President Trump’s supporters believed the election was stolen because they listened to his words,
      <Citation number={321} />
      and they knew what he had called them to do; stop the certification of the electoral count.
      <Citation number={322} />
    </Typography>

    <Typography className="indent">
      For example, one supporter, Charles Bradford Smith, noted on December 22, 2020, that “Trump is asking everyone to go” to Washington, DC on January 6th “to fill the streets” on the “day Pence counts up the votes.”
      <Citation number={323} />
      Derek Sulenta posted to Facebook on December 23, 2020, that “I’ll be there Jan 6th to support the president no matter what happens” because “That’s the day he called for patriots to show up.”
      <Citation number={324} />
      By December 31, 2020, Robert Morss believed January 6th stood for the moment when “1776 Will Commence Again” because President Trump asked them to “Be there, Will be Wild.”
      <Citation number={325} />
      Kenneth Grayson predicted what would eventually happen on January 6th, when on December 23, 2020, he wrote on Facebook that President Trump called people to Washington, DC through his December 19th tweet and then added “IF TRUMP TELLS US TO STORM THE FUKIN CAPITAL IMA DO THAT THEN!”
      <Citation number={326} />
      Some demonstrated their inspiration for January 6th by circulating flyers, which proclaimed “#OccupyCongress” over images of the United States Capitol.
      <Citation number={327} />
      Robert Gieswein, a Coloradan affiliated with Three Percenters who was among the first to breach the Capitol, said that he came to Washington, DC “to keep President Trump in.”
      <Citation number={328} />
    </Typography>

    <Typography className="indent">
      Chapter 8 of this report documents how the Proud Boys led the attack, penetrated the Capitol, and led hundreds of others inside. Multiple Proud Boys reacted immediately to President Trump’s December 19th tweet and began their planning. Immediately, Proud Boys leaders reorganized their hierarchy, with Enrique Tarrio, Joseph Biggs, and Ethan Nordean messaging groups of Proud Boys about what to expect on January 6th.
      <Citation number={329} />
      Tarrio created a group chat known as the Ministry of Self-Defense for handselected Proud Boys whom he wanted to “organize and direct” plans for January 6th.
      <Citation number={330} />
      On social media, Tarrio referenced “revolt” and “[r]evolution,” and conspicuously asked “What if we invade it?” on Telegram.
      <Citation number={331} />
      As of December 29, 2020, Tarrio told the group the events on January 6th would be “centered around the Capitol.”
      <Citation number={332} />
    </Typography>

    <Typography className="indent">
      At the time of publication of this report, prosecutions of certain Proud Boys are ongoing. To date, one Proud Boy has pled guilty to seditious conspiracy and other Proud Boys have pled guilty to other crimes, including conspiracy to obstruct Congress.
      <Citation number={333} />
      Jeremy Bertino, a Proud Boy who pled guilty to seditious conspiracy, admitted that he:
    </Typography>

    <Blockquote>
      <Typography>
        understood from internal discussions among the Proud Boys that in the leadup to January 6, the willingness to resort to unlawful conduct increasingly included a willingness to use and promote violence to achieve political objectives.
        <Citation number={334} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Moreover,
    </Typography>

    <Blockquote>
      <Typography>
        Bertino believed that the 2020 election had been “stolen” and, as January 6, 2021, approached, believed that drastic measures, including violence, were necessary to prevent Congress from certifying the Electoral College Vote on January 6, 2021. Bertino made his views in this regard known publicly, as well as in private discussions with MOSD leadership. Bertino understood from his discussions with MOSD leadership that they agreed that the election had been stolen, that the purpose of traveling to Washington, D.C., on January 6, 2021, was to stop the certification of the Electoral College Vote, and that the MOSD leaders were willing to do whatever it would take, including using force against police and others, to achieve that objective.
        <Citation number={335} />
      </Typography>
      <Typography>
        As set out in Bertino’s plea agreement, members of MOSD:
      </Typography>
      <Typography>
        openly discussed plans for potential violence at the Capitol ... and] members of MOSD leadership were discussing the possibility of storming the Capitol. Bertino believed that storming the Capitol would achieve the group's goal of stopping Congress from certifying the Electoral College Vote. Bertino understood that storming the Capitol or its grounds would be illegal and would require using force against police or other government officials.
        <Citation number={336} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Another Proud Boy who has pled guilty to conspiracy and assault charges, Charles Donohoe, understood that the Proud Boys planned to storm the Capitol. Donohoe, a Proud Boys local chapter leader from North Carolina:
    </Typography>

    <Blockquote>
      <Typography>
        was aware [as early as January 4, 2021] that members of MOSD leadership were discussing the possibility of storming the Capitol. Donohoe believed that storming the Capitol would achieve the group’s goal of stopping the government from carrying out the transfer of presidential power.
        <Citation number={337} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The Department of Justice has charged a number of Oath Keepers with seditious conspiracy. Specifically, the government alleges that “[a]fter the Presidential Election, Elmer Stewart Rhodes III conspired with his co-defendants, introduced below, and other co-conspirators, known and unknown to the Grand Jury, to oppose by force the lawful transfer of presidential power.”
      <Citation number={338} />
      A jury agreed, convicting Stewart Rhodes and Kelly Meggs—the leader of the Florida Oath Keepers chapter—of seditious conspiracy. The jury also convicted Rhodes and Meggs, as well as fellow Oath Keepers Jessica Watkins, Kenneth Harrelson, and Thomas Caldwell,
      <Citation number={339} />
      of other serious felonies for their actions on January 6th.
      <Citation number={340} />
    </Typography>

    <Typography className="indent">
      Meggs celebrated the December 19th tweet, sending an encrypted Signal message to Florida Oath Keepers that President Trump “wants us to make it WILD that’s what he’s saying. He called us all to the Capitol and wants us to make it wild!!!... Gentlemen we are heading to DC pack your shit!!”
      <Citation number={341} />
      Similarly, Oath Keeper Joshua James—who pleaded guilty to seditious conspiracy—told Oath Keepers that there was now a “NATIONAL CALL TO ACTION FOR DC JAN 6TH” following President Trump’s words.
      <Citation number={342} />
    </Typography>

    <Typography className="indent">
      Stewart Rhodes, the Oath Keepers’ founder, felt that “the time for peaceful protest is over” after December 19th and, according to the government, “urged President Trump to use military force to stop the lawful transfer of presidential power, describing January 6, 2021, as “a hard constitutional deadline” to do so.
      <Citation number={343} />
      Rhodes created a “an invitation-only Signal group chat titled, ‘DC OP: Jan 6 21’” on December 30, 2020, which he and other Oath Keepers, like Meggs and James, used to plan for January 6th, including by creating a “quick reaction force” of firearms to be stashed in Virginia.
      <Citation number={344} />
    </Typography>

    <Typography className="indent">
      Multiple members of the Oath Keepers have pleaded guilty to seditious conspiracy. Brian Ulrich started planning for January 6th right after President Trump sent out his December 19th tweet. The Department of Justice summarized Ulrich’s communications, as follows:
    </Typography>

    <Blockquote>
      <Typography>
        Ulrich messaged the “Oath Keepers of Georgia” Signal group chat, “Trump acts now maybe a few hundred radicals die trying to burn down cities... Trump sits on his hands Biden wins... millions die resisting the death of the 1st and 2nd amendment.” On December 20, 2020, an individual in the “Oath Keepers of Georgia” Signal group chat, who later traveled with Ulrich to Washington, D.C., and breached the Capitol grounds with Ulrich on January 6, 2021, messaged, “January 6th. The great reset. America or not.”
        <Citation number={345} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The Justice Department’s Statement of Offense for Oath Keeper Joshua James provided these details:
    </Typography>

    <Blockquote>
      <Typography>
        In advance of and on January 6, 2021, James and others agreed to take part in the plan developed by Rhodes to use any means necessary, up to and including the use of force, to stop the lawful transfer of presidential power. In the weeks leading up to January 6, 2021, Rhodes instructed James and other coconspirators to be prepared, if called upon, to report to the White House grounds to secure the perimeter and use lethal force if necessary against anyone who tried to remove President Trump from the White House, including the National Guard or other government actors who might be sent to remove President Trump as a result of the Presidential Election.
        <Citation number={346} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The former President’s call also galvanized Three Percenters to act. A group known as The Three Percenters Original sent a message to its members on December 16, 2020, noting they “stand ready and are standing by to answer the call from our President should the need arise” to combat the “pure evil that is conspiring to steal our country away from the american people” through the “2020 presidential election.”
      <Citation number={347} />
      After President Trump’s tweet, the group put out another letter instructing “any member who can attend... to participate” on January 6th because “[t]he President of the United States has put out a general call for the patriots of this Nation to gather” in Washington, DC.
      <Citation number={348} />
    </Typography>

    <Typography className="indent">
      Other Three Percenter groups also responded. Alan Hostetter and Russell Taylor led a group of Three Percenters calling themselves the California Patriots–DC Brigade, who have been charged with conspiracy to obstruct Congress because they organized to fight to keep President Trump in power on January 6th after President Trump’s December 19th tweet inspired them to come to Washington, DC.
      <Citation number={349} />
      On December 19th, Hostetter posted on Instagram:
    </Typography>

    <Blockquote>
      <Typography>
        President Trump tweeted that all patriots should descend on Washington DC on Wednesday l/6/2021. This is the date of the Joint Session of Congress in which they will either accept or reject the fake/ phony/stolen electoral college votes.
        <Citation number={350} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Between December 19th and January 6th, Hostetter, Taylor, and other members of the California Patriots–DC Brigade exchanged messages and posted to social media about bringing gear, including “weaponry,” like “hatchet[s],” “bat[s],” or “[l]arge metal flashlights,” and possibly “firearms,” and, about being “ready and willing to fight” like it was “1776.” Taylor even spoke in front of the Supreme Court on January 5, 2021, explaining that “[p]atriots” would “not return to our peaceful way of life until this election is made right....”
      <Citation number={351} />
      On December 29, 2020, Taylor exclaimed “I personally want to be on the front steps and be one of the first ones to breach the doors!”
      <Citation number={352} />
    </Typography>

    <Typography className="indent">
      Similarly, members of the Florida Guardians of Freedom, Three Percent sent around a flyer on December 24, 2020, saying they were “responding to the call from President Donald J. Trump to assist in the security, protection, and support of the people as we all protest the fraudulent election and re-establish liberty for our nation.”
      <Citation number={353} />
      Their leader, Jeremy Liggett, posted a meme to Facebook stating that “3% Will Show In Record Numbers In DC”
      <Citation number={354} />
      and put out a “safety video” instructing people that they could bring “an expandable metal baton, a walking cane and a folding knife”
      <Citation number={355} />
      to Washington, DC on January 6th. Several have been arrested for participating in the violence around the tunnel on January 6th.
      <Citation number={356} />
    </Typography>

    <Typography className="indent">
      When interviewed by the FBI on March 31, 2021, Danny Rodriguez—a Three Percenter from California who tased Officer Michael Fanone in the neck as rioters tried to break through a door on the west side of the Capitol—reflected on his decision to go to Washington, DC
      <Citation number={357} />
    </Typography>

    <Blockquote>
      <Typography>
        Trump called us to D.C... and he’s calling for help—I thought he was calling for help. I thought he was—I thought we were doing the right thing... [W]e thought we were going to hit it like a civil war. There was going to be a big battle... I thought that the main fight, the main battle, was going to be in D.C. because Trump called everyone there.
        <Citation number={358} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      These groups were not operating in silos. Meggs bragged on Facebook that following President Trump’s December 19th tweet he had formed an alliance between the Oath Keepers, the Florida Three Percenters, and the Proud Boys “to work together to shut this shit down.”
      <Citation number={359} />
      On December 19th, Meggs called Enrique Tarrio and they spoke for more than three minutes.
      <Citation number={360} />
      Three days later, Meggs messaged Liggett, echoing his excitement about the December 19th tweet and specifically referencing the seat of Congress: “He called us all to the Capitol and wants us to make it wild!!!”
      <Citation number={361} />
      Liggett said “I will have a ton of men with me” and Meggs replied that “we have made Contact [<i>sic</i>] with PB [Proud Boys] and they always have a big group. Force multiplier... I figure we could splinter off the main group of PB and come up behind them. Fucking crush them for good.”
      <Citation number={362} />
      Aside from Meggs, Stewart Rhodes brought in at least one local militia leader
      <Citation number={363} />
      and Three Percenters into the Oath Keepers January 6th planning chats that came about following President Trump’s tweet.
      <Citation number={364} />
    </Typography>

    <Typography className="indent">
      Even on January 6th, rioters referenced the tweet. An unknown rioter was caught on video as they ascended the Capitol steps saying “He said it was gonna be wild. He didn’t lie.”
      <Citation number={365} />
      MPD body-worn cameras captured Cale Clayton around 3:15 p.m. as he taunted officers from under the scaffolding: “Your fucking president told us to be here. You should be on this side, right here, going with us. You are an American citizen. Your fucking President told you to do that. You too. You too. You. All of you guys. That Tweet was for you guys. For us. For you.”
      <Citation number={366} />
    </Typography>

    <Typography className="indent">
      As January 6th neared, intelligence emerged indicating that January 6th was likely to be violent, and specifically that the Capitol was a target. On January 3rd, an intelligence summary informed Department of Justice officials of plans to “occupy the Capitol” and “invade” the Capitol on January 6th. This summarized a “SITE Intelligence Group” report about the “online rhetoric focused on the 6 Jan event.” Some of the reporting includes: “Calls to occupy federal buildings.” “intimidating Congress and invading the capitol building.” The email also quoted WUSA9 local reporting: “one of the websites used for organizing the event was encouraging attendees to bring guns.”
    </Typography>

    <Typography className="indent">
      Acting Deputy Attorney General Richard Donoghue testified:
    </Typography>

    <Blockquote>
      <Typography>
        And we knew that if you have tens of thousands of very upset people showing up in Washington, DC, that there was potential for violence.
        <Citation number={368} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      At the same time, a Defense Department official predicted on a White House National Security Council call that violence could be targeted at the Capitol on January 6th. According to Chairman of the Joint Chiefs of Staff Gen. Mark Milley:
    </Typography>

    <Blockquote>
      <Typography>
        So during these calls, I—I only remember in hindsight because he was almost like clairvoyant. [Deputy Secretary of Defense David] Norquist says during one of these calls, the greatest threat is a direct assault on the Capitol. I’ll never forget it.
        <Citation number={369} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Likewise, documentation received by the Committee from the Secret Service demonstrates a growing number of warnings both that January 6th was likely to be violent, and specifically that the Capitol would likely be the target, including intelligence directly regarding the Proud Boys and Oath Keepers militia groups.
    </Typography>

    <Typography className="indent">
      Even two weeks ahead of January 6th, the intelligence started to show what could happen. On December 22, 2020, the FBI received a screenshot of an online chat among Oath Keepers, seemingly referring to the State capitols besieged by protesters across the country earlier that year: “if they were going to go in, then they should have went all the way.”
      <Citation number={370} />
      “There is only one way. It is not signs. It’s not rallies. It’s fucking bullets,” one user replied.
      <Citation number={371} />
    </Typography>

    <Typography className="indent">
      A public source emailed the Secret Service a document titled “Armed and Ready, Mr. President,” on December 24th, which summarized online comments responding to President Trump’s December 19th tweet.
      <Citation number={372} />
      Protestors should “start marching into the chambers,” one user wrote.
      <Citation number={373} />
      Trump “can’t exactly openly tell you to revolt,” another replied. “This is the closest he’ll ever get.”
      <Citation number={374} />
      “I read [the President’s tweet] as armed,” someone said.
      <Citation number={375} />
      “[T]here is not enough cops in DC to stop what is coming,” replied yet another.
      <Citation number={376} />
      “[B]e already in place when Congress tries to get to their meeting,” the comments continued, and “make sure they know who to fear.’”
      <Citation number={377} />
      “[W]aiting for Trump to say the word,” a person said, and “this is what Trump expects,” exclaimed another.
      <Citation number={378} />
      Capitol Police’s head of intelligence, Jack Donohue, got the same compilation from a former colleague at the New York Police Department on December 28, 2020.
      <Citation number={379} />
    </Typography>

    <Typography className="indent">
      On December 26, 2020, the Secret Service received a tip about the Proud Boys detailing plans to have “a large enough group to march into DC armed [that] will outnumber the police so they can’t be stopped.”
      <Citation number={380} />
      “Their plan is to literally kill people,” the informant stated. “Please please take this tip seriously... ”
      <Citation number={381} />
      On December 29, 2020, Secret Service forwarded related warnings to Capitol Police that pro-Trump demonstrators were being urged to “occupy federal building[s],” including “march[ing] into the capital building and mak[ing] them quake in their shoes by our mere presence.”
      <Citation number={382} />
    </Typography>

    <Typography className="indent">
      Civilians also tipped off Capitol Police about people bringing weapons to besiege the Capitol. One tipster, who had “track[ed] online far right extremism for years,” emailed Capitol Police warning “I’ve seen countless tweets from Trump supporters saying they will be armed,” and “I[’]ve also seen tweets from people organizing to ‘storm the Capitol’ on January 6th.”
      <Citation number={383} />
    </Typography>

    <Typography className="indent">
      On December 29, 2020, Secret Service forwarded related warnings to Capitol Police that pro-Trump demonstrators were being urged to “occupy federal building,” including “march[ing] into the capital building and mak[ing] them quake in their shoes by our mere presence.”
      <Citation number={384} />
      Indeed, a Secret Service intelligence briefing on December 30th entitled “March for Trump,” highlighted the President’s “Will be wild!” tweet alongside hashtags #WeAreTheStorm, #1776Rebel, and #OccupyCapitols, writing “President Trump supporters have proposed a movement to occupy Capitol Hill.”
      <Citation number={385} />
    </Typography>

    <Typography className="indent">
      On January 1, 2021, a lieutenant in the intelligence branch at DC Police forwarded a civilian tip about “a website planning terroristic behavior on Jan 6th, during the rally” to Capitol Police intelligence.
      <Citation number={386} />
      “There are detailed plans to storm federal buildings,” including “the capitol in DC on Jan 6th,” the tipster reported, linking to thedonald.win.
      <Citation number={387} />
    </Typography>

    <Typography className="indent">
      On January 2, 2021, the FBI discovered a social media posting that read, “This is not a rally and it’s no longer a protest. This is a final stand... many are ready to die to take back #USA.... And don’t be surprised if we take the #capital building.”
      <Citation number={388} />
    </Typography>

    <Typography className="indent">
      On January 3, 2021, a Parler user’s post—under the name 1776(2.0) Minuteman— noting “after weds we are going to need a new congress” and “Jan 6 may actually be their [Members of Congress] last day in office” reached the FBI and Capitol Police.
      <Citation number={389} />
    </Typography>

    <Typography className="indent">
      The FBI field office in Norfolk, Virginia issued an alert to law enforcement agencies on January 5th tiled “Potential for Violence in Washington, D.C. Area in Connection with Planned ‘StopTheSteal’ Protest on 6 January 2021,” which noted:
    </Typography>

    <Blockquote>
      <Typography>
        An online thread discussed specific calls for violence to include stating, “Be ready to fight. Congress needs to hear glass breaking, doors being kicked in, and blood... being spilled. Get violent... stop calling this a march, or rally, or a protest. Go there ready for war. We get our President or we die. NOTHING else will achieve this goal.”
        <Citation number={390} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      In addition, the alert copied “perimeter maps [of the Capitol] and caravan pictures [that] were posted” on thedonald.win, particularly worrying that the “caravans... had the same colors as the sections of the perimeter” of the Capitol.
      <Citation number={391} />
      Secret Service also knew about caravans planning to come to DC to “Occupy the Capitol.”
      <Citation number={392} />
    </Typography>

    <Typography className="indent">
      That same day, representatives from DHS, FBI, DC’s Homeland Security and Emergency Management Agency, Secret Service, DC Police, and Capitol Police shared a website, Red State Secession, which had a post titled “Why the Second American Revolution Starts Jan 6.” A user asked visitors to post where they could find the home addresses of Democratic congressmen and “political enemies” and asked if “any of our enemies [will] be working in offices in DC that afternoon.” 
      <Citation number={393} />
      “What are their routes to and from the event?” the post continued.
      <Citation number={394} />
      “[T]he crowd will be looking for enemies.”
      <Citation number={395} />
    </Typography>

    <Typography className="indent">
      A Secret Service open-source unit flagged an account on thedonald.win that threatened to bring a sniper rifle to a rally on January 6th. The user also posted a picture of a handgun and rifle with the caption, “Sunday Gun Day Providing Overwatch January 6th Will be Wild.”
      <Citation number={396} />
    </Typography>

    <Typography className="indent">
      The Secret Service learned from the FBI on January 5th about rightwing groups establishing armed quick reaction forces in Virginia, where they could amass firearms illegal in DC
      <Citation number={397} />
      Trump supporters staged there waiting across the river “to respond to ‘calls for help.’”
      <Citation number={398} />
      The Oath Keepers were such a group.
      <Citation number={399} />
    </Typography>

    <Typography className="indent">
      President Trump’s closest aides knew about the political power of sites like thedonald.win, which is where much of this violent rhetoric and planning happened. On December 30, 2020, Jason Miller—a Senior Adviser to and former spokesman for the former President—texted Chief of Staff Mark Meadows a link to the thedonald.win, adding “I got the base FIRED UP.”
      <Citation number={400} />
      The link connected to a page with comments like “Gallows don’t require electricity,” “if the filthy commie maggots try to push their fraud through, there will be hell to pay,” and Congress can certify Trump the winner or leave “in a bodybag.”
      <Citation number={401} />
      Symbolic gallows were constructed on January 6th at the foot of the Capitol.
      <Citation number={402} />
    </Typography>

    <Typography className="indent">
      After President Trump’s signal, his supporters did not hide their plans for violence at the Capitol, and those threats made their way to national and local law enforcement agencies. As described in this report, the intelligence agencies did detect this planning, and they shared it with the White House and with the U.S. Secret Service.
    </Typography>

    <Image
      src="/images/image-018.jpg"
      alt="Noose set up outside of the Capitol on January 6th."
      caption="Noose set up outside of the Capitol on January 6th."
    />

    <Typography className="indent">
      Testimony from White House staff also suggests real concerns about the risk of violence as January 6th approached. Cassidy Hutchinson, for example, testified about a conversation she had with her boss, Mark Meadows, on January 2nd:
    </Typography>

    <Blockquote>
      <Typography>
        I went into Mark’s office, and he was still on his phone.... I said to Mark, “Rudy [Giuliani] said these things to me. What’s going on here? Anything I should know about?”
      </Typography>
      <Typography>
        This was—he was, like, looking at his phone. He was like, “Oh, it’s all about the rally on Wednesday. Isn’t that what he was talking to you about?”
      </Typography>
      <Typography>
        I said, “Yeah. Yeah, sounds like we’re going to the Capitol.”
      </Typography>
      <Typography>
        He said, “Yeah. Are you talking with Tony?”
      </Typography>
      <Typography>
        “I’m having a conversation, sir.”
      </Typography>
      <Typography>
        He said—still looking at his phone. I remember he was scrolling. He was like, “Yeah. You know, things might get real, real bad on the 6th.”
      </Typography>
      <Typography>
        And I remember saying to him, “What do you mean?”
      </Typography>
      <Typography>
        He was like, “I don’t know. There’s just going to be a lot of people here, and there’s a lot of different ideas right now. I’m not really sure of everything that’s going on. Let's just make sure we keep tabs on it.”
        <Citation number={403} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Hutchinson also testified about a conversation she had with Director of National Intelligence, Ratcliffe:
    </Typography>

    <Blockquote>
      <Typography>
        He had expressed to me that he was concerned that it could spiral out of control and potentially be dangerous, either for our democracy or the way that things were going for the 6th.
        <Citation number={404} />
      </Typography>
    </Blockquote>

    <Image
      src="/images/image-019.jpg"
      alt="Mark Meadows walks along the South Lawn on October 30, 2020."
      caption="Mark Meadows walks along the South Lawn on October 30, 2020."
      credit="(Photo by Sarah Silbiger/Getty Images)"
    />

    <Typography className="indent">
      Hope Hicks texted Trump Campaign spokesperson Hogan Gidley in the midst of the January 6th violence, explaining that she had “suggested... several times” on the preceding days (January 4th and January 5th) that President Trump publicly state that January 6th must remain peaceful and that he had refused her advice to do so.
      <Citation number={405} />
      Her recollection was that Herschmann earlier advised President Trump to make a preemptive public statement in advance of January 6th calling for no violence that day.
      <Citation number={406} />
      No such statement was made.
    </Typography>

    <Typography className="indent">
      The District of Columbia Homeland Security office explicitly warned that groups were planning to “occupy the [Capitol] to halt the vote.”
      <Citation number={407} />
    </Typography>

    <Blockquote>
      <Typography>
        [W]e got derogatory information from OSINT suggesting that some very, very violent individuals were organizing to come to DC, and not only were they organized to come to DC, but they were—these groups, these nonaligned groups were aligning. And so all the red flags went up at that point, you know, when you have armed militia, you know, collaborating with White supremacy groups, collaborating with conspiracy theory groups online all toward a common goal, you start seeing what we call in, you know, terrorism, a blended ideology, and that’s a very, very bad sign... [T]hen when they were clearly across—not just across one platform but across multiple platforms of these groups coordinating, not just like chatting, “Hey, how’s it going, what’s the weather like where you’re at,” but like, “what are you bringing, what are you wearing, you know, where do we meet up, do you have plans for the Capitol.” That’s operational—that’s like preoperational intelligence, right, and that is something that's clearly alarming.
        <Citation number={408} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Again, this type of intelligence was shared, including obvious warnings about potential violence prior to January 6th.
      <Citation number={409} />
      What was not shared, and was not fully understood by intelligence and law enforcement entities, is what role President Trump would play on January 6th in exacerbating the violence, and later refusing for multiple hours to instruct his supporters to stand down and leave the Capitol. No intelligence collection was apparently performed on President Trump’s plans for January 6th, nor was there any analysis performed on what he might do to exacerbate potential violence. Certain Republican members of Congress who were working with Trump and the Giuliani team may have had insight on this particular risk, but none appear to have alerted the Capitol Police or any other law enforcement authority.
    </Typography>

    <Typography className="indent">
      On January 2, 2021, Katrina Pierson wrote in an email to fellow rally organizers, “POTUS expectations are to have something intimate at the [E]llipse, and call on everyone to march to the Capitol.”
      <Citation number={410} />
      And, on January 4, 2021, another rally organizer texted Mike Lindell, the MyPillow CEO, that President Trump would “unexpectedly” call on his supporters to march to the Capitol:
    </Typography>

    <Blockquote>
      <Typography>
        This stays only between us.... It can also not get out about the march because I will be in trouble with the national park service and all the agencies but POTUS is going to just call for it “unexpectedly.”
        <Citation number={411} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Testimony obtained by the Committee also indicates that President Trump was specifically aware that the crowd he had called to Washington was fired up and angry on the evening of January 5th. Judd Deere, a deputy White House press secretary recalled a conversation with President Trump in the Oval Office on the evening of January 5th:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Judd Deere</strong>: I said he should focus on policy accomplishments. I didn’t mention the 2020 election.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: Okay. What was his response?
      </Typography>
      <Typography>
        <strong>Deere</strong>: He acknowledged that and said, “We’ve had a lot,” something along those lines, but didn’t—he fairly quickly moved to how fired up the crowd is, or was going to be.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: Okay. What did he say about it?
      </Typography>
      <Typography>
        <strong>Deere</strong>: Just that they were—they were fired up. They were angry. They feel like the election’s been stolen, that the election was rigged, that—he went on and on about that for a little bit.
        <Citation number={412} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Testimony indicated that President Trump was briefed on the risk of violence on the morning of the 6th before he left the White House. Cassidy Hutchinson provided this testimony:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Vice Chair Cheney</strong>: So, Ms. Hutchinson, is it your understanding that Mr. Ornato told the President about weapons at the rally on the morning of January 6th?
      </Typography>
      <Typography>
        <strong>Hutchinson</strong>: That is what Mr. Ornato relayed to me.
        <Citation number={413} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The head of President Trump’s security detail, Bobby Engel, told the Select Committee that when he shared critical information with White House Deputy Chief of Staff Anthony Ornato, it was a means of conveying that information with the Oval Office: “So, when it came to passing information to Mr. Ornato, I—my assumption was that it would get to the chief [of staff, Mark Meadows], or that he was sharing the information with the chief. I don’t—and the filtering process, or if the chief thinks it needs to get to the President, then he would share it with the President.”
      <Citation number={414} />
      Also, Engel confirmed that if “information would come to my attention, whether it was a protective intelligence issue or a concern or—primarily, I would—I would make sure that the information got filtered up through the appropriate chain usually through Mr. Ornato. So if I received a report on something that was happening in the DC area, I’d either forward that information to Mr. Ornato, or call him about that information or communicate in some way.”
      <Citation number={415} />
    </Typography>

    <Typography className="indent">
      The Select Committee also queried Deputy Chief of Staff Ornato this November about what he generally would have done in this sort of situation, asking him the following: “Generally you receive information about things like the groups that are coming, the stuff that we talked earlier. You would bring that to Mr. Meadows and likely did here, although you don’t have a specific recollection?”
      <Citation number={416} />
      Ornato responded: “That is correct, sir.”
      <Citation number={417} />
      Ornato also explained to the Committee that “... in my normal daily functions, in my general functions as my job, I would’ve had a conversation with him about all the groups coming in and what was expected from the secret service.”
      <Citation number={418} />
      As for the morning of January 6th itself, he had the following answer:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: Do you remember talking to Chief of Staff Mark Meadows about any of your concerns about the threat landscape going into January 6th?
      </Typography>
      <Typography>
        <strong>Ornato</strong>: I don’t recall; however, in my position I would’ve made sure he was tracking the demos, which he received a daily brief, Presidential briefing. So he most likely was getting all this in his daily brief as well. I wouldn’t know what was in his intelligence brief that day, but I would’ve made sure that he was tracking these things and just mentioned, “Hey, are you tracking the demos?” If he gave me a “yeah”, I don’t recall it today, but I’m sure that was something that took place.
        <Citation number={419} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Ornato had access to intelligence that suggested violence at the Capitol on January 6th, and it was his job to inform Meadows and President Trump of that. Although Ornato told us that he did not recall doing so, the Select Committee found multiple parts of Ornato’s testimony questionable. The Select Committee finds it difficult to believe that neither Meadows nor Ornato told President Trump, as was their job, about the intelligence that was emerging as the January 6th rally approached.
    </Typography>

    <Typography className="indent">
      Hours before the Ellipse rally on January 6th, the fact that the assembled crowd was prepared for potential violence was widely known. In addition to intelligence reports indicating potential violence at the Capitol, weapons and other prohibited items were being seized by police on the streets and by Secret Service at the magnetometers for the Ellipse speech. Secret Service confiscated a haul of weapons from the 28,000 spectators who did pass through the magnetometers: 242 cannisters of pepper spray, 269 knives or blades, 18 brass knuckles, 18 tasers, 6 pieces of body armor, 3 gas masks, 30 batons or blunt instruments, and 17 miscellaneous items like scissors, needles, or screwdrivers.
      <Citation number={420} />
      And thousands of others purposely remained outside the magnetometers, or left their packs outside.
      <Citation number={421} />
    </Typography>

    <Typography className="indent">
      Others brought firearms. Three men in fatigues from Broward County, Florida brandished AR-15s in front of Metropolitan police officers on 14th Street and Independence Avenue on the morning of January 6th.
      <Citation number={422} />
      MPD advised over the radio that one individual was possibly armed with a “Glock” at 14th and Constitution Avenue, and another was possibly armed with a “rifle” at 15th and Constitution Avenue around 11:23 a.m.
      <Citation number={423} />
      The National Park Service detained an individual with a rifle between 12 and 1 p.m.
      <Citation number={424} />
      Almost all of this was known before Donald Trump took the stage at the Ellipse.
    </Typography>

    <Image
      src="/images/image-020.jpg"
      alt="President Trump looks backstage at the crowd gathered at the Ellipse."
      caption="President Trump looks backstage at the crowd gathered at the Ellipse."
      credit="(Photo provided to the Select Committee by the National Archives and Records Administration)"
    />

    <Typography className="indent">
      By the time President Trump was preparing to give his speech, he and his advisors knew enough to cancel the rally. And he certainly knew enough to cancel any plans for a march to the Capitol. According to testimony obtained by the Select Committee, President Trump knew that elements of the crowd were armed, and had prohibited items, and that many thousands would not pass through the magnetometers for that reason. Testimony indicates that the President had received an earlier security briefing, and testimony indicates that the Secret Service mentioned the prohibited items again as they drove President Trump to the Ellipse.
    </Typography>

    <Typography className="indent">
      Cassidy Hutchinson was with the President backstage. Her contemporaneous text messages indicate that President Trump was “effing furious” about the fact that a large number of his supporters would not go through the magnetometers:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Cassidy Hutchinson</strong>: But the crowd looks good from this vanish [<i>sic</i>] point. As long as we get the shot. He was fucking furious
      </Typography>
      <Typography>
        <strong>Tony Ornato</strong>: He doesn’t get it that the people on the monument side don’t want to come in. They can see from there and don’t want to come in. They can see from there and don’t have to go through mags. With 30k magged inside.
      </Typography>
      <Typography>
        <strong>Cassidy Hutchinson</strong>: That’s what was relayed several times and in different iterations
      </Typography>
      <Typography>
        <strong>Cassidy Hutchinson</strong>: Poor max got chewed out
      </Typography>
      <Typography>
        <strong>Cassidy Hutchinson</strong>: He also kept mentioning [an off the record trip] to Capitol before he took the stage
      </Typography>
      <Typography>
        <strong>Tony Ornato</strong>: Bobby will tell him no. It’s not safe to do. No assets available to safely do it.
        <Citation number={425} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      And Hutchinson described what President Trump said as he prepared to take the stage:
    </Typography>

    <Blockquote>
      <Typography>
        When we were in the off-stage announce area tent behind the stage, he was very concerned about the shot. Meaning the photograph that we would get because the rally space wasn’t full. One of the reasons, which I’ve previously stated, was because he wanted it to be full and for people to not feel excluded because they had come far to watch him at the rally. And he felt the mags were at fault for not letting everybody in, but another leading reason and likely the primary reasons is because he wanted it full and he was angry that we weren’t letting people through the mags with weapons—what the Secret Service deemed as weapons, and are, are weapons. But when we were in the off-stage announce tent, I was a part of a conversation, I was in the vicinity of a conversation where I overheard the President say something to the effect of, “I don’t F’ing care that they have weapons. They’re not here to hurt me. Take the F’ing mags away. Let my people in. They can march to the Capitol from here. Let the people in. Take the F’ing mags away.”
        <Citation number={426} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The Secret Service special agent who drove the President after his speech told the Select Committee that Trump made a similar remark in the vehicle when his demand to go to the Capitol was refused—essentially that Trump did not believe his supporters posed a security risk to him personally.
      <Citation number={427} />
    </Typography>

    <Typography className="indent">
      Minutes after the exchange that Hutchinson described—when President Trump took the stage—he pointedly expressed his concern about the thousands of attendees who would not enter the rally area and instructed Secret Service to allow that part of the crowd to enter anyway:
    </Typography>

    <Blockquote>
      <Typography>
        ...I’d love to have if those tens of thousands of people would be allowed. The military, the secret service. And we want to thank you and the police law enforcement. Great. You’re doing a great job. But I’d love it if they could be allowed to come up here with us. Is that possible? Can you just let [them] come up, please?
        <Citation number={428} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Although President Trump and his advisors knew of the risk of violence, and knew specifically that elements of the crowd were angry and some were armed, from intelligence and law enforcement reports that morning, President Trump nevertheless went forward with the rally, and then specifically instructed the crowd to march to the Capitol: “Because you’ll never take back our country with weakness. You have to show strength and you have to be strong. We have come to demand that Congress do the right thing and only count the electors who have been lawfully slated, lawfully slated.”
      <Citation number={429} />
      Much of President Trump’s speech was improvised. Even before his improvisation, during the review of President Trump’s prepared remarks, White House lawyer Eric Herschmann specifically requested that “if there were any factual allegations, someone needed to independently validate or verify the statements.”
      <Citation number={430} />
      And in the days just before January 6th, Herschmann “chewed out” John Eastman and told him he was “out of [his] F’ing mind” to argue that the Vice President could be the sole decision-maker as to who becomes the next President.
      <Citation number={431} />
      Herschmann told us, “I so berated him that I believed that theory would not go forward.”
      <Citation number={432} />
      But President Trump made that very argument during his speech at the Ellipse and made many false statements. Herschmann attended that speech, but walked out during the middle of it.
      <Citation number={433} />
    </Typography>

    <Typography className="indent">
      President Trump’s speech to the crowd that day lasted more than an hour. The speech walked through dozens of known falsehoods about purported election fraud. And Trump again made false and malicious claims about Dominion voting systems.
      <Citation number={434} />
      As discussed earlier, he again pressured Mike Pence to refuse to count lawful electoral votes, going off script repeatedly, leading the crowd to believe falsely that Pence could and would alter the election outcome:
    </Typography>

    <Blockquote>
      <Typography>
        And I actually, I just spoke to Mike. I said: “Mike, that doesn’t take courage. What takes courage is to do nothing. That takes courage.” And then we’re stuck with a president who lost the election by a lot and we have to live with that for four more years. We’re just not going to let that happen....
      </Typography>
      <Typography>
        When you catch somebody in a fraud, you’re allowed to go by very different rules.
      </Typography>
      <Typography>
        So I hope Mike has the courage to do what he has to do. And I hope he doesn’t listen to the RINOs and the stupid people that he’s listening to.”
        <Citation number={435} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      This characterization of Vice President Pence’s decision had a direct impact on those who marched to and approached the Capitol, as illustrated by this testimony from a person convicted of crimes committed on January 6th:
    </Typography>

    <Blockquote>
      <Typography>
        So this woman came up to the side of us, and she, says, Pence folded. So it was kind of, like, okay. Well, in my mind I was thinking, ”Well, that’s it, you know.” Well, my son-in-law looks at me, and he says, ”I want to go in.”
        <Citation number={436} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Trump used the word “peacefully,” written by speech writers, one time. But he delivered many other scripted and unscripted comments that conveyed a very different message:
    </Typography>

    <Blockquote>
      <Typography>
        Because you’ll never take back our country with weakness. You have to show strength and you have to be strong. We have come to demand that Congress do the right thing and only count the electors who have been lawfully slated, lawfully slated....
      </Typography>
      <Typography>
        And we fight. We fight like hell. And if you don’t fight like hell, you’re not going to have a country anymore....
        <Citation number={437} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Trump also was not the only rally speaker to do these things. Giuliani, for instance, also said, “Let’s have trial by combat.”
      <Citation number={438} />
      Likewise, Eastman used his two minutes on the Ellipse stage to make a claim already known to be false—that corrupted voted machines stole the election.
      <Citation number={439} />
    </Typography>

    <Typography className="indent">
      The best indication of the impact of President Trump’s words, both during the Ellipse speech and beforehand, are the comments from those supporters who attended the Ellipse rally and their conduct immediately thereafter. Videoclips show several of the attendees on their way to the Capitol or shortly after they arrived:
    </Typography>

    <Blockquote>
      <Typography>
        I’m telling you what, I’m hearing that Pence—hearing the Pence just caved. No. Is that true? I didn’t hear it. I’m hear—I’m hearing reports that Pence caved. No way. I’m telling you, if Pence caved, we’re going to drag motherfuckers through the streets. You fucking politicians are going to get fucking drug through the streets.
        <Citation number={440} />
      </Typography>
      <Typography>
        Yes. I guess the hope is that there’s such a show of force here that Pence will decide do the right thing, according to Trump.
        <Citation number={441} />
      </Typography>
      <Typography>
        Pence voted against Trump. [Interviewer: Ok. And that’s when all this started?] Yup. That’s when we marched on the Capitol.
        <Citation number={442} />
      </Typography>
      <Typography>
        We just heard that Mike Pence is not going to reject any fraudulent electoral votes. [Other speaker: Boo. You're a traitor! Boo!] That’s right. You’ve heard it here first. Mike Pence has betrayed the United States of America. [Other speaker: Boo! Fuck you, Mike Pence!] Mike Pence has betrayed this President and he has betrayed the people of the United States and we will never, ever forget. [Cheers]
        <Citation number={443} />
      </Typography>
      <Typography>
        <strong>[Q]</strong> What percentage of the crowd is going to the Capitol?
      </Typography>
      <Typography>
        <strong>[A] [Oath Keeper Jessica Watkins]</strong>: One hundred percent. It has, it has spread like wildfire that Pence has betrayed us, and everybody’s marching on the Capitol. All million of us. It’s insane.
        <Citation number={444} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Another criminal defendant—charged with assaulting an officer with a flagpole and other crimes—explained in an interview why he went to the Capitol and fought:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Dale Huttle</strong>: We were not there illegally, we were invited there by the President himself.... Trump’s backers had been told that the election had been stolen....
      </Typography>
      <Typography>
        <strong>Reporter Megan Hickey</strong>: But do you think he encouraged violence?
      </Typography>
      <Typography>
        <strong>Dale Huttle</strong>: Well, I sat there, or stood there, with half a million people listening to his speech. And in that speech, both Giuliani and [Trump] said we were going to have to fight like hell to save our country. Now, whether it was a figure of speech or not—it wasn’t taken that way.
      </Typography>
      <Typography>
        <strong>Reporter Megan Hickey</strong>: You didn’t take it as a figure of speech?
      </Typography>
      <Typography>
        <strong>Dale Huttle</strong>: No.
        <Citation number={445} />
      </Typography>
      <Typography>
        President Trump concluded his speech at 1:10 p.m.
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Among other statements from the Ellipse podium, President Trump informed the crowd that he would be marching to the Capitol with them:
    </Typography>

    <Blockquote>
      <Typography>
        Now, it is up to Congress to confront this egregious assault on our democracy. And after this, we’re going to walk down, and I’ll be there with you, we’re going to walk down, we’re going to walk down. Anyone you want, but I think right here, we’re going to walk down to the Capitol, and we’re going to cheer on our brave senators and congressmen and women, and we’re probably not going to be cheering so much for some of them.
        <Citation number={446} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Hutchinson testified that she first became aware of President Trump’s plans to attend Congress’s session to count votes on or about January 2nd. She learned this from a conversation with Giuliani: “It’s going to be great. The President’s going to be there. He’s going to look powerful. He’s—he’s going to be with the members. He’s going to be with the Senators.”
      <Citation number={447} />
      Evidence also indicates that multiple members of the White House staff, including White House lawyers, were concerned about the President’s apparent intentions to go to the Capitol.
      <Citation number={448} />
    </Typography>

    <Typography className="indent">
      After he exited the stage, President Trump entered the Presidential SUV and forcefully expressed his intention that Bobby Engel, the head of his Secret Service detail, direct the motorcade to the Capitol. The Committee has now obtained evidence from several sources about a “furious interaction” in the SUV. The vast majority of witnesses who have testified before the Select Committee about this topic, including multiple members of the Secret Service, a member of the Metropolitan police, and national security and military officials in the White House, described President Trump’s behavior as “irate,” “furious,” “insistent,” “profane” and “heated.” Hutchinson heard about the exchange second-hand and related what she heard in our June 28, 2022, hearing from Ornato (as did another witness, a White House employee with national security responsibilities, who shared that Ornato also recounted to him President Trump’s “irate” behavior in the Presidential vehicle). Other members of the White House staff and Secret Service also heard about the exchange after the fact. The White House employee with national security responsibilities gave this testimony:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: But it sounds like you recall some rumor or some discussion around the West Wing about the President’s anger about being told that he couldn’t go to the Capitol. Is that right?
      </Typography>
      <Typography>
        <strong>Employee</strong>: So Mr. Ornato said that he was angry that he couldn’t go right away. In the days following that, I do remember, you know, again, hearing again how angry the President was when, you know, they were in the limo. But beyond specifics of that, that's pretty much the extent of the cooler talk.
        <Citation number={449} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The Committee has regarded both Hutchinson and the corroborating testimony by the White House employee with national security responsibilities as earnest and has no reason to conclude that either had a reason to invent their accounts. A Secret Service agent who worked on one of the details in the White House and was present in the Ellipse motorcade had this comment:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: Ms. Hutchinson has suggested to the committee that you sympathized with her after her testimony, and believed her account. Is that accurate?
      </Typography>
      <Typography>
        <strong>Special Agent</strong>: I have no—yeah, that’s accurate. I have no reason—I mean, we—we became friends. We worked—I worked every day with her for 6 months. Yeah, she became a friend of mine. We had a good working relationship. I have no reason—she’s never done me wrong. She’s never lied that I know of.
        <Citation number={450} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The Committee’s principal concern was that the President actually intended to participate personally in the January 6th efforts at the Capitol, leading the attempt to overturn the election either from inside the House Chamber, from a stage outside the Capitol, or otherwise. The Committee regarded those facts as important because they are relevant to President Trump’s intent on January 6th. There is no question from all the evidence assembled that President Trump <strong><i>did have that intent</i></strong>.
      <Citation number={451} />
    </Typography>

    <Typography className="indent">
      As it became clear that Donald Trump desired to travel to the Capitol on January 6th, a White House Security Official in the White House complex became very concerned about his intentions:
    </Typography>

    <Blockquote>
      <Typography>
        To be completely honest, we were all in a state of shock.... it just— one, I think the actual physical feasibility of doing it, and then also we all knew what that implicated and what that meant, that this was no longer a rally, that this was going to move to something else if he physically walked to the Capitol. I—I don’t know if you want to use the word “insurrection,” “coup,” whatever. We all knew that this would move from a normal, democratic, you know, public event into something else.
        <Citation number={452} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      President Trump continued to push to travel to the Capitol even after his return to the White House, despite knowing that a riot was underway. Kayleigh McEnany, the White House press secretary, spoke with President Trump about his desire to go to the Capitol after he returned to the White House from the Ellipse. “So to the best of my recollection, I recall him being—wanting to—saying that he wanted to physically walk and be a part of the march and then saying that he would ride the Beast if he needed to, ride in the Presidential limo.”
      <Citation number={453} />
    </Typography>

    <Typography className="indent">
      Later in the afternoon, Mark Meadows relayed to Cassidy Hutchinson that President Trump was still upset that he would not be able to go to the Capitol that day. As he told Hutchinson, “the President wasn’t happy that Bobby [Engel] didn’t pull it off for him and that Mark didn’t work hard enough to get the movement on the books.”
      <Citation number={454} />
    </Typography>
  </Page>
);
