import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { Blockquote } from '../../components/Blockquote';
import { Page } from '../../components/Page';

const ResponsiveDiv = styled('div')(({ theme }) => `
  display: block;
  overflow: hidden;

  img {
    margin-right: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
    max-width: 100%;
  }

  @media (min-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    display: flex;
    align-items: center;
  }
`);

const StyledTitle = styled(Typography)(({ theme }) => `
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1em;
  line-height: 1.5;

  color: ${
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.primary.main
  };

  ${theme.breakpoints.down('sm')} {
    font-size: 2em;
  },
`);

export const ForewordsSpeakerOfTheHouse = ({ ...args }: any) => (
  <Page {...args}>
    <StyledTitle color="primary" variant="h2">
      “THE LAST BEST HOPE OF EARTH”
    </StyledTitle>
    <Blockquote className="small">
      <Typography>
        “I do solemnly swear that I will support and defend the Constitution of the United States against all enemies, foreign and domestic; that I will bear true faith and allegiance to the same; that I take this obligation freely, without any mental reservation or purpose of evasion; and that I will well and faithfully discharge the duties of the office on which I am about to enter: So help me God.”
      </Typography>
    </Blockquote>
    <Typography className="indent">
      All Members of the United States Congress take this sacred oath. On January 6, 2021, Democrats and Republicans agreed that we would fulfill this oath— and that we had an obligation to signal to the world that American Democracy would prevail.
    </Typography>
    <Typography className="indent">
      In furtherance of fulfilling this duty, the Select Committee to Investigate the January 6th Attack on the United States Capitol was charged with investigating the facts, circumstances and causes that led to this domestic terror attack on the Capitol, the Congress and the Constitution.
    </Typography>
    <Typography className="indent">
      We owe a debt of gratitude to Chairman Bennie Thompson, Vice Chair Liz Cheney, the patriotic Members of Congress and dedicated staff—who devoted themselves to this investigation, to uncovering the truth and to writing a report that is a “Roadmap for Justice.”
    </Typography>
    <Typography className="indent">
      The Select Committee to Investigate the January 6th Attack has succeeded in bringing clarity and demonstrating with painstaking detail the fragility of our Democracy. Above all, the work of the Select Committee underscores that our democratic institutions are only as strong as the commitment of those who are entrusted with their care.
    </Typography>
    <Typography className="indent">
      As the Select Committee concludes its work, their words must be a clarion call to all Americans: to vigilantly guard our Democracy and to give our vote only to those dutiful in their defense of our Constitution.
    </Typography>
    <Typography className="indent">
      Let us always honor our oath to, as Abraham Lincoln said, “nobly save, or meanly lose, the last best hope of earth.” So help us God.
    </Typography>

    <br />

    <ResponsiveDiv>
      <img src="/images/image-003.jpg" alt="Speaker of the House Nancy Pelosi" />

      <div>
        <img src="/images/image-002.jpg" alt="Speaker of the House Nancy Pelosi, signature" />
        <Typography>
          <strong>NANCY PELOSI</strong>
        </Typography>
        <Typography>
          Speaker of the House
        </Typography>
      </div>
    </ResponsiveDiv>
  </Page>
)