import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet3'));

export const Chapter12 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
       On Halloween, advisor Steve Bannon, who had served four years earlier as Donald Trump’s 2016 campaign manager, laid out the election night plan. “What Trump’s gonna do is just declare victory. Right? He’s gonna declare victory. But that doesn’t mean he’s a winner,” Bannon told a private audience. “He’s just gonna say he’s a winner.”
       <Citation number={15} />
    </Typography>

    <Typography className="indent">
       Bannon explained that the Democrats “[would] have a natural disadvantage” on election night, because more Democrats would vote by mail than Republicans and it would take time to count the mail-in ballots. This would give President Trump the illusion of a lead. “And Trump’s going to take advantage of it,” Bannon said. “That’s our strategy. He’s gonna declare himself a winner.”
       <Citation number={16} />
    </Typography>

    <Typography className="indent">
       In an interview on Fox News the morning of the election, Bannon insisted that President Trump needed to address the nation that night, to “provide the narrative engine for how we go forward.”
       <Citation number={17} />
       During an episode of his podcast later that same day, Bannon clarified what he meant: President Trump is “going to claim victory. Right? He’s going to claim victory.”
       <Citation number={18} />
    </Typography>

    <Typography className="indent">
       Tom Fitton drafted a victory statement for the President to read on election night.
       <Citation number={19} />
       On October 31st, he emailed the statement to President Trump’s assistant, Molly Michael, and social media guru, Dan Scavino. Fitton wrote that election day, November 3rd, was the “deadline by which voters in states across the country must choose a president.” Fitton argued that counting ballots that arrived after election day would be part of an effort by “partisans” to “overturn” the election results.
       <Citation number={20} />
    </Typography>

    <Typography className="indent">
       Of course, that claim wasn’t true—mail-in ballots are regularly processed after election day. Regardless, Fitton encouraged the President to pre-emptively declare victory. “We had an election today—and I won,” Fitton wrote for President Trump.
       <Citation number={21} />
       Early in the evening on election day, Fitton emailed Michael again to say he had “[j]ust talked to him [President Trump] about the draft [statement].”
       <Citation number={22} />
       Later that evening, before President Trump made his election night remarks, Michael replied that she was“...redelivering to him [President Trump] now.”
       <Citation number={23} />
    </Typography>

    <Image
      src="/images/image-028.jpg"
      alt="Steve Bannon, on screen"
      credit="(Photo by Alex Wong/Getty Images)"
    />

    <Typography className="indent">
       Roger Stone, President Trump’s longtime political confidante, told several associates just prior to the election that Trump needed to declare victory—especially if the race wasn’t called on election day. “Let’s just hope we are celebrating” on election night, Stone said. “I really do suspect it will still be up in the air. When that happens, the key thing to do is claim victory.” Stone elaborated with colorful language. “Possession is ninetenths of the law. No, we won. Fuck you. Sorry. Over. We won. You’re wrong. Fuck you.”
       <Citation number={24} />
    </Typography>

    <Typography className="indent">
       Indeed, published reports echoed these warnings about President Trump’s election strategy. Two days before the election, Jonathan Swan of Axios reported that President Trump “has told confidants he'll declare victory on Tuesday night if it looks like he's ‘ahead.’”
       <Citation number={25} />
       Swan added that “Trump's team is preparing to falsely claim that mail-in ballots counted after Nov. 3—a legitimate count expected to favor Democrats—are evidence ofelectionfraud.”
       <Citation number={26} />
       If the vote tally swung against Trump after election night in States such as Pennsylvania, then the Trump team would claim the Democrats had “stolen” the election.
       <Citation number={27} />
       Fox News election analysis Chris Stirewalt testified that he and his team “had gone to pains” to inform viewers that early votes would favor Republicans but the lead would be illusory “because the Trump campaign and the President had made it clear that they were going to try to exploit this anomaly.”
       <Citation number={28} />
       Others warned that President Trump could exploit the Red Mirage as well.
       <Citation number={29} />
    </Typography>
  </Page>
)
