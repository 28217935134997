import { lazy } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../components/Blockquote';
import { Page } from '../../components/Page';

const Citation = lazy(() => import('../Citations/CitationSheet12'));

export const Appendix1 = ({ ...args }: any) => (
  <Page {...args}>
    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h2" color="primary">
        INTRODUCTION
      </Typography>
    </Box>

    <Typography className="indent">
      The Select Committee investigated the facts relating to law enforcement entities’ preparation for, and response to, the January 6th events at the Capitol, including the character of the intelligence prior to the insurrection. This appendix does not address the cause of the attack, which resulted from then President Trump’s multi-pronged effort to overturn the 2020 presidential election.
    </Typography>

    <Typography className="indent">
      Prior to January 6th, numerous government agencies received intelligence that those descending on The Mall for a rally organized by the President were armed and that their target may be the Capitol. The intelligence community and law enforcement agencies detected the planning for potential violence directed at the joint session of Congress.
    </Typography>

    <Typography className="indent">
      That intelligence included information about specific planning by the Proud Boys and Oath Keepers militia groups who ultimately led the attack on the Capitol. By contrast, the intelligence did not support a conclusion that Antifa or other left-wing groups would likely engage in a violent counterdemonstration, or attack President Trump’s supporters on January 6th. Indeed, intelligence from January 5th indicated that some left-wing groups were instructing their members to “stay at home” and not attend on January 6th.
      <Citation number={1} />
    </Typography>

    <Typography className="indent">
      As January 6th approached, some of the intelligence about the potential for violence was shared within the executive branch, including the Secret Service and the President’s National Security Council. That intelligence should have been sufficient for President Trump, or others at the White House, to cancel the Ellipse speech, and for President Trump to cancel plans to instruct his supporters to march to the Capitol. Few in law enforcement predicted the full extent of the violence at the Capitol, or that the President of the United States would incite a mob attack on the Capitol, that he would send them to stop the joint session knowing they were armed and dangerous, that he would further incite them against his own vice President while the attack was underway, or that he would do nothing to stop the assault for hours.
    </Typography>

    <Typography className="indent">
      Nevertheless, as explained below, and in multiple hearings by the Committee on House Administration, there are additional steps that should have been taken to address the potential for violence on that day.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h2" color="primary">
        DISCUSSION
      </Typography>
    </Box>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        INTELLIGENCE RECEIVED BY GOVERNMENT AGENCIES
      </Typography>
    </Box>

    <Typography className="indent">
      On December 19, 2020, President Trump tweeted: “Big protest in D.C. on January 6th. Be there, will be wild!”
      <Citation number={2} />
      Following President Trump’s tweet, an analyst at the National Capital Region Threat Intelligence Consortium (NTIC) noticed a tenfold uptick in violent online rhetoric targeting Congress and law enforcement.
      <Citation number={3} />
      The analyst also noticed that violent right-wing groups that had not previously been aligned had begun coordinating their efforts.
      <Citation number={4} />
      These indications reached the head of the D.C. Homeland Security and Emergency Management Agency (HSEMA), Christopher Rodriguez, as well as incoming Chief of D.C. Metropolitan Police Department (MPD) Robert Contee.
      <Citation number={5} />
      Chief Contee remembered that the information prompted the DC Police to “change the way that we were going to deploy for January the 6th.”
      <Citation number={6} />
    </Typography>

    <Typography className="indent">
      Following President Trump’s “be there, will be wild!” tweet, Director Rodriguez arranged a briefing to provide the DC Mayor Muriel Bowser the latest threat intelligence about January 6th, outline the potential for violence, and “make operational recommendations,” including that the Mayor request assistance from the DC National Guard.
      <Citation number={7} />
      During the briefing, the Mayor was told that “there is greater negative sentiment motivating conversation than the last two events in November and December of 2020,” and that “others are calling to ‘peacefully’ storm the Capitol and occupy the building to halt the vote.”
      <Citation number={8} />
    </Typography>

    <Typography className="indent">
      As early as December 30th, in its intelligence briefing entitled, “March for Trump,” the U.S. Secret Service (USSS) highlighted the President’s “will be wild!” tweet alongside hashtags #WeAreTheStorm, #1776Rebel, and #OccupyCapitols, and wrote, “President Trump supporters have proposed a movement to occupy Capitol Hill.”
      <Citation number={9} />
      It added that promoters of the January 6th rally on social media had borrowed the President’s phrase and were marketing the January 6th rally as the “WildProtest.”
      <Citation number={10} />
    </Typography>

    <Typography className="indent">
      Other law enforcement entities were receiving similar indications from both government and private entities. By December 21st, the U.S. Capitol Police (USCP) had learned of a surge in viewers of online maps of the Capitol complex’s underground tunnels, which were attracting increased attention on www.thedonald.win, alongside violent rhetoric supporting the President.
      <Citation number={11} />
      By the late afternoon of January 5, 2021, Capitol Police Assistant Chief for Intelligence Yogananda Pittman urged Capitol Police Chief Steven Sund to convene a “brief call” to discuss “a significant uptick in groups wanting to block perimeter access to the Capitol tomorrow starting as early as 0600 hours.”
      <Citation number={12} />
      Chief Sund remembered discussing those indications and the preparations Capitol Police already had “in place, and [that] everybody seemed fine with utilizing the resources we had.”
      <Citation number={13} />
      Chief Sund added that, by that time, he had already deployed “all the available resources.”
      <Citation number={14} />
    </Typography>

    <Typography className="indent">
      The Federal Bureau of Intelligence (FBI) and the U.S. Department of Homeland Security’s Office of Intelligence and Analysis (DHS I&A) were also aware of the increased online interest in the Capitol tunnels. The FBI’s special agent in charge of the intelligence division at the Washington Field Office, Jennifer Moore, pointed out that there was nothing illegal about discussing the tunnels. Without a very specific discussion of violence, it was a matter of ensuring that the appropriate law enforcement partner agencies were aware of the uptick, ensuring that the Capitol Police were aware.
      <Citation number={15} />
      “People’s First Amendment rights, obviously, are protected. We cannot troll—can[’t] just troll the internet looking for things that’s out there,” Moore said.
      <Citation number={16} />
      “So it would have to be with such specificity and such planning and such detail that we would be able to open a case, immediately seek authority for an undercover, have enough probable cause for that undercover off of one tip would be tough.”
      <Citation number={17} />
    </Typography>

    <Typography className="indent">
      Other agencies were also surfacing indications and receiving tips. On December 26, 2020, the Secret Service received a tip about the Proud Boys detailing plans of having “a large enough group to march into DC armed and will outnumber the police so they can’t be stopped.”
      <Citation number={18} />
      It stressed, “Their plan is to literally kill people.... Please please take this tip seriously and investigate further.”
      <Citation number={19} />
      On December 24th, the Secret Service received a compilation of social media posts from “SITE,” a private intelligence group. One of them urged that protesters “march into the chambers.”
      <Citation number={20} />
      Another, referring to President Trump’s December 19th “will be wild!” post, wrote that Trump “can’t exactly openly tell you to revolt,” so the December 19th post was “the closest he’ll ever get.”
      <Citation number={21} />
      Another understood the President’s tweet to be urging his supporters to come to Washington “armed.”
      <Citation number={22} />
      Others were to the same effect (“there is not enough cops in DC to stop what is coming,”
      <Citation number={23} />
      “make sure they know who to fear,”
      <Citation number={24} />
      and “waiting for Trump to say the word”
      <Citation number={25} />
      ).
    </Typography>

    <Typography className="indent">
      By December 28th, that compilation had reached the newly installed head of the Capitol Police intelligence unit, Jack Donohue.
      <Citation number={26} />
      The same day, a self-styled “internet expert” who had been “tracking online far right extremism for years” sent an email to the Capitol Police public information inbox warning of “countless tweets from Trump supporters saying they will be armed,” and of tweets “from people organizing to ‘storm the Capitol’ on January6th.”
      <Citation number={27} />
      She added, “January 6th will be the day most of these people realize there’s no chance left for Trump. They’ll be pushed to what they feel is the edge,” noting that many would be armed and that she was, for the first time, “truly worried.”
      <Citation number={28} />
      Other senior Capitol Police officers do not recall seeing that email before the January 6th attack.
      <Citation number={29} />
      The next day, Secret Service agents forwarded to Capitol Police warnings that pro-Trump demonstrators were being urged to “occupy federal building[s],” “march into the capital building [sic] and make them quake in their shoes by our mere presence.”
      <Citation number={30} />
    </Typography>

    <Typography className="indent">
      In addition, on January 1, 2021, a lieutenant in the intelligence branch of the MPD forwarded to the Capitol Police intelligence unit a tip—later forwarded to USCP Deputy Chief Sean Gallagher—that he had “found a website planning terroristic behavior on Jan 6th, during the rally.”
      <Citation number={31} />
      The source included a link to www.thedonald.win site, describing a “detailed plan on [s]torming the capitol in DC on Jan 6th.”
      <Citation number={32} />
      On January 2, 2021, the FBI saved in its system a social media post stating, “This is not a rally and it’s no longer a protest. This is a final stand... many are ready to die to take back #USA.... And don’t be surprised if we take the #capital building [sic].”
      <Citation number={33} />
      On January 3rd, FBI and Capitol Police received a Parler post that “after weds we are going to need a new congress,” and “Jan 6 may actually be their last day in Congress.”
      <Citation number={34} />
    </Typography>

    <Typography className="indent">
      On January 4th, Jack Donoghue, head of USCP’s intelligence unit, and his assistant director, Julie Farnam, briefed Capitol Police leadership, including Chief Gallagher and Chief Pittman (but not Chief Sund), about the January 3rd Threat Assessment, which highlighted that Congress itself was the target of potential violence on January 6th. Assistant Director Farnam explicitly warned the group:
    </Typography>

    <Blockquote>
      <Typography>
        Supporters see this as the last opportunity to overturn the election. There was disappointment and desperation amongst the protestors, and this could be an incentive to become violent, because they have nothing left to lose. The targets are not the counter protestors; the target is Congress. The protests are heavily publicized. Stop the Steal has a propensity for attracting White supremacists, militia groups, groups like the Proud Boys. There are multiple social media posts saying that people are going to be coming armed, and it's potentially a very dangerous situation.
        <Citation number={35} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      On January 5, 2021, at 12:19 p.m., the Architect of the Capitol head of security, Valerie Hasberry, forwarded an alert to Capitol Police incident command that an individual was calling on thousands to “go to Washington Jan 6 and help storm the Capital [sic],” adding “we will storm the government buildings, kill cops, kill security guards, kill federal employees and agents.”
      <Citation number={36} />
      “There is now chatter on Parler about storming the Capitol,” Ms. Hasberry wrote to her AOC employee working at the USCP.
      <Citation number={37} />
      “Please let me know if there are any updates to credible threats.”
      <Citation number={38} />
      Within an hour, she was advised by her staff that “[t]here is no talk about any credible threats or storming the Capitol.” That same day, representatives from DHS, FBI, HSEMA, Secret Service, DC Police, and Capitol Police shared notice of a website, Red State Secession, that urged its visitors to post the home and work addresses of Democratic Members of Congress and “political enemies” under the title, “Why the Second American Revolution Starts Jan 6.”
      <Citation number={39} />
      It asked for their routes to and from the January 6th congressional certification because “the crowd will be looking for enemies.”
      <Citation number={40} />
    </Typography>

    <Typography className="indent">
      The FBI was uploading to, and tagging in, its system incoming information from all FBI field offices about January 6th under the label, “CERTUNREST2021.” While the incoming information was reviewed on a regular basis by the Washington Field Office, “unified monitoring” of the items in the aggregate didn’t begin until January 5th.
      <Citation number={41} />
      That same day, the FBI captured a January 6th-related threat that warned a “Quick Reaction Force” of Trump supporters was preparing for January 6th in Virginia with weapons and prepared “to respond to ‘calls for help’” in the event that “protesters believed the police were not doing their job,” and a “Situation Incident Report” from FBI’s Norfolk Field Office warned of a “war” on January 6th.
      <Citation number={42} />
      While Capitol Police leadership received neither warning until after the attack,
      <Citation number={43} />
      Assistant Director Farnam, USCP intelligence unit, warned that Congress would be the target on January 6th. She noted that a “sense of desperation and disappointment may lead to more of an incentive to become violent. Unlike previous post-election protests, Congress itself is the target on the 6th.”
      <Citation number={44} />
      The Chairman of the Joint Chiefs of Staff, General Mark Milley, remembers Deputy Secretary of Defense David Norquist expressing a similar view based on the social media traffic in early January 2021: “Norquist says... [t]he greatest threat is a direct assault on the Capitol. I’ll never forget it.”
      <Citation number={45} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        DISCUSSION OF THE POTENTIAL FOR VIOLENCE
      </Typography>
    </Box>

    <Typography className="indent">
      Federal and local agencies agreed that there was a potential for violence on January 6th. As noted above, the intelligence leading up to January 6th did not support a conclusion that Antifa or other left-wing groups would likely engage in a violent counter-demonstration, or attack President Trump’s supporters on January 6th. In fact, none of these groups was involved to any material extent with the attack on the Capitol on January 6th.
    </Typography>

    <Typography className="indent">
      That said, certain witnesses testified that they believed that there would be violence with Antifa or similar counter protest groups. President Trump’s National Security Advisor, Robert O’Brien, said the White House saw a risk of violence from counter-protesters.
      <Citation number={46} />
      Then Acting DHS Secretary Chad Wolfe said that his “main concern [...] at the time was what we had seen throughout the summer and throughout the fall, which was you were going to have groups on either side, and so you were going to have counterprotests. And usually where those counterprotests interacted was where you had the violence.”
      <Citation number={47} />
    </Typography>

    <Typography className="indent">
      General Milley said the potential for violence was clear to all: “Everyone knew. I can't imagine anybody in those calls that didn't realize that on the 6th was going to be the certification of an intensely contested election, and there were large crowds coming into town, and they were coming into protest. And everybody knew there was a probability, more than a possibility, a probability of violence.”
      <Citation number={48} />
      He expected “street fights when the sun went down,” while [Deputy Secretary] Norquist said the most dangerous thing was assault on the Capitol.
      <Citation number={49} />
      Director of DHS Special Operations Christopher Tomney remembered, “[T]here was broad discussion/ acknowledgment that folks were calling for bringing weapons into the city on that day, so there was no surprise, there was no—you know, no one disagreed that there was going to be the high likelihood that there could be some violence on January 6.”
      <Citation number={50} />
    </Typography>

    <Typography className="indent">
      Acting Deputy Attorney General Richard Donoghue described the discussion about the threat landscape as “generally about left-wing, right wing, or Pro-Trump, anti-Trump groups coming to the Capitol. It didn't really matter what they called themselves. It was a matter of they're upset, they're coming to the Capitol, and there's a potential for violence.”
      <Citation number={51} />
      Donoghue added: “Everyone knew what everyone else was doing. Everyone knew that there was a danger of violence. Everyone knew that the Capitol and other facilities were potential targets. And I think we all felt comfortable that we were aware what the situation was, and we had the resources in place to address it.”
      <Citation number={52} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        OPERATIONALIZATION OF JANUARY 6-RELATED INTELLIGENCE
      </Typography>
    </Box>

    <Typography className="indent">
      Preparing for January 6th required coordination among the several local and Federal law enforcement agencies that have distinct authorities and jurisdiction over adjacent areas in the Washington, DC, area. These range from the MPD, United States Park Police (USPP), and USSS to the USCP.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        DC GOVERNMENT PREPARATION
      </Typography>
    </Box>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        December 30, 2020, HSEMA briefing
      </Typography>
    </Box>

    <Typography className="indent">
      Following the DC HSEMA’s December 30th intelligence briefing, Mayor Bowser, anticipating that President Trump’s December 19th “will be wild!” tweet would have a big effect on the number of people coming into the District,
      <Citation number={53} />
      agreed with HSEMA Director Rodriguez, who thought “the intelligence was showing that we needed to posture ourselves, we needed to brace ourselves, and we needed additional resources in the city particularly the DC National Guard.”
      <Citation number={54} />
      MPD Chief Contee, who attended the December 30th briefing and was seeing similar intelligence, concurred with the request for the DC National Guard.
      <Citation number={55} />
    </Typography>

    <Typography className="indent">
      In light of the upcoming holiday weekend, HSEMA Director Rodriguez wanted to expedite the DC request for National Guard assistance.
      <Citation number={56} />
      On December 31st, Mayor Bowser requested the assistance of the DC National Guard to assist the MPD at traffic points within the city.
      <Citation number={57} />
      Mayor Bowser’s request explicitly limited National Guard assistance to “non-law enforcement activities” so that the MPD could focus on the civil protests and specified that Guard troops should not be armed.
      <Citation number={58} />
      Army Secretary Ryan McCarthy approved Mayor Bowser’s request.
      <Citation number={59} />
      By this time, DC HSEMA Director Rodriguez had fully activated HSEMA and coordinated with Federal, State and local partners, to deal with “consequence management.”
      <Citation number={60} />
    </Typography>

    <Typography className="indent">
      On January 4th, Mayor Bowser held a press conference and invited the MPD, USCP, and USPP.
      <Citation number={61} />
      Mayor Bowser announced that she had activated the DC Fire and Emergency Management Services (DC FEMS) in preparation of the January 6th event and that the DC National Guard would assist MPD at traffic points and with crowd control. Mayor Bowser urged DC residents to stay out of downtown on January 5th and 6th, acknowledging the possibility of violence. She stated that, while “[p]eople are allowed to come into our city to participate in First Amendment activities,” DC officials would “not allow people to incite violence, intimidate our residents, or cause destruction in our city.”
      <Citation number={62} />
    </Typography>

    <Typography className="indent">
      The next day, Mayor Bowser sent a letter requesting that Federal agencies coordinate with the Mayor’s office and the MPD in their response on January 6th.
      <Citation number={63} />
      The letter plainly stated that it was intended to ensure coordination among the agencies involved. DC HSEMA Director Rodriguez testified that there was a concern, in light of the Federal response to the previous summer’s civil justice protests, “that in the event that activities on the street escalated, the city could once again become... militarized and that armed military and Federal law enforcement personnel could be brought into the District,” perhaps intimidating residents.
      <Citation number={64} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        DC FEMS PREPARATION
      </Typography>
    </Box>

    <Typography className="indent">
      Mayor Bowser also activated DC FEMS (Fire and Emergency Medical Service Department), several days before January 6th.
      <Citation number={65} />
      DC FEMS focused most of its attention on the event at the Ellipse since the permits indicated it would be the largest event of the day, with an estimated 5,000 people attending. Other DC and Federal agencies believed the number would likely be closer to 35,000. This led DC FEMS to establish an area command for the Ellipse, including a Mobile Command Unit, six ambulances, four engine companies, and a first aid tent staffed by George Washington University medical staff.
      <Citation number={66} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        MPD PREPARATION
      </Typography>
    </Box>

    <Typography className="indent">
      After the DC HSEMA’s December 30th intelligence briefing, MPD Chief Contee ordered full deployment of the Department, cancelling previously scheduled days off, fully deploying the Civil Disturbance Unit, and contacting police departments in Montgomery and Prince George’s Counties in Maryland so that their forces would be pre-staged at certain locations. Chief Contee also staged police at the White House and Lafayette Park. Chief Contee said that although the MPD “are normally not fully deployed for civil disturbance for counting votes at the U.S. Capitol,”
      <Citation number={67} />
      “obviously,aswe got closer from the time of the initial [December 19th Trump] tweet leading up, <i>with all of the rhetoric that's out there on social media, you know, people were going to bring guns</i>, were going to do this and that and so forth, that caused us obviously to change the way that we were going to deploy for Januarythe6th.”
      <Citation number={68} />
      Because of the numerous social media posts about guns, MPD also posted signs on the National Mall indicating that possession of firearms in Washington, D.C., was illegal and would be prosecuted.
      <Citation number={69} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        U.S. CAPITOL POLICE PREPARATION
      </Typography>
    </Box>

    <Typography className="indent">
      On January 3rd, the same day Capitol Police’s Intelligence and Interagency Coordination Division (IICD) issued a threat assessment indicating that “Congress itself is a target,” Chief Sund called House Sergeant-at-Arms Paul D. Irving to discuss requesting the DC National Guard to assist in policing the Capitol’s perimeter.
      <Citation number={70} />
      Chief Sund needed approval from the Capitol Police Board, which consisted of Irving, Senate Sergeant-at-Arms Michael C. Stenger, and the Architect of the Capitol J. Brett Blanton. Chief Sund remembers that Irving responded immediately that he did not “like the optics” and that the intelligence did not support the request.
      <Citation number={71} />
      Irving, however, remembers Chief Sund calling him to say the DC National Guard had offered 125 unarmed National Guardsmen to the USCP and MPD.
      <Citation number={72} />
      He also remembered that, during a conference call, Chief Sund told Stenger and him that the National Guard would be utilized in similar fashion to the assistance provided to the DC police, namely, staffing intersections, and for traffic control to free up officers, but then could be used for crowd control, although he acknowledged that the Capitol campus does not have many intersections in need of staffing.
      <Citation number={73} />
    </Typography>

    <Typography className="indent">
      The Capitol Police Board, including Chief Sund, later agreed that a request for the DC National Guard would not be necessary, particularly if the USCP was in an “all hands on deck” posture.
      <Citation number={74} />
      Chief Sund agreed with Stenger and Irving that the intelligence did not support a request for DC National Guard assistance.
      <Citation number={75} />
      According to Irving, Chief Sund did not believe the National Guard would add much to the USCP security plan for January 6th.
      <Citation number={76} />
      Chief Sund briefed the Capitol Police Board on the USCP’s enhanced security plan, and “all hands on deck posture”—including 1,200-plus officers, added Civil Disturbance Units (CDU), an enhanced Containment Emergency Response Team (“CERT”), and an expanded perimeter.
      <Citation number={77} />
      Chief Sund did not believe, based on the intelligence he had, that it was then necessary to cancel officers’ days off.
      <Citation number={78} />
    </Typography>

    <Typography className="indent">
      USCP leadership did not create a department-wide plan for the January 6th event.
      <Citation number={79} />
      In retrospect Chief Sund believed “there should have been a plan for the joint session of Congress inside the Capitol to reflect all the planning and all the coordination that goes on inside the Capitol.”
      <Citation number={80} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        GOVERNMENT AGENCY PREPARATION
      </Typography>
    </Box>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        Interagency Coordination
      </Typography>
    </Box>

    <Typography className="indent">
      In the appendix on Deployment of the National Guard, we describe certain reasons why the deployment of the National Guard was delayed, highlighting the activity of Secretary of the Army McCarthy and how he understood an order given by Acting Secretary of Defense Christopher Miller. In our interviews with Department of Defense (DoD) officials, they testified that they had asked the Department of Justice to serve the role of “lead Federal agency,” meaning to lead the coordination and the response on January 6th. The Justice Department does not command National Guard units. Department of Defense, Department of Justice (DOJ), and Department of Homeland Security (DHS) officials testified from each agency’s perspective about the discussions concerning which department would serve as a lead Federal agency. Notably, these discussions occurred at the same time President Trump was offering the Acting Attorney General position to Jeffrey Clark, replacing then Acting Attorney General Jeffrey Rosen. Had Clark ultimately been appointed, and had he been placed in charge of the Federal security response to the violence on January 6th, the situation could have been materially worse.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        January 3rd Coordination Call
      </Typography>
    </Box>

    <Typography className="indent">
      Realizing that there had not yet been a coordination call among the Federal agencies engaged in planning for the January 6th events and related contingencies, Acting Secretary Miller convened one for January 3rd, because “nobody else was doing it.”
      <Citation number={81} />
      In addition to DoD officials including General Milley, DOJ, DHS, Department of the Interior (DOI) officials participated. Acting Secretary Miller’s objectives were to ensure that “everyone had the same perception of the threat and then figure out how to synchronize, coordinate.” He also wanted to make sure that DoD was prepared for any additional requests for support.
      <Citation number={82} />
    </Typography>

    <Typography className="indent">
      Director of DHS Special Operations Tomney remembered that participants discussed the threat outlook and estimated crowd
      <Citation number={83} />
      of up to 30,000— not large for the District.
      <Citation number={84} />
      The consensus was that 8,000 to 10,000 police officers would be available on January 6th, a force regarded as appropriate for up to a million protesters. General Milley asked the police participants on the call whether they needed any other assistance from the Department of Defense.
      <Citation number={85} />
      General Milley and Secretary Miller expressed concern about the number of groups requesting permits and the attendance of groups like the Proud Boys and Oath Keepers who, as Secretary Miller explained, had “conducted acts of violence in the past.”
      <Citation number={86} />
      General Milley asked whether requests for permits could be declined, canceled, or permits revoked.
      <Citation number={87} />
      Participants from the Department of the Interior responded that “that wasn't an option.”
      <Citation number={88} />
    </Typography>

    <Typography className="indent">
      According to Director Tomney, DHS felt confident in the United States Secret Service’s ability to protect the White House and Vice President, the Federal Protective Service’s (FPS) ability to protect potentially affected Federal buildings, as well as the Immigration and Customs Enforcement and Customs and Border Protection’s ability to augment FPS, if needed.
      <Citation number={89} />
      General Milley and the other DoD participants left the call reassured that the law enforcement agencies involved were prepared for January 6th.
      <Citation number={90} />
    </Typography>

    <Typography className="indent">
      During the January 3rd call, the DoD also raised the issue of a lead Federal agency. General Milley noted the desirability of a law enforcement lead for coordinating the interagency planning and response effort, given the “potpourri of jurisdictions” and diversity of agency authorities.
      <Citation number={91} />
      Secretary Miller testified that he believed he and Acting Attorney General Jeffrey Rosen were in agreement that the DOJ should lead interagency coordination for January 6th,
      <Citation number={92} />
      although when asked during the call, Rosen did not confirm that the DOJ would play that role.
      <Citation number={93} />
      The question of a lead Federal agency remained “an open, unanswered question” at the end of the January 3rd call.
      <Citation number={94} />
      The same day, Rosen was attempting to secure a White House meeting with President Trump regarding the imminent appointment of Clark in his stead.
      <Citation number={95} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        DELIBERATION ON AGENCY ROLES
      </Typography>
    </Box>

    <Typography className="indent">
      On a January 4th inter-agency call with the same group, Acting Deputy Attorney General Donoghue made the DOJ’s role clear: it would take the lead in <i>certain areas</i> of responsibility, although he stressed that the DOJ was never designated lead Federal agency and could not serve in that capacity.
      <Citation number={96} />
      On the evening of January 4th, the FBI established a Strategic Information Operations Center (“SIOC”) at FBI headquarters, which became operational on January 5th.
      <Citation number={97} />
      Unlike the previous summer’s civil protests, DoD did not have a representative at the SIOC. All the DoD officials who were interviewed by the Select Committee, however, believe that the DOJ agreed to take—and may have been assigned by the White House—the lead coordinating role.
      <Citation number={98} />
      Director Tomney, however, remembered that the DOJ participants neither agreed to, nor explicitly declined, the lead agency role.
      <Citation number={99} />
    </Typography>

    <Typography className="indent">
      During the January 3rd and 4th calls, General Milley, according to Donoghue, noted that “[t]here should be plenty of police forces available without using Federal military troops,” so he was adamant that no active-duty troops would be deployed on January 6th.
      <Citation number={100} />
      During this call, participants also discussed whether there was a need for a police-based quick reaction force and concluded that the size of the MPD and USCP police forces made that unnecessary.
      <Citation number={101} />
    </Typography>

    <Typography className="indent">
      According to Donoghue, at the end of the January 4th call, “[i]t was clear that everyone understood what everyone else’s responsibility was, and everyone understood what was available to them if they needed more resources to meet their responsibilities.”
      <Citation number={102} />
      The calls had also given local and Federal law enforcement entities the chance to “voice any issues, concerns, or requests for Department of Defense support if they felt that they were incapable of handling at their level. So, institutionally, there was agreement on the threat assessment and the plan going forward.”
      <Citation number={103} />
      The DoD’s leading role during the January 3rd and 4th calls had, in fact, left Acting DHS Secretary Chad Wolf with the impression that DoD was the lead agency, as they were “coordinating phone calls, they were setting agendas for phone calls, and they were calling out different people, okay, what do you need... So they were quarterbacking the situation and the response.”
      <Citation number={104} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        AGENCY ACTIONS ON PERMITTING
      </Typography>
    </Box>

    <Typography className="indent">
      Three organizations in the National Capitol Region handle permits for organized activities depending on where the planned activity is to occur. The USSS issues permits for the Ellipse, while the DC MPD issues permits for the area around the Ellipse. The USCP handles permitting for activities on the Capitol campus. All three entities, as well as other law enforcement agencies, communicate about applications for permits and the expected number of attendees. They are reluctant to deny permits for what appears to be First Amendment-protected protests.
      <Citation number={105} />
      The USCP received, evaluated, and approved six group permit requests for January 5th and 6th activity on Capitol Grounds.
      <Citation number={106} />
    </Typography>

    <Typography className="indent">
      On December 19th, the day of President Trump’s “will be wild!” tweet, Cindy Chafian, spokesperson from the “Eighty Percent Coalition,” applied to the MPD and USCP for a permit to hold a rally.
      <Citation number={107} />
      On December 29th, Chafian applied to USPP for a permit for a January 5th rally in Freedom Plaza.
      <Citation number={108} />
      The next day, Kylie Kremer filed for a permit for “Women for America First” to hold a rally for up to 5,000 people on the Ellipse.
      <Citation number={109} />
      On December 31st, the National Park Service (NPS) held two meetings with Chafian as well as the MPD and USCP.
      <Citation number={110} />
      Then, on January 1st, the USSS confirmed that President Trump would attend the January 6th rally at the Ellipse, prompting USPP to provide additional support for the rally.
      <Citation number={111} />
    </Typography>

    <Typography className="indent">
      On January 4th, “Women for America First” requested that the NPS increase the authorized attendance at its rally to 20,000 from the 5,000 in the original application. The same day, reacting to the USPP briefing that 5,000 people were expected, Joseph Roth, the USSS site lead, commented that he found it “funny that this permit says 5,000 people when they have said 30k repeatedly.”
      <Citation number={112} />
      On January 5th, the NPS issued a permit for 30,00 participants for the Ellipse event.
      <Citation number={113} />
      At no point was any permit granted for a march from the Ellipse to the Capitol. The President planned to announce that march “spontaneously.”
      <Citation number={114} />
    </Typography>

    <Typography className="indent">
      At the White House, the increased crowd estimate concerned Bobby Peede, Director of Presidential Advance, who emailed White House Deputy Chief of Operations Anthony Ornato, noting that the USSS was planning on using only 12 magnetometers. Peede added that “the mag issue is a pretty major problem if the expected crowd shows up.” Secret Service documents reveal internal discussion of an initial USSS assessment that 17 magnetometers would be needed. On January 6th, only 10 magnetometers were initially assigned to the Ellipse.
      <Citation number={115} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        AGENCY PREPARATIONS FOR JANUARY 6TH
      </Typography>
    </Box>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        January 5th Congressional Briefing by Chief Sund and Paul Irving
      </Typography>
    </Box>

    <Typography className="indent">
      On January 5th, Chief Sund briefed the Chairperson of the Committee on House Administration (CHA), Representative Zoe Lofgren, along with numerous staff, as well as House Sergeant-at-Arms Paul Irving. Given CHA's oversight of the Capitol Police, this was “an opportunity for the chair of the committee to hear from the security professionals on the security plan.”
      <Citation number={116} />
      Chair Lofgren’s staff director described it as a “topline” briefing that covered various aspects of the security plan, including Chief Sund’s direction of “all hands on deck,” his focus on the prospect of counter protesters, as well as Chair Lofgren’s concern that Members of Congress speaking at the Ellipse that day could incite protesters.
      <Citation number={117} />
      After the briefing, the CHA staff director specifically asked about the availability of the National Guard in case it was needed. “Chief Sund said that the Guard could be activated with an emergency declaration from the [Capitol Police] board, but they are here. They are a phone call away, and if we need them, they are ready to go.”
      <Citation number={118} />
    </Typography>

    <Typography className="indent">
      Speaker Pelosi did not receive a similar briefing, but her chief of staff was given a readout of Chief Sund’s briefing to Chairperson Lofgren. On that basis, as well as the assurances Chief Sund provided, the Speaker’s chief of staff said, “So I believed and the Speaker believed the security professionals were in charge of the security and they were prepared. We were told that there was a plan.”
      <Citation number={119} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        FEDERAL AGENCY RESPONSE ON JANUARY 6TH
      </Typography>
    </Box>

    <Typography className="indent">
      Although intelligence was available suggesting potential violence at the Capitol, it was not apparent exactly what President Trump would do to provoke the crowd at the January 6th Ellipse rally. Chief Sund, for example, drove into work on January 6th believing that preparations for the day’s events were sound and that there was no extraordinary risk or threat. “You know, on my way in, I called Inspector Glover with MPD just to get a read. He said he was actually parked over by the Ellipse. Asked him, Hey, how are things going over there? He said, there's big crowds, lots of people in line, but right now he wasn't seeing any concern with the folks that we had. So that was my initial take,” Sund told the Select Committee.
      <Citation number={120} />
    </Typography>

    <Typography className="indent">
      Throughout the morning, Robert Engel, the special agent in charge of the President’s Secret Service detail, received updates from the event at the Ellipse. At 10:35 a.m., an update informed Engel that 20,000 attendees had been processed and outside of the magnetometers, but that there were “several thousand on the mall watching but not in line.”
      <Citation number={121} />
      An hour later, Engel forwarded an update to White House Deputy Chief of Operations Ornato, informing him that 30,000 attendees had been processed.
      <Citation number={122} />
    </Typography>

    <Typography className="indent">
      Acting Attorney General Rosen met with FBI leadership for a briefing that morning.
      <Citation number={123} />
      He remembered this briefing, unlike previous ones, as “more of a situational update,” adding that the DOJ was “going to hope for the best, prepare for the worst.”
      <Citation number={124} />
      At 10:43 a.m., Acting Deputy Attorney General Donoghue received an email from Matt Blue, Acting Chief of the Counterterrorism Section, stating “[t]here are no credible threats as of the 10:00 brief.”
      <Citation number={125} />
      Twelve minutes later, Rosen spoke to White House Counsel Pat Cipollone via phone.
      <Citation number={126} />
      Acting Attorney General Rosen admits that “in hindsight” no one at the Department contemplated “how bad that afternoon turned out to be.”
      <Citation number={127} />
      Nobody in the DOJ leadership could have predicted President Trump’s actions that day.
    </Typography>

    <Typography className="indent">
      The President’s speech at the Ellipse began just before noon. David Torres,
      <Citation number={128} />
      head of the USSS’s Protective Intelligence Division (PID), insisted that the Secret Service was not listening to the President’s speech, however PID agents monitored the speech throughout.
      <Citation number={129} />
      At 12:20 p.m., Faron Paramore, assistant director of Strategic Intel & Information (SII), sent an email to USSS leadership that “POTUS just said that he is going up to the U.S. Capitol to ‘watch’ the vote” and asked whether this is true. Secret Service executive Kimberly Cheatle responded “[h]e said it, but not going, to our knowledge.”
      <Citation number={130} />
      Minutes later, the USSS PIOC warned that “Mogul just mentioned in his speech that he would accompany the protesters to the Capitol,” with a note that “DAD Torres requested this be sent for awareness.”
      <Citation number={131} />
      At 1:14 p.m., the USSS Joint Operations Center (JOC) sent an email designating a response team to accompany the President in his march to the Capitol, “per [his] announcement” at the Ellipse. The JOC notes: “Multiple reports of armed individuals with various weapons and malicious intent. Be on your guard.”
      <Citation number={132} />
    </Typography>

    <Typography className="indent">
      At 12:24 p.m., while the President was speaking, the Vice President, with his USSS detail, departed the Vice President’s Residence for the Capitol.
      <Citation number={133} />
      After being routed to the Senate side due to the protests, the Vice President’s detail arrived at the Capitol at 12:38 p.m. and was inside the Senate Chamber at 12:54 p.m.
      <Citation number={134} />
    </Typography>

    <Typography className="indent">
      Around 1 p.m., Chief Contee notified Mayor Bowser about the discovery of a pipe bomb at the Republican National Committee and of the Capitol perimeter breach.
      <Citation number={135} />
      Within minutes, Mayor Bowser was at the Joint Operations Center with Chief Contee.
      <Citation number={136} />
      They tried to contact Chief Sund and sent command officials to Capitol Police headquarters.
      <Citation number={137} />
      At approximately 12:59 p.m., once the violence had begun, Chief Sund first called Deputy Chief Jeff Carroll of the MPD, which provided almost immediate reinforcements.
      <Citation number={138} />
      Approximately 10 minutes later, MPD officers arrived at the West Front balcony to assist the USCP officers. Chief Sund’s next call was to the House and Senate Sergeants-at-Arms to request National Guard resources.
      <Citation number={139} />
    </Typography>

    <Typography className="indent">
      As the violence at the Capitol escalated, DC FEMS realized that they were facing an “expanding incident with the potential for mass casualties, fires, active shooter, and hazardous materials incidents that would exceed the resources at hand.”
      <Citation number={140} />
      As violence escalated at the West Front, non-lethal grenadiers began launching chemical munitions at the crowd.
      <Citation number={141} />
      Around the same time, the USCP discovered a nearby truck containing firearms and Molotov cocktails,
      <Citation number={142} />
      as well as a second explosive device at the Democratic National Headquarters at 1:07 p.m., while Vice President-elect Kamala Harris was inside. Responding to these incidents required a commitment of significant USCP resources for mitigation and to evacuate nearby buildings, preventing their deployment to the Capitol to help secure the building.
    </Typography>

    <Typography className="indent">
      Chief Gallagher recalled that “it started to really unfold into an investigative—heavy on the investigation of let's pull up the cameras. Let's try to get an image of the pipe bombs. Let's get the images to our law enforcement partners. Let's try to play back the cameras and see if we can identify anybody that placed these pipe bombs. Let's get the owner of the vehicle, run the vehicle information for that suspicious vehicle. So we were coordinating all of that type of response that was from our Investigations Division.... We also had our IICD team trying to run down as much information, working with our Investigations Division as they could, on the suspicious vehicle, the tags of the vehicle, stuff of that nature.”
      <Citation number={143} />
      Not including those in the command center, the incidents would require the response of 34 USCP personnel, with additional assistance provided by the FBI and ATF. USCP’s senior leadership at the Command Center and in the intelligence division also divided their time between the escalating threat to their officers at the Capitol and the explosive devices elsewhere on campus.
    </Typography>

    <Typography className="indent">
      The next update to the DOJ was at 1:17 p.m., after President Trump had finished speaking at the Ellipse. After several attempts, Acting Attorney General Rosen got in touch with Acting U.S. Attorney for the District of Columbia Michael Sherwin, who was at the Ellipse. Rosen admits he was not very concerned with the situation at the time, because “[i]t was early, but at least the initial report was: Crowd size doesn't appear to be unexpected, and the conduct so far is okay.”
      <Citation number={144} />
      He had only watched the end of the President’s speech.
      <Citation number={145} />
      It was early afternoon before the DOJ’s senior leadership began to realize the extent of what was occurring. Acting Deputy Attorney General Donoghue remembered hearing protesters outside the Department of Justice “marching down Constitution, going from the Ellipse toward the Capitol” in the late morning, early afternoon, but wasn’t specifically monitoring the protests as “there were a million things going on.”
      <Citation number={146} />
    </Typography>

    <Typography className="indent">
      At the same time, the President was pressing his request to go to the Capitol. According to Robert Engel, the head of his Secret Service detail, President Trump asked to go to the Capitol once they had gotten into the Presidential SUV.
      <Citation number={147} />
      Engel denied the President’s request and returned to the White House.
      <Citation number={148} />
      The Committee has significant evidence regarding this period of time.
      <Citation number={149} />
    </Typography>

    <Typography className="indent">
      President Trump, nevertheless, persisted in his request to go to the Capitol. A 1:35 p.m. entry in the USSS Civil Disturbance Unit’s time log shows that the plan was to hold at the White House for the next 2 hours and then move the President to the Capitol.
      <Citation number={150} />
      Soon after, Engel emailed USSS leadership from the West Wing to say they were “discussing options and setting expectations.”
      <Citation number={151} />
      Minutes later, after receiving an email from USSS leadership saying that it would not be advisable for the President to go “anywhere near” the Capitol, Engel responded, “[w]e are not doing an OTR to Punch Bowl.”
      <Citation number={152} />
    </Typography>

    <Typography className="indent">
      Mayor Bowser also spoke directly with Army Secretary Ryan McCarthy who informed her that they had not gotten a request for National Guard assistance from the USCP. Mayor Bowser informed Secretary McCarthy that she did not have the authority to re-direct the 340 DC National Guard troops at traffic points across the city, but that she had already deployed the DC MPD and FEMs to the Capitol. Because she and Chief Sund had not connected, Mayor Bowser concluded that the security of the Capitol was “now our responsibility” and requested National Guard assistance. At the end of this call, Mayor Bowser believed that Secretary McCarthy was “running [her request] up his chain of command.”
      <Citation number={153} />
    </Typography>

    <Typography className="indent">
      USCP Chief Sund was concerned when the explosive device was discovered near RNC Headquarters but did not then believe there was a need to change the USCP’s operational posture. Minutes later, when a large group of rioters approached the outer west perimeter of the Capitol, Chief Sund raised the alarm and began to reposition his officers: “When we looked up, and I saw them approaching the officers that were standing, you know, right there on the barrier, I looked over to Chief Thomas and I said, Chad, where's our—where's CDU? Get CDU down there now.”
      <Citation number={154} />
    </Typography>

    <Typography className="indent">
      The USCP timeline shows that at 12:55 p.m. all available officers were directed to the West Front of the Capitol. Then, at approximately 1:25 p.m., FBI Deputy Director David Bowdich received a report about the pipe bombs at the RNC and DNC.
      <Citation number={155} />
      Bowdich testified that the FBI considered the possibility that the DNC and RNC bombs were possible distractions.
      <Citation number={156} />
      At 1:28 p.m., USCP requested the AOC deliver 400 additional bike racks to the East Front to serve as protective barriers, even though rioters were using bike racks as weapons. The pipe bomb discovery at the DNC prevented the AOC from delivering them.
      <Citation number={157} />
    </Typography>

    <Typography className="indent">
      Chief Gallagher was surprised that the violence had escalated so fast. “The amount of violence that immediately took place when that crowd of 30,000, 35,000, whatever the number that was estimated to come was, that did catch, I think, caught Capitol Police and all of our partners a little off guard with how violent they were and how quick they were.”
      <Citation number={158} />
      At 1:49 p.m., DC MPD Commander Glover declared a riot on the West Front of the Capitol. “Cruiser 50, we’re going to give riot warnings. We’re going to give riot warnings. Going to try to get compliance, but this is now effectively a riot,” Commander Glover yelled into his radio. “1349 hours. Declaring it a riot,” the dispatcher responded, which allowed a change in the type of equipment the MPD could use in responding to the violence.
      <Citation number={159} />
    </Typography>

    <Typography className="indent">
      While the violence continued to escalate at the Capitol, the USCP leadership focused on three things: (1) requesting support from local and Federal law enforcement agencies nearby; (2) planning for and coordinating with arriving reinforcements; and (3) protecting congressional leadership and other Members of Congress. Chief Sund was “still making other calls to other agencies for support—ATF, FBI, you name it, Secret Service.”
      <Citation number={160} />
    </Typography>

    <Typography className="indent">
      Yogananda Pittman, Gallagher’s direct supervisor, told the Select Committee that she took roles that day beyond her responsibility as Assistant Chief of Police for Protective and Intelligence Operations. “So we started—so I started to take Protective Services Bureau resources, as well as the chief's staff, to set up operations adjacent to headquarters building, specifically lot 16, so that we could have a check-in procedure for those units so they could stage vehicles,” Pittman said.
      <Citation number={161} />
      “Because like we know now, there were breaches on both sides of the buildings and these folks are inside of the Capitol. So you have to deploy them with your officers. They don't know the layout of the land. We're telling them to respond to north barricade. They don't know the north from the south.”
      <Citation number={162} />
    </Typography>

    <Typography className="indent">
      Just after 2 p.m., when the Capitol was breached, Assistant Chief Pittman turned her full attention to the protection of congressional leadership.
      <Citation number={163} />
      Meanwhile, the USCP officers at the West Front were overwhelmed. Commander Glover praised the actions of his fellow law enforcement officers that day but also noted a lack of leadership.
      <Citation number={164} />
      He observed that the USCP officers he encountered when walking toward the Capitol’s West Front seemed to be “very hectic and scattered, with no clear direction, ...fighting for every inch on the line,” capable, but “without a whole lot of command and control.”
      <Citation number={165} />
    </Typography>

    <Typography className="indent">
      When it became clear to him that securing the Capitol would require additional resources, Chief Sund requested the assistance of the DC National Guard.
      <Citation number={166} />
      During a 2:30 p.m. call set up by HSEMA Director Rodriguez, the USCP specified the support they needed from the Guard.
      <Citation number={167} />
      Mayor Bowser also made “two urgent requests of the President” that she communicated to Chief of Staff Mark Meadows.
      <Citation number={168} />
      One was for the National Guard’s assistance.
      <Citation number={169} />
      The other was that the President make a statement asking “people to leave, to leave the building and to get out of the city, to stop.”
      <Citation number={170} />
      At 2:56 p.m., Meadows told Mayor Bowser that the President “had approved the request” and was “going to make a statement.”
      <Citation number={171} />
    </Typography>

    <Typography className="indent">
      At the Justice Department, it had become clear by early afternoon that the situation was rapidly deteriorating. Donoghue first became aware of the Capitol breach when he walked into Rosen’s office and saw on television that the rioters were in the Rotunda.
      <Citation number={172} />
      Rosen turned to him and said, “[D]o you see this, do you see what's going on, can you believe this?”
      <Citation number={173} />
    </Typography>

    <Typography className="indent">
      At 2:14 p.m., the Vice President’s detail had alerted Secret Service over their radio channel that the Capitol Building had been breached and that they were holding the Vice President in his Senate office.
      <Citation number={174} /> About 5 minutes later, the detail reported that the rioters had gained access to the second floor and that they would need to relocate the Vice President,
      <Citation number={175} />
      despite the Vice President’s objection.
      <Citation number={176} />
      Five to 7 minutes later, after confirming that the route was safe, the lead agent on the Vice President’s Secret Service detail reasserted the need for the Vice President to leave his Senate office.
      <Citation number={177} />
      (At 2:24 p.m. President Trump tweeted, “Mike Pence didn’t have the courage to do what should have been done.”) At 2:25 p.m., the Vice President and his detail left for a secure location.
      <Citation number={178} />
      Vice President Pence refused to leave the Capitol for his residence and remained in the secure Capitol location until the Senate and House floors were cleared around 7 that evening.
      <Citation number={179} />
    </Typography>

    <Typography className="indent">
      At 2:29 p.m., DC MPD Commander Glover transmitted an emergency radio message: “Cruiser 50. We lost the line. We've lost the line. All MPD, pull back. All MPD, pull back up to the upper deck ASAP. All MPD, pull back to the upper deck ASAP. All MPD, come back to the upper deck. Upper deck. Cruiser 50. We've been flanked. 10–33. I repeat, 10–33 West Front of the Capitol. We have been flanked, and we've lost the line.”
      <Citation number={180} />
      Commander Glover later told the Select Committee that a “10–33” indicates an immediate need for “emergency assistance for any officer, life or death at that moment in time. That's when that line on the north side finally just broke and we just lost it, and we kind of got overrun behind us... [W]hen you hear that in general daily activity, it's like the radio stops and you're focused on getting to that officer, wherever they are, because you know it's that bad, that they're fighting for their life; something they're perceiving or seeing or realizing is that their life is in immediate danger.”
      <Citation number={181} />
    </Typography>

    <Typography className="indent">
      Donoghue left Rosen’s office to go to the FBI’s Strategic Information and Operations Center (SIOC) across the street at the FBI’s Hoover Building.
      <Citation number={182} />
      Before he left, Donoghue remembers someone at SIOC telling him “Capitol Police say they don't need help at this point, they've got it covered.”
      <Citation number={183} />
      When Donoghue arrived at the Washington Field Office, he found FBI Deputy Director Bowdich in a conference room by himself on the phone with a senior FBI official.
      <Citation number={184} />
      After a brief discussion, Donoghue and Bowdich agreed that they should both go to the Capitol to evaluate the situation firsthand.
      <Citation number={185} />
      Donoghue remembered that they arrived at the assembly area on D Street.
      <Citation number={186} />
      Donoghue called Rosen to say that he and Bowdich were going to the Capitol.
      <Citation number={187} />
    </Typography>

    <Typography className="indent">
      Around this time, Bowdich says that he received a call from Senator Mark Warner, who said “[t]his is a mess, and we now have the vast majority of the Senate in one room.”
      <Citation number={188} />
      Bowdich recalls the number being about 87 senators,
      <Citation number={189} />
      and that he directed the FBI’s Baltimore team to “protect that room, recognizing you have almost the entire Senate in one room.”
      <Citation number={190} />
      Bowdich also directed a SWAT team to Senator Mitch McConnell’s office, in response to a call from McConnell’s staff informing him that rioters were kicking in their door. On arriving, the SWAT team found that McConnell’s staff had reached safety.
      <Citation number={191} />
    </Typography>

    <Typography className="indent">
      At 3:25 p.m., Rosen spoke to Speaker Nancy Pelosi and Senator Chuck Schumer about the ongoing crisis. A video of the call shows Senator Schumer imploring Rosen, “get the President to tell them to leave the Capitol, Mr. Attorney General, in your law enforcement responsibility. A public statement that they should all leave.”
      <Citation number={192} />
    </Typography>

    <Typography className="indent">
      As the day’s crisis unfolded, Mayor Bowser activated the DC mutual assistance compact with neighboring jurisdictions for law assistance support and spoke to the Governors of Maryland and Virginia to solicit additional National Guard support. At about 3:30 p.m., Mayor Bowser spoke to congressional leadership, including Speaker Pelosi and House Majority Leader Steny Hoyer.
      <Citation number={193} /> Then, around 4 p.m., Mayor Bowser, MPD Chief Contee and Army Secretary McCarthy met in the Joint Operations Center at MPD headquarters.
      <Citation number={194} /> At 4:30 p.m., Mayor Bowser held a press conference with DC HSEMA Director Rodriguez as well as Secretary McCarthy.
      <Citation number={195} /> Mayor Bowser also declared a 6 p.m. curfew for the District.
      <Citation number={196} />
    </Typography>

    <Typography className="indent">
      Vice President Pence, who remained inside the Capitol, called Acting Attorney General Rosen at 4:34 p.m. to ask what the DOJ was doing and what more the Department could do to help.
      <Citation number={197} />
      Vice President Pence told Rosen that the situation at the Capitol seemed then to be “improving.”
      <Citation number={198} />
      The head of his USSS security detail recalls overhearing the Vice President asking USCP Chief Sund, over the phone, whether it would be possible to “go back to finish the business of the government this evening.”
      <Citation number={199} />
      At 4:42 p.m., the head of the Vice President’s detail emailed the USSS Office of Protective Operations that the Vice President was confirming with Chief Sund that it would “take days to sweep and reopen” the Capitol.
      <Citation number={200} />
    </Typography>

    <Typography className="indent">
      Congressional leadership continued to push to return to the Capitol to continue certifying the electoral votes. Senior DOJ and FBI officials— including Rosen, Bowdich, and Donoghue—held two conference calls. Donoghue remembered that the first, at 6 p.m., was a “law enforcement-level call” with General Daniel R. Hokansen, chief of the National Guard Bureau, and focused on the role of the DC National Guard.
      <Citation number={201} />
      The second call, at approximately 7 p.m., included Speaker Pelosi, Leader McConnell, Leader Schumer, the Vice President, the Secretary of Defense, and General Milley, as well as other congressional leaders.
      <Citation number={202} />
      During that call, FBI and other law enforcement officials on the ground provided an updated timeline for clearing the Capitol to “hopefully get in an hour later.”
      <Citation number={203} />
    </Typography>

    <Typography className="indent">
      At 8:05 p.m., the U.S. Capitol Police announced that the Capitol Building was clear and that Congress could resume counting electoral votes.
      <Citation number={204} />
      Shortly after Members returned, Donoghue left the Capitol.
      <Citation number={205} />
    </Typography>

    <Typography className="indent">
      DC FEMS statistics help describe the scope of the January 6th riot at the Capitol. Over the course of the day, DC FEMS reported 22 EMS responses, 14 EMS transports, including two cardiac arrests and two critical injury transports. There were an estimated 250 injured law enforcement officers from numerous agencies.
      <Citation number={206} />
      One hundred-fourteen USCP officers reported injuries.
      <Citation number={207} />
      Five police officers who were at the Capitol on January 6th died in the days following the riot.
    </Typography>

    <Typography className="indent">
      Federal and local law enforcement authorities were in possession of multiple streams of intelligence predicting violence directed at the Capitol prior to January 6th. Although some of that intelligence was fragmentary, it should have been sufficient to warrant far more vigorous preparations for the security of the joint session. The failure to sufficiently share and act upon that intelligence jeopardized the lives of the police officers defending the Capitol and everyone in it.
    </Typography>

    <Typography className="indent">
      While the danger to the Capitol posed by an armed and angry crowd was foreseeable, the fact that the President of the United States would be the catalyst of their fury and facilitate the attack was unprecedented in American history. If we lacked the imagination to suppose that a President would incite an attack on his own Government, urging his supporters to “fight like hell,” we lack that insight no more. And the best defense against that danger will not come from law enforcement, but from an informed and active citizenry.
    </Typography>
  </Page>
)
