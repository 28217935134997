import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet5'));

export const Chapter31 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      The fake elector plan emerged from a series of legal memoranda written by an outside legal advisor to the Trump Campaign: Kenneth Chesebro. Although John Eastman would have a more prominent role in advising President Trump in the days immediately before January 6th, Chesebro—an attorney based in Boston and New York recruited to assist the Trump Campaign as a volunteer legal advisor—was central to the creation of the plan.
      <Citation number={8} />
      Memos by Chesebro on November 18th, December 9th, and December 13th, as discussed below, laid the plan’s foundation.
    </Typography>

    <Typography className="indent">
      Chesebro’s first memo on November 18th suggested that the Trump Campaign could gain a few extra weeks for litigation to challenge Wisconsin’s election results, so long as a Wisconsin slate of Republican nominees to the electoral college met on December 14th to cast placeholder electoral college votes on a contingent basis.
      <Citation number={9} />
      This memo acknowledged that “[i]t may seem odd that the electors pledged to Trump and Pence might meet and cast their votes on December 14 even if, at that juncture, the Trump-Pence ticket is behind in the vote count, and no certificate of election has been issued in favor of Trump and Pence.”
      <Citation number={10} />
      However, Chesebro argued that if such a slate of alternate electors gathered to cast electoral votes on a contingent basis, this would preserve the Trump Campaign’s options so “a court decision (or, perhaps, a state legislative determination) rendered after December 14 in favor of the Trump-Pence slate of electors should be timely.”
      <Citation number={11} />
    </Typography>

    <Typography className="indent">
      On December 9th, Chesebro penned a second memo, which suggested another purpose for fake electoral college votes on January 6th. It stated that unauthorized Trump electors in these States could be retroactively recognized “by a court, the state legislature, <i>or Congress</i>.”
      <Citation number={12} />
      Under this theory, there would be no need for a court to decide that the election had been decided in error; instead, Congress itself could choose among dueling slates of purported electoral votes—and thereby decide the Presidential election— even though Article II of the Constitution grants that power to the electoral college via the States.
      <Citation number={13} />
    </Typography>

    <Typography className="indent">
      Chesebro’s contemporaneous communications make clear that the goal was having Congress act on the fake electoral votes. He emailed an organizer of the fake electors in Nevada that “the purpose of having the electoral votes sent in to Congress is to provide the opportunity to debate the election irregularities in Congress, and to keep alive the possibility that the votes could be flipped to Trump...”
      <Citation number={14} />
      And a legal advisor to the Arizona GOP reportedly described being told by Chesebro around this time that their supposed electors “would just be sending in ‘fake’ electoral votes to Pence so that ‘someone’ in Congress can make an objection when they start counting votes, and start arguing that the ‘fake’ votes should be counted.”
      <Citation number={15} />
    </Typography>

    <Typography className="indent">
      Many of the States contested by the Trump team had laws that specified requirements for electors to validly cast and transmit their votes—and the December 9, 2020, memo recognized that some of these criteria would be difficult, if not impossible, for the fake electors to fulfill. (As described later, most were not fulfilled.) For example, Nevada State law required that the secretary of state preside when Presidential electors meet,
      <Citation number={16} />
      and Nevada Secretary of State Barbara Cegavske, a Republican, had already signed a certificate ascertaining the Biden/Harris electors as the authorized, winning slate.
      <Citation number={17} />
      Several States also had rules requiring electors to cast their votes in the State capitol building, or rules governing the process for approving substitutes if any original proposed electors from the November ballot were unavailable. As a result, Chesebro’s December 9, 2020, memo advised the Trump Campaign to abide by such rules, when possible, but also recognized that these slates could be “slightly problematic in Michigan,” “somewhat dicey in Georgia and Pennsylvania,” and “very problematic in Nevada.”
      <Citation number={18} />
    </Typography>

    <Typography className="indent">
      On December 13th, the fake elector scheme became even clearer in an email sent by Chesebro to Giuliani. His message was entitled “Brief notes on ‘President of the Senate’ strategy.” It addressed how the fake electors meeting the next day, December 14th, could be exploited during the joint session of Congress on January 6th by the President of the Senate—a role that the Constitution grants to the Vice President of the United States.
      <Citation number={19} />
      Chesebro argued that, on January 6th, the President of the Senate could:
    </Typography>

    <Blockquote>
      <Typography>
        ...firmly take the position that he, and he alone, is charged with the constitutional responsibility not just to open the votes, but to count them—including making judgments about what to do if there are conflicting votes...
        <Citation number={20} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Chesebro’s email suggested that the President of the Senate (which under the Constitution, is the Vice President) could toss out former Vice President Biden’s actual electoral votes for any State where the Trump Campaign organized fake electors, simply “because there are two slates of votes.”
      <Citation number={21} />
      Ofcourse,there were never two slates of electoral votes, so this premise itself was fundamentally wrong. But he was arguing that even if votes by fake electors were never retroactively ratified under State law, their mere submission to Congress would be enough to allow the presiding officer to disregard valid votes for former Vice President Biden.
      <Citation number={22} />
      Chesebro suggested this might result in a second term for President Trump, or, at minimum, it would force a debate about purported election fraud—neither of which was a lawful, legitimate reason to organize and convene fake electors.
      <Citation number={23} />
    </Typography>

    <Typography className="indent">
      As discussed below and in Chapter 5, John Eastman worked with Chesebro as January 6th approached and wrote two additional memos that built upon, and extended, the plan to use the fake electoral votes during the joint session.
      <Citation number={24} />
    </Typography>
  </Page>
)
