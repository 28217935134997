import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet5'));

export const Chapter30 = ({ ...args }: any) => (
  <Page {...args}>
    <Image
      src="/images/image-044.jpg"
      alt="Georgia Electors cast their Electoral College votes at the Georgia State Capitol on December 14, 2020."
      caption="Georgia Electors cast their Electoral College votes at the Georgia State Capitol on December 14, 2020."
      credit="(Photo by Jessica McGowan/Getty Images)"
      style={{ marginTop: 0 }}
    />

    <Typography className="indent">
      On the morning of January 6th, in his speech at the Ellipse, President Trump exhorted his thousands of assembled supporters to march to the U.S. Capitol, explaining that “[w]e have come to demand that Congress do the right thing and only count the electors who have been lawfully slated, lawfully slated.”
      <Citation number={1} />
      This was no off-the-cuff remark; it was the culmination of a carefully planned scheme many weeks in the making. This plea by the President turned the truth on its head. There was only one legitimate slate of electors from the battleground States of Arizona, Georgia, Michigan, Nevada, New Mexico, Pennsylvania, and Wisconsin, and Trump wanted them rejected. This scheme involved lawyers, such as Kenneth Chesebro and Rudy Giuliani, as well as Mark Meadows. It also was aided at key points by Chairwoman of the Republican National Committee Ronna McDaniel, Members of Congress, and Republican leaders across seven States—some of whom did not know exactly what they were being asked to do. President Trump oversaw it himself.
    </Typography>

    <Typography className="indent">
      President Trump and his allies prepared their own fake slates of electoral college electors in seven States that President Trump lost: Arizona, Georgia, Michigan, Nevada, New Mexico, Pennsylvania, and Wisconsin. And on December 14, 2020—the date when true, certified electors were meeting to cast their electoral votes for the candidate who had won the popular vote in each of those States—these fake electors also met, ostensibly casting electoral votes for President Trump, the candidate who had lost.
    </Typography>

    <Typography className="indent">
      There was no legitimate reason for Trump electors to meet, vote, and produce fake slates on December 14th in States that former Vice President Biden won. Instead, this effort was aimed directly at the President of the Senate (which, under the Constitution, is the Vice President) in his role at the joint session of Congress on January 6th. President Trump and his advisors wanted Vice President Pence to disregard real electoral college votes for former Vice President Biden, in favor of these fake competing electoral slates.
    </Typography>

    <Typography className="indent">
      But there never were real, competing slates of electors. By the time the fake Trump electors met on December 14th, appropriate government officials in each of the seven States had already certified their State’s official election results for former Vice President Biden. No court had issued an order reversing or calling into question those results, and most election-related litigation was over. And as detailed in Chapter 2, despite the illicit efforts of President Trump and his allies, no State legislature had agreed to the President’s request to reverse the result of the election by appointing a different slate of electors.
    </Typography>

    <Typography className="indent">
      Given all of this, these groups of Trump backers who called themselves Presidential electors were never actually electors, and the votes they purported to cast on December 14th were not valid. They were fake. They had no legal standing, and their fake votes could not have been used by Vice President Pence to disregard the real votes of electors chosen by the voters.
    </Typography>

    <Typography className="indent">
      By January 6th, President Trump had been discouraged by his top lawyers from following through on this plan. The Trump Campaign’s senior staff attorneys had concerns,
      <Citation number={2} />
      and several days before the joint session, the Acting Attorney General and the Deputy Attorney General blocked the sending of a letter indicating that there were “competing slates” of electors, including “in Georgia and several other States.”
      <Citation number={3} />
      But this reasoning did nothing to change President Trump’s rhetoric or plan. He continued to assert that there were “competing” or “dual” slates of electors to create an opportunity to stay in office on January 6th.
      <Citation number={4} />
    </Typography>

    <Typography className="indent">
      These lawyers were right: President Trump’s plan was illegal. In his June 7, 2022, opinion, Federal District Judge David Carter wrote that this initiative to “certify alternate slates of electors for President Trump” constituted a “critical objective of the January 6 plan.”
      <Citation number={5} />
      This followed Judge Carter’s earlier determination in March that “[t]he illegality of the plan was obvious,” and “[e]very American—and certainly the President of the United States—knows that in a democracy, leaders are elected, not installed. With a plan this ‘BOLD,’ President Trump knowingly tried to subvert this fundamental principle. Based on the evidence the Court finds it more likely than not that President Trump corruptly attempted to obstruct the Joint Session of Congress on January 6, 2021.”
      <Citation number={6} />
    </Typography>

    <Typography className="indent">
      The fake elector effort was an unlawful, unprecedented and destructive break from the electoral college process that our country has used to select its President for generations.
      <Citation number={7} />
      It led directly to the violence that occurred on January 6th. To address the damage that it caused, it is important to understand how it transpired.
    </Typography>
  </Page>
)
