import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet8'));

export const Chapter60 = ({ ...args }: any) => (
  <Page {...args}>
    <Image src="/images/image-075.jpg" alt="Donald Trump, tweet" style={{ marginTop: 0 }} />

    <Typography className="indent">
      On December 14, 2020, electors around the country met to cast their Electoral College votes. Their vote ensured former Vice President Joe Biden's victory and cemented President Donald J. Trump's defeat. The people, and the States, had spoken. Members of President Trump’s own Cabinet knew the election was over. Attorney General William Barr viewed it as “the end of the matter.”
      <Citation number={1} />
      Secretary of State Mike Pompeo and Secretary of Labor Eugene Scalia concurred.
      <Citation number={2} />
      That same day, Scalia told President Trump directly that he should concede defeat.
      <Citation number={3} />
    </Typography>

    <Typography className="indent">
      President Trump had no intention of conceding. As he plotted ways to stay in power, the President summoned a mob for help.
    </Typography>

    <Typography className="indent">
      At 1:42 a.m., on December 19th, President Trump tweeted: “Big protest in D.C. on January 6th. Be there, will be wild!”
      <Citation number={4} />
    </Typography>

    <Typography className="indent">
      The President’s tweet galvanized tens of thousands of his supporters around the country. President Trump had been lying to them since election day, claiming he won, and that the Democrats had stolen victory from him. Now, with a single tweet, the President focused his supporters’ anger on the joint session of Congress in Washington, DC on January 6th.
    </Typography>

    <Typography className="indent">
      Anika Navaroli, the longest-tenured member of Twitter’s Trust and Safety Policy team, monitored the reaction to President Trump’s “be wild” tweet. She told the Select Committee that the President was “essentially staking a flag in DC... for his supporters to come and rally.”
      <Citation number={5} />
      The tweet created a “fire hose” of calls to overthrow the U.S. Government. President Trump’s supporters had a new sense of urgency because they felt “as if their Commander in Chief” had summoned them.
      <Citation number={6} />
    </Typography>

    <Typography className="indent">
      For many extremists and conspiracy theorists, the President’s announcement was a call to arms.
      <Citation number={7} />
    </Typography>

    <Image
      src="/images/image-079.jpg"
      alt="Tarrio’s video appears on a screen during a Select Committee hearing on June 09, 2022."
      caption="Tarrio’s video appears on a screen during a Select Committee hearing on June 09, 2022."
      credit="(Photo by Drew Angerer/Getty Images)"
    />

    <Typography className="indent">
      For the Proud Boys—described in more detail below—and their leader, Henry “Enrique” Tarrio, President Trump’s tweet set in motion a chain of events that led directly to the attack on the U.S. Capitol. In the days that followed, the Proud Boys reorganized their hierarchy, imposed a stricter chain-of-command, and instructed followers to go “incognito” on January 6th.
      <Citation number={8} />
      The Proud Boys had made their presence known at previous pro-Trump events, including “Stop the Steal” rallies, where they brandished their black and yellow apparel and engaged in street brawls.
      <Citation number={9} />
      Suddenly, they did not want to stand out from the crowd. They wanted to blend in. They were planning something big.
      <Citation number={10} />
    </Typography>

    <Typography className="indent">
      Tarrio allegedly used encrypted messages to plot the January 6, 2021, attack. On January 4, 2021, Tarrio told his men that they should “storm the Capitol.”
      <Citation number={11} />
      While the attack was underway, Tarrio claimed credit in a private chat, writing: “We did this.”
      <Citation number={12} />
      And on the evening of January 6th, Tarrio released a video of a man, presumably Tarrio himself, dressed in an odd costume standing in front of the U.S. Capitol. The eerie production had been recorded prior to the events of that day. Tarrio—who was not in Washington, DC on January 6th
      <Citation number={13} />
      —titled it, “Premonition.”
      <Citation number={14} />
    </Typography>

    <Typography className="indent">
      The Oath Keepers, a far-right, anti-government militia movement— also described in more detail below—began planning for January 6th after the President’s tweet as well. Stewart Rhodes, the group’s leader, had agitated against the U.S. Government for years.
      <Citation number={15} />
      Immediately following the 2020 presidential election, Rhodes and others schemed to stop the peaceful transfer of power. They stored weapons outside of Washington, DC,
      <Citation number={16} />
      hoping that President Trump would deputize them as his own militia.
      <Citation number={17} />
      An Oath Keeper leader, Kelly Meggs, read President Trump’s December 19th tweet and commented in a Facebook message: “He called us all to the Capitol and wants us to make it wild!!! Sir Yes Sir!!!”
      <Citation number={18} />
      The Oath Keepers formed two military “stacks” and marched up the steps of the U.S. Capitol on January 6th. Meggs led one of them.
      <Citation number={19} />
    </Typography>

    <Typography className="indent">
      Members of both the Proud Boys and Oath Keepers have been charged with “seditious conspiracy” and other serious crimes, including conspiracy to interfere with a Federal proceeding; some, including Stewart Rhodes, have been convicted.
      <Citation number={20} />
      U.S. law defines seditious conspiracy as plotting “to overthrow,” or “to oppose by force,” or to use “force to prevent, hinder, or delay the execution of any law of the United States.”
      <Citation number={21} />
      Someofthetwo groups’ members have already admitted that this is what they intended to do.
      <Citation number={22} />
    </Typography>

    <Typography className="indent">
      Other extremists and conspiracy theorists mobilized after President Trump’s tweet as well. These movements are described in more detail in subsequent sections. Three Percenter militias—another far-right, anti-government movement—shared “#OccupyCongress” memes
      <Citation number={23} />
      and planned for violence at the U.S. Capitol.
      <Citation number={24} />
      Nick Fuentes, leader of the white nationalist “Groypers,” rallied his followers for January 6th.
      <Citation number={25} />
      Fuentes bragged afterwards that the “Capitol siege was fucking awesome.”
      <Citation number={26} />
      Users on TheDonald.win, a website populated by some of President Trump’s most ardent fans, openly discussed surrounding and occupying the U.S. Capitol.
      <Citation number={27} />
    </Typography>

    <Typography className="indent">
      Adherents of QAnon, a bizarre and dangerous conspiracy cult, believed January 6th would bring the prophesied “Storm”—a violent purge of Democrats and government officials promised by the mysterious online personality known only as “Q.”
      <Citation number={28} />
      QAnon’s devotees flocked to Washington, DC because of the President’s tweet and subsequent rhetoric. They shared a digital banner, “Operation Occupy the Capitol,” which depicted the U.S. Capitol being torn in two.
      <Citation number={29} />
    </Typography>

    <Typography className="indent">
      One especially notorious conspiracy theorist, Alex Jones, repeatedly told his <i>InfoWars’</i> viewers that January 6th would be a day of reckoning.
      <Citation number={30} />
      Jones is known for his outlandish conspiracy-mongering, including his baseless claim that the massacre of school children at Sandy Hook Elementary School was really a “false flag” operation staged by the U.S. Government. Of course, his vicious lie was disproven in court, but Jones is obsessed with “deep state” conspiracy theories and often propagates them.
      <Citation number={31} />
      After the 2020 presidential election, Jones argued that President Trump should use the power of the Government to impose martial law on American citizens.
      <Citation number={32} />
      Along with his <i>InfoWars</i> co-hosts, Jones amplified President Trump’s “Big Lie” and relentlessly promoted President Trump’s “wild” protest. One of Jones’ co-hosts floated the idea of “storming right into the Capitol.”
      <Citation number={33} />
      Jones himself marched to the Capitol January 6th.
      <Citation number={34} />
    </Typography>

    <Typography className="indent">
      Jones’s influence helped shape the planning for January 6th behind the scenes as well. The Select Committee investigated how event organizers and the White House staff planned President Trump’s rally at the Ellipse, a park south of the White House. This event was intended to rile up the President’s supporters just prior to the joint session of Congress. A wealthy heiress paid for the event after listening to Jones’ <i>InfoWars</i> rant about the importance of President Trump’s tweet. She spent $3 million with the goal to “get as many people there as possible.”
      <Citation number={35} />
      It worked—Americans who believed the election was stolen flocked to the Nation’s capital.
    </Typography>

    <Typography className="indent">
      By January 5th, President Trump’s supporters—a large, angry crowd ready for instructions—had assembled in Washington. That evening, he could hear his raucous supporters at a rally not far from the White House. The President knew his supporters were “angry,”
      <Citation number={36} />
      and he planned to call on them to march on the U.S. Capitol.
      <Citation number={37} />
      He even wanted to join them on the march.
      <Citation number={38} />
      It was all part of President Trump’s plan to intimidate officials and obstruct the joint session of Congress.
    </Typography>

    <Typography className="indent">
      “We fight like hell,” President Trump told the crowd assembled at the Ellipse on January 6, 2021. “And if you don’t fight like hell, you’re not going to have a country anymore.”
      <Citation number={39} />
      Some of those in attendance, as well as elsewhere in Washington that day, were already prepared to fight. They had begun preparing two and a half weeks earlier—when President Trump told them it would “be wild!”
    </Typography>
  </Page>
)
