import { useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';

import { FBLikeButton } from '../../components/FBLikeButton';
import { Header } from '../../components/Header';
import { routes } from '../../App/Router';

import { GoogleAd } from '../../components/GoogleAd';
import { useFbAsync } from '../../hooks';

const StyledIntroduction = styled('div')(({ theme }) => `
  display: flex;
  flex-direction: column;
  background-image: url('/images/image-000.jpg');
  background-attachment: fixed;
  background-position: 0 90%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: ${theme.spacing(2)} 0;

  .intro,
  .contributors {
    padding: ${theme.spacing(2)};
  }

  .intro-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .intro {
    text-align: center;
    width: 300px;
  }

  .contributors {
    text-align: center;
  }

  @media (max-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    .intro {
      width: 100%;
    }
  }
`);

const StyledGrid = styled('div')(({ theme}) => `
  padding: ${theme.spacing(2)} 0;
  display: flex;
  justify-content: center;

  @media (max-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    flex-direction: column;
  }
`);

const StartReading = ({ to, label }: any) => {
  const theme = useTheme();
  const buttonVariant = theme.palette.mode === 'dark'
    ? 'primary'
    : 'secondary';

  return (
    <Link to={to} style={{ textDecoration: 'none', margin: '-8px 0 40px', display: 'block' }}>
      <Button variant="contained" color={buttonVariant}>{label}</Button>
    </Link>
  )
}

export const Introduction = ({ next }: any) => {
  const theme = useTheme();
  const narrativeStartRoute = useMemo(() => routes.find(({ route }: any) => route.match(/^\/narrative/)), []);
  const summaryStartRoute = useMemo(() => routes.find(({ route }: any) => route.match(/^\/executive-summary/)), []);
  const likeRef = useRef<HTMLDivElement>(null);

  useFbAsync(likeRef);

  return (
    <>
      <Helmet>
        <title>J6 Report</title>

        <meta property="og:description" content="(reader friendly, w/ audio)" />
        <meta property="og:url" content={window.location.origin + window.location.pathname} />
        <meta property="og:image" content={`${window.location.origin}/images/image-000.jpg`} />)
        <meta property="og:image:type" content="image/jpeg" />)
      </Helmet>

      <Header next={next} prev={null} />

      <Box className="overflow-scroll" style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
      }}>
        <StyledIntroduction>
          <Container maxWidth="xl">
            <Box className="intro-wrapper">
              <Paper className="intro">
                <div ref={likeRef} style={{ textAlign: 'right', marginBottom: theme.spacing(1) }}>
                  <FBLikeButton />
                </div>

                <Typography sx={{ fontSize: 34 }}>FINAL REPORT</Typography>


                <StartReading to={next.route} label='Forewords' />
                <StartReading to={summaryStartRoute.route} label='Skip to Executive Summary' />
                <StartReading to={narrativeStartRoute.route} label='Skip to Narrative' />

                <Divider />
                <br />

                <Typography>Select Committee to Investigate the</Typography>
                <Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>January 6th</Typography>
                <Typography>Attack on the United States Capitol</Typography>

                <br />
                <Divider />
                <br />

                <Typography>December 00, 2022</Typography>
                <Typography>117th Congress Second Session</Typography>
                <Typography>House Report 117-000</Typography>
              </Paper>
            </Box>

            <Box sx={theme => ({ margin: `${theme.spacing(20)} 0 ${theme.spacing(2)}`})}>
              <Paper className="contributors">
                <Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>SELECT COMMITTEE TO INVESTIGATE THE JANUARY 6TH ATTACK ON THE UNITED STATES CAPITOL</Typography>

                <br />
                <Divider />
                <br />

                <Typography>BENNIE G. THOMPSON Mississippi, <i>Chairman</i></Typography>
                <Typography>LIZ CHENEY Wyoming, <i>Vice Chair</i></Typography>
                <Typography>ZOE LOFGREN California</Typography>
                <Typography>ADAM B. SCHIFF California</Typography>
                <Typography>PETE AGUILAR California</Typography>
                <Typography>STEPHANIE N. MURPHY Florida</Typography>
                <Typography>JAMIE RASKIN Maryland</Typography>
                <Typography>ELAINE G. LURIA Virginia</Typography>
                <Typography>ADAM KINZINGER Illinois</Typography>
              </Paper>
            </Box>

            <GoogleAd />

            <Box sx={theme => ({ margin: `${theme.spacing(2)} 0`})}>
              <Paper className="contributors">
                <Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>COMMITTEE STAFF</Typography>

                <br />
                <Divider />
                <br />

                <Typography>DAVID B. BUCKLEY, Staff Director</Typography>
                <Typography>KRISTIN L. AMERLING, Deputy Staff Director and Chief Counsel</Typography>
                <Typography>HOPE GOINS, Senior Counsel to the Chairman</Typography>
                <Typography>JOSEPH B. MAHER, Senior Counsel to the Vice Chair</Typography>
                <Typography>TIMOTHY J. HEAPHY, Chief Investigative Counsel</Typography>
                <Typography>JAMIE FLEET, Senior Advisor</Typography>
                <Typography>TIMOTHY R. MULVEY, Communications Director</Typography>
                <Typography>CANDYCE PHOENIX, Senior Counsel and Senior Advisor</Typography>
                <Typography>JOHN F. WOOD, Senior Investigative Counsel and Of Counsel to the Vice Chair</Typography>

                <br />
                <Divider />

                <StyledGrid>
                  <Box sx={theme => ({ marginRight: theme.spacing(4) })}>
                    <Typography>KATHERINE B. ABRAMS, Staff Associate</Typography>
                    <Typography>TEMIDAYO AGANGA-WILLIAMS, Senior Investigative Counsel</Typography>
                    <Typography>ALEJANDRA APECECHEA, Investigative Counsel</Typography>
                    <Typography>LISA A. BIANCO, Director of Member Services and Security Manager</Typography>
                    <Typography>JEROME P. BJELOPERA, Investigator</Typography>
                    <Typography>BRYAN BONNER, Investigative Counsel</Typography>
                    <Typography>RICHARD R. BRUNO, Senior Administrative Assistant</Typography>
                    <Typography>MARCUS CHILDRESS, Investigative Counsel</Typography>
                    <Typography>JOHN MARCUS CLARK, Security Director</Typography>
                    <Typography>JACQUELINE N. COLVETT, Digital Director</Typography>
                    <Typography>HEATHER I. CONNELLY, Professional Staff Member</Typography>
                    <Typography>MEGHAN E. CONROY, Investigator</Typography>
                    <Typography>HEATHER L. CROWELL, Printer Proofreader</Typography>
                    <Typography>WILLIAM C. DANVERS, Senior Researcher</Typography>
                    <Typography>SOUMYALATHA O. DAYANANDA, Senior Investigative Counsel</Typography>
                    <Typography>STEPHEN W. DEVINE, Senior Counsel</Typography>
                    <Typography>LAWRENCE J. EAGLEBURGER, Professional Staff Member</Typography>
                    <Typography>KEVIN S. ELLIKER, Investigative Counsel</Typography>
                    <Typography>MARGARET E. EMAMZADEH, Staff Associate</Typography>
                    <Typography>SADALLAH A. FARAH, Professional Staff Member</Typography>
                    <Typography>DANIEL GEORGE, Senior Investigative Counsel</Typography>
                    <Typography>JACOB H. GLICK, Investigative Counsel</Typography>
                    <Typography>AARON S. GREENE, Clerk</Typography>
                    <Typography>MARC S. HARRIS, Senior Investigative Counsel</Typography>
                    <Typography>ALICE K. HAYES, Clerk</Typography>
                    <Typography>QUINCY T. HENDERSON, Staff Assistant</Typography>
                    <Typography>JENNA HOPKINS, Professional Staff Member</Typography>
                    <Typography>CAMISHA L. JOHNSON, Professional Staff Member </Typography>
                  </Box>
                  <Box>
                    <Typography>THOMAS E. JOSCELYN, Senior Professional Staff Member</Typography>
                    <Typography>REBECCA L. KNOOIHUIZEN, Financial Investigator</Typography>
                    <Typography>CASEY E. LUCIER, Investigative Counsel</Typography>
                    <Typography>DAMON M. MARX, Professional Staff Member</Typography>
                    <Typography>EVAN B. MAULDIN, Chief Clerk</Typography>
                    <Typography>YONATAN L. MOSKOWITZ, Senior Counsel</Typography>
                    <Typography>HANNAH G. MULDAVIN, Deputy Communications Director</Typography>
                    <Typography>JONATHAN D. MURRAY, Professional Staff Member</Typography>
                    <Typography>JACOB A. NELSON, Professional Staff Member</Typography>
                    <Typography>ELIZABETH OBRAND, Staff Associate</Typography>
                    <Typography>RAYMOND O’MARA, Director of External Affairs</Typography>
                    <Typography>ELYES OUECHTATI, Technology Partner</Typography>
                    <Typography>ROBIN M. PEGUERO, Investigative Counsel</Typography>
                    <Typography>SANDEEP A. PRASANNA, Investigative Counsel</Typography>
                    <Typography>BARRY PUMP, Parliamentarian</Typography>
                    <Typography>SEAN M. QUINN, Investigative Counsel</Typography>
                    <Typography>BRITTANY M. J. RECORD, Senior Counsel</Typography>
                    <Typography>DENVER RIGGLEMAN, Senior Technical Advisor</Typography>
                    <Typography>JOSHUA D. ROSELMAN, Investigative Counsel</Typography>
                    <Typography>JAMES N. SASSO, Senior Investigative Counsel</Typography>
                    <Typography>GRANT H. SAUNDERS, Professional Staff Member</Typography>
                    <Typography>SAMANTHA O. STILES, Chief Administrative Officer</Typography>
                    <Typography>SEAN P. TONOLLI, Senior Investigative Counsel</Typography>
                    <Typography>DAVID A. WEINBERG, Senior Professional Staff Member</Typography>
                    <Typography>AMANDA S. WICK, Senior Investigative Counsel</Typography>
                    <Typography>DARRIN L. WILLIAMS, JR., Staff Assistant</Typography>
                    <Typography>ZACHARY S. WOOD, Clerk</Typography>
                  </Box>
                </StyledGrid>
              </Paper>
            </Box>

            <Box sx={theme => ({ margin: `${theme.spacing(2)} 0`})}>
              <Paper className="contributors">
                <Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>CONTRACTORS & CONSULTANTS</Typography>

                <br />
                <Divider />
                <br />

                <Typography>RAWAA ALOBAIDI</Typography>
                <Typography>MELINDA ARONS</Typography>
                <Typography>STEVE BAKER</Typography>
                <Typography>ELIZABETH BISBEE</Typography>
                <Typography>DAVID CANADY</Typography>
                <Typography>JOHN COUGHLIN</Typography>
                <Typography>AARON DIETZEN</Typography>
                <Typography>GINA FERRISE</Typography>
                <Typography>ANGEL GOLDSBOROUGH</Typography>
                <Typography>JAMES GOLDSTON</Typography>
                <Typography>POLLY GRUBE</Typography>
                <Typography>L. CHRISTINE HEALEY</Typography>
                <Typography>DANNY HOLLADAY</Typography>
                <Typography>PERCY HOWARD</Typography>
                <Typography>DEAN JACKSON</Typography>
                <Typography>STEPHANIE J. JONES</Typography>
                <Typography>HYATT MAMOUN</Typography>
                <Typography>MARY MARSH</Typography>
                <Typography>TODD MASON</Typography>
                <Typography>RYAN MAYERS</Typography>
                <Typography>JEFF MCBRIDE</Typography>
                <Typography>FRED MURAM</Typography>
                <Typography>ALEX NEWHOUSE</Typography>
                <Typography>JOHN NORTON</Typography>
                <Typography>ORLANDO PINDER</Typography>
                <Typography>OWEN PRATT</Typography>
                <Typography>DAN PRYZGODA</Typography>
                <Typography>BRIAN SASSER</Typography>
                <Typography>WILLIAM SCHERER</Typography>
                <Typography>DRISS SEKKAT</Typography>
                <Typography>CHRIS STUART</Typography>
                <Typography>PRESTON SULLIVAN</Typography>
                <Typography>BRIAN YOUNG</Typography>

                <br />
                <br />

                <Typography>INNOVATIVE DRIVEN</Typography>

                <br />
                <br />

                <br />
                <br />

                <StartReading to={next.route} label='Start Reading' />
              </Paper>
            </Box>

            <GoogleAd />
          </Container>
        </StyledIntroduction>
      </Box>
    </>
  )
}
