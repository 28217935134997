import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet8'));

export const Chapter615 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      As Katrina Pierson helped plan the Ellipse rally, she faced another complication. The “Stop the Steal” movement played an outsized role in promoting January 6th. And now, as the day approached, its leading voices wanted prime speaking gigs—perhaps even on the same stage as President Trump. Roger Stone, Alex Jones and Ali Alexander were all angling for significant stage time. Pierson knew they were trouble.
    </Typography>

    <Typography className="indent">
      In her testimony before the Select Committee, Pierson cited several concerns, including that Jones and Alexander had played a prominent role in the November 2020 protest in Atlanta, Georgia. This was no ordinary protest. Jones and Alexander “had gone into the Georgia Capitol with some inflammatory rhetoric,” Pierson explained.
      <Citation number={440} />
      When Pierson was asked if Jones and Alexander “surrounding the governor’s mansion” and “going into the Capitol” were the “kind of thing” that gave her pause, she responded: “Absolutely.”
      <Citation number={441} />
      After the Georgia protest, Pierson explained, the Kremers—who had helped organize “Stop the Steal” activities— distanced themselves from Jones and Alexander.
      <Citation number={442} />
    </Typography>

    <Image
      src="/images/image-085.jpg"
      alt="Photos of Roger Stone, Alex Jones and Ali Alexander appear on a screen during a Select Committee hearing on July 12, 2022."
      caption="Photos of Roger Stone, Alex Jones and Ali Alexander appear on a screen during a Select Committee hearing on July 12, 2022."
      credit="(Photo by Anna Moneymaker/Getty Images)"
    />

    <Typography className="indent">
      But there was an additional problem. President Trump wanted to include the “Stop the Steal” leaders in the January 6th event. As Pierson put it in a text message to Kylie Kremer: “POTUS... likes the crazies.”
      <Citation number={443} />
      Pierson said that she believed this was the case because President Trump “loved people who viciously defended him in public.”
      <Citation number={444} />
      But their “vicious” defenses of the President clearly troubled Pierson.
    </Typography>

    <Typography className="indent">
      Pierson tried to trim the speaker lineup—which still included the “Stop the Steal” trio of Stone, Jones, and Alexander. She was initially vetoed by the White House after Deputy Chief of Staff for Communications Dan Scavino,
      <Citation number={445} />
      who had approved the “original psycho list.”
      <Citation number={446} />
      At one point, she texted Scavino’s boss, Mark Meadows, saying: “Things have gotten crazy and I desperately need some direction.”
      <Citation number={447} />
      She was concerned by the possibility of “crazy people” being included in the event, their incendiary role in Georgia, and the fact that people coming to Washington, DC were planning to protest at the U.S. Capitol.
      <Citation number={448} />
    </Typography>

    <Typography className="indent">
      Meadows told Pierson that she should take control of the situation and remove the possibility of controversial speakers.
      <Citation number={449} />
      Pierson agreed to do so.
      <Citation number={450} />
      But the President remained an obstacle. During their January 4th meeting, Pierson tried to convince President Trump to minimize the role of these potentially explosive figures at the Ellipse. She offered to place them at a planned event the night before in Freedom Plaza or on other stages in DC on January 6th. She told the President to “[k]eep the fringe on the fringe”
      <Citation number={451} />
      and advised him to “[e]liminate convicted felons that could damage other speakers.”
      <Citation number={452} />
    </Typography>

    <Typography className="indent">
      President Trump was still unwilling to remove them from the lineup entirely. The President instructed Pierson to give Stone a speaking slot on January 5th and asked for more information about Ali Alexander.
      <Citation number={453} />
      After discussing the matter with Scavino, President Trump also requested that Alexander be given a speaking slot. President Trump “brought up Ali [Alexander]... just keep him on stage not associated with POTUS or main event,” Scavino wrote.
      <Citation number={454} />
    </Typography>

    <Typography className="indent">
      In the end, the “Stop the Steal” leaders—Stone, Jones and Alexander — did not appear on the stage at the Ellipse on January 6th, although they did speak at other planned events, consistent with the President’s request about Alexander. “POTUS expectations are [to have something] intimate and then send everyone over to the Capitol,” Pierson explained in a text message to Justin Caporale and Taylor Budowich.
      <Citation number={455} />
      Caporale redacted this text and others in his early production of documents to the Select Committee, and he only revealed them after they had already been produced by other witnesses.
      <Citation number={456} />
    </Typography>

    <Typography className="indent">
      However, other incendiary voices—in addition to President Trump’s — were given time on the Ellipse stage. The Select Committee learned that President Trump’s aides warned him against the inclusion of figures like John Eastman
      <Citation number={457} />
      and Rudolph Giuliani,
      <Citation number={458} />
      given their false claims about election fraud.
      <Citation number={459} />
      Both men, of course, ended up sharing a stage with him on January 6th.
      <Citation number={460} />
      Meadows himself directed that they be allowed to speak.
      <Citation number={461} />
    </Typography>
  </Page>
)
