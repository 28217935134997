import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const ResponsiveDiv = styled('div')(({ theme }) => `
  font-size: .8em;
  width: 100%;
  margin: ${theme.spacing(4)} auto;

  @media (min-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    font-size: 1em;
    width: 80%;
  }

  @media (min-width: ${theme.breakpoints.values.lg}${theme.breakpoints.unit}) {
    width: 60%;
  }
`);

const StyledCredit = styled('div')(({ theme }) => `
  background: ${
    theme.palette.mode === 'dark'
      ? theme.palette.grey[800]
      : theme.palette.grey[200]
  };

  padding: ${theme.spacing(.25)} ${theme.spacing(1)} ${theme.spacing(.5)};
  margin-top: -2px;

`);

export const Image = ({ src, alt, caption, credit, style }: any) => (
  <ResponsiveDiv style={style}>
    <img src={src} alt={alt} width="100%" />

    <StyledCredit>
      <Typography sx={theme => ({ marginBottom: `-${theme.spacing(1)} !important`})}>{ caption }</Typography>
      <Typography variant="overline">{ credit }</Typography>
    </StyledCredit>
  </ResponsiveDiv>
)
