import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet8'));

export const Chapter64 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      As the Proud Boys began their plans for January 6th, Kelly Meggs, the leader of the Florida chapter of the Oath Keepers, reached out. In the past, the Proud Boys and the Oath Keepers had their differences, deriding each other’s tactics and ethos during the summer 2020 protests.
      <Citation number={175} />
      But President Trump’s tweet on December 19th conveyed a sense of urgency which provided the two extremist rivals the opportunity to work together for a common goal.
    </Typography>

    <Typography className="indent">
      After President Trump’s tweet, Meggs called Enrique Tarrio. They spoke for 3 minutes and 26 seconds.
      <Citation number={176} />
      Meggs also sent a message on Facebook, bragging about an alliance he had formed among the Oath Keepers, the Florida Three Percenters, and the Proud Boys: “We have decided to work together and shut this shit down.”
      <Citation number={177} />
      The Oath Keepers were making plans of their own, too.
    </Typography>

    <Typography className="indent">
      “Oath Keepers president [Rhodes] is pretty disheartened,” Roberto Minuta, one of Rhodes’ men, messaged someone on December 19th. “He feels like it's go time, the time for peaceful protest is over in his eyes. I was talking with him last night.”
      <Citation number={178} />
      Minuta has been charged with “seditious conspiracy” and other crimes.
      <Citation number={179} />
    </Typography>

    <Typography className="indent">
      In the days that followed, the Oath Keepers planned for violence. They used encrypted chats on Signal to discuss travel plans, trade tips on tactical equipment to bring, and develop their plans for once they were on the ground in the DC area.
      <Citation number={180} />
      On December 21st, 2020, Joshua James messaged the group, stating, “SE region is creating a NATIONAL CALL TO ACTION FOR DC JAN 6TH.... 4 states are mobilizing[.]”
      <Citation number={181} />
      Meggs, Rhodes, and others created several different chat groups to coordinate for January 6th.
      <Citation number={182} />
    </Typography>

    <Typography className="indent">
      On December 22nd, Meggs echoed President Trump’s tweet in a Facebook message to someone else:
    </Typography>

    <Blockquote>
      <Typography>
        Trump said It’s gonna be wild!!!!!!! It’s gonna be wild!!!!!!! He wants us to make it WILD that’s what he’s saying. He called us all to the Capitol and wants us to make it wild!!! Sir Yes Sir!!! Gentlemen we are heading to DC pack your shit!!”
        <Citation number={183} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Meggs also wrote that the Oath Keepers would have 50–100 members in Washington, DC on January 6th.
      <Citation number={184} />
    </Typography>

    <Typography className="indent">
      The Oath Keepers hosted periodic group video meetings to discuss plans for January 6th. Richard Dockery, a former Oath Keepers member, testified to the Select Committee about a video call that took place around December 31st, and related specifically to planning for January 6th.
      <Citation number={185} />
      During the call, Oath Keepers’ leadership announced plans to provide security for far-right celebrities like Roger Stone.
      <Citation number={186} />
      If there were any problems while they were providing security, “there was a quick reaction force in Virginia that would come help them out ... and that they would have firearms.”
      <Citation number={187} />
    </Typography>

    <Typography className="indent">
      Rhodes announced during an episode of <i>InfoWars</i> in November 2020 that the Oath Keepers had established a “Quick Reaction Force” (QRF) outside of Washington, DC.
      <Citation number={188} />
      After President Trump announced the “wild” protest, the group’s advanced coordination largely focused on planning related to their QRF, as well as the various security details for VIPs and stage areas on January 5th and 6th.
      <Citation number={189} />
      Oath Keepers from North Carolina, Florida, South Carolina, and Arizona converged on the Comfort Inn in Ballston, Virginia, and used the location to store their cache of weapons for January 6th.
      <Citation number={190} />
      Oath Keepers leaders communicated actively about the QRF for January 6th.
      <Citation number={191} />
      Rhodes and another contingent of Oath Keepers stayed at the Hilton Garden Inn in Vienna, Virginia, and stored weapons there as well.
      <Citation number={192} />
    </Typography>

    <Typography className="indent">
      Rhodes amassed an arsenal of military-grade assault weapons and equipment in the days leading up to January 6th. On December 30th, Rhodes spent approximately $7,000 on two night-vision devices and a weapon sight and shipped them to Marsha Lessard, a rally organizer who lived near Washington, DC and who had previously been in contact with the organizers of the Ellipse rally.
      <Citation number={193} />
      On January 1st and 2nd, Rhodes purchased additional weapons and accessories at a cost of approximately $5,000.
      <Citation number={194} />
      The following day, January 3rd, Rhodes and Kellye SoRelle departed Texas for Washington, DC. While traveling, Rhodes spent an additional $6,000 on an AR-style rifle and firearms attachments.
      <Citation number={195} />
      Making one final shopping trip in Mississippi, Rhodes purchased $4,500 of firearms equipment including more sights, magazines, and weapons parts on January 4th.
      <Citation number={196} />
    </Typography>

    <Typography className="indent">
      On the morning of January 6th, with weapons stockpiled, Rhodes messaged the Signal group of Oath Keepers leaders:
    </Typography>

    <Blockquote>
      <Typography>
        We have several well equipped [<i>sic</i>] QRFs outside DC. And there are many, many others, from other groups, who will be watching and waiting on the outside in case of worst case [<i>sic</i>] scenarios.
        <Citation number={197} />
      </Typography>
    </Blockquote>
  </Page>
)
