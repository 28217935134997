import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet8'));

export const Chapter69 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Shortly after the January 6th attack, a video of a bearded man in a “Q” shirt chasing U.S. Capitol Police Officer Ryan Goodman through the halls of the U.S. Capitol went viral.
      <Citation number={313} />
      That man was Doug Jensen, a QAnon believer.
      <Citation number={314} />
      After Jensen’s arrest, FBI agents asked him why he traveled from Iowa to Washington, DC in the first place. “Trump posted make sure you're there, January 6 for the rally in Washington, D.C.,” Jensen responded.
      <Citation number={315} />
    </Typography>

    <Typography className="indent">
      Jensen was not the only QAnon believer to attack the U.S. Capitol on January 6th. The letter “Q” and related slogans, such as “Where We Go One, We Go All,” were ubiquitous among the rioters. They were visible on shirts, signs, and flags throughout the crowd. What was once a marginal digital movement had become a bricks-and-mortar force powerful enough to help obstruct a joint session of Congress.
    </Typography>

    <Typography className="indent">
      QAnon is a bizarre and dangerous cult that gained popularity in 2017, when a person known only as “Q” began posting on 4chan, an anonymous message board.
      <Citation number={316} />
      The poster supposedly held a “Q” security clearance at the Department of Energy. QAnon adherents believe that President Trump is a messianic figure battling the forces of the “deep state” and a Satanic pedophile ring operated by leading Democrats and the American elite.
      <Citation number={317} />
      Q’s first post in October 2017 predicted that former Secretary of State Hillary Clinton would be arrested in short order.
      <Citation number={318} />
      Although that prophecy did not come to pass, the conspiracy theory evolved and grew over time, spreading across social media platforms and eventually finding a home in 8kun, another anonymous message board known for trafficking in conspiracy theories and hate.
      <Citation number={319} />
    </Typography>

    <Typography className="indent">
      President Trump was given multiple opportunities to disavow QAnon. Instead, he essentially endorsed its core tenets. During an August 19, 2020, press briefing, President Trump was asked what he thought about the QAnon belief that he was fighting a Satanic cabal. “I mean, you know, if I can help save the world from problems, I’m willing to do it. I’m willing to put myself out there,” he replied.
      <Citation number={320} />
      During a townhall on NBC News two weeks prior to the election, President Trump first claimed he “knew nothing” about QAnon, but he then praised its believers for being “very strongly against pedophilia.” The President emphasized: “And I agree with that. I mean, I do agree with that.”
      <Citation number={321} />
    </Typography>

    <Typography className="indent">
      In 2020, QAnon played a significant role in spreading various election conspiracy theories. After the election, QAnon accounts amplified the claim that Dominion Voting System’s software had altered votes.
      <Citation number={322} />
      On November 19th, President Trump tweeted and retweeted a link to a segment on One America News Network (OAN) that was captioned, “Dominion-izing the Vote.”
      <Citation number={323} />
      The segment claimed that Dominion had switched votes from President Trump to former Vice President Biden. OAN featured a supposed cyber expert, Ron Watkins, a key figure in the QAnon conspiracy movement.
      <Citation number={324} />
      Watkins’s father, Jim, owned the 8kun site that “Q” called home, and Ron helped oversee its message boards.
      <Citation number={325} />
    </Typography>

    <Typography className="indent">
      After promoting the OAN segment, President Trump retweeted Ron Watkins’s account on several other occasions. On December 15, 2020, President Trump retweeted a post in which Watkins spread false claims of foreign influence in the election.
      <Citation number={326} />
      Then, on January 3rd, President Trump retweeted Ron Watkins’s account four more times.
      <Citation number={327} />
    </Typography>

    <Typography className="indent">
      QAnon’s adherents were clearly paying attention to President Trump’s words—and tweets. The President’s “be wild” tweet was widely heard as a clarion call. Jim Watkins told the Select Committee that “thousands and thousands of people probably” agreed that the President’s December 19th tweet was a call for them to come to Washington, DC.
      <Citation number={328} />
      Jim Watkins himself marched in Washington, DC on January 6th because of the President’s call, but he has not been charged with any crime.
      <Citation number={329} />
    </Typography>

    <Typography className="indent">
      Other QAnon adherents flocked to Washington, DC in response to the President’s call to action. “POTUS HAS REQUESTED YOUR ATTENDANCE Washington DC JANUARY 6TH 2021,” Thomas Munn, a QAnon believer, posted on Facebook. Munn added: “Our President has only asked two things from us, so far...#1 Vote #2 January 6, 2021.”
      <Citation number={330} />
      Jacob Chansley, better known as the QAnon Shaman, told the FBI that he traveled from Arizona because President Trump had requested that all “patriots” come to Washington, DC on January 6th.
      <Citation number={331} />
    </Typography>

    <Typography className="indent">
      During the investigation, the Select Committee learned that the QAnon conspiracy theory often overlaps with other extremist beliefs. Stewart Rhodes of the Oath Keepers testified to the Select Committee that he’s “not a Q-tard” and “not a follower of Q at all.”
      <Citation number={332} />
      However, Rhodes cynically exploited QAnon for his own purposes. The Oath Keepers’ website and text messages were littered with QAnon phrases.
      <Citation number={333} />
      Nick Quested, a filmmaker who shadowed the Proud Boys, often heard QAnon themes in the Proud Boys’ private discussions.
      <Citation number={334} />
    </Typography>

    <Typography className="indent">
      As January 6th drew closer, multiple posts on the QAnon-linked website 8kun indicated that violence was imminent. “You can go to Washington on Jan 6 and help storm the Capitol,” one user wrote. This same user continued: “As many Patriots as can be. We will storm the government buildings, kill cops, kill security guards, kill federal employees and agents, and demand a recount.”
      <Citation number={335} /> Other posts on 8kun debated the politicians that users should target once they got inside the Capitol.
      <Citation number={336} />
    </Typography>

    <Typography className="indent">
      A QAnon-inspired banner was also widely shared by groups planning events for January 5th and 6th. The top of the image read: “Operation Occupy the Capitol.” The central image showed the U.S. Capitol being torn in two. In the lower left corner, there appeared a QAnon phrase: “#WeAreTheStorm.”
      <Citation number={337} />
    </Typography>
  </Page>
)
