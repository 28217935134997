import { useEffect } from "react";

export const useFbAsync = (ref: any) => {
  useEffect(() => {
    const observer = new MutationObserver(function(mutations) {
      if (document.contains(ref.current)) {
        window.fbAsyncInit();
        observer.disconnect();
      }
    });

    observer.observe(document, {attributes: false, childList: true, characterData: false, subtree:true});

    return () => observer?.disconnect();

  }, [ref]);
}
