import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet10'));

export const Chapter89 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Shortly after law enforcement officers evacuated the House and Senate Members, they started to clear rioters out of the Capitol and off the grounds. Starting before 3:00 p.m., law enforcement spent approximately three hours pushing rioters out of the Capitol building and off the East and West Plazas. In general, law enforcement cleared rioters out of the Capitol through three doors: (1) the House side door located on the northeast side of the Capitol; (2) the Columbus Doors (East Rotunda Doors); and (3) the Senate wing door, which was next to the first breach point. As discussed above, the Proud Boys and other extremists led the charge at the latter two locations during the early stages of the attack.
    </Typography>

    <Typography className="indent">
      Outside the Capitol, law enforcement pushed the mob from the upper West Plaza towards the East Plaza, crossing the north doors. Eventually, these rioters were forced to exit the Capitol grounds on the east side. The last point where rioters were removed was the lower West Plaza—the scene of some of the most intense hand-to-hand fighting that day. After law enforcement cleared the tunnel, where violence had raged for hours, police officers corralled rioters to the west and away from the Capitol building.
      <Citation number={311} />
    </Typography>

    <Typography className="indent">
      After rioters first breached the Senate wing door on the first floor, they immediately moved south towards the House Chamber. This route took them to the Crypt—with the mob filling this room by 2:24 p.m. This was also one of the first rooms that law enforcement cleared as they started to secure the building. By 2:49 p.m., law enforcement officers cleared the Crypt by pushing towards the Senate wing door and up the stairs to the Rotunda.
      <Citation number={312} />
    </Typography>

    <Typography className="indent">
      Around the same time that police officers cleared the Crypt, they also removed rioters from hallways immediately adjacent to the House and Senate Chambers. On the House side, rioters were pushed out shortly before 3:00 p.m. The House hallway immediately in front of the House Chamber’s door was cleared at 2:56 p.m.
      <Citation number={313} />
      The mob outside of the Speaker’s lobby was pushed out of the House side door at 2:57 p.m.
      <Citation number={314} />
    </Typography>

    <Typography className="indent">
      USCP officers were able to quickly clear out the Senate Chamber, which was initially breached at 2:42 p.m.
      <Citation number={315} />
      Rioters were cleared from the hallways outside the Senate by 3:09 p.m.
      <Citation number={316} />
      Surveillance shows officers checking the Senate Gallery and hallways for rioters; there are no people on camera by this time.
      <Citation number={317} />
    </Typography>

    <Typography className="indent">
      The Rotunda served as a key point where the mob settled during the Capitol attack. For example, at 2:45 p.m., hundreds of people can be seen standing in the Rotunda.
      <Citation number={318} />
      It appears law enforcement officers funneled rioters from other parts of the Capitol into the Rotunda. Once they had President Trump’s supporters herded there, law enforcement started to push them towards the east doors shortly after 3:00 p.m. At 3:25 p.m., law enforcement successfully pushed rioters out of the Rotunda and closed the doors so that the room could remain secure.
      <Citation number={319} />
      By 3:43 p.m., just 18 minutes after the Rotunda doors were closed, law enforcement successfully pushed the rioters out of the east doors of the Capitol.
      <Citation number={320} />
    </Typography>

    <Typography className="indent">
      The last rioters in the Capitol building were cleared out of the Senate wing door—the same location where rioters first breached the building at 2:13 p.m. Like the other locations inside the Capitol, law enforcement began forcing rioters out of the Senate wing door after 3:00 p.m. By 3:40 p.m., law enforcement had successfully pushed many of the rioters out of the door and onto the upper West Plaza.
      <Citation number={321} />
      However, officers were unable to close the doors because some rioters remained in the doorway and attempted to re-enter the building. At 4:23 p.m., a combination of USCP and MPD officers forced these people out of the doorway and successfully secured the door.
      <Citation number={322} />
    </Typography>

    <Typography className="indent">
      After clearing the inside of the Capitol, law enforcement officers proceeded to sweep the perimeter adjacent to the building, starting with the upper West Plaza. After pushing the last rioter out of the Senate wing door, officers started to clear the upper West Plaza, which is located just outside this same doorway. Law enforcement officers in riot gear formed a line and marshalled the crowd north from the upper West Plaza. By 4:31 p.m., 8 minutes after closing the Senate wing door, rioters were cleared from the upper West Plaza.
      <Citation number={323} />
    </Typography>

    <Image
      src="/images/image-113.jpg"
      alt="Police officers form line to push rioters away from the Capitol building."
      caption="Police officers form line to push rioters away from the Capitol building."
      credit="(Photo by Spencer Platt/Getty Images)"
    />

    <Typography className="indent">
      Many of these same officers started to secure the north side of the Capitol as they pushed rioters from the upper West Plaza towards the East Plaza. By approximately 4:32 p.m., law enforcement officers walked out of the North Doors, forming additional lines to push rioters eastward. As discussed earlier, the North Doors had been the location of violent fighting throughout much of the afternoon. By 4:46 p.m., law enforcement had successfully pushed the rioters from the north side of the Capitol to the East Plaza.
      <Citation number={324} />
    </Typography>

    <Typography className="indent">
      Law enforcement cleared the East Plaza next. By 4:59 p.m., officers had swept all the remaining rioters from the east stairs of the Capitol.
      <Citation number={325} />
      At this point, the mob that had overrun the upper West Plaza, the north side of the Capitol, and the East Plaza had been moved off the grounds adjacent to the Capitol.
    </Typography>

    <Typography className="indent">
      The last areas of the Capitol grounds to get cleared were the tunnel and the lower West Plaza. Thousands of rioters had packed into the West Plaza just after the initial invasion, led by the Proud Boys and their associates. The tunnel was the location of the day’s most violent fighting and the conflict extended until late in the day.
    </Typography>

    <Typography className="indent">
      After 5:00 p.m., it appears that law enforcement directed their attention to clearing the lower West Plaza, including the tunnel. At 5:04 p.m., police officers in the tunnel shot smoke bombs to get the remaining rioters to back away from the doors.
      <Citation number={326} />
      By 5:05 p.m., the rioters had all retreated and the police officers inside the tunnel moved out and started clearing out the area.
      <Citation number={327} />
    </Typography>

    <Image
      src="/images/image-114.jpg"
      alt="Vice President Pence and Speaker Pelosi preside over the joint session of Congress."
      caption="Vice President Pence and Speaker Pelosi preside over the joint session of Congress."
      credit="(Photo by Erin Schaff—Pool/Getty Images)"
    />

    <Typography className="indent">
      At 5:13 p.m., on the opposite side of the lower West Plaza, officers pushed the mob down the scaffold stairs and to the lower West Plaza.
      <Citation number={328} />
      These are the same stairs that rioters, led by the Proud Boys and other extremists, had previously climbed before reaching the Senate wing door.
    </Typography>

    <Typography className="indent">
      Once the rioters from the tunnel and the scaffold were all situated on the lower West Plaza, officers formed another line and started walking the mob back towards the grass—which was away from the actual Capitol building. The line appears to have been fully formed at 5:19 p.m., and the officers started their sweep at 5:30 p.m.
      <Citation number={329} />
      By 5:37 p.m., police officers pushed rioters back to the grassy area away from the Capitol. It was at this time that in or around the Capitol building.
      <Citation number={330} />
      At 6:56 p.m., a little more than an hour after the Capitol grounds were cleared, Vice President Pence returned to the Capitol from the loading dock.
      <Citation number={331} />
      Vice President Pence walked up the stairs in the basement of the Capitol to his office in the Senate at 7:00 p.m.
      <Citation number={332} />
    </Typography>

    <Typography className="indent">
      Shortly after 8:00 p.m., the joint session of Congress resumed, with Vice President Pence saying: “Let’s get back to work.”
      <Citation number={333} />
      At 3:32 a.m., the Congress completed the counting of the votes and certified the election of Joseph R. Biden, Jr. as the 46th President of the United States.
    </Typography>
  </Page>
)
