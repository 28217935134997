import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet3'));

export const Chapter10 = ({ ...args }: any) => (
  <Page {...args}>
    <Image
      src="/images/image-024.jpg"
      alt="President Trump"
      credit="(Photo by Chip Somodevilla/Getty Images)"
      style={{ marginTop: 0 }}
    />

    <Typography className="indent">
      Late on election night 2020, President Donald J. Trump addressed the nation from the East Room of the White House. When Trump spoke, at 2:21 a.m. on November 4th, the President’s re-election was very much in doubt. Fox News, a conservative media outlet, had correctly called Arizona for former Vice President Joseph R. Biden. Every Republican presidential candidate since 1996 had won Arizona. If the President lost the State, and in the days ahead it became clear that he had, then his campaign was in trouble. But as the votes continued to be counted, President Trump’s apparent early lead in other key States—States he needed to win—steadily shrank. Soon, he would not be in the lead at all—he’d be losing.
     </Typography>

    <Typography className="indent">
      So, the President of the United States did something he had planned to do long before election day: he lied.
     </Typography>

    <Typography className="indent">
      “This is a fraud on the American public. This is an embarrassment to our country,” President Trump said. “We were getting ready to win this election,” the President continued. “Frankly, we did win this election. We did win this election.” Trump claimed, without offering any evidence, that a “major fraud” was occurring “in our nation.”
      <Citation number={1} />
     </Typography>

    <Typography className="indent">
      Neither of President Trump’s claims were true. He had no basis for claiming victory or that fraud was taking place. Millions of votes still had not been counted. The States were simply tabulating the ballots cast by the American people. Trump’s own campaign advisors told him to wait—that it was far too early to declare victory.
     </Typography>

    <Typography className="indent">
      As the evening progressed, President Trump called in his campaign team to discuss the results. Trump Campaign Manager William Stepien and other campaign experts advised him that the results of the election would not be known for some time, and that he could not truthfully declare victory. Stepien was of the view that, because ballots were going to be counted for days, “it was far too early to be making any proclamation [about having won the election].” Stepien told President Trump that his recommendation was to say, “votes are still being counted. It's... too early to call the race.”
      <Citation number={2} />
     </Typography>

    <Typography className="indent">
      Jason Miller, another senior Trump Campaign advisor, told the Select Committee that he argued in conversations with Stepien and others that night against declaring victory at the time as well, because “it was too early to say one way [or] the other” who had won. Miller recalled recommending that “we should not go and declare victory until we had a better sense of the numbers.”
      <Citation number={3} />
     </Typography>

    <Typography className="indent">
      According to testimony received by the Committee, the only advisor present who supported President Trump’s inclination to declare victory was Rudy Giuliani, who, according to Miller, was “definitely intoxicated” that evening.
      <Citation number={4} />
     </Typography>

    <Typography className="indent">
      President Trump’s decision to declare victory falsely on election night and, unlawfully, to call for the vote counting to stop, was not a spontaneous decision. It was premeditated. The Committee has assembled a range of evidence of President Trump’s preplanning for a false declaration of victory. This includes multiple written communications on October 31st and November 3, 2020, to the White House by Judicial Watch President Tom Fitton.
      <Citation number={5} />
      This evidence demonstrates that Fitton was in direct contact with President Trump and understood that he would falsely declare victory on election night and call for vote counting to stop. The evidence also includes an audio recording of President Trump’s advisor Steve Bannon, who said this on October 31, 2020, to a group of his associates from China:
     </Typography>

    <Blockquote>
      <Typography>
        And what Trump’s going to do is just declare victory, right? He’s gonna declare victory. But that doesn’t mean he’s the winner. He’s just gonna say he’s a winner.... The Democrats, more of our people vote early that count. Their vote in mail. And so they’re gonna have a natural disadvantage, and Trump’s going to take advantage of it. That’s our strategy. He’s gonna declare himself a winner. So when you wake up Wednesday morning, it’s going to be a firestorm.... Also, if Trump, if Trump is losing, by ten or eleven o’clock at night, it’s going to be even crazier. No, because he’s gonna sit right there and say ‘They stole it. I’m directing the Attorney General to shut down all ballot places in all 50 states. It’s going to be, no, he’s not going out easy. If Trump—if Biden’s winning, Trump is going to do some crazy shit.
      <Citation number={6} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Also in advance of the election, Roger Stone, another outside advisor to President Trump, made this statement:
     </Typography>

    <Blockquote>
      <Typography>
        I really do suspect it will still be up in the air. When that happens, the key thing to do is to claim victory. Possession is 9/10s of the law. No, we won. Fuck you, Sorry. Over. We won. You’re wrong. Fuck you.
      <Citation number={7} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      In the days after the election, the President’s own campaign team told him he had lost and there was no evidence of significant fraud. When his campaign staff wouldn’t tell him what he wanted to hear, President Trump replaced them with what Attorney General William Barr described as a “clown car” of individuals willing to promote various conspiracy theories.
      <Citation number={8} />
     </Typography>

    <Typography className="indent">
      But Donald Trump was no passive consumer of these lies. He actively propagated them. Time and again President Trump was informed that his election fraud claims were not true. He chose to spread them anyway. He did so even after they were legally tested and rejected in dozens of lawsuits. Not even the electoral college’s certification of former Vice President Biden’s victory on December 14, 2020, stopped the President from lying. Throughout, the Big Lie remained central to President Trump’s efforts to block the peaceful transfer of power on January 6, 2021.
     </Typography>
  </Page>
)
