import { createContext, useState } from 'react';

export const PlayerSpeeds = [ 1, 1.25, 1.5, 1.75, 2 ];

export interface GlobalPlayerState {
  show: boolean;
  autoplay: boolean;
  muted: boolean;
  speed: number;
}

const defaultState: GlobalPlayerState = {
  show: false,
  autoplay: false,
  muted: false,
  speed: 0,
}

export const GlobalPlayerStateContext = createContext([defaultState, (() => {}) as any ]);

export const GlobalPlayerStateProvider = ({ children }: any) => {
  const globalPlayerState = useState(defaultState);

  return (
    <GlobalPlayerStateContext.Provider value={globalPlayerState} >
      { children }
    </GlobalPlayerStateContext.Provider>
  )
}