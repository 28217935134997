import { lazy } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../components/Blockquote';
import { Page } from '../../components/Page';

const Citation = lazy(() => import('../Citations/CitationSheet15'));

export const Appendix4 = ({ ...args }: any) => (
  <Page {...args}>
    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h2" color="primary">
        INTRODUCTION
      </Typography>
    </Box>

    <Typography className="indent">
      In the wake of the 2020 U.S. Presidential election, President Donald J. Trump and his apologists attempted to blame his loss on foreign interference. They falsely claimed that foreign-manufactured voting machines had been manipulated so that votes cast for Trump were instead recorded as votes for Joseph R. Biden, Jr.
      <Citation number={1} />
      No one has ever, either at the time or since, offered any evidence to support Trump’s assertion. On the contrary, ample evidence collected by the Intelligence Community (IC) and reviewed by the Select Committee disproves those claims.
    </Typography>

    <Typography className="indent">
      That is not to say foreign actors made no attempt to influence the American political climate during and after the 2020 Presidential election. This appendix evaluates the role foreign influence played in the circumstances surrounding the insurrection.
      <Citation number={2} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h2" color="primary">
        DISCUSSION
      </Typography>
    </Box>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        ELECTION MEDDLING IN 2020:
        <br />
        FOREIGN INTERFERENCE? NO. FOREIGN INFLUENCE? YES.
      </Typography>
    </Box>

    <Typography className="indent">
      In its postmortem assessment of the 2020 U.S. Presidential election, the Intelligence Community comprehensively examined two types of foreign meddling: interference and influence. The distinction between the two is critical in evaluating President Trump’s repeated public assertions that there had been massive and widespread “fraud” that had the effect of “stealing” the election for then-candidate Biden.
    </Typography>

    <Typography className="indent">
      For its analytic purposes, the Intelligence Community defines election <i>interference</i> as “a subset of election influence activities targeted at the technical aspects of the election, including voter registration, casting and counting ballots, or reporting results.”
      <Citation number={3} />
      That definition notes that election interference is a subset of election <i>influence</i>, which the Intelligence Community defines to include “overt and covert efforts by foreign governments or actors acting as agents of, or on behalf of, foreign governments intended to affect directly or indirectly a US election—including candidates, political parties, voters or their preferences, or political processes.”
      <Citation number={4} />
    </Typography>

    <Typography className="indent">
      The Intelligence Community’s Assessment (ICA) found no factual basis for any allegation of technical interference with the 2020 U.S. election: “We have no indications that any foreign actor attempted to interfere in the 2020 US elections by altering any technical aspect of the voting process, including voter registration, ballot casting, vote tabulation, or reporting results.”
      <Citation number={5} />
      Put simply, allegations that foreign powers rigged voting machines and swapped ballots were false and unsupported.
    </Typography>

    <Typography className="indent">
      Although there is no evidence of foreign technical interference in the 2020 election, there is evidence of foreign influence. Specifically, the Intelligence Community’s Assessment concluded that “Russian President Putin authorized, and a range of Russian government organizations conducted, influence operations aimed at denigrating President Biden’s candidacy and the Democratic Party, supporting former President Trump, undermining public confidence in the electoral process, and exacerbating sociopolitical divisions in the US.”
      <Citation number={6} />
      The two Intelligence Community analytic conclusions about the 2020 U.S. Presidential election—that there was evidence of foreign influence, but not foreign interference—are completely consistent.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        MALIGN FOREIGN EFFORTS TO INFLUENCE THE 2020 U.S. ELECTIONS
      </Typography>
    </Box>

    <Typography className="indent">
      The 2020 U.S. elections saw an increase in the number of foreign state and non-state entities that attempted to influence the U.S. electorate. The U.S. Intelligence Community suggests, as a possible explanation, that more such foreign entities “may view influence operations as important tools for projecting power abroad.”
      <Citation number={7} />
      More ascertainably, “[t]he growth of internet and social media use means foreign actors are more able to reach US audiences directly, while the tools for doing so are becoming more accessible.”
      <Citation number={8} />
    </Typography>

    <Typography className="indent">
      The United States’ principal foreign adversaries—Russia, China, and Iran—all of them autocracies, engage, to varying degrees, in disguised efforts to influence U.S. public opinion.
      <Citation number={9} />
      In the context of these overarching efforts,
      <Citation number={10} />
      U.S. elections offer special opportunities.
    </Typography>

    <Typography className="indent">
      For Russia, “[e]lections... often serve as an opportune target. But attacks on elections are typically just one part of ongoing, multi-pronged operations.”
      <Citation number={11} />
      The U.S. Intelligence Community’s definitive post-election assessment of foreign influence activities during the 2020 Presidential election concluded that Russia was deeply engaged in disinformation activities intended to influence the outcome by supporting President Trump while disparaging then-candidate Biden; Iran also engaged in efforts to influence the election’s outcome, but unlike Russia, did not actively promote any candidate; and that China considered opportunities to influence the election’s outcome, but ultimately decided that potential costs outweighed any foreseeable benefits.
      <Citation number={12} />
      Both Russia and Iran worked to undermine the American public’s confidence in U.S. democratic processes and to deepen socio-political divisions in the United States.
      <Citation number={13} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        RUSSIA’S MALIGN INFLUENCE EFFORTS TARGETING THE UNITED STATES
      </Typography>
    </Box>

    <Typography className="indent">
      Russian malign disinformation efforts are both strategic in scope and opportunistic in nature. They aim to corrode the power and appeal of the U.S. democratic processes, worsen U.S. domestic divisions, and weaken America at home and abroad. The Intelligence Community’s February 2022 unclassified “Annual Threat Assessment” puts this sustained Russian threat in a nutshell:
    </Typography>

    <Blockquote>
      <Typography>
        Russia presents one of the most serious foreign influence threats to the United States, using its intelligence services, proxies, and wideranging influence tools to try to divide Western alliances, and increase its sway around the world, while attempting to undermine U.S. global standing, amplify discord inside the United States, and influence U.S. voters and decisionmaking.
        <Citation number={14} />
      </Typography>
    </Blockquote>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        RUSSIAN DISINFORMATION AND THE 2020 PRESIDENTIAL ELECTION
      </Typography>
    </Box>

    <Typography className="indent">
      Foreign adversaries’ influence campaigns routinely push disinformation to U.S. audiences. Elections offer an important forum for Russia and other U.S. adversaries to seek to deepen divisions within American society through disinformation campaigns.
      <Citation number={15} />
      The Intelligence Community projects that both Russia and China will, for the foreseeable future, continue to press their disinformation campaigns attempting to undermine the U.S. population’s confidence in their government and society.
      <Citation number={16} />
      Russia certainly did so in the period following the election and preceding the January 6th attack.
    </Typography>

    <Typography className="indent">
      The disinformation spread by Russia and its messengers during that time was not, however, entirely original. The Intelligence Community Assessment found that Russia’s disinformation engine borrowed President Trump’s own words to achieve its goals:
    </Typography>

    <Blockquote>
      <Typography>
        Russian online influence actors generally promoted former President Trump and his commentary, including repeating his political messaging on the election results; the presidential campaign; debates; the impeachment inquiry; and, as the election neared, US domestic crises.
        <Citation number={17} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Indeed, President Trump’s messaging during and after the 2020 election was reflected in Russian influence efforts at the time. In September of 2020, the Department of Homeland Security’s Office of Intelligence and Analysis warned that Russia was engaged in pre-election activity targeting the U.S. democratic process.
      <Citation number={18} />
      The bulletin advised that “Russia is likely to continue amplifying criticisms of vote-by-mail and shifting voting processes amidst the COVID–19 pandemic to undermine public trust in the electoral process.”
      <Citation number={19} />
    </Typography>

    <Typography className="indent">
      Deliberately spreading disinformation to discredit a U.S. election was not new to Russia’s influence arsenal. In the judgment of the U.S. Intelligence Community, it is a tactic Russia was prepared to deploy after the 2016 U.S. Presidential election:
    </Typography>

    <Blockquote>
      <Typography>
        Even after the [2020] election, Russian online influence actors continued to promote narratives questioning the election results and disparaging President Biden and the Democratic Party. These efforts parallel plans Moscow had in place in 2016 to discredit a potential incoming Clinton administration, but which it scrapped after former President Trump’s victory.
        <Citation number={20} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Russian influence efforts in the 2016 and 2020 elections, while distinct in their particulars, shared some similarities. Historically, Russia has engaged in near-industrial scale online influence efforts.
      <Citation number={21} />
      The Intelligence Community Assessment states that in 2020, Russia again relied on internet trolls to amplify divisive content aimed at American audiences:
    </Typography>

    <Blockquote>
      <Typography>
        The Kremlin-linked influence organization Project Lakhta and its Lakhta Internet Research (LIR) troll farm—commonly referred to by its former moniker Internet Research Agency (IRA)—amplified controversial domestic issues. LIR used social media personas, news websites, and US persons to deliver tailored content to subsets of the US population. LIR established short-lived troll farms that used unwitting third-country nationals in Ghana, Mexico, and Nigeria to propagate these US-focused narratives....
        <Citation number={22} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The threats posed by Russia’s influence efforts are not new, nor are they diminishing. The latest unclassified Intelligence Community Annual Threat Assessment throws this into sharp relief:
    </Typography>

    <Blockquote>
      <Typography>
        Moscow has conducted influence operations against U.S. elections for decades, including as recently as the 2020 presidential election. We assess that it probably will try to strengthen ties to U.S. persons in the media and politics in hopes of developing vectors for future influence operations.
        <Citation number={23} />
      </Typography>
    </Blockquote>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        PROXIES AMONG US: MALIGN FOREIGN INFLUENCE AND U.S. AUDIENCES
      </Typography>
    </Box>

    <Typography className="indent">
      Tech-enabled or not, if ever there was a “people business,” foreign influence is it. People working on behalf of a foreign government—foreign government officials, their agents, and proxies—work to influence, directly or indirectly, a target audience in another country—its officials and citizens at large. Most who are engaged in those efforts act overtly: ambassadors, consuls general, government delegations and so forth. Their foreign influence efforts are not, however, focused on philanthropy or foreign aid. Moreover, the perspectives they seek to embed in their target audiences may be intentionally and materially inaccurate, propagandistic, or driven by unstated motives. In such instances, foreign influence may amount to injecting foreign disinformation into the U.S. media ecosystem for re-branding and onward transmission to an American audience.
      <Citation number={24} />
    </Typography>

    <Typography className="indent">
      Foreign state adversaries of the United States generally disguise their efforts to influence U.S. audiences, particularly when they seek to influence U.S. voters’ views in the run-up to an election. Among the many ways of concealing the foreign-state origin or sponsorship of such a message is to use unattributable proxies—“cut-outs”—or fully independent ideological allies in the United States as messaging organs. A cooperative American messenger—a proxy for the foreign government itself—may be needed to make the foreign-origin message congenial to the target American audience.
    </Typography>

    <Typography className="indent">
      Malign foreign influencers, including foreign governments, used an additional such masking tool during the Trump administration: amplifying U.S.-originated messages so that they reached a broader audience. These influencers often took advantage of the algorithms by which social media platforms bring congenial messages and other information to users whose views are likely to be similar or compatible.
    </Typography>

    <Typography className="indent">
      Shortly after the January 6th attack, the National Intelligence Council summarized the scope and significance of Russia’s use of proxies in the 2020 U.S. Presidential election:
    </Typography>

    <Blockquote>
      <Typography>
        A key element of Moscow’s strategy this election cycle was its use of proxies linked to Russian intelligence to push influence narratives— including misleading or unsubstantiated allegations against President Biden—to US media organizations, US officials, and prominent US individuals, including some close to former President Trump and his administration.
        <Citation number={25} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The success of the proxy depends on shielding its foreign sponsorship. For that reason, it can be difficult or impossible to determine conclusively whether someone parroting a foreign government adversary’s point of view to a U.S. audience is that government’s controlled proxy or a volunteer taking full advantage of U.S. First Amendment freedoms.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        ANTI-U.S. FOREIGN STATE PROPAGANDA AND THE JANUARY 6TH ATTACK
      </Typography>
    </Box>

    <Typography className="indent">
      U.S. adversaries use anti-American propaganda and disinformation to advance their strategic foreign policy objectives. They aim to corrode U.S. influence abroad while diluting U.S. citizens’ trust in their democratic institutions and processes. They hope to deepen and sharpen the sociopolitical divisions in American society.
      <Citation number={26} />
      In doing so, foreign adversaries hope not only to limit U.S. ability to influence the policy choices of other foreign states, but also to help immunize their own populations against the attractions of American-style democracy.
    </Typography>

    <Typography className="indent">
      That matters, as Russia and other adversaries of the United States well know. If the United States has long demonstrated such a globally effective cultural power to attract, its corrosion must be a primary strategic objective of Russia or any other of the United States’ principal adversaries. Accordingly, over the next 20 years, the Intelligence Community expects that “China and Russia probably will try to continue targeting domestic audiences in the United States and Europe, promoting narratives about Western decline and overreach.”
      <Citation number={27} />
      The January 6th attack played into their hands.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        PRESIDENT TRUMP AND THE 2020 ELECTION AS AN OPPORTUNITY FOR FOREIGN INFLUENCE
      </Typography>
    </Box>

    <Typography className="indent">
      With President Trump in the White House, Russia benefited from a powerful American messenger creating and spreading damaging disinformation it could amplify. The Intelligence Community’s comprehensive March 2021 assessment noted that throughout the 2020 Federal election cycle, “Russian online influence actors generally promoted former President Trump and his commentary....”
      <Citation number={28} />
    </Typography>

    <Typography className="indent">
      President Trump’s relentless propagation of the Big Lie damaged American democracy from within and made it more vulnerable to attack from abroad. His actions did not go unnoticed by America’s adversaries, who seized on the opportunity to damage the United States. According to the Intelligence Community’s March 2021 assessment, “[e]ven after the election, Russian online influence actors continued to promote narratives questioning the election results....”
      <Citation number={29} />
      What President Trump was saying was, in sum, exactly what the Russian government wanted said—but he was doing it on his own initiative and from the trappings of the Oval Office.
    </Typography>
  </Page>
)
