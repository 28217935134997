import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet5'));

export const Chapter35 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      On December 14th, using instructions provided by Chesebro, the fake Trump electors gathered and participated in signing ceremonies in all seven States. In five of these States—Arizona, Georgia, Michigan, Nevada, and Wisconsin—the certificates they signed used the language that falsely declared themselves to be “the duly elected and qualified Electors” from their State.
      <Citation number={96} />
      This declaration was false because none of the signatories had been granted that official status by their State government in the form of a certificate of ascertainment.
    </Typography>

    <Image src="/images/image-049.jpg" alt="Arizona elector ballot sheet, real vs fake" />

    <Typography className="indent">
      The paperwork signed by the fake Trump electors in two other States contained partial caveats. In New Mexico, the document they signed made clear that they were participating “on the understanding that it might later be determined that we are the duly elected and qualified Electors....”
      <Citation number={97} />
      In Pennsylvania, the document they signed indicated that they were participating “on the understanding that if, as a result of a final non-appealable Court Order or other proceeding prescribed by law, we are ultimately recognized as being the duly elected and qualified Electors....”
      <Citation number={98} />
    </Typography>

    <Typography className="indent">
      All seven of these invalid sets of electoral votes were then transmitted to Washington, DC. Roman’s team member in Georgia, for example, sent him an email on the afternoon of December 14th that affirmed the following: “All votes cast, paperwork complete, being mailed now. Ran pretty smoothly.”
      <Citation number={99} />
      Likewise, Findlay updated Campaign Manager Bill Stepien and his bosses on the legal team that the Trump team’s slate in Georgia was not able to satisfy all provisions of State law but still “voted as legally as possible under the circumstances” before transmitting their fake votes to Washington, DC, by mail.
      <Citation number={100} />
    </Typography>

    <Typography className="indent">
      On the evening of December 14th, RNC Chairwoman McDaniel provided an update for President Trump on the status of the fake elector effort. She forwarded President Trump’s executive assistant an “Elector Recap” email, which conveyed that “President Trump’s electors voted” not just in “the states that he won” but also in six “contested states” (specifically, Arizona, Georgia, Michigan, Nevada, Pennsylvania, and Wisconsin).
      <Citation number={101} />
      Minutes later, President Trump’s executive assistant replied: “It’s in front of him!”
      <Citation number={102} />
    </Typography>

    <Typography className="indent">
      The Trump team and the fake electors also engaged in acts of subterfuge to carry out their plans on December 14th. For instance, a campaign staffer notified the Georgia participants via email that he “must ask for your complete discretion.”
      <Citation number={103} />
      He explained that their efforts required “complete secrecy,” and told them to arrive at the State capitol building and “please state to the guards that you are attending a meeting with either Senator Brandon Beach or Senator Burt Jones.”
      <Citation number={104} />
      Indeed, Greg Bluestein of the <i>Atlanta Journal-Constitution</i> reported that he tried to enter this group’s meeting room but “[a] guy at the door called it an ‘education meeting’ and scrambled when I tried to walk in.”
      <Citation number={105} />
    </Typography>

    <Typography className="indent">
      Former Michigan GOP Chair Laura Cox told the Select Committee that an attorney who “said he was working with the President’s Campaign” informed her that the Michigan slate for President Trump was “planning to meet in the capit[o]l and hide overnight so that they could fulfill the role of casting their vote in, per law, in the Michigan chambers.”
      <Citation number={106} />
      She said that she “told him in no uncertain terms that that was insane and inappropriate,” and that she warned Michigan’s senate majority leader as a precaution.
      <Citation number={107} />
      Instead, the group of fake electors in Michigan signed their paperwork in the State GOP headquarters, where staff told them not to bring phones inside.
      <Citation number={108} />
    </Typography>
  </Page>
)
