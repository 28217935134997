export const components = {
  MuiCssBaseline: {
    styleOverrides: {
      '@global': {
        '@font-face': [],
      },
    },
  },

  MuiTypography: {
    styleOverrides: {
      root: {
        'p&': {
          marginBottom: '1em',
        },

        '&.indent': {
          textIndent: '2.5em',
        },


      },
      text: {
        'button&': {
          border: 0,
          padding: 0,
          background: 'transparent',
          cursor: 'pointer',
        },
      },
      'subtitle2': {
        '.MuiTooltip-tooltip &': {
          margin: '16px 0'
        }
      }
    }
  }
};
