import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet9'));

export const Chapter710 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      The President’s tweets were not tamping down on the violence, and White House staff knew it.
      <Citation number={247} />
      By 3:17 p.m., Fox News was reporting gunshots on Capitol Hill. Law enforcement officers could be seen in the House chamber, pointing guns over the barricaded door: The chyron blared “Guns Drawn on House Floor.”
      <Citation number={248} />
      Between 3:29 p.m. and 3:42 p.m., the network was flashing images of a protestor in the presiding officer’s chair, right where Vice
    </Typography>

    <Typography className="indent">
      President Pence had been sitting 90 minutes earlier.
      <Citation number={249} />
      Other images showed Members of Congress trapped in the House gallery, crouching below the balcony for cover.
      <Citation number={250} />
    </Typography>

    <Typography className="indent">
      Allies continued to text Meadows, begging the President to order the mob to go home and indicating that it was time the American people hear from the President directly:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Unknown, 3:04 p.m.</strong>: “Are you with potus right now? Hearing he is in the dining room watching this on TV...”
      </Typography>
      <Typography>
        “Is he going to say anything to de-escalate apart from that Tweet?”
        <Citation number={251} />
      </Typography>
    </Blockquote>

    <Blockquote>
      <Typography>
        <strong>Reince Priebus, 3:09 p.m.</strong>: “TELL THEM TO GO HOME !!!”
        <Citation number={252} />
      </Typography>
    </Blockquote>

    <Blockquote>
      <Typography>
        <strong>Unknown, 3:13 p.m.</strong>: “POTUS should go on air and defuse this. Extremely important.”
        <Citation number={253} />
      </Typography>
    </Blockquote>

    <Blockquote>
      <Typography>
        <strong>Alyssa Farah, 3:13 p.m.</strong>: “Potus has to come out firmly and tell protestors to dissipate. Someone is going to get killed...”
        <Citation number={254} />
      </Typography>
    </Blockquote>

    <Blockquote>
      <Typography>
        <strong>Representative Chip Roy, 3:25 p.m.</strong>: “Fix this now.”
        <Citation number={255} />
      </Typography>
      <Typography>
        <strong>Meadows responded</strong>: “We are.”
        <Citation number={256} />
      </Typography>
    </Blockquote>

    <Blockquote>
      <Typography>
        <strong>Sean Hannity (Fox News), 3:31 p.m.</strong>: “Can he make a statement. I saw the tweet. Ask people to peacefully leave the capital [sic].”
        <Citation number={257} />
      </Typography>
      <Typography>
        <strong>Meadows responded</strong>: “On it.”
        <Citation number={258} />
      </Typography>
    </Blockquote>

    <Blockquote>
      <Typography>
        <strong>Katrina Pierson, 3:40 p.m.</strong>: “Note: I was able to keep the crazies off the stage. I stripped all branding of those nutty groups and removed videos of all of the psychos. Glad it [sic] fought it.”
        <Citation number={259} />
      </Typography>
    </Blockquote>

    <Blockquote>
      <Typography>
        <strong>Unknown, 3:42 p.m.</strong>: “Pls have POTUS call this off at the Capitol. Urge rioters to disperse. I pray to you.”
        <Citation number={260} />
      </Typography>
    </Blockquote>

    <Blockquote>
      <Typography>
        <strong>Unknown, 3:57 p.m.</strong>: “Is he coming out?”
      </Typography>
      <Typography>
        “He has to right?”
        <Citation number={261} />
      </Typography>
    </Blockquote>

    <Blockquote>
      <Typography>
        <strong>Brian Kilmeade, 3:58 p.m. (Fox News)</strong>: “Please get him on tv. Destroying every thing you guys have accomplished.”
        <Citation number={262} />
      </Typography>
    </Blockquote>

    <Blockquote>
      <Typography>
        <strong>Donald Trump, Jr., 4:05 p.m.</strong>: “We need an oval address. He has to lead now. It’s gone too far and gotten out of hand.”
        <Citation number={263} />
      </Typography>
    </Blockquote>

    <Image src="/images/image-099.jpg" alt="President Trump, standing at microphone" />

    <Typography className="indent">
      At any moment in the afternoon, it would have been easy for President Trump to get before cameras and call off the attack. The White House Press Briefing Room is just down the hallway from the Oval Office, past the Cabinet Room and around the corner to the right. It would have taken less than 60 seconds for the President to get there.
      <Citation number={264} />
      The space, moreover, is outfitted with cameras that are constantly “hot,” meaning that they are on and ready to go live at a moment’s notice.
      <Citation number={265} />
      The White House press corps is also situated in the West Wing, right by the briefing room.
      <Citation number={266} />
      The whole affair could have been assembled in minutes.
      <Citation number={267} />
    </Typography>

    <Typography className="indent">
      However, it was not until nearly 3 hours after the violence began that President Trump finally agreed to tell the mob to go home.
      <Citation number={268} />
    </Typography>

    <Typography className="indent">
      The Presidential Daily Diary notes that President Trump left the dining room to shoot the video at 4:03 p.m.
      <Citation number={269} />
      By this point—per Fox News coverage playing continually in the dining room—more law enforcement officers had arrived at the Capitol to resist the violent mob.
      <Citation number={270} />
    </Typography>

    <Typography className="indent">
      The video shoot took place in the Rose Garden, the outdoor space that borders the Oval Office and the West Wing.
      <Citation number={271} />
      The setup was not ornate, just a camera and a microphone. Luna made sure that the background and lighting looked good, and that President Trump’s hair and tie were in place.
      <Citation number={272} />
      President Trump delivered his remarks in one take, more or less, although he stopped and restarted at one point.
      <Citation number={273} />
      In all, the video took less than 4 minutes to shoot, and the President was back in the dining room by 4:07 p.m.
      <Citation number={274} />
    </Typography>

    <Typography className="indent">
      “I would stick to this script... ,” McEnany told President Trump before he stepped out to film.
      <Citation number={275} />
    </Typography>

    <Typography className="indent">
      He didn’t.
    </Typography>

    <Typography className="indent">
      Kushner and others had drafted a statement, but President Trump spoke entirely off the cuff.
      <Citation number={276} />
      Here’s what he said:
    </Typography>

    <Blockquote>
      <Typography>
        I know your pain. I know you’re hurt. We had an election that was stolen from us. It was a landslide election and everyone knows it, especially the other side. But you have to go home now. We have to have peace. We have to have law and order. We have to respect our great people in law and order. We don’t want anybody hurt. It’s a very tough period of time. There’s never been a time like this where such a thing happened where they could take it away from all of us, from me, from you, from our country. This was a fraudulent election. But we can’t play into the hands of these people. We have to have peace. So go home, we love you. You’re very special. You’ve seen what happens. You see the way others are treated that are so bad and so evil. I know how you feel, but go home and go home in peace.
        <Citation number={277} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      A photo obtained from the National Archives shows President Trump and Herschmann huddled next to each other, watching a completed take through the monitor on the video camera.
      <Citation number={278} />
    </Typography>

    <Typography className="indent">
      “There needs to be a more direct statement” telling the rioters to leave the Capitol, Luna heard Herschmann—yet again—tell the President.
      <Citation number={279} />
      Herschmann testified that he did not recall this exchange.
      <Citation number={280} />
    </Typography>

    <Typography className="indent">
      But according to Luna, President Trump rejected the note.
    </Typography>

    <Typography className="indent">
      “These people are in pain,” he said in reply.
      <Citation number={281} />
    </Typography>

    <Typography className="indent">
      Down at the Capitol, the video began streaming onto rioters’ phones, and by all accounts including video footage taken by other rioters, they listened to President Trump’s command.
    </Typography>

    <Typography className="indent">
      “Donald Trump has asked everybody to go home,” one rioter shouted as he “deliver[ed] the President’s message.” “That’s our order,” another rioter responded. Others watching the video responded: “He says, go home.”
      <Citation number={282} />
    </Typography>

    <Typography className="indent">
      The crowd afterward began to disperse.
      <Citation number={283} />
      The video made clear what had been evident to many, including those closest to him: The President could have called off the rioters far earlier and at any point that day.
      <Citation number={284} />
      But he chose not to do so.
      <Citation number={285} />
    </Typography>

    <Typography className="indent">
      It was not until it was obvious that the riot would fail to stop the certification of the vote that the President finally relented and released a video statement made public at 4:17 p.m.
      <Citation number={286} />
    </Typography>

    <Image
      src="/images/image-100.jpg"
      alt="President Trump huddles with aides, watching a completed take of a video through the monitor of the video camera."
      caption="President Trump huddles with aides, watching a completed take of a video through the monitor of the video camera."
      credit="(Photo provided to the Select Committee by the National Archives and Records Administration)"
    />
  </Page>
)
