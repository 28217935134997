import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { styled, useTheme } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => `
  .MuiListSubheader-root {
    line-height: 2.5;
    margin-bottom: ${theme.spacing(1)}
  }

  .MuiListItem-root {
    padding-top: 0;
  }

  .MuiListItemText-root {
    margin-bottom: 0;
  }
`);

export const ReportDialog = ({ open, close }: any) => {
  const theme = useTheme();

  return (
    <StyledDialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { width: '100%', maxWidth: '600px', margin: theme.spacing(2) }
      }}
    >
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', padding: theme.spacing(2) }}>
        <span>Report an Issue</span>

        <IconButton size="small" onClick={close}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>

      <DialogContent style={{ padding: `0 ${theme.spacing(2)}` }}>
        <List dense>
          <ListSubheader sx={(theme: any) => ({
            fontWeight: 'bold',
            background: theme.palette.mode !== 'dark' ? theme.palette.grey[200] : '',
          })}>
            Please report:
          </ListSubheader>
          <ListItem disableGutters>
            <ListItemText primaryTypographyProps={{ style: { fontWeight: 'bold' } }}>
              Application Bugs, Audio Glitches, Spelling Errors, Transcription Errors, etc.
            </ListItemText>
          </ListItem>
        </List>
        <List dense>
          <ListSubheader sx={(theme: any) => ({
            background: theme.palette.mode !== 'dark' ? theme.palette.grey[200] : '',
          })}>
            Please <strong>DO NOT</strong> report:
          </ListSubheader>
          <ListItem disableGutters>
            <ListItemText>
              <strong>Audio Mispronunciations</strong>
              <List dense>
                <ListItem>
                  <ListItemText>The audio was produced via AWS Polly, an AI-powered neural voice synthesizer.</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>It's okay, but will stumble over bracketed text, abbreviations, acronyms, etc.</ListItemText>
                </ListItem>
              </List>
            </ListItemText>
          </ListItem>
          <ListItem disableGutters>
            <ListItemText>
              <strong>Errors in Source Material</strong>
              <List dense>
                <ListItem>
                  <ListItemText>This is a good-faith presentation of the report, as is.</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Minor editorial changes have been made for spelling or readability only.</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Any grammatical or factual errors in the report will not be addressed here.</ListItemText>
                </ListItem>
              </List>
            </ListItemText>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          href="https://m.me/j6.report"
          target="_blank"
          rel="noopener noreferrer"
          variant="contained"
          onClick={close}
          color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}
          sx={(theme: any) => ({ margin: `0 ${theme.spacing(1)} ${theme.spacing(1)}` })}
        >
          Report
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
