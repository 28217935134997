import { styled } from '@mui/material/styles';

const StyledBlockquote = styled('blockquote')(({ theme }) => `
  width: 100%;
  text-align: left;
  border-left: 5px solid;
  margin: ${theme.spacing(4)} auto;
  padding: 0 ${theme.spacing(2)};
  color: ${
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.primary.main
  };
  font-weight: bold;
  font-size: 1em;
  line-height: 2em;
  font-style: italic;

  p, span {
    font-size: inherit;
    line-height: inherit;
  }

  .MuiTooltip-tooltip & {
    max-width: 100%;
    color: inherit;
  }

  @media (min-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    width: 80%;
  }

  @media (min-width: ${theme.breakpoints.values.lg}${theme.breakpoints.unit}) {
    width: 60%;
  }
`);

export const Blockquote = ({children, style, className}: any) => (
  <StyledBlockquote style={style} className={className}>
    {children}
  </StyledBlockquote>
);
