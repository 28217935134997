import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet8'));

export const Chapter65 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Stewart Rhodes’s and Oath Keepers’ lawyer Kellye SoRelle arrived in Washington on the afternoon of January 5th.
      <Citation number={198} />
      They immediately went to Freedom Plaza, where President Trump had instructed rally organizers to give some of his most extreme supporters time to speak.
      <Citation number={199} />
      As a small group of Oath Keepers patrolled Freedom Plaza, they were able to see the results of President Trump’s call to mobilize.
      <Citation number={200} />
      SoRelle testified that there were Oath Keepers, Proud Boys, and “Alex Jones people” mingling together in the crowd, with “just a small distinction between them.”
      <Citation number={201} />
    </Typography>

    <Typography className="indent">
      The Oath Keepers later found themselves at the Phoenix Park Hotel,
      <Citation number={202} />
      where they ate and drank with a motley coalition of far-right political activists who were united in their shared belief in President Trump’s Big Lie.
      <Citation number={203} />
      Among them were: Proud Boys-linked Bianca Gracia of Latinos for Trump; Joshua Macias, leader of Vets for Trump;
      <Citation number={204} />
      and Amanda Chase, a Virginia State senator.
      <Citation number={205} />
      In a livestream discussion moderated by Chase, they promoted false election fraud claims. Macias and Rhodes encouraged President Trump to invoke the Insurrection Act and call up combat veterans who are “ready to step in and do what is needed.”
      <Citation number={206} />
    </Typography>

    <Typography className="indent">
      SoRelle later told the Select Committee that there was discussion of going to “storm the Capitol,” although she claimed that this was “normal” discussion and supposedly did not indicate violence or “any of that type of stuff.”
      <Citation number={207} />
    </Typography>

    <Typography className="indent">
      That same evening, Gracia asked SoRelle and Rhodes to follow her to a garage where she was supposed to meet Proud Boys leader Enrique Tarrio,
      <Citation number={208} />
      who had just been released from custody and ordered to leave the DC area.
      <Citation number={209} />
      Instead of immediately leaving Washington, DC, Tarrio instead made his way to a garage near the hotel where the others gathered.
      <Citation number={210} />
      Portions of the ensuing meeting were captured on video by documentary filmmaker Nick Quested and his camera crew. SoRelle claims that she was asked to attend to discuss Tarrio’s legal woes,
      <Citation number={211} />
      but there is evidence indicating that the conversation turned tactical.
    </Typography>

    <Typography className="indent">
      Tarrio discussed the court’s order, informing the group he was going north to Maryland, so he could “stay close just to make sure my guys are ok.”
      <Citation number={212} />
      Tarrio discussed his confiscated phone with Gracia. He told her that “they couldn’t get in there,” apparently referencing the two-factor authentication enabled on his phone.
      <Citation number={213} />
      Tarrio also appeared familiar with another attendee, Vets for Trump leader Macias, who rested his hand on Tarrio’s shoulder at various points.
      <Citation number={214} />
      Rhodes and Tarrio shook hands.
      <Citation number={215} />
    </Typography>

    <Typography className="indent">
      Much of the substantive conversation between Rhodes, Tarrio, and the others cannot be heard because Tarrio asked Quested’s camera crew to stop recording.
      <Citation number={216} />
      However, some of the conversation is audible from afar and Rhodes can be heard telling Tarrio that he “has three groups in Tyson’s Corner,”
      <Citation number={217} />
      a reference to the QRFs that he had mustered in the event that President Trump called the Oath Keepers into service.
    </Typography>

    <Typography className="indent">
      Tarrio later expressed appreciation for Rhodes’s presence at the garage meeting and underscored that their two organizations needed to stand together on January 6th. Tarrio explained that the Proud Boys and Oath Keepers are “just two different groups” and that he and Rhodes “don’t get along,” but said that “for situations like this where there is a need to unite regardless of our differences ... what he did today was commendable.”
      <Citation number={218} />
      Tarrio added that Rhodes’s presence at the garage meeting was “thoughtful” because Rhodes had “quickly provided security” for the meeting and “seemed concerned” about Tarrio’s legal situation.
      <Citation number={219} />
      In a likely nod to prior coordination between Proud Boys and Oath Keepers at other post-election events, Tarrio further explained that “my guys have helped him [Rhodes] out in the past,” and that he and Rhodes have “mutual respect” for one another.
      <Citation number={220} />
      Tarrio then traveled north to a hotel near Baltimore, Maryland, where he stayed through the events of the next day.
      <Citation number={221} />
    </Typography>
  </Page>
)
