import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet8'));

export const Chapter68 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      The Oath Keepers were not the only anti-government extremists who viewed President Trump’s December 19th, tweet as a call to arms. Militias around the country were similarly inspired to act. “People were retweeting it right and left.... I saw people retweeting it, talking about, yeah, it’s going to be crazy, going to be a huge crowd,” Michael Lee Wells, a militia leader in North Carolina, told the Select Committee.
      <Citation number={272} />
      Members of militias known as the “Three Percenters” were electrified.
    </Typography>

    <Typography className="indent">
      The Three Percenters believe that three percent of American colonists successfully overthrew the British during the American Revolution.
      <Citation number={273} />
      This is not true. Far more than a tiny fraction of the colonial population fought in or supported the Revolutionary War.
      <Citation number={274} />
      Regardless, this ahistorical belief has become an organizing myth for militias around modern-day America.
    </Typography>

    <Typography className="indent">
      As with the Oath Keepers, many Three Percenters have turned against the U.S. Government, such that they equate it with the British monarchy and believe it should be overthrown.
      <Citation number={275} />
      The movement does not have one, centralized hierarchy. Instead, semi-autonomous branches organize and run themselves.
      <Citation number={276} />
      The Three Percenter cause was growing prior to the attack on the U.S. Capitol. Jeremy Liggett, a militia leader in Florida, told the Select Committee it was “trendy” in far-right circles to identify with the Three Percenter movement in the months leading up to January 6th.
      <Citation number={277} />
    </Typography>

    <Typography className="indent">
      President Trump tapped into this well of anti-government extremism. The President’s repeated insistence that the election had been stolen resonated with militia members who were already inclined to believe in shady political conspiracies. The President’s December 19th tweet mobilized Three Percenters around the country. Suddenly, they had a focal point for their anti-government beliefs: the joint session of Congress on January 6th. Court filings and other evidence reveal that Three Percenters immediately began planning for violence after President Trump’s “be wild” announcement.
    </Typography>

    <Typography className="indent">
      For example, Lucas Denney and Donald Hazard led a militia affiliated with the Three Percenter movement called the “Patriot Boys of North Texas.” Both Denney and Hazard were charged with assaulting officers on January 6th.
      <Citation number={278} />
      Denney pleaded guilty and has been sentenced to 52 months in prison.
      <Citation number={279} />
      After President Trump’s tweet, they discussed travel plans, as well as the need to procure body armor, helmets, knuckle gloves and pepper spray.
      <Citation number={280} />
      But they did not plan to act alone. Instead, they saw themselves as part of a coalition. In multiple messages, both Denney and Hazard claimed they were also affiliated with Proud Boys and intended to work with them on or before January 6th.
      <Citation number={281} />
    </Typography>

    <Typography className="indent">
      Denney repeatedly cited President Trump’s tweet. “Trump himself is calling for a big protest in DC on January 6th. I’m not going to miss this one,” Denney told Hazard on December 21st.
      <Citation number={282} />
      On December 30th, Denney wrote in a Facebook message:
    </Typography>

    <Blockquote>
      <Typography>
        Trump has called this himself. For everyone to come. It’s the day the electoral college is suppose to be certified by congress to officially elect Biden. But, Pence is in charge of this and he’s going to throw out all the votes from States that were proved to have fraud. There’s so much more going on behind the scenes though. That’s why he’s called this rally for support.... Trump will stay President ... 
        <Citation number={283} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      As this message indicates, Denney was well-aware of President Trump’s multi-part plan to disrupt the transfer of power. He thought that Vice President Pence had the power to “throw out” electoral votes, just as the President demanded. In other messages, Denney claimed that President Trump wanted militias to descend on Washington, DC so they could serve as a security force against a perceived threat from Antifa and Black Lives Matter on January 6th.
      <Citation number={284} />
    </Typography>

    <Typography className="indent">
      Additional messages between the two reveal their intent to march on the U.S. Capitol. For instance, Denney attempted to post two banners on Facebook that advertised events on January 6th.
      <Citation number={285} />
      Both banners contained the hashtag “#OccupyCongress.” The pictures contained images of the U.S. Capitol and referenced “The Great Betrayal.” One of them read “If They Won’t Hear Us” and “They Will Fear Us.” In another post, Denney wrote: “I can’t wait to be in the middle of it on the front line on the 6th.”
      <Citation number={286} />
    </Typography>

    <Typography className="indent">
      Curiously, Denney had also heard a “rumor” that President Trump would march with them. On January 4, 2021, he stated in a Facebook message:
    </Typography>

    <Blockquote>
      <Typography>
        Things are going to be happening here. Trump is going to be speaking to everyone Wed [January 6] before everyone marches to the capital [<i>sic</i>]. Rumour [<i>sic</i>] has it that he may march with us. I’ll tell you more when you get here on where to be wed and what time so you have the best seats.
        <Citation number={287} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      On or about January 6th, Denney sent another message via Facebook, writing: “Trump speaking to us around 11 am then we march to the capital and after that we have special plans that I can’t say right now over Facebook. But keep an eye out for live feed tomorrow from me. Tomorrow will be historic.”
      <Citation number={288} />
      Later on January 6th, during the attack, Hazard was captured on video bragging: “We have stormed our nation’s capitol.”
      <Citation number={289} />
    </Typography>

    <Typography className="indent">
      The Patriot Boys of North Texas were not the only Three Percenter group that mobilized after President Trump’s tweet. The Department of Justice has alleged that multiple other cadres of Three Percenter militiamen prepared for violence on January 6th and then took part in the attack on the U.S. Capitol.
    </Typography>

    <Typography className="indent">
      In Florida, a Three Percenter organization known as the “Guardians of Freedom” established a “B-squad” for January 6th because they allegedly wanted to avoid being called a “militia.”
      <Citation number={290} />
      These men were led by Jeremy Liggett, mentioned above.
      <Citation number={291} />
    </Typography>

    <Typography className="indent">
      On December 24, 2020, the B-squad sent out a flyer, “CALLING ALL PATRIOTS!” to Washington, D.C.
      <Citation number={292} />
      The flyer read: “The Guardians of Freedom III% are responding to the call from President Donald J. Trump to assist in the security, protection, and support of the people as we all protest the fraudulent election and re-establish liberty for our nation. JOIN US & Thousands of other Patriots!”
      <Citation number={293} />
      The B-Squad claimed it was the “right & duty of the people to alter or to abolish” the Government.
      <Citation number={294} />
      Its members discussed bringing tactical gear to Washington, DC.
      <Citation number={295} />
    </Typography>

    <Typography className="indent">
      On December 30th, Liggett posted a meme to Facebook stating that “3% Will Show In Record Numbers In DC.”
      <Citation number={296} />
      When the Select Committee asked about this post, Liggett downplayed its significance or disclaimed any knowledge about other Three Percenter groups that might “show in record numbers.”
      <Citation number={297} />
      However, on January 3, 2021, Liggett posted a “safety video” on Facebook in which he and others dressed in military gear. Liggett instructed listeners about self-defense and the tools they (like him) could bring to Washington, DC, including “an expandable metal baton, a walking cane and a folding knife.”
      <Citation number={298} />
      He advised “all of you Patriots going to Washington, D.C.... to support Trump,” and to “keep up the fight.”
      <Citation number={299} />
      Several “B-squad” members have been charged with civil disorder and disorderly and disruptive conduct, which took place while rioters nearby were assaulting officers in the tunnel area of the Capitol’s Lower West Terrace on January 6th.
      <Citation number={300} />
    </Typography>

    <Typography className="indent">
      In California, another group of men associated with the Three Percenter movement quickly began plotting their next moves after President Trump’s tweet. Alan Hostetter and Russell Taylor ran a non-profit known as the American Phoenix Project, which protested COVID-19 lockdowns and the 2020 election results, while also promoting violence ahead of January 6th.
      <Citation number={301} />
      Ahead of the joint session, Hostetter and Taylor organized a small group in an encrypted chat they named “The California Patriots—DC Brigade.”
      <Citation number={302} />
    </Typography>

    <Typography className="indent">
      On December 19th, Taylor linked to President Trump’s “will be wild” tweet and asked members of the chat “Who is going?”
      <Citation number={303} />
      The same day, Hostetter posted a message to his Instagram account, explaining he was traveling to Washington, DC on January 6th because President Trump “tweeted that all patriots should descend on Washington DC” and that day “is the date of the Joint Session of Congress in which they will either accept or reject the fake/phony/stolen electoral college votes.”
      <Citation number={304} />
      The next day, Taylor renamed the Telegram chat as “The California Patriots-Answer the Call Jan 6.”
      <Citation number={305} />
      On December 29th, Taylor posted to that chat: “I personally want to be on the front steps and be one of the first ones to breach the doors!”
      <Citation number={306} />
    </Typography>

    <Typography className="indent">
      Between December 19th and January 6th, Hostetter, Taylor and their alleged co-conspirators exchanged messages about bringing weapons, such as hatchets, bats, or large metal flashlights, as well as possibly firearms, with them to Washington, DC.
      <Citation number={307} />
      They were “ready and willing to fight.”
      <Citation number={308} />
      In one message, Hostetter predicted that January 6th would be similar to the “War of Independence” because “[t]here will likely be 3% of us again that will commit fully to this battle, but just as in 1776 patriots will prevail.”
      <Citation number={309} />
    </Typography>

    <Typography className="indent">
      There are additional examples of how President Trump’s “be wild” tweet led Three Percenters to descend on the U.S. Capitol. One Three Percenter group issued an open letter on December 16, 2020, announcing that they “stand ready and are standing by to answer the call from our President should the need arise that We The People are needed to take back our country from the pure evil that is conspiring to steal our country away from the American people.... We will not act unless we are told to.”
      <Citation number={310} />
      Inlate December, after the President’s tweet, The Three Percenters Original (TTPO) issued a letter to its members announcing that “this organization will be answering that call!”
      <Citation number={311} />
    </Typography>

    <Typography className="indent">
      There is also additional evidence showing that militia groups like the Three Percenters coordinated with other groups both before and on January 6th. Josh Ellis, the owner of the MyMilitia website, testified that he used Zello (a walkie-talkie app) when he was in Washington, DC on January 6th. The Proud Boys, Oath Keepers, other militia members, and “regular patriots” all used these Zello channels in the leadup to January 6th and in response to President Trump’s December 19th tweet. They used these channels to share intelligence.
      <Citation number={312} />
    </Typography>
  </Page>
)
