import { lazy } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Page } from '../../components/Page';

const Citation = lazy(() => import('../Citations/CitationSheet13'));

export const Appendix2 = ({ ...args }: any) => (
  <Page {...args}>
    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h2" color="primary">
        INTRODUCTION
      </Typography>
    </Box>

    <Typography className="indent">
      H. Res. 503 Section 4(a) directs the Select Committee to examine the “facts, circumstances, and causes relating to the domestic terrorist attack on the Capitol,” including the “activities of intelligence agencies, law enforcement agencies, and the Armed Forces, including with respect to intelligence collection, analysis, and dissemination and information sharing among the branches and other instrumentalities of government.” This appendix focuses on the activities of the DC National Guard in the days leading up to and on January 6, 2021.
    </Typography>

    <Typography className="indent">
      In contrast to the National Guard units in 50 States and three territories, where deployment authority lies with the governor of those respective jurisdictions, the DC Guard falls directly under the command of the United States President. In the discussion section below, this appendix provides a narrative of the preparations for and eventual deployment of the DC Guard on January 6th, and the interaction between then-President Trump and the DC Guard in the relevant time period. It is based on the Select Committee’s interviews of 24 witnesses and review of over 37,000 pages of documents.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h2" color="primary">
        DISCUSSION
      </Typography>
    </Box>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        A “GUT-WRENCHING” SUMMER
      </Typography>
    </Box>

    <Typography className="indent">
      The approval process for the deployment of the DC National Guard is unique, unlike any of the 50 States or three territories across the country where ultimate authority rests in the hands of the governor.
      <Citation number={1} />
      In the nation’s capital, where no governorship exists, the Guard is ultimately under the command of the President of the United States when acting in its militia capacity to support civil authorities.
      <Citation number={2} />
      By executive order, however, President Richard Nixon delegated the President’s day-to-day control of the DC Guard to the Secretary of Defense and specified that its Commanding General should report to the Secretary of Defense or the Secretary’s designee.
      <Citation number={3} />
      By memorandum, the Secretary of Defense, in turn, delegated day-to-day control of the DC Guard to the Secretary of the Army.
      <Citation number={4} />
      The commander of the DC Guard reported directly to the Secretary of the Army on January 6, 2021.
      <Citation number={5} />
    </Typography>

    <Typography className="indent">
      During the 2020 summer protests in response to the murder of George Floyd,
      <Citation number={6} />
      the approval process for Guard deployment ran smoothly. “Very, very proactive,” then-Commanding General of the DC Guard William Walker said.
      <Citation number={7} />
      Secretary of the Army Ryan McCarthy “really wanted us out there.”
      <Citation number={8} />
      Secretary McCarthy, with all his authorities, was physically beside Major General Walker throughout that summer. “[H]e was with me for all of it,” Major General Walker said.
      <Citation number={9} />
      “He came to the [A]rmory every day. He brought his staff with him.”
      <Citation number={10} />
      As has been widely reported, a number of President Trump’s senior advisors, including Attorney General Bill Barr, resisted President Trump’s requests to deploy the Guard or other troops in various states and cities where violence had occurred or was underway.
      <Citation number={11} />
    </Typography>

    <Typography className="indent">
      In the summer of 2020, nothing was being written down; it “was just all verbal back and forth.”
      <Citation number={12} />
      That approach sped up response times. But as the DC Guard footprint grew and controversies began plaguing the operation, Secretary McCarthy came away with the lesson that deliberative and by written order beat fast and by oral command.
      <Citation number={13} />
    </Typography>

    <Typography className="indent">
      “What we learned in the process was we were not capturing a lot of the information in writing in the orders process, which is fundamental, foundational because of the stress of the situation and the speed of the situation,” Secretary McCarthy said.
      <Citation number={14} />
    </Typography>

    <Typography className="indent">
      While a concept of operations (“con-op”) was developed at the lower level during the summer 2020 operations, it did not require approval or input at the secretary level.
      <Citation number={15} />
      That approach came to be seen as a mistake that should not be replicated the next time there was a civil disturbance crisis in the nation’s capital. General Walter Piatt, director of the Army staff, explained: “That’s where Secretary McCarthy put that restriction to say, I want a concept of the operation before we just send a force to do something.”
      <Citation number={16} />
    </Typography>

    <Typography className="indent">
      One of the most visible and highly criticized of the summer 2020 operations was the use of low-flying helicopters that appeared to be bearing down on protesters with the aim of dispersing them. On June 1st, as Guard presence tripled overnight, the use of helicopters meant for aerial surveillance
      <Citation number={17} />
      “somehow got translated to a very competent Army officer that ‘I am to fly low and loud to deter looters,’” General Piatt said.
      <Citation number={18} />
      “[W]hat the investigation revealed was that we did not have good procedures in place to provide military support to a very serious civil disturbance ongoing. The— because—the pilot of that aircraft believed that was his mission.”
      <Citation number={19} />
    </Typography>

    <Typography className="indent">
      The “embarrass[ment]”
      <Citation number={20} />
      of the low-flying helicopter affected Secretary McCarthy. General Charles Flynn, then-deputy chief of staff for operations, plans, and training, told the Select Committee, “I know the Secretary was concerned.”
      <Citation number={21} />
      He further explained: “I’m sure that affected his thinking.”
      <Citation number={22} />
    </Typography>

    <Typography className="indent">
      Secretary McCarthy became convinced that a concept of operations needed to be “explicit, tailored”
      <Citation number={23} />
      and—most importantly—that it needed to “come from [the] top down.”
      <Citation number={24} />
      Secretary McCarthy told his staff, “[W]hen we get a request next time, we have to be absolutely certain that we understand the mission clearly,”
      <Citation number={25} />
      and that “no other civil authority could re-mission off that support without the approval of either the Secretary of the Army or, in certain circumstances, the Secretary of Defense.”
      <Citation number={26} />
    </Typography>

    <Typography className="indent">
      In mid-June 2020, then-Secretary of Defense Mark Esper, Secretary McCarthy, Chairman of the Joint Chiefs of Staff General Mark Milley, and Major General Walker huddled to talk about what went wrong in the preceding weeks. Senior defense officials then decided they would take a “more active” role in directing the force.
      <Citation number={27} />
      “[A]s a result, we all took a more active interest in what was happening down to the tactical level to make sure that we were, again, abiding by kind of the core principles of civil-military relations,” former Secretary Esper said.
      <Citation number={28} />
    </Typography>

    <Typography className="indent">
      Major General Walker said he was not told about that shift in perspective: “[I]f the Army thought different of how we respond to civil unrest, civil disturbance, I would hope... they would communicate that with the guy—with the person who is going to execute that change.”
      <Citation number={29} />
    </Typography>

    <Typography className="indent">
      In the words of General Milley, the summer of 2020 had been “a pretty gut-wrenching experience.”
      <Citation number={30} />
      The Department of Defense was still recovering when it was faced with the decision of the manner and degree to which the DC Guard should provide assistance to law enforcement authorities planning, just seven months later, for the events anticipated in connection with Congressional certification of the electoral votes on January 6, 2021.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        A “Tailored” Request for Guard Resources
      </Typography>
    </Box>

    <Typography className="indent">
      On December 19, 2020, President Trump tweeted, “Big protest in DC on January 6th. Be there! Will be wild!” From that day forward, a rookie DC intelligence analyst saw a tenfold uptick in violent rhetoric targeting Congress and law enforcement.
      <Citation number={31} />
      Right-wing groups were sharing histories of violence and some not traditionally aligned had begun coordinating their efforts.
      <Citation number={32} />
      The analyst’s report reached more senior DC leadership, including, eleven days later, Mayor Bowser.
      <Citation number={33} />
      In the course of the Committee’s investigation, it received and reviewed a significant number of documents indicating that certain intelligence and law enforcement agencies understood that violence was possible or even likely on January 6th. The Committee received many of those materials from the U.S. Secret Service, but also from other agencies as well.
    </Typography>

    <Typography className="indent">
      On Thursday, December 31, 2020, the day after the briefing, Mayor Bowser sent a letter to Major General Walker requesting Guard assistance for January 5 and 6, 2021.
      <Citation number={34} />
      A second letter specified the District’s request as limited to two forms of assistance: crowd management at Metro stations and blocking vehicles at traffic posts. It did not request help with potential civil disturbance.
      <Citation number={35} />
    </Typography>

    <Typography className="indent">
      General Piatt viewed the “limited request”
      <Citation number={36} />
      as a “pretty good, tailored mission,” that was “not vague.”
      <Citation number={37} />
      General James McConville, chief of staff of the Army, called the request appropriately “restricted”
      <Citation number={38} />
      with “a very low military signature.”
      <Citation number={39} />
    </Typography>

    <Typography className="indent">
      According to testimony by Defense Department witnesses, after a summer of perceived overreach, military leadership was grateful for the delineated parameters set by the city itself.
    </Typography>

    <Typography className="indent">
      The substance of the request—limited to traffic and crowd control “so they could have more police officers to do police functions”
      <Citation number={40} />
      —was not seen as narrow by District officials. “I would say it’s a specific request,” Chief of DC Police (Metropolitan Police Department (“MPD”)) Robert Contee said.
      <Citation number={41} />
      “[L]eave the unlawful stuff, leave that to the police to deal with.”
      <Citation number={42} />
      Mayor Bowser said: “I don't know any law enforcement person who would suggest that urban disturbances aren’t best handled by the police.”
      <Citation number={43} />
    </Typography>

    <Typography className="indent">
      “Civil disturbance was not something we requested at that time. Mostly also because the vast majority of the, if not all, of the permitted protests were taking place on Federal lands,” said Director of the DC Homeland Security and Emergency Management Agency Christopher Rodriguez.
      <Citation number={44} />
      The District had no jurisdiction. “Mayor Bowser cannot make a request on behalf of the White House or on behalf of the Capitol for U.S. Capitol—for... DC National Guardsmen to deploy to those two entities. She can’t,” Chief Contee said.
      <Citation number={45} />
      At this time, it was well known that President Trump had planned a speech and rally on “Federal lands”—on the Ellipse south of the White House. It was not widely known that President Trump intended to “spontaneously” instruct the tens of thousands of supporters at that Ellipse rally to march down Pennsylvania Avenue to the Capitol.
      <Citation number={46} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        Five Days of “Tremendous Resistance”
      </Typography>
    </Box>

    <Typography className="indent">
      At first, Secretary McCarthy was not sold on involving the Guard at all. Major General Walker called Secretary McCarthy “instantly when I got the letters” from the District on Thursday, December 31st, and “initially I felt I must have caught him at a bad time.”
      <Citation number={47} />
      Secretary McCarthy recalls it being a short conversation. “I said, ‘okay, got it. Thank you.’ You want to immediately flip it so that Secretary of Defense knows that we alerted his office,” he said.
      <Citation number={48} />
    </Typography>

    <Typography className="indent">
      According to Major General Walker’s account of this call, the Secretary initially stated “‘We’re not doing it,’”
      <Citation number={49} />
      and then left the door open for further discussion. Major General Walker explained: “‘I said, ‘Well, sir, I think you should look at it.’ And then he told me, ‘Well, we’ll talk about it, but we don’t really want to do this, because the look it would give, the military out there interfering.’ ...He says, ‘Well, we’ll discuss it on Monday.’”
      <Citation number={50} />
    </Typography>

    <Typography className="indent">
      On Sunday, January 3rd, Secretary McCarthy called Chief Contee, who had formally assumed the role of acting head of MPD just the day before.
      <Citation number={51} />
    </Typography>

    <Typography className="indent">
      “I thought initially that... he is just calling me basically as a rubber stamp to say, ...‘You asked for it, you got it.’ ...It didn’t go that way,” Chief Contee said.
      <Citation number={52} />
      “[H]e had concerns about deploying National Guard for this event. He talked about the optics of the event, having boots on the ground.... And I pushed back on that.”
      <Citation number={53} />
    </Typography>

    <Typography className="indent">
      In his interview with the Select Committee, Secretary McCarthy described evaluating the request on the evening of January 3rd. “I sat at home. I chewed on it,” he said.
      <Citation number={54} />
      “You know, I'm not particularly inclined to support it, because my concern was really we didn't have a command-and-control architecture in place. We didn't really have all of the mechanisms to be successful, you know.... So it was a very tough decision for me.”
      <Citation number={55} />
    </Typography>

    <Typography className="indent">
      Over five days, from December 31st to January 4th, District officials faced what Major General Walker called “tremendous resistance.”
      <Citation number={56} />
    </Typography>

    <Typography className="indent">
      Both Chief Contee and Director Rodriguez recalled that five-day period on January 6th, when Chief Steven Sund, of the U.S. Capitol Police, was pleading for reinforcements.
      <Citation number={57} />
      Acting Defense Secretary Christopher Miller, “heard through the grapevine that [Secretary McCarthy] was inclined—I don't want to say inclined to disapprove, but, you know, looking at it carefully or whatever. So—but that's fine. He can do whatever he wants. I knew that I was going to honor [the mayor’s] request....”
      <Citation number={58} />
    </Typography>

    <Typography className="indent">
      How close those Guard assets could go to the Capitol became a sticking point. Colonel Craig Hunter, the highest-ranking commander on the ground on January 6th, said the Army “really want[ed] to go through the concept of operations to see, okay, exactly—basically Metro stop by Metro stop, intersection by intersection, to see where will Guardsmen be exactly, you know, how close are you to the Capitol....”
      <Citation number={59} />
      He said an initial request by MPD to post Guard troops at the South Capitol Metro station—like all other Metro stations—was denied.
      <Citation number={60} /> In conference calls that “went back andforth,”
      <Citation number={61} />
      Major General Walker was told, “There was a concern about being too close, military uniforms too close to the Capitol.”
      <Citation number={62} />
    </Typography>

    <Typography className="indent">
      Major General Walker had a different perspective. He saw his people as “citizen soldiers,” “your neighbors that are going to come to your aid and rescue when you need us,”
      <Citation number={63} />
      —not traditional boots on the ground. “[T]hat’s where, to me, the vest came in. This was the National Guard, not the Army,” he said.
      <Citation number={64} />
    </Typography>

    <Typography className="indent">
      Military authorities determined that a geographical boundary would have to be established as a condition of approving the Guard’s deployment to assist MPD. No service member could go east of Ninth Street. It wasn’t made explicit to District officials, but they all knew what lay east. “[T]he Capitol is east,” Chief Contee said.
      <Citation number={65} />
      “[I]f you move them anywhere east of Ninth Street, they will be close to the Capitol. That was certainly the way I understood it.”
      <Citation number={66} />
    </Typography>

    <Typography className="indent">
      Director Rodriguez worried “that it constrained our ability to react quickly if the situation got out of hand.”
      <Citation number={67} />
      Without the limitation, the District fully intended to post its resources farther east: “[W]e couldn't get as close to the Capitol as could have been helpful,” Mayor Bowser said.
      <Citation number={68} />
      “[W]e would have had a broader traffic box.”
      <Citation number={69} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        Fears of Politicizing the Military in an Antidemocratic Manner
      </Typography>
    </Box>

    <Typography className="indent">
      Both Acting Secretary Miller and Secretary McCarthy were sensitive to the sight of troops near the site of the Congressional certification of electoral votes, because of President Trump’s previous expression of interest in using Federal troops in civilian situations. Again, Attorney General Barr and other members of the Trump Administration had resisted President Trump’s desire to deploy such troops. Secretary Esper said it “tended to be the case... that the President was inclined to use the military,” contrary to longstanding principles of reserving the armed forces as a last resort.
      <Citation number={70} />
    </Typography>

    <Typography className="indent">
      According to his testimony, Acting Secretary Miller’s express first priority—after being installed with just two months left in the Trump administration—was “to make every effort to return the Department of Defense to a nonpoliticized entity,” because previously, “the Department was being showcased too much.”
      <Citation number={71} />
      In testimony to the U.S. House Committee on Oversight and Reform on May 12, 2021, he cited “fears that the President would invoke the Insurrection Act to politicize the military in an antidemocratic manner” as shaping his thinking.
      <Citation number={72} />

     “No such thing was going to occur on my watch,” he wrote,
      <Citation number={73} />

     later adding that “if I would have put troops on Capitol Hill” before the attack and without a request from civil authorities, “that would have been seen as extremely provocative, if not supporting this crazy narrative that the military was going to try to overturn the election.”
      <Citation number={74} />
    </Typography>

    <Typography className="indent">
      Secretary McCarthy felt similar pressure. He had been taken aback when—as he was walking down the Pentagon’s hallways—“one of the most seasoned reporters” asked him whether the Army was planning to seize ballot boxes.
      <Citation number={75} />
      It was “an incredibly tense period,” according to Secretary McCarthy.
      <Citation number={76} />
      As our investigation has demonstrated, President Trump had considered proposals from Lt. General (ret.) Michael Flynn, Sidney Powell, and others that troops be utilized to seize ballot boxes in certain parts of the country.
    </Typography>

    <Typography className="indent">
      On December 18, 2020—the same day as the contentious White House meeting with Flynn and others,—Secretary McCarthy issued a statement, “mirror[ing] what General Milley said about a month before,”
      <Citation number={77} />
      reiterating that “There is no role for the U.S. military in determining the outcome of an Americanelection.”
      <Citation number={78} />
      Given the heat of the rhetoric, he thought, “[I]f we don't say anything, it's going to scare people.”
      <Citation number={79} />
      Secretary McCarthy told the Select Committee he thought he would be fired after publicly stating that the military would not assist in a coup.
      <Citation number={80} />
      General McConville, who signed the statement alongside Secretary McCarthy’s signature,
      <Citation number={81} />
      linked their words directly to the Ninth Street limitation: “[T]here was no plan to put any military anywhere near the Capitol because of what we had said, the military has no role in determining the outcome of elections.”
      <Citation number={82} />
    </Typography>

    <Typography className="indent">
      On January 1st, Executive Officer to Secretary McCarthy Colonel John Lubas wrote in an internal email that the Secretary “wants to clearly communicate that this request is NOT from the White House.”
      <Citation number={83} />
      The email noted that the Secretary wanted to “aggressively message” that the request had come from District officials, not the President.
      <Citation number={84} />
    </Typography>

    <Typography className="indent">
      “We wanted everybody to know that, because it would create confusion and even more tension of having soldiers on the street without a request and that they be near the Capitol with certification of an election, a contested election,” Secretary McCarthy said.
      <Citation number={85} />
    </Typography>

    <Typography className="indent">
      On January 3, 2021, 10 former Secretaries of Defense, including the recently fired, former Secretary Esper, published a joint op-ed warning that “[i]nvolving the military in election disputes would cross into dangerous territory.”
      <Citation number={86} />
      Secretary McCarthy had himself worked for five of the 10 secretaries.
      <Citation number={87} /> “I know all these [men],” he said.
      <Citation number={88} /> “[T]hey were—everyone was telling us, be very conscious of your actions and how you—you know, what you're going to do that day. So we wanted to know where every soldier was by street corner.”
      <Citation number={89} />
    </Typography>

    <Typography className="indent">
      Was Secretary McCarthy concerned that President Trump might use the military to cling to power? “There was a lot of talk in the lead-up about martial law... and the employment of forces, and you know, that was something that we were all, you know, conscious of.”
      <Citation number={90} /> Our investigation suggests that those civilian and military officials who had considerable experience working directly with President Trump had genuine concerns about whether he would attempt to use the military to change the election results. Again, at this time, there is no evidence the Department of Defense understood exactly what President Trump and his associates planned for January 6th.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        “Very Strict on the Use of the Military” on January 6th
      </Typography>
    </Box>

    <Typography className="indent">
      By Monday, January 4th, with Secretary McCarthy now backing the operation, Acting Secretary Miller was briefed. He told the Select Committee that he “made the decision right there to honor the request.”
      <Citation number={91} />
      That approval came with strings attached. The role of the DC Guard would be spelled out and tightly circumscribed in a memorandum that, as characterized by General Milley, was “very strict on the use of the military.”
      <Citation number={92} />
      It decreed that without the Acting Secretary’s “subsequent, personal authorization,” the Guard would not be issued batons, helmets, or body armor; could not interact physically with protestors, except in self-defense; and that the Quick Reaction Force (QRF)—40 servicemembers staged in case of an emergency at Joint Base Andrews in Prince George’s County, Maryland—could be deployed only as a last resort.
      <Citation number={93} />
    </Typography>

    <Typography className="indent">
      Above the tactical level, changes in the mission of the Guard had to be approved by the Secretary of the Army and, in some cases—in order to “interact physically with protestors” or be issued batons—required running further up the chain to the Secretary of Defense.
      <Citation number={94} />
    </Typography>

    <Typography className="indent">
      To District officials, that seemed new—and unnecessary. “[The Secretary told us,] if you send them to any other kind of mission, that has to get approval from me. And I just think that those were unnecessary restrictions...” Chief Contee said.
      <Citation number={95} />
    </Typography>

    <Typography className="indent">
      What the QRF would be called upon to do, even in the case of an emergency, is a matter of debate. The letter and memorandum do not expressly note whether the QRF could be used to support the original mission of the Guard—traffic and crowd control—or a new mission helping contain sudden and out-of-hand civil disturbance.
      <Citation number={96} />
      The DC Guard official put in charge of the QRF for January 5th and 6th, Lieutenant Colonel David Reinke, said he had not been given much guidance as to their role.
      <Citation number={97} />
    </Typography>

    <Typography className="indent">
      The Army and the DC Guard appear to have had different understandings. “[T]he intent of the quick reaction force was really to send these troops over to help if they had a problem at one of the traffic command posts,” General McConville said.
      <Citation number={98} />
      “There never was an intent for a quick reaction force to go to the Capitol....”
      <Citation number={99} />
      According to Army officials, without a con-op and a formal change in mission, the QRF could do traffic control and no more.
    </Typography>

    <Typography className="indent">
      That was not how others imagined an emergency unit would or should operate. According to Major General Walker, “a quick reaction force, something's happening; do I have time to find you and call you and ask you?”
      <Citation number={100} />
      He called the preapproval language “highly unusual,”
      <Citation number={101} />
      particularly as their name “already implied that it's a last resort,”
      <Citation number={102} />
      their intended purpose was if “[u]nexpectedly, you have a spontaneous unrest,”
      <Citation number={103} />
      and if “I need to write a concept of operations for a quick reaction force? They're no longer quick. It's just a reaction force.”
      <Citation number={104} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        Capitol Police Board “Prepared” without Guard Help
      </Typography>
    </Box>

    <Typography className="indent">
      It wasn’t clear to everyone involved in planning for the events anticipated on January 6th that all agencies, including the Capitol Police, were deploying all their resources ahead of that day. “We had had issues understanding, getting the full picture of U.S. Capitol Police's operational posture and what their planning was,” Director Rodriguez said.
      <Citation number={105} />
    </Typography>

    <Typography className="indent">
      Mayor Bowser was struck when—right before the press briefing that Monday, January 4th—the mayor asked the Capitol Police representative, “[W]here does your perimeter start? [And h]e gets up out of the room, calls somebody. And the next thing I know he can’t participate in the conference.”
      <Citation number={106} />
      She elaborated: “[T]hat should have been like a trigger to me. Like these people, they don't want to answer questions about their preparation.”
      <Citation number={107} />
    </Typography>

    <Typography className="indent">
      On the morning of January 3rd, Capitol Police Chief Steven Sund approached two of the members of the Capitol Police Board and purportedly requested—but concededly did not push for—Guard resources for the Capitol.
      <Citation number={108} />
      According to Chief Sund, in a minutes long meeting in the office of House Sergeant at Arms Paul Irving, Irving told Chief Sund he did not like the optics of asking for the Guard in advance and that the intelligence did not support it.
      <Citation number={109} />
      Chief Sund said he did not push back on either point.
      <Citation number={110} />
      In fact, he agreed that his reading of the intelligence—despite a forewarning put out by his own intelligence unit that “Congress was itself the target” on January 6th
      <Citation number={111} />
      —did not call for Guard support,
      <Citation number={112} />
      only that having more personnel on his perimeter would make him “more comfortable.”
      <Citation number={113} />
      Irving suggested he talk to the Senate Sergeant at Arms, and then-chairman of the Capitol Police Board, Michael Stenger.
      <Citation number={114} />
      According to Irving, Stenger, in a meeting in his office, asked Chief Sund to reach out to the Guard and find out, if an emergency called for it, “how many people can [the commanding general] give us and how quickly can he give us those people?”
      <Citation number={115} />
      Chief Sund said he took their responses to mean “no,” despite conceding that he was never told “you cannot have the National Guard” or anything to that effect.
      <Citation number={116} />
      “It was 100 percent a denial,” he maintained.
      <Citation number={117} />
    </Typography>

    <Typography className="indent">
      Irving recalled the matter coming up on a three-way phone conference during which “the consensus was that we didn’t need” the Guard.
      <Citation number={118} />
      He did not consider it a request.
      <Citation number={119} />
      On the call, Chief Sund noted that the District planned to use the servicemembers to staff intersections, but the Capitol grounds had few of those, and it would not relieve many officers if they were used in a similar fashion.
      <Citation number={120} />
      “It was a combination of operationally the chief didn't feel that they would add much to his plan, and the intelligence really didn't speak for anything that we felt would justify the need for them,” Irving said.
      <Citation number={121} />
      Irving doesn’t recall taking the “optics” into consideration.
      <Citation number={122} />
      According to Irving, the conversation ended the same way Chief Sund said it had: “Why don't you just tell them to be on standby?” Stenger suggested.
      <Citation number={123} />
      It was never brought up again.
      <Citation number={124} />
    </Typography>

    <Typography className="indent">
      The discussion about the use of the Guard remained within the Capitol Police Board and did not reach congressional leadership, including the Speaker of the House. That was normal. “[F]rom a tactical perspective, we would make decisions without the input from congressional leadership,” Irving said.
      <Citation number={125} />
      “I always felt that I had full authority to implement security decisions as I deemed appropriate.”
      <Citation number={126} />
      In fact, when the three men briefed congressional leaders on January 5th, Chief Sund conveyed the same optimistic outlook as he had with Major General Walker: “[We told them] we felt we were prepared based on the information we had, yes,” he said.
      <Citation number={127} />
    </Typography>

    <Typography className="indent">
      To keep these exchanges in perspective, we note again that we are aware of no evidence that these individuals were privy to President Trump’s plans to instruct tens of thousands of his supporters to walk down Pennsylvania Avenue to the Capitol to help “take back” their country. Nor were they aware of how President Trump would suggest to his followers that Vice President Pence had the authority to change the outcome of the election, or how President Trump would behave in the hours that followed. Certain members of Congress, including those who met at the White House on December 21, 2020, may have had considerably more insight into President Trump’s planning, but the Committee has no information suggesting that any of those members alerted the Capitol Police or other authorities of President Trump’s plans.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        Soldiers Prepare for the Worst in Secret
      </Typography>
    </Box>

    <Typography className="indent">
      Guard reinforcements could draw from a pool of three groups already activated for the day: (1) the 40 members making up the QRF, staged in Maryland;
      <Citation number={128} />
      (2) the 90 members at the traffic control points, 24 at the Metro stations, and four as part of the command staff distributed throughout the city—but no farther east than Ninth Street—for a total of 118 representing the first shift;
      <Citation number={129} />
      and (3) the second shift of another 118 members,
      <Citation number={130} />
      preparing at the Armory in Southeast Washington, D.C, for a 3:00 p.m. shift takeover.
      <Citation number={131} />
      The Armory also housed a command-and-control squad that handled logistics at about 52 members strong, in addition to a Civil Support Team of about 20 members.
      <Citation number={132} />
      That gave Colonel Hunter a maximum limit of 348 activated servicemembers, eight more than the allotted 340—nearly all of whom reported directly to him on January 6th.
      <Citation number={133} />
    </Typography>

    <Typography className="indent">
      The QRF was most prepared for responding to sudden and escalating civil unrest. At Joint Base Andrews, they were provisioned with full riot-control kits,
      <Citation number={134} />
      including a helmet with a face shield already attached, protective vest, shin guards, knee guards, shield, and baton.
      <Citation number={135} />
      The head of the QRF—himself provided little guidance on the contours of his mission—had his squad train for civil disturbance on January 5th and the morning of the 6th as they waited.
      <Citation number={136} />
      Not only had they trained, but they trained together, as a unit—a benefit military officials all agreed is ideal. Army leadership, all the way up to Secretary McCarthy,
      <Citation number={137} />
      had no idea that Lt. Col. Reinke had taken these initiatives. Secretary McCarthy agreed that had he known of their civil disturbance preparation, “it could have” affected the speed with which approval was ultimately given for their deployment.
      <Citation number={138} />
    </Typography>

    <Typography className="indent">
      As to the second group of available resources—the servicemembers stationed at traffic control points since the early morning of January 6th— Army leadership held misconceptions about what equipment was available to them. Secretary McCarthy had agreed that some gear—expressly not batons—could remain stowed away in vehicle trunks.
      <Citation number={139} />
      Colonel Hunter had his troops put the gear into a white box truck instead and designated a rally point for the truck that would be central to all traffic control points.
      <Citation number={140} />
      On the night of January 5th, in anticipation of January 6th, Colonel Hunter had his troops move the civil disturbance gear—including the prohibited batons—into the individual vehicles themselves.
      <Citation number={141} />
      Captain Tarp, the head of the second shift, agreed that they were told to load the equipment into their vehicles “on the down low. Done so it wasn’t visible, so it wouldn’t look like we were escalating [our] role.”
      <Citation number={142} />
    </Typography>

    <Typography className="indent">
      The commanding general of the DC Guard was aware that the troops had all they needed in their trunks and—in the case of an emergency— would not need to return to the Armory to get it: “They already had it,” Major General Walker said.
      <Citation number={143} />
    </Typography>

    <Typography className="indent">
      But Army leadership did not know that. Although General Piatt said “We never asked, like, what was actually—I have no knowledge of what they [actually] had in” the vehicles,
      <Citation number={144} />
      the guidance from Secretary McCarthy’s letter led him to believe that “weapons, ammunition, batons, shields, kneepads, other protection that we may be asked to do for civil disturbance, that was not [there]—because they were specifically told they would not participate in that mission....”
      <Citation number={145} />
      Secretary McCarthy said, “The only thing I authorized General Walker to do was their ballistic helmets and body armor in the vehicle, not their shields or their riot batons.”
      <Citation number={146} />
      He figured his orders had been followed.
      <Citation number={147} />
      When asked why he was not aware of the moves the Guard had made, Secretary McCarthy said, “I mean, I made a mistake. I think a local unit commander was anticipating more than what potentially we were prepared for.”
      <Citation number={148} />
    </Typography>

    <Typography className="indent">
      DC Guard leadership understood that loading this equipment flouted direct orders. “I wasn’t going to have my soldiers unprepared,” Major General Walker said.
      <Citation number={149} />
      The prohibition on batons, in particular, had been sent 54 minutes after the Guard had already begun their traffic control shifts on the morning of January 5th.
      <Citation number={150} />
    </Typography>

    <Typography className="indent">
      The third group of available resources—the servicemembers awaiting at the Armory to take over as the second shift at the traffic control points— had equipment accessible to them at headquarters. Captain Tarp did note that it took time to ensure that the Armory equipment was in working order: repairing straps that were broken, wiping off dirt on the shields because “they were the same from the George Floyd protests,” and affixing the helmet to the shields, which took upwards of 20 to 30 minutes.
      <Citation number={151} />
      In the three different locations where the Guard was stationed as the Capitol was being breached, all servicemembers had access to full civil disturbance gear right there with them.
    </Typography>

    <Typography className="indent">
      Outside of the QRF, which had recently returned from two days of training together, there is debate as to how ready the rest of the Guard was to engage in civil disturbance response on January 6th. The notion that the military is not primed or naturally skilled to deal with civil disturbance appears to stand in tension with National Guard traditions, training, and doctrine. “They were not missioned, tasked, organized, equipped to do civil disturbance operations,” General Flynn said.
      <Citation number={152} />
      Although General Piatt conceded that “[a]ll soldiers are trained in civil disturbance,” he maintained that on “that day we were not postured to do civil disturbance operations.”
      <Citation number={153} />
    </Typography>

    <Typography className="indent">
      Major General Walker—who pointed out that the DC Guard shield, on Guard troop uniforms, features the Capitol building itself: “Protect the Capitol. That’s why Thomas Jefferson created it”
      <Citation number={154} />
      —called civil disturbance “foundational” to what they do.
      <Citation number={155} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        DC Ground Commander Takes Initiative
      </Typography>
    </Box>

    <Typography className="indent">
      On January 5th, as he led his forces in traffic control, Colonel Hunter did not observe activity that raised concerns.
      <Citation number={156} />
      But by the next morning—as thousands of out-of-towners invited by President Trump descended on the nation’s capital—that all changed. “I could see like the Proud Boys,” he said.
      <Citation number={157} />
      “I could see different people with Kevlars on, with bulletproof vests on. You know, they're all kitted up and they're wearing different patches and colors. And I said, ‘Well, this crowd is definitely different....’”
      <Citation number={158} />
      This, of course, was an indication of the potential for violence in the hours that followed.
    </Typography>

    <Typography className="indent">
      Colonel Hunter, sitting at the intersection of 15th Street and Pennsylvania Avenue, saw crowds flowing past him and his soldiers toward the Capitol—walking as one, chanting as one. “Hey, is it that way to the Capitol? Where’s the Capitol?” some asked.
      <Citation number={159} />
      Colonel Hunter got in his car and began writing an update report.
      <Citation number={160} />
    </Typography>

    <Typography className="indent">
      He was interrupted by a soldier who had been watching CNN on his phone: “Hey, sir, I think there’s been shots fired at the Capitol.”
      <Citation number={161} />
      It was then that Colonel Hunter began to put a plan in place for the redeployment of the Guard. “So at that point in my mind I said, ‘Okay, then they will be requesting the DC National Guard now, so we have to move.”
      <Citation number={162} />
      The time was 2:12 p.m.
      <Citation number={163} />
    </Typography>

    <Typography className="indent">
      The first thing he did was designate a rally point for DC Guard reinforcements.
      <Citation number={164} />
      Over the radio, he relayed the rally point to all 118 members currently spread across the city doing traffic control, and one by one, in order by their points, they called in to acknowledge where they would go once approval came down.
      <Citation number={165} />
      At 2:17 p.m., he called Lt. Col. Reinke, the head of the 40-strong QRF, and ordered his subordinate to “have all of your guys put their gear on and get on the bus.”
      <Citation number={166} />
      “In my mind, this is about to happen really fast,” he said.
      <Citation number={167} />
      “As soon as I make one call, I will get clearance to go and support. The United States Capitol was breached. I mean, this is unheard of.”
      <Citation number={168} />
      Accordingly, he advised Lt. Col. Reinke that, “[W]e will be getting a call soon.”
      <Citation number={169} />
    </Typography>

    <Typography className="indent">
      He next tried to find the incident command post. He ran into the Assistant MPD Chief Jeffery Carroll, piled into a car and, sirens flipped on, sped off to U.S. Capitol Police headquarters.
      <Citation number={170} />
    </Typography>

    <Typography className="indent">
      Once there, they got into the elevator, and “before the doors even closed,” Assistant Chief Carroll asked him, “How many do you have coming right now?”
      <Citation number={171} />
      Colonel Hunter said, “I’m working on it. I need to make some calls, but we are—we’re coming.... And I said, as soon as I start making these calls, I’m going to have so many National Guardsmen just flooding this way. I just need to have the location, have the plan set, be ready to receive them.”
      <Citation number={172} />
    </Typography>

    <Typography className="indent">
      No later than 2:50 p.m., Colonel Hunter had confirmed with Lt. Col. Reinke that the QRF was on the bus and ready.
      <Citation number={173} />
      The highest-ranking Guard official on the ground had sorted out all of the details and linked up with the law enforcement agencies that would lead them in support. At least 135 National Guard servicemembers—the 40 QRF members already in gear and on the bus and the 90 at traffic control posts awaiting word, with gear in their trunks to be donned at the rally point, along with four command staff plus Colonel Hunter himself—were ready to go. At 3:10 p.m., Colonel Hunter felt it was time to tell his superiors all that he had done and hopefully get fast approval.
      <Citation number={174} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        3 Hours and 19 Minutes
      </Typography>
    </Box>

    <Typography className="indent">
      At the Capitol, MPD Chief Contee was on the West Front, himself inhaling chemical agents—“you can smell it before you see it, felt it in my throat”
      <Citation number={175} />
      —as officers tried to resist rioters beating back the perimeter, having reached the stage built for the Inauguration set for two weeks later. “[T]he gas stuff and the spray, the mist that’s in the air, I mean, it’s real,” he said.
      <Citation number={176} />
      “I'm trying to talk to the Mayor to give her a situational update, and the city administrator—I've got them both on the line. I’m coughing, trying to explain what's going on.”
      <Citation number={177} />
      Chief Contee cut through the crowds of people around the Capitol to meet the mayor at MPD headquarters.
      <Citation number={178} />
    </Typography>

    <Typography className="indent">
      Chief Sund said he reached out to House Sergeant at Arms Irving at 12:57 or 12:58 p.m., and told him, “We are getting overrun on the West Front by thousands. We need the National Guard now.”
      <Citation number={179} />
      Irving recalled the call coming before a break in the electoral certification session just short of 1:30 p.m. and that the Chief said “that conditions were deteriorating outside and he <i>might</i> be making a request for the National Guard.”
      <Citation number={180} />
      Although Irving was firm in his stance that only the Capitol Police Board had the authority to request National Guard assistance, he nonetheless sought out the chief of staff to the Speaker to inform her of the impending request.
      <Citation number={181} />
      He did not need her to sign off, but “[a]ny change in security posture, given the time, I would give them a heads-up.”
      <Citation number={182} />
    </Typography>

    <Typography className="indent">
      The Speaker’s chief of staff “immediately scribbled down a note” and went over to inform the Speaker—who was in the chair presiding over the floor debate on the Arizona objections—about the request for the National Guard.
      <Citation number={183} />
      “Absolutely. Go,” Speaker Nancy Pelosi said.
      <Citation number={184} />
      Later, as they were evacuating the floor to an undisclosed location, the Speaker asked her, “Is the National Guard coming?”
      <Citation number={185} />
      The Speaker’s chief of staff said, “Yes, we asked them.”
      <Citation number={186} />
    </Typography>

    <Typography className="indent">
      Irving said the formal request for Guard assistance came in a call after 2 p.m. from Chief Sund “and, of course, we said absolutely.”
      <Citation number={187} />
      Chief Sund said he had made the request in that earlier 12:57 or 12:58 call and had been waiting for 71 minutes.
      <Citation number={188} />
      “I hung up the phone. I yelled across the command center, [‘M]ark the time, 2:10, I finally got approval from the Capitol Police Board for the use of the National Guard,’” Chief Sund said.
      <Citation number={189} />
    </Typography>

    <Typography className="indent">
      At around 2:30 p.m., Director Rodriguez patched Chief Contee—and a largely silent Mayor Bowser listening in
      <Citation number={190} />
      —into the conference call with Chief Sund and Major General Walker, who brought in General Piatt.
      <Citation number={191} />
      Major General Walker ordered his aide-de-camp on his second day on the job, Lt. Timothy Nick, to take handwritten notes of the call and the rest of the day.
      <Citation number={192} />
    </Typography>

    <Typography className="indent">
      On the line at the Pentagon—gathered around the speaker of the Secretary of the Army’s desk phone—were General Piatt, General McConville, and Secretary McCarthy.
      <Citation number={193} />
      According to Secretary McCarthy, it was during the call that he learned the Capitol had been breached,
      <Citation number={194} />
      watching it unfold in real time on television.
      <Citation number={195} />
      He didn’t recall hearing Chief Sund’s voice on the call, but said “we were trying to get... what we call the operational sight picture. What is going on? How big is the crowd? How violent is the crowd? ...They started laying out really the—just how bad it was.”
      <Citation number={196} />
      Secretary McCarthy resolved to run to the office of Acting Secretary of Defense Miller, leaving behind instructions to General Piatt to “find out the requirements,” as he was “going to get the authority.”
      <Citation number={197} />
      “[W]e go zipping down there,” General McConville said.
      <Citation number={198} />
      As they were leaving, General Flynn showed up.
      <Citation number={199} />
    </Typography>

    <Typography className="indent">
      General Flynn said, “when I came by the phone,” he “heard voices screaming on the end.”
      <Citation number={200} />
      He called the tones of their voices as “chaotic”
      <Citation number={201} />
      and that “[y]ou couldn’t tell who was talking sometimes.”
      <Citation number={202} />
      Chief Sund was pleading for help. “I want to say he even used the word, like, ‘I am pleading,’” Chief Contee said.
      <Citation number={203} />
      Col. Matthews, listening in beside Major General Walker, said of Chief Sund: “His voice was cracking. He was almost crying.”
      <Citation number={204} />
    </Typography>

    <Typography className="indent">
      According to Chief Contee, the reaction to his pleas was “tepid.”
      <Citation number={205} />
      “It was a very sluggish response,” Chief Contee said.
      <Citation number={206} />
      “I remember just, you know, with all that was going on, not hearing a ‘yes,’ you know, just... what I would in my mind qualify as, like, excuses and not decisive action... I was hearing, like, all the reasons, you know, why we shouldn't be doing this.”
      <Citation number={207} />
      Director Rodriguez called it a “kind of bureaucratic” response in the midst of “a rapidly evolving situation where literally the Capitol was being overrun.”
      <Citation number={208} />
      He added: “I don't want to use the word disinterested, but more just, ‘let’s just hold on. Let’s just wait. Let’s just kind of calm down for a second while literally Rome is burning.’”
      <Citation number={209} />
    </Typography>

    <Typography className="indent">
      For his part, General Flynn depicted General Piatt—the main interlocutor—as “the calming voice in an otherwise chaotic situation.”
      <Citation number={210} />
      General McConville agreed: “I talked to some of my staff, and they said that General Piatt did an incredible job. He was like the—you know, in a very calm [voice], just saying, ‘let’s just settle....’”
      <Citation number={211} />
    </Typography>

    <Typography className="indent">
      But Major General Walker said he “just couldn't believe nobody was saying: ‘Hey, go.’”
      <Citation number={212} />
      He asked the generals on the other line, “‘Aren't you watching the news? Can't you see what's going on? We need to get there.’ And [I was] cognizant of the fact that I'm talking to senior... people, but I could see what was happening....”
      <Citation number={213} />
      Chief Sund was “perplexed” and “dumbfounded.”
      <Citation number={214} />
      “It wasn’t what I expected of, yeah, the cavalry's coming. It was a bunch of, round-the-house, oh, hey, let’s do this, let’s do that,” he said.
      <Citation number={215} />
      “I was borderline getting pretty pissed off.”
      <Citation number={216} />
    </Typography>

    <Typography className="indent">
      Many participants on the call say General Piatt’s stated concern was the optics of sending troops to the site of a democratic process.
    </Typography>

    <Typography className="indent">
      “[T]he infamous talk about optics. That came up again. There was talk about boots on the ground again. You know, that's not good optics, having boots on the ground,” Chief Contee said.
      <Citation number={217} />
      He recalled how Secretary McCarthy had vocalized the same hesitance during the five-day deliberation preceding January 6th.
      <Citation number={218} />
    </Typography>

    <Typography className="indent">
      Director Rodriguez believed General Piatt replied to the request by saying, “[W]e don’t like the optics of having military personnel at the Capitol against peaceful protesters.”
      <Citation number={219} />
    </Typography>

    <Typography className="indent">
      He recalls Chief Contee replying bluntly, “[W]ell, they're not peaceful anymore.”
      <Citation number={220} />
    </Typography>

    <Typography className="indent">
      Major General Walker heard one of the Army generals say it “wouldn’t be their best military advice or guidance to suggest to the Secretary that we have uniformed presence at the Capitol.”
      <Citation number={221} />
      He added: “They were concerned about how it would look, the optics.”
      <Citation number={222} />
      Chief Sund heard the general use the word twice.
      <Citation number={223} />
      “General Piatt said—and I will never forget this—‘Yeah, I don't know. I'm concerned about the optics of the National Guard standing a line with the Capitol in the background,’” Chief Sund said.
      <Citation number={224} />
      “[M]y officers are getting beaten, and they're worried about the optics of the National Guard.”
      <Citation number={225} />
    </Typography>

    <Typography className="indent">
      Although General Piatt denies explicitly using the word “optics”—“I don't recall ever saying that word on that phone call, because at the time it just wasn't important”
      <Citation number={226} />
      —he agreed that he said use of the Guard was “not my best military judgment or my best military advice.”
      <Citation number={227} />
      He said he “made a couple of suggestions that were not well-received,”
      <Citation number={228} />
      including “if there was any other facility where we could go and relieve police... I think they took that as I was saying no, because they immediately came back and said, you’re denying our request.”
      <Citation number={229} />
    </Typography>

    <Typography className="indent">
      General McConville—who wasn’t present at the time—said he talked to people in the room about the use of the word “optics,” and “some people said, ‘No, it wasn’t said.’ And then some people said it was said.”
      <Citation number={230} />
      Nonetheless, the sentiment behind it should not be a particularly controversial one, according to General McConville. “People like to use optics—I'm going to stay away because that's a political term in my eyes. But what type of signature do you want on the streets in Washington, DC? Do you want a police signature? Do you want a military signature? Do you want a Federal signature?” he said.
      <Citation number={231} />
      “[T]hat creates a reaction from the American people, and we need to think our way through that...”
      <Citation number={232} />
    </Typography>

    <Typography className="indent">
      On the call, talk of needing a plan—the so-called con-op that had been a lesson learned from the summer—emerged. “[A]fter the optics, ...then it was, you know, they wanted, like, specific information. There was something they were talking about, like, mission and... what exactly they're going to be doing when they get there,” Chief Contee said.
      <Citation number={233} />
      Before running off, Secretary McCarthy had instructed General McConville to put together a plan: “My charter, my direction from him is to get a plan. We’re gonna support; I just wanted to get something to support with.”
      <Citation number={234} />
      But he acknowledged the impression nonetheless remained that he was “denying or pushing back.”
      <Citation number={235} />
    </Typography>

    <Typography className="indent">
      That impression was made explicit: “They said three times to me clearly, ‘You’re denying my request,’” General Piatt said.
      <Citation number={236} />
    </Typography>

    <Typography className="indent">
      Chief Contee interrupted “Chief Sund mid-sentence” and said, “Wait a minute. Hold up. Let me make sure that I understand this correctly... [A]re you asking for support from the National Guard at the U.S. Capitol?”
      <Citation number={237} />
    </Typography>

    <Typography className="indent">
      Chief Sund said, “Yes.”
      <Citation number={238} />
    </Typography>

    <Typography className="indent">
      Chief Contee then addressed the Army generals: “‘Are you guys honoring his request?’ I asked them that. And they didn't say ‘no,’ but they also didn't say ‘yes.’”
      <Citation number={239} />
      Chief Sund recalled it the same way.
      <Citation number={240} />
    </Typography>

    <Typography className="indent">
      General Piatt said he was “clear in my response, ‘I don't have any authority to deny or approve. The Secretary is getting approval.’”
      <Citation number={241} />
    </Typography>

    <Typography className="indent">
      “[T]he third time when they said, ‘You're denying our request,’ they also said, ‘And we're going to go to the media,’” General Piatt said.
      <Citation number={242} />
      “[W]e were desperate. Everyone was desperate. So I’m not angry at that, but I just knew it wasn’t helpful, so we told that to Secretary McCarthy.”
      <Citation number={243} />
    </Typography>

    <Typography className="indent">
      As for the threat to go public, Major General Walker said, “I remember that very clearly.”
      <Citation number={244} />
      According to him, after the generals would not say yes or no, “Chief Contee says: ‘I’m going to call the mayor and ask her to have a press conference saying that the Army is not going to allow the DC Guard to come and support.’”
      <Citation number={245} />
      General Piatt reportedly replied, “Please don't do that. I don't have the authority to authorize the National Guard to go. So please don't do that. Please don't hold the press conference.”
      <Citation number={246} />
      Chief Contee doesn’t recall saying that.
      <Citation number={247} />
      Mayor Bowser doesn’t know if she was still on the line when the remark was made, but “it was certainly going to be something that I would do.”
      <Citation number={248} />
    </Typography>

    <Typography className="indent">
      In the end, “the call sort of ended very abruptly,....”
      <Citation number={249} />
      The DC head of homeland security and emergency management left the call thinking “that help was not coming, and—at least [not] from the National Guard.”
      <Citation number={250} />
      That was Chief Sund’s belief, too. “[I]f a general says his troops are not coming, his troops aren't coming,” he said.
      <Citation number={251} />
    </Typography>

    <Typography className="indent">
      Before the call ended, General Flynn set up a video-conferencing bridge. General Piatt explained to the Select Committee that this was meant “to get the principals and the team together to start making a plan.”
      <Citation number={252} />
      But Major General Walker—under whom “it was actually written... would maintain control of National Guard forces”
      <Citation number={253} />
      —said he was not privy to any planning while on the call.
    </Typography>

    <Typography className="indent">
      “We were just told to hold,” he said.
      <Citation number={254} />
    </Typography>

    <Typography className="indent">
      How long did Major General Walker hold?
    </Typography>

    <Typography className="indent">
      “Three hours and 19 minutes,” he said.
      <Citation number={255} />
    </Typography>

    <Typography className="indent">
      Major General Walker told the Select Committee regarding what occurred during this time. “[W]e all thought, it’s in a minute, we're going to be told to go, in a minute. Then 5 minutes, then 10 minutes, then 15 minutes. We kept thinking, any minute now, somebody is going to say ‘go,’” he said.
      <Citation number={256} />
      “And then an hour went by, then more time went by.... But we never thought it would take that long.”
      <Citation number={257} />
      Col. Matthews confirmed that there were periods on the call when no one was talking.
      <Citation number={258} />
      At times, there was talk of securing buildings other than the Capitol.
      <Citation number={259} />
      He called the open channel essentially “a general officer chat line.”
      <Citation number={260} />
    </Typography>

    <Typography className="indent">
      What did Major General Walker think was happening in those 3 hours and 19 minutes?
    </Typography>

    <Typography className="indent">
      “Delay.”
      <Citation number={261} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        An Absent Commander-in-Chief
      </Typography>
    </Box>

    <Typography className="indent">
      Vice President Mike Pence called several times to check in on the delayed response of the Guard. President Trump did not.
    </Typography>

    <Typography className="indent">
      Vice President Pence called Acting Secretary Miller at least two times.
      <Citation number={262} />
      “He was very animated, and he issued very explicit, very direct, unambiguous orders. There was no question about that,” General Milley said.
      <Citation number={263} />
      “And he said, ‘Get the National Guard down here. Get them down here now, and clear the Capitol.’ You know, and this is the Vice President of the United States. And there was other forceful language.’”
      <Citation number={264} />
    </Typography>

    <Typography className="indent">
      Acting Secretary Miller clarified that “he did not order me,” as “he’s not in the chain of command,” but he considered the talk with the Vice President “[h]yper professional” and “[v]ery focused,” in which the secretary “highlighted that District of Columbia National Guard... was activated, and we were throwing every asset we could marshal to support law enforcement.”
      <Citation number={265} />
    </Typography>

    <Typography className="indent">
      In contrast, according to General Milley, Chief of Staff Meadows called and said, “‘We have to kill the narrative that the Vice President is making all the decisions. We need to establish the narrative, you know, that the President is still in charge and that things are steady or stable,’ or words to that effect. I immediately interpreted that as politics, politics, politics.”
      <Citation number={266} />
    </Typography>

    <Typography className="indent">
      President Trump himself did not call. As reports of Departments of Defense denials and delay were echoing in the media, no high-level Defense official—including Secretaries Miller
      <Citation number={267} />
      and McCarthy
      <Citation number={268} />
      —received a call from him that day.
      <Citation number={269} />
      At the time, General Milley thought that was “absolutely... highly unusual.”
      <Citation number={270} />
    </Typography>

    <Typography className="indent">
      “[Y]ou're the Commander in Chief. You've got an assault going on on the Capitol of the United States of America, and there's nothing? No call? Nothing? Zero?” he said.
      <Citation number={271} />
      “I grew up in an organization where commanders are responsible and take charge and they see situations unfolding and they issue orders and take charge.”
      <Citation number={272} />
    </Typography>

    <Typography className="indent">
      On January 3rd, at the end of a national security meeting concerning a foreign threat, the President asked “in passing” about January 6th preparations.
      <Citation number={273} />
      Acting Secretary Miller informed him they would be fulfilling Mayor Bowser’s request for DC Guard support.
      <Citation number={274} />
      From then on, if not earlier, the secretary “felt like I had all the authorities I needed and did not need to discuss anything with the President regarding authorities.”
      <Citation number={275} />
      The conversation lasted all of 30 seconds to a minute.
      <Citation number={276} />
      Secretary Miller testified that he never received any order at any time from President Trump to deploy the National Guard on January 6th. “There was no direct—there was no order from the President,” he said.
      <Citation number={277} />
    </Typography>

    <Typography className="indent">
      On January 5th, as demonstrators rallied in support of the President, Acting Secretary Miller received a call from the commander-in-chief.
      <Citation number={278} />
      The President asked him if he was watching the events on television.
      <Citation number={279} />
      The secretary told him he had caught some of the coverage.
      <Citation number={280} />
    </Typography>

    <Typography className="indent">
      Unprompted, President Trump then said, “You’re going to need 10,000 people” the following day, as in troops.
      <Citation number={281} />
      An email sent by Chief of Staff Meadows on January 5th explicitly noted that the DC Guard would be on hand to “protect pro Trump people.”
      <Citation number={282} />
      The President and his staff appeared to be aware of the likelihood of violence on the day the election certification of his loss was slated to transpire. This communication from President Trump contemplated that the Guard could support and secure the safety of Trump supporters, not protect the Capitol. At that time, Secretary Miller apparently had no information on what President Trump planned for January 6th.
    </Typography>

    <Typography className="indent">
      Acting Secretary Miller thought the 10,000 number was astronomical— “we expected 35,000 protesters... [and] even if there were more protesters than expected, [we thought] that local law enforcement could handle it”
      <Citation number={283} />
      —but, again, this was “no order from the President,” just “President Trump banter that you all are familiar with.”
      <Citation number={284} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        Parallel Plans in the Midst of Crisis
      </Typography>
    </Box>

    <Typography className="indent">
      While the Army and the District engaged in the “heated”
      <Citation number={285} /> 
      2:30 p.m. phone call, Secretary McCarthy was hurrying down the Pentagon hallways to Acting Secretary Miller’s office. General Milley had been summoned there before Secretary McCarthy arrived with General McConville in tow,
      <Citation number={286} />
      “running down the hall, and he was actually winded when he showed up...”
      <Citation number={287} />
    </Typography>

    <Typography className="indent">
      The next half hour was spent in “a quick, rapid fire meeting, [with] lots of quick questions.”
      <Citation number={288} />
      Secretary McCarthy—out of breath—said he started by saying, “We've got to go. We've got to get something—we've got to put every capability we can up there.”
      <Citation number={289} />
      The response he received was, “They were all kind of, like, ‘Slow down. What's going on?’ They wanted to get a sense of the situation.”
      <Citation number={290} />
      Secretary McCarthy said it took about 15 to 20 minutes to “relay this,” “laying out what I thought we needed to do.”
      <Citation number={291} />
    </Typography>

    <Typography className="indent">
      By 3:04 p.m., Acting Secretary Miller said he approved deployment of the DC Guard to assist law enforcement at the Capitol at that time.
      <Citation number={292} />
      Acting Secretary Miller did not understand why Major General Walker—if he felt the exigency demanded it—did not deploy troops as soon as his 3 p.m. order allowed it. “Why didn’t he launch them? I'd love to know,” he said.
      <Citation number={293} />
    </Typography>

    <Typography className="indent">
      Secretary McCarthy agreed <strong><i>“that’s where we may have talked past each other in his office,”</i></strong> because Secretary McCarthy thought he “had the authority as the Secretary of the Army” to conduct a mission analysis and send troops at his discretion, not that of Major General Walker.
      <Citation number={294} />
    </Typography>

    <Typography className="indent">
      Major General Walker himself understood he had to wait for approval from Secretary McCarthy to deploy his forces. <strong><i>But as he waited on that video call for hours, he did strongly consider sending them anyway</i></strong>. He turned to his lawyer and said, “Hey, you know what? You know, we’re going to go, and I'm just going to shoulder the responsibility.”
      <Citation number={295} />
      According to Major General Walker, his lawyer responded, “What if you get sued?”
      <Citation number={296} />
      Colonel Mathews, that lawyer, “told him not to do that. Just hold on.”
      <Citation number={297} />
      The Guard officials located with Major General Walker at the Armory all say he seriously contemplated aloud the possibility of breaking with the chain of command.
    </Typography>

    <Typography className="indent">
      “Should we just deploy now and resign tomorrow?” was how Lieutenant Nick recalled Major General Walker bluntly putting it.
      <Citation number={298} />
    </Typography>

    <Typography className="indent">
      “I would have done just that,” Major General Walker said, “but not for those two letters”
      <Citation number={299} />
      from his superiors curtailing Guard redeployment.
      <Citation number={300} />
    </Typography>

    <Typography className="indent">
      The man who signed one of the letters, however—himself a former member of the DC Guard
      <Citation number={301} />
      —now says Major General Walker should have moved forward regardless of whether he had proper authorization.
    </Typography>

    <Typography className="indent">
      “I've launched QRF without approval more than once,” Acting Secretary Miller said.
      <Citation number={302} />
      “If you’re the person on the ground in the Army, and you realize that there’s something that is unpredictable or unexpected and you have the ability to influence it, the culture, the training, the education, the expectation of you, the American people, is that you will execute and do what you can, even if it costs you your job.”
      <Citation number={303} />
    </Typography>

    <Typography className="indent">
      After authorization at 3:04 p.m., Secretary McCarthy said he gave Major General Walker a call. He told him to “[m]obilize the entire Guard, bring everybody in.... And I said, you know, move the QRF to the armory and get as many people as you can to the armory and configure them in a minimum of riot gear and batons. And then we’re going to do a mission analysis of what we need to do with the police....”
      <Citation number={304} />
      Major General Walker “categorically denies” that any such call took place.
      <Citation number={305} />
      In fact, Major General Walker said the two men did not talk at all until much later that night.
      <Citation number={306} />
      “Here’s the bottom line. The Secretary was unavailable to me, and he never called me,” Major General Walker said.
      <Citation number={307} />
    </Typography>

    <Typography className="indent">
      Beginning around 3:00 p.m., 25 minutes of Secretary McCarthy’s time was spent reassuring members of Congress that the Guard was indeed coming,
      <Citation number={308} />
      although he had not yet conveyed the order. That was time unspent on facilitating their actual coming. In addition to the alleged threat on the 2:30 p.m. call, a media tweet had gone out at 2:55 p.m. declaring that the Department of Defense had denied requests for Guard support.
      <Citation number={309} />
    </Typography>

    <Typography className="indent">
      By 3:45 p.m., Secretary McCarthy was done with his calls and—after picking up some things from his office—headed down to the MPD headquarters to draft a con-op beside law enforcement.
      <Citation number={310} />
      Acting Secretary Miller arrived at 4:10 p.m.
      <Citation number={311} />
    </Typography>

    <Typography className="indent">
      While he was waiting, Colonel Hunter decided he would keep the first shift handling traffic control out at their posts in case they were needed for re-mission by the MPD, relying instead only on the QRF and the second shift at the Armory to respond to the Capitol.
      <Citation number={312} />
      Those servicemembers ended up manning their traffic control posts up to 20 hours straight.
      <Citation number={313} />
      Army leadership never found out that the servicemembers at the traffic control posts didn’t end up responding to the Capitol that night, incorrectly crediting some of the Guard’s delay that day to their travel time.
      <Citation number={314} />
    </Typography>

    <Typography className="indent">
      By 3:50 p.m., the QRF had arrived at the Armory, bringing their own equipment, given no new information upon making the extra pit stop there instead of the Capitol.
      <Citation number={315} />
      They were ready to go, steeped in “a lot of nerves.”
      <Citation number={316} />
      The second shift of servicemembers originally missioned for traffic control had been told as early as 2:30 p.m. to expect a switch in mission to handling civil disturbance.
      <Citation number={317} />
      They rushed to gear up and prepare, but it was a lot of “hurry up and wait.”
      <Citation number={318} />
      Not long afterward, “we’re all ready. Now we’re all donned. So go sit on the bleachers and wait... We were in a tight holding pattern until the time to deploy.”
      <Citation number={319} />
    </Typography>

    <Typography className="indent">
      At around the same time, at 3:49 p.m., Speaker Pelosi is heard in video footage from that day urging Acting Secretary Miller to hurry.
    </Typography>

    <Typography className="indent">
      “Just pretend for a moment this was the Pentagon or the White House or some other entity that was under siege,” she told him over the phone while she—and the rest of the Congressional leadership—were huddled in a secure location.
      <Citation number={320} /> “Just get them there!”
      <Citation number={321} />
    </Typography>

    <Typography className="indent">
      When Secretary McCarthy arrived at MPD headquarters, he joined Chief Contee, his Army Operations Director Brigadier General Chris LaNeve, and Assistant Chief Carroll by phone.
      <Citation number={322} />
    </Typography>

    <Typography className="indent">
      In the next 20 minutes, Secretary McCarthy developed a con-op.
    </Typography>

    <Typography className="indent">
      As Secretary McCarthy had decided after the summer, crafting a strategy was his job—“I was doing it with the Mayor, the police chief, and the deputy director of the FBI, my counterparts, and then ultimately wanted to understand what our role would be, the conditions”—and afterward, “we turned to [Major General Walker] to work the tactical details for that.”
      <Citation number={323} />
    </Typography>

    <Typography className="indent">
      But Major General Walker said, “If I need you to tell me how to execute a civil disturbance mission,” he “[s]hould relieve me. Should fire me.”
      <Citation number={324} />
    </Typography>

    <Typography className="indent">
      It wasn’t until later, post-January 6th, that Major General Walker said he found out that Secretary McCarthy, his boss, had been putting together a con-op—without him. “Then later they said they had to put together a plan for me to execute... which I found kind of disturbing,” Major General Walker said.
      <Citation number={325} />
      “You're coming up with a plan without me being involved in the plan?”
      <Citation number={326} />
    </Typography>

    <Typography className="indent">
      General McConville agreed that “usually[,] the Secretary of Army is not developing concepts for the employment, but because of the situation that wasn’t done,” so the secretary had to fill in the gap.
      <Citation number={327} />
      That, of course— given the preparations Colonel Hunter had laid out hours earlier—was not true. But Secretary McCarthy did not know that.
      <Citation number={328} />
    </Typography>

    <Typography className="indent">
      He said Major General Walker never told him about how Colonel Hunter had prepared and that it was his responsibility to tell him.
      <Citation number={329} />
      “I don't talk to troop lead commanders, no,” he said.
      <Citation number={330} />
    </Typography>

    <Typography className="indent">
      Ultimately, no plan from Army leaders—strategic or tactical—made it to the troops.
    </Typography>

    <Typography className="indent">
      “[I]f they came up with a plan, they never shared it with us,” Major General Walker said.
      <Citation number={331} />
      <strong><i>“They claim they were putting a plan together. That’s what took so long.</i></strong> I never saw a plan from the Department of Defense or the Department of the Army.”
      <Citation number={332} />
    </Typography>

    <Typography className="indent">
      Colonel Hunter agreed that “[n]o one ever told me, because I already had the plan there, and no one ever informed me that there was a different plan or a different [con-op].”
      <Citation number={333} />
      He said to the extent a “hasty plan” was put into action on January 6th, it was his: “I created the concept of operation.”
      <Citation number={334} />
      He added: “The [plan] that was actually used as far as which lot they would come into, who would meet them at the lot, and then who would lead them over to the Capitol. That was between myself and MPD and Capitol Police.”
      <Citation number={335} />
    </Typography>

    <Typography className="indent">
      After hours of wait, Major General Walker said, “The plan was executed just like we said it would be [from the start], get to the Capitol, take direction from the ranking police officers there... to help restore order.”
      <Citation number={336} />
      Colonel Hunter passed the details of his hasty plan onto Lt. Col. Reinke— the highest ranking officer at the rally point—letting him know, “Hey, when you pull into this lot, they will meet you there. This is who is—you know, you’re going with these personnel,” exactly what Secretary McCarthy had just spent 20 minutes putting together.
      <Citation number={337} />
    </Typography>

    <Typography className="indent">
      Although Lt. Col. Reinke said his QRF servicemembers were given rules of engagement before arriving at the rally point, he was not told more than report to Capitol Police and supplement and assist them.
      <Citation number={338} />
    </Typography>

    <Typography className="indent">
      Captain Tarp, outranked by Lt. Col. Reinke but in charge of the second shift, was merely told by Brigadier General Ryan: “‘You need to act like there’s a fire now. You’re going to [the] Capitol.’ Those were his directions.”
      <Citation number={339} />
    </Typography>

    <Typography className="indent">
      <strong><i>After an hour and a half spent in calls, travel, and making plans, Secretary McCarthy was prepared to green light the deployment of the Guard at 4:35 p.m.</i></strong> But miscommunication led to another half-hour delay.
    </Typography>

    <Typography className="indent">
      Secretary McCarthy relayed the “go” order to Major General Walker— with his subordinate Brigadier General LaNeve serving as the intermediary—in a conversation Major General Walker said never happened.
    </Typography>

    <Typography className="indent">
      According to Secretary McCarthy, Brigadier General LaNeve “wasn’t a junior aide.”
      <Citation number={340} />
      In his role, “he can speak, once given the authority, delegated authority to speak as the Sec Army...”
      <Citation number={341} />
      He said that the first-star officer “was standing next to me,”
      <Citation number={342} />
      and General Piatt said that it was generally “not uncommon” for him to ask his staff to “transmit [the] communication from the Secretary to General Walker.”
      <Citation number={343} />
    </Typography>

    <Typography className="indent">
      For his part, Brigadier General LaNeve denies that he himself conveyed the “go” order.
      <Citation number={344} />
      He said he spoke with Major General Walker first at 4:25 p.m. to tell him that his forces should, “Get on the bus, do not leave.”
      <Citation number={345} />
      On a second call at 4:35 p.m., Brigadier General LaNeve said he overheard Secretary McCarthy himself convey the “go” order to Major General Walker: He said something “to the effect of ‘You’re approved to provide support.’”
      <Citation number={346} />
      Secretary McCarthy, on his part, said he never spoke a word.
      <Citation number={347} />
    </Typography>

    <Typography className="indent">
      Brigadier General LaNeve said the secretary then again handed him the phone to convey the details of “where to go and what officer to meet up with.”
      <Citation number={348} />
      Those two details would be the full extent of the “con-op” allegedly communicated to Major General Walker.
      <Citation number={349} />
      He even recalled Major General Walker saying, “Roger,” to acknowledge the plan.
      <Citation number={350} />
      But, Brigadier General LaNeve said, there was “mass confusion in that room,” and he agreed that “[t]here were huge communications problems.”
      <Citation number={351} />
    </Typography>

    <Typography className="indent">
      Major General Walker said there was no such call, nor any like it.
      <Citation number={352} />
      He said he remained on the video conference line the whole time “with everybody else,” he said.
      <Citation number={353} />
      He said he would not have taken an order from Brigadier General LaNeve anyhow. “[W]hy would I ever take directions from General LaNeve? Anybody? Brigadier General LaNeve, one-star,” Major General Walker said.
      <Citation number={354} />
      “I mean, he’s not a peer, it wouldn’t be somebody that would convey that type of message to me... So my thinking wouldn’t have been that he would have been speaking on behalf of the Secretary.”
      <Citation number={355} />
    </Typography>

    <Typography className="indent">
      Although his staff confirms they didn’t see him field a call from Secretary McCarthy or Brigadier General LaNeve,
      <Citation number={356} />
      including never seeing him leave their conference room,
      <Citation number={357} />
      Major General Walker’s own note taker appears to have jotted down at 4:37 p.m. the following: “advised to sent [sic] 150 to establish D st / 1st outer perimeter, General LaNeve,” beside what appears to be his phone number.
      <Citation number={358} />
      That address is the rally point Secretary McCarthy had asked General LaNeve to convey, the same one Colonel Hunter and law enforcement had already chosen earlier. Major General Walker said, “the only way [Lt. Nick] could have got it was listening to the VTC, which I was on.”
      <Citation number={359} />
      He further said, “I never saw General LaNeve on the [video teleconference]... I didn’t hear General LaNeve’s voice.”
      <Citation number={360} />
      Lt. Nick said he had it penned at much later—at 5:09 p.m.—“as the time they received the orders” to deploy.
      <Citation number={361} />
    </Typography>

    <Typography className="indent">
      Major General Walker certainly did not <i>act</i> as if he had been given authority until, fortuitously, General McConville—who had heard about the 4:35 p.m. call—walked by the teleconference screen and was “surprised” to see the commanding general sitting idly at 5:09 p.m.
      <Citation number={362} />
    </Typography>

    <Typography className="indent">
      Major General Walker agreed the first time he heard he had the authority was from the lips of the general: “General McConville came back into the call and said, Hey, you’re a go.”
      <Citation number={363} />
    </Typography>

    <Typography className="indent">
      Lt. Col. Reinke’s QRF and Captain Tarp’s second shift got on the bus at 5:10 p.m.
      <Citation number={364} />
      They left at 5:15 p.m.
      <Citation number={365} />
      Lt. Col. Reinke said they didn’t arrive at the Capitol Police parking lot until 5:55 p.m.,
      <Citation number={366} />
      although official timing from the Army and Department of Defense put their arrival time at 5:40 p.m. and from the DC Guard at 5:20 p.m.
      <Citation number={367} />
      At the earliest, the troops arrived in the vicinity of the Capitol grounds at 5:29 p.m., when Lt. Col. Reinke texted Colonel Hunter: “Apparently we pulled into the wrong lot, trying to reroute to LOT 16 now.”
      <Citation number={368} />
      He said they sat around for 20 minutes once they arrived, and then were sworn in, before relieving an entire line of officers.
      <Citation number={369} />
      Captain Tarp said they remained idle for 45 minutes waiting for Capitol Police to come “bus by bus to swear-in the officers. It was a long wait. Frustrating—we’re sitting a mile from where we[’re] going.”
      <Citation number={370} />
    </Typography>

    <Typography className="indent">
      Captain Tarp said, “By the time we got there, we were just holding back the people who remained past the curfew.”
      <Citation number={371} />
      The height of the riot had passed.
    </Typography>

    <Typography className="indent">
      Colonel Hunter estimated that—had his preparations been approved— the DC Guard could have arrived as early as an hour and a half earlier than they did.
    </Typography>

    <Typography className="indent">
      “Within one hour, I'd say I could’ve had 135. So the [about 40] coming from Joint Base Andrews, if they would’ve headed directly to me at the Capitol, and then the 90 I had on the street and the 4 that were—including myself,” he said.
      <Citation number={372} />
      “[S]o I arrived at the Capitol at 3:10. So, if I would’ve recalled everyone by 3:30, 3:40, we could've been—had gear on and walking towards the Capitol.”
      <Citation number={373} />
    </Typography>

    <Typography className="indent">
      He further stated: “I would give them another hour. So by 4:40 I should've had at least 250 coming from the Armory... That includes the second shift as well as full-timers.”
      <Citation number={374} />
    </Typography>

    <Typography className="indent">
      Presented with the plans Colonel Hunter had set in motion and the easy accessibility of their equipment, neither of which he had known about at the time, Secretary McCarthy conceded “you could have shaved minutes,”
      <Citation number={375} />
      and the speed of deployment “could have” been pushed up, but “[i]t depends.”
      <Citation number={376} />
    </Typography>

    <Typography className="indent">
      When the Guard finally arrived at the Capitol, “pretty much all the other fighting, per se, had stopped on the Capitol complex,” according to Robert Glover, head of the MPD Special Operations Division.
      <Citation number={377} />
      Then-Inspector Glover received the Guard troops when they arrived.
      <Citation number={378} />
      “[T]he bus just kind of showed up. It was my decision at that point, looking at their numbers and their capabilities at that moment in time and what was the most pressing activity—and that was to make the arrests,” he said.
      <Citation number={379} />
      He had them create a secure “prisoner cordon” where they could stand guard as arrested individuals waited transport to jail.
      <Citation number={380} />
      “They were the freshest personnel that we had at that moment in time. And, again, they didn't have any significant numbers to really do much else at that moment in time either,” he said.
      <Citation number={381} />
      “[T]heir orders were basically, support us in whatever we told them to do...”
      <Citation number={382} />
    </Typography>

    <Typography className="indent">
      Secretary McCarthy said that it was possible that DOD and DC National Guard leaders had simply not been coordinating their planning.
      <Citation number={383} />
      He acknowledged that “a lot of things were probably missed. It was tremendously confusing,”
      <Citation number={384} />
      and “that makes for a messy response.”
      <Citation number={385} />
    </Typography>

    <Typography className="indent">
      No one within the Department of Defense, Army, or Guard leveled accusations of an intentional delay. “I didn't see anybody trying to throw sand in the gearbox and slow things down,” General Milley said.
      <Citation number={386} />
    </Typography>

    <Typography className="indent">
      Major General Walker said the Army’s reluctance to approve National Guard assistance to the Mayor during the planning for the anticipated January 6th events continued through January 6th itself.
      <Citation number={387} />
      “I don’t know where the decision paralysis came from, but it was clearly there. The decision paralysis, decision avoidance,” he said.
      <Citation number={388} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h2" color="primary">
        CONCLUSION
      </Typography>
    </Box>

    <Typography className="indent">
      Former President Trump’s eagerness to engage the U.S. military to play a visible role in addressing domestic unrest during the late spring and summer of 2020 does appear to have prompted senior military leadership to take precautions, in preparing for the joint session, against the possibility that the DC Guard might be ordered to deploy for an improper purpose. Those precautions seem to have been prudential as much as legal in nature.
    </Typography>

    <Typography className="indent">
      What that entailed in the unprecedented circumstances of the January 6th attack on the Capitol is, however, harder to accept: a 3 hour and 19 minute lag-time in making a relatively small, but riot-trained and highly capable military unit available to conduct one of its statutory support missions.
    </Typography>

    <Typography className="indent">
      While the delay seems unnecessary and unacceptable, it was the byproduct of military processes, institutional caution, and a revised deployment approval process. We have no evidence that the delay was intentional. Likewise, it appears that none of the individuals involved understood what President Trump planned for January 6th, and how he would behave during the violence. Imperfect inter-government and intra-military communications as the January 6th rally morphed, with President Trump’s active encouragement, into a full-blown riot at the Capitol also help explain the time it took to deploy Guard troops to the Capitol after their assistance there was requested and approved. Post-hoc evaluation of real-time communications during an unprecedented and evolving crisis and limited tactical intelligence, nevertheless, carries the risk of a precision that was unrealistic at the time. It is also clear from testimony provided to the Select Committee that DoD and DC National Guard leaders have differing perspectives that are not reconcilable regarding the timing of deployment authorization.
    </Typography>

    <Typography className="indent">
      Where the DC Guard’s deployment on January 6th is concerned, then, the “lessons learned” at this juncture include: careful evaluation on the basis of limited information may take time; statutorily constrained intergovernmental requests for assistance and multi-level approval processes are complex and may be time-consuming; any visible military presence in the domestic setting is circumscribed by law and triggers considerable, constitutionally-driven sensitivities; and crisis communications are often imperfect, especially in unforeseen and rapidly evolving situations.
    </Typography>
  </Page>
)
