import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet8'));

export const Chapter66 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      As explained above, a constellation of far-right characters came together in late 2020 as part of the “Stop the Steal” cause. Among them was Roger Stone, a right-wing political operative whose career as a self-trumpeted dirty trickster stretched back decades. Stone is arguably President Trump’s oldest political advisor.
      <Citation number={222} />
      For example, he worked for Donald Trump’s independent presidential bid during the 2000 campaign.
      <Citation number={223} />
      In addition to his political connections, Stone cultivated relationships with far-right extremists, including the two groups charged with seditious conspiracy: the Oath Keepers and the Proud Boys.
    </Typography>

    <Image
      src="/images/image-083.jpg"
      alt='Roger Stone in front of the Supreme Court on January 5, 2021 in Washington, DC.'
      caption='Roger Stone in front of the Supreme Court on January 5, 2021 in Washington, DC.'
      credit="(Photo by Tasos Katopodis/Getty Images)"
    />

    <Typography className="indent">
      The Select Committee found that at least seven members of the Oath Keepers provided security for Stone, or were seen with him, in the weeks immediately preceding the attack on the U.S. Capitol.
      <Citation number={224} />
      Text messages released by Edward Vallejo, an Oath Keeper charged with seditious conspiracy and other crimes, show that Stewart Rhodes and Kelly Meggs discussed providing security for Stone.
      <Citation number={225} />
      Some of these Oath Keepers guarded Stone during an event at Freedom Plaza in Washington, DC on the night of January 5th.
      <Citation number={226} />
      Stone was also flanked by Oath Keepers outside of the Willard Hotel on the morning of January 6th.
      <Citation number={227} />
      One of the Oath Keepers who provided security for Stone was Joshua James, who pleaded guilty to seditious conspiracy, obstruction of Congress and other charges in March 2022.
      <Citation number={228} />
      James was also reportedly seen in Stone’s hotel room at the Willard hours before the attack on the U.S. Capitol.
      <Citation number={229} />
    </Typography>

    <Typography className="indent">
      Stone has a longstanding, close relationship with the Proud Boys. Stone has taken the Proud Boys oath
      <Citation number={230} />
      and repeatedly defended the group.
      <Citation number={231} />
      Danish documentarians filmed him working with Proud Boys for years.
      <Citation number={232} />
      In one scene, filmed in 2019, Stone warmly greets Joe Biggs, a Proud Boys leader central to the Capitol violence. Stone says of Biggs: “My guy, right here.”
      <Citation number={233} />
      In a 2019 court case, Stone identified Enrique Tarrio as one of his volunteers, explaining that Tarrio had access to his phone and could post to Stone’s Instagram account from it.
      <Citation number={234} />
    </Typography>

    <Typography className="indent">
      As mentioned above, Stone, Tarrio and another Proud Boy leader, Ethan Nordean, addressed an impromptu rally in Washington, DC on the night of December 11, 2020. Owen Shroyer, an <i>InfoWars</i> host, was also with them.
      <Citation number={235} />
      “We will fight to the bitter end for an honest count of the 2020 election,” Stone told the crowd. “Never give up, never quit, never surrender, and fight for America!”
      <Citation number={236} />
      A few weeks later, on January 2, 2021, Tarrio led a Proud Boys protest outside of Senator Marco Rubio’s home in Florida. The Proud Boys wanted to convince Rubio to vote against certification of the vote on January 6th.
      <Citation number={237} />
      Stone reportedly called into the event to speak to Tarrio’s crowd.
      <Citation number={238} />
    </Typography>

    <Typography className="indent">
      One way in which Stone maintained these contacts was through a Signal chat group named “F.O.S.”—or Friends of Stone.
      <Citation number={239} />
      Two days after the election, Stone sent a text: “We provide information several times a day. So please monitor the F.O.S. feed so you can act in a timely fashion.”
      <Citation number={240} />
      Ali Alexander and Stone continued to coordinate about Stop the Steal strategy and events between the election and January 6th.
      <Citation number={241} />
      In addition to Alexander, Stone’s “Friends” on the Signal chat included Rhodes and Tarrio.
      <Citation number={242} />
    </Typography>

    <Typography className="indent">
      In July 2020, President Trump granted Stone clemency after he was convicted of lying to Congress and other charges.
      <Citation number={243} />
      Then, on December 23rd, President Trump pardoned Stone.
      <Citation number={244} />
      Several days later, at a dinner on the evening of December 27th, Stone thanked President Trump. In a post on Parler, Stone wrote that he “thanked President Trump in person tonight for pardoning me” and also recommended to the President that he “appoint a special counsel” to stop “those who are attempting to steal the 2020 election through voter fraud.” Stone also wrote that he wanted “to ensure that Donald Trump continues as our president.”
      <Citation number={245} />
      Finally, he added: “#StopTheSteal” and “#rogerstonedidnothingwrong.”
      <Citation number={246} />
      The Select Committee has learned that Stone discussed the January 6th event with the President, likely at this same dinner on December 27th.
      <Citation number={247} />
      The President told Stone he “was thinking of speaking.”
      <Citation number={248} />
    </Typography>

    <Typography className="indent">
      The Select Committee sought to question Roger Stone about his relationships with President Trump and far-right extremists, as well as other issues. During his deposition, Stone invoked his Fifth Amendment right nearly 90 times.
      <Citation number={249} /> Stone has publicly stated that he committed no wrongdoing and that he encouraged a peaceful protest.
      <Citation number={250} />
    </Typography>
  </Page>
)
