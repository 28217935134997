import { useMemo } from "react";

const defaultImageMetaTags = [
  <meta key={0} property="og:image" content={`${window.location.origin}/images/image-000.jpg`} />,
  <meta key={1} property="og:image:type" content="image/jpeg" />
];

export const useImageMetaTags = (children: any) => {
  const imageMetaTags = useMemo(() => {
    let index = 0;

    return (Array.isArray(children) ? children : [children])
      .filter(({ props }: any) => props?.src)
      .reduce((acc: any, { props }: any) => {
        acc.push(<meta key={index++} property="og:image" content={`${window.location.origin}${props.src}`} />)
        acc.push(<meta key={index++} property="og:image:type" content="image/jpeg" />)

        if (props.alt) {
          acc.push(<meta key={index++} property="og:image:alt" content={props.alt} />)
        }

        return acc;
      }, []);
  }, [children]);

  return imageMetaTags.length ? imageMetaTags : defaultImageMetaTags;
}
