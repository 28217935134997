import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet4'));

export const Chapter21 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
     	When Americans vote for a presidential candidate on election day, they are actually casting votes for that candidate’s proposed presidential electors to participate in the electoral college. After a State certifies its election results and announces a winner, it also issues a “certificate of ascertainment,” which contains the names of the duly chosen electoral college electors. The electors whose names appear as having received the most votes on the certificate of ascertainment will go on to participate in the electoral college, while a losing candidate’s proposed electors have no role to play and no standing to participate in the electoral college. This happens after every Presidential election, in each of the fifty States and the District of Columbia.
    </Typography>

    <Typography className="indent">
      This process comes from a clause in the U.S. Constitution that gives States the power to choose electoral college electors according to State law.
      <Citation number={13} />
      That clause says that each State “shall appoint” electoral college electors “in such [m]anner as the Legislature thereof may direct.” All 50 States have decreed that electors will be selected by popular vote.
    </Typography>

    <Typography className="indent">
      Tuesday, November 3rd, was the day established by Federal law as election day in 2020. Each State’s rules had been set—and courts had weighed in when certain rules were challenged. Polls opened around the country and votes came in, whether in person or via the mail, according to each State’s laws.
    </Typography>

    <Typography className="indent">
      Over 154 million voters cast votes according to the rules in place on election day.
      <Citation number={14} />
      President Trump lost. He and his supporters went to court, filing long-shot legal challenges to the election, but they failed in courts around the country, before judges appointed by executives of both parties (including President Trump himself), and, for those judges who were elected, that are members of both parties.
    </Typography>

    <Typography className="indent">
      Rather than abiding by the rule of law and accepting the courts’ rulings, President Trump and his advisors tried every which way to reverse the outcome at the State level. They pressured local and State elections officials to stop counting votes once it became clear that former Vice President Joseph Biden would prevail in the final count. They pressured Governors, secretaries of State, and local officials not to certify the popular vote in several swing States that former Vice-President Biden had won. And, when that did not work, they pressured State legislators to disregard the vote counts and instead appoint Trump electors to vote in the electoral college.
    </Typography>

    <Typography className="indent">
      This fundamentally anti-democratic effort was premised on the incorrect theory that, because the Constitution assigns to State legislatures the role of directing how electoral college electors are chosen (which every State legislature had done <i>before</i> the election, giving that power to the people at the ballot boxes) then the State legislatures could simply choose Trump/Pence electors <i>after</i> seeing the election results. In effect, President Trump and his advisors pushed for the rules to be changed after the election—even if it meant disenfranchising millions of Americans.
    </Typography>
  </Page>
)
