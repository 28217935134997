import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet8'));

export const Chapter616 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      While the “Stop the Steal” coalition was not given speaking slots on the Ellipse stage on January 6th, its leaders had plenty of opportunities to speak the day before. And they used their platforms to rile up the crowd in Washington, DC in advance of the joint session.
    </Typography>

    <Typography className="indent">
      Ali Alexander spoke at an event sponsored by Moms for America in front of the U.S. Capitol. Alexander claimed that he was honored to be sharing the same stage with President Trump the following day, even though behind the scenes his appearance had been nixed.
      <Citation number={462} />
    </Typography>

    <Typography className="indent">
      “We must rebel,” Alexander told rallygoers. “I’m not even sure if I’m going to leave D.C. We might make this ‘Fort Trump,’ right?” Alexander said, while standing in front of the U.S. Capitol. “We’re going to keep fighting for you, Mr. President.”
      <Citation number={463} />
      On his Twitter account, Alexander also spread the idea that President Trump’s supporters should occupy areas of Washington, DC, using the phrases and hashtags such as “Fort Trump” and “#OccupyDC”.
      <Citation number={464} />
    </Typography>

    <Typography className="indent">
      Alex Jones and Roger Stone spoke at a separate event hosted by Virginia Women for Trump in front of the Supreme Court.
      <Citation number={465} />
      The event, named the “One Nation Under God” prayer rally, was cohosted by the American Phoenix Project—the Three Percenter-linked group run by Alan Hostetter and Russel Taylor, discussed above, which is charged with conspiracy to obstruct an official proceeding.
      <Citation number={466} />
    </Typography>

    <Typography className="indent">
      Jones repeated his claims about the election being stolen, claiming that those in attendance stood against a “Satanic world government.”
      <Citation number={467} />
      Stone led a “Stop the Steal” chant, claiming the “evidence of election fraud is not only growing, it is overwhelming, and it is compelling.” President Trump “won the majority of the legal votes cast” and President Trump “won this election,” Stone said. Nothing less than the fate of Western Civilization was at stake, according to Stone:
    </Typography>

    <Blockquote>
      <Typography>
       Let’s be very clear. This is not fight between Republicans and Democrats. This is not a fight between liberals and conservatives. This is a fight for the future the United States of America. It is a fight for the future of Western Civilization as we know it. It’s a fight between dark and light. It’s a fight between the godly and the godless. It’s a fight between good and evil. And we dare not fail, or we will step out into one thousand years of darkness.
       <Citation number={468} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Stone claimed that they “renounce violence” and those on “the left... are the violent ones.” But he insisted that “nothing is over until we say it is,” and “Victory will be ours.”
      <Citation number={469} />
    </Typography>

    <Typography className="indent">
      Both Taylor and Hostetter spoke as well. Hostetter told the crowd, “We are at war.”
      <Citation number={470} />
      Taylor promised to “fight” and “bleed,” vowing that “Patriot[s]” would “not return to our peaceful way of life until this election is made right.”
      <Citation number={471} />
    </Typography>

    <Typography className="indent">
      A long rally was also hosted at Freedom Plaza, an open-air space on Pennsylvania Avenue in Washington, DC. It is a symbolic protest site, standing in the direct line between the White House and the U.S. Capitol. Stone, Jones and Alexander all appeared at Freedom Plaza on the evening of January 5th. Their remarks were incendiary.
    </Typography>

    <Typography className="indent">
      Stone repeated his apocalyptic language from earlier in the day, claiming that rallygoers were embroiled in “an epic struggle for the future of this country between dark and light.”
      <Citation number={472} />
      “I want them to know that 1776 is always an option,” Ali Alexander said. “These degenerates in the deep state are going to give us what we want, or we are going to shut this country down.”
      <Citation number={473} />
      When Alex Jones took to the stage, he screamed at the crowd: “<i>It’s 1776!</i>”
      <Citation number={474} />
    </Typography>

    <Typography className="indent">
      Another speaker that evening was Lt. Gen. Michael Flynn (ret.). “Tomorrow, tomorrow, trust me, the American people that are standing on the soil that we are standing on tonight, and they’re going to be standing on this soil tomorrow, this is soil that we have fought over, fought for, and we will fight for in the future,” Flynn also told the crowd. Flynn addressed Members of Congress, saying “those of you who are feeling weak tonight, those of you that don’t have the moral fiber in your body, get some tonight because tomorrow, we the people are going to be here, and we want you to know that we will not stand for a lie. We will not stand for a lie.”
      <Citation number={475} />
    </Typography>
  </Page>
)
