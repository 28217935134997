import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet3'));

export const Chapter15 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
    	President Trump’s campaign leadership, including Bill Stepien (the campaign’s manager) and Justin Clark (the campaign’s deputy manager), supported President Trump, and were willing to pursue recounts and other standard post-election litigation, but they were not willing to promote baseless conspiracy theories.
    	<Citation number={51} />
    	Stepien and others characterized this group as “Team Normal.”
    	<Citation number={52} />
    </Typography>

    <Typography className="indent">
      Less than two weeks after the election, President Trump pushed “Team Normal” aside because its members didn’t tell him what he wanted to hear. In their place, Trump promoted Rudy Giuliani and his associates, men and women who spread baseless and extreme claims of election fraud. Giuliani, the former mayor of New York City, recruited several investigators and lawyers to assist him.
      <Citation number={53} />
      Giuliani’s team included Jenna Ellis, Bernard Kerik, Boris Epshteyn, Katherine Friess, and Christina Bobb.
      <Citation number={54} />
      Ellis functioned as Giuliani’s deputy on the new Trump Campaign legal team.
      <Citation number={55} />
      Kerik, the former commissioner of the New York Police Department and a pardoned felon, served as Giuliani’s chief investigator.
      <Citation number={56} />
      Other attorneys who collaborated with Giuliani’s legal team included Sidney Powell, Cleta Mitchell, and John Eastman. As discussed elsewhere in this report, Eastman became a key player in President Trump’s efforts to overturn the election.
    </Typography>
  </Page>
)
