import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet9'));

export const Chapter74 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Minutes after arriving back at the White House, the President ran into a member of the White House staff and asked whether he or she watched his speech on television.
      <Citation number={122} />
    </Typography>

    <Typography className="indent">
      “Sir, they cut it off because they’re rioting down at the Capitol,” the employee said.
    </Typography>

    <Typography className="indent">
      The President asked what he or she meant by that.
    </Typography>

    <Typography className="indent">
      [T]hey’re rioting down there at the Capitol,” the employee repeated.
    </Typography>

    <Typography className="indent">
      “Oh really?” the President asked. “All right, let’s go see.”
      <Citation number={123} />
    </Typography>

    <Typography className="indent">
      A photograph taken by the White House photographer—the last one permitted until later in the day—captures the moment the President heard the news from the employee at 1:21 p.m.
      <Citation number={124} />
      By that time, if not sooner, he had been made aware of the violent riot at the Capitol.
    </Typography>

    <Typography className="indent">
      President Trump walked through the corridor from the Oval Office into the Presidential Dining Room and sat down at the table with the television remote and a Diet Coke close at hand.
      <Citation number={125} />
      For the rest of the afternoon—as his country faced an hours-long attack—he hunkered down in or around the dining room, watching television.
      <Citation number={126} />
      He left only for a few minutes— from 4:03 p.m. to 4:07 p.m.—to film a video in the Rose Garden, only a few steps away, after hours of arm-twisting.
      <Citation number={127} />
      But otherwise, the President remained in the dining room until 6:27 p.m., when he returned to his private residence.
      <Citation number={128} />
    </Typography>

    <Typography className="indent">
      What happened during the 187 minutes from 1:10 p.m. to 4:17 p.m., when President Trump finally told the rioters to go home, is—from an official standpoint—undocumented.
    </Typography>

    <Typography className="indent">
      For instance, the Presidential Daily Diary—the schedule that tracks every meeting and phone call in which the President partakes—is inexplicably blank between 1:21 p.m. and 4:03 p.m.
      <Citation number={129} />
      When asked to explain the gap in record-keeping on and around January 6th, White House officials in charge of its maintenance provided no credible explanation, including: “I don’t recall a specific reason.”
      <Citation number={130} />
    </Typography>

    <Typography className="indent">
      The men who spent most of the afternoon in that room with the President, Mark Meadows and Dan Scavino, both refused to comply with lawful subpoenas from the Select Committee.
      <Citation number={131} />
      Others in the dining room appeared before the Select Committee but cited executive privilege to avoid answering questions about their direct communications with President Trump.
      <Citation number={132} />
      Others who worked just outside of the Oval Office, like the President’s personal secretaries Molly Michael and Austin Ferrer Piran Basauldo, claimed not to remember nearly anything from one of the most memorable days in recent American history.
      <Citation number={133} />
    </Typography>

    <Typography className="indent">
      The White House photographer, Shealah Craighead, had been granted access to photograph the President during his January 6th speech, but once she got to the White House—and it became clear that an attack was unfolding on the Capitol’s steps—she was turned away.
      <Citation number={134} />
    </Typography>

    <Typography className="indent">
      “The President [didn’t] want any photos,” she was told.
      <Citation number={135} />
    </Typography>

    <Typography className="indent">
      Here’s what President Trump did during the 187 minutes between the end of his speech and when he finally told rioters to go home: For hours, he watched the attack from his TV screen.
      <Citation number={136} />
      His channel of choice was Fox News.
      <Citation number={137} />
      He issued a few tweets, some on his own inclination and some only at the repeated behest of his daughter and other trusted advisors.
      <Citation number={138} />
      He made several phone calls, some to his personal lawyer Rudolph Giuliani, some to Members of Congress about continuing their objections to the electoral certification, even though the attack was well underway.
      <Citation number={139} />
    </Typography>

    <Typography className="indent">
      Here’s what President Trump did not do: He did not call any relevant law enforcement agency to ensure they were working to quell the violence. He did not call the Secretary of Defense; he did not call the Attorney General; he did not call the Secretary of Homeland Security.
      <Citation number={140} />
      And for hours on end, he refused the repeated requests—from nearly everyone who talked to him—to simply tell the mob to go home.
      <Citation number={141} />
    </Typography>

    <Typography className="indent">
      Throughout the afternoon, senior staff regularly entered the room to give him updates on what was happening at the Capitol.
      <Citation number={142} />
      And, of course, President Trump used Twitter, where information is shared on an instantaneous basis.
    </Typography>

    <Typography className="indent">
      Shortly after President Trump entered the dining room, White House Press Secretary Kayleigh McEnany swung by to “check in with him” about the letter Vice President Pence released around 1:00 p.m. announcing that he would not, in fact, overturn the will of the voters.
    </Typography>

    <Typography className="indent">
      The President, once again, brought up going to the Capitol.
      <Citation number={143} />
      McEnany recorded what he said in her notes, certain of which she later produced to the Select Committee: “POTUS wanted to walk to [sic] capital. Physically walk. He said fine ride beast,” referring to the nickname for the presidential vehicle. “Meadows said not safe enough[.]”
      <Citation number={144} />
    </Typography>

    <Typography className="indent">
      Meadows told Hutchinson at some point in the day that “the President wasn’t happy that Bobby [Engel] didn’t pull it off for him,” meaning the trip to the Capitol, “and that Mark didn’t work hard enough to get the movement on the books.”
      <Citation number={145} />
    </Typography>

    <Typography className="indent">
      Despite the turmoil just outside its walls, the proceedings in the joint session—which had begun at 1:00 p.m.—were still ongoing, and the President was watching them on the television.
      <Citation number={146} />
      He was eager to know which senators were lodging objections on his behalf.
      <Citation number={147} />
      “Back there and he wants list of senators,” McEnany’s notes read. “Who [sic] objecting to what. He’s calling them one by one.”
      <Citation number={148} />
    </Typography>

    <Typography className="indent">
      The Select Committee subpoenaed several Members of Congress who reportedly spoke with President Trump during the afternoon.
      <Citation number={149} />
      None of them complied.
      <Citation number={150} />
    </Typography>

    <Typography className="indent">
      Cellular records obtained by the Select Committee suggest that President Trump was on the phone with his lawyer Rudolph Giuliani at least twice during this period. Giuliani’s phone connected with the White House switchboard for 3 minutes and 53 seconds at 1:39 p.m. and again for more than 8 minutes at 2:03 p.m.
      <Citation number={151} />
      Between the two calls, at 1:49 p.m., President Trump tweeted a link to a video of his speech from the Ellipse.
      <Citation number={152} />
    </Typography>

    <Typography className="indent">
      Before 1:57 p.m., Herschmann phoned Senior Advisor to the President Jared Kushner—who was on a plane travelling home from overseas— advising him that “people are trying to break into the Capitol” and that “this is getting pretty ugly.”
      <Citation number={153} />
    </Typography>

    <Typography className="indent">
      “We’re going to see what we can do here,” Herschmann said. “We’re going to try to get the President to put out a statement.”
      <Citation number={154} />
    </Typography>
  </Page>
)
