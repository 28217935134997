import { lazy } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet8'));

export const Chapter62 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      From the Proud Boys’ founding in 2016, violence was intrinsic to their mission. “We will kill you. That’s the Proud Boys in a nutshell,” their founder said.
      <Citation number={85} />
      New recruits pledge an oath, established in the group’s bylaws, identifying themselves as unapologetic “Western chauvinists,”
      <Citation number={86} />
      promoting an exclusionary, hyper-masculine interpretation of Western culture.
      <Citation number={87} />
      They find common ground in an embrace of misogyny and hate for their perceived enemies.
      <Citation number={88} />
      The group is somewhat ethnically diverse, but their public and private messages fester with toxic white supremacist, xenophobic, and anti-Semitic slurs.
      <Citation number={89} />
    </Typography>

    <Typography className="indent">
      The Proud Boys have participated in, or instigated, protests since their founding.
      <Citation number={90} />
      They’ve long been known as street brawlers looking for a fight.
      <Citation number={91} />
      But 2020 was a watershed year for the group. As protests spread around the country, the Proud Boys deputized themselves as agents of law and order—vigilantes against perceived threats.
      <Citation number={92} />
      More often, they played the role of instigators.
      <Citation number={93} />
      They portrayed themselves as counter-protestors and identified their targets as Black Lives Matter and Antifa—though they were hard-pressed to define their organizational enemies.
      <Citation number={94} />
    </Typography>

    <Typography className="indent">
      During the presidential debate on September 29, 2020, President Trump was asked to disavow far-right extremists, including the Proud Boys. The President did not explicitly condemn the group. Instead, he seemingly endorsed their mission. “Stand back and stand by,” President Trump told the Proud Boys, before adding, “but I’ll tell you what... somebody’s got to do something about Antifa and the left.”
      <Citation number={95} />
      The President’s words electrified the group, injecting new life into their recruitment and activities. According to Nick Quested, a filmmaker who spent significant time with the group and testified before the Select Committee, the Proud Boys had found their “savior” in President Trump.
      <Citation number={96} />
    </Typography>

    <Image
      src="/images/image-082.jpg"
      alt='A Proud Boy during a "Stop the Steal" rally on November 7, 2020 in Salem, Oregon.'
      caption='A Proud Boy during a "Stop the Steal" rally on November 7, 2020 in Salem, Oregon.'
      credit="(Photo by Nathan Howard/Getty Images)"
    />

    <Typography className="indent">
      Joseph Biggs, a senior Proud Boy, immediately trumpeted President Trump’s debate statement on Parler,
      <Citation number={97} />
      a fringe social media platform. Biggs made it clear that the Proud Boys were ready to fight Antifa.
      <Citation number={98} />
      The group’s size “tripled” in response to President Trump’s apparent endorsement, according to Jeremy Bertino, a Proud Boys leader who has pleaded guilty to seditious conspiracy in relation to January 6th.
      <Citation number={99} />
      Similarly, Enrique Tarrio and another Proud Boys member, George Meza, testified to the Select Committee that the President’s comment was a pivotal, energizing moment.
      <Citation number={100} />
      The group started selling merchandise with their new “stand back and stand by” slogan the very same night.
      <Citation number={101} />
    </Typography>

    <Typography className="indent">
      As the presidential votes were tallied, the Proud Boys became agitated at the prospect that President Trump would lose. On November 5, 2020, Biggs posted on social media, “It’s time for fucking war if they steal this shit.”
      <Citation number={102} />
      As former Vice President Joe Biden’s victory became apparent, Proud Boys leaders directed their ire toward others in the Government. Biggs, speaking on a Proud Boys livestream show with Tarrio and others, warned that government officials are “evil scum, and they all deserve to die a traitor’s death.” Ethan Nordean—another Proud Boys leader who allegedly helped lead the attack at the Capitol—responded, “Yup, Day of the Rope,”
      <Citation number={103} />
      referring to a day of mass lynching of “race traitors” in the white supremacist novel <i>The Turner Diaries</i>.
      <Citation number={104} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        THE PROUD BOYS IN WASHINGTON PRIOR TO JANUARY 6TH
      </Typography>
    </Box>

    <Typography className="indent">
      Within days of the election, dozens of “Stop the Steal” protests were organized around the country.
      <Citation number={105} />
      The Proud Boys participated alongside other right-wing extremist groups in some of them, including a November 7, 2020, protest outside of the Pennsylvania State capitol in Harrisburg.
      <Citation number={106} />
      The two events in Washington, DC—on November 14, 2020, and the other on December 12, 2020—proved to be especially important for the group’s evolution.
    </Typography>

    <Typography className="indent">
      The daytime events on both dates passed by without violence or major unrest, but as the sun set, bouts of violence erupted,
      <Citation number={107} />
      driven by clashes between far-right extremist groups—chiefly the Proud Boys—and counter-protestors.
      <Citation number={108} />
      Among far-right extremists, the Proud Boys had the largest showing in both November and December,
      <Citation number={109} />
      with roughly 200 to 300 Proud Boys at the November 14th rally, and the same number or more in December.
      <Citation number={110} /> As discussed in Chapter 8, they mustered about the same contingent for the attack on the U.S. Capitol.
    </Typography>

    <Typography className="indent">
      The gathering on November 14th provided a chance for Tarrio to socialize with rally leaders and far-right celebrities. In fact, his travel to DC by private jet appears to have been paid for by Patrick Byrne, a businessman who had President Trump’s ear in the last weeks of his presidency and encouraged the President to authorize the seizure of voting machines in a December 18th meeting.-
      <Citation number={111} />
      Tarrio’s testimony and photographs from the day show that he met with “Stop the Steal” organizer Ali Alexander that evening, and the pair toasted each other.
      <Citation number={112} />
      Tarrio described the event as a “historic” meeting of Trump supporters and celebrated the opportunity to share that platform with Alexander, Jones, and Jones’ InfoWars co-host, Owen Shroyer.
      <Citation number={113} />
      Shroyer would later be charged with crimes committed during the January 6th attack.
      <Citation number={114} />
    </Typography>

    <Typography className="indent">
      A month later, the Proud Boys returned to the Nation’s capital. On the evening of December 11th, hundreds of Proud Boys and friends gathered in downtown Washington, DC to listen to an impromptu bullhorn speech by Tarrio and Nordean, along with Roger Stone and Shroyer.
      <Citation number={115} />
      Stone implored the crowd to “fight to the bitter end.”
      <Citation number={116} />
    </Typography>

    <Typography className="indent">
      The next day, as the Proud Boys marched in force on the streets, Tarrio teased in a social media post that he had a meeting in the White House.
      <Citation number={117} />
      The visit, which was only a public White House tour, appears to have been facilitated by a friend, Bianca Gracia, the head of Latinos for Trump.
      <Citation number={118} />
      As the rallies concluded the next day, the Proud Boys took to the streets again. Two key events occurred that evening.
    </Typography>

    <Typography className="indent">
      First, members of the Proud Boys tore down a Black Lives Matter banner from a historically Black church in downtown Washington, DC.
      <Citation number={119} />
      They filmed themselves burning it.
      <Citation number={120} />
      Tarrio was eventually charged with destruction of property.
      <Citation number={121} />
      He was arrested on January 4, 2021, and banned from Washington, DC, barring him from joining the group at the Capitol.
      <Citation number={122} />
      As explained in Chapter 8, however, Tarrio’s arrest did not stop him from conspiring with his men on January 6th.
    </Typography>

    <Typography className="indent">
      Minutes after the flag burning, a man wearing black clothes walked into a crowd of Proud Boys.
      <Citation number={123} />
      Assuming he was associated with Antifa, they began pushing and harassing him, and he drew a knife in response.
      <Citation number={124} />
      In the ensuing melee, four Proud Boys suffered stab wounds, including Bertino, a confidant to Tarrio.
      <Citation number={125} />
      Bertino’s wounds were severe and life-threatening, preventing him from joining the group on January 6th.
      <Citation number={126} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        STORMING THE WINTER PALACE
      </Typography>
    </Box>

    <Typography className="indent">
      The Proud Boys began to reorient and formalize their operations to focus on January 6th after President Trump’s December 19th tweet. Inspired, in part, by Bertino’s stabbing, the Proud Boys centered their new hierarchy in group chats that used terms such as “Ministry of Self Defense” (MOSD).
      <Citation number={127} />
      However, the words “Self Defense” were misleading: Enrique Tarrio and others would soon go on the offense. And the MOSD served as their organizational scaffolding for the January 6, 2021, attack.
    </Typography>

    <Typography className="indent">
      On December 20, 2020, Tarrio established a “national rally planning committee” and created an encrypted MOSD chat to organize their activities.
      <Citation number={128} />
      Tarrio added Proud Boys leaders from across the country, including several who played lead roles in the violence on January 6th.
      <Citation number={129} />
      In the ensuing weeks, the Proud Boys traded equipment recommendations, shared maps marked with law enforcement positions, and established command and control structures.
      <Citation number={130} />
      A separate encrypted chat, named “Boots on the Ground,” was established for foot soldiers who would be in Washington, DC on January 6th.
      <Citation number={131} />
    </Typography>

    <Typography className="indent">
      The Proud Boys’ planning for January 6th was a significant step in the group’s evolution. Previously, they were loosely organized. The MOSD was created to enforce a “top down structure” with a defined leadership.
      <Citation number={132} />
      Tarrio stressed the command structure by telling members that they needed to “[f]it in [] or fuck off.”
      <Citation number={133} />
    </Typography>

    <Typography className="indent">
      From the start, it was clear that MOSD chat members were intensely interested in disrupting the electoral count on January 6th. On December 20, 2020, one MOSD leader stated, “I assume most of the protest will be at the capital [<i>sic</i>] building given what’s going on inside.”
      <Citation number={134} />
      On December 29, 2020, in a group message to the MOSD, a member wrote, “I know most of the events will be centered around freedom plaza....” Tarrio responded, “Negative. They’re centered around the Capitol.”
      <Citation number={135} />
    </Typography>

    <Typography className="indent">
      On December 30, 2020, Tarrio received an intriguing document titled, “1776 Returns.”
      <Citation number={136} />
      The document was apparently sent to him by cryptocurrency investors in South Florida.
      <Citation number={137} />
      The file’s author(s) divided their plan into five parts, “Infiltrate, Execution, Distract, Occupy and Sit-In,” with the goal of overrunning several Federal buildings around the U.S. Capitol. The plan specifically mentioned House and Senate office buildings, setting forth steps for occupying them. The author(s) called for “the masses to rush the building[s],” distract law enforcement in the area by pulling fire alarms around the city, target specific Senators’ offices, and disguise participants’ identities with COVID masks.
      <Citation number={138} />
    </Typography>

    <Typography className="indent">
      One proposal mentioned in the document is titled, “Storm the Winter Palace.”
      <Citation number={139} />
      This is a reference to a dramatic reenactment of the 1917 Bolshevik Revolution, during which Vladimir Lenin ordered his forces to take over the Romanovs’ residence in Petrograd. The “Winter Palace” was the seat of the provisional government, which had held out against the Bolshevik revolutionaries. The Proud Boys would frame their actions on January 6th as part of the American Revolution. But the “1776 Returns” document shows their inspiration came at least in part from the Communist Revolution, which led to 70-plus years of totalitarian rule. No historical event has been less American.
    </Typography>

    <Typography className="indent">
      The Proud Boys did not adopt the “1776 Returns” plan in full. Several Proud Boys testified that they were unaware of the document before it became public.
      <Citation number={140} />
      But the document does appear to have been significantly edited while in the Proud Boys’ hands.
      <Citation number={141} />
      The person who sent it to Tarrio— his ex-girlfriend, Eryka Gemma Flores—commented, “The revolution is [more] important than anything.” To which Tarrio responded: “That’s what every waking moment consists of ... I’m not playing games.”
      <Citation number={142} />
    </Typography>

    <Typography className="indent">
      On January 3rd, Tarrio posted a conspicuous question on Telegram: “What if we invade it?” The first response to his post read: “January 6th is D day [<i>sic</i>] in America.”
      <Citation number={143} />
      In private, on the Proud Boys’ leadership group message, planning continued. One MOSD leader, John Stewart, floated a plan that centered around “the front entrance to the Capitol building.”
      <Citation number={144} />
      At 7:10 p.m. on January 3rd, Stewart wrote to the MOSD leaders:
    </Typography>

    <Blockquote>
      <Typography>
        I mean the main operating theater should be out in front of the house of representatives. It should be out in front of the Capitol building. That’s where the vote is taking place and all of the objections. So, we can ignore the rest of these stages and all that shit and plan the operations based around the front entrance to the Capitol building. I strongly recommend you use the national mall and not Pennsylvania avenue though. It’s wide-open space, you can see everything coming from all angles.
        <Citation number={145} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Early the next morning, on January 4th, Tarrio sent a voice memo to the same group of MOSD leaders stating, “I didn’t hear this voice until now, you want to storm the Capitol.”
      <Citation number={146} />
    </Typography>

    <Typography className="indent">
      One of Tarrio’s comrades in the Proud Boys’ leadership, Charles Donohoe—who pleaded guilty to conspiracy to obstruct an official proceeding and assaulting, resisting, or impeding certain officers
      <Citation number={147} />
      —later told authorities that by January 4th he “was aware that members of MOSD leadership were discussing the possibility of storming the Capitol.”
      <Citation number={148} />
      Donohoe “believed that storming the Capitol would achieve the group’s goal of stopping the government from carrying out the transfer of presidential power” and “understood that storming the Capitol would be illegal.”
      <Citation number={149} />
      By the following evening, January 5th, Tarrio was discussing with other Proud Boy leaders a “tactical plan” for the following day. Their “objective” was “to obstruct, impede, or interfere with the certification of the Electoral College vote.”
      <Citation number={150} />
      Moreover, Donohoe understood that the Proud Boys “would pursue this through the use of force and violence, in order to show Congress that ‘we the people’ were in charge.”
      <Citation number={151} />
      On January 6th, Charles Donohoe understood that two of his fellow Proud Boys’ leaders—Ethan Nordean and Joe Biggs—“were searching for an opportunity to storm the Capitol.”
      <Citation number={152} />
    </Typography>

    <Typography className="indent">
      Jeremy Bertino, the Proud Boys leader who was stabbed on the night of December 12th, later told authorities that his fellow extremists plotted to stop the peaceful transfer of power. In October 2022, Bertino pleaded guilty to “seditious conspiracy” and other crimes.
      <Citation number={153} />
      Bertino admitted that the Proud Boys traveled to Washington, DC on January 6, 2021, “to stop the certification of the Electoral College Vote.” They “were willing to do whatever it would take, including using force against police and others, to achieve that objective.”
      <Citation number={154} />
    </Typography>

    <Typography className="indent">
      In testimony before the Select Committee, Bertino recalled a telling text exchange with Tarrio on the evening of January 6th. “I was like, ‘holy shit,’ or something like that I said to him,” Bertino recalled. “And I was like, ‘I can’t believe this is happening,’ or something like that, and ‘1776.’”
      <Citation number={155} />
    </Typography>

    <Typography className="indent">
      Tarrio replied to Bertino: “Winter Palace.”
      <Citation number={156} />
    </Typography>
  </Page>
)
