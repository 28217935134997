import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet5'));

export const Chapter36 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      In spite of the Trump Campaign’s efforts to give the fake electors’ votes the sheen of authenticity, they failed. The U.S. Senate Parliamentarian noted in correspondence by January 3rd that materials from the Trump team’s supposed electors in Arizona, Georgia, Nevada, New Mexico, and Pennsylvania had “no seal of the state” and “no evidence votes were delivered by the executive of the state for signature by electors,”
      <Citation number={109} />
      and, as a result, these materials failed to meet requirements of federal law. Similarly, the Senate Parliamentarian noted that the Trump team’s slates from Georgia, New Mexico, and Pennsylvania appeared to violate another statute which requires the approval of the Governor for the substitution of electors.
      <Citation number={110} />
    </Typography>

    <Typography className="indent">
      Meanwhile, the documents from Michigan and Wisconsin did not even arrive to Congress on time, so they also had missed the required statutory deadline.
      <Citation number={111} />
    </Typography>

    <Typography className="indent">
      Several of the Trump team’s fake electoral slates also failed to follow State rules specifying where they were required to meet. In Georgia and Wisconsin, State lawmakers or their staff appear to have helped participants gather inside their State capitols.
      <Citation number={112} />
      But in Michigan, the fake Trump electors were blocked from entering the State capitol building.
      <Citation number={113} />
      Despite this, they still signed documents attesting that they “convened and organized in the State Capitol, in the City of Lansing, Michigan, and at 2:00 p.m.... performed the duties enjoined upon us.”
      <Citation number={114} />
      That document had been signed earlier in the day off-site, and one of the signatories even told the Committee she didn’t join their march to the State capitol building because she “didn’t see a need to go.”
      <Citation number={115} />
    </Typography>

    <Typography className="indent">
      If the entire premise of the fake votes was not enough, these infirmities also meant that they had no legal relevance. In no way could they ever have been used by the Vice President to disregard the real votes of electors chosen by the voters.
    </Typography>

    <Typography className="indent">
      In the weeks between December 14th and January 6th, President Trump’s team continued to embrace the idea that the fake electoral votes had a purpose. Although Giuliani and White House speechwriter Stephen Miller made public comments on December 14th suggesting that the uncertified Trump votes were merely contingent, that pretense was dropped in short order.
      <Citation number={116} />
    </Typography>

    <Typography className="indent">
      For example, on December 17th, White House Press Secretary Kayleigh McEnany said on Fox News that in numerous States “there has been an alternate slate of electors voted upon that Congress will decide in January.”
      <Citation number={117} />
      On December 21st, President Trump and Vice President Pence each joined parts of a White House meeting in which Members of Congress from the Freedom Caucus encouraged the Vice President to reject Biden electors from one or more of the seven contested States.
      <Citation number={118} />
      And days later, Eastman cited the existence of the fake votes in an email to Boris Epshteyn, a member of the Giuliani legal team, writing, “[t]he fact that we have multiple slates of electors demonstrate[s] the uncertainty of either. That should be enough.”
      <Citation number={119} />
    </Typography>

    <Typography className="indent">
      As discussed further in Chapter 5, that email contained Eastman’s 2-page memo proposing a strategy for January 6th based on the incorrect legal theory that Vice President Pence could assert some authority as President of the Senate to prevent or delay the election of former Vice President Biden during the joint session. Eastman’s memo relied on the fake votes, which the memo featured in the very first line: “7 states have transmitted dual slates of electors.”
      <Citation number={120} />
      When Eastman submitted his memo to Epshteyn, he also copied Chesebro, who had edited the memo and called it “[r]eally awesome.”
      <Citation number={121} />
    </Typography>

    <Typography className="indent">
      By that point, Chesebro and Eastman were coordinating their arguments about the fake-elector votes and how they should be used. On January 1, 2021, Chesebro sent an email to Eastman and Epshteyn that recommended that Vice President Pence derail the joint session of Congress. In it, he raised the idea of Vice President Pence declaring “that there are two competing slates of electoral votes” in several States, and taking the position that only he, or possibly Congress, could “resolve any disputes concerning them.”
      <Citation number={122} />
    </Typography>

    <Typography className="indent">
      Two days later, Eastman completed his second major memo advising President Trump and his team on strategies for January 6th, again arguing that there were “dual slates of electors from 7 states,” and calling for Vice President Pence to assert power to act “[a]s the ultimate arbiter” to take steps that could overturn the election, either by sending the election back to State legislatures to reassess or by rejecting Biden’s certified electoral votes from States in which there were also fake Trump electors.
      <Citation number={123} />
    </Typography>

    <Typography className="indent">
      By early January, most of the fake elector votes had arrived in Washington, except those from Michigan and Wisconsin.
      <Citation number={124} />
      Undeterred, the Trump team arranged to fly them to Washington and hand deliver them to Congress for the Vice President himself. “Freaking trump idiots want someone to fly original elector papers to the senate President...” Wisconsin Republican Party official Mark Jefferson wrote to Party Chairman Hitt on January 4th.
      <Citation number={125} />
      Hitt responded, “Ok I see I have a missed call from [Mike] Roman and a text from someone else. Did you talk to them already? This is just nuts....”
      <Citation number={126} />
    </Typography>

    <Typography className="indent">
      The next day, Trump Campaign Deputy Director for Election Day Operations G. Michael Brown sent a text message to other campaign staff suggesting that he was the person who delivered the fake votes to Congress.
      <Citation number={127} />
      After sending the group a photo of his face with the Capitol in the background, Brown said, “This has got to be the cover a book I write one day” and “I should probably buy [Mike] [R]oman a tie or something for sending me on this one. Hasn’t been done since 1876 and it was only 3 states that did it.”
      <Citation number={128} />
      The reference to 1876 alludes to a controversy during that election about certain States’ electoral college votes.
      <Citation number={129} />
    </Typography>

    <Image
      src="/images/image-050.jpg"
      alt="Senator Ron Johnson, February 12, 2021."
      caption="Senator Ron Johnson, February 12, 2021."
      credit="(Photo by Samuel Corum/Getty Images)"
    />

    <Typography className="indent">
      President Trump and his Campaign apparently had assistance from allies on Capitol Hill for this effort, including Senator Ron Johnson, his chief of staff, and the chief of staff to Representative Mike Kelly, although Senator Johnson has said that “[his] involvement in that attempt to deliver” fake elector paperwork “spanned the course of a couple seconds.”
      <Citation number={130} />
      On the morning of January 6th, Representative Kelly’s then-chief of staff texted an aide to the Vice President, Chris Hodgson, about hand-delivering the fake elector votes to the Vice President’s team before the joint session, a message that Hodgson ignored: “Just following up-any chance you or someone from your team can meet to take the Michigan and Wisconsin packets.”
      <Citation number={131} />
    </Typography>

    <Typography className="indent">
      According to the office of Senator Ron Johnson, Representative Kelly’s chief of staff then had a phone call with Senator Johnson’s chief of staff at 11:58 a.m. “about how Kelly’s office could get us the electors [<i>sic</i>] because they had it.”
      <Citation number={132} />
      Shortly after 11:30 a.m., the Trump Campaign’s lead attorney in Wisconsin had texted Senator Johnson expressing a “[n]eed to get a document on Wisconsin electors to you [for] the VP immediately. Is there a staff person I can talk to immediately.”
      <Citation number={133} />
      Senator Johnson then put his chief of staff in touch with the campaign to handle the issue.
      <Citation number={134} />
    </Typography>

    <Typography className="indent">
      Shortly afterwards, Senator Johnson’s chief of staff texted Hodgson: “[Sen.] Johnson needs to hand something to VPOTUS please advise.”
      <Citation number={135} />
      When Hodgson asked what it was, the response he got was, “Alternate slate of electors for MI and WI because archivist didn’t receive them.”
      <Citation number={136} />
      Hodgson did not mince words: “Do not give that to him [the Vice President]. He’s about to walk over to preside over the joint session, those were supposed to come in through the mail.”
      <Citation number={137} />
    </Typography>

    <Image
      src="/images/image-051.jpg"
      alt="Senator Mike Lee, April 28, 2016."
      caption="Senator Mike Lee, April 28, 2016."
      credit="(Photo by Leigh Vogel/Getty Images)"
    />

    <Typography className="indent">
      Those fake electoral votes, which the Trump team tried for weeks to manufacture and deliver, never made it to the Vice President. But they would have been invalid even if they did arrive on time. The Trump team’s activities were based on the false pretense that these fake electoral votes had a decisive role to play at the joint session of Congress. And yet any such role that they could have played would have helped unlawfully obstruct an official proceeding that determines how our Nation carries out the peaceful transfer of power between Presidents.
    </Typography>

    <Typography className="indent">
      Indeed, as the joint session approached, Senator Mike Lee had expressed grave concerns about the fake elector effort in a series of text messages to one of the Trump team’s senior legal advisors. Although Senator Lee had spent a month encouraging the idea of having State legislatures endorse competing electors for Trump, he grew alarmed as it became clear that the Trump team wanted the fake electors’ votes to be considered on January 6th even without authorization from any State government body.
      <Citation number={138} />
    </Typography>

    <Typography className="indent">
      On December 30th, Senator Lee texted Trump advisor Cleta Mitchell that January 6th was “a dangerous idea,” including “for the republic itself.”
      <Citation number={139} />
      He explained that, “I don’t think we have any valid basis for objecting to the electors” because “it cannot be true that we can object to any state’s presidential electors simply because we don’t think they handled their election well or suspect illegal activity.”
      <Citation number={140} />
      Senator Lee even questioned her about the plan’s dangerous long-term consequences: “[w]ill you please explain to me how this doesn’t create a slippery slope problem for all future presidential elections?”
      <Citation number={141} />
    </Typography>
  </Page>
)
