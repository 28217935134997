import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet6'));

export const Chapter41 = ({ ...args }: any) => (
  <Page {...args}>
    <Blockquote>
      <Typography>
        “JUST CALL IT CORRUPT AND LEAVE THE REST TO ME”
      </Typography>
    </Blockquote>

    <Typography className="indent">
      U.S. Attorney General William Barr knew there would be trouble before all the votes had been counted. “So, right out of the box on election night, the President claimed that there was major fraud underway,” Barr explained. “I mean, this happened, as far as I could tell, before there was actually any potential of looking at evidence.”
      <Citation number={1} />
      President Trump was quick to claim, “there was major fraud” based solely on the phenomenon known as the “Red Mirage.”
      <Citation number={2} />
    </Typography>

    <Typography className="indent">
      As explained elsewhere in this report, Democrats were more inclined to vote via mail-in ballot during the 2020 Presidential election than Republicans, who were more likely to vote in-person on election day. This was widely known, and partly a result of, President Trump’s own public statements criticizing mail-in balloting. It also created a gap in the timing of how votes were tallied. The early vote tally favored Republicans on election night because the mail-in ballots, which skewed toward Democrats, were not yet fully counted. This occurred not just in 2020, but also in previous elections.
      <Citation number={3} />
      The President knew of this phenomenon but exploited it on election night, nonetheless, as he and his allies had planned to do.
      <Citation number={4} />
    </Typography>

    <Typography className="indent">
      President Trump exploited this timing gap and used it as “the basis for this broad claim that there was major fraud,” Barr said.
      <Citation number={5} />
      But the Attorney General “didn’t think much of that.” People “had been talking for weeks and everyone understood for weeks that that was going to be what happened on election night,” Barr explained.
      <Citation number={6} />
      Cities with Democratic majorities in the battleground States wouldn’t have their votes fully counted until “the end of the cycle,” with “a lot of Democratic votes coming in at the end.”
      <Citation number={7} />
      This was not some well-guarded secret, as “everyone understood that the dynamic of election night in many States would be whether or not the Democratic votes at the end of the day would overcome the election day votes.”
      <Citation number={8} />
    </Typography>

    <Typography className="indent">
      Within days of the election, the President made an “avalanche” of fraud allegations. It “was like playing Whac-A-Mole,” Barr explained, “because something would come out one day and the next day it would be another issue.”
      <Citation number={9} />
      Barr told his “staff very soon after the election” that he “didn’t think the President would ever admit that he lost the election, and he would blame it on fraud, and then he would blame the actions and evidence on the Department of Justice.”
      <Citation number={10} />
    </Typography>

    <Typography className="indent">
      Barr soon took steps to investigate claims of fraud in the 2020 Presidential election, even in the absence of evidence. The Department of Justice’s (DOJ) longstanding policy had been to avoid any substantive investigations until after the election’s results were certified.
      <Citation number={11} />
      As the country’s premier Federal law enforcement agency, DOJ is justifiably concerned that its substantial power can influence the outcome of an election, and it has enacted policies to mitigate this possibility.
      <Citation number={12} />
    </Typography>

    <Typography className="indent">
      On November 7, 2020, the media declared former Vice President Biden the winner of the Presidential election. Two days later, on November 9th, Attorney General Barr authorized wider investigations into claims of election fraud.
      <Citation number={13} />
      Barr instructed DOJ and FBI personnel “to pursue substantial allegations of voting and vote tabulation irregularities prior to the certification of elections in your jurisdictions in certain cases.”
      <Citation number={14} />
      Barr noted that nothing in his memo “should be taken as any indication that the Department has concluded that voting irregularities have impacted the outcome of any election.”
      <Citation number={15} />
    </Typography>
  </Page>
)
