import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet2'));

export const ObstructionOfAnOfficialProceeding = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Section 1512(c)(2) of Title 18 of the United States Code makes it a crime to “corruptly” “obstruct[], influence[], or impede[] any official proceeding, or attempt[] to do so.”
      <Citation number={594} />
      Sufficient evidence exists of one or more potential violations of this statute for a criminal referral of President Trump and others.
      <Citation number={595} />
    </Typography>

    <Typography className="indent">
      <i>First</i>, there should be no question that Congress’s joint session to count electoral votes on January 6th was an “official proceeding” under section 1512(c). Many Federal judges have already reached that specific conclusion.
      <Citation number={596} />
    </Typography>

    <Typography className="indent">
      <i>Second</i>, there should be no doubt that President Trump knew that his actions were likely to “obstruct, influence or impede” that proceeding. Based on the evidence developed, President Trump was attempting to prevent or delay the counting of lawful certified electoral college votes from multiple States.
      <Citation number={597} />
      President Trump was directly and personally involved in this effort, personally pressuring Vice President Pence relentlessly as the joint session on January 6th approached.
      <Citation number={598} />
    </Typography>

    <Typography className="indent">
      <i>Third</i>, President Trump acted with a “corrupt” purpose. Vice President Pence, Greg Jacob, and others repeatedly told the President that the Vice President had no unilateral authority to prevent certification of the election.
      <Citation number={599} />
      Indeed, in an email exchange during the violence of January 6th, Eastman admitted that President Trump had been “advised” that Vice President Pence could not lawfully refuse to count votes under the Electoral Count Act, but “once he gets something in his head, it’s hard to get him to change course.”
      <Citation number={600} />
      In addition, President Trump knew that he had lost dozens of State and Federal lawsuits, and that the Justice Department, his campaign and his other advisors concluded that there was insufficient fraud to alter the outcome. President Trump also knew that no majority of any State legislature had taken or manifested any intention to take any official action that could change a State’s electoral college votes.
      <Citation number={601} />
      But President Trump pushed forward anyway. As Judge Carter explained, “[b]ecause President Trump likely knew that the plan to disrupt the electoral count was wrongful, his mindset exceeds the threshold for acting ‘corruptly’ under § 1512(c).”
      <Citation number={602} />
    </Typography>

    <Typography className="indent">
      Sufficient evidence exists of one or more potential violations of 18 U.S.C. § 1512(c) for a criminal referral of President Trump based solely on his plan to get Vice President Pence to prevent certification of the election at the joint session of Congress. Those facts standing alone are sufficient. But such a charge under that statute can also be based on the plan to create and transmit to the executive and legislative branches fraudulent electoral slates, which were ultimately intended to facilitate an unlawful action by Vice President Pence, to refuse to count legitimate, certified electoral votes during Congress’s official January 6th proceeding.
      <Citation number={603} />
      Additionally, evidence developed about the many other elements of President Trump’s plans to overturn the election, including soliciting State legislatures, State officials, and others to alter official electoral outcomes, provides further evidence that President Trump was attempting through multiple means to corruptly obstruct, impede, or influence the counting of electoral votes on January 6th. This is also true of President Trump’s personal directive to the Department of Justice to “just say that the election was was [<i>sic</i>] corrupt + leave the rest to me and the R[epublican] Congressmen.”
      <Citation number={604} />
    </Typography>

    <Typography className="indent">
      We also stress in particular the draft letter to the Georgia legislature authored by Jeffrey Clark and another Trump political appointee at the Department of Justice. The draft letter embraces many of the same theories that John Eastman and others were asserting in President Trump’s effort to lobby State legislatures. White House Counsel Pat Cipollone described that letter as “a murder-suicide pact,” and other White House and Justice Department officials offered similar descriptions.
      <Citation number={605} />
      As described herein, that draft letter was intended to help persuade a State legislature to change its certified slate of electoral college electors based on false allegations of fraud, so Vice President Pence could unilaterally and unlawfully decide to count a different slate on January 6th.
      <Citation number={606} />
      The letter was transparently false, improper, and illegal. President Trump had multiple communications with Clark in the days before January 6th, and there is no basis to doubt that President Trump offered Clark the position of Acting Attorney General knowing that Clark would send the letter and others like it.
      <Citation number={607} />
    </Typography>

    <Typography className="indent">
      Of course, President Trump is also responsible for recruiting tens of thousands of his supporters to Washington for January 6th, and knowing they were angry and some were armed, instructing them to march to the Capitol and “fight like hell.”
      <Citation number={608} />
      And then, while knowing a violent riot was underway, he refused for multiple hours to take the single step his advisors and supporters were begging him to take to halt the violence: to make a public statement instructing his supporters to disperse and leave the Capitol.
      <Citation number={609} />
      Through action and inaction, President Trump corruptly obstructed, delayed, and impeded the vote count.
    </Typography>

    <Typography className="indent">
      In addition, the Committee believes sufficient evidence exists for a criminal referral of John Eastman and certain other Trump associates under 18 U.S.C. §1512(c). The evidence shows that Eastman knew in advance of the 2020 election that Vice President Pence could not refuse to count electoral votes on January 6th.
      <Citation number={610} />
      In the days before January 6th, Eastman was warned repeatedly that his plan was illegal and “completely crazy,” and would “cause riots in the streets.”
      <Citation number={611} />
      Nonetheless, Eastman continued to assist President Trump’s pressure campaign in public and in private, including in meetings with the Vice President and in his own speech at the Ellipse on January 6th. And even as the violence was playing out at the Capitol, Eastman admitted in writing that his plan violated the law but pressed for Pence to do it anyway.
      <Citation number={612} />
      In the immediate aftermath of January 6th, White House lawyer Eric Herschmann told Eastman that he should “[g]et a great F'ing criminal defense lawyer, you’re going to need it.”
      <Citation number={613} />
      Others working with Eastman likely share in Eastman’s culpability. For example, Kenneth Chesebro was a central player in the scheme to submit fake electors to the Congress and the National Archives.
    </Typography>

    <Typography className="indent">
      The Committee notes that multiple Republican Members of Congress, including Representative Scott Perry, likely have material facts regarding President Trump’s plans to overturn the election. For example, many Members of Congress attended a White House meeting on December 21, 2020, in which the plan to have the Vice President affect the outcome of the election was disclosed and discussed. Evidence indicates that certain of those Members unsuccessfully sought Presidential pardons from President Trump after January 6th,
      <Citation number={614} />
      as did Eastman,
      <Citation number={615} />
      revealing their own clear consciousness of guilt.
    </Typography>
  </Page>
)
