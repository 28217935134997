import { StrictMode } from 'react';
import { HelmetProvider } from "react-helmet-async";
import { styled } from '@mui/material/styles';

import { Router } from './Router';

import { AppThemeProvider } from '../components/AppThemeProvider';
import { GlobalPlayerStateProvider } from '../components/GlobalPlayerStateProvider';

const StyledApp = styled('div')(({ theme }) => `
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${theme.palette.background.paper};
  color: ${
    theme.palette.mode === 'dark'
      ? theme.palette.common.white
      : theme.palette.common.black
  };
`);

const helmetContext = {};

export const App = () => (
  <StrictMode>
    <AppThemeProvider>
      <HelmetProvider context={helmetContext}>
        <GlobalPlayerStateProvider>
          <StyledApp>
            <Router />
          </StyledApp>
        </GlobalPlayerStateProvider>
      </HelmetProvider>
    </AppThemeProvider>
  </StrictMode>
);
