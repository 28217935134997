import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet6'));

export const Chapter411 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Even after the December 29th meeting, President Trump and those working on his behalf still wanted DOJ leadership to file <i>United States v. Pennsylvania</i>. On December 30th, Acting Attorney General Rosen had a phone call with President Trump that included a discussion about the lawsuit.
      <Citation number={239} />
      During the call, Rosen clearly explained to the President that DOJ could not file the lawsuit.
      <Citation number={240} />
      Rosen said, “This doesn’t work. There’s multiple problems with it. And the Department of Justice is not going to be able to do it.”241 According to Rosen, President Trump accepted what he said without argument.
      <Citation number={242} />
      Yet President Trump and his allies continued pressuring the Department to file the lawsuit.
    </Typography>
  </Page>
)
