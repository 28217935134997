import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet8'));

export const Chapter63 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      The Oath Keepers, founded in 2009 by Elmer Stewart Rhodes, is a far-right anti-government organization. The group targets former and current military and law enforcement for recruitment. Their name refers to the oath taken by public servants to support and defend the U.S. Constitution. The Oath Keepers’ claimed fealty to the U.S. Constitution is belied by their obsession with conspiracy theories about alleged evil-intentioned elites in the Government.
      <Citation number={157} />
      Rhodes has often spouted these conspiracy theories on <i>InfoWars</i>.
      <Citation number={158} />
    </Typography>

    <Typography className="indent">
      Over the summer of 2020, the Oath Keepers organized armed groups, ostensibly to serve as volunteer, self-appointed security at protests around the country. The Oath Keepers used the protests to draw in new recruits.
      <Citation number={159} />
      They also built muscle memory by coordinating for these events. For example, the Oath Keepers hired Michael Greene, who later coordinated Oath Keepers’ activities on January 5th and 6th, to lead security operations in multiple cities around the country.
      <Citation number={160} />
      In the early part of 2020, protests against COVID-related lockdowns served as additional growth and networking opportunities. Kellye SoRelle, a lawyer for the Oath Keepers, met the Oath Keepers at a lockdown protest in Austin, Texas in early 2020. SoRelle saw these COVID events as a “coalescing moment” for different far-right groups.
      <Citation number={161} />
    </Typography>

    <Typography className="indent">
      The “Stop the Steal” movement created another opportunity for the Oath Keepers to grow their influence. Rhodes repeatedly amplified the stolen election conspiracy theory. On November 10, 2020, he posted a “Call to Action!” on the Oath Keepers website, alleging the election was “stolen” and exhorting his followers to “refuse to EVER recognize this as a legitimate election, and refuse to recognize Biden as a legitimate winner.”
      <Citation number={162} />
      Under a section entitled “What We the People Must Do,” Rhodes quoted a “patriot from Serbia, who also loves America.” The Serbian author described how his fellow countrymen fomented a political revolution. Parts of the statement presaged the attack on the U.S. Capitol:
    </Typography>

    <Blockquote>
      <Typography>
        ...Millions gathered in our capital [<i>sic</i>]. There were no barricades strong enough to stop them, nor the police determined enough to stop them. Police and Military aligned with the people after few hours of fist-fight [<i>sic</i>]. We stormed the Parliament. And burned down fake state Television! WE WON!
        <Citation number={163} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The Oath Keepers were obsessed with the Insurrection Act—seeing it as a way for President Trump to cling to power. Rhodes believed that the President could empower militias like the Oath Keepers to enforce law and order after other Americans refused to accept President Trump’s rule.
      <Citation number={164} />
      Indeed, President Trump had been intensely interested in the Insurrection Act as a potential tool to quell the protests in summer 2020.
      <Citation number={165} />
      Rhodes wished the Act had been invoked then, but he did not give up on the fantasy.
      <Citation number={166} />
      As mentioned above, Rhodes called for President Trump to invoke the Insurrection Act during his speech in Washington on December 12, 2020.
      <Citation number={167} />
    </Typography>

    <Typography className="indent">
      That day, Rhodes also coordinated with Jericho March organizers to provide security.
      <Citation number={168} />
      He coordinated with a paramilitary group known as 1st Amendment Praetorian (1AP), to guard VIPs, including retired Lieutenant General Michael Flynn and Patrick Byrne.
      <Citation number={169} />
      Rhodes indicated that the Oath Keepers would be “working closely” with them for the event.
      <Citation number={170} />
    </Typography>

    <Typography className="indent">
      The Oath Keepers continued to call for President Trump to invoke the Insurrection Act throughout December 2020, arguing that the President needed to do so to “Stop the Steal.”
      <Citation number={171} />
      This fantasy reflected a warped sense of reality. Rhodes testified that President Trump could have mobilized “unorganized militia,” including the Oath Keepers, to suppress an insurrection if he attempted to stay in power after losing the election.
      <Citation number={172} />
      But the Oath Keepers themselves were the ones contemplating insurrection. On December 10, 2020, Rhodes messaged others: “Either Trump gets off his ass and uses the Insurrection Act to defeat the Chicom puppet coup or we will have to rise up in insurrection (rebellion) against the ChiCom puppet Biden. Take your pick.”
      <Citation number={173} />
      Rhodes was blunt in other messages to the Oath Keepers, writing: “We need to push Tump [<i>sic</i>] to do his duty. If he doesn’t, we will do ours. Declare Independence. Defy[,] Resist[,] Defend[,] Conquer or Die. This needs to be our attitude.”
      <Citation number={174} />
    </Typography>
  </Page>
)
