import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet8'));

export const Chapter612 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      As discussed above, Alex Jones was one of the loudest supporters of the “Stop the Steal” movement. Jones dedicated much of the December 20th episode of his <i>InfoWars</i> show to President Trump’s “be wild” tweet, telling his listeners that nothing less than the fate of the American Republic was at stake. “He’s calling you, he needs your help, we need your help,” Jones told his audience.
      <Citation number={395} />
      The Select Committee has learned that, between the time of the President’s tweet and Jones’s December 20th show, Jones’s staff had several calls with Chafian, who had just procured a new permit for WFAF’s event on the Ellipse.
      <Citation number={396} />
      The two parties apparently discussed whether this newly hatched January 6th event was an opportunity to work together.
      <Citation number={397} />
    </Typography>

    <Typography className="indent">
      Jones’s broadcast also led to an influx of funds for the January 6th event at the Ellipse. Julie Fancelli is the billionaire heiress to the Publix supermarket fortune and a longtime supporter of President Trump.
      <Citation number={398} />
      Fancelli had recently become a donor to Jones’s <i>InfoWars</i> site.
      <Citation number={399} />
      She listened to Jones’s December 20th show,
      <Citation number={400} />
      and decided she wanted to back the cause.
    </Typography>

    <Typography className="indent">
      Inspired by Jones and the fervor that continued to surround the President’s tweet, Fancelli called Caroline Wren, a Republican fundraiser linked to the Trump Campaign, the next day.
      <Citation number={401} />
      According to Wren, Fancelli said that “she wanted to see a lot of people there in DC, so how much would that cost?”
      <Citation number={402} />
      Fancelli spoke with Jones’s staff and they recommended that she connect with Chafian, who was organizing the Ellipse rally.
      <Citation number={403} />
      In the waning days of 2020, Fancelli and Jones spoke several times.
      <Citation number={404} />
    </Typography>

    <Typography className="indent">
      Fancelli worked with Wren to create a multimillion-dollar budget to convene as many supporters of President Trump as possible.
      <Citation number={405} />
      To ensure that Fancelli’s dollars made maximum impact, Wren contacted some of the major players who were rallying supporters for January 6th. Wren emailed Kylie Kremer
      <Citation number={406} />
      and exchanged texts with Jones
      <Citation number={407} />
      and Chafian.
      <Citation number={408} />
      Fancelli’s goal was clear: she wanted to spend $3 million to “get as many people there as possible.”
      <Citation number={409} />
      The resulting budget allocated $500,000 to a busing program and a centralized ad campaign by the Tea Party Express to promote the event.
      <Citation number={410} />
      Another $500,000 went to assisting WFAF and Jones in their organizational efforts.
      <Citation number={411} />
    </Typography>

    <Typography className="indent">
      Caroline Wren also connected with Ali Alexander. On December 29th, Wren told the Stop the Steal leader, “I can pay for the buses and I have my team looking for available companies, so let me know what cities you need them in!”
      <Citation number={412} />
      Wren’s offer came in response to a tweet from Alexander earlier that day: “Coalition of us working on 25 new charter buses to bring people FOR FREE to #JAN6 #STOPTHESTEAL for President Trump. If you have money for more buses or have a company, let me know. We will list our buses sometime in the next 72 hours. STAND BACK & STAND BY!”
      <Citation number={413} />
    </Typography>

    <Typography className="indent">
      The final words of Alexander’s tweet directly echoed President Trump’s command to the Proud Boys during the September 29, 2020, presidential debate.
      <Citation number={414} />
      Alexander’s word choice was apt. The Proud Boys were already planning to show up in force, and to ensure that the crowd would be “wild.”
    </Typography>
  </Page>
)
