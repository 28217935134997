import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet6'));

export const Chapter48 = ({ ...args }: any) => (
  <Page {...args}>
    <Image
      src="/images/image-059.jpg"
      alt="Representative Scott Perry, November 14, 2022."
      caption="Representative Scott Perry, November 14, 2022."
      credit="(Photo by Anna Moneymaker/Getty Images)"
      style={{ marginTop: 0 }}
    />

    <Typography className="indent">
      Toward the end of the December 27th call, President Trump asked Donoghue for his cell number.
      <Citation number={164} />
      Later that day, Representative Perry called Donoghue to press the President’s case.
      <Citation number={165} />
      Representative Perry was one of President Trump’s key congressional allies in the effort to overturn the election’s results. Representative Perry was an early supporter of the “Stop the Steal” campaign and, as noted above, addressed the crowd at one such event outside the Pennsylvania State capitol in Harrisburg on November 5, 2020.
      <Citation number={166} />
      Representative Perry was also one of 27 Republican Congressmen who signed a letter requesting that President Trump “direct Attorney General Barr to appoint a Special Counsel to investigate irregularities in the 2020 election.” The letter was dated December 9, 2020—more than 1 week after Barr told the press there was no evidence of significant fraud.
      <Citation number={167} />
      There was no reason to think that a Special Counsel was warranted. Representative Perry and the other congressmen advocated for one to be appointed anyway.
    </Typography>

    <Typography className="indent">
      Representative Perry attended the December 21st Oval Office meeting along with at least 10 other congressional Republicans to discuss the strategy for objecting to the electoral college votes on January 6th. Along with 125 other Republican Members of Congress. Representative Perry also supported Texas’s lawsuit against Pennsylvania and three other states.
      <Citation number={168} />
      That is, Representative Perry supported Texas’s effort to nullify the certified electoral college vote from four states, including his own home state.
    </Typography>

    <Typography className="indent">
      Donoghue took notes during his conversation with Representative Perry and provided those notes to the Select Committee.
      <Citation number={169} />
      The notes reflect that when Representative Perry called Donoghue on December 27th, Representative Perry explained that President Trump asked him to call and that he, Representative Perry, did not think DOJ had been doing its job on the election.
      <Citation number={170} />
      Representative Perry brought up other, unrelated matters and argued that the “FBI doesn’t always do the right thing in all instances.”
      <Citation number={171} />
      Representative Perry also brought up Jeff Clark. He said he liked him and thought that Clark “would do something about this,” meaning the election fraud allegations.
      <Citation number={172} />
    </Typography>

    <Typography className="indent">
      On the evening of December 27th, Representative Perry emailed Donoghue a set of documents alleging significant voting fraud had occurred in Pennsylvania.
      <Citation number={173} />
      One document asserted that election authorities had counted 205,000 more votes than had been cast.
      <Citation number={174} />
      Representative Perry also shared this same claim on Twitter the following day.
      <Citation number={175} />
      President Trump kept raising the same claim. Sometimes there was an alleged discrepancy of 205,000 votes, other times it was supposedly 250,000 votes.
      <Citation number={176} />
      Either way, it was not true.
    </Typography>

    <Typography className="indent">
      Acting Deputy Attorney General Donoghue forwarded Representative Perry’s email to Scott Brady, who was the U.S. Attorney for the Western District of Pennsylvania at the time.
      <Citation number={177} />
      As Brady soon discovered, there was no discrepancy.
      <Citation number={178} />
      President Trump’s supporters came up with the claim by comparing the Pennsylvania Secretary of State’s website, which reported the total number of votes as 5.25 million, to a separate State election registry, which showed only 5 million votes cast.
      <Citation number={179} />
      The problem was simple: Pennsylvania’s election site had not been updated.
      <Citation number={180} />
      The totals for four counties had not yet been reported on the election site. Once those votes were counted on the site, the totals matched. This was simply not an example of fraud, as President Trump, Representative Perry and others would have it.
    </Typography>
  </Page>
)
