import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet6'));

export const Chapter415 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
     The most senior DOJ officials at the end of President Trump’s term stopped him from co-opting America’s leading law enforcement agency for his own corrupt purposes. Recall that Attorney General Barr commented “you can’t live in a world where the incumbent administration stays in power based on its view, unsupported by specific evidence, that the election—that there was fraud in the election.
     <Citation number={327} />
    </Typography>

    <Typography className="indent">
      Richard Donoghue concluded that Jeffrey Clark’s letter “may very well have spiraled us into a constitutional crisis.”
      <Citation number={328} />
    </Typography>

    <Typography className="indent">
      Jeffrey Rosen summed up his short time as the Acting Attorney General like this:
    </Typography>

    <Blockquote>
      <Typography>
        [D]uring my tenure, we appointed no special prosecutors, we sent no letters to States or State legislators disputing the election outcome; we made no public statements saying the election was corrupt and should be overturned; we initiated no Supreme Court actions, nor filed or joined any other lawsuits calling into question the legitimacy of our election and institutions.
        <Citation number={329} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      President Trump attempted to get DOJ to do each of those things.
    </Typography>
  </Page>
)
