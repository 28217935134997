import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet9'));

export const Chapter77 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Minutes after drawing increased attention to his besieged Vice President, the President called newly elected Senator Tommy Tuberville of Alabama at 2:26 p.m.
      <Citation number={179} />
      He misdialed, calling Senator Mike Lee of Utah instead, but one passed the phone to the other in short order.
      <Citation number={180} />
    </Typography>

    <Typography className="indent">
      President Trump wanted to talk objections to the electoral count. But Senator Tuberville—along with every other elected official trapped and surrounded in the building—had other things on his mind.
      <Citation number={181} />
    </Typography>

    <Typography className="indent">
      “I said, ‘Mr. President, they’ve taken the Vice President out. They want me to get off the phone, I gotta go,’” Senator Tuberville told reporters.
      <Citation number={182} />
      “‘[W]e’re not doing much work here right now.’”
      <Citation number={183} />
    </Typography>

    <Typography className="indent">
      In the next half hour, between 2:26 p.m. and 3:06 p.m., President Trump spoke with House Leader Kevin McCarthy.
      <Citation number={184} />
    </Typography>

    <Typography className="indent">
      Leader McCarthy told the public in a live interview with CBS News, while he and his colleagues were sheltering at a secure location,
      <Citation number={185} />
      that he was “very clear” in telling President Trump “to talk to the nation to tell them to stop this.”
      <Citation number={186} />
    </Typography>

    <Typography className="indent">
      Leader McCarthy later recounted his conversation to a number of people, including Representative Jaime Herrera Beutler, a Republican congresswoman from Washington State.
      <Citation number={187} />
      “You have got to get on TV, you’ve got to get on Twitter, you’ve got to call these people off,” he said he told the President.
      <Citation number={188} />
    </Typography>

    <Typography className="indent">
      “[These] aren’t my people, you know, these are—these are Antifa,” President Trump insisted, against all evidence.
      <Citation number={189} />
      “They’re your people. They literally just came through my office windows, and my staff are running for cover. I mean, they’re running for their lives. You need to call them off,” Leader McCarthy told him.
      <Citation number={190} />
    </Typography>

    <Typography className="indent">
      What President Trump said next was “chilling,” in Representative Herrera Beutler’s words.
      <Citation number={191} />
    </Typography>

    <Typography className="indent">
      “Well, Kevin, I guess they’re just more upset about the election theft than you are,” the President said.
      <Citation number={192} />
    </Typography>

    <Typography className="indent">
      The call then devolved into a swearing match.
      <Citation number={193} />
    </Typography>

    <Typography className="indent">
      Mick Mulvaney, former Chief of Staff to President Trump, had a similar call with Leader McCarthy in the days after the attack. McCarthy told Mulvaney that he urged the President to get the rioters to stop, and the President replied, “Kevin, maybe these people are just more angry about this than you are.”
      <Citation number={194} />
    </Typography>

    <Typography className="indent">
      Marc Short, the Vice President’s Chief of Staff, spoke with Leader McCarthy later that afternoon.
      <Citation number={195} />
      Leader McCarthy told Short that he had spoken with President Trump and that he was “frustrat[ed]” that the White House was “not taking the circumstance as seriously as they should at that moment.”
      <Citation number={196} />
      The administration was demonstrating a “lack of response or lack of responsibility,” Leader McCarthy told Short.
      <Citation number={197} />
    </Typography>

    <Typography className="indent">
      At 2:49 p.m.—as the violence escalated—President Trump’s speechwriter Gabriel Robert texted someone: “Potus im sure is loving this.”
      <Citation number={198} />
    </Typography>
  </Page>
)
