import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet10'));

export const Chapter87 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      After Dominic Pezzola and others breached the Capitol at 2:13 p.m., a mob quickly entered and headed towards the Senate and House Chambers, where Members were meeting.
      <Citation number={244} />
      As the crowd moved through the Capitol, they chanted “Fight for Trump” and “Stop the Steal!” They also chanted “Nancy, Nancy” as they searched for Speaker Pelosi.
      <Citation number={245} />
      At 2:18 p.m., the House went into recess as hundreds of rioters confronted USCP officers inside the Crypt, which is a short distance from the first breach point.
      <Citation number={246} />
    </Typography>

    <Typography className="indent">
      USCP officers formed a line across the Crypt in an attempt to stop the mob’s advance.
      <Citation number={247} />
      By 2:21 p.m., the rioters had tried to break through police lines, but they were temporarily unsuccessful.
      <Citation number={248} />
    </Typography>

    <Typography className="indent">
      As USCP officers held the line inside the Crypt, President Trump poured fuel on the fire, tweeting at 2:24 p.m.:
    </Typography>

    <Blockquote>
      <Typography>
        “Mike Pence didn’t have the courage to do what should have been done to protect our Country and our Constitution, giving states a chance to certify a corrected set of facts, not the fraudulent or inaccurate ones which they were asked to previously certify. USA demands the truth!”
        <Citation number={249} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      One minute later, the mob violently pushed through the USCP officers in the Crypt and continued moving south towards the House Chamber.
      <Citation number={250} />
      Joshua Pruitt, the Proud Boy dressed in a Punisher shirt, was at the front of the line as rioters broke through in the Crypt.
      <Citation number={251} />
      Officer David Millard told the Select Committee that rioters in the Crypt claimed they were in the Capitol because their “boss” told them to be there—meaning President Trump.
      <Citation number={252} />
      Officer Millard also recalled members of the mob telling him they were there to stop the steal.
      <Citation number={253} />
    </Typography>

    <Typography className="indent">
      After breaking through the police line in the Crypt, the mob pursued USCP officers as they retreated to the U.S. Capitol Visitor’s Center (CVC). Pruitt was among the rioters who advanced into the CVC, where he came close to Senator Chuck Schumer.
      <Citation number={254} />
      When the USCP officers attempted to lower metal barriers to halt the crowd’s momentum, another small group of Proud Boys immediately interceded to prevent the barricades from coming down.
      <Citation number={255} />
      The Proud Boy contingent included three men from the Kansas City, Kansas area: William Chrestman,
      <Citation number={256} />
      Chris Kuehne,
      <Citation number={257} />
      and Louis Colon.
      <Citation number={258} />
      Felicia Konold and Cory Konold, two Proud Boy associates from Arizona, joined the Kansas City group while marching from the Washington Monument to the Capitol earlier in the day and were on the scene.
      <Citation number={259} />
      Two other Proud Boys, Nicholas Ochs and Nicholas DeCarlo, filmed the incident.
      <Citation number={260} />
    </Typography>

    <Typography className="indent">
      Surveillance footage shows Chrestman using a wooden club, or modified axe handle, to prevent the barrier from being lowered to the floor.
      <Citation number={261} />
      Colon later admitted to authorities that he purchased and modified an axe handle “to be used as both a walking stick and an improvised weapon” on January 6th.
      <Citation number={262} />
      Colon also told authorities that he attended a meeting with Chrestman and others on the night of January 5th, during which someone asked, “do we have patriots here willing to take it by force?” Colon understood that the individual meant that they should use “force against the government.” This same individual commented that they should “go in there and take over.”
      <Citation number={263} />
    </Typography>

    <Image
      src="/images/image-110.jpg"
      alt="Rioters enter the Senate Chamber."
      caption="Rioters enter the Senate Chamber."
      credit="(Photo by Win McNamee/Getty Images)"
    />

    <Typography className="indent">
      At 2:36 p.m., the mob pushed through a line of USCP officers guarding the House Chamber.
      <Citation number={264} />
      Rioters also entered the Senate Chamber.
      <Citation number={265} />
      Within minutes, Jacob Chansley (a.k.a. the QAnon Shaman) entered the Senate Chamber, making his way to the Senate dais, where Vice President Pence had been presiding over the joint session. An officer asked Chansley to vacate the dais, but instead he shouted, “Mike Pence is a fucking traitor.” Chansley also left a note that read: “It’s Only a Matter of Time. Justice is Coming!”
      <Citation number={266} />
      Surrounded by others, Chansley held a conspiracy-laden prayer session, saying: “Thank you for allowing the United States of America to be reborn. Thank you for allowing us to get rid of the communists, the globalists, and the traitors within our government.”
      <Citation number={267} />
      Other extremists, including at least one associate of the white nationalist “America First” movement, also sat in the Vice President’s seat.
      <Citation number={268} />
    </Typography>

    <Typography className="indent">
      While law enforcement fought to contain the mob inside the Capitol, the fighting raged outside as well. Key agitators continued to fire up the crowd. Nick Fuentes, the leader of the “America First” movement, amplified President Trump’s rhetoric aimed at Vice President Pence, including the President’s 2:24 p.m. tweet.
      <Citation number={269} />
      Speaking through a bullhorn while standing on the Peace Monument, Fuentes shouted:
    </Typography>

    <Blockquote>
      <Typography>
        We just heard that Mike Pence is not going to reject any fraudulent elector votes! That’s right, you heard it here first: Mike Pence has betrayed the United States of America. Mike Pence has betrayed the President and he has betrayed the people of the United States of America—and we will never ever forget!
        <Citation number={270} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      As rioters flowed through the halls and offices inside the Capitol, others broke through the defensive lines of USCP and MPD officers on the lower West Plaza at 2:28 p.m., allowing them to take over the inauguration stage.
      <Citation number={271} />
      According to MPD Officer Michael Fanone, MPD officers were then forced to conduct the “first fighting withdrawal” in the history of the force, with law enforcement seeking to “reestablish defensive lines” to prevent the “crowd that had swelled to approximately 20,000 from storming the U.S. Capitol.”
      <Citation number={272} />
    </Typography>

    <Typography className="indent">
      After surging through the West Plaza, rioters quickly headed towards the West Plaza tunnel. The violence that escalated at 2:28 p.m. on the lower West Plaza continued as rioters reached the tunnel. By 2:41 p.m., law enforcement retreated inside the tunnel, allowing rioters to slowly fill in.
      <Citation number={273} />
      Just ten minutes later, the mob jammed the tunnel, desperately trying to break through the police lines.
      <Citation number={274} />
      The fighting in and immediately outside of the tunnel raged for over two hours.
      <Citation number={275} />
    </Typography>

    <Typography className="indent">
      Throughout the afternoon, members of the mob struck officers with weapons, shot them with OC (or pepper) spray, and dragged officers from the tunnel into the crowd. Lucas Denney, a Three Percenter from Texas who carried a baton on January 6th, pushed a riot shield into and on top of police officers at the tunnel. The crowd chanted “heave-ho!” as Denney did so.
      <Citation number={276} />
      Jeffrey Scott Brown sprayed a chemical or pepper spray at officers and pushed the front of the line in the tunnel.
      <Citation number={277} />
      Kyle Young, a January 6th defendant with a long prior criminal history, participated in multiple assaults and violence at the tunnel, including using a pole to jab at police officers.
    </Typography>

    <Typography className="indent">
      Young’s 16-year-old son was present during the fighting.
      <Citation number={278} />
      Robert Morss, a former Army Ranger who wore a military-style vest, participated in a heave-ho effort in the tunnel where he and rioters had created a shield wall.
      <Citation number={279} />
      Peter Schwartz and another rioter passed a large cannister of spray back and forth before Schwartz’s companion sprayed officers and then the two joined in the heave-ho.
      <Citation number={280} />
    </Typography>

    <Image
      src="/images/image-111.jpg"
      alt="Rioters assault police officers at a tunnel to the Capitol."
      caption="Rioters assault police officers at a tunnel to the Capitol."
      credit="(Photo by Brent Stirton/Getty Images)"
    />

    <Typography className="indent">
      One of the most brutal attacks of the day occurred outside the tunnel when rioters dragged MPD Officer Michael Fanone into the crowd, and then tased, beat, and robbed him while a Blue Lives Matter flag fluttered above him. Albuquerque Head, a rioter from Tennessee, grabbed Officer Fanone around the neck and pulled him into the mob.
      <Citation number={281} />
      “I got one!” Head shouted.
      <Citation number={282} />
      Lucas Denney, the Three Percenter, “swung his arm and fist” at Officer Fanone, grabbed him, and pulled him down the stairs.
      <Citation number={283} />
      Daniel Rodriguez then tased him in the neck. Kyle Young lunged towards Officer Fanone, restraining the officer’s wrist.
      <Citation number={284} />
      While Young held him, still another rioter, Thomas Sibick, reached towards him and forcibly removed his police badge and radio.
      <Citation number={285} />
      Officer Fanone feared they were after his gun. Members of the crowd yelled: “Kill him!,” “Get his gun!” and “Kill him with his own gun!”
      <Citation number={286} />
    </Typography>

    <Typography className="indent">
      In an interview with FBI agents, Daniel Rodriguez admitted his role in the attack on Officer Fanone.
      <Citation number={287} />
      During that same interview, Rodriguez discussed the influences that led him down the path to January 6th. Rodriguez was a fan of Alex Jones’s InfoWars and told FBI agents that he became active at rallies after watching the conspiracy show.
      <Citation number={288} />
      Rodriguez was motivated by Jones’s decision to support then candidate Trump in 2015.
      <Citation number={289} />
      He also began to affiliate himself with the Three Percenter movement, which he learned about by watching InfoWars.
      <Citation number={290} />
      And when President Trump called for a “wild” protest in Washington on January 6th, Rodriguez thought it was necessary to respond. “Trump called us. Trump called us to DC,” Rodriguez told interviewing agents.
      <Citation number={291} />
      “If he’s the commander in chief and the leader of our country, and he's calling for help—I thought he was calling for help,” Rodriguez explained. “I thought he was—I thought we were doing the right thing.”
      <Citation number={292} />
    </Typography>

    <Typography className="indent">
      Rodriguez and another January 6th defendant, Edward Badalian, began preparing for violence after President Trump’s December 19th tweet. They gathered weapons and tactical gear
      <Citation number={293} />
      and discussed their plans in a Signal chat named, “Patriots 45 MAGA Gang.”
    </Typography>

    <Typography className="indent">
      “Congress can hang. I’ll do it,” Rodriguez posted to the chat. Please let us get these people dear God.” 
      <Citation number={294} />
    </Typography>

    <Typography className="indent">
      Badalian also posted a flyer titled “MAGA_CAVALRY,” which showed rally points for “patriot caravans” to connect with the “Stop The Steal” movement in DC.
      <Citation number={295} />
      The same flyer was popular among Three Percenters and other self-described “patriot” groups. It also garnered the attention of law enforcement. The FBI’s Norfolk, Virginia division noted in a January 5th intelligence assessment that the flyer was accompanied by another image, titled “Create Perimeter,” which depicted the U.S. Capitol and other buildings being surrounded by the same caravans.
      <Citation number={296} />
    </Typography>
  </Page>
)
