import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';
import { StyledListContainer } from '../../../components/StyledListContainer';

const Citation = lazy(() => import('../../Citations/CitationSheet4'));

export const Chapter27 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Many of the people who refused to be pushed into manipulating election results—governors, secretaries of State, State legislators, State and local election officials, and frontline election workers just doing their jobs— found themselves subjected to public demonization and subsequent spamming, doxing, harassment, intimidation, and violent threats. Some of the threats were sexualized or racist in nature and targeted family members. President Trump never discouraged or condemned these tactics, and in fact he was an active participant in directing his supporters, through tweets and speeches, to apply pressure to public servants who would not comply.
    </Typography>

    <Typography className="indent">
      President Trump and his team were not above using incendiary rhetoric or threats to achieve their goal of overturning the election. Giuliani said so before the purported hearing in Michigan in December. Recall that he told an online audience, there’s “nothin’ wrong with putting pressure on your state legislators”
      <Citation number={307} />
      and “you have got to get them to remember that their oath to the Constitution sometimes requires being criticized. Sometimes it even requires being threatened.”
      <Citation number={308} />
    </Typography>

    <Typography className="indent">
      That pressure came privately and publicly in the post-election period.
    </Typography>

    <Typography className="indent">
      Privately, for example, President Trump called Michigan Senate Majority Leader Mike Shirkey three times after their White House meeting: November 21st, November 25th, and December 14th.
      <Citation number={309} />
      Shirkey did not recall many specifics of those calls and claimed he did not remember the President applying any specific pressure.
      <Citation number={310} />The day after one of those calls, however, Shirkey tweeted that “our election process MUST be free of intimidation and threats,” and “it’s inappropriate for anyone to exert pressure on them.”
      <Citation number={311} />From this and other public statements, it is clear that Shirkey was sensitive to outside forces pressuring people with roles in the election. In fact, the same day that the electoral college met and voted former Vice President Joe Biden as the winner of the 2020 Presidential election, Shirkey received another call from President Trump and issued another public statement. Shirkey’s statement that day, December 14, 2020, read: “Michigan’s Democratic slate of electors should be able to proceed with their duty, free from threats of violence and intimidation” and “[i]t is our responsibility as leaders to follow the law....”
      <Citation number={312} />
    </Typography>

    <Typography className="indent">
      Publicly, President Trump used both Twitter posts and paid social media and cable television ads to advance his pressure campaign.
    </Typography>

    <Typography className="indent">
      In Arizona, for example, President Trump used social media to both praise and criticize legislators. When Speaker Bowers and Senate President Karen Fann requested an audit of Maricopa County’s election software and equipment, President Trump publicly commended them, retweeting a press release about their announcement and commenting: “Thank you to Senate President Karen Fann and House Speaker Russell Bowers—and all, for what you are doing in Arizona. A fast check of signatures will easily give us the state.”
      <Citation number={313} />
      But just days later, President Trump assailed Bowers for opposing a special session to appoint new electors. He retweeted a post by Campaign lawyer Christina Bobb that accused Bowers of “intentionally misleading the people of Arizona” and that included a demand by Stop-the-Steal organizer Ali Alexander for 50,000 phone calls to Rusty Bowers “[r]ight the heck now” to threaten him with a primary challenge.
      <Citation number={314} />
    </Typography>

    <Typography className="indent">
      And, as his efforts to change the outcome of the election continued to meet resistance, President Trump personally approved a series of advertisements that the Campaign ran on cable television and social media in several important States. One advertisement in Arizona called for pressure on Governor Ducey in particular, alleging, “The evidence is overwhelming. Call Governor Ducey and your legislators. Demand they inspect the machines and hear the evidence.”
      <Citation number={315} />
      Another claimed that “illegal aliens voted, and here in Arizona Trump votes were discarded. It’s an outrage. Call Governor Ducey and your legislators at 602–542–4331. Demand they inspect the machines and hear the evidence. Call Governor Ducey, at 602– 542–4331. Stand up for President Trump. Call today. Paid for by Donald J. Trump for President, Inc.”
      <Citation number={316} />
    </Typography>

    <Typography className="indent">
      Several days earlier, Trump Campaign Senior Advisor Jason Miller had explained the intention for this round of advertisements in an email. He wrote that, “the President and Mayor Giuliani want to get back up on TV ASAP, and Jared [Kushner] has approved in budgetary concept, so here’s the gameplan” in order to “motivate the GOP base to put pressure on the Republican Governors of Georgia and Arizona and the Republican-controlled State legislatures in Wisconsin and Michigan to hear evidence of voter fraud before January 6th.”
      <Citation number={317} />
      Miller anticipated a budget of $5 million and asked for the messaging to follow an earlier round of advertisements, “but the endings need to be changed to include phone numbers and directions to call the local Governor or state legislature.”
      <Citation number={318} />
      On December 22nd, Jason Miller texted Jared Kushner that “POTUS has approved the buy.”
      <Citation number={319} />
    </Typography>

    <Typography className="indent">
      References to anger and fighting were featured in some of the President’s remarks during that period. After the Georgia Secretary of State’s Chief Operating Officer, Gabriel Sterling, made an impassioned public plea and accurately warned that someone would die as a result of the threatening election-related rhetoric that President Trump failed to condemn, President Trump dismissively tweeted in response: “Rigged Election. Show signatures and envelopes. Expose the massive voter fraud in Georgia. What is Secretary of State and @BrianKempGA afraid of. They know what we’ll find!!!”
      <Citation number={320} />
      The President also tweeted that, between Governor Ducey in Arizona and Governor Kemp in Georgia, “the Democrat Party could not be happier” because these Republicans “fight harder against us than do the Radical Left” and were singlehandedly responsible for losing him both States, something that “Republicans will NEVER forget[.]”
      <Citation number={321} />
      Regarding Kemp, he asked “What’s wrong with this guy? What is he hiding?”
      <Citation number={322} />
      and he alleged that “RINOs” Governor Kemp, Lieutenant Governor Geoff Duncan, and Secretary Raffensperger “will be solely responsible” for Senators Loeffler and Perdue losing their senate runoff because they “[w]on’t call a Special Session or check for Signature Verification! People are ANGRY!”
      <Citation number={323} />
    </Typography>

    <Typography className="indent">
      President Trump’s spoken remarks were not much different. After the President wrapped up a November 26th public phone call to wish U.S. service members a happy Thanksgiving, he answered a reporter’s question about election integrity in Georgia by lashing out at Secretary Raffensperger in particular. President Trump made several baseless claims of election fraud in Georgia, declared that Raffensperger himself appeared to be complicit, and labeled the Georgia Secretary of State “an enemy of the people.”
      <Citation number={324} />
    </Typography>

    <Typography className="indent">
      President Trump and his team’s practice of naming and viciously criticizing people had real consequences. Philadelphia City Commissioner Al Schmidt’s story, recounted earlier, is just one of many examples. And the consequences weren’t just limited to high-profile public figures. Schmidt’s deputy, for example, Seth Bluestein faced threats after being demonized by a surrogate for President Trump, and many of the threats he received were anti-Semitic in nature. He received a Facebook message telling him that “EVERYONE WITH A GUN IS GOING TO BE AT YOUR HOUSE- AMERICANS LOOK AT THE NAME- ANOTHER JEW CAUGHT UP IN UNITED STATES VOTER FRAUD.”
      <Citation number={325} />
      Bluestein got a security detail at his home, and the experience gave his three-year-old daughter nightmares.
      <Citation number={326} />
    </Typography>

    <Typography className="indent">
      Similarly, after President Trump promoted online accusations that Arizona House Speaker Rusty Bowers had been “intentionally misleading the people of Arizona...”
      <Citation number={327} />
      Bowers’s personal cell phone and home address were published,
      <Citation number={328} />
      leading demonstrators to congregate at his home, honk horns and shout insults until police arrived.
      <Citation number={329} />
      Bowers told the Select Committee this was the first of at least nine protests at his home, sometimes with protesters shouting into bullhorns and calling him a pedophile.
      <Citation number={330} />
      One protestor who showed up at his home was armed and believed to be a member of an extremist militia.
      <Citation number={331} />
    </Typography>

    <Typography className="indent">
      Sadly, those were not isolated incidents. Stories similar to Schmidt’s and Bowers’ proliferated after President Trump’s loss in the election. Examples from each of the States discussed in this chapter are documented below, but this list is by no means exhaustive:
    </Typography>

    <StyledListContainer>
      <ul>
        <li>
          <Typography>
            <i>Arizona</i>: After Secretary of State Katie Hobbs’s home address and son’s phone number were publicly released, demonstrators congregated outside her home chanting “we are watching you.”
            <Citation number={332} />
            A social media user at the time recommended: “Let’s burn her house down and kill her family and teach these fraudsters a lesson.”
            <Citation number={333} />
            Secretary Hobbs has continued to receive threats since then, reporting over 100 threats to the FBI in mid-2022, including a September 2021 voicemail message that “you should be hunted” and “will never be safe in Arizona again.”
            <Citation number={334} />
          </Typography>
        </li>
        <li>
          <Typography>
            <i>Arizona</i>: Maricopa County Recorder Adrian Fontes testified before Congress that his family had “go-bags” packed in case they needed to evacuate and that, because of the threats, he had moved his children “out of the family home at least once for three days in the wake of serious threats to [his] family’s safety.”
            <Citation number={335} />
          </Typography>
        </li>
        <li>
          <Typography>
            <i>Arizona</i>: Paul Boyer, a Republican State senator, had to evacuate his family, get police protection, and change his phone number after he voted against jailing Maricopa’s County Supervisors over election disputes.
            <Citation number={336} />
          </Typography>
        </li>
        <li>
          <Typography>
            <i>Arizona</i>: On January 5, 2021, a comment on a blog suggested some members of the Maricopa County Board of Supervisors “have earned a good old fashioned neck tie party” as “punishment for Treason.”
            <Citation number={337} />
            According to Board member Clint Hickman, “the threats never abated.”
            <Citation number={338} />
            And on January 6th, police convinced Hickman and his family to leave their home.
            <Citation number={339} />
          </Typography>
        </li>
        <li>
          <Typography>
            <i>Michigan</i>: Secretary of State Jocelyn Benson and her family were driven out of their home for several days after dozens of protestors with bullhorns and firearms congregated outside “shouting obscenities and graphic threats into bullhorns”
            <Citation number={340} />
            while she spent time with her son and got him ready for bed.
            <Citation number={341} />
            Secretary Benson said that she only feels safe “sometimes” as a result of continuing threats.
            <Citation number={342} />
          </Typography>
        </li>
        <li>
          <Typography>
            <i>Michigan</i>: Several members of the Wayne County Board of Canvassers received threats, as did Aaron Van Langevelde, a Republican member of the State Board of Canvassers.
            <Citation number={343} />
            Van Langevelde was bombarded with communications and people began showing up at his family's home, forcing police to ensure his and his family’s safety and escort him across the State after he voted to certify Michigan's election.
            <Citation number={344} />
          </Typography>
        </li>
        <li>
          <Typography>
            <i>Michigan</i>: Detroit City Clerk Janice Winfrey, a Democrat, and Rochester Hills City Clerk Tina Barton, a Republican, were both targeted. Barton had never before received a death threat in over a decade of work as an election official but, as a result of the 2020 Presidential election, was subject to “a torrent of threats and harassment,” such as an anonymous caller who “repeatedly threatened to kill her and her family.”
            <Citation number={345} />
            Winfrey was confronted outside her home by a man who indicated he had been surveilling her and that “You are going to pay dearly for your actions in this election!”
            <Citation number={346} />
            She started carrying a firearm because death threats against her continued.
            <Citation number={347} />
          </Typography>
        </li>
        <li>
          <Typography>
            <i>Michigan</i>: Michigan House Speaker Lee Chatfield confirmed that “I and my family have received numerous threats, along with members on both sides of the aisle.”
            <Citation number={348} />
            This included the top Democrat on Michigan’s House Oversight Committee, Rep. Cynthia Johnson, who was threatened with lynching after she challenged the witnesses that Giuliani offered to her committee.
            <Citation number={349} />
            One caller who allegedly threatened to kill Rep. Johnson and wipe out her family in December 2020 called the Capitol again on the morning of January 7, 2021, and said that “everyone better get out of the building because it’ll fucking explode.”
            <Citation number={350} />
          </Typography>
        </li>
        <li>
          <Typography>
            <i>Pennsylvania</i>: Secretary of the Commonwealth Kathy Boockvar said she received so many threats “I didn’t feel comfortable walking the dog on the street.”
            <Citation number={351} />
            This included a message in November 2020 threatening to murder her in her home at night, forcing her and her husband to flee for a week.
            <Citation number={352} />
            Another voicemail she received after certifying Pennsylvania’s election results threatened: “You crooked f***ing bitch. You’re done.”
            <Citation number={353} />
          </Typography>
        </li>
        <li>
          <Typography>
            <i>Pennsylvania</i>: House Speaker Bryan Cutler told the Select Committee there were at least three protests outside either his district office or his home, and that his then-15-year-old son was home by himself for the first one.
            <Citation number={354} />
            Senate Jake Corman’s spokesperson revealed in December 2020 that he, too, was being subjected to violent threats,
            <Citation number={355} />
            something Senate Majority Leader Kim Ward also received.
            <Citation number={356} />
          </Typography>
        </li>
        <li>
          <Typography>
            <i>Pennsylvania</i>: Philadelphia City Commissioner Omar Sabir, spent several nights evacuated from his home and continued to receive death threats a year after the 2020 election, reflecting that, “I feel anxiety every time I walk outside of the house.”
            <Citation number={357} />
            Commissioner Lisa Deeley, another City Commission colleague, also received death threats and said she suffers occasional anxiety attacks as a result.
            <Citation number={358} />
          </Typography>
        </li>
        <li>
          <Typography>
            <i>Georgia</i>: After Georgia Secretary of State Brad Raffensperger’s email and phone number were published, he said that he and his wife received frequent hostile messages, some of which “typically came in sexualized attacks.”
            <Citation number={359} />
            As a result, the Secretary’s wife cancelled visits from their grandchildren out of fear for the kids’ safety.
            <Citation number={360} />
            That was not an overreaction as that came after police found self-identified members of the Oath Keepers outside their home and after someone broke into their daughter-in-law’s house.
            <Citation number={361} />
          </Typography>
        </li>
        <li>
          <Typography>
            <i>Georgia</i>: On January 5, 2021, Governor Kemp and Secretary Raffensperger were reportedly named in a Craigslist post encouraging people to “put an end to the lives of these traitors.”
            <Citation number={362} />
          </Typography>
        </li>
        <li>
          <Typography>
            <i>Georgia</i>: Fulton County Elections Director Richard Barron was named and depicted on screen in the video President Trump played at his December 5th rally. He said that this incident led to a spike in death threats targeted at election workers, including himself.
            <Citation number={363} />
            His team’s registration chief, Ralph Jones, received death threats following the election including one calling him a “n[igger] who should be shot,” and another threatening “to kill him by dragging his body around with a truck.”
            <Citation number={364} />
          </Typography>
        </li>
        <li>
          <Typography>
            <i>Georgia</i>: Election offices in ten Georgia counties received emailed threats of bombings that would “make the Boston bombings look like child’s play” and that the “death and destruction” would continue “[u]ntil Trump is guaranteed to be POTUS....”
            <Citation number={365} />
          </Typography>
        </li>
      </ul>
    </StyledListContainer>

    <Typography className="indent">
      One of the most striking examples of the terror that President Trump and his allies caused came in Georgia, where election workers Ruby Freeman and Shaye Moss, mother and daughter, were besieged by incessant, terrifying harassment and threats that often evoked racial violence and lynching, instigated and incited by the President of the United States.
    </Typography>

    <Image
      src="/images/image-043.jpg"
      alt='Ruby Freeman and Shaye Moss'
      credit="(Photo by Kevin Dietsch/Getty Images)"
    />

    <Typography className="indent">
      As described earlier, in a State legislative hearing in Georgia, Giuliani publicly—and baselessly—accused Freeman and Moss of engaging in criminal conduct. He showed a video of Freeman passing Moss a ginger mint before claiming that the two women, both Black, were smuggling USB drives “as if they’re vials of heroin or cocaine.”
      <Citation number={366} />
    </Typography>

    <Typography className="indent">
      President Trump seemed fixated on Freeman and Moss, too. He played surveillance video showing them inside the State Farm Arena at a December 5th rally in Georgia,
      <Citation number={367} />
      and mentioned Freeman by name 18 times during the January 2nd call to Secretary of State Raffensperger in which he asked the Secretary to simply “find” enough votes to ensure victory.
      <Citation number={368} />
    </Typography>

    <Typography className="indent">
      Freeman’s and Moss’s lives were forever changed. After their contact information was published, they were besieged by the President’s supporters. In early December 2020, Freeman “told police she had received hundreds of threats at her home.”
      <Citation number={369} />
      Moss’s son also started receiving threatening phone calls, including one stating he “should hang alongside [his] nigger momma.”
      <Citation number={370} />
    </Typography>

    <Typography className="indent">
      In the wake of President Trump’s December 5, 2020, rally, Freeman called 911 because strangers had come to her home trying to lure her out, sending threatening emails and text messages.
      <Citation number={371} />
      She pleaded with the 911 dispatcher for help after hearing loud banging on her door just before 10 p.m. “Lord Jesus, where’s the police?” she asked the dispatcher. “I don’t know who keeps coming to my door.” “Please help me!”
      <Citation number={372} />
    </Typography>

    <Typography className="indent">
      Ultimately, Freeman fled from her own home based on advice from the FBI.
      <Citation number={373} />
      She would not move back for months.
      <Citation number={374} />
    </Typography>

    <Typography className="indent">
      In her testimony to the Select Committee, Freeman recounted how she had received “hundreds of racist, threatening, horrible calls and messages” and that now “[t]here is nowhere I feel safe—nowhere.”
      <Citation number={375} />
      But it’s not just a sense of security that the President and his followers took from Freeman. She told the Select Committee that she also lost her name and reputation:
    </Typography>

    <Blockquote>
      <Typography>
        My name is Ruby Freeman. I've always believed it when God says that he’ll make your name great, but this is not the way it was supposed to be. I could have never imagined the events that followed the Presidential election in 2020. For my entire professional life, I was Lady Ruby. My community in Georgia where I was born and lived my whole life knew me as Lady Ruby.... Now I won’t even introduce myself by my name anymore. I get nervous when I bump into someone I know in the grocery store who says my name. I’m worried about who’s listening. I get nervous when I have to give my name for food orders. I’m always concerned of who’s around me. I’ve lost my name, and I’ve lost my reputation.
      </Typography>
      <Typography>
        I’ve lost my sense of security—all because a group of people, starting with Number 45 and his ally Rudy Giuliani, decided to scapegoat me and my daughter Shaye to push their own lies about how the presidential election was stolen.
        <Citation number={376} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Freeman’s sense of dread is well-founded. According to Federal prosecutors, a member of the Oath Keepers militia convicted of multiple offenses for his role in the January 6th insurrection had a document in his residence with the words “DEATH LIST” written across the top.
      <Citation number={377} />
    </Typography>

    <Typography className="indent">
      His death list contained just two names: Ruby Freeman and Shaye Moss.
      <Citation number={378} />
    </Typography>
  </Page>
)
