import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet6'));

export const Chapter45 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Barr felt that he was leaving the Department in the hands of two trusted lieutenants. But President Trump immediately began to pressure Rosen and Donoghue, just as he had Barr.
    </Typography>

    <Typography className="indent">
      On December 14, 2020, the day Barr resigned, Molly Michael, Special Assistant to the President and Oval Office Coordinator, sent an email to Acting Attorney General Jeffrey Rosen. The email had two documents attached, both of which were labeled “From POTUS.”
      <Citation number={90} />
      The first was a set of talking points focused on false allegations of voter fraud in Antrim County, Michigan. The second document was the same ASOG report the President had given Barr.
      <Citation number={91} />
    </Typography>

    <Typography className="indent">
      The next day, President Trump held a meeting in the White House with Acting Attorney General Rosen, Acting Deputy Attorney Donoghue, Cipollone, Meadows, Acting Deputy Secretary of Homeland Security Ken Cuccinelli, and Acting General Counsel of the Department of Homeland Security Chad Mizelle.
      <Citation number={92} />
      Barr did not attend, even though he was not scheduled to leave DOJ until the following week. The timing of the meeting was notable, as the previous day the electoral college had met and cast their votes in favor of former Vice President Biden.
    </Typography>

    <Typography className="indent">
      During testimony before the Select Committee, Donoghue explained that the December 15th, meeting “was largely focused on” the ASOG report.
      <Citation number={93} />
      According to Donoghue, the President “was adamant that the report must be accurate, that it proved that the election was defective, that he in fact won the election, and the [D]epartment should be using that report to basically tell the American people that the results were not trustworthy.”
      <Citation number={94} />
      President Trump discussed “other theories as well,” including erroneous allegations of voter fraud in Georgia and Pennsylvania, but “the bulk of that conversation on December 15th focused on Antrim County, Michigan.”
      <Citation number={95} />
      President Trump asked why DOJ wasn’t “doing more to look at this ”and whether the Department was “going to do its job.”
      <Citation number={96} />
    </Typography>

    <Typography className="indent">
      The Department of Justice was doing its job. In fact, Attorney General Barr had ordered unprecedented investigations into the many specious claims of voter fraud. The President simply didn’t want to hear the truth— that DOJ found that not one of the bogus claims was true. As explained in Chapter 1, the original vote totals in Antrim County were the result of a human error that had since been corrected, not the result of any problem with Dominion machines or software. There was no evidence of fraud.
    </Typography>

    <Image
      src="/images/image-057.jpg"
      alt="Former Acting Deputy Attorney General Richard Donoghue testifies before the Select Committee on June 23, 2022."
      caption="Former Acting Deputy Attorney General Richard Donoghue testifies before the Select Committee on June 23, 2022."
      credit="(Photo by House Creative Services)"
    />
  </Page>
)
