import { lazy } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet3'));

export const Chapter18 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Instead of accepting his defeat, President Trump attempted to justify his Big Lie with a series of increasingly preposterous claims. The President was not simply led astray by those around him. The opposite was true. He actively promoted conspiracy theories and false election fraud claims even after being informed they were baseless. Millions of President Trump’s supporters believed the election was stolen from him. Many of them still do, but President Trump knew the truth and chose to lie about it.
    </Typography>

    <Typography className="indent">
      The power of the President’s bully pulpit should not be underestimated, especially in the digital age.
      <Citation number={139} />
      President Trump’s relentless lying sowed seeds of distrust in America’s election system. Researchers who studied this election-denial phenomenon have noted: “President Trump didn’t just prime his audience to be receptive to false narratives of election fraud—he inspired them to produce those narratives and then echoed those false claims back to them.”
      <Citation number={140} />
      Social media played a prominent role in amplifying erroneous claims of election fraud. Shortly after election day, the “Stop the Steal” campaign, discussed more fully in Chapter 6, went viral. “Stop the Steal” influencers echoed President Trump’s premature declaration of victory, asserting that he won the election, the Democrats stole it from him, and it was the responsibility of American “patriots” to combat this supposed injustice.
      <Citation number={141} />
    </Typography>

    <Typography className="indent">
      This resulted in what Attorney General Barr has described as an “avalanche” of false claims, as President Trump’s supporters attempted to justify his “Big Lie.”
      <Citation number={142} />
      The post-election allegations of fraud or other malfeasance were “completely bogus,” “silly” and “usually based on complete misinformation,” Barr explained.
      <Citation number={143} />
      Nonetheless, many of President Trump’s supporters wanted to believe them. The stolen election narrative has proven to be remarkably durable precisely because it is a matter of belief—not evidence, or reason. Each time a claim was debunked, more claims emerged in its place. Barr later complained that this dynamic forced him and others to play “whack-a-mole.”
      <Citation number={144} />
    </Typography>

    <Image
      src="/images/image-032.jpg"
      alt="protestor, Stop the Steal"
      credit="(Photo by Michael Ciaglo/Getty Images)"
    />

    <Typography className="indent">
      The United States Department of Justice, under Barr’s leadership and then Acting Attorney General Jeffrey Rosen, was forced to knock down one lie after another. As discussed in Chapter 4, Barr took unprecedented steps to investigate the “avalanche” of lies. Claims of election fraud were referred to United States Attorney’s offices and the FBI for investigation. Deputy Attorney General Richard Donoghue tracked dozens of investigations. None of them were found to have merit.
      <Citation number={145} />
      The top officials in President Trump’s Justice Department personally told the President that the claims he was promoting were false. But that did not matter to the President. As Barr told the Select Committee, President Trump never showed any “indication of interest in what the actual facts were.”
      <Citation number={146} />
    </Typography>

    <Typography className="indent">
      For example, on December 27th, Rosen and Donoghue spent approximately two hours on the phone with President Trump. They debunked a litany of claims regarding the election, explaining that each had been investigated and found to be baseless.
      <Citation number={147} />
      According to Donoghue, President Trump “had this arsenal of allegations that he wanted to rely on.” Donoghue thought it was necessary to explain to the President “based on actual investigations, actual witness interviews, actual reviews of documents, that these allegations simply had no merit.” Donoghue wanted “to cut through the noise” and be “very blunt” with the President, making it clear “these allegations were simply not true.”
      <Citation number={148} />
    </Typography>

    <Typography className="indent">
      During their December 27th conversation with President Trump, Rosen and Donoghue rebutted false claims regarding: suitcases of ballots in Georgia, Dominion’s voting machines in Antrim County, a truckload of ballots in Pennsylvania, ballots being scanned multiple times, people voting more than once, dead people voting, Native Americans being paid to vote, and more votes than voters in particular jurisdictions.
      <Citation number={149} />
      As the officials debunked each claim, President Trump “would just roll on to another one.”
      <Citation number={150} />
      Donoghue told President Trump that Federal law enforcement officials had conducted dozens of investigations and hundreds of interviews, and they had concluded that the major allegations were not supported by the evidence developed.
      <Citation number={151} />
      Donoghue and Rosen told President Trump “flat out” that “much of the information he [was] getting [was] false and/or just not supported by the evidence.”
      <Citation number={152} />
      President Trump responded: “You guys may not be following the internet the way I do.”
      <Citation number={153} />
    </Typography>

    <Typography className="indent">
      The Department of Justice was not alone in trying to contain the President’s conspiracy-mongering. President Trump’s lies were often debunked in real-time by State authorities, judges, experts, journalists, Federal officials, and even members of his own legal team. As discussed above, the President’s campaign team found that there was no significant fraud in the election. So, the President pushed them aside. The courts rejected nearly every claim brought by the President’s legal team. Even though courts rejected the claims as speculative, unsupported and meritless, President Trump, Rudy Giuliani, and others continued to assert them as truth to Trump’s followers in speeches, tweets, and podcasts.
      <Citation number={154} />
    </Typography>

    <Typography className="indent">
      The burden of refuting the false claims made by President Trump and his surrogates often fell on State and local officials. For example, in Michigan, the Secretary of State’s office posted thorough and prompt responses to the claims of election fraud on a “Fact Check” page on its website.
      <Citation number={155} />
      In Georgia, the Secretary of State’s office issued news releases and held frequent press conferences in the weeks following the election to respond to claims of fraud.
      <Citation number={156} />
      County clerks in the contested States also spoke out publicly to refute allegations. Even as the President undermined the public’s confidence in how votes are cast and counted, these clerks assured voters that their elections were secure and they could have confidence in the results.
      <Citation number={157} />
      Outside experts also publicly denounced and dismantled the claims being raised and amplified by President Trump. This was done in the context of litigation, congressional hearings, and press releases.
      <Citation number={158} />
      President Trump simply ignored these authoritative sources and continued to promote false claims that had been soundly discredited.
    </Typography>

    <Typography className="indent">
      Below, the Select Committee presents two case studies demonstrating how President Trump and his surrogates lied in the face of overwhelming evidence. The first case study deals with Dominion Voting Systems. President Trump repeatedly claimed that Dominion’s software “switched votes” and “rigged” the election well after the leaders of campaign and Justice Department officials told him that these claims were baseless. The President’s smear of Dominion was central to his “Big Lie.”
    </Typography>

    <Typography className="indent">
      The second case study examines video footage recorded in Fulton County on election night. President Trump and his representatives concocted a fictional narrative based on a deceptively edited version of the footage. After these two case studies, the Select Committee examines a variety of other claims the President repeatedly made. Once again, these claims had no basis in truth.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        DOMINION VOTING SYSTEMS
      </Typography>
    </Box>

    <Typography className="indent">
      Between election day and January 6th, President Trump repeatedly spread conspiracy theories about Dominion voting machines. The President tweeted or retweeted false claims about Dominion more than 30 times.
      <Citation number={159} />
      He also repeatedly lied about the company’s software during his postelection speeches and interviews.
      <Citation number={160} />
      President Trump’s own campaign staff, administration officials, and State officials, all told him the claims had no merit. Hand recounts confirmed the fidelity of the machines. But none of this overwhelming evidence mattered. President Trump demonstrated a conscious disregard for the facts and continued to maliciously smear Dominion.
    </Typography>

    <Typography className="indent">
      President Trump’s allies began spreading false claims regarding Dominion within days of the election. On November 8th, the day after networks called the election for Joe Biden, Sidney Powell claimed on Fox News that Dominion machines “were flipping votes in the computer system or adding votes that did not exist.”
      <Citation number={161} />
      On November 12th, Rudy Giuliani appeared on Fox News to claim that Dominion was connected to Venezuelan dictator Hugo Chavez and its software was created “in order to fix elections.”
      <Citation number={162} />
      The same day, President Trump retweeted a “REPORT” claiming that Dominion had “DELETED 2.7 MILLION TRUMP VOTES NATIONWIDE” and switched hundreds of thousands of votes in key swing states.
      <Citation number={163} />
    </Typography>

    <Typography className="indent">
      By that time, the Trump Campaign team had looked into allegations regarding Dominion and its software and concluded that the claims were false. An internal campaign memo, dated November 12, said that Dominion’s software “did not lead to improper vote counts” and cited reports concluding that, among other things, Dominion machines “Did Not Affect The Final Vote Count.”
      <Citation number={164} />
      The memo also addressed various claims of foreign influence regarding Dominion.
      <Citation number={165} />
      Jason Miller told the Select Committee that by November 12th he had told President Trump the results of the analysis of the Dominion claims by the campaign’s internal research team, specifically telling him “that the international allegations for Dominion were not valid.”
      <Citation number={166} />
      Emails and text messages show that this same analysis was shared with Mark Meadows, President Trump’s chief of staff.
      <Citation number={167} />
      White House Press Secretary Kayleigh McEnany told the Select Committee that she found herself “waving [President Trump] off of the Dominion theory,” encouraging him to use more “fact-driven” arguments.
      <Citation number={168} />
      But it was to no avail.
    </Typography>

    <Typography className="indent">
      Even though members of the Trump Campaign team reported that the result of the election was not compromised by any problems with Dominion machines, the President continued to assail Dominion on Twitter in the days that followed, for example retweeting a false claim that Dominion’s machines were “engineered by China, Venezuela, [and] Cuba” and claiming that Dominion had “[r]igged” the election.
      <Citation number={169} />
    </Typography>

    <Typography className="indent">
      Officials in the Trump administration also worked to debunk the false rumors about vote manipulation. The United States Department of Homeland Security’s Cybersecurity & Infrastructure Security Agency (CISA) released a joint statement of election security officials on November 12, reassuring voters that the election was “the most secure in American history.” CISA emphasized: “There is no evidence that any voting system deleted or lost votes, changed votes, or was in any way compromised.”
      <Citation number={170} />
    </Typography>

    <Typography className="indent">
      This was another decision point for the President. He could choose to endorse the findings of his administration’s own cyber security experts, or he could continue to promote baseless fictions about Dominion. President Trump chose the lies. The President and his supporters never did produce any evidence showing that Dominion’s machines affected the results of the election. But President Trump was undeterred by the facts. Indeed, the President and his supporters seized upon a simple human error in a small Michigan county as their initial pretense for these allegations as well as to keep the Dominion conspiracy theory alive.
    </Typography>

    <Typography className="indent">
      During the early-morning hours of November 4th, Sheryl Guy, a clerk in Antrim County, Michigan, reported the unofficial results of the vote count.
      <Citation number={171} />
      Guy’s online report was odd. It showed that former Vice President Biden had somehow won Antrim, a county that is majority-Republican and President Trump was expected to easily win. Trump’s supporters quickly pointed to Biden’s improbable win as evidence that Dominion had tampered with the votes.
      <Citation number={172} />
      That wasn’t true. Guy had made a mistake in updating the election counting software after a late addition of a local candidate to the ballot in some of the county’s precincts, which caused her unofficial counts to be off when she tallied the votes reported by the various precincts.
      <Citation number={173} />
      Guy, a Republican, was informed of the odd result and began to investigate immediately. The result was corrected, and President Trump won Antrim just as was expected.
      <Citation number={174} />
    </Typography>

    <Typography className="indent">
      Within days, local and State officials in Michigan explained to the public what had happened. On November 7th, the Michigan Secretary of State’s office issued a detailed description of Guy’s error and assured the public that the official results were not impacted.
      <Citation number={175} />
      The Michigan Senate’s Committee on Oversight, led by Republican Senator Ed McBroom, conducted its own comprehensive review of the claims related to Antrim County and confirmed that the initial reporting error was entirely attributable to an honest mistake by the county clerk.
      <Citation number={176} />
    </Typography>

    <Typography className="indent">
      The mix-up in Antrim County was quickly corrected. A human erred— not the voting machines. But President Trump used it as a pretext to continue lying about Dominion.
    </Typography>

    <Typography className="indent">
      On November 12th, the same day CISA released its statement on election security, President Trump asked Tim Walberg, a Republican Congressman from Michigan, to “check with key leadership in Michigan’s Legislature as to how supportive they could be in regards to pushing back on election irregularities and potential fraud.”
      <Citation number={177} />
      That night, President Trump asked his Acting Secretary of Homeland Security, Chad Wolf, to look into allegations of election irregularities in Michigan.
      <Citation number={178} />
      The next day, President Trump’s assistant sent Wolf a letter from Michigan State legislators raising claims about the election, including an incorrect claim that flawed Dominion software had caused votes to be counted for the wrong candidate.
      <Citation number={179} />
    </Typography>

    <Typography className="indent">
      Administration officials quickly knocked down the Dominion claim. Wolf forwarded the allegations to the leadership of CISA, including CISA Director Christopher Krebs.
      <Citation number={180} />
      Krebs provided Wolf with a press release from the Michigan Secretary of State that debunked the false claim about Antrim County and Dominion’s software in detail.
      <Citation number={181} />
      Wolf shared an update about the information he received from Krebs with White House Chief of Staff Mark Meadows.
      <Citation number={182} />
    </Typography>

    <Typography className="indent">
      On November 17th, Krebs tweeted out a statement issued by the nation’s leading election scientists that dismissed claims that election systems had been manipulated as either “unsubstantiated” or “technically incoherent.”
      <Citation number={183} />
      President Trump fired Krebs that same day.
      <Citation number={184} />
      President Trump claimed the statement released by Krebs was “highly inaccurate, in that there were massive improprieties and fraud.”
      <Citation number={185} />
      The President had no evidence for his claim.
    </Typography>

    <Typography className="indent">
      On November 19th, Rudy Giuliani, Sidney Powell, and Jenna Ellis held a press conference at the Republican National Committee (RNC) headquarters in Washington, DC. Powell asserted that there was “massive influence of communist money through Venezuela, Cuba, and likely China in the interference with our elections here in the United States.”
      <Citation number={186} />
      She pointed a finger at Dominion, claiming its software was “created in Venezuela at the direction of Hugo Chavez to make sure he never lost an election,” and Giuliani echoed her claims.
      <Citation number={187} />
    </Typography>

    <Typography className="indent">
      Hope Hicks told the Select Committee how that press conference was received in the White House. The day after the press conference, President Trump spoke by phone with Sidney Powell from the Oval Office. During the call, Powell repeated the same claims of foreign interference in the election she had made at the press conference. While she was speaking, the President muted his speakerphone and laughed at Powell, telling the others in the room, “This does sound crazy, doesn’t it?”
      <Citation number={188} />
    </Typography>

    <Typography className="indent">
      A few days later, the Trump Campaign issued a statement claiming Powell was not part of the Trump Campaign’s legal team.
      <Citation number={189} />
      But Powell’s outlandish claims were no different from those President Trump was making himself. On November 19th, the same day as Powell’s appearance at the RNC, President Trump tweeted and retweeted a link to a segment on One America News Network (OAN) that was captioned, “Dominion-izing the Vote.”
      <Citation number={190} />
      The segment claimed that Dominion had switched votes from Trump to Biden. OAN featured a supposed cyber expert, Ron Watkins, a key figure in the QAnon conspiracy movement.
      <Citation number={191} />
      On his own Twitter account, Watkins celebrated and thanked his supporters just minutes after President Trump tweeted the clip, and President Trump went on to share the clip again several times in the days that followed.
      <Citation number={192} />
    </Typography>

    <Typography className="indent">
      Officials inside the Trump administration continued to debunk the Dominion conspiracy theory, including during in-person meetings with President Trump. Attorney General Bill Barr met with President Trump face-to-face on three occasions after the election.
      <Citation number={193} />
      Barr told the Select Committee, “every time I was with the President, I raised the machines as sort of Exhibit A of how irresponsible this was.”
      <Citation number={194} />
      During the first of these meetings, on November 23rd, Barr explained to the President that the conspiracy theory about Dominion’s voting machines had “zero basis,” and was “one of the most disturbing allegations.” Barr stressed that this was “crazy stuff” and was poisoning Americans’ confidence in the voting system for no reason. This “complete nonsense” was “doing [a] great, great disservice to the country,” Barr said.
      <Citation number={195} />
    </Typography>

    <Typography className="indent">
      President Trump ignored Barr’s grave concerns. On November 29th, President Trump was interviewed by Fox News’ Maria Bartiromo. It was the President’s first interview since he lost his bid for reelection. He claimed the election was “rigged” and rife with “theft” and “a total fraud.”
      <Citation number={196} />
      He repeated various conspiracy theories, leading with the claim that Dominion’s voting machines had “glitches,” which he alleged moved “thousands of votes from my account to Biden’s account.”
      <Citation number={197} />
      He claimed that there had been “big, massive dumps” of votes—a reference to the Red Mirage.
      <Citation number={198} />
      He rambled off various other, spurious allegations, including that dead people voted in significant numbers.
      <Citation number={199} />
      None of it was true.
    </Typography>

    <Typography className="indent">
      On December 1st, Attorney General Barr met again with President Trump and told him that “the stuff his people were shoveling out to the public was bullshit.”
      <Citation number={200} />
      Attorney General Barr specifically told President Trump that the claims about Dominion voting machines were “idiotic claims.”
      <Citation number={201} />
      President Trump was still not dissuaded from continuing the lie. The day after his meeting with the Attorney General, President Trump released a video in which he repeated several claims of election fraud, including a claim that “votes had been wrongly switched from Trump to Biden” using Dominion voting machines.
      <Citation number={202} />
    </Typography>

    <Typography className="indent">
      By early-December, courts had assessed and rejected claims that Dominion machines were manipulated to affect the outcome of the 2020 election. In Michigan, a Federal judge found that claims, including those related to fraud due to the use of Dominion voting machines, were based on “nothing but speculation and conjecture that votes were destroyed, discarded or switched....”
      <Citation number={203} />
      In Arizona, a Federal judge dismissed claims that Dominion machines had deleted, switched, or changed votes.
      <Citation number={204} />
      But President Trump and his supporters refused to accept denunciations of the fabricated Dominion claims.
    </Typography>

    <Typography className="indent">
      Through December, President Trump and his legal team tried to echo the Dominion conspiracy theory by claiming to have found evidence that votes were switched in Antrim County. The clerk’s unintentional error was fixed weeks earlier and there was no evidence showing that Dominion had altered the vote tally in Antrim, or anywhere else.
      <Citation number={205} />
      But President Trump’s legal team used a case challenging a local marijuana ordinance that had passed by one vote to gain access to Dominion’s voting machines. An Antrim County judge issued an order granting the plaintiff’s experts access to the county’s computer, Dominion voting machines, thumb drives and memory cards.
      <Citation number={206} />
      Although the purpose of the order was to allow the plaintiff to seek evidence related to his ordinance challenge, it soon became clear that President Trump’s legal team was behind the effort.
      <Citation number={207} />
    </Typography>

    <Typography className="indent">
      An organization named Allied Security Operations Group (“ASOG”), led by Russell Ramsland, conducted an analysis of Antrim County’s voting machines and related systems. On December 13th, ASOG released a report 
    </Typography>

    <Typography className="indent">
      on its findings. The inspection yielded no evidence of vote manipulation. Still, the report included an unsubstantiated assertion that the Dominion voting machines used in Antrim County and throughout Michigan were “purposefully designed with inherent error to create systemic fraud and influence election results” and that a malicious algorithm was used to manipulate the results of the 2020 election.
      <Citation number={208} />
      Documents obtained by the Select Committee show that President Trump and Vice President Mike Pence were briefed on ASOG’s findings by Giuliani’s team.
      <Citation number={209} />
      On December 14th, President Trump widely disseminated the ASOG report and accompanying talking points prepared by Giuliani’s team.
      <Citation number={210} />
      He also trumpeted the report on Twitter, writing on December 14th: “WOW. This report shows massive fraud. Election changing result!”
      <Citation number={211} />
    </Typography>

    <Typography className="indent">
      During a meeting with Attorney General Bill Barr that day, President Trump claimed the ASOG report was “absolute proof that the Dominion machines were rigged” and meant he was “going to have a second term.”
      <Citation number={212} />
      Barr told the Select Committee that he believed the ASOG report was “very amateurish,” its authors lacked “any real qualifications,” and it failed to provide any supporting information for its sweeping conclusions about Dominion.
      <Citation number={213} />
      Barr told President Trump he would look into the report, but that the DOJ already had a good idea of what happened in Antrim County and it was human error, not a problem with the machines.
      <Citation number={214} />
      In any event, Barr promised President Trump they would have a definitive answer within a couple of days because a hand recount was being conducted.
      <Citation number={215} />
    </Typography>

    <Typography className="indent">
      In the ensuing days, as Barr predicted, the ASOG report was swiftly and soundly criticized by experts within and outside the Trump Administration, including the Department of Justice and the Department of Homeland Security.
      <Citation number={216} />
      The initial analysis of election security experts at the Department of Homeland Security was that the ASOG report was “false and misleading” and “demonstrates a callous misunderstanding of the actual current voting certification process.”
      <Citation number={217} />
      Subsequent analyses of the ASOG report and the underlying data from Antrim County were even more critical.
      <Citation number={218} />
      These thorough assessments of the Antrim County data and the ASOG report demonstrate that virtually every one of the claims that President Trump and his surrogates made about the report was false.
      <Citation number={219} />
      ASOG’s inspection did not reveal any malicious software or algorithms or any other evidence that the voting machines had been compromised.
      <Citation number={220} />
    </Typography>

    <Typography className="indent">
      Most importantly, as Attorney General Barr had promised President Trump, within days of the release of the ASOG report, a full hand recount of every ballot cast in Antrim County confirmed the results reported by the Dominion machines and refuted ASOG’s assertion that an algorithm has manipulated the vote count.
      <Citation number={221} />
      Giuliani’s chief investigator, Bernie Kerik, acknowledged that his team was not able to find any proof that a Dominion voting machine improperly switched, deleted, or injected votes during the 2020 election.
      <Citation number={222} />
    </Typography>

    <Typography className="indent">
      President Trump was not swayed by these basic facts. The President continued to promote the ASOG report, hounding DOJ to investigate the matter further. He returned to ASOG’s claims during a December 27th call with Acting Attorney General Rosen and Acting Deputy Attorney General Donoghue, citing the report’s claimed error rate of 68 percent in Antrim County. Donoghue pointed out to the President that the difference between the computer and hand count was only one vote and that he “cannot and should not be relying on” ASOG’s fraudulent claim, because it was simply “not true.”
      <Citation number={223} />
    </Typography>

    <Typography className="indent">
      President Trump’s fixation on Dominion’s voting machines and the baseless theory that the machines had manipulated votes led to a concerted effort to gain access to voting machines in States where President Trump was claiming election fraud. On the evening of December 18th, Powell, Lt. Gen. Michael Flynn (ret.) and Patrick Byrne met with the President at the White House. Over several hours, they argued that President Trump had the authority, under a 2018 executive order, to seize voting machines. Several administration officials joined the meeting and forcefully rejected this extreme proposal.
      <Citation number={224} />
      Multiple lawyers in the White House, including Eric Herschmann, Derek Lyons, and White House Counsel Pat Cipollone “pushed back strongly” against the idea of seizing voting machines. Cipollone told the Select Committee it was a “horrible idea,” which had “no legal basis,”
      <Citation number={225} />
      and he emphasized that he had “seen no evidence of massive fraud in the election.”
      <Citation number={226} />
      White House advisor Eric Herschmann similarly told the Select Committee that he “never saw any evidence whatsoever” to sustain the allegations against Dominion.
      <Citation number={227} />
      National Security Adviser Robert O’Brien phoned into the December 18th meeting and was asked if he had seen “any evidence of election fraud in the voting machines or foreign interference in our voting machines.” O’Brien responded that his team had “looked into that, and there’s no evidence of it.”
      <Citation number={228} />
    </Typography>

    <Typography className="indent">
      Around the same time, President Trump, Mark Meadows, and Rudy Giuliani were repeatedly asking the leadership of DHS whether the agency had authority to seize voting machines, and they were repeatedly told that DHS has no such unilateral authority.
      <Citation number={229} />
      Giuliani and Powell were also engaged in efforts to access voting machines in multiple States with the assistance of sympathetic local election officials.
      <Citation number={230} />
      Those efforts turned up no evidence of any vote manipulation by any Dominion machine, but President Trump continued to press this bogus claim.
    </Typography>

    <Typography className="indent">
      On January 2, 2021, President Trump had a lengthy phone call with Georgia Secretary of State Brad Raffensperger. The President repeatedly brought up Dominion’s voting machines, alleging that they were at the heart of a conspiracy against him.
      <Citation number={231} />
      Raffensperger was incredulous. “I don’t believe that you’re really questioning the Dominion machines,” Raffensperger said. “Because we did a hand re-tally, a 100 percent re-tally of all the ballots, and compared them to what the machines said and came up with virtually the same result. Then we did the recount, and we got virtually the same result.”
      <Citation number={232} />
      In other words, the story in Georgia was the same as the story in Antrim County, Michigan: Officials performed a hand recount to put to rest any allegations that Dominion’s machines had manipulated the vote. But once again, President Trump consciously disregarded these basic facts and persisted with his lies.
    </Typography>

    <Typography className="indent">
      During a January 4, 2021, speech in Dalton, Georgia, President Trump chose to ignore Secretary Raffensperger’s straightforward observations. The President rhetorically attacked Dominion once again, claiming that a “crime” had been “committed in this state” and it was “immeasurable.”
      <Citation number={233} />
      The President called for an “immediate forensic audit of an appropriate sampling of Dominion’s voting machines and related equipment.”
      <Citation number={234} />
      His allegations were both false and nonsensical. Georgia had already performed a statewide hand recount of all ballots.
    </Typography>

    <Typography className="indent">
      President Trump and his allies have never provided any evidence showing that Dominion’s voting software altered votes in the 2020 presidential election. In fact, some of the most vocal proponents of the Dominion claims harbored their own misgivings about the claims they were making in public. For example, Rudy Giuliani repeatedly claimed in public that Dominion voting machines stole the election, and that foreign countries had interfered in the election, but the evidence uncovered by the Select Committee reveals that he did not believe either of those things to be true. Giuliani testified that he did not believe that voting machines stole the election.
      <Citation number={235} />
      He also acknowledged that he had seen no evidence that foreign countries had interfered in the election or manipulated votes.
      <Citation number={236} />
    </Typography>

    <Typography className="indent">
      This testimony is consistent with his lead investigator Bernie Kerik’s acknowledgment that he had not come across proof that voting machines were used to switch, delete, or inject votes improperly.
      <Citation number={237} />
      Christina Bobb, an attorney who worked with Giuliani, similarly could not point to any evidence of wrongdoing by Dominion.
      <Citation number={238} />
      Even Sidney Powell, perhaps the most committed proponent of the Dominion falsehoods, was unable to provide the Select Committee with any evidence or expert report that demonstrated that the 2020 election outcome in any State had been altered through manipulation of voting machines.
      <Citation number={239} />
      And Powell defended herselfin a defamation suit brought by Dominion by claiming that “no reasonable person would conclude that her statements were truly statements of fact.”
      <Citation number={240} />
    </Typography>

    <Typography className="indent">
      By January 6, 2021, President Trump’s claims regarding Dominion had been debunked time and again. The President knew, or should have known, that he had no basis for alleging that Dominion’s voting machines had cost him the election.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        THE STATE FARM ARENA VIDEO
      </Typography>
    </Box>

    <Typography className="indent">
      President Trump also recklessly promoted allegations that video footage from a ballot counting center in Fulton County, Georgia, was proof of major election fraud. He was repeatedly informed that these allegations were false, but he pressed them anyway.
    </Typography>

    <Typography className="indent">
      On December 3rd, Rudy Giuliani presented State legislators with selectively edited footage of ballots being counted on Election Night at Fulton County’s State Farm Arena.
      <Citation number={241} />
      Giuliani misrepresented the video as “a smoking gun” proving election fraud.
      <Citation number={242} />
      The President repeatedly claimed that he would have won Georgia, if not for a supposed conspiracy that unfolded on election night. President Trump and some of his supporters alleged that political operatives faked a water main rupture to expel Republican poll watchers.
      <Citation number={243} />
      These same operatives then supposedly took illegal ballots from suitcases hidden under tables and added those ballots to the official count multiple times over by scanning them more than once.
      <Citation number={244} />
      Not one of these allegations was true.
    </Typography>

    <Typography className="indent">
      In a speech on December 5th, President Trump made the false claim about the State Farm Arena and claimed that “if you just take the crime of what those Democrat workers were doing ...[t]hat’s 10 times more than I need to win this state.”
      <Citation number={245} />
      During a December 22nd speech, he played the same deceptive footage presented by Giuliani several weeks earlier.
      <Citation number={246} />
      President Trump also repeatedly scapegoated one of these Fulton County election workers during his January 2nd phone call with Georgia’s Secretary of State, repeatedly referencing her by name and calling her “a professional vote scammer and hustler.”
      <Citation number={247} />
      It was a malicious smear.
    </Typography>

    <Typography className="indent">
      President Trump was directly notified at least four different times that the allegations he was making were false. On December 15th, then-Deputy Attorney General Jeffrey Rosen told him: “It wasn’t a suitcase. It was a bin. That’s what they use when they’re counting ballots. It’s benign.”
      <Citation number={248} />
      Rosen’s deputy, Richard Donoghue, also debunked this claim, including on a phone call on December 27th and in a meeting in the Oval Office on December 31st: “I told the President myself ...several times, in several conversations, that these allegations about ballots being smuggled in in a suitcase and run through the machines several times, it was not true, that we had looked at it, we looked at the video, we interviewed the witnesses, and it was not true.”
      <Citation number={249} />
    </Typography>

    <Typography className="indent">
      Likewise, Georgia Secretary of State Brad Raffensperger told President Trump that his allegations about the video were false. During his January 2nd call with the President, Raffensperger explained that Giuliani’s team “sliced and diced that video and took it out of context” and that “the events that transpired are nowhere near what was projected” once one looks at more complete footage.
      <Citation number={250} />
      Raffensperger also explained to the President that his team “did an audit of that, and we proved conclusively that they were not scanned three times.”
      <Citation number={251} />
      Yet, when Raffensperger said he would send President Trump a link to the television segment, the President refused: “I don’t care about the link. I don’t need it.”
      <Citation number={252} />
    </Typography>

    <Typography className="indent">
      The actual evidence contradicted all of President Trump’s claims about what the Fulton County video depicted. For example, the chief investigator for Raffensperger’s office explained in a December 6th court filing that “there were no mystery ballots that were brought in from an unknown location and hidden under tables....”
      <Citation number={253} />
      As the investigator noted, the security footage showed there was nothing under the table when it was brought into the room. Hours later, with reporters and observers present, the “video shows ballots that had already been opened but not counted placed in the boxes, sealed up, [and] stored under the table.”
      <Citation number={254} />
      This finding was affirmed by the FBI, DOJ, and the Georgia Bureau of Investigation, which interviewed witnesses and reviewed the full video footage and machine data from the site.
      <Citation number={255} />
    </Typography>

    <Typography className="indent">
      The ballots in question were not double counted. This was confirmed by a full hand recount in November, as well as a subsequent review by investigators.
      <Citation number={256} />
      They found that although one of the workers was shown in the video scanning certain batches multiple times, this was for a valid reason: her scanner kept jamming. The investigators confirmed from scanner logs, as well as the footage, that she only hit the “accept” button once per batch.
      <Citation number={257} />
      Investigators also found that staff likely did not tell the observers to leave, let alone forcefully eject them from the facility.
      <Citation number={258} />
    </Typography>

    <Typography className="indent">
      Despite this conclusive evidence and testimony, President Trump continued to point to the Fulton County video as evidence of a grand conspiracy. On January 5th, for instance, President Trump’s executive assistant emailed a document “from POTUS” to Senator Josh Hawley (R-MO), Senator Ted Cruz (R-TX), and Representative Jim Jordan (R-OH) that cited “Suitcase Gate” among the “worst fraud incidents” in Georgia.
      <Citation number={259} />
    </Typography>

    <Typography className="indent">
      During his January 6th speech, President Trump told the crowd that “in Fulton County, Republican poll watchers were ejected, in some cases, physically from the room under the false pretense of a pipe burst.” The President continued:
    </Typography>

    <Blockquote>
      <Typography>
        ...then election officials pull boxes, Democrats, and suitcases of ballots out from under a table. You all saw it on television, totally fraudulent. And illegally scanned them for nearly two hours, totally unsupervised. Tens of thousands of votes. This act coincided with a mysterious vote dump of up to 100,000 votes for Joe Biden, almost none for Donald Trump.
        <Citation number={260} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      No part of President Trump’s story was true. He had already been informed that it was false.
    </Typography>

    <Typography className="indent">
      In June 2021, when Giuliani’s law license was revoked by a New York State appellate court, the court’s ruling cited his statements about supposed suitcases of ballots in Georgia as one of its reasons for doing so. “If, as respondent claims, he reviewed the entire video, he could not have reasonably reached a conclusion that illegal votes were being counted,” the court’s ruling reads.
      <Citation number={261} />
    </Typography>

    <Typography className="indent">
      President Trump's conspiracy-mongering endangered innocent public servants around the country, including in Fulton County. For example, during a December 10, 2020, appearance in Georgia, Giuliani falsely accused Ruby Freeman and Shaye Moss, two Black public servants shown in the Fulton County video, of “surreptitiously passing around USB ports as if they’re vials of heroin or cocaine.”
      <Citation number={262} />
      In fact, Moss had been given a ginger mint by her mother, Freeman.
      <Citation number={263} />
      As described in Chapter 2, baseless accusations like these forever changed the lives of election workers like Freeman and Moss. All in service of President Trump's Big Lie.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        THE FAKE BALLOT MYTH
      </Typography>
    </Box>
  
    <Typography className="indent">
      The Trump Campaign’s distortion of the State Farm Arena video is just one example of the “fake ballots” lie. President Trump frequently claimed that “fake ballots” for Biden were injected into the vote-counting process. To hear the President tell it, there were truckloads of ballots delivered in the middle of the night to vote-counting centers and millions more votes were cast than there were registered voters. Judges, Trump administration officials, State authorities, and independent election experts found each iteration of the “fake ballot” claim to be just that: fake. The Trump Campaign and its surrogates brought nine cases that raised some version of a “fake ballots” claim. Every one of those cases was promptly dismissed.
      <Citation number={264} />
      For example, in <i>Costantino v. City of Detroit</i>, a Michigan court ruled that the plaintiff’s claims regarding forged, backdated and double-counted votes in Detroit were “incorrect and not credible” and “rife with speculation and guess-work about sinister motives.”
      <Citation number={265} />
    </Typography>

    <Typography className="indent">
      Many of the fake ballot claims were publicly raised and repeated by President Trump, but never included in any lawsuit. For example, a truck driver for the U.S. Postal Service claimed that he delivered hundreds of thousands of completed ballots from Bethpage, New York to Lancaster, Pennsylvania.
      <Citation number={266} />
      President Trump repeated this allegation numerous times.
      <Citation number={267} />
      The DOJ and FBI interviewed the relevant witnesses, including the truck driver, and reviewed the loading manifests. They determined that the allegation was not true.
      <Citation number={268} />
      Both Attorney General Barr and his successor, Jeffrey Rosen, told President Trump this claim was false. But that didn’t stop the President from repeating it.
    </Typography>

    <Typography className="indent">
      Another alleged “truckload of ballots” was supposedly delivered to the Detroit counting center at 4:30 a.m. on election night. This truck allegedly carried 100,000 ballots in garbage cans, wastepaper bins, cardboard boxes, and shopping baskets.
      <Citation number={269} />
      A widely circulated video purportedly showed an unmarked van dropping off ballots, which were then wheeled into the counting center on a wagon.
      <Citation number={270} />
      In fact, the only ballot delivery in Detroit after midnight on election night was an official delivery of 16,000 ballots, stacked in 45 well-organized trays of approximately 350 ballots each.
      <Citation number={271} />
      The wagon depicted in the video contained camera equipment being pulled by a reporter.
      <Citation number={272} />
      The claim of 100,000 fake ballots being smuggled into the counting center in the middle of the night is even more ridiculous in light of the fact that only 174,384 absent voter ballots were recorded in the City of Detroit in the 2020 election.
      <Citation number={273} />
      The addition of 100,000 fake ballots to approximately 74,000 legitimate ballots would certainly have been obvious to election officials.
      <Citation number={274} />
    </Typography>

    <Typography className="indent">
      President Trump also repeatedly claimed that more votes were cast than there were registered voters in certain States, cities, or precincts. It was easy to fact-check these allegations and demonstrate they were false.
    </Typography>

    <Typography className="indent">
      For example, in Pennsylvania, approximately nine million people were registered to vote and approximately 6.8 million votes were cast in the 2020 presidential election.
      <Citation number={275} />
      Nevertheless, President Trump and his allies made numerous “more votes than voters” claims in Pennsylvania. Citing 2020 mail-in voting data tweeted by Pennsylvania State Senator Doug Mastriano, President Trump claimed that 1.1 million ballots had been “created” and counted improperly.
      <Citation number={276} />
      In fact, there was no discrepancy in the actual numbers—Mastriano erroneously compared the 2.6 million mail-in ballots cast <i>in the November general</i> election to the 1.5 million ballots that were returned <i>in the June primary</i> election.
      <Citation number={277} />
    </Typography>

    <Typography className="indent">
      President Trump also promoted a false claim by a different Pennsylvania legislator that Pennsylvania had 205,000 more votes than voters.
      <Citation number={278} />
      This claim was based on a flawed comparison by State Representative Frank Ryan of the votes recorded by State election authorities as having been cast and those reflected in a separate State registry.
      <Citation number={279} />
      In fact, the discrepancy was a result of some counties not yet uploading their official results to the registry.
      <Citation number={280} />
      In late-December 2020, Acting Deputy Attorney General Donoghue told President Trump that this allegation was baseless.
      <Citation number={281} />
      President Trump kept repeating it anyway.
      <Citation number={282} />
    </Typography>

    <Typography className="indent">
      The President and his surrogates made similar false claims concerning excess votes in Michigan. Many of those claims originated with a grossly inaccurate affidavit submitted by Russell Ramsland, the person behind the “very amateurish” and “false and misleading” ASOG report regarding Dominion voting machines in Antrim County.
      <Citation number={283} />
      Ramsland claimed in a similar affidavit filed in Federal court in Georgia that 3,276 precincts in Michigan had turnout of between 84% and 350%, with 19 precincts reporting turnout in excess of 100%.
      <Citation number={284} />
      Ramsland’s affidavit was widely ridiculed, in part, because he relied on data for dozens of precincts that are located in Minnesota, not Michigan.
      <Citation number={285} />
      Even after he corrected his affidavit to remove the Minnesota townships, his Michigan data remained wildly off-base.
      <Citation number={286} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        THE “MULTIPLE COUNTING OF BALLOTS” FICTION"
      </Typography>
    </Box>

    <Typography className="indent">
      The President and his surrogates repeatedly claimed that ballots for former Vice President Biden were counted multiple times.
      <Citation number={287} />
      These claims originated when some noticed election officials re-running stacks of ballots through counting machines. But the allegation is based on a fundamental misunderstanding of the vote-counting process—it is routine and appropriate for election officials to re-scan ballots if they are not properly scanned and tabulated in the initial effort. In Costantino v. City of Detroit, the court rejected the “incorrect and not credible” affidavits speculating that ballots were run through scanners and counted multiple times in favor of the “more accurate and persuasive explanation of activity” put forward by the “highly-respected” election official with 40 years of experience.
      <Citation number={288} />
    </Typography>

    <Typography className="indent">
      As with other misguided claims of election fraud, the claim that ballots were counted multiple times disregards the safeguards in the voting process. In particular, as noted above, it would certainly have been apparent in the canvassing process if hundreds of ballots were counted multiple times in Detroit because the total number of ballots would greatly exceed the number of voters who voted. But that was not the case.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        THE IMAGINARY “DEAD” AND “INELIGIBLE” VOTERS"
      </Typography>
    </Box>

    <Typography className="indent">
      In addition to their false claims regarding fake ballots, President Trump and his surrogates also relentlessly asserted that tens of thousands of ballots were cast by dead or otherwise ineligible voters. For example, President Trump and Giuliani frequently alleged that more than 66,000 unregistered juveniles voted in Georgia.
      <Citation number={289} />
      In fact, no underage people voted in Georgia.
      <Citation number={290} />
      Giuliani offered several different made-up figures of the number of non-citizens who supposedly voted in Arizona, but provided no evidence to substantiate his claims.
      <Citation number={291} />
      In fact, Arizona requires every new voter to provide proof of citizenship in order to register to vote—or to complete a Federal voter registration form that requires the individual to sign an attestation to citizenship status under penalty of perjury—and no person can vote without being registered.
      <Citation number={292} />
      By mid-November, Trump Campaign staff determined this allegation that thousands of non-citizens voted in Arizona was based on “highly unreliable” information, and it is one of the false claims that led to Giuliani losing his New York law license.
      <Citation number={293} />
      These “ineligible” voters did not exist.
    </Typography>

    <Typography className="indent">
      Nor were thousands of votes cast in the names of dead Americans.
    </Typography>

    <Typography className="indent">
      During his January 2nd, call with Georgia Secretary of State Raffensperger, the President claimed that “close to about 5,000 [dead] voters” cast ballots in the election. Raffensperger quickly informed the President this wasn’t true.
      <Citation number={294} />
      But the “dead voter” lie wasn’t limited to Georgia. President Trump wanted Americans to believe that “dead voters” contributed to his defeat in several battleground States.
      <Citation number={295} />
    </Typography>

    <Typography className="indent">
      But even the Trump Campaign and its lawyers recognized early on that the claims regarding “dead voters” were grossly exaggerated, to say the least. By early November, Trump lawyers discovered that many people listed by the campaign as having died were actually alive and well.
      <Citation number={296} />
      In early December, Eric Herschmann advised Chief of Staff Meadows by text message that the Trump legal team had determined that the claim of more than 10,000 dead people voting in Georgia was not accurate.
      <Citation number={297} />
      The ensuing exchange makes clear that both men knew that Giuliani’s claims were absurd:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Herschmann</strong>: Just an FYI. Alex Cannon and his team verified that the 10k+ supposed dead people voting in GA is not accurate
      </Typography>
      <Typography>
        <strong>Meadows</strong>: I didn’t hear that claim. It is not accurate. I think I found 22 if I remember correctly. Two of them died just days before the general
      </Typography>
      <Typography>
        <strong>Herschmann</strong>: It was alleged in Rudy's hearing today. Your number is much closer to what we can prove. I think it's 12
      </Typography>
      <Typography>
        <strong>Meadows</strong>: My son found 12 obituaries and 6 other possibles depending on the Voter roll acuracy [<i>sic</i>]
      </Typography>
      <Typography>
        <strong>Herschmann</strong>: That sounds more like it. Maybe he can help Rudy find the other 10k ??
      </Typography>
      <Typography>
        <strong>Meadows</strong>: lol
        <Citation number={298} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Shortly thereafter, a Georgia court dismissed the claim that there were tens of thousands of votes cast by ineligible voters, noting the claims “rest on speculation rather than duly pled facts.”
      <Citation number={299} />
    </Typography>

    <Typography className="indent">
      The Trump Campaign’s own expert on the supposed “dead voters” admitted that the Campaign lacked the necessary data to make any conclusions about whether any (or how many) votes were cast in the name of a deceased person.
      <Citation number={300} />
      State officials did have such data, however, and were able to conduct the type of matching analysis required. These State authorities determined that there were only a handful of cases in which people voted on behalf of deceased individuals.
      <Citation number={301} />
    </Typography>

    <Typography className="indent">
      Even in those cases where the person who voted actually did die, President Trump’s lawyers knew that the vast majority of the voters included on their list of dead voters actually cast their votes before they passed.
      <Citation number={302} />
      In early-January 2021, just days before January 6th, Republican Senator Lindsey Graham asked several Trump lawyers to provide evidence to support the Campaign’s claims regarding dead voters.
      <Citation number={303} />
      As Giuliani’s team investigated, they concluded that they could not find evidence of dead voters anywhere near the number that Giuliani and President Trump were claiming publicly. After noting the shortcomings in their evidence, Katherine Friess, a lawyer working with the Giuliani legal team, warned that Senator Graham would “push back” on their evidence.
      <Citation number={304} />
      As predicted by Friess, Senator Graham was not impressed by the information provided by Giuliani’s team. In his speech on the Senate floor on January 6th, Graham explained why he would not object to the certification of electoral votes. Senator Graham referred to the failure of the Trump attorneys to provide the evidence he requested:
    </Typography>

    <Blockquote>
      <Typography>
        They said there’s 66,000 people in Georgia under 18 voted. How many people believe that? I asked, ‘Give me 10.’ Hadn’t had one. They said 8,000 felons in prison in Arizona voted. Give me 10. Hadn’t gotten one. Does that say there’s—There’s problems in every election. I don’t buy this. Enough’s enough. We’ve got to end it.
        <Citation number={305} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Documents obtained by the Select Committee reveal that President Trump and his lawyers knew that the claims being made in court about dead or ineligible voters in Georgia were inaccurate, and the lawyers were concerned that if the President vouched for those claims in another court pleading he might be criminally prosecuted. On December 31st, as the lawyers rushed to file a Federal lawsuit in Georgia, some of the lawyers raised concerns about the President signing a “verification” under oath that the allegations regarding voter fraud in Georgia, including claims regarding dead people voting, were true. As Eastman noted in an email to his colleagues on December 31st:
    </Typography>

    <Blockquote>
      <Typography>
        Although the President signed a verification [regarding the Georgia claims] back on Dec. 1, he has since been made aware that some of the allegations (and evidence proffered by the experts) has been inaccurate. For him to sign a new verification with that knowledge... would not be accurate. And I have no doubt that an aggressive DA or US Atty someplace will go after both the President and his lawyers once all the dust settles on this.
        <Citation number={306} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Despite these concerns, President Trump and his attorneys filed a complaint that incorporated the same inaccurate numbers, and President Trump signed a verification swearing under oath that the inaccurate numbers were “true and correct” or “believed to be true and correct” to the best of his knowledge and belief.
      <Citation number={307} />
      A Federal judge reviewing the relevant emails and pleadings recently concluded:
    </Typography>

    <Blockquote>
      <Typography>
        The emails show that President Trump knew that the specific numbers of voter fraud were wrong but continued to tout those numbers, both in court and to the public. The Court finds that these emails are sufficiently related to and in furtherance of a conspiracy to defraud the United States.
        <Citation number={308} />
      </Typography>
    </Blockquote>
  </Page>
)