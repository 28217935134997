import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet7'));

export const Chapter50 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      On the morning of January 6, 2021, Vice President Michael R. Pence gathered his staff to pray. Vice President Pence and his closest advisors knew the day ahead “would be a challenging one.”
      <Citation number={1} />
      They asked God for “guidance and wisdom” in the hours to come.
      <Citation number={2} />
      No Republican had been more loyal to President Donald J. Trump throughout his turbulent presidency than Vice President Pence. The Vice President rarely, if ever, criticized his boss. But as January 6th approached, President Trump turned on his own Vice President.
    </Typography>

    <Typography className="indent">
      President Trump was desperate. As described in the previous chapters, the President was searching for a way to stay in power. He had lost the election to former Vice President Biden. He had run out of legal options to overturn the election weeks earlier, when his lawyers lost nearly every court challenge they filed.
    </Typography>

    <Typography className="indent">
      The President pursued other means as well. President Trump and his lawyers tried to convince State and local officials to overturn the election, but they met resistance. Those same officials would not break the law or violate their oath to the Constitution. President Trump and his associates tried to convince State legislatures to replace the legitimate electors won by former Vice President Biden with Trump electors. The Trump Campaign even convened their own fake electors who submitted false electoral votes to Washington. But those efforts failed, too.
    </Typography>

    <Typography className="indent">
      President Trump also attempted to use the Department of Justice (DOJ) for his own corrupt political purposes. President Trump offered the job of Acting Attorney General to a loyalist. He wanted this same DOJ official, Jeffrey Clark, to send a letter to several States suggesting that they should certify the fake electors convened by the Trump Campaign. President Trump’s effort to subvert the DOJ came to a head on January 3rd, when the Department’s senior personnel and lawyers in the White House Counsel’s Office threatened mass resignations if Clark was installed.
    </Typography>

    <Typography className="indent">
      At that point, theories about a role the Vice President could play at the joint session had been circulating in certain corners of the internet and among Trump-supporting attorneys.
      <Citation number={3} />
      President Trump focused his attention on the man who had loyally served by his side for four years.
    </Typography>

    <Typography className="indent">
      On January 4, 2021, President Trump summoned Vice President Pence to a meeting in the Oval Office with John Eastman, a law professor representing President Trump in litigation challenging the election result. Eastman argued, on President Trump’s behalf, that the Vice President could take matters into his own hands during the joint session on January 6th. Eastman offered Vice President Pence two options. First, the Vice President could unilaterally reject the certified electors from several States won by former Vice President Biden, thereby handing the presidency to President Trump. Or, according to Eastman, Vice President Pence could delay the joint session to give State legislatures the opportunity to certify new electors loyal to the President. Eastman admitted, in front of the president, that both options violated the Electoral Count Act of 1887, the statute that sets forth the process for counting and disputing electoral votes during the joint session.
      <Citation number={4} />
      Eastman admitted as much in a subsequent conversation with the Vice President’s staff as well.
      <Citation number={5} />
    </Typography>

    <Typography className="indent">
      Therefore, President Trump knew, or should have known, that this scheme was illegal—in fact, it violated the Electoral Count Act and the U.S. Constitution. President Trump repeatedly demanded that Vice President Pence go through with it anyway.
    </Typography>

    <Typography className="indent">
      Vice President Pence rejected President Trump’s demands “many times” on January 4th and in the days that followed.
      <Citation number={6} />
      Vice President Pence correctly pointed out that he had no power to take any action other than counting the certified electoral votes. America’s founders could not possibly have contemplated a scenario in which the Vice President could unilaterally reject electoral votes and decide the outcome of a Presidential election. However, instead of backing down, President Trump ratcheted up the pressure even further, relentlessly harassing Vice President Pence both in public and in private.
    </Typography>

    <Typography className="indent">
      President Trump used his bully pulpit, at rallies and on Twitter, to lie to his supporters. President Trump told them that Vice President Pence had the power to deliver another 4 years in the White House. It was not true. President Trump’s campaign of coercion became so intense that Marc Short, Vice President Pence’s Chief of Staff, alerted the head of the Vice President’s Secret Service detail to the impending danger. On January 5th, Short warned that as the “disagreements” between President Trump and Vice President Pence “became more public, that the president would lash out in some way.”
      <Citation number={7} />
    </Typography>

    <Typography className="indent">
      Indeed, President Trump did. And those around him recognized that his lashing out at the Vice President could have disastrous consequences. On the morning of January 6th, an agent in the Secret Service’s intelligence division was alerted to online chatter “regarding the VP being a dead man walking if he doesn’t do the right thing.”
      <Citation number={8} />
      A few minutes later, another agent made a comment that turned out to be an ominous prediction: “I saw several other alerts saying they will storm the [C]apitol if he [the Vice President] doesn’t do the right thing etc.”
      <Citation number={9} />
    </Typography>

    <Typography className="indent">
      During his speech at the Ellipse on January 6th, President Trump repeatedly pointed his finger at Vice President Pence. President Trump insisted that “if Mike Pence does the right thing, we win the election.”
       <Citation number={10} />
      President Trump added: “And Mike Pence is going to have to come through for us, and if he doesn’t, that will be a, a sad day for our country because you’re sworn to uphold our Constitution.”
       <Citation number={11} />
    </Typography>

    <Typography className="indent">
      President Trump’s scheme required Vice President Pence to break his oath to the Constitution, not uphold it. By the time President Trump spoke at the Ellipse, he also knew that Vice President Pence had no intention of overturning the election.
    </Typography>

    <Typography className="indent">
      President Trump then sent a mob to the U.S. Capitol. He did so even after being informed by the Secret Service that people in the crowd possessed weapons. He wanted his supporters to intimidate Vice President Pence and any other Republican who refused his demands. The President told the crowd assembled before him to march down Pennsylvania Avenue, to “our Republicans, the weak ones” at the U.S. Capitol, “to try and give them the kind of pride and boldness that they need to take back our country.”
       <Citation number={12} />
    </Typography>

    <Typography className="indent">
      The mob overran the U.S. Capitol in short order. At 2:24 p.m., while the attack was well underway, President Trump tweeted:
    </Typography>

    <Blockquote>
      <Typography>
        Mike Pence didn’t have the courage to do what should have been done to protect our Country and our Constitution, giving States a chance to certify a corrected set of facts, not the fraudulent or inaccurate ones which they were asked to previously certify. USA demands the truth!
         <Citation number={13} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Again, the opposite was true. Vice President Pence showed courage on January 6th. The Vice President refused to be intimidated by President Trump’s mob, even as chants of “Hang Mike Pence!” echoed throughout the halls of the U.S. Capitol and a makeshift gallows was constructed on the Capitol grounds.
       <Citation number={14} />
    </Typography>

    <Typography className="indent">
      It is no mystery why the mob turned on Vice President Pence. President Trump told his supporters that the election was stolen, and that Vice President Pence had the power, but lacked the courage, to fix it. None of this was true.
    </Typography>

    <Typography className="indent">
      President Trump and Vice President Pence have both reflected on the events of January 6th in the months since then. Vice President Pence has described President Trump’s demands as “un-American.”
       <Citation number={15} />
      President Trump has since insisted that Vice President Pence “could have overturned the Election!”
       <Citation number={16} />
      Asked about the calls to hang the Vice President, President Trump said it was “common sense.”
       <Citation number={17} />
    </Typography>

    <Typography className="indent">
      In early 2022, U.S. District Judge David Carter evaluated the Trump-Eastman scheme to pressure the Vice President. Judge Carter described it as “a campaign to overturn a democratic election, an action unprecedented in American history.”
       <Citation number={18} />
      It was “a coup in search of a legal theory,” Judge Carter found, that likely violated at least two Federal laws.
       <Citation number={19} />
      The Trump-Eastman scheme was not a feature of the U.S. Constitution, as President Trump told his supporters. Instead, it “would have permanently ended the peaceful transition of power, undermining American democracy and the Constitution.”
       <Citation number={20} />
    </Typography>

    <Typography className="indent">
      And it all began because President Trump refused to accept the result of the election, expressed through the votes of 81 million Americans.
    </Typography>
  </Page>
)
