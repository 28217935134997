import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet8'));

export const Chapter67 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Nick Fuentes is an online provocateur who leads a white nationalist movement known as “America First,” or the “Groypers.” Fuentes immediately responded to President Trump’s “be wild” tweet. On December 19, 2020, Fuentes wrote on Twitter: “I will return to Washington DC to rally for President Trump on January 6th!”
      <Citation number={251} />
      Fuentes and his Groypers did return to Washington, DC for the joint session. As the attack was underway, Fuentes incited followers from his perch immediately outside of the U.S. Capitol. Some of his followers joined the attack inside, with one even sitting in Vice President Pence’s seat on the Senate dais.
      <Citation number={252} />
    </Typography>

    <Typography className="indent">
      Fuentes and a fellow Groyper leader, Patrick Casey, rose to prominence in 2017 after rallying at the Charlottesville “Unite the Right” event.
      <Citation number={253} />
      For years, the Groypers have repeatedly promoted white supremacist and Christian nationalist beliefs, often cloaked in wink-and-nod humor, puns, or religion, and they regularly gin up public opposition to other right-wing organizations or politicians whom they deem insufficiently conservative.
      <Citation number={254} />
    </Typography>

    <Typography className="indent">
      Fuentes was a key voice for “Stop the Steal” conspiracy theories leading up to January 6th. He spent 2 months leading rallies in State capitals across the country,
      <Citation number={255} />
      spreading the Big Lie and livestreaming coded calls to violence.
      <Citation number={256} />
      He also used his livestream to raise significant funds between November 2020 and January 2021.
      <Citation number={257} />
    </Typography>

    <Typography className="indent">
      On November 9, 2020, Fuentes promised, “GROYPERS ARE GOING TO STOP THIS COUP!”
      <Citation number={258} />
      Two days later, Fuentes organized a “Stop the Steal” rally at the Michigan State Capitol. He told the crowd that they should be “more feral” in their tactics to overturn the election, suggesting that they target lawmakers in their homes.
      <Citation number={259} />
      On November 14th, Fuentes rallied a crowd of his followers at the Million MAGA March in Washington, DC, pushing “Stop the Steal” conspiracies, calling for President Trump to rule for life, and exhorting his followers to “storm every State capitol until January 20, 2021, until President Trump is inaugurated for 4 more years.”
      <Citation number={260} />
    </Typography>

    <Typography className="indent">
      As discussed above, Fuentes was a prominent figure at the “Stop the Steal” rally in Atlanta, Georgia, in November 2020.
      <Citation number={261} />
      He promoted election conspiracies, criticized the Republican Party, joked about the Holocaust, and denounced former Vice President Biden as illegitimate.
      <Citation number={262} />
      Fuentes also suggested his followers intimidate politicians in their homes.
      <Citation number={263} />
    </Typography>

    <Typography className="indent">
      On December 12th, Fuentes again rallied a crowd of supporters at the “Stop the Steal” events in Washington, DC, calling for the destruction of the Republican Party because it had failed to overturn the election.
      <Citation number={264} />
      As others spoke at the Jericho March rally, Fuentes headlined a “Stop the Steal” protest just a few blocks away.
      <Citation number={265} />
    </Typography>

    <Typography className="indent">
      On January 4th, Fuentes suggested that his followers kill State legislators who don’t support efforts to overturn the 2020 election. As discussed in Chapter 2, President Trump and his surrogates were pressuring State legislators at the time to do just that. Fuentes complained that his side “had no leverage.” Fuentes then asked: “What can you and I do to a state legislator, besides kill them?” He then quickly added: “Although we should not do that. I am not advising that, but I mean, what else can you do, right?
      <Citation number={266} />
    </Typography>

    <Typography className="indent">
      On January 5th, Casey advertised the marches in Washington, DC on his Telegram channel and provided repeated updates on the logistics of getting into the city. Casey also spoke to his followers about the next day’s rally on a livestream on DLive.
      <Citation number={267} />
      As discussed in Chapter 8, the Groypers clearly played a role in the January 6th attack. They even planted their flag in the inner chambers of the U.S. Capitol.
      <Citation number={268} />
      Fuentes crowed about the attack the day after, tweeting: “The Capitol Siege was fucking awesome and I’m not going to pretend it wasn’t.”
      <Citation number={269} />
      In another tweet on January 7th, Fuentes wrote: “For a brief time yesterday the US Capital [<i>sic</i>] was once again occupied by The American People, before the regime wrested back control.”
      <Citation number={270} />
    </Typography>

    <Typography className="indent">
      Despite his boasts on Twitter, Fuentes exercised his Fifth Amendment privilege against self-incrimination and refused to provide information about his organizing activities to the Select Committee.
      <Citation number={271} />
    </Typography>
  </Page>
)
