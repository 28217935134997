import { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
import CloseIcon from '@mui/icons-material/Close';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import TextField from '@mui/material/TextField';
import { styled, useTheme } from '@mui/material/styles';

import { debounce } from '../utils';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

const StyledTextField = styled(TextField)(({ theme }): any => `
  input,
  label {
    font-size: .7em;
  }

  legend {
    font-size: .5em;
  }
`)

const StyledOpenBox = styled(Button)(({ theme }): any => `
  ${theme.breakpoints.down('sm')} {
    .label {
      display: none;
    }

    .MuiButtonBase-root {
      min-width: auto;
      padding: ${theme.spacing(1)};
    }

    .MuiButton-endIcon {
      margin: 0;
    }
  }
`)

const getPdfWidth = (theme: any) => (
  window.innerWidth <= theme.breakpoints.values.md
    ? window.innerWidth - Number(theme.spacing(8).slice(0, -2))
    : undefined
)

const Loading = () => (
  <div style={{ minHeight: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <CircularProgress />
  </div>
)

export const PdfViewer = ({ file, external, page, open, close }: any) => {
  const theme = useTheme();
  const [goToPage, setGoToPage] = useState<number | ''>(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(page || 1);
  const [width, setWidth] = useState(getPdfWidth(theme));

  const buttonColor = theme.palette.mode === 'dark' ? 'primary' : 'secondary';

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;
  }, []);

  useEffect(() => {
    const debouncedHandleResize = debounce(() => setWidth(getPdfWidth(theme)));

    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, [theme]);

  useEffect(() => setGoToPage(pageNumber), [pageNumber]);

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{
        style: {
          maxWidth: 'none',
          borderRadius: 10,
          margin: theme.spacing(2),
          background: theme.palette.mode === 'dark'
            ? theme.palette.grey[800]
            : theme.palette.grey[300],
        }
      }}
    >
      <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <StyledTextField
            style={{ width: '80px' }}
            type="number"
            size="small"
            label="Page"
            variant="outlined"
            color={buttonColor}
            value={goToPage}
            onKeyPress={({ key }: any) => key === 'Enter' && setPageNumber(goToPage)}
            onBlur={() => !goToPage && setGoToPage(pageNumber)}
            onChange={({ target: { value } }: any) => {
              if (!value) {
                setGoToPage('');
              } else {
                setGoToPage(
                  !value.match(/^[1-9]+[0-9]*$/)
                    ? goToPage
                    : value <= pageCount
                      ? Number(value)
                      : pageCount
                );
              }
            }}
          />
          <Button
            variant="contained"
            color={buttonColor}
            onClick={() => setPageNumber(goToPage)}
            style={{ marginLeft: theme.spacing(1), minWidth: 'auto' }}
          >
            Go
          </Button>
        </div>

        <div>
          <StyledOpenBox>
            <Button
              href={external || file}
              target="_blank"
              rel="noopener noreferrer"
              variant="outlined"
              color={buttonColor}
              endIcon={<OpenInNewIcon />}
            >
              <span className="label">Open</span>
            </Button>
          </StyledOpenBox>

          <IconButton size="small" onClick={close} color={buttonColor}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent style={{ padding: `0 ${theme.spacing(2)}` }}>
        <Document
          file={file}
          externalLinkTarget="_blank"
          onLoadSuccess={({ numPages }: any) => setPageCount(numPages)}
          onItemClick={({ pageNumber }: any) => setPageNumber(pageNumber)}
          loading={<Loading />}
        >
          <Page width={width} pageNumber={pageNumber} loading={<Loading />} />
        </Document>
      </DialogContent>

      <DialogActions style={{ padding: theme.spacing(2), justifyContent: 'center' }}>
        <Pagination
          count={pageCount}
          page={pageNumber}
          size="small"
          onChange={(e: any, page: any) => setPageNumber(page)}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              color={buttonColor}
              slots={{ previous: NavigateBeforeIcon, next: NavigateNextIcon }}
            />
          )}
        />
      </DialogActions>
    </Dialog>
  );
}
