import { useContext, useRef, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import LyricsIcon from '@mui/icons-material/Lyrics';
import MessageIcon from '@mui/icons-material/Message';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { alpha, styled, useTheme } from '@mui/material/styles';

import { GlobalPlayerStateContext } from './GlobalPlayerStateProvider';
import { FBComments } from './FBComments';
import { FBLikeButton } from './FBLikeButton';
import { GoogleAd } from './GoogleAd';
import { Header } from './Header';
import { Player } from './Player';
import { StyledToggleButton, StyledToggleLabel } from './StyledToggleButton';

import { useFbAsync, useImageMetaTags } from '../hooks';
import { fbLoaded } from '../utils';

const StyledButtons = styled('div')(({ theme }) => `
  margin: ${theme.spacing(6)} 0 ${theme.spacing(4)} 0;
  text-align: center;

  a {
    min-width: 100px;
    margin: 0 ${theme.spacing(2)}};
  }
`);

export const Page = ({ title, subtitle, children, next, prev, audio, sourcePage }: any) => {
  const [globalPlayerState, updateGlobalPlayerState] = useContext(GlobalPlayerStateContext);
  const theme = useTheme();
  const commentsRef = useRef<HTMLDivElement>(null);
  const imageMetaTags = useImageMetaTags(children);
  const buttonColor = theme.palette.mode === 'dark' ? 'primary' : 'secondary';

  useFbAsync(commentsRef);

  return (
    <>
      <Helmet>
        <title>J6 Report | {title}</title>

        <meta property="og:description" content={title} />
        <meta property="og:url" content={window.location.origin + window.location.pathname} />

        <meta property="og:audio" content={`${window.location.origin}/audio/${audio}`} />
        <meta property="og:audio:type" content="audio/mpeg" />

        { imageMetaTags }
      </Helmet>

      <Header next={next} prev={prev} sourcePage={sourcePage} />

      <Container maxWidth="xl">
        <Box style={{
          display: 'flex',
          padding: `${theme.spacing(1)} 0`,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <FBLikeButton />

          <div>
            <StyledToggleButton
              size="small"
              selected={globalPlayerState.show}
              value={!globalPlayerState.show}
              color={buttonColor}
              onChange={(_: any, show: any) => updateGlobalPlayerState((s: any) => ({
                ...s,
                show
              }))}
            >
              <StyledToggleLabel component="span">
                Audio
              </StyledToggleLabel>

              <LyricsIcon fontSize="small" />
            </StyledToggleButton>

            <IconButton
              size="small"
              onClick={() => commentsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })}
              style={{ marginLeft: theme.spacing(2) }}
            >
              <MessageIcon fontSize="small" />
            </IconButton>
          </div>
        </Box>
      </Container>

      <Divider />

      <div style={globalPlayerState.show ? {} : { height: 0, overflow: 'hidden' }}>
        <Container maxWidth="xl">
          <div style={{ margin: `${theme.spacing(2)} 0 ${theme.spacing(.25)}` }}>
            <Player src={audio} next={next} />
          </div>

          <Typography style={{ textAlign: 'right', fontSize: '10px', marginBottom: '2px' }}>
            <strong>Suggestion</strong>: try at <strong>1.25x</strong> speed if voice seems unnaturally slow
          </Typography>

          {/*<div style={{ margin: `${theme.spacing(2)} 0` }}>
            <GoogleAd />
          </div>*/}
        </Container>

        <Divider />
      </div>

      <Box style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
      }}>
        <Container maxWidth="xl">
          <div style={{ padding: `${theme.spacing(1)} 0` }}>
            {
              subtitle && (
                <Typography variant="overline" marginTop="0">{ subtitle }</Typography>
              )
            }
            <Typography variant="h1" marginBottom="0">{ title }</Typography>
          </div>
        </Container>

        <Divider />

        <Container
          maxWidth="xl"
          className="overflow-scroll"
          sx={theme => ({
            height: '100%',
            paddingTop: `${theme.spacing(2)}`,
            paddingBottom: `${theme.spacing(4)}`,
          })}
        >
          <Suspense>
            { children }

            <StyledButtons>
              <Button variant="contained" color={buttonColor} component={Link} to={prev.route} >
                Previous
              </Button>

              <Button variant="contained" color={buttonColor} component={Link} to={next?.route} disabled={!next}>
                next
              </Button>
            </StyledButtons>

            {
              fbLoaded() && (
                <>
                  <Box ref={commentsRef} style={{
                    background: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'none',
                    border: `1px solid ${ theme.palette.mode === 'dark'
                      ? alpha(theme.palette.getContrastText(theme.palette.common.black), .12)
                      : alpha(theme.palette.getContrastText(theme.palette.common.white), .12)
                    }`,
                    borderRadius: 10,
                    marginTop: theme.spacing(4)
                  }}>
                    <FBComments />
                  </Box>

                  {
                    theme.palette.mode === 'dark' && (
                      <Typography variant="caption">
                        Facebook Comments Plugin does not support Dark Mode. Please tolerate the lightblue background as a workaround.
                      </Typography>
                    )
                  }
                </>
              )
            }

            <div style={{ marginTop: theme.spacing(4) }}>
              <GoogleAd />
            </div>
          </Suspense>
        </Container>

        <footer></footer>
      </Box>
    </>
  );
};
