import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet2'));

export const ReferralSummary = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      The Committee’s work has produced a substantial body of new information. We know far more about the President’s plans and actions to overturn the election than almost all Members of Congress did when President Trump was impeached on January 13, 2021, or when he was tried by the Senate in February of that year. Fifty-seven of 100 Senators voted to convict President Trump at that time, and more than 20 others condemned the President’s conduct and said they were voting against conviction because the President’s term had already expired.
      <Citation number={570} />
      At the time, the Republican Leader of the U.S. Senate said this about Donald Trump: “A mob was assaulting the Capitol in his name. These criminals were carrying his banners, hanging his flags, and screaming their loyalty to him. It was obvious that only President Trump could end this. He was the only one who could.”
      <Citation number={571} />
      House Republican Leader Kevin McCarthy, who spoke directly with President Trump during the violence of January 6th, expressed similar views both in private and in public. Privately, Leader McCarthy stated: “But let me be very clear to you and I have been very clear to the President. He bears responsibility for his words and actions. No if, ands or buts.”
      <Citation number={572} />
      In public, Leader McCarthy concluded: “The President bears responsibility for Wednesday’s attack on Congress by mob rioters.”
      <Citation number={573} />
    </Typography>

    <Typography className="indent">
      Today we know that the planning to overturn the election on January 6th was substantially more extensive, and involved many other players, and many other efforts over a longer time period. Indeed, the violent attack and invasion of the Capitol, and what provoked it, are only a part of the story.
    </Typography>

    <Typography className="indent">
      From the outset of its hearings, the Committee has explained that President Trump and a number of other individuals made a series of very specific plans, ultimately with multiple separate elements, but all with one overriding objective: to corruptly obstruct, impede, or influence the counting of electoral votes on January 6th, and thereby overturn the lawful results of the election. The underlying and fundamental feature of that planning was the effort to get one man, Vice President Mike Pence, to assert and then exercise unprecedented and lawless powers to unilaterally alter the actual election outcome on January 6th. Evidence obtained by the Committee demonstrates that John Eastman, who worked with President Trump to put that and other elements of the plan in place, knew even before the 2020 Presidential election that Vice President Pence could not lawfully refuse to count official, certified electoral slates submitted by the Governors of the States.
      <Citation number={574} />
      Testimony and contemporaneous documentary evidence also indicate that President Trump knew that the plan was unlawful before January 6th.
      <Citation number={575} />
      When the Vice President’s counsel wrote to Eastman on January 6th to ask whether the latter had informed the President that the Vice President did not have authority to decide the election unilaterally, Eastman responded: “He’s been so advised,” and added, “[b]ut you know him—once he gets something in his head, it is hard to get him to change course.”
      <Citation number={576} />
    </Typography>

    <Typography className="indent">
      Many of the other elements of President Trump’s plans were specifically designed to create a set of circumstances on January 6th to assist President Trump in overturning the lawful election outcome during Congress’s joint session that day. For example, President Trump pressured State legislatures to adopt new electoral slates that Vice President Pence could, unlawfully, count. Trump solicited State officials to “find” a sufficient number of votes to alter the final count, and instructed the Department of Justice to “just say that the election was was [<i>sic</i>] corrupt + leave the rest to me and the R[epublican] Congressmen.”
      <Citation number={577} />
      President Trump offered the job of Acting Attorney General to Jeffrey Clark. As our evidence has unequivocally demonstrated, Clark intended to use that position to send a series of letters from the Department of Justice to multiple States falsely asserting that the Department had found fraud and urging those States to convene their legislatures to alter their official electoral slates.
      <Citation number={578} />
      And President Trump, with the help of the Republican National Committee and others, oversaw an effort to create and transmit to Government officials a series of intentionally false electoral slates for Vice President Pence to utilize on January 6th to alter or delay the count of lawful votes.
      <Citation number={579} />
    </Typography>

    <Typography className="indent">
      Of course, other elements of the plan complemented these efforts too. As this Report documents, President Trump was advised by his own experts and the Justice Department that his election fraud allegations were false, and he knew he had lost virtually all the legal challenges to the election, but he nevertheless engaged in a successful but fraudulent effort to persuade tens of millions of Americans that the election was stolen from him. This effort was designed to convince Americans that President Trump’s actions to overturn the election were justified. President Trump then urged his supporters to travel to Washington on January 6th to apply pressure to Congress to halt the count and change the election outcome, explaining to those who were coming to Washington that they needed to “take back” their country and “stop the steal.”
      <Citation number={580} />
    </Typography>

    <Typography className="indent">
      It is helpful in understanding these facts to focus on specific moments in time when President Trump made corrupt, dishonest, and unlawful choices to pursue his plans. For example, by December 14th when the electoral college met and certified Joe Biden’s victory, President Trump knew that he had failed in all the relevant litigation; he had been advised by his own experts and the Justice Department that his election fraud claims were false; and he had been told by numerous advisors that he had lost and should concede. But despite his duty as President to take care that the laws are faithfully executed, he chose instead to ignore all of the judicial rulings and the facts before him and push forward to overturn the election. Likewise, in the days and hours before the violence of January 6th, President Trump knew that no State had issued any changed electoral slate. Indeed, neither President Trump nor his co-conspirators had any evidence that any majority of any State legislature was willing to do so. President Trump also knew that Vice President Pence could not lawfully refuse to count legitimate votes. Despite all of these facts, President Trump nevertheless proceeded to instruct Vice President Pence to execute a plan he already knew was illegal. And then knowing that a violent riot was underway, President Trump breached his oath of office; our Commander in Chief refused for hours to take the one simple step that his advisors were begging him to take—to instruct his supporters to disperse, stand down, and leave the Capitol. Instead, fully understanding what had unfolded at the Capitol, President Trump exacerbated the violence with a tweet attacking Vice President Pence.
      <Citation number={581} />
      Any rational person who had watched the events that day knew that President Trump’s 2:24 p.m. tweet would lead to further violence. It did. And, at almost exactly the same time, President Trump continued to lobby Congress to delay the electoral count.
    </Typography>

    <Typography className="indent">
      As the evidence demonstrates, the rioters at the Capitol had invaded the building and halted the electoral count. They did not begin to relent until President Trump finally issued a video statement instructing his supporters to leave the Capitol at 4:17 p.m., which had an immediate and helpful effect: rioters began to disperse
      <Citation number={582} />
      —but not before the Capitol was invaded, the election count was halted, feces were smeared in the Capitol, the Vice President and his family and many others were put in danger, and more than 140 law enforcement officers were attacked and seriously injured by mob rioters. Even if it were true that President Trump genuinely believed the election was stolen, this is no defense. No President can ignore the courts and purposely violate the law no matter what supposed “justification” he or she presents.
    </Typography>

    <Typography className="indent">
      These conclusions are not the Committee’s alone. In the course of its investigation, the Committee had occasion to present evidence to Federal District Court Judge David Carter, who weighed that evidence against submissions from President Trump’s lawyer, John Eastman. Judge Carter considered this evidence in the context of a discovery dispute—specifically whether the Committee could obtain certain of Eastman’s documents pursuant to the “crime-fraud” exception to the attorney-client privilege. That exception provides that otherwise privileged documents may lose their privilege if they were part of an effort to commit a crime or a fraud, in this case by President Trump. Judge Carter set out his factual findings, discussing multiple elements of President Trump’s multi-part plan to overturn the election,
      <Citation number={583} />
      and then addressed whether the evidence, including Eastman’s email communications, demonstrated that Trump and Eastman committed crimes. “Based on the evidence,” Judge Carter explained, “the Court finds it more likely than not that President Trump corruptly attempted to obstruct the Joint Session of Congress on January 6, 2021,” and “more likely than not that President Trump and Dr. Eastman dishonestly conspired to obstruct the Joint Session of Congress on January 6th.”
      <Citation number={584} />
      Judge Carter also concluded that President Trump’s and Eastman’s “pressure campaign to stop the electoral count did not end with Vice President Pence—it targeted every tier of federal and state elected officials”
      <Citation number={585} />
      and was “a coup in search of a legal theory.”
      <Citation number={586} />
      “The plan spurred violent attacks on the seat of our nation’s government,” Judge Carter wrote, and it threatened to “permanently end[] the peaceful transition of power....”
      <Citation number={587} />
    </Typography>

    <Typography className="indent">
      The U.S. Department of Justice has been investigating and prosecuting persons who invaded the Capitol, engaged in violence, and planned violence on that day. The Department has charged more than 900 individuals, and nearly 500 have already been convicted or pleaded guilty as we write.
      <Citation number={588} />
      As the Committee’s investigation progressed through its hearings, public reporting emerged suggesting that the Department of Justice had also begun to investigate several others specifically involved in the events being examined by the Committee. Such reports indicated that search warrants had been issued, based on findings of probable cause, for the cell phones of John Eastman, Jeffrey Clark, and Representative Scott Perry.
      <Citation number={589} />
      Other reports suggested that the Department had empaneled one or more grand juries and was pursuing a ruling compelling several of this Committee’s witnesses, including Pat Cipollone and Greg Jacob, to give testimony on topics for which President Trump had apparently asserted executive privilege. Recent reporting suggests that a Federal district court judge has now rejected President Trump’s executive privilege claims in that context.
      <Citation number={590} />
    </Typography>

    <Typography className="indent">
      Criminal referrals from a congressional committee are often made in circumstances where prosecutors are not yet known to be pursuing some of the same facts and evidence. That is not the case here. During the course of our investigation, both the U.S. Department of Justice and at least one local prosecutor’s office (Fulton County, Georgia) have been actively conducting criminal investigations concurrently with this congressional investigation.
      <Citation number={591} />
      In fact, the U.S. Department of Justice has recently taken the extraordinary step of appointing a Special Counsel to investigate the former President’s conduct.
      <Citation number={592} />
    </Typography>

    <Typography className="indent">
      The Committee recognizes that the Department of Justice and other prosecutorial authorities may be in a position to utilize investigative tools, including search warrants and grand juries, superior to the means the Committee has for obtaining relevant information and testimony. Indeed, both the Department of Justice and the Fulton County District Attorney may now have access to witness testimony and records that have been unavailable to the Committee, including testimony from President Trump’s Chief of Staff Mark Meadows, and others who either asserted privileges or invoked their Fifth Amendment rights.
      <Citation number={593} />
      The Department may also be able to access, via grand jury subpoena or otherwise, the testimony of Republican Leader Kevin McCarthy, Representative Scott Perry, Representative Jim Jordan and others, each of whom appears to have had materially relevant communications with Donald Trump or others in the White House but who failed to comply with the Select Committee’s subpoenas.
    </Typography>

    <Typography className="indent">
      Taking all of these facts into account, and based on the breadth of the evidence it has accumulated, the Committee makes the following criminal referrals to the Department of Justice’s Special Counsel.
    </Typography>
  </Page>
)
