import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet9'));

export const Chapter75 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Throughout the afternoon, the President’s advisors tried to get him to tell the mob to leave the Capitol, but to no avail.
    </Typography>

    <Typography className="indent">
      Ben Williamson, the White House Acting Director of Communications, watched on the news as officers and rioters pepper sprayed each other and crowds used bicycle barricades to push against officers holding the line.
      <Citation number={155} /> He and Sarah Matthews, the Deputy Press Secretary, devised a plan: He would go to Meadows and she would go to McEnany to urge that the President issue a statement.
      <Citation number={156} />
      Williamson first texted Meadows:
    </Typography>

    <Typography className="indent">
      “Would recommend POTUS put out a tweet about respecting the police over at the Capitol.”
      <Citation number={157} />
    </Typography>

    <Typography className="indent">
      Minutes later, around 2:05 p.m., Hutchinson found Meadows seated in his office on the couch, absorbed by his cell phone screen.
      <Citation number={158} />
    </Typography>

    <Typography className="indent">
      “Are you watching the TV, chief?” she asked. He indicated he was.
    </Typography>

    <Typography className="indent">
      “Have you talked to the President?” she asked.
    </Typography>

    <Typography className="indent">
      “No,” he replied. “He wants to be alone right now.”
      <Citation number={159} />
    </Typography>

    <Typography className="indent">
      Rioters broke into the west side of the Capitol building around 2:13 p.m.
      <Citation number={160} />
      Just a few minutes later, Hutchinson saw Cipollone “barreling down the hallway” and—after looking at Hutchinson and shaking his head— opened the door to Meadows’s office unannounced.
      <Citation number={161} />
      Meadows was right where she left him, “still sitting on his phone.”
      <Citation number={162} />
    </Typography>

    <Typography className="indent">
      “The rioters have gotten to the Capitol, Mark. We need to go down and see the President now,” she heard Cipollone say.
      <Citation number={163} />
      Cipollone would not confirm or deny any of this exchange, citing executive privilege.
      <Citation number={164} />
    </Typography>

    <Typography className="indent">
      “He doesn’t want to do anything, Pat,” Meadows said, peering up from his phone.
      <Citation number={165} />
    </Typography>

    <Typography className="indent">
      “Mark something needs to be done, or people are going to die and the blood’s gonna be on your [fucking] hands,” Cipollone said. “This is getting out of control. I’m going down there.”
      <Citation number={166} />
    </Typography>

    <Typography className="indent">
      Meadows finally stood up from the couch and walked with Cipollone toward the dining room to meet with the President.
      <Citation number={167} />
    </Typography>
  </Page>
)
