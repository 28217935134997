import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet3'));

export const Chapter19 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      At noon on January 6, 2021, President Trump addressed thousands of his supporters at a rally just south of the White House. The election had been decided two months earlier. The courts found there was no evidence of significant fraud. The States certified their votes by mid-December. It was over—President Trump lost. But that’s not what the President told those in attendance. He delivered an incendiary speech from beginning to end, arguing that nothing less than the fate of America was at stake.
    </Typography>

    <Typography className="indent">
      “Our country has had enough,” President Trump said. “We will not take it anymore and that's what this is all about.”
      <Citation number={309} />
      He claimed that his followers had descended on Washington to “save our democracy” and “stop the steal.”
      <Citation number={310} />
      He refused, once again, to concede. And he proclaimed that “[t]oday I will lay out just some of the evidence proving that we won this election and we won it by a landslide.”
      <Citation number={311} />
    </Typography>

    <Image
      src="/images/image-033.jpg"
      alt="Trump rally"
      credit="(Photo by Samuel Corum/Getty Images)"
    />

    <Typography className="indent">
      For months, President Trump had relentlessly promoted his Big Lie.
      <Citation number={312} />
      He and his associates manufactured one tale after another to justify it. For more than an hour on January 6th, the President wove these conspiracy theories and lies together.
      <Citation number={313} />
    </Typography>

    <Typography className="indent">
      By the Select Committee’s assessment, there were more than 100 times during his speech in which President Trump falsely claimed that either the election had been stolen from him, or falsely claimed that votes had been compromised by some specific act of fraud or major procedural violations. That day, President Trump repeated many of the same lies he had told for months—even after being informed that many of these claims were false. He lied about Dominion voting machines in Michigan, suitcases of ballots in Georgia, more votes than voters in Pennsylvania, votes cast by non-citizens in Arizona, and dozens of other false claims of election fraud.
      <Citation number={314} />
      None of those claims were true.
    </Typography>

    <Typography className="indent">
      As explained in the chapters that follow, the Big Lie was central to President Trump’s plan to stay in power. He used the Big Lie to pressure State and local officials to undo the will of the people. His campaign convened fake electors on the baseless pretense that former Vice President Biden won several States due to fraud or other malfeasance. The President tried to subvert the Department of Justice by browbeating its leadership to endorse his election lies. And when the DOJ’s senior personnel did not acquiesce, President Trump sought to install a loyalist who would.
    </Typography>

    <Typography className="indent">
      When all those efforts failed, President Trump betrayed his own Vice President. He pressured Vice President Pence to obstruct the joint session of Congress on January 6th, falsely claiming that he had the power to refuse to count certain electoral votes. President Trump knew this was illegal but attempted to justify it with lies about the election.
    </Typography>

    <Typography className="indent">
      On December 19, 2020, President Trump summoned a mob to Washington, DC on the same day that Congress was set to certify former Vice President Biden’s victory by claiming the election was stolen and promising a “wild” protest.
      <Citation number={315} />
    </Typography>

    <Typography className="indent">
      And the bogus stolen election claim was the focus of President Trump’s speech on January 6th. The litany of lies he told riled up a mob that would march to the U.S. Capitol to intimidate Vice President Pence and Members of Congress.
    </Typography>

    <Typography className="indent">
      “And we fight. We fight like hell. And if you don't fight like hell, you're not going to have a country anymore,” President Trump told the crowd.
      <Citation number={316} />
      He incited them with these words just after praising his own election night lie—the Big Lie.
    </Typography>

    <Typography className="indent">
      President Trump told his followers to “fight” to “save” their country from a bogus specter of supposed election fraud.
      <Citation number={317} />
      And many of them did.
    </Typography>
  </Page>
)