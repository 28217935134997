import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet10'));

export const Chapter85 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Far-right extremists continued to lead the charge as protestors streamed onto the U.S. Capitol’s restricted grounds. On the north side of the West Plaza, there was a scaffold with stairs used by construction workers to build the inauguration stage. Law enforcement officers were stationed at the base of the stairs, preventing rioters from climbing to the upper West Plaza, where doors to the Capitol building itself were located. At 1:49 p.m., MPD declared a riot at the Capitol.
      <Citation number={136} />
    </Typography>

    <Typography className="indent">
      Shortly before 1:50 p.m., rioters gathered in front of this scaffold on the northwest corner of the Capitol. The rioters included Proud Boys and other extremists. One rioter, Guy Reffitt, belonged to a Three Percenter group from Texas.
      <Citation number={137} />
      By approximately 1:50 p.m., he stood at the front of the pack near the scaffold, carrying a pistol and flexicuffs.
      <Citation number={138} />
      He wore body armor under a blue jacket and a helmet with a mounted body camera.
      <Citation number={139} />
    </Typography>

    <Typography className="indent">
      Reffitt advanced on the police line, absorbing rubber bullets and pushing through chemical spray.
      <Citation number={140} />
      As he recounted shortly after the attack, Reffitt got “everything started moving forward.”
      <Citation number={141} />
      He “started the fire” and the presence of law enforcement was not going to prevent Reffitt’s advance.
      <Citation number={142} />
      According to Reffitt:
    </Typography>

    <Blockquote>
      <Typography>
        [T]here was no reason for me to give up because I had come so far to do what I wanted, what we wanted and needed to do. And I had a mindset. I didn’t mean to actually be the first guy up there. I didn’t even mean to do that. I just, the adrenaline and knowing that I can’t let my country fall.
        <Citation number={143} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Reffitt had indeed planned for violence on January 6th, noting on December 28, 2020, that he would “be in full battle rattle.”
      <Citation number={144} />
      While driving to Washington, DC on January 5th, Reffitt expressed his desire to “drag[ ] those people out of the Capitol by their ankles” and “install[ ] a new government.”
      <Citation number={145} />
      On the morning of January 6th, Reffitt clarified the target, telling “other members of his militia group and those gathered around him” at the Ellipse that “I’m taking the Capitol with everybody fucking else”and that “[w]e’re all going to drag them mother fuckers out kicking and screaming.... I just want to see Pelosi’s head hit every fucking stair on the way out... And Mitch McConnell too. Fuck ’em all.”
      <Citation number={146} />
      Reffitt was convicted and ultimately sentenced to 7 years in prison for his conduct.
      <Citation number={147} />
    </Typography>

    <Typography className="indent">
      A member of the Proud Boys, Daniel Scott, helped lead the charge up the scaffolding stairs.
      <Citation number={148} />
      Scott, also known as Milkshake, had marched with the Proud Boys from the Washington Monument to the Capitol. During the march, Scott was recorded in a video yelling, “Let’s take the fucking Capitol!”
      <Citation number={149} />
      Someone else responded, “Let’s not fucking yell that, alright?” And then Nordean added: “It was Milkshake, man, you know... idiot.” Scott had apparently blurted out the Proud Boys’ plan. At the scaffolding, Scott then helped others “take” the U.S. Capitol. While wearing a blue cap with white lettering that read, “Gods, Guns & Trump,” he pushed police officers backwards, clearing a path for the rioters. Another Proud Boy, Chris Worrell, was also nearby.
      <Citation number={150} />
      As rioters massed under the scaffold, Worrell sprayed officers with OC (or pepper) spray. 
      <Citation number={151} />
      Other Proud Boys were present at the scaffold, including Micajah Jackson
      <Citation number={152} />
      and Matthew Greene.
      <Citation number={153} />
    </Typography>

    <Typography className="indent">
      The attack at and in the vicinity of the scaffolding cleared a path for a wave of rioters who forced their way up the stairs and to the U.S. Capitol building itself.
      <Citation number={154} />
      As the rioters rushed up the stairs, another January 6th defendant, Ryan Kelley, climbed up the scaffolding around 1:51 p.m.
      <Citation number={155} />
      In the ensuing minutes he waved people on, encouraging them to follow.
      <Citation number={156} />
      Kelley—who ran in the Republican primary to be the governor of Michigan in 2022—denied to the Select Committee that he had climbed the scaffolding to wave people on.
      <Citation number={157} />
      The FBI arrested Kelley a few months after his deposition.
      <Citation number={158} />
    </Typography>

    <Typography className="indent">
      By 2:00 p.m., rioters at the top of the scaffolding stairs were only feet away from Capitol building doors and windows.
    </Typography>
  </Page>
)
