import Typography from '@mui/material/Typography';

import { Page } from '../../components/Page';
import { StyledListContainer } from '../../components/StyledListContainer';

export const SelectCommitteeWitnesses = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      In its ten hearings or business meetings, the Select Committee called live testimony or played video for several dozen witnesses, the vast majority of whom were Republicans. A full list is set forth below.
    </Typography>

    <Typography className="indent">
      <strong><u>Republicans:</u></strong>
    </Typography>

    <StyledListContainer>
      <ul>
        <li>
          <Typography>
            <strong>John McEntee</strong> (served as Director of the White House Presidential Personnel Office in Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Judd Deere</strong> (served as Deputy Assistant to the President and White House Deputy Press Secretary in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Jared Kushner</strong> (served as a Senior Advisor to President Donald Trump)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Pat Cipollone</strong> (served as White House Counsel for President Donald Trump)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Eric Herschmann</strong> (served as a Senior Advisor to President Donald Trump)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Kayleigh McEnany</strong> (served as White House Press Secretary in Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Derek Lyons</strong> (served as White House Staff Secretary and Counselor to the President in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Cassidy Hutchinson</strong> (served as Assistant to Chief of Staff Mark Meadows in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Matt Pottinger</strong> (served as Deputy National Security Advisor in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Ben Williamson</strong> (served as Senior Advisor to Chief of Staff Mark Meadows)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Sarah Matthews</strong> (served as Deputy Press Secretary in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>William Barr</strong> (served as Attorney General in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Mike Pompeo</strong> (served as Director of the Central Intelligence Agency and Secretary of State in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Ivanka Trump</strong> (served as a Senior Advisor and Director of the Office of Economic Initiatives and Entrepreneurship in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Donald Trump Jr.</strong> (eldest child of Donald Trump)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Molly Michael</strong> (served as Deputy Assistant to the President and Executive Assistant to the President)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Tim Murtaugh</strong> (served as Director of Communications for the Trump 2020 Presidential campaign)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Richard Donoghue</strong> (served as Acting Deputy Attorney General in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Jeffrey Rosen</strong> (served as Acting Attorney General in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Steven Engel</strong> (served as Assistant Attorney General for the Office of Legal Counsel in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Marc Short</strong> (served as Chief of Staff to Vice President Mike Pence)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Greg Jacob</strong> (served as Counsel to Vice President Mike Pence)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Keith Kellogg</strong> (served as National Security Advisor to Vice President Mike Pence)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Chris Hodgson</strong> (served as Director of Legislative Affairs for Vice President Mike Pence)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Douglas Macgregor</strong> (served as advisor to the Secretary of Defense in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Jason Miller</strong> (served as spokesman for the Donald Trump 2016 Presidential Campaign and was a Senior Adviser to the Trump 2020 Presidential Campaign)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Alex Cannon</strong> (Counsel for the Trump 2020 Presidential Campaign)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Bill Stepien</strong> (served as the Campaign Manager for the Trump 2020 Presidential Campaign and was the White House Director of Political Affairs in the Trump Administration from 2017 to 2018)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Rudolph Giuliani</strong> (an attorney for Donald Trump)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>John Eastman</strong> (an attorney for Donald Trump)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Michael Flynn</strong> (served as National Security Advisor in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Eugene Scalia</strong> (served as the Secretary of Labor in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Matthew Morgan</strong> (General Counsel for the Trump 2020 Presidential Campaign)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Sidney Powell</strong> (an attorney and advisor to Donald Trump)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Jeffrey Clark</strong> (served as Acting Assistant Attorney General for the Civil Division in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Cleta Mitchell</strong> (an attorney working with the Trump 2020 Presidential Campaign)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Ronna Romney McDaniel</strong> (Chair of the Republican National Committee)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Justin Clark</strong> (served as Deputy Campaign Manager for the Trump 2020 Presidential Campaign)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Robert Sinners</strong> (Georgia State Director of Election Day Operations for the Trump 2020 Presidential Campaign)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Andrew Hitt</strong> (Wisconsin Republican Party Chair)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Laura Cox</strong> (Michigan Republican Party Chair)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Mike Shirkey</strong> (Majority Leader, Michigan State Senate)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Bryan Cutler</strong> (Speaker, Pennsylvania House of Representatives)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Rusty Bowers</strong> (Speaker, Arizona House of Representatives)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Brad Raffensperger</strong> (Georgia Secretary of State)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Gabriel Sterling</strong> (Georgia Secretary of State, Chief Operating Officer)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>BJay Pak</strong> (served as United States Attorney for the Northern District of Georgia in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Al Schmidt</strong> (City Commissioner of Philadelphia)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Chris Stirewalt</strong> (Fox News Political Editor)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Benjamin Ginsberg</strong> (Election Attorney)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>J. Michael Luttig</strong> (Retired judge for the U.S. Court of Appeals for the Fourth Circuit and informal advisor to Vice President Mike Pence)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Katrina Pierson</strong> (served as a liaison for the White House and organizers at Donald Trump’s “Save America” rally on January 6)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Nicholas Luna</strong> (served as Personal Aide to President Trump)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Stephen Miller</strong> (served as Senior Advisor to President Trump)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Vincent Haley</strong> (served as Deputy Assistant to the President and Advisor for Policy, Strategy and Speechwriting in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Julie Radford</strong> (Chief of Staff to Ivanka Trump in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Mick Mulvaney</strong> (former Acting Chief of Staff and Special Envoy for Northern Ireland in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Elaine Chao</strong> (Secretary of Transportation in the Trump Administration)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Roger Stone</strong> (Trump associate)
          </Typography>
        </li>
      </ul>
    </StyledListContainer>

    <Typography className="indent">
      <strong><u>Democrats:</u></strong>
    </Typography>

    <StyledListContainer>
      <ul>
        <li>
          <Typography>
            <strong>Jocelyn Benson</strong> (Michigan Secretary of State)
          </Typography>
        </li>
      </ul>
    </StyledListContainer>

    <Typography className="indent">
      <strong><u>Other:</u></strong>
    </Typography>

    <StyledListContainer>
      <ul>
        <li>
          <Typography>
            <strong>U.S. Capitol Police Officer Harry Dunn</strong>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>DC Metropolitan Police Officer Michael Fanone</strong>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>U.S. Capitol Police Sgt. Aquilino Gonell</strong>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>DC Metropolitan Police Officer Daniel Hodges</strong>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>General Mark Milley</strong> (Chairman of the Joint Chiefs of Staff)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>U.S. Capitol Police Officer Caroline Edwards</strong>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Nick Quested</strong> (award-winning British filmmaker)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Robert Schornack</strong> (sentenced to 36 months’ probation)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Eric Barber</strong> (charged with theft and unlawful demonstration in the Capitol)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>John Wright</strong> (awaiting trial for felony civil disorder and other charges)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>George Meza</strong> (Proud Boy)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Daniel Herendeen</strong> (sentenced to 36 months’ probation for role in Capitol attack)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Matthew Walter (Proud Boy)</strong>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Wandrea ArShaye “Shaye” Moss (Georgia election worker)</strong>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Ruby Freeman (Georgia election worker)</strong>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Anika Collier Navaroli (former Twitter employee)</strong>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>White House Security Official</strong>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Jim Watkins (Founder and owner, 8kun)</strong>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Jody Williams (former owner of TheDonald.win)</strong>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Dr. Donell Harvin</strong> (Chief of Homeland Security and Intelligence for the government of the District of Columbia)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Kellye SoRelle (attorney for Oath Keepers)</strong>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Shealah Craighead (White House Photographer)</strong>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Jason Van Tatenhove (former Oath Keepers spokesperson)</strong>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Stephen Ayres</strong> (plead guilty to disorderly and disruptive conduct related to Capitol attack)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Sgt. Mark Robinson (Ret.)</strong> (Metropolitan Police Department)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Janet Buhler</strong> (plead guilty to charges related to the Capitol attack)
          </Typography>
        </li>
      </ul>
    </StyledListContainer>
  </Page>
)
