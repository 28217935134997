import { lazy } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet10'));

export const Chapter86 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Incited by President Trump, over the course of the next hour, extremists, conspiracy theorists and others breached the U.S. Capitol building at several locations. They probed for weaknesses in the building’s defenses, battling law enforcement personnel who stood in their way. Once again, the Proud Boys and other extremists played conspicuous roles.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        THE SENATE WING IS BREACHED AT 2:13 P.M.
      </Typography>
    </Box>

    <Typography className="indent">
      At 2:13 p.m., Dominic Pezzola, a Proud Boy from New York, smashed a window on the Senate wing.
      <Citation number={159} />
      This was the first breach of the Capitol building. Pezzola used a riot shield he stole from a law enforcement officer to break through the window. After climbing through, rioters were able to easily open a nearby Senate wing door from the inside—giving them unfettered passage into the building at 2:14 p.m. Two minutes later, at approximately 2:16 p.m., rioters pushed opened a second door, the Senate fire door, from the inside.
      <Citation number={160} />
      Just as the building was being breached, Vice President Pence and Speaker Pelosi were ushered off the Senate and House floors, respectively.
      <Citation number={161} />
    </Typography>

    <Typography className="indent">
      The first person to enter the Capitol building was a Kentucky native named Michael Sparks. Sparks had expressed his desire to kill people after watching protests in the summer of 2020.
      <Citation number={162} />
      Following one of President Trump’s calls to Washington, DC on December 30, 2020, Sparks answered that he would “be there.”
      <Citation number={163} />
    </Typography>

    <Typography className="indent">
      As Pezzola entered the building, he was joined by other noteworthy extremists and conspiracy theorists. Robert Gieswein, an individual from Colorado affiliated with Three Percenters who espoused conspiracy beliefs, climbed through the Senate wing window.
      <Citation number={164} />
      Doug Jensen, a QAnon adherent, was part of this first cadre of people to enter the Capitol as well.
      <Citation number={165} />
      Jensen wore a brazen “Q” shirt. Jensen later told authorities that he “intentionally positioned himself to be among the first people inside the United States Capitol because... he wanted to have his t-shirt seen on video so that ‘Q’ could ‘get the credit.’”
      <Citation number={166} />
      Another prominent QAnon believer, Jacob Chansley (a.k.a. the “QAnon Shaman”), also entered through the Senate wing door at approximately 2:14 p.m.
      <Citation number={167} />
    </Typography>

    <Image
      src="/images/image-109.jpg"
      alt="Doug Jensen and rioters confront police after storming the Capitol."
      caption="Doug Jensen and rioters confront police after storming the Capitol."
      credit="(Photo by Win McNamee/Getty Images)"
    />

    <Typography className="indent">
      White supremacists and Confederate-sympathizers were among the first rioters to enter the U.S. Capitol. Kevin Seefried and his son, Hunter, entered the building at approximately 2:13 p.m. through the Senate wing window smashed by Proud Boy Dominic Pezzola.
      <Citation number={168} />
      Kevin Seefried carried a Confederate Battle Flag with him and unfurled it inside the building. According to some historians, while the Confederate Flag has appeared in the building before, it was the first time that an insurrectionist ever carried the banner inside the U.S. Capitol.
      <Citation number={169} />
      According to court filings, Hunter Seefried helped punch out the Senate wing window and then clear the broken glass before he, his father and others entered the Capitol.
      <Citation number={170} />
      Kevin Seefried was found guilty of obstructing an official proceeding, which is a felony offense, as well as four misdemeanors.
      <Citation number={171} />
      The Department of Justice has alleged that at 2:16 p.m., just 3 minutes after the Senate wing was first breached, five individuals associated with the Nick Fuentes’s white nationalist “America First” movement entered the U.S. Capitol.
      <Citation number={172} />
      The five, all of whom are in their 20s, have been identified as: Joseph Brody, Thomas Carey, Gabriel Chase, Jon Lizak, and Paul Lovley.
      <Citation number={173} />
      Four of the five “initially met at an America First event and attended subsequent events together.”
      <Citation number={174} />
      Nick Fuentes and other America First leaders espouse “a belief that they are defending against the demographic and cultural changes in America.”
      <Citation number={175} />
      Online researchers say that Brody is the masked man seen in a photo wearing a MAGA hat and holding a rifle in front of a Nazi flag.
      <Citation number={176} />
      (The photo was not taken on January 6th.) As discussed in Chapter 6, members of the America First movement, commonly known as “Groypers,” were well-represented at “Stop the Steal” events in late 2020 and these rallies helped pave the road to January 6th. Indeed, at least three members of the group—Lovley, Lizak and Chase—attended the “Stop the Steal, March for Trump” rally in Washington, DC on November 14, 2020.
      <Citation number={177} />
    </Typography>

    <Typography className="indent">
      On January 6th, Brody and his America First associates made their way to various points inside and outside of the Capitol after the initial breach, including House Speaker Nancy Pelosi’s conference room and office, as well as the U.S. Senate Chamber.
      <Citation number={178} />
      After exiting the Capitol, the group went to the north side of the building. One of the five, Brody, and another rioter allegedly used a “metal barricade” to assault a law enforcement officer who was defending the North Door.
      <Citation number={179} />
      (The attack on the North Door is discussed below.) Brody and Chase also allegedly helped others destroy media equipment.
      <Citation number={180} />
      Still another America First associate, Riley Williams, directed rioters up a staircase to Speaker Pelosi’s office and was accused of aiding and abetting the theft of a laptop found there.
      <Citation number={181} />
      Other white supremacists were among the first rioters to enter the U.S. Capitol. Timothy Hale-Cusanelli, an Army Reservist from New Jersey who was identified by a confidential source to law enforcement as an “an avowed white supremacist and Nazi sympathizer,” entered through the Senate wing breach around 2:14 p.m.
      <Citation number={182} />
      Hale-Cusanelli “[u]sed tactical hand signals” to direct other members of the mob, and he commanded them to “‘advance’ on the Capitol.”
      <Citation number={183} />
      Afterwards, he bragged to a friend that January 6th was “exhilarating,” that he hoped “for a ‘civil war,’ and that the ‘tree of liberty must be refreshed with the blood of patriots and tyrants.’”
      <Citation number={184} />
      Robert Packer was also among the first rioters to enter the Capitol, and he made his way into the Crypt by 2:25 p.m.
      <Citation number={185} />
      Packer was wearing a “Camp Auschwitz” sweatshirt, a “symbol of Nazi hate ideology,” at the time.
      <Citation number={186} />
    </Typography>

    <Typography className="indent">
      After breaking in, some of the first rioters headed north toward the Senate chambers.
      <Citation number={187} />
      Officer Eugene Goodman, a USCP officer, intercepted them before they headed up the stairs leading to the chambers. Immediately after entering, a rioter asked Officer Goodman, “Where are the [M]embers at?” and “where are they counting the votes?”
      <Citation number={188} />
      Jensen, Gieswein, Sparks, and others stalked Officer Goodman through the halls of the Senate.
      <Citation number={189} />
      Jensen demanded that Officer Goodman and other USCP officers arrest Vice President Pence.
      <Citation number={190} />
      Sparks chanted, “This is our America!”
      <Citation number={191} />
      Other rioters who entered through the Senate wing door clashed with police offices at the Senate carriage door located on the northeast side of the Capitol.
      <Citation number={192} />
      When the rioters followed Officer Goodman up the stairs to the Senate Chamber, they were stopped by a line of USCP officers outside the Ohio Clock Tower.
      <Citation number={193} />
    </Typography>

    <Typography className="indent">
      Joe Biggs of the Proud Boys entered the Capitol shortly after the first breach. At 2:14 p.m., Biggs walked through the senate wing door and moved north. Part of his route was captured in videos posted on Parler, a rightwing social media site.
      <Citation number={194} />
      Someone recorded the Proud Boys leader shortly after he entered the Capitol and asked him, “Hey Biggs what do you gotta say?”
      <Citation number={195} />
      Smiling, Biggs replied: “this is awesome!”
      <Citation number={196} />
      Other Proud Boys were seen with Biggs, or near him, as he entered the Capitol. One of them is Paul Rae, a Proud Boys member from Florida, who appears to have communicated directly with Biggs after they entered through the door.
      <Citation number={197} />
      Another Proud Boy from Florida, Arthur Jackman, was seen with his hand on Biggs’s right shoulder. Jackman “became involved in the Proud Boys to support Donald Trump,” was in Washington on January 6th “to support President Trump and to stop the steal” and “believe[d] the election was stolen.” Still another, Joshua Pruitt, who was clad in a Punisher shirt, entered the Capitol through the Senate wing door around this time.
      <Citation number={198} />
      At approximately 2:17 p.m., 3 minutes after entering the U.S. Capitol for the first time, Biggs exited through another door.
      <Citation number={199} />
    </Typography>

    <Typography className="indent">
      At 2:43 p.m., law enforcement was able to regain control of the Senate wing door, forcing all the rioters out. But their success lasted for only 5 minutes. At 2:48 p.m., rioters again breached the Senate wing door, pushing law enforcement out of the way.
      <Citation number={200} />
      The second breach was one of the more violent breaches of the day, with the mob forcefully pushing law enforcement backwards until the pathway was clear for them to enter.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        THE COLUMBUS DOORS (EAST ROTUNDA DOORS) ARE BREACHED AT 2:24 P.M. AND 2:38 P.M.
      </Typography>
    </Box>

    <Typography className="indent">
      While the Proud Boys and other extremists were overwhelming law enforcement at the West Plaza scaffolding, another group led the attack on security barriers on the East Plaza. At 2:06 p.m., a crowd broke through security barriers and charged a set of doors just outside the Rotunda.
      <Citation number={201} />
      The mob’s surge occurred just minutes after Alex Jones arrived on the scene.
      <Citation number={202} />
      The crowd’s cheers and celebration as they move up the steps can be heard while Jones’s camera crew negotiates with USCP officers nearby.
      <Citation number={203} />
    </Typography>

    <Typography className="indent">
      Once rioters had filled the Rotunda stairs, Jones and his team, along with the Proud Boy Walter, ascended the stairs. They moved into the thick of the crowd at the top of the stairs, where Jones began calling for peace but also revolution, leading the crowd in chants of “1776” and other bellicose rhetoric.
      <Citation number={204} />
      Publicly available video shows that Jones reached the top of the stairs at 2:18 p.m.
      <Citation number={205} />
      Walter told the Select Committee that he thought Jones was successful in getting some people down, “but I also think that may have created enough space for people to be able to move, whereas before you couldn’t move.”
      <Citation number={206} />
      Apparently, Jones’s security team also realized he was not successfully controlling the crowd, as one of his security guards reportedly told him, “Alex, they’re going to blame this all on you, we got to get out of here as fast as possible.”
      <Citation number={207} />
      By approximately 2:21 p.m., Jones began descending the stairs.
      <Citation number={208} />
      Despite claiming to make attempts to calm the crowd, Jones further incited the mob as he departed, loudly proclaiming “we will never submit to the new world order” and then leading the crowd in the chant “fight for Trump.”
      <Citation number={209} />
    </Typography>

    <Typography className="indent">
      At 2:24 p.m., rioters gained entrance to the Capitol through the doors leading into the Rotunda,
      <Citation number={210} />
      an entrance that was only a few feet directly behind Jones as he was speaking. As the Rotunda was breached by rioters, Jones and Alexander left the area and decided to leave the Capitol complex area altogether.
      <Citation number={211} />
    </Typography>

    <Typography className="indent">
      Law enforcement officials were able to thwart the initial breach of the doors leading into the Rotunda. By 2:28 p.m., they temporarily regained control and stopped rioters from entering.
      <Citation number={212} />
      But their success was shortlived. Within ten minutes, the doors were breached once again.
      <Citation number={213} />
      And two members of the Proud Boys—Ronald Loehrke and James Haffner—helped lead the attack.
      <Citation number={214} />
    </Typography>

    <Typography className="indent">
      Loehrke was allegedly recruited by Nordean, the Proud Boys leader, for January 6th. In late December 2020, Nordean asked Loehrke via text message if he was coming to “DC.”
      <Citation number={215} />
      After Loehrke indicated he was, Nordean said he wanted Loehrke “on the front line” with him.
      <Citation number={216} />
      Loehrke replied, “Sounds good man.”
      <Citation number={217} />
      Loehrke and Haffner marched with the Proud Boys from the Washington Monument to the Capitol grounds and were present during the breach at the Peace Circle.
      <Citation number={218} />
      The pair made their way to the east side of the Capitol, where they began removing the security barriers and resisting USCP officers. 
      <Citation number={219} />
      Other members of the crowd joined. Eventually, the rioters breached these barriers too, allowing them to reach the doors of the Rotunda.
    </Typography>

    <Typography className="indent">
      When the rioters reached the Columbus Doors, they were again stopped by USCP officers. But as the officers explained to the Select Committee, the rioters pushed them against the doors and sprayed them with OC spray (commonly known as pepper spray), making it impossible to defend the Capitol.
      <Citation number={220} />
      James Haffner was one of the rioters who allegedly sprayed the officers.
      <Citation number={221} />
    </Typography>

    <Typography className="indent">
      Shortly after Haffner and others assaulted the USCP officers, they were able to breach the Columbus Doors at approximately 2:38 p.m. A Proud Boys contingent—including Haffner, Loehrke, and Joe Biggs—then entered the Capitol.
      <Citation number={222} />
      It was the second time that Biggs entered the U.S. Capitol that day.
    </Typography>

    <Typography className="indent">
      A military-style “stack” of Oath Keepers entered through the Columbus Doors as well. The Oath Keeper members attended the Ellipse rally, where they were provided personal security details for VIPs in attendance.
      <Citation number={223} />
      Afterwards, they marched to the Capitol, as directed by President Trump.
    </Typography>

    <Typography className="indent">
      Stewart Rhodes, the leader of the Oath Keepers, monitored the attack on the Capitol from just outside, including during the assault on the Columbus Doors. At 2:28 p.m., Rhodes texted members of the F.O.S., or Friends of Stone, (FOS) Signal chat—which included Roger Stone, the Proud Boys’ Enrique Tarrio, Ali Alexander, Alex Jones, and others
      <Citation number={224} />
      —that he was at the “Back door of the U.S. Capitol.”
      <Citation number={225} />
      Rhodes followed up at 2:30 p.m. by texting members of another chat that there was “Pounding on the doors” of the Capitol.
      <Citation number={226} />
    </Typography>

    <Typography className="indent">
      At 2:32 p.m., Rhodes held a three-way call with two other Oath Keepers, Kelly Meggs and Michael Green.
      <Citation number={227} />
      Three minutes later, Meggs’s group (“Stack 1”) started pushing through the rioters amassed on the East Plaza steps in a military-stack formation, with each person placing a hand on the shoulder of the person in front.
      <Citation number={228} />
      This stack entered the Capitol around 2:40 p.m.
      <Citation number={229} />
    </Typography>

    <Typography className="indent">
      One minute later, Rhodes was caught on camera on the Upper West Terrace responding to a rioter who said the Members of Congress must be “shitting their pants inside.” Rhodes replied: “Amen They need to shit their fucking pants. Sic semper tyrannis.”
      <Citation number={230} />
    </Typography>

    <Typography className="indent">
      Once inside, Stack 1 moved through the Rotunda. At 2:44 p.m., Stack 1 pushed into the Senate hallway, which was filled with officers blocking the way. “Push, push, push. Get in there. They can’t hold us,” Watkins implored the others. However, the officers repelled their attack, pushing them back into the Rotunda.
      <Citation number={231} />
    </Typography>

    <Typography className="indent">
      Other Oath Keepers made their way to the Capitol as Stack 1 tried to advance. Joshua James and another group of Oath Keepers (“Stack 2”) pushed through the Columbus Doors at approximately 3:15 p.m.
      <Citation number={232} />
      “This is my fucking Capitol. This is not yours. This is my building,” James shouted at officers inside the Rotunda who were trying to push the rioters out of the Capitol.
      <Citation number={233} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        ADDITIONAL BREACH POINTS
      </Typography>
    </Box>

    <Typography className="indent">
      In addition to the breaches discussed above, rioters opened other entry points into the U.S. Capitol. The Upper West Terrace door, which leads directly into the Rotunda, was breached at 2:33 p.m. when rioters opened it from the inside.
      <Citation number={234} />
    </Typography>

    <Typography className="indent">
      Inside the Capitol, rioters broke through the police lines, such as in the Crypt, a space located directly underneath the Rotunda. The Crypt is anchored by a marble “compass stone,” marking the center of the building, and is lined with 13 statues representing the original American colonies.
      <Citation number={235} />
      The rioters quickly moved towards the House Chambers and, by 2:40 p.m., started to crowd the main doors outside the Chambers, moving to the east side near the Speaker’s lobby. As they moved to the east side, rioters opened the east House doors from the inside at 2:41 p.m., allowing rioters from the northeast side of the Capitol to enter.
      <Citation number={236} />
    </Typography>

    <Typography className="indent">
      The north doors were the last Capitol doors breached. At 3:10 p.m., rioters entered through the north doors where they were quickly met by USCP.
      <Citation number={237} />
      Within a minute, the hallway just inside the doors was filled with rioters. At 3:12 p.m., a combination of USCP and MPD officers forcefully pushed the rioters out of the doors.
      <Citation number={238} />
      However, rioters continued to attack just outside the north doors throughout the afternoon and evening.
    </Typography>

    <Typography className="indent">
      The north doors have an outer entranceway that is separated by a vestibule from a set of inner doors that lead directly into the Capitol. Rioters threw bricks at the doors and forcefully tried to stop police officers from clearing the area.
      <Citation number={239} />
      Law enforcement officers briefly opened the inner doors to spray a chemical irritant that was intended to disperse the mob.
      <Citation number={240} />
      But the rioters continued to fight. For instance, as the crowd held the outer doors open, John Thomas Gordon of West Virginia repeatedly threw a heavy projectile at the inner doors, while swearing at the officers.
      <Citation number={241} />
      Another rioter gave Gordon, who came to Washington to attend the “Stop the Steal” rally, a pair of goggles so he would withstand the chemical spray. Gordon kicked the inner doors as he and others desperately tried to enter the Capitol.
      <Citation number={242} />
      Law enforcement held the doors, withstanding the mob’s best efforts to break in.
    </Typography>

    <Typography className="indent">
      As law enforcement officers started to clear the building, rioters continued to fight police officers at the tunnel on the West Plaza. Rioters violently struck officers, including MPD Officer Daniel Hodges, and sprayed them with OC spray. Although rioters did not break through the police line at the tunnel, they were able to successfully break a window just north of it. There is no surveillance coverage for this area, so Select Committee staff was unable to determine the precise time of the breach. According to opensource videos, however, the breach appears to occur at approximately 4:15 p.m.
      <Citation number={243} />
    </Typography>
  </Page>
)
