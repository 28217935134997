import { lazy } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet7'));

export const Chapter53 = ({ ...args }: any) => (
  <Page {...args}>
    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        JANUARY 6, 2021: PRESIDENT TRUMP CONTINUED TO FALSELY ASSERT IN MULTIPLE TWEETS POSTED THE MORNING OF JANUARY 6TH THAT THE VICE PRESIDENT HAD A ROLE TO PLAY IN THE OUTCOME OF THE ELECTION.
      </Typography>
    </Box>

    <Typography className="indent">
      Despite the public pressure initiated by the President and amplified by Bannon, Navarro and others, there was no ambiguity in the Vice President’s decision. By January 6th, President Trump had been told multiple times that Vice President Pence was not going to reject the certified electors from any State. Nor was Vice President Pence going to move for a delay and send the electors back to the States. Either move would have been illegal, requiring Vice President Pence to break the law, violating his oath to the U.S. Constitution. Pence made his decision clear “[m]any times” to President Trump, and he was “very consistent” in rejecting the President’s demands.
      <Citation number={230} />
      President Trump continued to publicly pressure the Vice President anyway.
    </Typography>

    <Typography className="indent">
      At 1:00 a.m. on January 6th, President Trump tweeted:
    </Typography>

    <Blockquote>
      <Typography>
        If Vice President @Mike_Pence comes through for us, we will win the Presidency. Many States want to decertify the mistake they made in certifying incorrect & even fraudulent numbers in a process NOT approved by their State Legislatures (which it must be). Mike can send it back!
        <Citation number={231} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Later that morning, at 8:17 a.m., President Trump tweeted again:
    </Typography>

    <Blockquote>
      <Typography>
        States want to correct their votes, which they now know were based on irregularities and fraud, plus corrupt process never received legislative approval. All Mike Pence has to do is send them back to the States, AND WE WIN. Do it Mike, this is a time for extreme courage!
        <Citation number={232} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      And, at 8:22 a.m., President Trump tweeted again, making a pitch for Congress to choose him, as if people’s votes on election day and the electoral college didn’t matter:
    </Typography>

    <Blockquote>
      <Typography>
        THE REPUBLICAN PARTY AND, MORE IMPORTANTLY, OUR COUNTRY, NEEDS THE PRESIDENCY MORE THAN EVER BEFORE—THE POWER OF THE VETO. STAY STRONG!
        <Citation number={233} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      President Trump’s tweets made it clear that he thought the Republican State legislatures would simply deliver him victory. President Trump emphasized this point, writing twice that if Vice President Pence gave in, “we win.” However, there was no sign of a change in the Vice President’s position. A moment of truth was looming.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        January 6, 2021: President Trump Has a “[H]eated” Conversation with Vice President Pence Before his Rally on the Ellipse.
      </Typography>
    </Box>

    <Typography className="indent">
      President Trump tried to reach Vice President Pence by phone early that morning.
      <Citation number={234} />
      He finally talked to his Vice President at approximately 11:20 a.m.
      <Citation number={235} />
      The exchange quickly became contentious.
    </Typography>

    <Typography className="indent">
      Eric Herschmann, a lawyer in the White House Counsel’s Office, overheard the conversation. Members of President Trump’s family and other White House officials were present as well.
      <Citation number={236} />
      Herschmann recalled that “it started off as a calmer tone, everything, and then it became heated.”
      <Citation number={237} />
      Ivanka Trump also described the call as “pretty heated.”
      <Citation number={238} />
      Ivanka Trump elaborated: “It was a different tone than I’d heard him take with the Vice President before.”
      <Citation number={239} />
      Ivanka Trump told her Chief of Staff, Julie Radford, that “her dad had just had an upsetting conversation with the Vice President.”
      <Citation number={240} />
      President Trump had even called Vice President Pence the “P Word.”
      <Citation number={241} />
    </Typography>

    <Image
      src="/images/image-071.jpg"
      alt="President Trump on the phone in the Oval Office."
      caption="President Trump on the phone in the Oval Office."
      credit="(Photo provided to the Select Committee by the National Archives and Records Administration)"
    />

    <Typography className="indent">
      Nick Luna, President Trump’s personal assistant (commonly known as the “body man”), was also in the Oval Office during the conversation. Luna told the Select Committee that President Trump called Vice President Pence a “wimp” on the call, with President Trump adding that he “made the wrong decision” in choosing Pence as his running mate.
      <Citation number={242} />
    </Typography>

    <Typography className="indent">
      Keith Kellogg, Vice President Pence’s National Security Advisor, also heard the conversation. President Trump “told the Vice President that, you know, he has legal authority to send these folks [the electors] back to the respective States,” Kellogg told the Select Committee.
      <Citation number={243} />
      President Trump insisted that Vice President Pence had the “constitutional authority to” reject certain electoral college votes.
      <Citation number={244} />
      When Vice President Pence would not budge, President Trump told him “you’re not tough enough to make the call.”
      <Citation number={245} />
    </Typography>

    <Typography className="indent">
      But Vice President Pence would not be bullied. The Vice President, who was at his residence at the time, had been meeting with Greg Jacob to finalize the statement he would be releasing later that day. When the President called, Pence stepped away to answer the phone. According to Jacob, when Pence returned, he did not say anything about the call—but he looked “steely, determined, [and] grim,” as he reentered the room.
      <Citation number={246} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        January 6, 2021: Trump, Eastman and Giuliani Continue to Pressure Vice President Pence at the Ellipse Rally.
      </Typography>
    </Box>

    <Typography className="indent">
      Despite the Vice President’s unwavering stance, the President and his outside counsel continued to turn up the heat of public pressure.
    </Typography>

    <Typography className="indent">
      At President Trump’s urging, thousands had gathered on the morning of January 6th to hear the President and others speak at a rally held at the Ellipse, a park just south of the White House. Before President Trump spoke, Eastman took the stage alongside Giuliani. Both would further amplify the President’s public pressure on the Vice President, but when Giuliani spoke on the Ellipse, he already knew that what Eastman had outlined would never practically happen.
    </Typography>

    <Typography className="indent">
      At 9:31 a.m. that morning, Giuliani called Eric Herschmann “out of the blue” to ask him for his view and analysis of the practical implications of Eastman’s theory.
      <Citation number={247} />
      According to Herschmann, after an “intellectual discussion about... the VP’s role,” Giuliani agreed that the “practical implication of [what Eastman had proposed] would be almost impossible.”
      <Citation number={248} />
      Immediately after this 51⁄2 minute conversation with Herschmann, Giuliani had two calls with the White House, at 9:41 a.m. and 9:53 a.m.
      <Citation number={249} />
    </Typography>

    <Typography className="indent">
      Giuliani recognized Eastman who joined him on stage, claiming that he was “one of the preeminent constitutional scholars in the United States.”
      <Citation number={250} />
    </Typography>

    <Typography className="indent">
      Giuliani said Vice President Pence could either “decide on the validity of these crooked ballots, or he can send it back to the legislat[ures], give them five to 10 days to finally finish the work.”
      <Citation number={251} />
      He added that that they had “letters from five legislat[ures] begging us to do that.”
      <Citation number={252} />
      This was not true. At most, what Giuliani, Eastman and other allies of President Trump had managed to procure were letters from individual State legislators or groups of State legislators. None of the letters came from a majority of any State’s legislative chamber, let alone a majority of an entire State legislature.
      <Citation number={253} />
    </Typography>

    <Typography className="indent">
      For instance, a letter that Eastman described to Jacob as a “[m]ajor new development” on the evening of January 5th contained the signatures of 21 members of the Pennsylvania Senate.
      <Citation number={254} />
      Eastman claimed that it “now looks like PA Legislature will vote to recertify its electors if Vice President Pence implements the plan we discussed,” but the letter asked only for a “delay” in certification to “allow for due process.”
      <Citation number={255} />
      The Select Committee learned from the most senior Pennsylvania Senate Republican that he signed the letter because of pressure he was feeling due to the voluminous post-election outreach from President Trump, Trump allies, and the public.
      <Citation number={256} />
      And, he only agreed to sign a letter directed to Congressional leaders—not the Vice President—after raising in a conversation with Vice President Pence’s brother, Congressman Greg Pence, his desire to avoid pressuring the Vice President.
      <Citation number={257} />
    </Typography>

    <Typography className="indent">
      Moreover, as Jacob explained, “what any of the State legislatures said they did or did not want to do had no impact on the legal analysis of what the Vice President’s authorities were.”
      <Citation number={258} />
      There was simply no legal path to send any votes back to the States on January 6th.
    </Typography>

    <Typography className="indent">
      On the stage at the President’s rally on the Ellipse, Giuliani repeated a conspiracy theory about the “crooked Dominion machines... deliberately” changing votes via an algorithm.
      <Citation number={259} /> 
      e explained that the 10-day delay in the certification would be used “to see the machines that are crooked” and “to find criminality there”—demonstrating that his repeated assertions of a stolen election were not based on any real proof, or even evidence, of actual widespread fraud or criminality.
      <Citation number={260} />
    </Typography>

    <Typography className="indent">
      “Let’s have trial by combat,” Giuliani told the crowd.
      <Citation number={261} />
    </Typography>

    <Typography className="indent">
      Eastman came to the microphone following Giuliani, and he proceeded to repeat proven falsehoods regarding voting machines. He then issued his “demand”:
    </Typography>

    <Blockquote>
      <Typography>
        And all we are demanding of Vice President Pence is this afternoon, at 1:00, he let the legislatures of the state look into this so we get to the bottom of it, and the American people know whether we have control of the direction of our government, or not. We no longer live in a self-governing republic if we can’t get the answer to this question. This is bigger than President Trump. It is a very essence of our republican form of government, and it has to be done. And anybody that is not willing to stand up to do it, does not deserve to be in the office. It is that simple.
        <Citation number={262} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Eastman told the assembled crowd that nothing less than the fate of the American Republic was in Vice President Pence’s hands.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        PRESIDENT TRUMP DIRECTS THE ANGRY MOB AT THE CAPITOL TO PRESSURE VICE PRESIDENT PENCE.
      </Typography>
    </Box>

    <Typography className="indent">
      When President Trump later took the stage at the Ellipse, he heaped praise on Giuliani and Eastman. “He’s got guts, unlike a lot of people in the Republican Party,” President Trump said of Giuliani. “He’s got guts. He fights, he fights.”
      <Citation number={263} />
      President Trump described Eastman as “one of the most brilliant lawyers in the country.”
      <Citation number={264} />
      President Trump claimed that Eastman had looked at the election and said, “What an absolute disgrace that this can be happening to our Constitution.”
      <Citation number={265} />
      Trump falsely argued that the keys to the election were in Vice President Pence’s hands, saying:
    </Typography>

    <Blockquote>
      <Typography>
        And he [Eastman] looked at Mike Pence, and I hope Mike is going to do the right thing. I hope so. I hope so. Because if Mike Pence does the right thing, we win the election.... [T]his is from the number one, or certainly one of the top, Constitutional lawyers in our country. He has the absolute right to do it.
        <Citation number={266} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      President Trump repeatedly lied, claiming that several States wanted to overturn former Vice President Biden’s victory:
    </Typography>

    <Blockquote>
      <Typography>
        States want to revote. The States got defrauded. They were given false information. They voted on it. Now they want to recertify. They want it back. All Vice President Pence has to do is send it back to the States to recertify and we become president and you are the happiest people.
        <Citation number={267} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Contrary to the statement President Trump dictated the night before, he all but admitted that Vice President Pence did not agree with him:
    </Typography>

    <Blockquote>
      <Typography>
        And I actually, I just spoke to Mike. I said: “Mike, that doesn’t take courage. What takes courage is to do nothing. That takes courage.” And then we’re stuck with a president who lost the election by a lot and we have to live with that for four more years. We’re just not going to let that happen.
        <Citation number={268} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Later in his speech at the Ellipse, President Trump repeated:
    </Typography>

    <Blockquote>
      <Typography>
        So, I hope Mike has the courage to do what he has to do. And I hope he doesn’t listen to the RINOs and the stupid people that he’s listening to.
        <Citation number={269} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      This was nothing less than a direct appeal to the large angry crowd to pressure Vice President Mike Pence to change his settled and oft-repeated conclusion about the limits of his authority. It was a shocking attempt to use public opinion to change the Vice President’s position. President Trump launched a mob toward the Capitol with the false hope that there was a scenario in which Vice President Pence would do what Eastman and President Trump had asked him to do, preventing the transfer of authority to President-elect Biden.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        VICE PRESIDENT PENCE FULFILLED HIS DUTY ON JANUARY 6TH
      </Typography>
    </Box>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        The Vice President Waited to Release His Statement Out of Deference to President Trump, Who Was Still Speaking on the Ellipse, and Ultimately Released It Just Minutes Before the Joint Session Convened at 1:00 p.m.
      </Typography>
    </Box>

    <Typography className="indent">
      President Trump’s speech began late and ran long. He didn’t finish speaking until approximately 1:10 p.m.—after the joint session had begun at 1:00 p.m. Minutes before he gaveled the joint session into order, Vice President Mike Pence released the “Dear Colleague” letter he had been working on for days with his staff.
      <Citation number={270} />
      There was never any ambiguity in Vice President Pence’s understanding of his role and authority, but he wanted to make it clear for everyone to see. “This may be the most important thing I ever say,” Vice President Pence remarked.
      <Citation number={271} />
    </Typography>

    <Typography className="indent">
      “Today it will be my duty to preside when the Congress convenes in Joint Session to count the votes of the Electoral College, and I will do so to the best of my ability,” Vice President Pence wrote. Vice President Pence explained that his “role as presiding officer is largely ceremonial” and dismissed the arguments that he could take unilateral action as contrary to his oath to support and defend the Constitution:
    </Typography>

    <Blockquote>
      <Typography>
        As a student of history who loves the Constitution and reveres its Framers, I do not believe that the Founders of our country intended to invest the Vice President with unilateral authority to decide which electoral votes should be counted during the Joint Session of Congress, and no Vice President in American history has ever asserted such authority. Instead, Vice Presidents presiding over Joint Sessions have uniformly followed the Electoral Count Act, conducting the proceedings in an orderly manner even where the count resulted in the defeat of their party or their own candidacy.
        <Citation number={272} />
      </Typography>
    </Blockquote>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        Vice President Pence Adheres to the U.S. Constitution and Complies with the Law Governing the Certification of the Presidential Election.
      </Typography>
    </Box>

    <Typography className="indent">
      When Vice President Pence gaveled the opening of the joint session, he knew that many of his Republican colleagues planned to challenge the election’s results based on fictitious claims of fraud. The Vice President took steps to ensure that those objections adhered to the process set forth in the Electoral Count Act.
    </Typography>

    <Typography className="indent">
      Every four years, on January 6th, vice presidents read from a script that remains essentially unchanged. Eastman’s theory of the Vice President’s power and the Trump Campaign’s scheme to convene and submit the slates of “alternate” (fake) electors motivated Vice President Pence and his advisors to alter the script and to make sure they were prepared to respond to any unexpected actions in the joint session.
      <Citation number={273} />
    </Typography>

    <Typography className="indent">
      Vice President Pence met with the Senate Parliamentarian on January 3rd to discuss the joint session and revised the joint session scripts in consultation with her office.
      <Citation number={274} />
      Vice President Pence and the Parliamentarian agreed that the Vice President’s role is ministerial.
      <Citation number={275} />
    </Typography>

    <Typography className="indent">
      The Vice President knew that the fake slates of electors organized by the Trump Campaign were not certified by the States and thus were not valid; he revised the script for the joint session to be transparent with the American people about what the Vice President would—and wouldn’t—be doing during the joint session.
      <Citation number={276} />
    </Typography>

    <Image
      src="/images/image-072.jpg"
      alt="Vice President Pence during the Joint Session of Congress."
      caption="Vice President Pence during the Joint Session of Congress."
      credit="(Photo by Win McNamee/Getty Images)"
    />

    <Typography className="indent">
      One of the most noticeable and important changes to the script was directed specifically at thwarting the fake electors scheme. The Vice President knew, informed by the research and analysis of his staff, that absent certification of the electoral votes by a State authority, the purported “alternate” slates were “not consequential” and would play no role in the certification of the Presidential election at the joint session.
      <Citation number={277} />
      The Senate Parliamentarian confirmed this understanding.
      <Citation number={278} />
    </Typography>

    <Typography className="indent">
      For decades, Vice Presidents read a similar simple passage concerning the ascertainment of the vote. Most recently, Vice President Joseph Biden read this passage aloud in 2017, as did his most recent predecessors:
    </Typography>

    <Blockquote>
      <Typography>
        After ascertainment has been had that the certificates are authentic and correct in form, the tellers will count and make a list of the votes cast by the electors of the several States.
      </Typography>
    </Blockquote>

    <Typography className="indent">
      On January 6, 2021, Vice President Pence read from a revised script (emphasis added):
    </Typography>

    <Blockquote>
      <Typography>
        After ascertaining that the certificates are regular in form and authentic, tellers will announce the votes cast by the electors for each state, beginning with Alabama, which the parliamentarians advise me is the only certificate of vote from that State and purports to be a return from the State that has annexed to it a certificate from an authority of that State purporting to appoint or ascertain electors.
        <Citation number={279} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Vice President Pence used the same phrasing for each of the 50 States counted.
    </Typography>

    <Typography className="indent">
      The Vice President’s attention to this issue was warranted. Trump’s allies pushed the fake electors scheme until the very end. Although the Trump Campaign had taken pains to direct the fake electors to send their documents to the appropriate authorities immediately after voting on December 14th, and though the Senate Parliamentarian’s and Vice President’s offices had been tracking the receipt by mail of both the legitimate and fake certificates, the Trump Campaign apparently became concerned that two States’ documents had not been received before the joint session.
      <Citation number={280} />
    </Typography>

    <Typography className="indent">
      On January 4th, the Trump campaign asked Republican Party officials in Wisconsin to fly their fake electors’ documents to Washington, DC.
      <Citation number={281} />
      Shortly after, staffers for Representative Mike Kelly (R–PA) and Senator Ron Johnson (R–WI) reached out to Vice President Pence’s Director of Legislative Affairs, apparently seeking to deliver the fake certificates.
      <Citation number={282} />
      A message from Senator Johnson’s staffer was sent just minutes before the beginning of the joint session. This staffer stated that Senator Johnson wished to hand-deliver to the Vice President the fake electors’ certificates from Michigan and Wisconsin. The Vice President’s aide unambiguously turned him away.
      <Citation number={283} />
    </Typography>

    <Typography className="indent">
      Vice President Pence made certain to call for objections as well, in compliance with the Electoral Count Act. After the tellers read off the votes cast for each State, he asked: “Are there any objections to counting the certificate of vote of the state... that the teller has verified, appears to be regular in form and authentic?”
      <Citation number={284} />
    </Typography>

    <Typography className="indent">
      For most States, there were no objections. Republicans only rose to object to the States that President Trump contested. The first such state was Arizona. At approximately 1:46 p.m., Congressman Paul Gosar (R–AZ) announced his objection.
      <Citation number={285} />
      “I rise for myself and 60 of my colleagues to object to the counting of the electoral ballots from Arizona,” Gosar said.
      <Citation number={286} />
    </Typography>

    <Typography className="indent">
      Vice President Pence then asked: “Is the objection in writing and Signed by a senator?” It was. Senator Ted Cruz endorsed the unfounded challenge to Arizona’s electoral votes.
      <Citation number={287} />
      Because the objections complied with the law, Vice President Pence directed the House and Senate to withdraw from the joint session so that the House and Senate could separately debate and vote on the objection.
      <Citation number={288} />
    </Typography>

    <Typography className="indent">
      When the joint session finally resumed after the attack on the Capitol, the clerks announced the results of each chamber’s vote. Just six U.S. Senators voted for the objection to the counting of Arizona’s electoral college votes. The objection was also defeated in the House, though 121 Republican Members voted to reject Arizona’s legitimate electors.
      <Citation number={289} />
      Pennsylvania was the only other State the chambers debated, after the House’s objection was signed by Senator Josh Hawley (R–Mo.).
      <Citation number={290} />
    </Typography>
  </Page>
)
