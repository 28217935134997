import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet10'));

export const Chapter80 = ({ ...args }: any) => (
  <Page {...args}>
    <Image src="/images/image-102.jpg" alt="Masked protestor outside capitol" style={{ marginTop: 0 }} />

    <Typography className="indent">
      Late in the evening on January 6, 2021, Henry “Enrique” Tarrio, the head of the Proud Boys, posted a video on his Parler account. The brief footage showed a masked man, wearing a black cape, standing in front of the U.S. Capitol Building. Tarrio titled the 18-second video, set to ominous music, “Premonition.” He offered no further explanation. The clear implication of the brief footage, recorded sometime prior to January 6th, was that Tarrio had foreknowledge of the events that transpired earlier that same day.
      <Citation number={1} />
    </Typography>

    <Typography className="indent">
      Indeed, Tarrio cheered on his fellow Proud Boys as they attacked the U.S. Capitol. He had been arrested and ordered to leave Washington, DC two days earlier. Although Tarrio was not physically present, he continued to monitor and communicate with his men via encrypted chats and social media. At 2:36 p.m. on January 6th, Tarrio wrote on Parler that he was “enjoying the show,” adding: “Do what must be done” and “#WeThePeople.”
      <Citation number={2} />
      Two minutes later, Tarrio wrote: “Don’t fucking leave.” Several minutes after that, Tarrio messaged his Proud Boys: “Make no mistake...” and “We did this...”
      <Citation number={3} />
    </Typography>

    <Typography className="indent">
      Law enforcement officials subsequently uncovered significant evidence showing that Tarrio and his lieutenants planned to storm the U.S. Capitol. In June 2022, Tarrio and four other Proud Boys were charged with seditious conspiracy and other crimes related to their alleged responsibility for the assault.
      <Citation number={4} />
      The U.S. Department of Justice (DOJ) has alleged that they “conspired to prevent, hinder and delay the certification of the Electoral College vote, and to oppose by force the authority of the government of the United States.”
      <Citation number={5} />
      On January 6, 2021, the Proud Boys “directed, mobilized and led members of the crowd onto the Capitol grounds and into the Capitol, leading to dismantling of metal barricades, destruction of property, breaching of the Capitol building, and assaults on law enforcement.”
      <Citation number={6} />
    </Typography>

    <Typography className="indent">
      The Select Committee’s analysis corroborates the DOJ’s findings and allegations. The Select Committee reviewed extensive footage of the attack, including that recorded by the U.S. Capitol Police’s (USCP) surveillance cameras, the Metropolitan Police Department’s (MPD) body-worn cameras, publicly available videos, as well as on-the-ground film produced by an embedded documentarian. The Select Committee interviewed rioters, law enforcement officers, and witnesses that were present on January 6th, while also consulting thousands of court filings. Using these sources of information, the Select Committee developed a timeline of events to understand how the unprecedented attack on the U.S. Capitol unfolded.
    </Typography>

    <Typography className="indent">
      As explained below, the Proud Boys marched from the Washington Monument to the U.S. Capitol on the morning of January 6th. While tens of thousands of President Trump’s supporters gathered at a rally at the Ellipse near the White House, the Proud Boys prepared to attack. Shortly before the joint session of Congress was set to begin at 1:00 p.m., the Proud Boys instigated an assault on outmanned law enforcement at the Peace Circle, a key location. They quickly overran security barriers and made their way onto the U.S. Capitol’s restricted grounds. Throughout the next several hours, members of the Proud Boys led the attack at key breach points, preventing law enforcement from gaining crowd control and inciting others to press forward.
    </Typography>

    <Typography className="indent">
      President Trump finished his speech at the Ellipse at approximately 1:10 p.m. Toward the end of his remarks, the President directed his supporters to march down Pennsylvania Avenue to the Capitol. Their natural path took them through the Peace Circle, which had already been cleared out by the Proud Boys and their associates. Thousands of rioters and protestors streamed onto the Capitol’s restricted grounds in short order.
    </Typography>

    <Typography className="indent">
      The Proud Boys were not solely responsible for attacking the U.S. Capitol. As explained in Chapter 6, other far-right extremists and conspiracy theorists prepared for violence after President Trump summoned them to Washington for a “wild” protest on January 6th. And they joined in the assault as well. Three Percenters, QAnon adherents, and other radicals were on the frontlines, pressing the charge. The Oath Keepers attacked the Capitol, forming two military-style “stacks” to push their way into the building. The white nationalist Groypers were present as their leader gave an inflammatory speech from the same Peace Circle where the attack was launched. Like members of the Proud Boys, Oath Keepers, and Three Percenters, some of the Groypers have been charged for their actions on January 6th.
    </Typography>

    <Typography className="indent">
      Unaffiliated Americans enraged by President Trump’s lies rioted as well. The January 6th, attack has often been described as a riot—and that is partly true. Some of those who trespassed on the Capitol’s grounds or entered the building did not plan to do so beforehand. But it is also true that extremists, conspiracy theorists and others were prepared to fight. That is an insurrection. They answered President Trump’s call to action. Some, like the Proud Boys, deliberately harnessed the mob’s anger to overrun the Capitol.
    </Typography>

    <Image
      src="/images/image-105.jpg"
      alt="Trump supporters from around the country gather at the Washington Monument on the morning of January 6, 2021."
      caption="Trump supporters from around the country gather at the Washington Monument on the morning of January 6, 2021."
      credit="(Photo by Brent Stirton/Getty Images)"
    />
  </Page>
)
