import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet2'));

export const ConspiracyToDefraud = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Section 371 of Title 18 of the U.S. Code provides that “[i]f two or more persons conspire either to commit any offense against the United States, or to defraud the United States, or any agency thereof in any manner or for any purpose, and one or more of such persons do any act to effect the object of the conspiracy, each shall be fined under this title or imprisoned not more than five years, or both.” The Committee believes sufficient evidence exists for a criminal referral of President Trump and others under this statute.
      <Citation number={616} />
    </Typography>

    <Typography className="indent">
      <i>First</i>, President Trump entered into an agreement with individuals to obstruct a lawful function of the government (the certification of the election). The evidence of this element overlaps greatly with the evidence of the section 1512(c)(2) violations, so we will not repeat it at length here. President Trump engaged in a multi-part plan described in this Report to obstruct a lawful certification of the election. Judge Carter focused his opinions largely on John Eastman’s role, as Eastman’s documents were at issue in that case, concluding that “the evidence shows that an agreement to enact the electoral count plan likely existed between President Trump and Eastman.”
      <Citation number={617} />
      But President Trump entered into agreements—whether formal or informal
      <Citation number={618} />
      —with several other individuals who assisted with the multi-part plan. With regard to the Department of Justice, Jeffrey Clark stands out as a participant in the conspiracy, as the evidence suggests that Clark entered into an agreement with President Trump that if appointed Acting Attorney General, he would send a letter to State officials falsely stating that the Department of Justice believed that State legislatures had a sufficient factual basis to convene to select new electors. This was false— the Department of Justice had reached the conclusion that there was no factual basis to contend that the election was stolen. Again, as with section 1512(c), the conspiracy under section 371 appears to have also included other individuals such as Chesebro, Rudolph Giuliani, and Mark Meadows, but this Committee does not attempt to determine all of the participants of the conspiracy, many of whom refused to answer this Committee’s questions.
    </Typography>

    <Typography className="indent">
      <i>Second</i>, there are several bases for finding that the conspirators used “deceitful or dishonest means.” For example, President Trump repeatedly lied about the election, after he had been told by his advisors that there was no evidence of fraud sufficient to change the results of the election.
      <Citation number={619} />
      In addition, the plot to get the Vice President to unilaterally prevent certification of the election was manifestly (and admittedly) illegal, as discussed above. Eastman and others told President Trump that it would violate the Electoral Count Act if the Vice President unilaterally rejected electors. Thus Judge Carter once again had little trouble finding that the intent requirement (“deceitful or dishonest means”) was met, stating that “President Trump continuing to push that plan despite being aware of its illegality constituted obstruction by ‘dishonest’ means under § 371.”
      <Citation number={620} />
      Judge Carter rejected the notion that Eastman’s plan—which the President adopted and actualized—was a “good faith interpretation” of the law, finding instead that it was “a partisan distortion of the democratic process.”
      <Citation number={621} />
      Similarly, both President Trump and Clark had been told repeatedly that the Department of Justice had found no evidence of significant fraud in any of its investigations, but they nonetheless pushed the Department of Justice to send a letter to State officials stating that the Department had found such fraud. And Georgia Secretary of State Brad Raffensperger and others made clear to President Trump that they had no authority to “find” him 11,780 votes, but the President relentlessly insisted that they do exactly that, even to the point of suggesting there could be criminal consequences if they refused.
      <Citation number={622} />
    </Typography>

    <Typography className="indent">
      <i>Third</i>, there were numerous overt acts in furtherance of the agreement, including each of the parts of the President’s effort to overturn the election. As Judge Carter concluded, President Trump and Eastman participated in “numerous overt acts in furtherance of their shared plan.”
      <Citation number={623} />
      These included, but certainly were not limited to, direct pleas to the Vice President to reject electors or delay certification, including in Oval Office meetings and the President’s vulgar comments to the Vice President on the morning of January 6th. Judge Carter also addressed evidence that President Trump knowingly made false representations to a court. Judge Carter concluded that Eastman’s emails showed “that President Trump knew that the specific numbers of voter fraud” cited in a complaint on behalf of President Trump “were wrong but continued to tout those numbers, both in court and to the public.” Judge Carter found that the emails in question were related to and in furtherance of a conspiracy to defraud the United States.
      <Citation number={624} />
    </Typography>

    <Typography className="indent">
      In finding that President Trump, Eastman, and others engaged in conspiracy to defraud the United States under section 371, Judge Carter relied on the documents at issue (largely consisting of Eastman’s own emails) and evidence presented to the court by this Committee. This Committee’s investigation has progressed significantly since Judge Carter issued his first crime-fraud ruling in March 2022. The evidence found by this Committee and discussed in detail in this Report further documents that the conspiracy to defraud the United States under section 371 extended far beyond the effort to pressure the Vice President to prevent certification of the election. The Committee believes there is sufficient evidence for a criminal referral of the multi-part plan described in this Report under section 371, as the very purpose of the plan was to prevent the lawful certification of Joe Biden’s election as President.
    </Typography>
  </Page>
)
