import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet10'));

export const Chapter84 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Toward the end of his speech at the Ellipse, President Trump made sure an already angry crowd of his supporters stayed enraged. “We fight like hell[,] and if you don’t fight like hell, you’re not going to have a country anymore,” the President told the tens of thousands of people who had assembled at the Ellipse, or in the vicinity. About one minute later, President Trump directed those in attendance “to walk down Pennsylvania Avenue... to the Capitol.” The President told the people they were “going to try and give” Republicans, including his own Vice President, “the kind of pride and boldness that they need to take back our country.”
      <Citation number={96} />
    </Typography>

    <Typography className="indent">
      “There’s enough people here to storm the Capitol,” a member of the crowd said at 1:06 p.m., just as the President was concluding his remarks.
      <Citation number={97} /> Ronald Sandlin, who pleaded guilty to and has been sentenced for felonies committed on January 6th, including telling officers in the Capitol that “[y]ou’re going to die,” watched the President’s speech from a nearby restaurant and live-streamed a video in which he encouraged “other patriots” to“taketheCapitol.”
      <Citation number={98} />
      Sandlin repeated the phrase “freedom is paid for with blood” several times during his video.
      <Citation number={99} />
    </Typography>

    <Typography className="indent">
      “We’re getting ready to go march on Capitol Hill. We’re gonna go fuck some shit up,” Cody Mattice, another January 6th defendant who pleaded guilty and has been sentenced,
      <Citation number={100} />
      said while walking to the Capitol. Mattice later added: “We’re getting up front, and we’re taking this shit.”
      <Citation number={101} />
      Ryan Nichols, who was charged with eight felonies, livestreamed a diatribe as he marched towards the Capitol at 1:40 p.m. Nichols echoed the President’s unconstitutional claim that Vice President Pence had the power to decide the election himself. “I’m hearing that Pence just caved.... I’m telling you if Pence caved, we’re gonna drag motherfuckers through the streets,” Nichols said.
      <Citation number={102} />
      “Cut their heads off!” Nichols yelled with his codefendant Harkrider, before encouraging others to join “Republican protestors [who] are trying to enter the House right now.”
      <Citation number={103} />
    </Typography>

    <Typography className="indent">
      On the way to the Capitol, Oath Keeper Jessica Watkins chatted with others in a Zello group named “Stop the Steal J6.” Watkins said that “100%” of the Ellipse crowd was “marching on the Capitol,” because “it has spread like wildfire that Pence has betrayed us.”
      <Citation number={104} />
      As she approached the Capitol with a contingent of Oath Keepers, Watkins said: “I’m probably gonna go silent when I get there ‘cause I’m a be a little busy.
      <Citation number={105} />
      Donald Hazard, a Three Percenter from Texas who claimed to be allied with Proud Boys on January 6th, told a <i>Washington Post</i> reporter that he wanted his face recorded on video as he marched to the Capitol. “I want the enemy to know exactly who is coming after them,” Hazard explained.
      <Citation number={106} />
    </Typography>

    <Typography className="indent">
      Leaders of the “Stop the Steal” movement continued to incite the crowd during the march as well. Alex Jones of InfoWars arrived at the Ellipse shortly before 9:00 a.m. on the morning of January 6th.
      <Citation number={107} />
      After some initial difficulty gaining access to the event area, Jones was seated in the VIP section.
      <Citation number={108} />
      While Jones stayed to listen to a portion of President Trump’s speech, planning for the crowd’s march to the Capitol was already underway and Jones intended to leave the Ellipse early to lead the march. The origins of the plan to have Jones lead the march are unclear. Jones has publicly stated that “the White House told me three days before, we are going to have you lead the March.”
      <Citation number={109} />
      Stop the Steal’s Ali Alexander also believed “the White House” wanted him to lead a march to the Capitol.
      <Citation number={110} />
      It is likely that both got that idea from Caroline Wren, a Republican fundraiser who helped organize the Ellipse event.
      <Citation number={111} />
      Jones texted Wren at 12:27 p.m., asking when he should leave the Ellipse and begin the march.
      <Citation number={112} />
    </Typography>

    <Typography className="indent">
      While Wren originally expected Jones, Roger Stone, and retired Lt. Gen. Michael Flynn to march to the Capitol, Stone did not attend the Ellipse rally and so he was not present to accompany Jones on the march as planned.
      <Citation number={113} />
      Additionally, while President Trump was delivering his speech, Wren asked Flynn if he was going to march with Jones. Flynn responded, “Hell, no. It’s freezing.”
      <Citation number={114} />
    </Typography>

    <Typography className="indent">
      While Stone and Flynn did not march, Jones and Alexander led others to the Capitol, though it is not clear how many people followed them.
      <Citation number={115} />
      Jones and Alexander gathered with Jones’s camera and security crew just outside the event perimeter, near Freedom Plaza, to discuss their plans.
      <Citation number={116} />
      The discussion, recorded by Alex Jones’s film crew, sheds some light on what Jones and Alexander knew about the President’s plans and what they intended for the march. The group, which included InfoWars host Owen Shroyer, huddled outside the Ellipse security perimeter to discuss how best to proceed. They tried to predict the Presidential motorcade’s route to the Capitol. The video shows Alex Jones telling his crew, “I think the Wren lady, where’s she at? She knows what they said they were going to do. Everything she’s said has been accurate, so we need to call her real quick.”
      <Citation number={117} />
      They then decided to walk down Pennsylvania Avenue, as the President had directed in his speech.
    </Typography>

    <Image
      src="/images/image-107.jpg"
      alt="Alex Jones uses a bullhorn to speak to crowd on January 6, 2021."
      caption="Alex Jones uses a bullhorn to speak to crowd on January 6, 2021."
      credit="(Photo by Jon Cherry/Getty Images)"
    />

    <Typography className="indent">
      Shroyer recommended the group wait for President Trump to finish speaking, and they agreed to at least delay their departure from Freedom Plaza to allow Jones to gather a crowd.
      <Citation number={118} />
      Jones began speaking from his bullhorn, imploring people to gather and walk down Pennsylvania Avenue.
      <Citation number={119} />
      While using the bullhorn, Jones told the crowd that they were experiencing “the second American revolution,”
      <Citation number={120} />
      and stated, “[l]et’s go take our country back. Trump is only minutes away. Let’s start marching to the Capitol, peacefully.”
      <Citation number={121} />
    </Typography>

    <Typography className="indent">
      Proud Boys were among the crowd Jones gathered during his march. Matthew Walter, president of a Tennessee chapter of the organization,
      <Citation number={122} />
      was near the National Mall with two other Proud Boys from Tennessee and decided to join Jones.
      <Citation number={123} />
      Other, more prominent members of the Proud Boys appear to have been in contact with Jones and Shroyer about the events of January 6th and on that day. Records for Enrique Tarrio’s phone show that while the attack on the Capitol was ongoing, he texted with Jones three times and Shroyer five times.
      <Citation number={124} />
      Ethan Nordean’s phone records reflect that he exchanged 23 text messages with Shroyer between January 4th and 5th, and that he had one call with him on each of those days.
      <Citation number={125} />
      Records of Joseph Biggs’s communications show that he texted with Shroyer eight times on January 4th and called him at approximately 11:15 a.m. on January 6th, while Biggs and his fellow Proud Boys were marching at and around the Capitol.
      <Citation number={126} />
    </Typography>

    <Typography className="indent">
      Once they had marched the length of Pennsylvania Avenue and reached the west side of the Capitol, Jones and Alexander used a bullhorn to continue directing those around them to the east side, making further references to President Trump’s alleged imminent arrival. A video recorded by a rallygoer at 1:51 p.m. shows Jones and Alexander standing together as Jones encourages the crowd to proceed to the east side of the Capitol. He tells those listening that “we’ve got a permit on the other side, it’s great that this happened, but Trump’s not going to come when we’ve taken this over. We are not Antifa, we are not BLM.”
      <Citation number={127} />
    </Typography>

    <Typography className="indent">
      Jones has repeatedly claimed that he tried to calm the crowd, but his actions also coincided with two police line breaches and one breach of the Capitol building itself. At 1:57 p.m., minutes after Jones encouraged rally goers to move east, newly arrived protestors breached the bike rack fencing used to keep the crowd away from the east side steps.
      <Citation number={128} />
      After the breach, police retreated to the base of the large set of steps behind them and the crowd moved forward to meet the newly established police line.
      <Citation number={129} />
    </Typography>

    <Typography className="indent">
      Jones followed shortly behind the crowd that led the initial east fence breach, and his arrival coincided with the next breach up the east stairs. Publicly available video shows Jones already departed from the west side, rounding the north side of the Capitol on the way to the east side at 2:00 p.m.
      <Citation number={130} />
      As he was walking, Jones told his group, “those fucking cops need to fucking back off man.”
      <Citation number={131} />
      He was then asked about Vice President Pence, to which Jones responded: “he floundered and was neutral, he passed the ball.”
      <Citation number={132} />
      At the conclusion of the video, one of Jones’s camera crew can be heard saying, “let’s take a break here. Let me talk to this cop to see if I can get Alex up there to deescalate the situation.” Other video released by Jones shows one of his camera crew interacting with USCP officers and asking how Jones can help deescalate the situation.
      <Citation number={133} />
      The Select Committee’s review of the evidence showed that Jones simultaneously called on the crowd to “fight” and start a “revolution,” while occasionally peppering his rhetoric with the word “peacefully.”
    </Typography>

    <Typography className="indent">
      Minutes after Jones’s arrival on the scene, at approximately 2:06 p.m., rioters breached the new police line and stormed up the stairs towards the Columbus Doors (also known as the Rotunda Doors).
      <Citation number={134} />
      The crowd’s cheers and celebration as they move up the steps can be heard while Jones’s camera crew negotiates with USCP officers nearby.
      <Citation number={135} />
      As explained below, the rioters broke through another key breach point with Jones and Alexander on the scene just minutes later.
    </Typography>

    <Image
      src="/images/image-108.jpg"
      alt="Rioters clash with police at the Capitol on January 6, 2021."
      caption="Rioters clash with police at the Capitol on January 6, 2021."
      credit="(Photo by Brent Stirton/Getty Images)"
    />
  </Page>
)
