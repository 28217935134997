import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet6'));

export const Chapter44 = ({ ...args }: any) => (
  <Page {...args}>
    <Image
      src="/images/image-056.jpg"
      alt="Former Acting Attorney General Jeffrey Rosen testifies before the Select Committee on June 23, 2022."
      caption="Former Acting Attorney General Jeffrey Rosen testifies before the Select Committee on June 23, 2022."
      credit="(Photo by House Creative Services)"
      style={{ marginTop: 0 }}
    />

    <Typography className="indent">
      Finally, Attorney General Barr had had enough. He submitted his resignation on December 14, 2020.
      <Citation number={81} />
      During an interview with the Select Committee, former Attorney General Barr reflected on his face-to-face encounters with President Trump in November and December 2020:
    </Typography>
    <Blockquote>
      <Typography>
        And, in that context, I made clear I did not agree with the idea of saying the election was stolen and putting out this stuff which I told the President was bullshit. And, you know, I didn’t want to be part of it. And that’s one of the reasons that went into me deciding to leave when I did. I observed, I think it was on December 1st, that—you know, I believe you can’t live in a world where the incumbent administration stays in power based on its view, unsupported by specific evidence, that the election—that there was fraud in the election.
        <Citation number={82} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Around mid-day on December 14th, Attorney General Barr met with President Trump and Meadows in the Oval Office to discuss his resignation.
      <Citation number={83} />
      When he arrived, and even before Barr could mention his resignation, President Trump began speaking at length about the recently released Allied Security Operations Group (ASOG) report on Dominion voting machines in Antrim County, Michigan.
      <Citation number={84} />
      While the Attorney General had been briefed on the allegations in Antrim County and did not find them credible, he promised the President that he would have DOJ investigate them.
      <Citation number={85} />
      The Attorney General then told President Trump that he had come for a separate reason and wished to speak to the President privately, so Meadows left.
      <Citation number={86} />
    </Typography>

    <Typography className="indent">
      Barr told President Trump that it was clear the President was dissatisfied with him as Attorney General and that he had decided to resign.
      <Citation number={87} />
      President Trump accepted his resignation and asked Barr who would replace him; Attorney General Barr recommended Jeffrey A. Rosen as Acting Attorney General and Richard Donoghue as his deputy.
      <Citation number={88} />
      Although President Trump called Donoghue to discuss the possibility of appointing him Acting Attorney General, Donoghue advised that normal procedures be followed and Rosen be named Acting Attorney General.
      <Citation number={89} />
      President Trump followed this advice, and upon Barr’s departure, Rosen became Acting Attorney General while Donoghue would function as his deputy.
    </Typography>
  </Page>
)
