import { useEffect, useState } from "react";
import { styled, useTheme } from '@mui/material/styles';

import { isProd } from '../utils';

const ADS = {
  DARK: '3125407545',
  LIGHT: '9641084129',
};

const disabled = !isProd();

const StyledIns = styled('ins')(({theme}) => `
  text-align: center;
  display: block;

  &:empty,
  &:not([data-ad-status]),
  &[data-ad-status=""],
  &[data-ad-status=unfilled] {
    height: 0 !important;
    overflow: hidden;
  }
`);

export const GoogleAd = () => {
  const theme = useTheme();
  const googleAdId = theme.palette.mode === 'dark' ? ADS.DARK : ADS.LIGHT;

  const [id, setId] = useState<string>(googleAdId);
  useEffect(() => setId(googleAdId), [googleAdId, setId]);

  useEffect(() => {
    if (disabled) {
      return;
    }

    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch {}
  }, []);

  if (disabled) {
    return <></>;
  }

  return <AdBlock id={id} />;
};

const AdBlock = ({ id }: any) => (
  <StyledIns className="adsbygoogle"
   data-ad-layout="in-article"
   data-ad-format="fluid"
   data-ad-client="ca-pub-8660681674945790"
   data-ad-slot={id} />
);
