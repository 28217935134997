import { lazy } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet8'));

export const Chapter61 = ({ ...args }: any) => (
  <Page {...args}>
    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        THE “STOP THE STEAL” COALITION
      </Typography>
    </Box>

    <Typography className="indent">
      President Trump’s “be wild” tweet immediately mobilized extremists and conspiracy theorists in the “Stop the Steal” coalition. The phrase “Stop the Steal” was originally coined in early 2016 by President Trump’s longtime political advisor, Roger Stone.
      <Citation number={40} />
      At the time, Stone alleged first that Candidate Trump’s Republican rivals were attempting to steal Candidate Trump’s nomination.
      <Citation number={41} />
      After Trump became the nominee, Stone repurposed the saying to claim that former Secretary of State Hillary Clinton would steal the presidency.
      <Citation number={42} />
      When President Trump won the 2016 election, “Stop the Steal” was rendered moot—and did not become a significant political movement until President Trump’s defeat on election night in 2020.
      <Citation number={43} />
      As early as November 5, 2020, Stone advised associates that he intended to reconstitute “Stop the Steal” by building an army of lawyers and suing “like there’s no tomorrow.”
      <Citation number={44} />
    </Typography>

    <Typography className="indent">
      Ali Alexander, a rightwing provocateur who has worked closely with Stone,
      <Citation number={45} />
      quickly organized a new “Stop the Steal” campaign. On November 10, 2020, Alexander established “Stop the Steal” as an entity incorporated in Alabama.
      <Citation number={46} />
      Alexander added a bank account and various websites.
      <Citation number={47} />
    </Typography>

    <Typography className="indent">
      One of Alexander’s key allies in the “Stop the Steal” movement was Alex Jones. Prior to January 6th, Jones riled up crowds both in-person and online with incendiary rhetoric about the election. Jones’ <i>InfoWars</i> was also a platform for others in the election-denial coalition. For instance, both Enrique Tarrio and Stewart Rhodes made multiple appearances on <i>InfoWars</i>, including between election day 2020 and January 6, 2021.
      <Citation number={48} />
    </Typography>

    <Typography className="indent">
      Another frequent guest on <i>InfoWars</i> was Roger Stone—a nexus character in the “Stop the Steal” coalition.
      <Citation number={49} />
      Stone recommended that then Presidential Candidate Donald Trump appear on Jones’s show in December 2015.
      <Citation number={50} />
      Trump accepted the invitation and praised Jones at length during his appearance.
      <Citation number={51} />
      The significance of Trump’s interview with Jones should not be underestimated. Donald Trump was a leading presidential contender at the time and would go on to win the election. His appearance with Jones normalized <i>InfoWars</i>, welcoming its conspiracy-minded audience into Trump’s base.
      <Citation number={52} />
      Trump did not appear on <i>InfoWars</i> again. However, Stone continued to make regular guest appearances.
      <Citation number={53} />
    </Typography>

    <Typography className="indent">
      After election day 2020, Alexander Jones, and other “Stop the Steal” organizers, held rallies around the country to protest fictional claims of voter fraud. These events provided an opportunity for radicals and extremists to coalesce. The Proud Boys, Oath Keepers, and Three Percenters were all attendees. QAnon adherents were well-represented. So, too, were the white nationalist Groypers and their leader, Nick Fuentes.
    </Typography>

    <Typography className="indent">
      “Stop the Steal” events and other protests throughout 2020 helped build the momentum for January 6th. The Select Committee collected data on 85 right-wing events between January 1, 2020, and January 20, 2021, which were inspired by opposition to COVID-19 lockdown measures, racial justice protests, and, later, the perceived theft of President Trump’s victory.
      <Citation number={54} />
      Far-right extremists protested at or inside State capitols, or at other government buildings, in at least 68 instances.
      <Citation number={55} />
      Of those, 49 occurred during the period after the election through January 6th.
      <Citation number={56} />
      In the year leading up to January 6th, there were at least nine events at which far-right actors entered State capitols.
      <Citation number={57} />
      At least four of these capitol incursions—in Michigan,
      <Citation number={58} />
      Idaho,
      <Citation number={59} />
      Arizona,
      <Citation number={60} />
      and Oregon
      <Citation number={61} />
      —involved identifiable individuals who later participated in the attack on the U.S. Capitol.
    </Typography>

    <Image
      src="/images/image-080.jpg"
      alt='Alex Jones and Ali Alexander inside the Georgia State Capitol during a "Stop the Steal" rally on November 18, 2020 in Atlanta, Georgia.'
      caption='Alex Jones and Ali Alexander inside the Georgia State Capitol during a "Stop the Steal" rally on November 18, 2020 in Atlanta, Georgia.'
      credit="(Photo by Elijah Nouvelage/Getty Images)"
    />

    <Typography className="indent">
      Consider, for example, the protests held in Atlanta between November 18 and 21, 2020. Leaders and rank-and-file members of the Proud Boys, Oath Keepers, and Groypers, gathered outside the State capitol and the governor’s mansion for nonstop events, including armed protests. Enrique Tarrio
      <Citation number={62} />
      and Stewart Rhodes
      <Citation number={63} />
      personally led contingents of the Proud Boys and Oath Keepers, respectively.
    </Typography>

    <Typography className="indent">
      Jones first announced the Atlanta events on InfoWars on November 16th. In his announcement, Jones teased that he would be joined by Roger Stone and also called on listeners to “surround the governor’s mansion” in order to prevent the election results from being certified.
      <Citation number={64} />
      Fuentes advertised that he would be speaking at the capitol every day at noon.
      <Citation number={65} />
      In fiery speeches across Atlanta, Fuentes spread election lies as well as wink-and-nod hints at intimidation and violence.
      <Citation number={66} />
    </Typography>

    <Typography className="indent">
      Alexander, standing alongside Jones and Fuentes outside the State capitol on November 18th, exhorted the crowd to “storm the capitol” with them.
      <Citation number={67} />
      The three men led a crowd into the State capitol building. On November 20th, Roger Stone gave a speech outside the Georgia capitol. Speaking through a telephone held up by Alexander, Stone advanced election lies, and finished with a provocative rallying cry: “Victory or death!”
      <Citation number={68} />
      That same day, Fuentes told the crowd, “Look, we’ve been in front of the State capitol, maybe we’ve been trying the wrong approach.”
      <Citation number={69} />
      Days earlier, at a nighttime event outside the governor’s mansion, Alexander, again flanked by Jones and Fuentes, goaded the crowd: “We’ll light the whole shit on fire.”
      <Citation number={70} />
    </Typography>

    <Typography className="indent">
      While the crowd did not turn violent, the “Stop the Steal” protests in Atlanta, Georgia, prefigured January 6th in important respects. “Stop the Steal” organizers tried to use the mob they had assembled—including extremists from the Proud Boys, Oath Keepers, Three Percenters and Groypers—to intimidate lawmakers and overturn the election results in Georgia, which was required to certify former Vice President Biden’s victory in the State by the end of that week.
      <Citation number={71} />
      They implored their followers to “storm the capitol.”
      <Citation number={72} />
      As discussed in Chapter 8, this same coalition of radicals did just that on January 6, 2021.
    </Typography>

    <Typography className="indent">
      Other “Stop the Steal” events helped pave the way for the events of January 6th. Two rallies in Washington D.C.—on November 14 and December 12, 2020—were critically important. Alexander’s “Stop the Steal” was not the only protest organization present at these events. Both were called “Million MAGA Marches” and drew in other rally organizers. One of these other protests was called the “Jericho March” prayer rally.
      <Citation number={73} />
      Regardless, the same constellation of actors that appeared in Atlanta also incited Trump supporters in Washington.
    </Typography>

    <Typography className="indent">
      For instance, during the Jericho March rally on December 12th, Stewart Rhodes called on President Trump to invoke the Insurrection Act as part of a desperate gambit to remain in power. In Rhodes’ vision, he would lead militiamen on behalf of President Trump when others tried to remove him from office.
      <Citation number={74} />
      If President Trump did not invoke the Insurrection Act, Rhodes warned the crowd, then they would be forced to wage a “much more desperate [and] much more bloody war.” Alex Jones also gave an incendiary speech at the Jericho March event, declaring: “I don't know who is going to the White House in 38 days, but I sure know this, Joe Biden is a globalist, and Joe Biden will be removed, one way or another!”
      <Citation number={75} />
    </Typography>

    <Typography className="indent">
      As the crowds gathered in Washington on December 12th, President Trump was publicly lobbying the Supreme Court to hear his fictious claims of election fraud. The President assailed the Supreme Court on Twitter throughout the day.
      <Citation number={76} />
      The “Stop the Steal” coalition was eager to help. After the Jericho March event ended, Jones, his InfoWars co-host Owen Shroyer, and Ali Alexander led a march on the Supreme Court. Once there, the crowd chanted slogans such as “Stop the Steal!”; “1776!!”; “Our revolution!”; and “The fight has just begun!!”
      <Citation number={77} />
    </Typography>

    <Image
      src="/images/image-081.jpg"
      alt="“Million MAGA March” protest on November 14, 2020 in Washington, DC."
      caption="“Million MAGA March” protest on November 14, 2020 in Washington, DC."
      credit="(Photo by Tasos Katopodis/Getty Images)"
    />

    <Typography className="indent">
      President Trump made sure to let the protestors in Washington know that he personally approved of their mission. During the November rally, President Trump waved to the crowd from his presidential motorcade.
      <Citation number={78} />
      Then, on the morning of December 12th, President Trump tweeted: “Wow! Thousands of people forming in Washington (D.C.) for Stop the Steal. Didn’t know about this, but I’ll be seeing them! #MAGA.”
      <Citation number={79} />
      Laterthatday, President Trump flew over the protestors in Marine One.
      <Citation number={80} />
    </Typography>

    <Typography className="indent">
      When President Trump tweeted one week later that there would be a “wild” protest in Washington on January 6th, the “Stop the Steal” coalition immediately began to mobilize. Jones posted an article on the <i>InfoWars</i> website asking readers if they would “answer President Trump’s call to defend the Republic?”
      <Citation number={81} />
      The next day, December 20th, Jones devoted much of his <i>InfoWars</i> show to the President’s announcement. Jones told his audience several times that if 10 million Americans came to Washington, DC on January 6th, Congress would have to listen to them.
      <Citation number={82} />
      He repeated this idea over the course of the episode, saying things such as, “He’s calling you, he needs your help, we need your help, we need 10 million people there,” “[w]e need martial law and have to prevent the police state of foreigners from taking over.” Jones added: “It’s literally in our hands. It’s literally up to us.”
      <Citation number={83} />
    </Typography>

    <Typography className="indent">
      Other <i>InfoWars</i> hosts promoted the “wild” protest as well. In late December, Matt Bracken told InfoWars viewers that it may be necessary to storm the U.S. Capitol. “We're going to only be saved by millions of Americans moving to Washington, occupying the entire area, if—if necessary storming right into the Capitol,” Bracken said. “You know, they're—we know the rules of engagement. If you have enough people, you can push down any kind of a fence or a wall.”
      <Citation number={84} />
    </Typography>

    <Typography className="indent">
      Far-right extremists planned to do just that.
    </Typography>
  </Page>
)
