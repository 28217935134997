import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../components/Blockquote';
import { Image } from '../../components/Image';
import { Page } from '../../components/Page';
import { StarDivider } from '../../components/StarDivider';
import { StyledListContainer } from '../../components/StyledListContainer';

const Citation = lazy(() => import('../Citations/CitationSheet2'));

export const OpeningStatement = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      On October 31, 2022, in a Federal courthouse in Washington, DC, Graydon Young testified against Stewart Rhodes and other members of the Oath Keepers militia group. The defendants had been charged with seditious conspiracy against the United States and other crimes related to the January 6, 2021, attack on Congress.
      <Citation number={1} />
    </Typography>
    <Typography className="indent">
      In his testimony that day, Young explained to the jury how he and other Oath Keepers were provoked to travel to Washington by President Donald Trump’s tweets and by Trump’s false claims that the 2020 Presidential election was “stolen” from him.
      <Citation number={2} />
      And, in emotional testimony, Young acknowledged what he and others believed they were doing on January 6th: attacking Congress in the manner the French had attacked the Bastille at the outset of the French Revolution.
      <Citation number={3} />
      Reflecting on that day more than a year and half later, Young testified:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Prosecutor</strong>: And so how do you feel about the fact that you were pushing towards a line of police officers?
      </Typography>
      <Typography>
        <strong>Young</strong>: Today I feel extremely ashamed and embarrassed....
      </Typography>
      <Typography>
        <strong>Prosecutor</strong>: How did you feel at the time?
      </Typography>
      <Typography>
        <strong>Young</strong>: I felt like, again, we were continuing in some kind of historical event to achieve a goal.
      </Typography>
      
      <StarDivider>***</StarDivider>
      
      <Typography>
        <strong>Prosecutor</strong>: Looking back now almost two years later, what would that make you as someone who was coming to D.C. to fight against the government?
      </Typography>
      <Typography>
        <strong>Young</strong>: I guess I was [acting] like a traitor, somebody against my own government.
        <Citation number={4} />
      </Typography>
    </Blockquote>

    <Image
      src="/images/image-008.jpg"
      alt="Protestors gather at the Capitol."
      caption="Protestors gather at the Capitol."
      credit="(Photo by Samuel Corum/Getty Images)"
    />

    <Typography className="indent">
      Young’s testimony was dramatic, but not unique. Many participants in the attack on the Capitol acknowledged that they had betrayed their own country:
    </Typography>

    <StyledListContainer>
      <ul>
        <li>
          <Typography>
            <strong>Reimler</strong>: “And I’m sorry to the people of this country for threatening the democracy that makes this country so great... My participation in the events that day were part of an attack on the rule of law.”
            <Citation number={5} />
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Pert</strong>: “I know that the peaceful transition of power is to ensure the common good for our nation and that it is critical in protecting our country’s security needs. I am truly sorry for my part and accept full responsibility for my actions.”
            <Citation number={6} />
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Markofski</strong>: “My actions put me on the other side of the line from my brothers in the Army. The wrong side. Had I lived in the area, I would have been called up to defend the Capitol and restore order... My actions brought dishonor to my beloved U.S. Army National Guard.”
            <Citation number={7} />
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Witcher</strong>: “Every member—every male member of my family has served in the military, in the Marine Corps, and most have saw combat. And I cast a shadow and cast embarrassment upon my family name and that legacy.”
            <Citation number={8} />
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Edwards</strong>: ““I am ashamed to be for the first time in my 68 years, standing before a judge, having pleaded guilty to committing a crime, ashamed to be associated with an attack on the United States Capitol, a symbol of American democracy and greatness that means a great deal to me.”
            <Citation number={9} />
          </Typography>
        </li>
      </ul>
    </StyledListContainer>

    <Typography className="indent">
      Hundreds of other participants in the January 6th attack have pleaded guilty, been convicted, or await trial for crimes related to their actions that day. And, like Young, hundreds of others have acknowledged exactly what provoked them to travel to Washington, and to engage in violence. For example:
    </Typography>

    <StyledListContainer>
      <ul>
        <li>
          <Typography>
            <strong>Ronald Sandlin</strong>, who threatened police officers in the Capitol saying, “[y]ou’re going to die,” posted on December 23, 2020: “I’m going to be there to show support for our president and to do my part to stop the steal and stand behind Trump when he decides to cross the rubicon. If you are a patriot I believe it’s your duty to be there. I see it as my civic responsibility.”
            <Citation number={10} />
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Garret Miller</strong>, who brought a gun to the Capitol on January 6th, explained: “I was in Washington, D.C. on January 6, 2021, because I believed I was following the instructions of former President Trump and he was my president and the commander-in-chief. His statements also had me believing the election was stolen from him.”
            <Citation number={11} />
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>John Douglas Wright</strong> explained that he brought busloads of people to Washington, DC, on January 6th “because [Trump] called me there, and he laid out what is happening in our government.”
            <Citation number={12} />
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Lewis Cantwell</strong> testified: If “the President of the United States... [is] out on TV telling the world that it was stolen, what else would I believe, as a patriotic American who voted for him and wants to continue to see the country thrive as I thought it was?”
            <Citation number={13} />
          </Typography>
        </li>
        <li>
          <Typography>
            Likewise, <strong>Stephen Ayres</strong> testified that “with everything the President was putting out” ahead of January 6th that “the election was rigged... the votes were wrong and stuff... it just got into my head.” “The President [was] calling on us to come” to Washington, DC.
            <Citation number={14} />
            Ayres “was hanging on every word he [President Trump] was saying”.
            <Citation number={15} />
            Ayres posted that “Civil War will ensue” if President Trump did not stay in power after January 6th.
            <Citation number={16} />
          </Typography>
        </li>
      </ul>
    </StyledListContainer>

    <Typography className="indent">
      The Committee has compiled hundreds of similar statements from participants in the January 6th attack.
      <Citation number={17} />
    </Typography>

    <Typography className="indent">
      House Resolution 503 instructed the Select Committee to “investigate and report upon the facts, circumstances, and causes relating to the January 6, 2021, domestic terrorist attack upon the United States Capitol Complex” and to “issue a final report” containing “findings, conclusions, and recommendations for corrective measures.” The Select Committee has conducted nine public hearings, presenting testimony from more than 70 witnesses. In structuring our investigation and hearings, we began with President Trump’s contentions that the election was stolen and took testimony from nearly all of the President’s principal advisors on this topic. We focused on the rulings of more than 60 Federal and State courts rejecting President Trump’s and his supporters’ efforts to reverse the electoral outcome.
    </Typography>

    <Typography className="indent">
      Despite the rulings of these courts, we understood that millions of Americans still lack the information necessary to understand and evaluate what President Trump has told them about the election. For that reason, our hearings featured a number of members of President Trump’s inner circle refuting his fraud claims and testifying that the election was not in fact stolen. In all, the Committee displayed the testimony of more than four dozen Republicans—by far the majority of witnesses in our hearings— including two of President Trump’s former Attorneys General, his former White House Counsel, numerous members of his White House staff, and the highest-ranking members of his 2020 election campaign, including his campaign manager and his campaign general counsel. Even key individuals who worked closely with President Trump to try to overturn the 2020 election on January 6th ultimately <i>admitted</i> that they lacked actual evidence sufficient to change the election result, and they <i>admitted</i> that what they were attempting was unlawful.
      <Citation number={18} />
    </Typography>

    <Typography className="indent">
      This Report supplies an immense volume of information and testimony assembled through the Select Committee’s investigation, including information obtained following litigation in Federal district and appellate courts, as well as in the U.S. Supreme Court. Based upon this assembled evidence, the Committee has reached a series of specific findings,
      <Citation number={19} />
      including the following:
    </Typography>

    <StyledListContainer>
      <ol>
        <li>
          <Typography>
            Beginning election night and continuing through January 6th and thereafter, Donald Trump purposely disseminated false allegations of fraud related to the 2020 Presidential election in order to aid his effort to overturn the election and for purposes of soliciting contributions. These false claims provoked his supporters to violence on January 6th.
          </Typography>
        </li>
        <li>
          <Typography>
            Knowing that he and his supporters had lost dozens of election lawsuits, and despite his own senior advisors refuting his election fraud claims and urging him to concede his election loss, Donald Trump refused to accept the lawful result of the 2020 election. Rather than honor his constitutional obligation to “take Care that the Laws be faithfully executed,” President Trump instead plotted to overturn the election outcome.
          </Typography>
        </li>
        <li>
          <Typography>
            Despite knowing that such an action would be illegal, and that no State had or would submit an altered electoral slate, Donald Trump corruptly pressured Vice President Mike Pence to refuse to count electoral votes during Congress’s joint session on January 6th.
          </Typography>
        </li>
        <li>
          <Typography>
            Donald Trump sought to corrupt the U.S. Department of Justice by attempting to enlist Department officials to make purposely false statements and thereby aid his effort to overturn the Presidential election. After that effort failed, Donald Trump offered the position of Acting Attorney General to Jeff Clark knowing that Clark intended to disseminate false information aimed at overturning the election.
          </Typography>
        </li>
        <li>
          <Typography>
            Without any evidentiary basis and contrary to State and Federal law, Donald Trump unlawfully pressured State officials and legislators to change the results of the election in their States.
          </Typography>
        </li>
        <li>
          <Typography>
            Donald Trump oversaw an effort to obtain and transmit false electoral certificates to Congress and the National Archives.
          </Typography>
        </li>
        <li>
          <Typography>
            Donald Trump pressured Members of Congress to object to valid slates of electors from several States.
          </Typography>
        </li>
        <li>
          <Typography>
            Donald Trump purposely verified false information filed in Federal court.
          </Typography>
        </li>
        <li>
          <Typography>
            Based on false allegations that the election was stolen, Donald Trump summoned tens of thousands of supporters to Washington for January 6th. Although these supporters were angry and some were armed, Donald Trump instructed them to march to the Capitol on January 6th to “take back” their country.
          </Typography>
        </li>
        <li>
          <Typography>
            Knowing that a violent attack on the Capitol was underway and knowing that his words would incite further violence, Donald Trump purposely sent a social media message publicly condemning Vice President Pence at 2:24 p.m. on January 6th.
          </Typography>
        </li>
        <li>
          <Typography>
            Knowing that violence was underway at the Capitol, and despite his duty to ensure that the laws are faithfully executed, Donald Trump refused repeated requests over a multiple hour period that he instruct his violent supporters to disperse and leave the Capitol, and instead watched the violent attack unfold on television. This failure to act perpetuated the violence at the Capitol and obstructed Congress’s proceeding to count electoral votes.
          </Typography>
        </li>
        <li>
          <Typography>
            Each of these actions by Donald Trump was taken in support of a multi-part conspiracy to overturn the lawful results of the 2020 Presidential election.
          </Typography>
        </li>
        <li>
          <Typography>
            The intelligence community and law enforcement agencies did successfully detect the planning for potential violence on January 6th, including planning specifically by the Proud Boys and Oath Keeper militia groups who ultimately led the attack on the Capitol. As January 6th approached, the intelligence specifically identified the potential for violence at the U.S. Capitol. This intelligence was shared within the executive branch, including with the Secret Service and the President’s National Security Council.
          </Typography>
        </li>
        <li>
          <Typography>
            Intelligence gathered in advance of January 6th did not support a conclusion that Antifa or other left-wing groups would likely engage in a violent counter-demonstration, or attack Trump supporters on January 6th. Indeed, intelligence from January 5th indicated that some left-wing groups were instructing their members to “stay at home” and not attend on January 6th.
            <Citation number={20} />
            Ultimately, none of these groups was involved to any material extent with the attack on the Capitol on January 6th.
          </Typography>
        </li>
        <li>
          <Typography>
            Neither the intelligence community nor law enforcement obtained intelligence in advance of January 6th on the full extent of the ongoing planning by President Trump, John Eastman, Rudolph Giuliani and their associates to overturn the certified election results. Such agencies apparently did not (and potentially could not) anticipate the provocation President Trump would offer the crowd in his Ellipse speech, that President Trump would “spontaneously” instruct the crowd to march to the Capitol, that President Trump would exacerbate the violent riot by sending his 2:24 p.m. tweet condemning Vice President Pence, or the full scale of the violence and lawlessness that would ensue. Nor did law enforcement anticipate that President Trump would refuse to direct his supporters to leave the Capitol once violence began. No intelligence community advance analysis predicted exactly how President Trump would behave; no such analysis recognized the full scale and extent of the threat to the Capitol on January 6th.
          </Typography>
        </li>
        <li>
          <Typography>
            Hundreds of Capitol and DC Metropolitan police officers performed their duties bravely on January 6th, and America owes those individuals immense gratitude for their courage in the defense of Congress and our Constitution. Without their bravery, January 6th would have been far worse. Although certain members of the Capitol Police leadership regarded their approach to January 6th as “all hands on deck,” the Capitol Police leadership did not have sufficient assets in place to address the violent and lawless crowd.
            <Citation number={21} />
            Capitol Police leadership did not anticipate the scale of the violence that would ensue after President Trump instructed tens of thousands of his supporters in the Ellipse crowd to march to the Capitol, and then tweeted at 2:24 p.m. Although Chief Steven Sund raised the idea of National Guard support, the Capitol Police Board did not request Guard assistance prior to January 6th. The Metropolitan Police took an even more proactive approach to January 6th, and deployed roughly 800 officers, including responding to the emergency calls for help at the Capitol. Rioters still managed to break their line in certain locations, when the crowd surged forward in the immediate aftermath of Donald Trump’s 2:24 p.m. tweet. The Department of Justice readied a group of Federal agents at Quantico and in the District of Columbia, anticipating that January 6th could become violent, and then deployed those agents once it became clear that police at the Capitol were overwhelmed. Agents from the Department of Homeland Security were also deployed to assist.
          </Typography>
        </li>
        <li>
          <Typography>
            President Trump had authority and responsibility to direct deployment of the National Guard in the District of Columbia, but never gave any order to deploy the National Guard on January 6th or on any other day. Nor did he instruct any Federal law enforcement agency to assist. Because the authority to deploy the National Guard had been delegated to the Department of Defense, the Secretary of Defense could, and ultimately did deploy the Guard. Although evidence identifies a likely miscommunication between members of the civilian leadership in the Department of Defense impacting the timing of deployment, the Committee has found no evidence that the Department of Defense intentionally delayed deployment of the National Guard. The Select Committee recognizes that some at the Department had genuine concerns, counseling caution, that President Trump might give an illegal order to use the military in support of his efforts to overturn the election.
          </Typography>
        </li>
      </ol>
    </StyledListContainer>

    <StarDivider>***</StarDivider>

    <Typography className="indent">
      This Report begins with a factual overview framing each of these conclusions and summarizing what our investigation found. That overview is in turn supported by eight chapters identifying the very specific evidence of each of the principal elements of President Trump’s multi-part plan to overturn the election, along with evidence regarding intelligence gathered before January 6th and security shortfalls that day.
    </Typography>

    <Typography className="indent">
      Although the Committee’s hearings were viewed live by tens of millions of Americans and widely publicized in nearly every major news source
      <Citation number={22} />
      the Committee also recognizes that other news outlets and commentators have actively discouraged viewers from watching, and that millions of other Americans have not yet seen the actual evidence addressed by this Report. Accordingly, the Committee is also releasing video summaries of relevant evidence on each major topic investigated.
    </Typography>

    <Typography className="indent">
      This Report also examines the legal implications of Donald Trump and his co-conspirators’ conduct and includes criminal referrals to the Department of Justice regarding President Trump and certain other individuals. The criminal referrals build upon three relevant rulings issued by a Federal district court and explain in detail how the facts found support further evaluation by the Department of Justice of specific criminal charges. To assist the public in understanding the nature and importance of this material, this Report also contains sections identifying how the Committee has evaluated the credibility of its witnesses and suggests that the Department of Justice further examine possible efforts to obstruct our investigation. We also note that more than 30 witnesses invoked their Fifth Amendment privilege against self-incrimination, others invoked Executive Privilege or categorically refused to appear (including Steve Bannon, who has since been convicted of contempt of Congress).
    </Typography>

    <Typography className="indent">
      Finally, this report identifies a series of legislative recommendations, including the Presidential Election Reform Act, which has already passed the House of Representatives.
    </Typography>
  </Page>
);
