import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet6'));

export const Chapter42 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      As Barr predicted, the President did call on him for information about alleged election fraud. Trump challenged him with a blizzard of conspiracy theories in three face-to-face meetings after the election. The first such meeting occurred on November 23, 2020.
    </Typography>

    <Typography className="indent">
      On November 23rd, the Attorney General spoke with White House Counsel Pat Cipollone, who said that it was important for him come to the White House and speak to President Trump.
      <Citation number={16} />
      Barr had not seen the President since before the election in late October, and the White House counsel believed that it was important that the Attorney General explain what the Department of Justice was doing related to claims of election fraud.
      <Citation number={17} />
    </Typography>

    <Image
      src="/images/image-055.jpg"
      alt="U.S. Attorney General William Barr at the Department of Justice on December 21, 2020."
      caption="U.S. Attorney General William Barr at the Department of Justice on December 21, 2020."
      credit="(Photo by Michael Reynolds-Pool/Getty Images)"
    />

    <Typography className="indent">
      “The President said there had been major fraud and that, as soon as the facts were out, the results of the election would be reversed,” Barr recalled. Trump continued “for quite a while,” and Barr was “expecting” what came next.
      <Citation number={18} />
      President Trump alleged that “the Department of Justice doesn’t think it has a role looking into these fraud claims.”
      <Citation number={19} />
      Barr anticipated this line of attack because the President’s counsel, Rudolph Giuliani, was making all sorts of wild, unsubstantiated claims.
      <Citation number={20} />
      And Giuliani wanted to blame DOJ for the fact that no one had come up with any real evidence of fraud.
      <Citation number={21} />
      Of course, by the time of this meeting, U.S. Attorneys’ Offices had been explicitly authorized to investigate substantial claims for 2 weeks and had yet to find any evidence of significant voter fraud.
      <Citation number={22} />
    </Typography>

    <Typography className="indent">
      Barr explained to the President why he was wrong. DOJ, was willing to investigate any “specific and credible allegations of fraud.”
      <Citation number={23} />
      The fact of the matter was that the claims being made were “just not meritorious” and were “not panning out.”
      <Citation number={24} />
      Barr emphasized to the President that DOJ “doesn’t take sides in elections” and “is not an extension of your legal team.”
      <Citation number={25} />
    </Typography>

    <Typography className="indent">
      During the November 23rd meeting, Barr also challenged one of President Trump’s central lies. He “specifically raised the Dominion voting machines, which I found to be one of the most disturbing allegations.”
      <Citation number={26} />
      “Disturbing,” Barr explained, because there was “absolutely zero basis for the allegations,” which were being “made in such a sensational way that they obviously were influencing alot of people, members of the public.”
      <Citation number={27} />
      Americans were being deceived into thinking “that there was this systematic corruption in the system and that their votes didn’t count and that these machines, controlled by somebody else, were actually determining it, which was complete nonsense.”
      <Citation number={28} />
      Barr stressed to the President that this was “crazy stuff,” arguing that not only was the conspiracy theory a waste of time,but it was also “doing [a] great, great disservice to the country.”
      <Citation number={29} />
    </Typography>

    <Typography className="indent">
      As Attorney General Barr left the meeting, he talked with Mark Meadows, the White House Chief of Staff, and Jared Kushner, President Trump’s son-in-law.
      <Citation number={30} />
      “I think he’s become more realistic and knows that there’s a limit to how far he can take this,” Meadows said, according to Barr.
      <Citation number={31} />
      Kushner reassured Barr, “we’re working on this, we’re working on it.”
      <Citation number={32} />
      Barr was hopeful that the President was beginning to accept reality.
      <Citation number={33} /> The opposite happened.
    </Typography>

    <Typography className="indent">
      “I felt that things continued to deteriorate between the 23rd and the weekend of the 29th,” Barr recalled.
      <Citation number={34} />
      Barr was concerned because President Trump began meeting with delegations of State legislators, and it appeared to him that “there was maneuvering going on.”
      <Citation number={35} />
      Barr had “no problem” with challenging an election “through the appropriate process,” but “worried” that he “didn’t have any visibility into what was going on” and that the “President was digging in.”
      <Citation number={36} />
    </Typography>
  </Page>
)
