import { lazy } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet6'));

export const Chapter414 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      On January 3rd, Clark informed Rosen that he had decided to accept the President’s offer to serve as the Acting Attorney General. Clark offered Rosen the position of his deputy.
      <Citation number={274} />
      Rosen thought that Clark’s offer was “preposterous” and “nonsensical.”
      <Citation number={275} />
      Rosen told the Select Committee that “there was no universe where I was going to do that to stay on and support someone else doing things that were not consistent with what I thought should be done.”
      <Citation number={276} />
      Donoghue believed it was a done deal, and Clark would become the head of DOJ. But Pat Cipollone told Rosen that it was “not a done deal and that we should fight this out at the White House.”
      <Citation number={277} />
    </Typography>

    <Typography className="indent">
      White House call logs from January 3rd show that President Trump and Clark spoke four times that day starting at 6:59 a.m.
      <Citation number={278} />
      The first three calls of the day, two in the morning and one in the early afternoon, show that the President spoke with “Mr. Jeffrey Clark.”
      <Citation number={279} />
      The final call between the two of them, from 4:19 to 4:22 p.m., however, shows that President Trump spoke to “Acting Attorney General Jeffrey Clark,” suggesting that Clark had, in fact, accepted the President’s offer.
      <Citation number={280} />
      <Citation number={272} />
    </Typography>

    <Typography className="indent">
      Acting Attorney General Rosen told the Select Committee that he would have felt comfortable being replaced by either Donoghue or Engel, but he did not “want for the Department of Justice to be put in a posture where it would be doing things that were not consistent with the truth, were not consistent with its own appropriate role, or were not consistent with the Constitution.”
      <Citation number={281} />
    </Typography>

    <Image
      src="/images/image-061.jpg"
      alt="Select Committee graphic"
      caption="Select Committee graphic"
    />

    <Typography className="indent">
      As a result, Rosen took four immediate steps to try and prevent Clark’s ascension to Attorney General. First, he called Meadows and asked him to set up a meeting for that evening with President Trump.
      <Citation number={282} />
      Second, he spoke to Cipollone, who told Rosen that Clark’s appointment was not inevitable and that he would also be at the meeting that evening to support Rosen and Donoghue.
      <Citation number={283} />
      Third, Rosen called Engel and asked him to come to DOJ headquarters so he could attend the White House meeting.
      <Citation number={284} />
      Finally, Rosen asked Donoghue and another senior Department attorney named Patrick Hovakimian to convene a meeting of the rest of the Department’s leadership to describe the situation to them and hear how they would react to Clark’s appointment.
      <Citation number={285} />
    </Typography>

    <Typography className="indent">
      Hovakimian set up a conference call. Although some of the Assistant Attorneys General were not able to participate in the call, all of those who did agreed that they would resign if Rosen were removed from office.
      <Citation number={286} />
      Pat Hovakimian drafted a resignation letter that read:
    </Typography>

    <Blockquote>
      <Typography>
        This evening, after Acting Attorney General Jeff Rosen over the course of the last week repeatedly refused the President’s direct instructions to utilize the Department of Justice’s law enforcement powers for improper ends, the President removed Jeff from the Department. PADAG Rich Donoghue and I resign from the Department, effectively immediately.
        <Citation number={287} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Hovakimian never sent the letter because the threat of mass resignations dissuaded President Trump from replacing Rosen. Regardless, the letter stated a plain truth: President Trump was trying to use DOJ for his own “improper ends.”
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
				THE JANUARY 3, 2021, OVAL OFFICE MEETING
  	  </Typography>
  	</Box>

    <Typography className="indent">
      At Rosen’s request, White House Chief of Staff Mark Meadows arranged a meeting with the President at 6:15 p.m. that evening.
      <Citation number={288} />
    </Typography>

    <Typography className="indent">
      We should pause to reflect on the timing and purpose of the meeting. Congress was set to meet in a joint session in less than 72 hours. The States had already certified their electors. Former Vice President Biden was going to be certified as the winner of the 2020 Presidential election. There was no material dispute over Biden’s victory. Trump and his lawyers had not produced any evidence of significant fraud. Instead, they presented one nonsensical conspiracy theory after another. The DOJ and FBI were forced to debunk these claims—and they did.
    </Typography>

    <Typography className="indent">
      None of this stopped President Trump’s effort to subvert DOJ. Quite the opposite. The President pushed forward with a plan to install Jeff Clark as the Acting Attorney General, apparently to attempt to interfere with the certification of the electoral college vote on January 6th. It is for this reason Rosen requested an emergency meeting on January 3rd.
    </Typography>

    <Typography className="indent">
      Before heading into the Oval Office, Rosen and Donoghue discussed the possible leadership change with Cipollone and Pat Philbin. “They were completely opposed to it,” Donoghue explained.
      <Citation number={289} />
      In fact, no one who attended the Oval Office meeting supported the leadership change—other than Jeff Clark.
      <Citation number={290} />
      Donoghue didn’t initially join the meeting, but the President soon called him in.
      <Citation number={291} />
    </Typography>

    <Typography className="indent">
      During the meeting, Clark attempted to defend the last-minute move to make him Acting Attorney General. Clark said he would “conduct real investigations that would, in his view, uncover widespread fraud.”
      <Citation number={292} />
      Clark declared that this was the “last opportunity to sort of set things straight with this defective election,” and he had the “intelligence,” the “will,” and “desire” to “pursue these matters in the way that the President thought most appropriate.”
      <Citation number={293} />
      Everyone else quickly disagreed.
      <Citation number={294} />
    </Typography>

    <Typography className="indent">
      President Trump asked Donoghue and Engel what they would do, and both confirmed they would resign.
      <Citation number={295} />
      Donoghue added that theirs would not be the only resignations. “You should understand that your entire Department leadership will resign,” Donoghue recalled saying.
      <Citation number={296} />
      This included every Assistant Attorney General. “Mr. President, these aren’t bureaucratic leftovers from another administration,” Donoghue continued.
      <Citation number={297} />
      “You picked them. This is your leadership team. You sent every one of them to the Senate; you got them confirmed.”
      <Citation number={298} />
      Donoghue argued that the President would look bad in the wake of the mass resignations. “What is that going to say about you, when we all walk out at the same time?”
      <Citation number={299} />
      Donoghue recalled asking the President. “And what happens if, within 48 hours, we have hundreds of resignations from your Justice Department because of your actions? What does that say about your leadership?”
      <Citation number={300} />
      Steve Engel reinforced Donoghue’s point, saying that Clark would be leading a “graveyard.”
      <Citation number={301} />
    </Typography>

    <Typography className="indent">
      White House Counsel Pat Cipollone threatened to resign as well, describing Clark’s letter as a “murder-suicide pact.”
      <Citation number={302} />
      Cipollone warned that the letter would “damage everyone who touches it” and no one should have anything to do with it.
      <Citation number={303} />
    </Typography>

    <Typography className="indent">
      Some of the participants in the meeting argued that Clark was the wrong person for the job of Attorney General. Clark attempted to defend his credentials, arguing that he had been involved in complicated civil and environmental litigation.
      <Citation number={304} />
      “That’s right. You’re an environmental lawyer,” Donoghue fired back.
      <Citation number={305} />
      “How about you go back to your office, and we’ll call you when there’s an oil spill.”
      <Citation number={306} />
    </Typography>

    <Typography className="indent">
      The meeting lasted approximately 3 hours.
      <Citation number={307} /> Only toward the end of the contentious affair did President Trump decide to reverse his earlier decision to make Clark the Acting Assistant Attorney General. Donoghue recalled President Trump addressing Clark along the following lines:
    </Typography>

    <Blockquote>
      <Typography>
        I appreciate your willingness to do it. I appreciate you being willing to suffer the abuse. But the reality is, you’re not going to get anything done. These guys are going to quit. Everyone else is going to resign. It’s going to be a disaster. The bureaucracy will eat you alive. And no matter how you want to get things done in the next few weeks, you won’t be able to get it done, and it’s not going to be worth the breakage.
        <Citation number={308} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Clark tried to change President Trump’s mind, saying “history is calling, this our opportunity” and “we can get this done.”
      <Citation number={309} />
      But the President was clearly rattled by the threat of mass defections and reiterated that the change would not happen. President Trump then wondered what would happen to Clark, and if Donoghue was going to fire him. Donoghue explained that only the President had that authority. That was the end of the matter. “And we all got up and walked out of the Oval Office,” Donoghue recalled.
      <Citation number={310} />
    </Typography>

    <Typography className="indent">
      But for one DOJ employee, the matter was not entirely settled. During the January 3rd meeting in the Oval Office, President Trump complained bitterly about BJay Pak, the U.S. Attorney for the Northern District of Georgia.
      <Citation number={311} />
      Barr had tasked Pak with investigating the State Farm Arena video in early December 2020. Like the FBI and Georgia State officials, Pak concluded that nothing nefarious had occurred. President Trump was dissatisfied.
      <Citation number={312} />
    </Typography>

    <Typography className="indent">
      “No wonder nothing’s been found in Atlanta, because the U.S. attorney there is a Never Trumper,” Donoghue recalled the President saying.
      <Citation number={313} />
      Donoghue objected, saying Pak had “been doing his job.”
      <Citation number={314} />
      But the President insisted, pointing out that Pak criticized him years earlier. “This guy is a Never Trumper,” the President reiterated.
      <Citation number={315} />
      “He should never have been in my administration to begin with. How did this guy end up in my administration?”
      <Citation number={316} />
      The President threatened to fire Pak.
      <Citation number={317} />
      When Donoghue pointed out that Pak was already planning to resign the next day, a Monday, President Trump insisted that it be Pak’s last day on the job.
      <Citation number={318} />
      Pak later confirmed to Donoghue that he would be leaving the next day.
      <Citation number={319} />
    </Typography>

    <Typography className="indent">
      President Trump asked if those in attendance at the Oval Office meeting knew Bobby Christine, who was the U.S. Attorney for the Southern District of Georgia.
      <Citation number={320} />
      Even though Pak had a first assistant, who was next in line for Pak’s job upon his resignation, President Trump wanted Christine to take the role.
      <Citation number={321} />
      Christine did take over for Pak, but he did not find any evidence of fraud either. It was Donoghue’s impression that Christine “concluded that the election matters... were handled appropriately.”
      <Citation number={322} />
    </Typography>

    <Typography className="indent">
      Later in the evening of January 3rd, President Trump called Donoghue to pass along yet another conspiracy theory.
      <Citation number={323} />
      The President had heard that an ICE agent outside of Atlanta was in custody of a truck filled with shredded ballots.
      <Citation number={324} />
      Donoghue explained that ICE agents are part of the Department of Homeland Security, so the matter would be under that Department’s purview. President Trump asked Donoghue to inform Ken Cuccinelli.
      <Citation number={325} />
      That story—like all the others—turned out to be fiction when DOJ investigators evaluated the claim. The truck was carrying shredded ballots, but they were from a <i>previous</i> election. The old ballots had been shredded to make room for storing ballots from the 2020 election.
      <Citation number={326} />
    </Typography>
  </Page>
)
