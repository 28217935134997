import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const StyledToggleButton: any = styled(ToggleButton)(({ theme }: any) => `
  padding: ${theme.spacing(.5)};
`);

export const StyledToggleLabel: any = styled(Typography)(({ theme }: any) => `
  margin: 0 ${theme.spacing(1)};
  font-size: .8em;

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`);