import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';
import { StarDivider } from '../../../components/StarDivider';

const Citation = lazy(() => import('../../Citations/CitationSheet2'));

export const EffortsToPressure = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Anticipating that the Eastman strategy for January 6th would be implemented, President Trump worked with a handful of others to prepare a series of false Trump electoral slates for seven States Biden actually won. President Trump personally conducted a teleconference with Eastman and Republican National Committee Chair Ronna McDaniel “a few days before December 14” and solicited the RNC’s assistance with the scheme.
      <Citation number={218} />
      McDaniel agreed to provide that assistance.
      <Citation number={219} />
      President Trump disregarded the rulings of the courts and rejected the findings and conclusions and advice from his Justice Department, his campaign experts, and his White House and Cabinet advisors. He chose instead to try to overturn the election on January 6th and took a series of very specific steps to attempt to achieve that result.
    </Typography>

    <Typography className="indent">
      A series of contemporaneous documents demonstrate what President Trump and his allies, including attorney Kenneth Chesebro, were attempting to accomplish: they anticipated that the President of the Senate (which, under the Constitution, is the Vice President) could rely upon these false slates of electors on January 6th to justify refusing to count genuine electoral votes.
      <Citation number={220} />
    </Typography>

    <Typography className="indent">
      The false slates were created by fake Republican electors on December 14th, at the same time the actual, certified electors in those States were meeting to cast their States’ Electoral College votes for President Biden. By that point in time, election-related litigation was over in all or nearly all of the subject States, and Trump Campaign election lawyers realized that the fake slates could not be lawful or justifiable on any grounds. Justin Clark, the Trump Campaign Deputy Campaign Manager and Senior Counsel told the Select Committee that he “had real problems with the process.”
      <Citation number={221} />
      Clark warned his colleagues, “unless we have litigation pending like in these States, like, I don’t think this is appropriate or, you know, this isn’t the right thing to do. I don’t remember how I phrased it, but I got into a little bit of a back and forth and I think it was with Ken Chesebro, where I said, ‘Alright, you know, you just get after it, like, I’m out.’”
      <Citation number={222} />
    </Typography>

    <Typography className="indent">
      Matthew Morgan, the Trump Campaign General Counsel, told the Select Committee that without an official State certificate of ascertainment,
      <Citation number={223} />
      “the [fake] electors were, for lack of a better way of saying it, no good or not—not valid.”
      <Citation number={224} />
    </Typography>

    <Image
      src="/images/image-014.jpg"
      alt="Graphic depicting the difference between the real and the fake elector certificates."
      caption="Graphic depicting the difference between the real and the fake elector certificates."
    />

    <Typography className="indent">
      The Office of White House Counsel also appears to have expressed concerns with this fake elector plan. In his interview by the Select Committee, White House Counsel Pat Cipollone acknowledged his view that by mid-December, the process was “done” and that his deputy, Pat Philbin, may have advised against the fake elector strategy.
      <Citation number={225} />
      In an informal Committee interview, Philbin described the fake elector scheme as one of the “bad theories” that were like “Whac-A-Mole” in the White House during this period.
      <Citation number={226} />
      Cipollone agreed with this characterization.
      <Citation number={227} />
    </Typography>

    <Typography className="indent">
      In her testimony, Cassidy Hutchinson testified that she heard at least one member of the White House Counsel’s Office say that the plan was not legal:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: [T]o be clear, did you hear the White House Counsel’s Office say that this plan to have alternate electors meet and cast votes for Donald Trump in States that he had lost was not legally sound?
      </Typography>
      <Typography>
        <strong>Hutchinson</strong>: Yes, sir.
        <Citation number={228} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Multiple Republicans who were persuaded to sign the fake certificates also testified that they felt misled or betrayed, and would not have done so had they known that the fake votes would be used on January 6th without an intervening court ruling. One elector told the Select Committee that he thought his vote would be strictly contingent: “[I]t was a very consistent message that we were told throughout all of that, is this is the only reason why we’re doing this, is to preserve the integrity of being able to have a challenge.”
      <Citation number={229} />
    </Typography>

    <Typography className="indent">
      The “Chairperson” of the Wisconsin fake electors, who was also at the time Chairman of the Wisconsin Republican Party, insisted in testimony to the Select Committee that he “was told that these would only count if a court ruled in our favor” and that he wouldn’t have supported anyone using the Trump electors’ votes without a court ruling.
      <Citation number={230} />
    </Typography>

    <Typography className="indent">
      Despite the fact that all major election lawsuits thus far had failed, President Trump and his co-conspirators in this effort, including John Eastman and Kenneth Chesebro, pressed forward with the fake elector scheme. Ultimately, these false electoral slates, five of which purported to represent the “duly elected” electoral college votes of their States, were transmitted to Executive Branch officials at the National Archives, and to the Legislative Branch, including to the Office of the President of the Senate, Vice President Mike Pence.
      <Citation number={231} />
    </Typography>

    <Typography className="indent">
      The fake electors followed Chesebro’s step-by-step instructions for completing and mailing the fake certificates to multiple officials in the U.S. Government,
      <Citation number={232} />
      complete with registered mail stickers and return address labels identifying senders like the “Arizona Republican Party” and the “Georgia Republican Party.”
      <Citation number={233} />
      The Wisconsin Republican Party’s fake certificates apparently weren’t properly delivered, however, so the Trump Campaign arranged to fly them to Washington just before the joint session on January 6th, and try to deliver them to the Vice President via Senator Ron Johnson and Representative Mike Kelly’s offices.
      <Citation number={234} />
      Both Johnson and Kelly’s offices attempted to do so, but Vice President Pence’s aide refused the delivery.
      <Citation number={235} />
    </Typography>

    <Typography className="indent">
      Despite pressure from President Trump, Vice President Pence and the Senate parliamentarian refused to recognize or count the unofficial fake electoral votes. Greg Jacob testified that he advised Vice President Pence on January 2nd that “none of the slates that had been sent in would qualify as an alternate slate” under the law and that the Senate Parliamentarian “was in agreement” with this conclusion.
      <Citation number={236} />
    </Typography>

    <StarDivider>***</StarDivider>

    <Typography className="indent">
      In addition to this plan to create and transmit fake electoral slates, Donald Trump was also personally and substantially involved in multiple efforts to pressure State election officials and State legislatures to alter official lawful election results. As U.S. District Judge Carter stated in his June 7, 2022, opinion:
    </Typography>

    <Blockquote>
      <Typography>
        Dr. Eastman’s actions in these few weeks [in December 2020] indicate that his and President Trump’s pressure campaign to stop the electoral count did not end with Vice President Pence—it targeted every tier of federal and state elected officials. Convincing state legislatures to certify competing electors was essential to stop the count and ensure President Trump’s reelection.
        <Citation number={237} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Judge Carter also explained that “Dr. Eastman and President Trump’s plan to disrupt the Joint Session was fully formed and actionable as early as December 7, 2020.”
      <Citation number={238} />
    </Typography>

    <Typography className="indent">
      Chapter 2 of this report provides substantial detail on many of President Trump’s specific efforts to apply pressure to State officials and legislators. We provide a few examples here:
    </Typography>

    <Typography className="indent">
      During a January 2, 2021, call, President Trump pressured Georgia’s Republican Secretary of State Brad Raffensperger to “find 11,780 votes.” During that call, President Trump asserted conspiracy theories about the election that Department of Justice officials had already debunked. President Trump also made a thinly veiled threat to Raffensperger and his attorney about his failure to respond to President Trump’s demands: “That’s a criminal, that’s a criminal offense... That’s a big risk to you and to Ryan, your lawyer... I’m notifying you that you’re letting it happen.”
      <Citation number={239} />
    </Typography>

    <Blockquote>
      <Typography>
        Judge Carter drew these conclusions:
      </Typography>
      <Typography>
        Mr. Raffensperger debunked the President’s allegations “point by point” and explained that “the data you have is wrong;” however, President Trump still told him, “I just want to find 11,780 votes.”
        <Citation number={240} />
      </Typography>

      <StarDivider>***</StarDivider>

      <Typography>
        President Trump’s repeated pleas for Georgia Secretary of State Raffensperger clearly demonstrate that his justification was not to investigate fraud, but to win the election.... Taken together, this evidence demonstrates that President Trump likely knew the electoral count plan had no factual justification. The plan not only lacked factual basis but also legal justification.
        <Citation number={241} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      That call to Raffensperger came on the heels of President Trump’s repeated attacks on Raffensperger, election workers, and other public servants about President Trump’s loss in the election. A month earlier, the Georgia Secretary of State’s Chief Operating Officer, Gabriel Sterling, had given this explicit public warning to President Trump and his team, a warning that the Select Committee has determined President Trump apparently saw and disregarded:
      <Citation number={242} />
    </Typography>

    <Blockquote>
      <Typography>
        [I]t has all gone too far. All of it....
      </Typography>
      <Typography>
        A 20-something tech in Gwinnett County today has death threats and a noose put out, saying he should be hung for treason because he was transferring a report on batches from an EMS to a county computer so he could read it.
      </Typography>
      <Typography>
        It has to stop.
      </Typography>
      <Typography>
        Mr. President, you have not condemned these actions or this language. Senators, you have not condemned this language or these actions. This has to stop. We need you to step up. And if you’re going to take a position of leadership, show some.
      </Typography>
      <Typography>
        My boss, Secretary Raffensperger—his address is out there. They have people doing caravans in front of their house, they’ve had people come onto their property. Tricia, his wife of 40 years, is getting sexualized threats through her cellphone.
      </Typography>
      <Typography>
        It has to stop.
      </Typography>
      <Typography>
        This is elections, this is the backbone of democracy, and all of you who have not said a damn word are complicit in this. It’s too much....
      </Typography>
      <Typography>
        What you don’t have the ability to do—and you need to step up and say this—is stop inspiring people to commit potential acts of violence. Someone’s going to get hurt. Someone’s going to get shot. Someone’s going to get killed.
        <Citation number={243} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The stark warning was entirely appropriate, and prescient. In addition to the examples Sterling identified, President Trump and his team were also fixated on Georgia election workers Ruby Freeman and Wandrea “Shaye” Moss. He and Giuliani mentioned Freeman repeatedly in meetings with State legislators, at public rallies, and in the January 2nd call with Raffensperger. Referring to a video clip, Giuliani even accused Freeman and Moss of trading USB drives to affect votes “as if they [were] vials of heroin or cocaine.”
      <Citation number={244} />
      This was completely bogus: it was not a USB drive; it was a ginger mint.
      <Citation number={245} />
    </Typography>

    <Typography className="indent">
      After their contact information was published, Trump supporters sent hundreds of threats to the women and even showed up at Freeman’s home.
      <Citation number={246} />
      As Freeman testified to the Select Committee, Trump and his followers’ conduct had a profound impact on her life. She left her home based on advice from the FBI, and wouldn’t move back for months.
      <Citation number={247} />
      And she explained, “I’ve lost my sense of security—all because a group of people, starting with Number 45 [Donald Trump] and his ally Rudy Giuliani, decided to scapegoat me and my daughter Shaye to push their own lies about how the Presidential election was stolen.”
      <Citation number={248} />
      The treatment of Freeman and Moss was callous, inhumane, and inexcusable. Rudolph Giuliani and others with responsibility should be held accountable.
    </Typography>

    <Image
      src="/images/image-015.jpg"
      alt="Gabriel Sterling at a press conference on November 6, 2020 in Atlanta, Georgia."
      caption="Gabriel Sterling at a press conference on November 6, 2020 in Atlanta, Georgia."
      credit="(Photo by Jessica McGowan/Getty Images)"
    />

    <Typography className="indent">
      In Arizona, a primary target of President Trump’s pressure, and ire, was House Speaker Russell “Rusty” Bowers, a longtime Republican who had served 17 years in the State legislature. Throughout November and December, Bowers spoke to President Trump, Giuliani, and members of Giuliani’s legal team, in person or on the phone. During these calls, President Trump and others alleged that the results in Arizona were affected by fraud and asked that Bowers consider replacing Presidential electors for Biden with electors for President Trump.
      <Citation number={249} />
      Bowers demanded proof for the claims of fraud, but never got it. At one point, after Bowers pressed Giuliani on the claims of fraud, Giuliani responded, “we’ve got lots of theories, we just don’t have the evidence.”
      <Citation number={250} />
      Bowers explained to Giuliani: “You are asking me do something against my oath, and I will not break my oath.”
      <Citation number={251} />
    </Typography>

    <Typography className="indent">
      President Trump and his supporters’ intimidation tactics affected Bowers, too. Bowers’s personal cell phone and home address were doxed,
      <Citation number={252} />
      leading demonstrators to show up at his home and shout insults until police arrived. One protestor who showed up at his home was armed and believed to be a member of an extremist militia.
      <Citation number={253} />
      Another hired a truck with a defamatory and profane allegation that Bowers, a deeply religious man, was a pedophile, and drove it through Bowers’s neighborhood.
      <Citation number={254} />
      This, again, is the conduct of thugs and criminals, each of whom should be held accountable.
    </Typography>

    <Typography className="indent">
      In Michigan, President Trump focused on Republican Senate Majority Leader Mike Shirkey and Republican House Speaker Lee Chatfield. He invited them to the White House for a November 20, 2020, meeting during which President Trump and Giuliani, who joined by phone, went through a “litany” of false allegations about supposed fraud in Michigan’s election.
      <Citation number={255} />
      Chatfield recalled President Trump’s more generic directive for the group to “have some backbone and do the right thing,” which he understood to mean overturning the election by naming Michigan’s Electoral College electors for President Trump.
      <Citation number={256} />
      Shirkey told President Trump that he wouldn’t do anything that would violate Michigan law,
      <Citation number={257} />
      and after the meeting ended, issued a joint statement with Chatfield: “We have not yet been made aware of any information that would change the outcome of the election in Michigan and as legislative leaders, we will follow the law and follow the normal process regarding Michigan’s electors, just as we have said throughout this election.”
      <Citation number={258} />
    </Typography>

    <Typography className="indent">
      When President Trump couldn’t convince Shirkey and Chatfield to change the outcome of the election in Michigan during that meeting or in calls after, he or his team maliciously tweeted out Shirkey’s personal cell phone number and a number for Chatfield that turned out to be wrong.
      <Citation number={259} />
      Shirkey received nearly 4,000 text messages after that, and another private citizen reported being inundated with calls and texts intended for Chatfield.
      <Citation number={260} />
    </Typography>

    <Typography className="indent">
      None of Donald Trump’s efforts ultimately succeeded in changing the official results in any State. That these efforts had failed was apparent to Donald Trump and his co-conspirators well before January 6th. By January 6th, there was no evidence at all that a majority of any State legislature would even attempt to change its electoral votes.
      <Citation number={261} />
    </Typography>

    <Typography className="indent">
      This past October, U.S. District Court Judge David Carter issued a further ruling relating to one of President Trump’s lawsuits in Georgia. Judge Carter applied the crime-fraud exception to attorney-client privilege again, and identified potential criminal activity related to a knowingly false representation by Donald Trump to a Federal court. He wrote:
    </Typography>

    <Blockquote>
      <Typography>
        The emails show that President Trump knew that the specific numbers of voter fraud were wrong but continued to tout those numbers, both in court and in public.
        <Citation number={262} />
      </Typography>
    </Blockquote>

    <Image
      src="/images/image-016.jpg"
      alt="Steven Engel, Jeffrey Rosen and Richard Donoghue at a Select Committee hearing on June 23, 2022."
      caption="Steven Engel, Jeffrey Rosen and Richard Donoghue at a Select Committee hearing on June 23, 2022."
      credit="(Photo by House Creative Services)"
    />

    <Typography className="indent">
      As John Eastman wrote in an email on December 31, 2020, President Trump was “made aware that some of the allegations (and evidence proffered by the experts)” in a verified State court complaint was “inaccurate.”
      <Citation number={263} />
      Dr. Eastman noted that “with that knowledge” President Trump could not accurately verify a Federal court complaint that incorporated by reference the “inaccurate” State court complaint: “I have no doubt that an aggressive DA or US Atty someplace will go after both the President and his lawyers once all the dust settles on this.”
      <Citation number={264} />
      Despite this specific warning, “President Trump and his attorneys ultimately filed the complaint with the same inaccurate numbers without rectifying, clarifying, or otherwise changing them.”
      <Citation number={265} />
      And President Trump personally “signed a verification swearing under oath that the incorporated, inaccurate numbers ‘are true and correct’ or ‘believed to be true and correct’ to the best of his knowledge and belief.”
      <Citation number={266} />
      The numbers were not correct, and President Trump and his legal team knew it.
    </Typography>
  </Page>
);
