import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet5'));

export const Chapter32 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      In early December, the highest levels of the Trump Campaign took note of Chesebro’s fake elector plan and began to operationalize it. On December 6th, White House Chief of Staff Mark Meadows forwarded a copy of Chesebro’s November 18, 2020, memo to Trump Campaign Senior Advisor Jason Miller writing, “Let’s have a discussion about this tomorrow.”
      <Citation number={25} />
      Miller replied that he just engaged with reporters on the subject, to which Meadows wrote: “If you are on it then never mind the meeting. <i>We just need to have someone coordinating the electors for states</i>.”
      <Citation number={26} />
      Miller clarified that he had only been “working the PR angle” and they should still meet, to which Meadows answered: “Got it.”
      <Citation number={27} />
      Later that week, Miller sent Meadows a spreadsheet that the Trump Campaign had compiled.
      <Citation number={28} />
      It listed contact information for nearly all of the 79 GOP nominees to the electoral college on the November ballot for Arizona, Georgia, Michigan, Nevada, Pennsylvania, and Wisconsin.
      <Citation number={29} />
      And on December 8th, Meadows received a text message from a former State legislator in Louisiana recommending that the proposed “Trump electors from AR [<i>sic</i>] MI GA PA WI NV all meet next Monday at their state capitols[,] [c]all themselves to order, elect officers, and cast their votes for the President.... Then they certify their votes and transmit that certificate to Washington.”
      <Citation number={30} />
      Meadows replied: “We are.”
      <Citation number={31} />
    </Typography>

    <Typography className="indent">
      Cassidy Hutchinson, a Special Assistant to the President and an assistant to Chief of Staff Mark Meadows, confirmed Meadows’s significant involvement in the plan. Hutchinson told the Select Committee that Meadows followed the progress of the fake elector effort closely and that she “remember[ed] him frequently having calls, meetings, and outreach with individuals and this just being a prominent topic of discussion in our office.” When asked how many of his calls or meetings it came up in, she estimated “[d]ozens.”
      <Citation number={32} />
    </Typography>

    <Typography className="indent">
      The evidence indicates that by December 7th or 8th, President Trump had decided to pursue the fake elector plan and was driving it. Trump Campaign Associate General Counsel Joshua Findlay was tasked by the campaign’s general counsel, Matthew Morgan, around December 7th or 8th with exploring the feasibility of assembling unrecognized slates of Trump electors in a handful of the States that President Trump had lost.
      <Citation number={33} />
      Findlay told the Select Committee “it was my understanding that the President made this decision....”
      <Citation number={34} />
      As recounted by Findlay, Morgan conveyed that the client—President Trump—directed the campaign lawyers to “look into electors in these potential litigation States[.]”
      <Citation number={35} />
    </Typography>

    <Typography className="indent">
      President Trump personally called RNC Chairwoman Ronna Romney McDaniel days before December 14th to enlist the RNC’s assistance in the scheme.
      <Citation number={36} />
      President Trump opened the call by introducing McDaniel to John Eastman, who described “the importance of the RNC helping the campaign to gather these contingent electors in case any of the legal challenges that were ongoing changed the results in any of the States.”
      <Citation number={37} />
      According to McDaniel, she called President Trump back soon after the call ended, letting him know that she agreed to his request and that some RNC staffers were already assisting.
      <Citation number={38} />
    </Typography>

    <Typography className="indent">
      On December 13th and 14th, President Trump worked with Rudolph Giuliani on the plan’s implementation. On the 13th, Miller texted some of his colleagues to check in about the fake elector meetings scheduled for the following day. He let them know that Giuliani had told him “POTUS was aware” that they would be filing litigation in four States just “to keep the effort going”—which the Select Committee believes was to create a pretext to claim that it was still possible for the fake electors to be authorized retroactively.
      <Citation number={39} />
      (In subsequent litigation, a Federal district court found that President Trump “filed certain lawsuits not to obtain legal relief, but to disrupt or delay the January 6th congressional proceedings through the courts.”
      <Citation number={40} />
      ) The next day, Miller sent an email asking whether they were going to issue a press release about electors, and he was told the “Mayor [is] going to discuss with POTUS.”
      <Citation number={41} />
    </Typography>
  </Page>
)
