import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet3'));

export const Chapter14 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
    	Consistent with the pre-election narrative planted by President Trump, within hours of polls closing, President Trump began pushing the claim that late-reported vote tallies were illegitimate.
    	<Citation number={40} />
    	Even though he had been reminded by his Campaign Manager that very day that a large number of mail-in ballots would not be counted for several hours or days,
    	<Citation number={41} />
    	President Trump claimed that Democrats were going to “find... ballots at four o’clock in the morning and add them to the list.”
    	<Citation number={42} />
    	He also suggested that Democrats were continuing to vote after the polls had closed.
    	<Citation number={43} />
    </Typography>

    <Typography className="indent">
      Indeed, this is exactly what Steve Bannon described when he said President Trump would “take advantage” of the Democrats’ “natural disadvantage” on election night.
      <Citation number={44} />
    </Typography>

    <Typography className="indent">
      In the ensuing days and weeks, President Trump often referred to “dumps” of votes that were injected into the counting process.
      <Citation number={45} />
      His supporters latched onto these false claims.
      <Citation number={46} />
      There were no “dumps” of votes—just tallies of absentee ballots as they were reported by jurisdictions throughout the country in a fully transparent process.
      <Citation number={47} />
      These batches of ballots included votes for both Trump and Biden. The late-reported votes favored the former Vice President, just as President Trump’s campaign advisors said they would, particularly in primarily Democratic cities.
      <Citation number={48} />
    </Typography>

    <Typography className="indent">
      Attorney General Bill Barr recognized immediately that the “Red Mirage” was the basis for President Trump’s erroneous claim of fraud. “[R]ight out of the box on election night, the President claimed that there was major fraud underway,” Barr said. “I mean, this happened, as far as I could tell, before there was actually any potential of looking at evidence.”
      <Citation number={49} />
      President Trump’s claim “seemed to be based on the dynamic that, at the end of the evening, a lot of Democratic votes came in which changed the vote counts in certain states, and that seemed to be the basis for this broad claim that there was major fraud.”
      <Citation number={50} />
    </Typography>

    <Typography className="indent">
      President Trump knew about the Red Mirage. He chose to lie about it repeatedly—even after being directly informed that his claims were false. This was often the case in the post-election period. The President consciously disregarded facts that did not support his Big Lie.
    </Typography>
  </Page>
)
