import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet9'));

export const Chapter712 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Even after President Trump finally told the rioters to go home, he and his lead attorney, Rudolph Giuliani, continued to seek to delay the joint session of Congress.
    </Typography>

    <Typography className="indent">
      Giuliani began frantically calling the White House line the very minute that the President’s video went up on Twitter.
      <Citation number={292} />
      Failing to get through, he called back, once every minute—4:17 p.m., 4:18 p.m., 4:19 p.m., 4:20 p.m.
      <Citation number={293} />
      He managed to get through, briefly, to Mark Meadows at 4:21 p.m., and then kept calling the White House line: at 4:22 p.m., three times on two different phones at 4:23 p.m., 4:24 p.m., and once more at 5:05 p.m.
      <Citation number={294} />
      He finally managed to speak with President Trump at 5:07 p.m., and the two spoke for almost 12 minutes.
      <Citation number={295} />
    </Typography>

    <Typography className="indent">
      After he spoke with President Trump, Giuliani’s phone calls went nearly without fail to Members of Congress: Senator Marsha Blackburn, and then Senator Mike Lee.
      <Citation number={296} />
      He made three calls to Senator Bill Hagerty, then two to Representative Jim Jordan.
      <Citation number={297} />
      He called Senator Lindsey Graham,
      <Citation number={298} />
      Senator Josh Hawley,
      <Citation number={299} />
      and Senator Ted Cruz.
      <Citation number={300} />
      Giuliani had two calls with Senator Dan Sullivan over the course of the evening.
      <Citation number={301} />
      There were another three calls to Representative Jordan, none of which connected.
      <Citation number={302} />
      After 8:06 p.m., when the joint session resumed, the calls to Members of Congress finally stopped.
      <Citation number={303} />
      Shortly afterward, at 8:39 p.m., Giuliani had one final call of 9 minutes with the President.
      <Citation number={304} />
    </Typography>

    <Typography className="indent">
      When asked about these calls during his deposition before the Select Committee, Giuliani initially refused to answer. Giuliani insisted his calls to Members of Congress—none of whom were his client—were all attorney-client privileged.
      <Citation number={305} />
      But Giuliani eventually relented.
    </Typography>

    <Typography className="indent">
      “I was probably calling to see any—if anything could be done,” he said. “About the vote—the vote.”
      <Citation number={306} />
    </Typography>

    <Typography className="indent">
      We know definitively what Giuliani was up to because he left a voice message for Senator Tuberville—inadvertently on Senator Lee’s phone— recording his request.
      <Citation number={307} />
      He wanted for “you, our Republican friends to try to just slow it down,” referring to the electoral count, and delay the joint session.
      <Citation number={308} />
      Here are his own words:
    </Typography>

    <Blockquote>
      <Typography>
       The only strategy we can follow is to object to numerous States and raise issues so that we get ourselves into tomorrow—ideally until the end of tomorrow. So if you could object to every State and, along with a congressman, get a hearing for every State, I know we would delay you a lot, but it would give us the opportunity to get the legislators who are very, very close to pulling their vote.
       <Citation number={309} />
      </Typography>
    </Blockquote>

    <Image
      src="/images/image-101.jpg"
      alt="Mike Pence reopens the joint session of Congress and resumes counting electoral votes."
      caption="Mike Pence reopens the joint session of Congress and resumes counting electoral votes."
      credit="(Photo by Will McNamee/Getty Images)"
    />

    <Typography className="indent">
      The President, too, was at home, but he remained focused on his goal. Between 6:54 p.m. and 11:23 p.m., he spoke with 13 people, some more than once.
      <Citation number={310} />
      Of the 13, six ignored or expressly refused to comply with Select Committee requests for their testimony.
      <Citation number={311} />
      Two agreed to appear but refused to answer questions about their phone calls with the President, citing executive privilege.
      <Citation number={312} />
      Two more refused to answer questions, claiming attorney-client privilege.
      <Citation number={313} />
    </Typography>

    <Typography className="indent">
      Of the 13, five were President Trump’s attorneys or lawyers who worked with him on efforts to reverse the outcome of the election. With one exception, each of these calls took place before 8:06 p.m., when Vice President Pence reopened the joint session of Congress and resumed counting the electoral votes.
      <Citation number={314} />
      The President spoke with White House Counsel Pat Cipollone for 7 minutes at 7:01 p.m.
      <Citation number={315} />
      He spoke with Kurt Olsen and Mark Martin, lawyers who both advised him on the Vice President’s role in the joint session:
      <Citation number={316} />
      He spoke with Martin for 9 minutes at 7:30 p.m., and Olsen twice, for 11 minutes at 7:17 p.m. and for another 10 minutes at 7:40 p.m.
      <Citation number={317} />
      He spoke with Cleta Mitchell, the lawyer leading his election challenges in Georgia, for 2 minutes at 7:53 p.m.
      <Citation number={318} />
      The President spoke with Herschmann for 5 minutes at 10:50 p.m.
      <Citation number={319} />
    </Typography>

    <Typography className="indent">
      Another five of the people who spoke with President Trump that night were employees or outside advisors who counseled him on communications issues. These calls, by contrast, predominantly took place after the joint session resumed.
      <Citation number={320} />
      He spoke with his communications director, Scavino, twice: for 7 minutes at 7:08 p.m. and for 15 minutes at 9:55 p.m.
      <Citation number={321} />
      He spoke with McEnany for 11 minutes at 9:42 p.m.
      <Citation number={322} />
      He took calls from Steve Bannon, for 7 minutes at 10:19 p.m., and Sean Hannity, for 8 minutes at 11:08 p.m.
      <Citation number={323} />
    </Typography>

    <Typography className="indent">
      At 9:23 p.m., President Trump spoke with Jason Miller, his Campaign Communications Director, for 18 minutes.
      <Citation number={324} />
    </Typography>

    <Typography className="indent">
      Of his own initiative, Miller had drafted a statement for the President assuring the nation that the transfer of power—despite the day’s events— would, indeed, take place.
      <Citation number={325} />
      On their call, the President pushed back on the phrasing.
    </Typography>

    <Typography className="indent">
      The President wanted the statement to promise a “peaceful transition” of power, rather than just an “orderly” one.
      <Citation number={326} />
    </Typography>

    <Typography className="indent">
      Miller rejected the change and told him why rather bluntly.
    </Typography>

    <Typography className="indent">
      “[T]hat ship’s kind of already sailed,” he said, “so we’re going to say ‘orderly transition.’”
      <Citation number={327} />
    </Typography>
  </Page>
)
