import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';
import { StarDivider } from '../../../components/StarDivider';
import { StyledListContainer } from '../../../components/StyledListContainer';

const Citation = lazy(() => import('../../Citations/CitationSheet2'));

export const DerelictionOfDuty = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Just after 1:00 p.m., Vice President Pence, serving as President of the Senate under Article I of the Constitution, gaveled the Congress into its Joint Session. President Trump was giving a speech at the Ellipse, which he concluded at 1:10 pm. For the next few hours, an attack on our Capitol occurred, perpetrated by Trump supporters many of whom were present at the Ellipse for President Trump’s speech. More than 140 Capitol and Metropolitan police were injured, some very seriously.
      <Citation number={455} />
      A perimeter security line of Metropolitan Police intended to secure the Capitol against intrusion broke in the face of thousands of armed rioters—more than 2,000 of whom gained access to the interior of the Capitol building.
      <Citation number={456} />
      A woman who attempted to forcibly enter the Chamber of the House of Representatives through a broken window while the House was in session was shot and killed by police guarding the chamber. Vice President Pence and his family were at risk, as were those Secret Service professionals protecting him. Congressional proceedings were halted, and legislators were rushed to secure locations.
    </Typography>

    <Typography className="indent">
      From the outset of the violence and for several hours that followed, people at the Capitol, people inside President Trump’s Administration, elected officials of both parties, members of President Trump’s family, and Fox News commentators sympathetic to President Trump all tried to contact him to urge him to do one singular thing—one thing that all of these people immediately understood was required: Instruct his supporters to stand down and disperse—to leave the Capitol.
    </Typography>

    <Typography className="indent">
      As the evidence overwhelmingly demonstrates, President Trump specifically and repeatedly refused to do so—for multiple hours—while the mayhem ensued. Chapter 8 of this report explains in meticulous detail the horrific nature of the violence taking place, that was directed at law enforcement officers at the Capitol and that put the lives of American lawmakers at risk. Yet in spite of this, President Trump watched the violence on television from a dining room adjacent to the Oval Office, calling Senators to urge them to help him delay the electoral count, but refusing to supply the specific help that everyone knew was unequivocally required. As this report shows, when Trump finally did make such a statement at 4:17 p.m.— after hours of violence—the statement immediately had the expected effect; the rioters began to disperse immediately and leave the Capitol.
      <Citation number={457} />
    </Typography>

    <Typography className="indent">
      To fully understand the President’s behavior during those hours—now commonly known as the “187 minutes”—it is important to understand the context in which it occurred. As outlined in this report, by the afternoon of January 6th, virtually all of President Trump’s efforts to overturn the outcome of the 2020 election had failed. Virtually all the lawsuits had already been lost. Vice President Mike Pence had refused Trump’s pressure to stop the count of certain electoral votes. State officials and legislators had refused to reverse the election outcomes in every State where Trump and his team applied pressure. The Justice Department’s investigations of alleged election fraud had all contradicted Trump’s allegations.
    </Typography>

    <Typography className="indent">
      The only factor working in Trump’s favor that might succeed in materially delaying the counting of electoral votes for President-elect Biden was the violent crowd at the Capitol. And for much of the afternoon of January 6th, it appeared that the crowd had accomplished that purpose. Congressional leaders were advised by Capitol Police at one or more points during the attack that it would likely take several days before the Capitol could safely be reopened.
      <Citation number={458} />
    </Typography>

    <Typography className="indent">
      By the time the President’s speech concluded, the lawlessness at the United States Capitol had already begun, but the situation was about to get much worse.
    </Typography>

    <Typography className="indent">
      By 1:25 p.m., President Trump was informed that the Capitol was under attack.
    </Typography>

    <Typography className="indent">
      Minutes after arriving back at the White House, the President ran into a member of the White House staff and asked if they had watched his speech on television. “Sir, they cut it off because they’re rioting down at the Capitol,” the employee said. The President asked what they meant by that. “[T]hey’re rioting down there at the Capitol,” the employee repeated. “Oh really?” the President asked. “All right, let’s go see.”
      <Citation number={459} />
      A photograph taken by the White House photographer—the last one permitted until later in the day—captures the moment the President was made aware of the violent uprising at the Capitol.
      <Citation number={460} />
    </Typography>

    <Typography className="indent">
      Not long thereafter, as thousands of Trump supporters from the Ellipse speech continued to arrive at the Capitol, the DC Metropolitan Police Department declared a riot at the Capitol at 1:49 p.m., the same time Capitol Police Chief Steven Sund informed the DC National Guard “that there was a dire emergency on Capitol Hill and requested the immediate assistance” of as many national guard troops as possible.
      <Citation number={461} />
    </Typography>

    <Typography className="indent">
      No photographs exist of the President for the remainder of the afternoon until after 4 p.m. President Trump appears to have instructed that the White House photographer was not to take any photographs.
      <Citation number={462} />
      The Select Committee also was unable to locate any official records of President Trump’s telephone calls that afternoon.
      <Citation number={463} />
      And the President’s official Daily Diary contains no information for this afternoon between the hours of 1:19 p.m. and 4:03 p.m., at the height of the worst attack on the seat of the United States Congress in over two centuries.
      <Citation number={464} />
    </Typography>

    <Typography className="indent">
      The Select Committee did, however, obtain records from non-official sources that contained data of some phone calls President Trump made that afternoon. Even though “he was placing lots of calls” that afternoon, according to his personal assistant,
      <Citation number={465} />
      the Select Committee was given no records of any calls from the President to security or law enforcement officials that afternoon, and that absence of data is consistent with testimony of witnesses who would have knowledge of any such calls, who said that he did not do so.
      <Citation number={466} />
      Based on testimony from President Trump’s close aides, we know that President Trump remained in the Dining Room adjacent to the Oval Office for the rest of the afternoon until after 4:03 p.m.
      <Citation number={467} />
    </Typography>

    <Typography className="indent">
      In fact, from cellular telephone records, it appears that at 1:39 p.m. and 2:03 p.m., after being informed of the riot at the Capitol, President Trump called his lawyer, Rudolph Giuliani. These calls lasted approximately four minutes and eight minutes, respectively.
      <Citation number={468} />
      And Press Secretary Kayleigh McEnany testified that President Trump also called a number of Senators.
      <Citation number={469} />
      The number or names of all such Members of Congress is unknown, although Senator Mike Lee (R–UT) received one such outgoing call from the President within the hour that followed.
      <Citation number={470} />
    </Typography>

    <Typography className="indent">
      At 1:49 p.m., just as the DC Metropolitan Police officially declared a riot and the Capitol Police were calling for help from the National Guard to address the crisis, President Trump sent a tweet with a link to a recording of his speech at the Ellipse.
      <Citation number={471} />
    </Typography>

    <Typography className="indent">
      At about that point, White House Counsel Pat Cipollone became aware of the Capitol riot. The Committee collected sworn testimony from several White House officials, each with similar accounts. The President’s White House Counsel Pat Cipollone testified that he raced downstairs, and went to the Oval Office Dining Room as soon as he learned about the violence at the Capitol—likely just around or just after 2 p.m. Cipollone knew immediately that the President had to deliver a message to the rioters—asking them to leave the Capitol.
    </Typography>

    <Typography className="indent">
      Here is how he described this series of events:
    </Typography>

    <Blockquote>
      <Typography>
        ...the first time I remember going downstairs was when people had breached the Capitol... But I went down with [Deputy White House Counsel] Pat [Philbin], and I remember we were both very upset about what was happening. And we both wanted, you know, action to be taken related to that ...But we went down to the Oval Office, we went through the Oval office, and we went to the back where the President was.... I think he was already in the dining room ...I can’t talk about conversations [with the President]. I think I was pretty clear there needed to be an immediate and forceful response, statement, public statement, that people need to leave the Capitol now.
        <Citation number={472} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Cipollone also left little doubt that virtually everyone among senior White House staff had the same view:
    </Typography>

    <Blockquote>
      <Typography>
        There were a lot of people in the White House that day... Senior people who, you know, felt the same way that I did and who were working very hard to achieve that result. There were—I think Ivanka was one of them. And Eric Herschmann was there, Pat Philbin was there, and a number of other people.... many people suggested it.... Many people felt the same way. I’m sure I had conversations with Mark [Meadows] about this during the course of the day and expressed my opinion very forcefully that this needs to be done.
        <Citation number={473} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Likewise, senior staff cooperated to produce a message for the President on a notecard, which read:
    </Typography>

    <Blockquote>
      <Typography>
        ANYONE WHO ENTERED THE CAPITOL <del>ILLEGALLY</del> WITHOUT PROPER AUTHORITY SHOULD LEAVE IMMEDIATELY.
        <Citation number={474} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The President declined to make the statement. Cipollone also made it clear that the advice they were giving to the President never changed throughout this three-hour period. Trump refused to do what was necessary.
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: [I]t sounds like you from the very onset of violence at the Capitol right around 2 o’clock were pushing for a strong statement that people should leave the Capitol. Is that right?
      </Typography>
      <Typography>
        <strong>Cipollone</strong>: I was, and others were as well.
        <Citation number={475} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Cassidy Hutchinson, who worked closely with Mark Meadows and sat directly outside his office, confirmed this account and described several additional details:
    </Typography>

    <Blockquote>
      <Typography>
        I see Pat Cipollone barreling down the hallway towards our office. And he rushed right in, looked at me, said, “Is Mark in his office?” And I said, “Yes.” And on a normal day he would’ve said, “Can I pop in,” or, “Is he talking to anyone,” or, “Is it an appropriate time for me to go chat with him,” and myself or Eliza would go let him in or tell him no. But after I had said yes, he just looked at me and started shaking his head and went over, opened Mark’s office door, stood there with the door propped open, and said something to the—Mark was still sitting on his phone. I remember, like, glancing in. He was still sitting on his phone.
      </Typography>
      <Typography>
        And I remember Pat saying to him something to the effect of, “The rioters have gotten to the Capitol, Mark. We need to go down and see the President now.” And Mark looked up at him and said, “He doesn't want to do anything, Pat.” And Pat said something to the effect of—and very clearly said this to Mark—something to the effect of, “Mark, something needs to be done, or people are going to die and the blood’s gonna be on your F’ing hands. This is getting out of control. I’m going down there.
        <Citation number={476} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The Select Committee believes that the entire White House senior staff was in favor of a Presidential statement specifically instructing the violent rioters to leave. But President Trump refused. White House Counsel Pat Cipollone answered certain questions from the Select Committee on this subject as follows:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Vice Chair Cheney</strong>: And when you talk about others on the staff thinking more should be done, or thinking that the President needed to tell people to go home, who would you put in that category?
      </Typography>
      <Typography>
        <strong>Cipollone</strong>: Well, I would put... Pat Philbin, Eric Herschmann. Overall, Mark Meadows, Ivanka. Once Jared got there, Jared. General Kellogg. I’m probably missing some, but those are—Kayleigh I think was there. But I don’t—Dan Scavino.
      </Typography>
      <Typography>
        <strong>Vice Chair Cheney</strong>: And who on the staff did not want people to leave the Capitol?”
      </Typography>
      <Typography>
        <strong>Cipollone</strong>: On the staff?
      </Typography>
      <Typography>
        <strong>Vice Chair Cheney</strong>: In the White House?
      </Typography>
      <Typography>
        <strong>Cipollone</strong>: I can’t think of anybody on that day who didn’t want people to get out of the Capitol once the—particularly once the violence started. No. I mean—
      </Typography>
      <Typography>
        <strong>Mr. Schiff</strong>: What about the President?
      </Typography>
      <Typography>
        <strong>Vice Chair Cheney</strong>: Yeah.
      </Typography>
      <Typography>
        ...
      </Typography>
      <Typography>
        [Consultation between Mr. Cipollone and his counsel.]
      </Typography>
      <Typography>
        <strong>Cipollone</strong>: Yeah. I can’t reveal communications. But obviously I think, you know—yeah.
        <Citation number={477} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The testimony of a White House employee with national security responsibilities also corroborated these facts. This employee testified about a conversation between Pat Cipollone and Eric Herschmann in which Herschmann indicated that the President did not want to do anything to halt the violence. That employee told the Select Committee that he overheard Herschmann saying something to the effect of “the President didn’t want anything done.”
      <Citation number={478} />
    </Typography>

    <Typography className="indent">
      Deputy Press Secretary Judd Deere also testified to the Select Committee that as soon as it was clear that the Capitol’s outer perimeter had been breached, he urged that the President make a statement telling the rioters to go home:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: And so what did you do at that point?
      </Typography>
      <Typography>
        <strong>Judd Deere</strong>: If I recall, I went back up to [Press Secretary] Kayleigh [McEnany]’s office and indicated that we now likely needed to say something.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: Okay. And why did you think it was necessary to say something?
      </Typography>
      <Typography>
        <strong>Deere</strong>: Well, I mean, it appears that individuals are storming the U.S. Capitol building. They also appear to be supporters of Donald Trump, who may have been in attendance at the rally. We’re going to need to say something.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: And did you have a view as to what should be said by the White House?
      </Typography>
      <Typography>
        <strong>Deere</strong>: If I recall, I told Kayleigh that I thought that we needed to encourage individuals to stop, to respect law enforcement, and to go home.... And it was—it was incumbent upon us to encourage those individuals, should they be supporters of ours, to stop.
        <Citation number={479} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Testimony from both Deputy Press Secretary Matthews and White House Counsel Cipollone indicated that it would have been easy, and nearly instantaneous, for Trump to make a public statement insisting that the crowd disperse. As Matthews explained, he could have done so in under a minute:
    </Typography>

    <Blockquote>
      <Typography>
       ...it would take probably less than 60 seconds from the Oval Office dining room over to the Press Briefing Room. And, for folks that might not know, the Briefing Room is the room that you see the White House Press Secretary do briefings from with the podium and the blue backdrop. And there is a camera that is on in there at all times. And so, if the President had wanted to make a statement and address the American people, he could have been on camera almost instantly.
        <Citation number={480} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Cipollone also shared that assessment:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: Would it have been possible at any moment for the President to walk down to the podium in the briefing room and talk to the nation at any time between when you first gave him that advice at 2 o’clock and 4:17 when the video statement went out? Would that have been possible?
      </Typography>
      <Typography>
        <strong>Cipollone</strong>: Would it have been possible?”
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: Yes.
      </Typography>
      <Typography>
        <strong>Cipollone</strong>: Yes, it would have been possible.
        <Citation number={481} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      At 2:13 p.m., rioters broke into the Capitol and flooded the building.
      <Citation number={482} />
    </Typography>

    <Typography className="indent">
      As the violence began to escalate, many Trump supporters and others outside the White House began urgently seeking his intervention. Mark Meadows’s phone was flooded with text messages. These are just some of them:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>2:32 p.m. from Fox News anchor Laura Ingraham</strong>: “Hey Mark, The president needs to tell people in the Capitol to go home.”
        <Citation number={483} />
      </Typography>
      <Typography>
        <strong>2:35 p.m. from Mick Mulvaney</strong>: “Mark: he needs to stop this, now. Can I do anything to help?”
        <Citation number={484} />
      </Typography>
      <Typography>
        <strong>2:46 p.m. from Rep. William Timmons (R–SC)</strong>: “The president needs to stop this ASAP
        <Citation number={485} />
      </Typography>
      <Typography>
        <strong>2:53 p.m. from Donald Trump, Jr.</strong>: “He’s got to condem [<i>sic</i>] this shit. Asap. The captiol [<i>sic</i>] police tweet is not enough.”
        <Citation number={486} />
      </Typography>
      <Typography>
        <strong>3:04 p.m. from Rep. Jeff Duncan (R–SC)</strong>: “POTUS needs to calm this shit down”
        <Citation number={487} />
      </Typography>
      <Typography>
        <strong>3:09 p.m. from former White House Chief of Staff Reince Priebus</strong>: “TELL THEM TO GO HOME !!!”
        <Citation number={488} />
      </Typography>
      <Typography>
        <strong>3:13 p.m. from Alyssa Farah Griffin</strong>: “Potus has to come out firmly and tell protestors to dissipate. Someone is going to get killed.”
        <Citation number={489} />
      </Typography>
      <Typography>
        <strong>3:15 p.m. from Rep. Chip Roy (R–TX)</strong>: “Fix this now.”
        <Citation number={490} />
      </Typography>
      <Typography>
        <strong>3:31 p.m. from Fox News anchor Sean Hannity</strong>: “Can he make a statement. I saw the tweet. Ask people to peacefully leave the capital [<i>sic</i>]”
        <Citation number={491} />
      </Typography>
      <Typography>
        <strong>3:58 p.m. from Fox News anchor Brian Kilmeade</strong>: “Please get him on tv. Destroying every thing you guys have accomplished”
        <Citation number={492} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Others on Capitol Hill appeared in the media, or otherwise appeared via internet. Representative Mike Gallagher (R–WI) issued a video appealing directly to the President:
    </Typography>

    <Blockquote>
      <Typography>
        Mr. President, you have got to stop this. You are the only person who can call this off. Call it off. The election is over. Call it off!
        <Citation number={493} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Some Members of Congress sent texts to President Trump’s immediate staff or took to Twitter, where they knew the President spent time:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Sen. Bill Cassidy (R–LA) issued a tweet</strong>: @realDonaldTrump please appear on TV, condemn the violence and tell people to disband.
        <Citation number={494} />
      </Typography>
      <Typography>
        <strong>Rep. Jaime Herrera Beutler (R–WA) sent a text to Mark Meadows</strong>: We need to hear from the president. On TV. I hate that Biden jumped him on it.
        <Citation number={495} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Republican Leader Kevin McCarthy tried repeatedly to reach President Trump, and did at least once. He also reached out for help to multiple members of President Trump’s family, including Ivanka Trump and Jared Kushner.
      <Citation number={496} />
      Kushner characterized Leader McCarthy’s demeanor on the call as “scared”:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Kushner</strong>: I could hear in his voice that he really was nervous, and so, obviously, I took that seriously. And, you know, I didn’t know if I’d be able to have any impact, but I said, you know, it’s better to at least try. And so I—like I said, I turned the shower off, threw on a suit, and, you know, and rushed into the White House as quickly as I could.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: Yeah. What did he ask you to do? When you say have an impact, what is it specifically that he needed your help with?
      </Typography>
      <Typography>
        <strong>Kushner</strong>: I don't recall a specific ask, just anything you could do. Again, I got the sense that, you know, they were—they were—you know, they were scared.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: “They” meaning Leader McCarthy and people on the Hill because of the violence?
      </Typography>
      <Typography>
        <strong>Kushner</strong>: That he was scared, yes.
        <Citation number={497} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Kevin McCarthy told Fox News at 3:09 p.m. about his call with the President
      <Citation number={498} />
      and elaborated about its contents in a conversation with CBS News’s Norah O’Donnell at around 3:30 p.m.:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>O’Donnell</strong>: Have you spoken with the President and asked him to perhaps come to the Capitol and tell his supporters it’s time to leave?
      </Typography>
      <Typography>
        <strong>Leader McCarthy</strong>: I have spoken to the President. I asked him to talk to the nation and tell them to stop this....
      </Typography>

      <StarDivider>***</StarDivider>

      <Typography>
        <strong>O’Donnell</strong>: The President invited tens of thousands of people to quote unquote stop the steal. I don’t know if you heard his morethan-hour-long remarks or the remarks of his son, who was the wind-up. It was some heated stuff, Leader McCarthy. I just wonder whether someone is going to accurately call a spade a spade, and I am giving you the opportunity right now that your precious and beloved United States Capitol and our democracy is witnessing this. Call a spade a spade.
      </Typography>
      <Typography>
        <strong>Leader McCarthy</strong>: I was very clear with the President when I called him. This has to stop. And he has to, he’s gotta go to the American public and tell them to stop this.
      </Typography>

      <StarDivider>***</StarDivider>

      <Typography>
        <strong>O’Donnell</strong>: Leader McCarthy, the President of the United States has a briefing room steps from the Oval Office. It is, the cameras are hot 24/7, as you know. Why hasn’t he walked down and said that, now?
      </Typography>
      <Typography>
        <strong>Leader McCarthy</strong>: I conveyed to the President what I think is best to do, and I’m hopeful the President will do it.
        <Citation number={499} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The Committee has evidence from multiple sources regarding the content of Kevin McCarthy’s direct conversation with Donald Trump during the violence.
    </Typography>

    <Typography className="indent">
      Rep. Jaime Herrera Beutler (R–WA), to whom McCarthy spoke soon after, relayed more of the conversation between McCarthy and President Trump:
    </Typography>

    <Blockquote>
      <Typography>
        And he said [to President Trump], “You have got to get on TV. You’ve got to get on Twitter. You’ve got to call these people off.” You know what the President said to him? This is as it’s happening. He said, “Well Kevin, these aren’t my people. You know, these are Antifa. And Kevin responded and said, “No, they’re your people. They literally just came through my office windows and my staff are running for cover. I mean they’re running for their lives. You need to call them off.” And the President’s response to Kevin to me was chilling. He said, “Well Kevin, I guess they’re just more upset about the election, you know, theft than you are”.
        <Citation number={500} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Rep. Herrera Beutler’s account of the incident was also corroborated by former Acting White House Chief of Staff Mick Mulvaney, who testified that Leader McCarthy told him several days later that President Trump had said during their call: “Kevin, maybe these people are just more angry about this than you are. Maybe they’re more upset.”
      <Citation number={501} />
    </Typography>

    <Typography className="indent">
      Mulvaney was also trying to reach administration officials to urge President Trump to instruct his supporters to leave the Capitol.
      <Citation number={502} />
      As were many elected officials in both parties, including Nancy Pelosi and Chuck Schumer, and several Republican Members of Congress.
      <Citation number={503} />
    </Typography>

    <Typography className="indent">
      As already noted, Cipollone and others in the White House repeatedly urged President Trump to tell his supporters to leave the Capitol. Cipollone described his conversations with Meadows after they failed to convince President Trump to deliver the necessary message:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: Do you remember any discussion with Mark Meadows with respect to his view that the President didn’t want to do anything or was somehow resistant to wanting to say something along the lines that you suggested.
      </Typography>
      <Typography>
        <strong>Pat Cipollone</strong>: Not just—just to be clear, many people suggested it.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: Yeah.
      </Typography>
      <Typography>
        <strong>Cipollone</strong>: Not just me. Many people felt the same way. I’m sure I had conversations with Mark about this during the course of the day and expressed my opinion very forcefully that this needs to be done.
        <Citation number={504} />
      </Typography>

      <StarDivider>***</StarDivider>

      <Typography>
        <strong>Committee Staff</strong>: So your advice was tell people to leave the Capitol, and that took over 2 hours when there were subsequent statements made, tweets put forth, that in your view were insufficient. Did you continue, Mr. Cipollone, throughout the period of time up until 4:17, continue, you and others, to push for a stronger statement?
      </Typography>
      <Typography>
        <strong>Cipollone</strong>: Yes.
        <Citation number={505} />
      </Typography>

      <StarDivider>***</StarDivider>

      <Typography>
        <strong>Committee Staff</strong>:  ...at the onset of the violence when you first notice on television or wherever that rioters have actually breached the Capitol, did you have a conversation with Mark Meadows in which Meadows indicated he doesn’t want to do anything, “he” meaning the President?
      </Typography>
      <Typography>
        <strong>Cipollone</strong>: I don’t—I had a conversation I’m sure with Mark Meadows, I’m sure with other people, of what I thought should be done. Did Mark say that to me? I don’t have a recollection of him saying that to me, but he may have said something along the lines.
        <Citation number={506} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      At 2:16 p.m., security records indicate that the Vice President was “being pulled” to a safer location.
      <Citation number={507} />
    </Typography>

    <Typography className="indent">
      In an interview with the Select Committee, a White House Security Official on duty at the White House explained his observations as he listened to Secret Service communications and made contemporaneous entries into a security log. In particular, he explained an entry he made at 2:24 p.m.:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: Ok. That last entry on this page is: “Service at the Capitol does not sound good right now.”
      </Typography>
      <Typography>
        <strong>Official</strong>: Correct.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: What does that mean?
      </Typography>
      <Typography>
        <strong>Official</strong>: The members of the VP detail at this time were starting to fear for their own lives. There were a lot of—there was a lot of yelling, a lot of—I don’t know—a lot [of] very personal calls over the radio. So—it was disturbing. I don’t like talking about it, but there were calls to say good-bye to family members, so on and so forth. It was getting—for whatever the reason was on the ground, the VP detail thought that this was about to get very ugly.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: And did you hear that over the radio?
      </Typography>
      <Typography>
        <strong>Official</strong>: Correct.
      </Typography>

      <Typography>
        ...
      </Typography>

      <Typography>
        <strong>Committee Staff</strong>:  ... obviously, you’ve conveyed that’s disturbing, but what prompted you to put it into an entry as it states there, “Service at the Capitol—”
      </Typography>
      <Typography>
        <strong>Official</strong>: That they’re running out of options, and they’re getting nervous. It sounds like that we came very close to either Service having to use lethal options or worse. At that point, I don’t know. Is the VP compromised? Is the detail—like, I don’t know. Like, we didn’t have visibility, but it doesn’t—if they’re screaming and saying things, like, say good-bye to the family, like, the floor needs to know this is going to a whole another level soon.
        <Citation number={508} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Also at 2:24 p.m., knowing the riot was underway and that Vice President Pence was at the Capitol, President Trump sent this tweet:
    </Typography>

    <Blockquote>
      <Typography>
        Mike Pence didn’t have the courage to do what should have been done to protect our Country and our Constitution, giving States a chance to certify a corrected set of facts, not the fraudulent or inaccurate ones which they were asked to previously certify. USA demands the truth!
        <Citation number={509} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Evidence shows that the 2:24 p.m. tweet immediately precipitated further violence at the Capitol. Immediately after this tweet, the crowds both inside and outside of the Capitol building violently surged forward.
      <Citation number={510} />
      Outside the building, within ten minutes thousands of rioters overran the line on the west side of the Capitol that was being held by the Metropolitan Police Force’s Civil Disturbance Unit, the first time in history of the DC Metro Police that such a security line had ever been broken.
      <Citation number={511} />
    </Typography>

    <Typography className="indent">
      Virtually everyone on the White House staff the Select Committee interviewed condemned the 2:24 p.m. tweet in the strongest terms.
    </Typography>

    <Image
      src="/images/image-021.jpg"
      alt="Police officers attempt to clear rioters inside the Capitol building."
      caption="Police officers attempt to clear rioters inside the Capitol building."
      credit="(Photo by Brent Stirton/Getty Images)"
    />

    <Typography className="indent">
      Deputy National Security Adviser Matthew Pottinger told the Select Committee that the 2:24 p.m. tweet was so destructive that it convinced him to resign as soon as possible:
    </Typography>

    <Blockquote>
      <Typography>
        One of my aides handed me a sheet of paper that contained the tweet that you just read. I read it and was quite disturbed by it. I was disturbed and worried to see that the President was attacking Vice President Pence for doing his constitutional duty.
      </Typography>
      <Typography>
        So the tweet looked to me like the opposite of what we really needed at that moment, which was a de-escalation. And that is why I had said earlier that it looked like fuel being poured on the fire.
      </Typography>
      <Typography>
        So that was the moment that I decided that I was going to resign, that that would be my last day at the White House. I simply didn’t want to be associated with the events with the events that were unfolding at the Capitol.
        <Citation number={512} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Deputy Press Secretary Sarah Matthews had a similar reaction:
    </Typography>

    <Blockquote>
      <Typography>
        So it was obvious that the situation at the Capitol was violent and escalating quickly. And so I thought that the tweet about the Vice President was the last thing that was needed in that moment.
      </Typography>
      <Typography>
        And I remember thinking that this was going to be bad for him to tweet this, because it was essentially him giving the green light to these people, telling them that what they were doing at the steps of the Capitol and entering the Capitol was okay, that they were justified in their anger.
      </Typography>
      <Typography>
        And he shouldn’t have been doing that. He should have been telling these people to go home and to leave and to condemn the violence that we were seeing.
      </Typography>
      <Typography>
        And I am someone who has worked with him, you know, I worked on the campaign, traveled all around the country, going to countless rallies with him, and I have seen the impact that his words have on his supporters. They truly latch onto every word and every tweet that he says.
      </Typography>
      <Typography>
        And so, I think that in that moment for him to tweet out the message about Mike Pence, it was him pouring gasoline on the fire and making it much worse.
        <Citation number={513} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Deputy Press Secretary Judd Deere stated the following:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: What was your reaction when you saw that tweet?
      </Typography>
      <Typography>
        <strong>Deere</strong>: Extremely unhelpful.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: Why?
      </Typography>
      <Typography>
        <strong>Deere</strong>: It wasn’t the message that we needed at that time. It wasn’t going to—the scenes at the U.S. Capitol were only getting worse at that point. This was not going to help that.
        <Citation number={514} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      White House Counsel Pat Cipollone told the Select Committee, “I don’t remember when exactly I heard about that tweet, but my reaction to it is that’s a terrible tweet, and I disagreed with the sentiment. And I thought it was wrong.”
      <Citation number={515} />
    </Typography>

    <Typography className="indent">
      Likewise, Counselor to the President Hope Hicks texted a colleague that evening: “Attacking the VP? Wtf is wrong with him.”
      <Citation number={516} />
    </Typography>

    <Typography className="indent">
      At 2:26 p.m., Vice President Pence was again moved to a different location.
      <Citation number={517} />
    </Typography>

    <Typography className="indent">
      President Trump had the TV on in the dining room.
      <Citation number={518} />
      At 2:38 p.m., Fox News was showing video of the chaos and attack, with tear gas filling the air in the Capitol Rotunda. And a newscaster reported, “[T]his is a very dangerous situation.”
      <Citation number={519} />
      This is the context in which Trump sent the tweet.
    </Typography>

    <Typography className="indent">
      Testimony obtained by the Committee indicates that President Trump knew about the rioters’ anger at Vice President Pence and indicated something to the effect that the Vice President “deserves it.”
      <Citation number={520} />
      As Cassidy Hutchinson explained:
    </Typography>

    <Blockquote>
      <Typography>
        I remember Pat saying something to the effect of, “Mark, we need to do something more. They’re literally calling for the Vice President to be f’ing hung.” And Mark had responded something to the effect of, “You heard him, Pat. He thinks Mike deserves it. He doesn’t think they're doing anything wrong.” To which Pat said something, “[t]his is f’ing crazy, we need to be doing something more,” briefly stepped into Mark’s office, and when Mark had said something—when Mark had said something to the effect of, “He doesn’t think they’re doing anything wrong,” knowing what I had heard briefly in the dining room coupled with Pat discussing the hanging Mike Pence chants in the lobby of our office and then Mark’s response, I understood “they’re” to be the rioters in the Capitol that were chanting for the Vice President to be hung.
        <Citation number={521} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Although White House Counsel Pat Cipollone was limited in what he would discuss because of privilege concerns, he stated the following:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: Do you remember any discussion at any point during the day about rioters at the Capitol chanting ‘hang Mike Pence?’
      </Typography>
      <Typography>
        <strong>Cipollone</strong>: Yes. I remember—I remember hearing that—about that. Yes.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: Yeah. And—
      </Typography>
      <Typography>
        <strong>Cipollone</strong>: I don’t know if I observed that myself on TV. I don’t remember.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: I'm just curious, I understand the privilege line you’ve drawn, but do you remember what you can share with us about the discussion about those chants, the ‘hang Mike Pence’ chants?
      </Typography>
      <Typography>
        <strong>Cipollone</strong>: I could tell you my view of that.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: Yeah. Please.
      </Typography>
      <Typography>
        <strong>Cipollone</strong>: My view of that is that is outrageous.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: Uh-huh.
      </Typography>
      <Typography>
        <strong>Cipollone</strong>: And for anyone to suggest such a thing as the Vice President of the United States, for people in that crowd to be chanting that I thought was terrible. I thought it was outrageous and wrong. And I expressed that very clearly to people.
        <Citation number={522} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Almost immediately after the 2:24 p.m. tweet, Eric Herschmann went upstairs in the West Wing to try to enlist Ivanka Trump’s assistance to persuade her father to do the right thing.
      <Citation number={523} />
      Ivanka rushed down to the Oval Office dining room. Although no one could convince President Trump to call for the violent rioters to leave the Capitol, Ivanka persuaded President Trump that a tweet could be issued to discourage violence against the police.
    </Typography>

    <Typography className="indent">
      At 2:38 p.m., President Trump sent this tweet:
    </Typography>

    <Blockquote>
      <Typography>
        “Please support our Capitol Police and Law Enforcement. They are truly on the side of our Country. Stay peaceful!”
        <Citation number={524} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      While some in the meeting invoked executive privilege, or failed to recall the specifics, others told us what happened at that point. Sarah Matthews, the White House Deputy Press Secretary, had urged her boss, Kayleigh McEnany, to have the President make a stronger statement. But she informed us that President Trump resisted using the word “peaceful” in his message:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: Ms. Matthews, Ms. McEnany told us she came right back to the press office after meeting with the President about this particular tweet. What did she tell you about what happened in that dining room?
      </Typography>
      <Typography>
        <strong>Sarah Matthews</strong>: When she got back, she told me that a tweet had been sent out. And I told her that I thought the tweet did not go far enough, that I thought there needed to be a call to action and he needed to condemn the violence. And we were in a room full of people, but people weren’t paying attention. And so, she looked directly at me and in a hushed tone shared with me that the President did not want to include any sort of mention of peace in that tweet and that it took some convincing on their part, those who were in the room. And she said that there was a back and forth going over different phrases to find something that he was comfortable with. And it wasn’t until Ivanka Trump suggested the phrase ‘stay peaceful’ that he finally agreed to include it.
        <Citation number={525} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      At 3:13 p.m., President Trump sent another tweet, but again declined to tell people to go home:
    </Typography>

    <Blockquote>
      <Typography>
        “I am asking for everyone at the U.S. Capitol to remain peaceful. No violence! Remember, WE are the Party of Law & Order—respect the Law and our great men and women in Blue. Thank you!”
        <Citation number={526} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Almost everyone, including staff in the White House also found the President’s 2:38 p.m. and 3:13 p.m. tweets to be insufficient because they did not instruct the rioters to leave the Capitol. As mentioned, President Trump’s son, Donald Trump Jr., texted Meadows:
    </Typography>

    <Blockquote>
      <Typography>
        He’s got to condem [<i>sic</i>] this shit. Asap. The captiol [<i>sic</i>] police tweet is not enough.
        <Citation number={527} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Sean Hannity also texted Mark Meadows:
    </Typography>

    <Blockquote>
      <Typography>
        Can he make a statement. I saw the tweet. Ask people to peacefully leave the capital [<i>sic</i>].
        <Citation number={528} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      None of these efforts resulted in President Trump immediately issuing the message that was needed. White House staff had these comments:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Pottinger</strong>: Yeah. It was insufficient. I think what—you could count me among those who was hoping to see an unequivocal strong statement clearing out the Capitol, telling people to stand down, leave, go home. I think that’s what we were hoping for.
        <Citation number={529} />
      </Typography>
      <Typography>
        ...
      </Typography>
      <Typography>
        <strong>Matthews</strong>: Yeah. So a conversation started in the press office after the President sent out those two tweets that I deemed were insufficient.... I thought that we should condemn the violence and condemn it unequivocally. And I thought that he needed to include a call to action and to tell these people to go home.
        <Citation number={530} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      And they were right. Evidence showed that neither of these tweets had any appreciable impact on the violent rioters. Unlike the video-message tweet that did not come until 4:17 finally instructing rioters to leave, neither the 2:38 nor the 3:13 tweets made any difference.
    </Typography>

    <Typography className="indent">
      At some point after 3:05 p.m. that afternoon, President Trump’s Chief of Staff—and President Trump himself—were informed that someone had been shot.
      <Citation number={531} />
      That person was Ashli Babbitt, who was fatally shot at 2:44 p.m. as she and other rioters tried to gain access to the House chamber.
      <Citation number={532} />
      There is no indication that this affected the President’s state of mind that day, and we found no evidence that the President expressed any remorse that day.
    </Typography>

    <Typography className="indent">
      Meanwhile, leaders in Congress—including Speaker Pelosi, Senator Schumer, Senator McConnell—and the Vice President, were taking action. They called the Secretary of Defense, the Attorney General, governors and officials in Virginia, Maryland, and the District of Columbia, begging for assistance.
      <Citation number={533} />
    </Typography>

    <Typography className="indent">
      President-elect Biden also broadcast a video calling on President Trump to take action:
    </Typography>

    <Blockquote>
      <Typography>
        I call on President Trump to go on national television now to fulfill his oath and defend the Constitution and demand an end to this siege.
        <Citation number={534} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      President Trump could have done this, of course, anytime after he learned of the violence at the Capitol. At 4:17 p.m., 187 minutes after finishing his speech (and even longer after the attack began), President Trump finally broadcast a video message in which he asked those attacking the Capitol to leave:
    </Typography>

    <Blockquote>
      <Typography>
        I know your pain. I know you’re hurt. We had an election that was stolen from us. It was a landslide election, and everyone knows it, especially the other side, but you have to go home now. We have to have peace.
        <Citation number={535} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      President Trump’s Deputy Press Secretary, Sarah Matthews testified about her reaction to this video message:
    </Typography>

    <Blockquote>
      <Typography>
        [H]e told the people who we had just watched storm our nation’s Capitol with the intent on overthrowing our democracy, violently attack police officers, and chant heinous things like, “Hang Mike Pence,” “We love you. You’re very special.” As a spokesperson for him, I knew that I would be asked to defend that. And to me, his refusal to act and call off the mob that day and his refusal to condemn the violence was indefensible. And so, I knew that I would be resigning that evening.
        <Citation number={536} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      By this time, the National Guard and other additional law enforcement had begun to arrive in force and started to turn the tide of the violence. Many of those attackers in the Capitol saw or received word of President Trump’s 4:17 p.m. message, and they understood this message as an instruction to leave:
      <Citation number={537} />
    </Typography>

    <StyledListContainer>
      <ul>
        <li>
          <Typography>
            Stephen Ayres testified in front of the Select Committee that: “Well, we were there. As soon as that come out, everybody started talking about it, and it seemed like it started to disperse, you know, some of the crowd. Obviously, you know, once we got back to the hotel room, we seen that it was still going on, but it definitely dispersed a lot of the crowd.”
            <Citation number={538} />
          </Typography>
        </li>
        <li>
          <Typography>
            Jacob Chansley, also known as the QAnon-Shaman answered President Trump’s directive: “I’m here delivering the President’s message. Donald Trump has asked everybody to go home.” Another responded to Chansley: “That’s our order.”
            <Citation number={539} />
          </Typography>
        </li>
        <li>
          <Typography>
            Other unknown individuals also listened to President Trump’s message while outside the Capitol, and responded: “He says, go home. He says, go home.” And “Yeah. Here. He said to go home.”
            <Citation number={540} />
          </Typography>
        </li>
      </ul>
    </StyledListContainer>

    <Typography className="indent">
      At 6:01 p.m., President Trump sent his last tweet of the day, not condemning the violence, but instead attempting to justify it:
    </Typography>

    <Blockquote>
      <Typography>
        These are the things and events that happen when a sacred election landslide victory is so unceremoniously & viciously stripped away from great patriots who have been badly & unfairly treated for so long. Go home with love & in peace. Remember this day forever!
        <Citation number={541} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Staff in President Trump’s own White House and campaign had a strong reaction to this message:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Sarah Matthews</strong>: At that point I had already made the decision to resign and this tweet just further cemented my decision. I thought that January 6, 2021, was one of the darkest days in our Nation’s history and President Trump was treating it as a celebratory occasion with that tweet. And so, it just further cemented my decision to resign.
        <Citation number={542} />
      </Typography>
      <Typography>
        <strong>Tim Murtaugh</strong>: I don’t think it’s a patriotic act to attack the Capitol. But I have no idea how to characterize the people other than they trespassed, destroyed property, and assaulted the U.S. Capitol. I think calling them patriots is a, let’s say, a stretch, to say the least.... I don’t think it’s a patriotic act to attack the U.S. Capitol.
        <Citation number={543} />
      </Typography>
      <Typography>
        <strong>Pat Cipollone</strong>: [W]hat happened at the Capitol cannot be justified in any form or fashion. It was wrong, and it was tragic. And a lot—and it was a terrible day. It was a terrible day for this country.
        <Citation number={544} />
      </Typography>
      <Typography>
        <strong>Greg Jacob</strong>: I thought it was inappropriate.... To my mind, it was a day that should live in infamy.
        <Citation number={545} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      At 6:27 p.m., President Trump retired to his residence for the night. As he did, he had one final comment to an employee who accompanied him to the residence. The one takeaway that the President expressed in that moment, following a horrific afternoon of violence and the worst attack against the U.S. Capitol building in over two centuries, was this: “Mike Pence let me down.”
      <Citation number={546} />
    </Typography>

    <Typography className="indent">
      President Trump’s inner circle was still trying to delay the counting of electoral votes into the evening, even after the violence had been quelled. Rudolph Giuliani tried calling numerous Members of Congress in the hour before the joint session resumed, including Rep. Jim Jordan (R–OH) and Senators Marsha Blackburn (R–TN), Tommy Tuberville (R–AL), Bill Hagerty (R–TN), Lindsey Graham (R–SC), Josh Hawley (R–MO), and Ted Cruz (R–TX).
      <Citation number={547} />
      His voicemail intended for Senator Tuberville at 7:02 p.m. that evening eventually was made public:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Guiliani</strong>: Sen. Tuberville? Or I should say Coach Tuberville. This is Rudy Guiliani, the President’s lawyer. I’m calling you because I want to discuss with you how they’re trying to rush this hearing and how we need you, our Republican friends, to try to just slow it down so we can get these legislatures to get more information to you.
        <Citation number={548} />
      </Typography>
    </Blockquote>

    <Image
      src="/images/image-022.jpg"
      alt="A Trump sign with Vice President Mike Pence’s name removed."
      caption="A Trump sign with Vice President Mike Pence’s name removed."
      credit="(Photo by Michael Ciaglo/Getty Images)"
    />

    <Typography className="indent">
      Reflecting on President Trump’s conduct that day, Vice President Pence noted that President Trump “had made no effort to contact me in the midst of the rioting or any point afterward.”
      <Citation number={549} />
      He wrote that President Trump’s “reckless words had endangered my family and all those serving at the Capitol.”
      <Citation number={550} />
    </Typography>

    <Typography className="indent">
      President Trump did not contact a single top national security official during the day. Not at the Pentagon, nor at the Department of Homeland Security, the Department of Justice, the F.B.I., the Capitol Police Department, or the D.C. Mayor’s office.
      <Citation number={551} />
      As Vice President Pence has confirmed, President Trump didn’t even try to reach his own Vice President to make sure that Pence was safe.
      <Citation number={552} />
      President Trump did not order any of his staff to facilitate a law enforcement response of any sort.
      <Citation number={553} />
      His Chairman of the Joint Chiefs of Staff—who is by statute the primary military advisor to the President—had this reaction:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>General Milley</strong>: You know, you’re the Commander in Chief. You’ve got an assault going on on the Capitol of the United States of America. And there’s nothing? No call? Nothing? Zero?
        <Citation number={554} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      General Milley did, however, receive a call from President Trump’s Chief of Staff Mark Meadows that day. Here is how he described that call:
    </Typography>

    <Blockquote>
      <Typography>
        He said, “We have to kill the narrative that the Vice President is making all the decisions. We need to establish the narrative, you know, that the President is still in charge and that things are steady or stable,” or words to that effect. I immediately interpreted that as politics, politics, politics. Red flag for me, personally. No action. But I remember it distinctly. And I don’t do political narratives.
        <Citation number={555} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Some have suggested that President Trump gave an order to have 10,000 troops ready for January 6th.
      <Citation number={556} />
      The Select Committee found no evidence of this. In fact, President Trump’s Acting Secretary of Defense Christopher Miller directly refuted this when he testified under oath:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: To be crystal clear, there was no direct order from President Trump to put 10,000 troops to be on the ready for January 6th, correct?
      </Typography>
      <Typography>
        <strong>Miller</strong>: No. Yeah. That’s correct. There was no direct—there was no order from the President.
        <Citation number={557} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Later, on the evening of January 6th, President Trump’s former campaign manager, Brad Parscale, texted Katrina Pierson, one of President Trump’s rally organizers, that the events of the day were the result of a “sitting president asking for civil war” and that “This week I feel guilty for helping him win” now that “...a woman is dead.” Pierson answered: “You do realize this was going to happen.” Parscale replied: “Yeah. If I was Trump and knew my rhetoric killed someone.” “It wasn’t the rhetoric,” Pierson suggested. But Parscale insisted: “Yes it was.”
      <Citation number={558} />
    </Typography>
  </Page>
)
