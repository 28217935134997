import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet3'));

export const Chapter13 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      President Trump also paved the way for his false election-night declaration of victory by blanketing voters with a blizzard of lies and statements delegitimizing mail-in voting in the middle of a deadly pandemic and consistently questioning the security of ballots. President Trump used the president’s bully pulpit, including his heavily-trafficked Twitter feed, to tell one lie after another.
    </Typography>

    <Typography className="indent">
      The Select Committee found dozens of instances in which President Trump claimed that mail-in voting would produce a “rigged” election. Trump repeatedly denounced mail-in voting on Twitter, during interviews, and even during the presidential debate. Here is a small sample of President Trump’s attempts to delegitimize mail-in balloting.
    </Typography>

    <Typography className="indent">
      On April 7, 2020, President Trump claimed:
    </Typography>

    <Blockquote>
      <Typography>
        Mail ballots are a very dangerous thing for this country, because they’re cheaters. They go and collect them. They’re fraudulent in many cases.... These mailed ballots come in. The mailed ballots are corrupt, in my opinion. And they collect them, and they get people to go in and sign them. And then they—they’re forgeries in many cases. It’s a horrible thing.
				<Citation number={30} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The following day, April 8, President Trump tweeted:
    </Typography>

    <Blockquote>
      <Typography>
        Republicans should fight very hard when it comes to statewide mail-in voting. Democrats are clamoring for it. Tremendous potential for voter fraud, and for whatever reason, doesn’t work out well for Republicans. @foxandfriends
        <Citation number={31} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      On May 24, President Trump tweeted:
    </Typography>

    <Blockquote>
      <Typography>
        The United States cannot have all Mail In Ballots. It will be the greatest Rigged Election in history. People grab them from mailboxes, print thousands of forgeries and “force” people to sign. Also, forge names. Some absentee OK, when necessary. Trying to use Covid for this Scam!
        <Citation number={32} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      On September 17, President Trump falsely alleged that mail-in ballots were ripe for foreign interference:
    </Typography>

    <Blockquote>
      <Typography>
        @TrueTheVote There is a group of people (largely Radical Left Democrats) that want ELECTION MAYHEM. States must end this CRAZY mass sending of Ballots. Also, a GIFT to foreign interference into our election!!! Stop it now, before it is too late.
        <Citation number={33} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Before the election, as President Trump campaigned against mail-in voting, Bill Stepien sought an intercession. Along with House Minority Leader Kevin McCarthy, Stepien attempted to convince the President that mail-in voting was “not... a bad thing for his campaign.”
      <Citation number={34} />
      Theyargued that President Trump’s decision to discourage mail-in voting, while “urging [his] voters to vote only on election day leaves a lot to chance” and would fail to take advantage of a superior grassroots operation that could encourage Trump voters to return their ballots.
      <Citation number={35} />
      President Trump did not heed their warning. He continued to demonize mail-in voting. The Red Mirage was a key part of his “Big Lie.”
    </Typography>

    <Typography className="indent">
      Ominously, President Trump consistently refused to commit to accepting the outcome of the election. During an interview on Fox News in July, Chris Wallace asked: “Can you give a direct answer [if] you will accept the election?” President Trump responded: “I have to see. Look, you—I have to see. No, I’m not going to just say yes. I’m not going to say no, and I didn’t last time either.”
      <Citation number={36} />
    </Typography>

    <Typography className="indent">
      On September 23, 2020, a reporter asked President Trump if he would commit to a “peaceful transferal of power after the election.” The President refused,saying,“we’re going to have to see what happens.”
      <Citation number={37} />
      ThePresident claimed, “the ballots are disaster,” adding that if he could “get rid of the ballots... we’ll have a very peaceful—there won’t be a transfer, frankly; there’ll be a continuation.”
      <Citation number={38} />
      That is, according to President Trump, there would be a “continuation” of his presidency.
    </Typography>

    <Typography className="indent">
      The following day, September 24, another reporter followed up by asking if the election would be legitimate only if President Trump won. The President again suggested there was something suspect about mail-in ballots, adding that he was “not sure” the election could be an honest one.
      <Citation number={39} />
    </Typography>
  </Page>
)
