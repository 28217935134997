import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet6'));

export const Chapter47 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      On December 27, 2020, President Trump called Acting Attorney General Rosen once again. At some point during the lengthy call, Rosen asked that Acting Deputy Attorney General Donoghue be conferenced in.
      <Citation number={116} />
      According to Donoghue’s contemporaneous notes, Trump referenced three Republican politicians, all of whom had supported the President’s election lies and the “Stop the Steal” campaign.
      <Citation number={117} />
      One was Representative Scott Perry. Another was Doug Mastriano, a State senator from Pennsylvania who would later be on the grounds of the U.S. Capitol during the January 6th attack.
      <Citation number={118} />
      President Trump also referenced Representative Jim Jordan from Ohio, praising him as a “fighter.”
      <Citation number={119} />
      Representatives Perry and Jordan had often teamed up to spread lies about the election. The two spoke at a “Stop the Steal” rally in front of the Pennsylvania State capitol in Harrisburg, just days after the November election.
      <Citation number={120} />
      The pair also pressed their conspiratorial case during interviews with friendly media outlets.
      <Citation number={121} />
    </Typography>

    <Typography className="indent">
      President Trump made a “stream of allegations” during the December 27th call.
      <Citation number={122} />
      As reflected in his notes, Donoghue considered the call to be an “escalation of the earlier conversations,” with the President becoming more adamant that “we weren’t doing our job.”
      <Citation number={123} />
      President Trump trafficked in “conspiracy theories” he had heard from others, and Donoghue sought to “make it clear to the President these allegations were simply not true.”
      <Citation number={124} />
      Donoghue sought to “correct” President Trump “in a serial fashion as he moved from one theory to another.”
      <Citation number={125} />
    </Typography>

    <Typography className="indent">
      The President returned to the discredited ASOG report, which former Attorney General Barr had already dismissed as complete nonsense. ASOG had claimed—based on no evidence—that the Dominion voting machines in Antrim County, Michigan had suffered from a 68 percent error rate. As noted above and in Chapter 1, that was not close to being true.
    </Typography>

    <Typography className="indent">
      Bipartisan election officials in Antrim County completed a hand recount of all machine-processed ballots on December 17, 2020, which should have ended the lies about Dominion’s voting machines.
      <Citation number={126} />
      The net difference between the machine count and the hand recount was only 12 out of 15,718 total votes.
      <Citation number={127} />
      The machines counted just one vote more for former Vice President Biden than was tallied during the hand recount.
      <Citation number={128} />
      Donoghue informed the President that he “cannot and should not be relying on” ASOG’s claim, because it was “simply not true.”
      <Citation number={129} />
      This did not stop the President from later repeating the debunked allegation multiple times, including during his January 6th speech at the Ellipse.
      <Citation number={130} />
    </Typography>

    <Typography className="indent">
      Acting Deputy Attorney General Donoghue debunked a “series” of other conspiracy theories offered by President Trump during the December 27th call as well. One story involved a truck driver “who claimed to have moved an entire tractor trailer of ballots from New York to Pennsylvania.”
      <Citation number={131} />
      There was no truth to the story. The FBI “interviewed witnesses at the front end and the back end of” the truck’s transit route, “looked at loading manifests,” questioned the truck driver, and concluded that there were no ballots in the truck.
      <Citation number={132} />
    </Typography>

    <Typography className="indent">
      President Trump then returned to the conspiracy theory about voting in Detroit. Former Attorney General Barr had already debunked the claim that a massive number of illegal votes had been dumped during the middle of the night, but the President would not let it go. President Trump alleged that someone “threw the poll watchers out,” and “you don’t even need to look at the illegal aliens voting—don’t need to. It’s so obvious.”
      <Citation number={133} />
      The President complained that the “FBI will always say there’s nothing there,” because while the Special Agents (“the line guys”) supported him, the Bureau’s leadership supposedly did not.
      <Citation number={134} />
      This was inconsistent with Donoghue’s view.
      <Citation number={135} />
      But President Trump complained that he had “made some bad decisions on leadership” at the FBI.
      <Citation number={136} />
    </Typography>

    <Typography className="indent">
      President Trump also “wanted to talk a great deal about Georgia, [and] the State Farm Arena video,” claiming it was “fraud staring you right in the face.”
      <Citation number={137} />
      President Trump smeared Ruby Freeman, a Georgia election worker who was merely doing her job, as a “Huckster” and an “Election scammer.”
      <Citation number={138} />
      President Trump said the “networks,” meaning the television networks, had “magnified the tape and saw them running them [ballots] through repeatedly.”
      <Citation number={139} />
      The President repeated the lie that Democrats had “[c]losed the facility and then came back with hidden ballots under the table.”
      <Citation number={140} />
      He suggested that both Rosen and Donoghue “go to Fulton County and do a signature verification.” They would “see how illegal it is” and “find tens of thousands” of illegal ballots.
      <Citation number={141} />
    </Typography>

    <Typography className="indent">
      President Trump “kept fixating” on the supposed suitcase in the video.
      <Citation number={142} />
      But Acting Deputy Attorney General Donoghue debunked the President’s obsession. “There is no suitcase,” Donoghue made clear.
      <Citation number={143} />
      Donoghue explained that the DOJ had looked at the video and interviewed multiple witnesses. The “suitcase” was an official lock box filled with genuine votes.
      <Citation number={144} />
      And election workers simply did not scan ballots for former Vice President Biden multiple times.
      <Citation number={145} />
      All of this was recorded by security cameras.
      <Citation number={146} />
    </Typography>

    <Typography className="indent">
      In response to what President Trump was saying during the conversation, Rosen and Donoghue tried to make clear that the claims the President made weren’t supported by the evidence. “You guys must not be following the internet the way I do,” the President remarked.
      <Citation number={147} />
      But President Trump was not finished peddling wild conspiracy theories.
    </Typography>

    <Typography className="indent">
      The President pushed the claim that Pennsylvania had reported 205,000 more votes than there were voters in the state.
      <Citation number={148} />
      “We’ll look at whether we have more ballots in Pennsylvania than registered voters,” Acting Attorney General Rosen replied, according to Donoghue. They “[s]hould be able to check that out quickly.”
      <Citation number={149} />
      But Rosen wanted President Trump to “understand that the DOJ can’t and won’t snap its fingers and change the outcome of the election. It doesn’t work that way.”
      <Citation number={150} />
    </Typography>

    <Typography className="indent">
      “I don’t expect you to do that,” President Trump responded. “Just say the election was corrupt and leave the rest to me and the Republican Congressmen.”
      <Citation number={151} />
    </Typography>

    <Typography className="indent">
      Donoghue explained this “is an exact quote from the President.”
      <Citation number={152} />
    </Typography>

    <Typography className="indent">
      “We have an obligation to tell people that this was an illegal, corrupt election,” President Trump told the DOJ team at another point in the call.
      <Citation number={153} />
      President Trump insisted this was DOJ’s “obligation,” even though Rosen and Donoghue kept telling him there was no evidence of fraud sufficient to overturn the outcome of the election. “We are doing our job,” Donoghue informed the President. “Much of the info you’re getting is false.”
      <Citation number={154} />
    </Typography>

    <Typography className="indent">
      The call on December 27th was contentious for additional reasons. President Trump did not want to accept that the Department of Justice was not an arm of his election campaign. He wanted to know why the Department did not assist in his campaign’s civil suits against States. There was a simple answer: There was no evidence to support the campaign’s claims of fraud.
      <Citation number={155} />
    </Typography>

    <Typography className="indent">
      Donoghue and Rosen also “tried to explain to the President on this occasion and on several other occasions that the Justice Department has a very important, very specific, but very limited role in these elections.”
      <Citation number={156} />
      The States “run their elections” and DOJ is not “quality control for the States.”
      <Citation number={157} />
      DOJ has “a mission that relates to criminal conduct in relation to federal elections” and also has “related civil rights responsibilities.”
      <Citation number={158} />
      But DOJ cannot simply intervene to alter the outcome of an election or support a civil suit.
      <Citation number={159} />
    </Typography>

    <Typography className="indent">
      When President Trump made these demands on December 27th, it was already crystal clear that the Department of Justice had found no evidence of systemic fraud.
      <Citation number={160} />
      The Department simply had no reason to assert that the 2020 Presidential contest was “an illegal corrupt election.”
      <Citation number={161} />
    </Typography>

    <Typography className="indent">
      “People tell me Jeff Clark is great” and that “I should put him in,” President Trump said on the call. “People want me to replace the DOJ leadership.”
      <Citation number={162} />
      Donoghue responded “[S]ir, that’s fine, you should have the leadership you want, but understand, changing the leadership in the Department won’t change anything.”
      <Citation number={163} />
    </Typography>

    <Typography className="indent">
      The President did not really care what facts had been uncovered by the Department of Justice. President Trump just wanted the Department to say the election was corrupt, so he and the Republican Congressmen could exploit the statement in the days to come, including on January 6th. And when Rosen and Donoghue resisted the President’s entreaties, he openly mused about replacing Rosen with someone who would do the President’s bidding.
    </Typography>
  </Page>
)
