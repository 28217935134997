import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet2'));

export const OtherConspiracyStatutes = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Depending on evidence developed by the Department of Justice, the President’s actions with the knowledge of the risk of violence could also constitute a violation of 18 U.S.C. § 372 and § 2384, both of which require proof of a conspiracy. Section 372 prohibits a conspiracy between two or more persons “to prevent, by force, intimidation, or threat, any person from accepting or holding any office, trust, or place of confidence under the United States, or from discharging any duties thereof, or to induce by like means any officer of the United States to leave the place, where his duties as an officer are required to be performed, or to injure him in the discharge of his official duties.”
      <Citation number={649} />
      Oath Keepers Kelly Meggs, Kenneth Harrelson, and Jessica Watkins were convicted of violating 18 U.S.C. § 372 in connection with the January 6th attack on the Capitol.
      <Citation number={650} />
      The Committee believes that former Chief of Staff Mark Meadows (who refused to testify and was held in contempt of Congress) could have specific evidence relevant to such charges, as may witnesses who invoked their Fifth Amendment rights against self-incrimination before this Committee.
    </Typography>

    <Typography className="indent">
      Section 2384, the seditious conspiracy statute, prohibits “conspir[acy] to overthrow, put down, or to destroy by force the Government of the United States... or to oppose by force the authority thereof, or by force to prevent, hinder or delay the execution of any law of the United States....”
      <Citation number={651} />
      A jury has already determined beyond a reasonable doubt that a conspiracy existed under section 2384, as the leader of the Oath Keepers and at least one other individual were convicted of seditious conspiracy under section 2384 for their actions related to the attack on the Capitol.
      <Citation number={652} />
      A trial regarding a series of other “Proud Boy” defendants may also address similar issues.
      <Citation number={653} />
    </Typography>

    <Typography className="indent">
      The Department of Justice, through its investigative tools that exceed those of this Committee, may have evidence sufficient to prosecute President Trump under sections 372 and 2384. Accordingly, we believe sufficient evidence exists for a criminal referral of President Trump under these two statutes.
    </Typography>
  </Page>
)
