import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet9'));

export const Chapter72 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      From a tent backstage at the Ellipse, President Trump looked out at the crowd of approximately 53,000 supporters and became enraged. Just under half of those gathered—a sizeable stretch of about 25,000 people
      <Citation number={53} />
      — refused to walk through the magnetometers and be screened for weapons,
      <Citation number={54} />
      leaving the venue looking half-empty to the television audience at home.
    </Typography>

    <Typography className="indent">
      According to testimony received by the Committee, earlier that morning at the White House, the President was told that the onlookers were unwilling to pass through the magnetometers because they were armed. “We have enough space, sir. They don’t want to come in right now,” Deputy Chief of Staff Tony Ornato reportedly told President Trump. “They have weapons that they don’t want confiscated by the Secret Service.”
      <Citation number={55} />
    </Typography>

    <Typography className="indent">
      So, when President Trump got to the rally site and could see the crowd for himself, “[h]e was fucking furious,” as Cassidy Hutchinson later texted Ornato.
      <Citation number={56} />
      Hutchinson testified that just minutes before addressing the crowd, President Trump shouted to his advance team: “I don’t [fucking] care that they have weapons. They’re not here to hurt <i>me</i>. Take the [fucking] mags away. Let my people in. They can march to the Capitol from here. Take the [fucking] mags away.”
      <Citation number={57} />
    </Typography>

    <Typography className="indent">
      By noon, President Trump took to the stage at the Ellipse.
      <Citation number={58} /> The President wanted all of those in attendance, including those who hadn’t passed through the magnetometers, to come closer to the stage. “And I’d love to have if those tens of thousands of people would be allowed,” President Trump said. “But I’d love it if they could be allowed to come up here with us. Is that possible? Can you just let [them] come up, please?”
      <Citation number={59} />
    </Typography>

    <Typography className="indent">
      President Trump repeatedly made it clear to those around him in the days before January 6th that he wanted to march to the Capitol alongside his supporters. That is, President Trump wanted to join his supporters in what the Secret Service refers to as an “off-the-record” movement (OTR).
    </Typography>

    <Typography className="indent">
      While the President spoke, Hutchinson texted Ornato, “He also kept mentioning OTR to Capitol before he took the stage.”
      <Citation number={60} />
      Minutes before the President stepped out, Chief of Staff Mark Meadows assured the President he was working on it.
      <Citation number={61} />
    </Typography>

    <Typography className="indent">
      President Trump’s plan to march appeared once in an early draft of the script, then a later revision was made to add the word “building” after “Capitol,” making it clear exactly where the crowd should go.
      <Citation number={62} />
      And the President repeatedly told the crowd that he would join them.
    </Typography>

    <Typography className="indent">
      “[A]fter this, we’re going to walk down, and I’ll be there with you, we’re going to walk down, we’re going to walk down,” he said to the crowd. “[W]e’re going to walk down to the Capitol, and we’re going to cheer on our brave senators and congressmen and women, and we’re probably not going to be cheering so much for some of them.”
      <Citation number={63} />
    </Typography>

    <Typography className="indent">
      President Trump used the phrase scripted for him by his White House speechwriters, “peacefully and patriotically” once, about 20 minutes into his speech.
      <Citation number={64} />
      Then he spent the next 50-or-so minutes amping up his crowd with lies about the election, attacking his own Vice President and Republican Members of Congress, and exhorting the crowd to fight. “And we fight. We fight like hell” the President said to a crowd that had already spent the day chanting, “Fight for Trump! Fight for Trump!,” and that would keep up the chorus when storming the Capitol.
      <Citation number={65} />
    </Typography>

    <Typography className="indent">
      Finally, he told the crowd where to go to “take back our country”: “So we’re going to, we’re going to walk down Pennsylvania Avenue. I love Pennsylvania Avenue. And we’re going to the Capitol, and we’re going to try and give... we’re going to try and give our Republicans, the weak ones because the strong ones don’t need any of our help. We’re going to try and give them the kind of pride and boldness that they need to take back our country. So let’s walk down Pennsylvania Avenue.”
      <Citation number={66} />
    </Typography>

    <Typography className="indent">
      When the President announced his intentions from the microphone, people listened.
    </Typography>

    <Typography className="indent">
      House Republican Leader Representative. Kevin McCarthy called Hutchinson mid-speech:
      <Citation number={67} />
    </Typography>

    <Blockquote>
      <Typography>
        “Do you guys think you’re coming to my office[?]” he asked her.
        <Citation number={68} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      She assured him that they weren’t coming at all.
      <Citation number={69} />
    </Typography>

    <Blockquote>
      <Typography>
        “Figure it out. Don’t come up here,” he replied.
        <Citation number={70} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The announcement from the stage put the Secret Service on alert, prompting agents to designate over email a last-minute response team “to filter in with the crowds” on the President’s “walk/motorcade over” to the Capitol and establish an emergency plan “if things go south.”
      <Citation number={71} />
      White House security officials were monitoring the situation in real time, remarking that President Trump was “going to the Capitol” and that “they are finding the best route now.”
      <Citation number={72} />
      Nonetheless, these staffers were in “a state of shock,”
      <Citation number={73} />
      because they knew—particularly if the President joined—this would “no longer [be] a rally.”
      <Citation number={74} />
    </Typography>

    <Typography className="indent">
      “[W]e all knew... that this was going to move to something else if he physically walked to the Capitol,” an employee said. “I don’t know if you want to use the word ‘insurrection,’ ‘coup,’ whatever. We all knew that this would move from a normal democratic... public event into something else.”
      <Citation number={75} />
    </Typography>

    <Typography className="indent">
      But the logistics made the move all but impossible.
    </Typography>

    <Typography className="indent">
      It was complicated for the Secret Service to coordinate a presidential movement even on a normal day. But today was not a normal day. Tens of thousands of President Trump’s supporters had flooded into downtown DC in the days before the rally, and the Secret Service would have to account for that unpredictability. By the end of the President's speech, it was clear that the crowd at the Capitol was growing violent.
    </Typography>

    <Typography className="indent">
      At 1:19 p.m., a Secret Service agent wrote to Bobby Engel, the head of President Trump’s Secret Service detail: “FYSA... [Capitol Police] having serious challenges securing [the Capitol]. Nine priority breach attempts at this time. OTR to anywhere near there is not advisable. Give me a call when free. Front Office concerned about OTR to [the Capitol].”
      <Citation number={76} />
    </Typography>
  </Page>
)
