import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet9'));

export const Chapter76 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      At exactly 2:24 p.m., President Trump made his first public statement during the attack on the Capitol by tweet. It read nothing like the statement his advisors had envisioned. It read:
    </Typography>

    <Blockquote>
      <Typography>
        Mike Pence didn’t have the courage to do what should have been done to protect our Country and our Constitution, giving States a chance to certify a corrected set of facts, not the fraudulent or inaccurate ones which they were asked to previously certify. USA demands the truth!
        <Citation number={168} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Minutes later, Meadows and Cipollone returned from their talk with the President.
      <Citation number={169} />
      No statement was forthcoming.
    </Typography>

    <Typography className="indent">
      “Mark, we need to do something more. They’re literally calling for the Vice President to be [fucking] hung,” Hutchinson heard Cipollone say.
      <Citation number={170} />
    </Typography>

    <Typography className="indent">
      “You heard him, Pat,” Meadows replied. “He thinks Mike deserves it. He doesn’t think they’re doing anything wrong.”
      <Citation number={171} />
    </Typography>

    <Typography className="indent">
      “This is [fucking] crazy. We need to be doing something more,” Cipollone said.
      <Citation number={172} />
    </Typography>

    <Typography className="indent">
      Cipollone told the Select Committee that “there needed to be an immediate and forceful response, statement, public statement, that people need to leave the Capitol now.”
      <Citation number={173} />
      He said he was “pretty clear” about his view in the White House that day, and he made that view known as soon as he became aware of the unrest.
      <Citation number={174} />
      He would not comment on how the President responded, or on this conversation with Meadows, citing executive privilege.
      <Citation number={175} />
      He did indicate that everyone in the White House—except President Trump—agreed that people needed to leave the Capitol:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Vice Chair Cheney</strong>: And who on the staff did not want people to leave the Capitol?
      </Typography>
      <Typography>
        <strong>Mr. Cipollone</strong>: On the staff?
      </Typography>
      <Typography>
        <strong>Vice Chair Cheney</strong>: In the White House.
      </Typography>
      <Typography>
        <strong>Mr. Cipollone</strong>: I can’t think of anybody on that day who didn’t want people to get out of the Capitol once the—particularly once the violence started. No. I mean—
      </Typography>
      <Typography>
        <strong>Mr. Schiff</strong>: What about the President?
      </Typography>
      <Typography>
        <strong>Vice Chair Cheney</strong>: Yeah.
      </Typography>
      <Typography>
        <strong>Mr. Cipollone</strong>: Well, she said the staff. So I answered.
      </Typography>
      <Typography>
        <strong>Vice Chair Cheney</strong>: No. I said in the White House.
      </Typography>
      <Typography>
        <strong>Mr. Cipollone</strong>: Oh, I’m sorry. I apologize. I thought you said who else on the staff. [Pauses to confer with counsel] Yeah. I can’t reveal communications. But obviously I think, you know—yeah.
        <Citation number={176} />
      </Typography>
    </Blockquote>

    <Image
      src="/images/image-095.jpg"
      alt="Noose set up outside of the Capitol on January 6, 2021."
      caption="Noose set up outside of the Capitol on January 6, 2021."
      credit="(Photo by Drew Angerer/Getty Images)"
    />

    <Typography className="indent">
      What the President <i>did</i> tweet—a broadside at his Vice President— enlarged the target on Vice President Pence’s back. A Secret Service agent in the Protective Intelligence Division, tasked with monitoring threats against protectees in part by scouring social media, told his colleagues the tweet was “probably not going to be good for Pence.”
      <Citation number={177} />
    </Typography>

    <Typography className="indent">
      A second agent in reply noted that it had garnered “[o]ver 24K likes in under 2 mins.”
      <Citation number={178} />
    </Typography>
  </Page>
)
