export const FBLikeButton = () => (
  <div
    className="fb-like"
    data-href={'https://j6.report' + window.location.pathname}
    data-width="100px"
    data-layout="button_count"
    data-action="like"
    data-size="small"
    data-share="true"
    style={{ margin: "0 -4px -8px", display: 'block' }}
  />
)
