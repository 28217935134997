import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet9'));

export const Chapter714 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      The President may not have expressed regret over his behavior, but some of his most loyal supporters made the connection between his words and the violence.
    </Typography>

    <Typography className="indent">
      A member of the speechwriting team, Patrick MacDonnell, conceded the next day in a text that “maybe the rhetoric could have been better.”
      <Citation number={336} />
      As the riot was in full throttle, even steadfast supporter Ali Alexander of “Stop the Steal” texted, “POTUS is not ignorant of what his words will do.”
      <Citation number={337} />
    </Typography>

    <Typography className="indent">
      “We all look like domestic terrorists now,” Hope Hicks texted Julie Radford.
      <Citation number={338} />
    </Typography>

    <Typography className="indent">
      Separately, Hicks texted Herschmann, “So predictable and so sad.”
    </Typography>

    <Typography className="indent">
      “I know,” he replied. “Tragic.”
    </Typography>

    <Typography className="indent">
      “I’m so upset. Everything we worked for wiped away,” she continued.
    </Typography>

    <Typography className="indent">
      “I agree. Totally self-inflicted,” he wrote.
      <Citation number={339} />
    </Typography>

    <Typography className="indent">
      Brad Parscale, Trump’s Former Campaign Manager, texted Katrina Pierson at 7:21 p.m. on January 6th, saying the day’s events were the result of a “sitting president asking for civil war.”
      <Citation number={340} />
    </Typography>

    <Typography className="indent">
      “This week I feel guilty for helping him win... a woman is dead,” Parscale added.
    </Typography>

    <Typography className="indent">
      “You do realize this was going to happen,” Pierson answered.
    </Typography>

    <Typography className="indent">
      “Yeah. If I was trump [sic] and knew my rhetoric killed someone,” he said.
    </Typography>

    <Typography className="indent">
      “It wasn’t the rhetoric,” she said.
    </Typography>

    <Typography className="indent">
      Parscale’s reply: “Yes it was.”
      <Citation number={341} />
    </Typography>
  </Page>
)
