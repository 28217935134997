export const typography = {
  fontSize: 14,
  fontFamily: 'Open Sans, sans-serif',

  h1: {
    fontSize: '1.25em',
    fontWeight: 'bold',
  },
  h2: {
    fontSize: '1.6em',
    fontWeight: 400,
  },
  h3: {
    fontSize: '1.25em',
    lineHeight: '1.2',
  },
  h4: {
    fontSize: '1.1em',
    fontWeight: 'bold',
  },
  // h5: {},
  // h6: {},
  // subtitle1: {},
  // subtitle2: {},
  body1: {
    fontSize: '1em',
  },
  // body2: {},
  // button: {},
  // caption: {},
  overline: {
    fontSize: '.8em',
    lineHeight: 1,
    display: 'block',
  },
};
