import { lazy } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet7'));

export const Chapter54 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      As the debate over Arizona’s legitimate electors took place on the Senate floor, the Vice President’s staff could see trouble brewing outside.
      <Citation number={291} />
      From inside the Vice President’s ceremonial office, staffers witnessed the crowds swelling on the east side of the Capitol. Then, the rioters broke through security barriers.
      <Citation number={292} />
      Jacob told young staffers that they should stand back from the windows, because the Vice President’s office was not “the most popular office on the block right now.”
      <Citation number={293} />
    </Typography>

    <Typography className="indent">
      The Vice President was presiding over the Senate debate on the Arizona objection when the noise from the rioters became audible and those in the Senate Chamber realized the rioters had entered the Capitol.
      <Citation number={294} />
      The Secret Service evacuated Vice President Pence from the Senate floor at 2:12 p.m.
      <Citation number={295} />
      Twelve minutes later, at 2:24 p.m., President Trump tweeted that Vice President Pence “didn’t have the courage to do what should have been done to protect our country and our Constitution.”
      <Citation number={296} />
      By that time, the Secret Service had moved the Vice President to his ceremonial office across the hall.
      <Citation number={297} />
      But the situation was spiraling out of control—and they wouldn’t stay there long. As Sarah Matthews, the Deputy White House Press Secretary, later explained: President Trump’s tweet was like “pouring gasoline on the fire.”
      <Citation number={298} />
    </Typography>

    <Typography className="indent">
      Thirty seconds after President Trump’s tweet, rioters who were already inside the Capitol opened the East Rotunda door just down the hall. A mere thirty seconds later, rioters breached the crypt one floor below the Vice President.
    </Typography>

    <Typography className="indent">
      Though the Vice President refused the Secret Service’s first two attempts to evacuate him from his ceremonial office, the situation quickly became untenable and the Vice President was told that the Secret Service could no longer protect him in this office in the Capitol that was quickly being overrun.
      <Citation number={299} />
      Marc Short recalls Tim Giebels, the head of the Vice President’s Secret Service protective detail, saying, “At this point, I can’t protect you behind these glass doors, and so I need to move you.”
      <Citation number={300} />
      This time, the third, the Secret Service was not asking the Vice President to move; they were stating the fact that the Vice President must be moved.
      <Citation number={301} />
      At 2:20 p.m., NSC staff monitoring radio communications reported that the second floor of the Capitol and the door to the Senate Chamber “ha[ve] now been breached.”
      <Citation number={302} />
    </Typography>

    <Typography className="indent">
      At 2:25 p.m., the Secret Service rushed the Vice President, his family, and his senior staff down a flight of stairs, through a series of hallways and tunnels to a secure location.
      <Citation number={303} />
      The Vice President and his team stayed in that same location for the next four and a half hours.
    </Typography>

    <Typography className="indent">
      The angry mob had come within 40 feet of the Vice President as he was evacuated.
      <Citation number={304} />
      President Trump never called to check on Vice President Pence’s safety, so Marc Short called Mark Meadows to tell him they were safe and secure.
      <Citation number={305} />
      Short himself became persona non grata with President Trump. The President directed staff to revoke Short’s access to the White House after Vice President Pence refused to betray his oath to the Constitution.
      <Citation number={306} />
      Marc Short never spoke with President Trump again.
      <Citation number={307} />
    </Typography>

    <Typography className="indent">
      After arriving at the secure location, the head of the Vice President’s Secret Service detail wanted to move the Vice President away from the Capitol, and staff hurried into the waiting vehicles. But the Vice President refused to get in the car.
      <Citation number={308} />
      As Greg Jacob explained in his testimony to the Select Committee:
    </Typography>

    <Blockquote>
      <Typography>
        The Vice President wouldn’t get in his car.... [H]e was determined that unless there was imminent danger to bodily safety that he was not going to abandon the Capitol and let the rioters have a victory of having made the Vice President flee or made it difficult to restart the process later that day.
        <Citation number={309} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      It was an unprecedented scene in American history. The President of the United States had riled up a mob that hunted his own Vice President.
    </Typography>

    <Image
      src="/images/image-073.jpg"
      alt="Mike Pence"
      credit="(Photo provided to the Select Committee by the National Archives and Records Administration)"
    />

    <Typography className="indent">
      The Vice President’s staff came to believe that the theory “pushed and sold” to the public that the Vice President had a role to play in the joint session was a cause of the attack on the Capitol. “The reason that the Capitol was assaulted was that the people who were breaching the Capitol believed that... the election [outcome] had not yet been determined, and, instead, there was some action that was supposed to take place in Washington, D.C., to determine it,” Jacob said.
      <Citation number={310} />
      “I do think [the violence] was the result of that position being continuously pushed and sold to people who ended up believing that with all their hearts.”
      <Citation number={311} />
      The people had been “told that the Vice President had the authority” to determine the outcome of the election during the joint session.
      <Citation number={312} />
    </Typography>

    <Typography className="indent">
      Of course, that was President Trump’s and John Eastman’s plan all along—to convince people that the election had been stolen, and that Vice President Pence could take action to change the outcome during the joint session on January 6th.
    </Typography>

    <Typography className="indent">
      Jacob was writing an email to Eastman when the Capitol was breached.
      <Citation number={313} />
      At 2:14 p.m., just before being evacuated, Jacob hurriedly hit send on his email, but not before adding the following: “thanks to your bullshit, we are now under siege.”
      <Citation number={314} />
    </Typography>

    <Typography className="indent">
      Eastman quickly replied to Jacob’s email and, incredibly, blamed Vice President Pence and Jacob for the attack. “The ‘siege’ is because YOU and your boss did not do what was necessary to allow this to be aired in a public way so the American people can see for themselves what happened,” Eastman wrote.
      <Citation number={315} />
      Naturally, Jacob was “somewhere between aghast and livid.”
      <Citation number={316} />
      It was “ridiculous” to blame Vice President Pence for the attack, when he simply followed the law.
      <Citation number={317} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        THE JOINT SESSION RECONVENES: “LET’S GET BACK TO WORK.”
      </Typography>
    </Box>

    <Image
      src="/images/image-074.jpg"
      alt="Mike Pence"
      credit="(Photo provided to the Select Committee by the National Archives and Records Administration)"
    />

    <Typography className="indent">
      The Senate reconvened at approximately 8:06 p.m.
      <Citation number={318} />
      Congressional leadership and the Vice President insisted on finishing the work of the people. “Today was a dark day in the history of the United States Capitol,” Vice President Pence said. “But thanks to the swift efforts of U.S. Capitol Police, federal, state and local law enforcement, the violence was quelled. The Capitol is secured, and the people’s work continues.” The Vice President addressed “those who wreaked havoc in our Capitol today,” saying “you did not win.” Vice President Pence continued:
    </Typography>

    <Blockquote>
      <Typography>
        Violence never wins. Freedom wins. And this is still the people’s house. And as we reconvene in this chamber, the world will again witness the resilience and strength of our democracy, for even in the wake of unprecedented violence and vandalism at this Capitol, the elected representatives of the people of the United States have assembled again on the very same day to support and defend the Constitution of the United States.
      </Typography>
    </Blockquote>

    <Typography className="indent">
      “Let’s get back to work,” Vice President Pence concluded.
      <Citation number={319} />
    </Typography>

    <Typography className="indent">
      Despite the violence that had unfolded at the Capitol, Eastman kept agitating for further delay. At 11:44 p.m. on January 6th, Eastman sent yet another email to Greg Jacob.
      <Citation number={320} />
      In a shockingly tone-deaf manner, Eastman claimed that the Electoral Count Act had been violated already, by allowing debate beyond two hours, so—he argued—Vice President Pence should no longer be concerned that what President Trump and Eastman had pressured him to do also would violate it.
      <Citation number={321} />
      “Of course,” as Jacob pointed out, the debate couldn’t have been completed in two hours due to the “intervening riot of several hours.”
      <Citation number={322} />
    </Typography>

    <Typography className="indent">
      Eastman argued that Vice President Pence should “adjourn for 10 days to allow the legislatures to finish their investigations, as well as to allow a full forensic audit of the massive amount of illegal activity that has occurred here.”
      <Citation number={323} />
      Eastman described this—a delay in the certification of the vote and the peaceful transfer of power with no legal or historical precedent or support, based on entirely specious and disproven allegations of election fraud, following on a violent attack on the seat of American democracy—as a “relatively minor violation.”
      <Citation number={324} />
    </Typography>

    <Typography className="indent">
      Vice President Pence later described Eastman’s email as “rubber room stuff,” meaning it was certifiably crazy.
      <Citation number={325} />
    </Typography>
  </Page>
)
