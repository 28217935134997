import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet8'));

export const Chapter614 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      President Trump wanted to personally accompany his supporters on the march from the Ellipse to the U.S. Capitol. During a January 4th meeting with staffers and event organizer Katrina Pierson, President Trump emphasized his desire to march with his supporters.
      <Citation number={429} />
      “Well, I should walk with the people,” Pierson recalled President Trump saying.
      <Citation number={430} />
      Though Pierson said that she did not take him “seriously,” she knew that “he would absolutely want to be with the people.”
      <Citation number={431} />
      Pierson pointed out that President Trump “did the drive-by the first time and the flyover the second time”—a reference to the November and December 2020 protests in Washington, DC.
      <Citation number={432} />
      During these previous events, President Trump made cameo appearances to fire up his supporters. Now, as January 6th approached, the President again wanted to be there, on the ground, as his supporters marched on the U.S. Capitol.
    </Typography>

    <Typography className="indent">
      The President’s advisors tried to talk him out of it. White House Senior Advisor Max Miller “shot it down immediately” because of concerns about the President’s safety.
      <Citation number={433} />
      Pierson agreed.
      <Citation number={434} />
      But President Trump was persistent, and he floated the idea of having 10,000 National Guardsmen deployed to protect him and his supporters from any supposed threats by leftwing counter-protestors.
      <Citation number={435} />
      Miller again rejected the President’s idea, saying that the National Guard was not necessary for the event. Miller testified that there was no further conversation on the matter.
      <Citation number={436} />
      After the meeting, Miller texted Pierson, “Just glad we killed the national guard and a procession.”
      <Citation number={437} />
      That is, President Trump briefly considered having the National Guard oversee his procession to the U.S. Capitol. The President did not order the National Guard to protect the U.S. Capitol, or to secure the joint session proceedings.
    </Typography>

    <Typography className="indent">
      Although his advisors tried to talk the President out of personally going, they understood that his supporters would be marching.
      <Citation number={438} />
      Pierson’s agenda for the meeting reflected the President’s plan for protestors to go to the U.S. Capitol after the rally.
      <Citation number={439} />
      But President Trump did not give up on the idea of personally joining his supporters on their march, as discussed further in Chapter 7.
    </Typography>
  </Page>
)
