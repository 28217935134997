import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';
import { StyledListContainer } from '../../../components/StyledListContainer';

const Citation = lazy(() => import('../../Citations/CitationSheet2'));

export const ConspiracyToMakeFalseStatement = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
    	President Trump, through others acting at his behest, submitted slates of fake electors to Congress and the National Archives. Section 1001 of Title 18 of the United States Code applies, in relevant part, to “whoever, in any matter within the jurisdiction of the executive, legislative, or judicial branch of the Government of the United States, knowingly and willfully—
    </Typography>

    <StyledListContainer>
      <ol>
        <li>
          <Typography>
            falsifies, conceals, or covers up by any trick, scheme, or device a material fact;
          </Typography>
        </li>
        <li>
          <Typography>
          	makes any materially false, fictitious, or fraudulent statement or representation; or
          </Typography>
        </li>
        <li>
          <Typography>
          	makes or uses any false writing or document knowing the same to contain any materially false, fictitious, or fraudulent statement or entry.”
          </Typography>
        </li>
      </ol>
    </StyledListContainer>

    <Typography className="indent">
    	According to the Department of Justice, whether a false statement is criminal under section 1001 “depends on whether there is an affirmative response to each of the following questions:
    </Typography>

    <StyledListContainer>
      <ol>
        <li>
          <Typography>
          	Was the act or statement material?
          </Typography>
        </li>
        <li>
          <Typography>
          	Was the act within the jurisdiction of a department or agency of the United States?
          </Typography>
        </li>
        <li>
          <Typography>
          	Was the act done knowingly and willfully?”
          	<Citation number={625} />
          </Typography>
        </li>
      </ol>
    </StyledListContainer>

    <Typography className="indent">
    	In addition, and as explained above, 18 U.S.C. § 371 makes it a crime to conspire to “commit any offense against the United States.”
    	<Citation number={626} />
    </Typography>

    <Typography className="indent">
    	The evidence suggests President Trump conspired with others to submit slates of fake electors to Congress and the National Archives. Sufficient evidence exists of a violation of 18 U.S.C. §§ 371 and 1001 for a criminal referral of President Trump and others.
    </Typography>

    <Typography className="indent">
    	As explained earlier and in Chapter 3 of this Report, the certifications signed by Trump electors in multiple States were patently false. Vice President Biden won each of those States, and the relevant State authorities had so certified. It can hardly be disputed that the false slates of electors were material, as nothing can be more material to the joint session of Congress to certify the election than the question of which candidate won which States. Indeed, evidence obtained by the Committee suggests that those attempting to submit certain of the electoral votes regarded the need to provide that material to Vice President Pence as urgent.
    	<Citation number={627} />
    </Typography>

    <Typography className="indent">
    	There should be no question that section 1001 applies here. The false electoral slates were provided both to the executive branch (the National Archives) and the legislative branch.
    	<Citation number={628} />
  		The statute applies to “any matter within the jurisdiction of the executive, legislative, or judicial branch of the Government of the United States.”
    	<Citation number={629} />
    	It is well established that false statements to Congress can constitute violations of section 1001.
    	<Citation number={630} />
    </Typography>

    <Typography className="indent">
    	Finally, the false statement was made knowingly and willfully. There is some evidence suggesting that some signatories of the fake certificates believed that the certificates were contingent, to be used only in the event that President Trump prevailed in litigation challenging the election results in their States. That may be relevant to the question whether those electors knowingly and willfully signed a false statement at the time they signed the certificates. But it is of no moment to President Trump’s conduct, as President Trump (including acting through co-conspirators such as John Eastman and Kenneth Chesebro) relied on the existence of those fake electors as a basis for asserting that the Vice President could reject or delay certification of the Biden electors. In fact, as explained earlier and in Chapter 5 of this Report, Eastman’s memorandum setting out a six-step plan for overturning the election on January 6th begins by stating that “7 states have transmitted dual slates of electors to the President of the Senate.”
    </Typography>

    <Typography className="indent">
    	The remaining question is who engaged in this conspiracy to make the false statement to Congress under section 1001. The evidence is clear that President Trump personally participated in a scheme to have the Trump electors meet, cast votes, and send their votes to the joint session of Congress in several States that Vice President Biden won, and then his supporters relied on the existence of these fake electors as part of their effort to obstruct the joint session. Republican National Committee (RNC) Chairwoman Ronna McDaniel testified before this Committee that President Trump and Eastman directly requested that the RNC organize the effort to have these fake (i.e., Trump) electors meet and cast their votes.
    	<Citation number={631} />
    	Thus, the Committee believes that sufficient evidence exists for a criminal referral of President Trump for illegally engaging in a conspiracy to violate section 1001; the evidence indicates that he entered into an agreement with Eastman and others to make the false statement (the fake electoral certificates), by deceitful or dishonest means, and at least one member of the conspiracy engaged in at least one overt act in furtherance of the conspiracy (e.g., President Trump and Eastman’s call to Ronna McDaniel).
    </Typography>
  </Page>
)
