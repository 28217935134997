import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet10'));

export const Chapter83 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Within minutes of arriving at the Peace Circle, the Proud Boys and their associates launched the attack on the U.S. Capitol. The circle is the site of the Peace Monument, a statue erected from 1877 to 1878 to commemorate naval deaths at sea during the Civil War with “two classically robed” women—one woman representing “grief,” covering her face, and the other woman representing “history.” The woman standing in for “history” holds a tablet that reads, “They died that their country might live.”
      <Citation number={77} />
    </Typography>

    <Typography className="indent">
      The Peace Circle’s geographical location is crucially important for understanding how the January 6th, attack unfolded. It sits at the end of Pennsylvania Avenue, just in front of the U.S. Capitol. At the conclusion of his speech at the Ellipse, President Trump directed rally attendees to march down Pennsylvania Avenue to the U.S. Capitol. Their shortest natural path would lead them right to the Peace Circle and to the northwest side of the Capitol grounds, also known as the West Plaza. By the time rally-goers arrived, the Proud Boys and their allies had already removed the fencing that stood in the crowd’s way. As a result, thousands of people streamed into the restricted Capitol grounds with relative ease.
    </Typography>

    <Typography className="indent">
      When the Proud Boys arrived back at the Peace Circle at 12:49 p.m., they still had about 200 to 300 members and many other protestors had joined them.
      <Citation number={78} />
      Shortly after arriving, the Proud Boys incited the crowd with antagonistic chants such as “1776.”
      <Citation number={79} />
      Officer Caroline Edwards, who was standing guard, explained to the Select Committee that the Proud Boys asked her and the other USCP officers if they could walk past the fencing and talk to the officers. “No,” she replied. The Proud Boys and others immediately turned on Edwards and her fellow officers, referring to them as “Nancy Pelosi’s dogs” and shouting.
      <Citation number={80} />
    </Typography>

    <Typography className="indent">
      At approximately 12:51 p.m., Quested captured a rioter named Ryan Samsel with his arm around Proud Boys leader Joe Biggs, who led the chants.
      <Citation number={81} />
      Samsel subsequently claimed that Biggs encouraged him to push through the barricades and, when Samsel hesitated to follow through, Biggs “flashed a gun, questioned his manhood and repeated his demand” to move to the front and “challenge the police.”
      <Citation number={82} />
      Biggs has contested Samsel’s version of events.
      <Citation number={83} />
      After speaking with Biggs, Samsel breached the outer fencing of the Peace Circle at 12:53 p.m.
      <Citation number={84} />
      The first set of fencing at the Peace Circle was staged on 1st Street Northwest, with the second set of fencing not far behind. Once Samsel breached the outer fencing, USCP officers, including Officer Edwards, moved from their posts to meet Samsel and other rioters.
      <Citation number={85} />
    </Typography>

    <Typography className="indent">
      In less than a minute, at 12:54 p.m., the rioters pushed USCP officers to the ground, removed the fencing, and quickly stormed east towards the U.S. Capitol building.
      <Citation number={86} />
      Officer Edwards was thrown to the ground, causing her to hit her head on concrete steps.
      <Citation number={87} />
    </Typography>

    <Typography className="indent">
      Two Proud Boys from New York, Dominic Pezzola and William Pepe, were among those leading the march to the next line of security barriers.
      <Citation number={88} />
      Pepe, an employee of the Metropolitan Transportation Authority in upstate New York, took sick leave to travel to Washington for the January 6th events.
      <Citation number={89} />
      Pepe dragged part of the fence away at the next security barrier, ensuring that USCP officers were left defenseless.
      <Citation number={90} />
      The Proud Boys’ actions were not spontaneous. Jeffrey Finley, a Proud Boys leader from West Virginia, later admitted “there appeared to be a coordinated effort to pull the barricades apart.”
      <Citation number={91} />
      Proud Boy Jeremy Bertino admitted to similar facts when pleading guilty to seditious conspiracy, stating stated that he “believed... that the purpose of traveling to Washington, DC, on January 6, 2021, was to stop the certification of the Electoral College Vote, and that the MOSD leaders were willing to do whatever it would take, including using force against police and others, to achieve that objective.” Based on discussions he and other Proud Boys leaders had in the leadup to January 6th, he “believed that storming the Capitol would achieve the group’s goal of stopping Congress from certifying the Electoral College Vote. Bertino understood that storming the Capitol or its grounds would be illegal and would require using force against police or other government officials.”
      <Citation number={92} />
    </Typography>

    <Typography className="indent">
      Parallel to the Peace Circle, at the Garfield Circle walkway located at the southeast corner of the Capitol grounds, rioters breached the fencing at 12:55 p.m. and began rushing the West Plaza where they would converge with others from the Peace Circle.
      <Citation number={93} />
    </Typography>

    <Typography className="indent">
      By 12:58 p.m., the crowd filled the lower West Plaza of the Capitol just below the inauguration stage that had been built for the ceremony scheduled two weeks later. After the initial breaches, the USCP was able to deploy enough officers to stop the rioters from advancing past the base of the inauguration stage. More importantly, rioter momentum was further halted when the first group of MPD officers arrived on scene at 1:11 p.m.,
      <Citation number={94} />
      almost precisely as President Trump finished his Ellipse speech. The MPD officers initially pushed back the rioters on the West Plaza, slowing them down before they would later breach the Capitol.
      <Citation number={95} />
    </Typography>

    <Typography className="indent">
      A stalemate ensued on the West Plaza before rioters were able to make any further progress. Rally-goers arriving from the Ellipse provided crucial momentum.
    </Typography>
  </Page>
)
