import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet5'));

export const Chapter33 = ({ ...args }: any) => (
  <Page {...args}>
    <Image
      src="/images/image-047.jpg"
      alt=" Ronna McDaniel at the Republican National Convention on August 24, 2020."
      caption=" Ronna McDaniel at the Republican National Convention on August 24, 2020."
      credit="(Photo by Chip Somodevilla/Getty Images)"
      style={{ marginTop: 0 }}
    />

    <Typography className="indent">
      Not everyone on the campaign was eager to pursue the fake elector plan. On December 11th, the U.S. Supreme Court rejected a high-profile lawsuit filed by the State of Texas challenging the election results in Pennsylvania, Georgia, Michigan, and Wisconsin.
      <Citation number={42} />
      After that decision, the Trump Campaign’s senior legal staffers said that they reduced their involvement in the fake elector effort, apparently because there was no longer a feasible scenario in which a court would determine that President Trump actually won any of the States he contested.
      <Citation number={43} />
      Justin Clark, who oversaw the Trump Campaign’s general counsel’s office, said that he basically conveyed, “I’m out,” and encouraged his colleagues on the legal team to do the same.
      <Citation number={44} />
      Findlay told the Select Committee that “we backed out of this thing,” and Morgan, his boss, said he had Findlay pass off responsibility for the electors as “my way of taking that responsibility to zero.”
      <Citation number={45} />
    </Typography>

    <Image
      src="/images/image-048.jpg"
      alt="Rudy Giuliani speaks inside the Republican National Committee Headquarters in November about various lawsuits related to the 2020 election."
      caption="Rudy Giuliani speaks inside the Republican National Committee Headquarters in November about various lawsuits related to the 2020 election."
      credit="(Photo by Drew Angerer/Getty Images)"
    />

    <Typography className="indent">
      Clark told the Select Committee that “it never sat right with me that there was no... contingency whereby these votes would count.”
      <Citation number={46} />
      “Ihad real problems with the process,” Clark said, because “it morphed into something I didn’t agree with.”
      <Citation number={47} />
      In his view, the fake electors were “not necessarily duly nominated electors” despite being presented as such.
      <Citation number={48} />
      He said he believed he warned his colleagues that “unless we have litigation pending like in these States, like I don’t think this is appropriate or, you know, this isn’t the right thing to do.”
      <Citation number={49} />
    </Typography>

    <Typography className="indent">
      Morgan told the Select Committee that he saw no value in pushing slates of purported electors if they were not authorized by a State government’s certificate of ascertainment. As he put it, “[M]y view was, as long as you didn’t have a certificate of ascertainment, then the electors were, for lack of a better way of saying it, no good or not—not valid.”
      <Citation number={50} />
      Findlay confirmed that Morgan told him after the Supreme Court ruling on December 11th that “there’s not really anything left for us to do on this project” and that “it doesn’t seem like a good idea for us to be involved in it.”
      <Citation number={51} />
    </Typography>

    <Typography className="indent">
      Campaign lawyers were not the only ones who doubted the legality of the fake elector plan. The Office of White House Counsel appears to have expressed concerns about it as well. In his testimony to the Select Committee, White House Counsel Pat Cipollone acknowledged his view that by mid-December, the electoral process was “done.” Cipollone told the Select Committee that the White House Counsel’s office “probably” had discussions about the electors plan and that his Deputy, Pat Philbin, would have been involved in evaluating the electors issue.
      <Citation number={52} />
      In an informal Committee interview, Philbin described the fake elector scheme as one of the “bad theories” that were like “Whac-A-Mole” in the White House during this period.
      <Citation number={53} />
      Mr. Cipollone agreed with this characterization.
      <Citation number={54} />
    </Typography>

    <Typography className="indent">
      In her testimony, Cassidy Hutchinson testified that she heard at least one member of the White House Counsel’s Office say that the plan was not legal:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: ...to be clear, did you hear the White House Counsel’s Office say that this plan to have alternate electors meet and cast votes for Donald Trump in States that he had lost was not legally sound?
      </Typography>
      <Typography>
        <strong>Hutchinson</strong>: Yes, sir.
        <Citation number={55} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      She also recalled a meeting that took place in or before mid-December during which this view was relayed to Giuliani and members of his team by lawyers in the White House Counsel’s Office.
      <Citation number={56} />
    </Typography>

    <Typography className="indent">
      By December 11th, Findlay emailed his main points of contact in six battleground States to say “[t]hank you for your work on the presidential elector project” and, in order to pass off his responsibilities, let them know that “Rudy’s team has designated Kenneth Chesebro as the point person for the legal documents” going forward.
      <Citation number={57} />
    </Typography>

    <Typography className="indent">
      While the campaign’s core legal team stepped back from the fake elector effort on December 11th, it nonetheless went forward because “Rudy was in charge of [it]” and “[t]his is what he wanted to do,” according to Findlay.
      <Citation number={58} />
      When Findlay was asked if this decision to let the effort proceed under Giuliani’s direction “was coming from your client, the President,” Findlay responded: “Yes, I believe so. I mean, he had made it clear that Rudy was in charge of this and that Rudy was executing what he wanted.”
      <Citation number={59} />
      Findlay also recalled being told that Chesebro’s elector memos had become “the justification for why Rudy and Ken were going to keep going forward with this stuff.”
      <Citation number={60} />
      He explained that Giuliani “really bought into Ken’s theory on this,” and that the two of them “were kind of the main ones driving this” from that point forward.
      <Citation number={61} />
      Clark told the Select Committee that “...my understanding of who was driving the process... was Mayor Giuliani and his team.”
      <Citation number={62} />
      On December 10th, when Kenneth Chesebro emailed one of the State party officials involved in organizing the fake elector effort in Nevada, he reported that “I spoke this evening with Mayor Guiliani [<i>sic</i>], who is focused on doing everything possible to ensure that that [<i>sic</i>] all the Trump-Pence electors vote on Dec.14.”
      <Citation number={63} />
    </Typography>

    <Typography className="indent">
      In the days that followed this handoff, Chesebro would draft and distribute documents intended for use in the Trump team’s fake elector ceremonies that were then shared with key contacts in Arizona,
      <Citation number={64} />
      Georgia,
      <Citation number={65} />
      Michigan,
      <Citation number={66} />
      Nevada,
      <Citation number={67} />
      New Mexico,
      <Citation number={68} />
      Pennsylvania,
      <Citation number={69} />
      and Wisconsin.
      <Citation number={70} />
      He also gave some of the groups step-by-step logistical guidance, such as when and where they should convene, how many copies each person would need to sign, and to send their fake votes to Congress via registered mail.
      <Citation number={71} />
      “Pretty Simple!” he commented in some of these emails.
      <Citation number={72} />
    </Typography>

    <Typography className="indent">
      A campaign operative named Michael Roman was also tapped for a major operational role in the fake elector effort. When Findlay sent his email handing off certain responsibilities for the initiative, he also wrote that Giuliani’s team had designated Roman “as the lead for executing the voting on Monday” December 14th.
      <Citation number={73} />
      Roman was the Trump Campaign’s Director of Election Day Operations (EDO), with team members who specialized in political outreach and mobilization in battleground States where the Trump team now urgently needed the fake electors to meet on December 14th.
    </Typography>

    <Typography className="indent">
      With help from his EDO staff, as well as Giuliani’s team and RNC staffers working alongside the Campaign as part of the Trump Victory Committee, Roman ran an improvised “Electors Whip Operation.”
      <Citation number={74} />
      For example, Roman sent an email on December 12th directing an aide to create “a tracker for the electors” with tabs for Arizona, Georgia, Michigan, Nevada, Pennsylvania, and Wisconsin, listing contact information, whether they had been contacted, whether they agreed to attend on December 14th, and names of “[s]ubstitute electors” to replace any reticent or unavailable participants as needed.
      <Citation number={75} />
      Roman referred to others on this email as the “WHIP TEAM” and directed them to fill out the spreadsheet, to update him on “what you have and what you need,” and to plan on a call that evening.
      <Citation number={76} />
    </Typography>

    <Typography className="indent">
      In the days that followed, this group focused on tracking which Republicans previously named as President Trump’s nominees to the electoral college would be willing to show up for fake elector ceremonies, finding adequate substitutes for those who refused to attend, and actually coordinating the unrecognized elector signing ceremonies in seven States on December 14th.
      <Citation number={77} />
      In all seven States, these efforts to mobilize fake electors benefitted from support from the RNC, as well as the State Republican parties.
      <Citation number={78} />
      However, it was the Trump team who drove the process from start to finish, as one of the fake electors and later co-chair of the Michigan Republican party, Meshawn Maddock, told an audience in January 2022: “We fought to seat the electors. The Trump campaign asked us to do that.”
      <Citation number={79} />
    </Typography>
  </Page>
)
