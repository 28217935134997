import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet8'));

export const Chapter613 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      On the evening of December 27th, President Trump boosted the upcoming event on Twitter: “See you in Washington, DC, on January 6th. Don’t miss it. Information to follow!”
      <Citation number={415} />
      The Select Committee learned that this tweet came after the White House spoke with a former Trump staffer, Justin Caporale, who was asked to help produce the Ellipse rally.
      <Citation number={416} />
      That same evening, the President had dinner with Donald Trump, Jr., and his girlfriend Kimberly Guilfoyle,
      <Citation number={417} />
      who spoke with rally organizer Caroline Wren during the meal.
      <Citation number={418} />
      Wren also texted Guilfoyle talking points that described her ambitions for the event, saying that “buses of people are coming in from all over the country to support you. It’s going to be huge, we are also adding in programming the night of January 5th.”
      <Citation number={419} />
    </Typography>

    <Typography className="indent">
      After Guilfoyle’s call with Wren, there was a series of calls among the senior White House staff,
      <Citation number={420} />
      likely underscoring the seriousness of the White House’s interest in the event.
    </Typography>

    <Typography className="indent">
      Within a few days, the White House began to take a more direct role in coordinating the rally at the Ellipse.
      <Citation number={421} />
      In a December 29th text to Wren, Caporale wrote that after the President’s planned speech there “maybe [<i>sic</i>] a call to action to march to the [C]apitol and make noise.”
      <Citation number={422} />
    </Typography>

    <Typography className="indent">
      This is the earliest indication uncovered by the Select Committee that the President planned to call on his supporters to march on the U.S. Capitol. But it wasn’t the last. On January 2nd, rally organizer Katrina Pierson informed Wren that President Trump’s Chief of Staff, Mark Meadows, had said the President was going to “call on everyone to march to the [C]apitol.”
      <Citation number={423} />

    </Typography>

    <Typography className="indent">
      Inside the White House, the President’s intent was well-known. Cassidy Hutchinson, an aide to Meadows, recalled in her testimony that she overheard discussions to this effect toward the end of December or early January. One such discussion included an exchange between Meadows and Rudolph Giuliani that occurred on January 2nd.
      <Citation number={424} />
      Hutchinson understood that President Trump wanted to have a crowd at the Capitol in connection with what was happening inside—the certification of the electoral count.
      <Citation number={425} />
      Hutchinson also recalled that President Trump’s allies in Congress were aware of the plan. During a call with members of the House Freedom Caucus, the idea of telling people to go to the Capitol was discussed as a way to encourage Congress to delay the electoral college certification and send it back to the States.
      <Citation number={426} />
    </Typography>

    <Typography className="indent">
      On January 4th, WFAF’s Kylie Kremer informed Mike Lindell, the CEO of MyPillow and an ally of President Trump, that “POTUS is going to have us march there [the Supreme Court]/the Capitol” but emphasized that the plan “stays only between us.”
      <Citation number={427} />
    </Typography>

    <Typography className="indent">
      The “Stop the Steal” coalition was aware of the President’s intent. On January 5th, Ali Alexander sent a text to a journalist saying: “Ellipse then US capitol [<i>sic</i>]. Trump is supposed to order us to the capitol [<i>sic</i>] at the end of his speech but we will see.”
      <Citation number={428} />
    </Typography>
  </Page>
)
