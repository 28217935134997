import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet9'));

export const Chapter71 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      President Trump tweeted three times on the morning of January 6th, repeating a false claim of election fraud at 8:06 a.m.,
      <Citation number={24} />
      pressuring Vice President Pence to delay the electoral count at 8:17 a.m.,
      <Citation number={25} />
      and urging Republican party officials to do the same at 8:22 a.m.
      <Citation number={26} />
      He made calls to his Republican allies in Congress, many of whom were already committed to objecting to the electoral count.
      <Citation number={27} />
      And he dialed his lawyers and advisors— including Steve Bannon and Rudolph Giuliani (twice), both of whom had been counseling the President on how to stay in power.
      <Citation number={28} />
    </Typography>

    <Typography className="indent">
      There was one person—critical to his plan—whom President Trump tried to reach but couldn’t. At 9:02 a.m., he asked the switchboard operator to call his Vice President. Vice President Pence did not answer the call.
      <Citation number={29} />
    </Typography>

    <Typography className="indent">
      Instead, between 9:52 a.m. and 10:18 a.m., the President spoke with his speechwriter, Stephen Miller, about the words he would deliver at the Save America Rally just hours later.
      <Citation number={30} />
      The former President’s speech had come together over the course of 36 hours, going from a screed aimed at encouraging congressional objections to one that would ultimately incite mob violence.
      <Citation number={31} />
    </Typography>

    <Typography className="indent">
      Only four minutes after the call concluded, at 10:22 a.m., Miller emailed revisions to the speechwriters, instructing them to “[s]tart inputting these changes asap” that included “red highlights marking POTUS edits.”
      <Citation number={32} />
      The President had made some cosmetic additions, like peppering in the word “corrupt” throughout,
      <Citation number={33} />
      but there was one substantive edit—a new target—that would focus the crowd’s anger on one man.
    </Typography>

    <Image
      src="/images/image-091.jpg"
      alt="President Trump speaks with speechwriter Stephen Miller about his Ellipse speech in the Oval Office on the morning of January 6, 2021."
      caption="President Trump speaks with speechwriter Stephen Miller about his Ellipse speech in the Oval Office on the morning of January 6, 2021."
      credit="(Photo provided to the Select Committee by the National Archives and Records Administration)"
    />

    <Typography className="indent">
      None of the preceding drafts mentioned Vice President Pence whatsoever. But now, at the very last minute, President Trump slipped in the following sentences calling the Vice President out by name:
    </Typography>

    <Blockquote>
      <Typography>
        Today, we will see whether Republicans stand strong for the integrity of our elections. And we will see whether Mike Pence enters history as a truly great and courageous leader. All he has to do is refer the illegally-submitted electoral votes back to the states that were given false and fraudulent information where they want to recertify. With only 3 of the 7 states in question we win and become President and have the power of the veto.
        <Citation number={34} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      No one on the speechwriting team could explain why President Trump added these lines just 30 minutes before he was originally scheduled to speak at 11:00 a.m.
      <Citation number={35} />
      But by 10:49 a.m., Vincent Haley, a speechwriter who was helping load the teleprompter at the Ellipse, was told to hold off and delete the mention of the Vice President—for now.
      <Citation number={36} />
      Miller said that Eric Herschmann, a lawyer who was one of the President’s senior advisors, asked him in a “brief sidebar” that morning to omit reference to the Vice President and his role in the certification process because he “didn’t concur with the legal analysis” and that it “wouldn’t advance the ball” but would be “counterproductive” instead.
      <Citation number={37} />
      As detailed in Chapter 5, Herschmann and others in the White House were vocal critics of Dr. John Eastman’s theory, which claimed that the Vice President had the unilateral power to reject electors during the joint session of Congress. President Trump repeatedly pressured Pence to either reject certified electors, or delay the electoral count based on Eastman’s unconstitutional and illegal theory. Vice President Pence would not budge. The Vice President consistently rejected President Trump’s demands.
    </Typography>

    <Image
      src="/images/image-092.jpg"
      alt="President Trump on a phone call with Vice President Mike Pence in the Oval Office on the morning of January 6, 2021."
      caption="President Trump on a phone call with Vice President Mike Pence in the Oval Office on the morning of January 6, 2021."
      credit="(Photo provided to the Select Committee by the National Archives and Records Administration)"
    />

    <Typography className="indent">
      After tweeting four more times that morning—all of them spreading lies about the election
      <Citation number={38} />
      —the President apparently thought he had one last chance to convince his number two to overrule the will of the American people.
    </Typography>

    <Typography className="indent">
      As recounted in Chapter 5, President Trump called Vice President Pence at 11:17 a.m.
      <Citation number={39} />
      The call between the two men—during which the President soon grew “frustrat[ed] or heated,”
      <Citation number={40} />
      visibly upset,
      <Citation number={41} />
      and “angry”
      <Citation number={42} />
      — lasted nearly 20 minutes.
      <Citation number={43} />
      And President Trump insulted Vice President Pence when he refused to obstruct or delay the joint session.
    </Typography>

    <Typography className="indent">
      After that call, General Keith Kellogg said that the people in the room immediately went back to editing the Ellipse speech.
      <Citation number={44} /> At 11:30 a.m., Miller emailed his assistant, Robert Gabriel, with no text in the body but the subject line: “insert—stand by for phone call.”
      <Citation number={45} />
      At 11:33 a.m., Gabriel emailed the speechwriting team: “REINSERT THE MIKE PENCE LINES. Confirm receipt.”
      <Citation number={46} />
       One minute later, speechwriter Ross Worthington confirmed that he had reached Vincent Haley by phone.
      <Citation number={47} />
       Haley corroborated that he added one “tough sentence about the Vice President” while he was at the teleprompter.
      <Citation number={48} />
    </Typography>

    <Image
      src="/images/image-093.jpg"
      alt="President Trump looks backstage at the crowd gathered at the Ellipse."
      caption="President Trump looks backstage at the crowd gathered at the Ellipse."
      credit="(Photo provided to the Select Committee by the National Archives and Records Administration)"
    />

    <Typography className="indent">
      The final written draft had the following Pence reference: “And we will see whether Mike Pence enters history as a truly great and courageous leader.”
      <Citation number={49} /> Haley wasn’t confident that line was what he reinserted, but email traffic and teleprompter drafts produced by the National Archives and Records Administration (NARA) indicate that he was mistaken.
      <Citation number={50} />
    </Typography>

    <Typography className="indent">
      After defying President Trump’s pressure, Vice President Pence—and the ire of the President he inspired—was back in the speech.
    </Typography>

    <Typography className="indent">
      After the heated call, President Trump’s personal assistant Nicholas Luna handed him a message on White House card stock and the President departed for the Ellipse to give his speech.
      <Citation number={51} />
      Preserved by NARA, the message read: “THEY ARE READY FOR YOU WHEN YOU ARE.”
      <Citation number={52} />
      When it finally came time for him to speak, President Trump repeatedly directed his anger at Vice President Pence—often ad-libbing lines that were not included in the draft text.
    </Typography>
  </Page>
)
