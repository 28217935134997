import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../components/Blockquote';
import { Page } from '../../components/Page';
import { StyledListContainer } from '../../components/StyledListContainer';

const Citation = lazy(() => import('../Citations/CitationSheet2'));

export const EffortsToAvoidTestifying = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      More than 30 witnesses before the Select Committee exercised their Fifth Amendment privilege against self-incrimination and refused on that basis to provide testimony. They included individuals central to the investigation, such as John Eastman, Jeffrey Clark, Roger Stone, Michael Flynn, Kenneth Chesebro, and others.
      <Citation number={683} />
      The law allows a civil litigant to rely upon an “adverse inference” when a witness invokes the Fifth Amendment. “[T]he Fifth Amendment does not forbid adverse inferences against parties to civil actions....”
      <Citation number={684} />
      The Committee has not chosen to rely on any such inference in this Report or in its hearings.
    </Typography>

    <Typography className="indent">
      We do note that certain witness assertions of the Fifth Amendment were particularly troubling, including this:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Vice Chair Cheney</strong>: General Flynn, do you believe the violence on January 6th was justified?
      </Typography>
      <Typography>
        <strong>Counsel for the Witness</strong>: Can I get clarification, is that a moral question or are you asking a legal question?
      </Typography>
      <Typography>
        <strong>Vice Chair Cheney</strong>: I'm asking both.
      </Typography>
      <Typography>
        <strong>General Flynn</strong>: The Fifth.
      </Typography>
      <Typography>
        <strong>Vice Chair Cheney</strong>: Do you believe the violence on January 6th was justified morally?
      </Typography>
      <Typography>
        <strong>General Flynn</strong>: Take the Fifth.
      </Typography>
      <Typography>
        <strong>Vice Chair Cheney</strong>: Do you believe the violence on January 6th was justified legally?
      </Typography>
      <Typography>
        <strong>General Flynn</strong>: Fifth.
      </Typography>
      <Typography>
        <strong>Vice Chair Cheney</strong>: General Flynn, do you believe in the peaceful transition of power in the United States of America?
      </Typography>
      <Typography>
        <strong>General Flynn</strong>: The Fifth.
        <Citation number={685} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      President Trump refused to comply with the Committee’s subpoena, and also filed suit to block the National Archives from supplying the Committee with White House records. The Committee litigated the National Archives case in Federal District Court, in the Federal Appellate Court for the District of Columbia, and before the Supreme Court. The Select Committee was successful in this litigation. The opinion of the D.C. Circuit explained:
    </Typography>

    <Blockquote>
      <Typography>
        On January 6, 2021, a mob professing support for then-President Trump violently attacked the United States Capitol in an effort to prevent a Joint Session of Congress from certifying the electoral college votes designating Joseph R. Biden the 46th President of the United States. The rampage left multiple people dead, injured more than 140 people, and inflicted millions of dollars in damage to the Capitol. Then-Vice President Pence, Senators, and Representatives were all forced to halt their constitutional duties and flee the House and Senate chambers for safety.
        <Citation number={686} />
      </Typography>
      <Typography>
        Benjamin Franklin said, at the founding, that we have “[a] Republic”—“if [we] can keep it.” The events of January 6th exposed the fragility of those democratic institutions and traditions that we had perhaps come to take for granted. In response, the President of the United States and Congress have each made the judgment that access to this subset of presidential communication records is necessary to address a matter of great constitutional moment for the Republic. Former President Trump has given this court no legal reason to cast aside President Biden’s assessment of the Executive Branch interests at stake, or to create a separation of powers conflict that the Political Branches have avoided.
        <Citation number={687} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Several other witnesses have also avoided testifying in whole or in part by asserting Executive Privilege or Absolute Immunity from any obligation to appear before Congress. For example, the President’s Chief of Staff Mark Meadows invoked both, and categorically refused to testify, even about text messages he provided to the Committee. The House of Representatives voted to hold him in criminal contempt.
      <Citation number={688} />
      Although the Justice Department has taken the position in litigation that a former high level White House staffer for a former President is not entitled to absolute immunity,
      <Citation number={689} />
      and that any interests in the confidentiality of his communications with President Trump and others are overcome in this case, the Justice Department declined to prosecute Meadows for criminal contempt. The reasons for Justice’s refusal to do so are not apparent to the Committee.
      <Citation number={690} />
      Commentators have speculated that Meadows may be cooperating in the Justice Department’s January 6th investigation.
      <Citation number={691} />
      The same may be true for Daniel Scavino, President Trump’s White House Deputy Chief of Staff for Communications and Director of Social Media, whom the House also voted to hold in contempt.
      <Citation number={692} />
    </Typography>

    <Typography className="indent">
      Steve Bannon also chose not to cooperate with the Committee, and the Justice Department prosecuted him for contempt of Congress.
      <Citation number={693} />
      Bannon has been sentenced and is currently appealing his conviction. Peter Navarro, another White House Staffer who refused to testify, is currently awaiting his criminal trial.
      <Citation number={694} />
    </Typography>

    <Typography className="indent">
      Although the Committee issued letters and subpoenas to seven Republican members of Congress who have unique knowledge of certain developments on or in relation to January 6th, none agreed to participate in the investigation; none considered themselves obligated to comply with the subpoenas. A number of these same individuals were aware well in advance of January 6th of the plotting by Donald Trump, John Eastman, and others to overturn the election, and certain of them had an active role in that activity.
      <Citation number={695} />
      None seem to have alerted law enforcement of this activity, or of the known risk of violence. On January 5th, after promoting unfounded objections to election results, Rep. Debbie Lesko appears to have recognized the danger in a call with her colleagues:
    </Typography>

    <Blockquote>
      <Typography>
        I also ask leadership to come up with a safety plan for Members [of Congress].... We also have, quite honestly, Trump supporters who actually believe that we are going to overturn the election, and when that doesn’t happen—most likely will not happen—they are going to go nuts.
        <Citation number={696} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      During our hearings, the Committee presented the testimony of numerous White House witnesses who testified about efforts by certain Republican Members of Congress to obtain Presidential pardons for their conduct in connection with January 6th.
      <Citation number={697} />
      Cassidy Hutchinson provided extensive detail in this regard:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Vice Chair Cheney</strong>: And are you aware of any members of Congress seeking pardons?
      </Typography>
      <Typography>
        <strong>Hutchinson</strong>: I guess Mr. Gaetz and Mr. Brooks, I know, have both advocated for there'd be a blanket pardon for members involved in that meeting, and a — a handful of other members that weren't at the December 21st meeting as the presumptive pardons. Mr. Gaetz was personally pushing for a pardon, and he was doing so since early December.
      </Typography>
      <Typography>
        I’m not sure why Mr. Gaetz would reach out to me to ask if he could have a meeting with Mr. Meadows about receiving a presidential pardon.
      </Typography>
      <Typography>
        <strong>Vice Chair Cheney</strong>: Did they all contact you?
      </Typography>
      <Typography>
        <strong>Hutchinson</strong>: Not all of them, but several of them did.
      </Typography>
      <Typography>
        <strong>Vice Chair Cheney</strong>: So, you mentioned Mr. Gaetz, Mr. Brooks.
      </Typography>
      <Typography>
        <strong>Hutchinson</strong>: Mr. Biggs did. Mr. Jordan talked about Congressional pardons, but he never asked me for one. It was more for an update on whether the White House was going to pardon members of Congress. Mr. Gohmert asked for one as well. Mr. Perry asked for a pardon, too. I’m sorry.
      </Typography>
      <Typography>
        <strong>Vice Chair Cheney</strong>: Mr. Perry? Did he talk to you directly?
      </Typography>
      <Typography>
        <strong>Hutchinson</strong>: Yes, he did.
      </Typography>
      <Typography>
        <strong>Vice Chair Cheney</strong>: Did Marjorie Taylor Greene contact you?
      </Typography>
      <Typography>
        <strong>Hutchinson</strong>: No, she didn't contact me about it. I heard that she had asked White House Counsel’s Office for a pardon from Mr. Philbin, but I didn't frequently communicate with Ms. Greene.
        <Citation number={698} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Many of these details were also corroborated by other sources. President Personnel Director Johnny McEntee confirmed that he was personally asked for a pardon by Representative Matt Gaetz (R-FL).
      <Citation number={699} />
      Eric Herschmann recalled that Representative Gaetz “...asked for a very, very broad pardon.... And I said Nixon’s pardon was never nearly that broad.”
      <Citation number={700} />
      When asked about reporting that Representatives Mo Brooks and Andy Biggs also requested pardons, Herschmann did not reject either possibility out of hand, instead answering: “It’s possible that Representative Brooks or Biggs, but I don’t remember.”
      <Citation number={701} />
      The National Archives produced to the Select Committee an email from Representative Mo Brooks to the President’s executive assistant stating that “President Trump asked me to send you this letter” and “... pursuant to a request from Matt Gaetz” that recommended blanket Presidential pardons to every Member of Congress who objected to the electoral college votes on January 6th.
      <Citation number={702} />
    </Typography>

    <Typography className="indent">
      These requests for pardons suggest that the Members identified above were conscious of the potential legal jeopardy arising from their conduct. As noted infra 136, the Committee has referred a number of these individuals to the House Ethics Committee for their failure to comply with subpoenas, and believes that they each owe the American people their direct and unvarnished testimony.
    </Typography>

    <Typography className="indent">
      The Select Committee has also received a range of evidence suggesting specific efforts to obstruct the Committee’s investigation. Much of this evidence is already known by the Department of Justice and by other prosecutorial authorities. For example:
    </Typography>

    <StyledListContainer>
      <ol>
        <li>
          <Typography>
            The Committee received testimony from a witness about her decision to terminate a lawyer who was receiving payments for the representation from a group allied with President Trump. Among other concerns expressed by the witness:
          </Typography>
          
          <StyledListContainer>
            <ul>
              <li>
                <Typography>
                  The lawyer had advised the witness that the witness could, in certain circumstances, tell the Committee that she did not recall facts when she actually did recall them.
                </Typography>
              </li>
              <li>
                <Typography>
                  During a break in the Select Committee’s interview, the witness expressed concerns to her lawyer that an aspect of her testimony was not truthful. The lawyer did not advise her to clarify the specific testimony that the witness believed was not complete and accurate, and instead conveyed that, “They don’t know what you know, [witness]. They don’t know that you can recall some of these things. So you saying ‘I don't recall’ is an entirely acceptable response to this.”
                </Typography>
              </li>
              <li>
                <Typography>
                  The lawyer instructed the client about a particular issue that would cast a bad light on President Trump: “No, no, no, no, no. We don’t want to go there. We don’t want to talk about that.”
                </Typography>
              </li>
              <li>
                <Typography>
                  The lawyer refused directions from the client not to share her testimony before the Committee with other lawyers representing other witnesses. The lawyer shared such information over the client’s objection.
                </Typography>
              </li>
              <li>
                <Typography>
                  The lawyer refused directions from the client not to share information regarding her testimony with at least one and possibly more than one member of the press. The lawyer shared the information with the press over her objection.
                </Typography>
              </li>
              <li>
                <Typography>
                  The lawyer did not disclose who was paying for the lawyers’ representation of the client, despite questions from the client seeking that information, and told her, “we’re not telling people where funding is coming from right now.”
                </Typography>
              </li>
              <li>
                <Typography>
                  The client was offered potential employment that would make her “financially very comfortable” as the date of her testimony approached by entities apparently linked to Donald Trump and his associates. Such offers were withdrawn or did not materialize as reports of the content of her testimony circulated. The client believed this was an effort to impact her testimony.
                </Typography>
              </li>
            </ul>
          </StyledListContainer>

          <Typography>
            Further details regarding these instances will be available to the public when transcripts are released.
          </Typography>
        </li>


        <li>
          <Typography>
            Similarly, the witness testified that multiple persons affiliated with President Trump contacted her in advance of the witness’s testimony and made the following statements:
          </Typography>

          <Blockquote>
            <Typography>
              What they said to me is, as long as I continue to be a team player, they know that I am on the right team. I am doing the right thing. I am protecting who I need to protect. You know, I will continue to stay in good graces in Trump World. And they have reminded me a couple of times that Trump does read transcripts and just keep that in mind as I proceed through my interviews with the committee.
            </Typography>
          </Blockquote>

          <Typography>
            Here is another sample in a different context. This is a call received by one of our witnesses:
          </Typography>

          <Blockquote>
            <Typography>
              [A person] let me know you have your deposition tomorrow. He wants me to let you know he’s thinking about you. He knows you’re a team player, you’re loyal, and you’re going do the right thing when you go in for your deposition.
              <Citation number={703} />
            </Typography>
          </Blockquote>
        </li>

        <li>
          <Typography>
            The Select Committee is aware of multiple efforts by President Trump to contact Select Committee witnesses. The Department of Justice is aware of at least one of those circumstances.
          </Typography>
        </li>

        <li>
          <Typography>
            Rather than relying on representation by Secret Service lawyers at no cost, a small number of Secret Service agents engaged private counsel for their interviews before the Committee.
            <Citation number={704} />
            During one such witness’s transcribed interview, a retained private counsel was observed writing notes to the witness regarding the content of the witness’s testimony while the questioning was underway. The witness’s counsel admitted on the record that he had done so.
            <Citation number={705} />
          </Typography>
        </li>

      </ol>
    </StyledListContainer>

    <Typography className="indent">
      Recently, published accounts of the Justice Department’s Mar-a-Lago investigation suggest that the Department is investigating the conduct of counsel for certain witnesses whose fees are being paid by President Trump’s Save America Political Action Committee.
      <Citation number={706} />
      The public report implies the Department is concerned that such individuals are seeking to influence the testimony of the witnesses they represent.
      <Citation number={707} />
      This Committee also has these concerns, including that lawyers who are receiving such payments have specific incentives to defend President Trump rather than zealously represent their own clients. The Department of Justice and the Fulton County District Attorney have been provided with certain information related to this topic.
    </Typography>

    <Typography className="indent">
      The Select Committee recognizes of course that most of the testimony we have gathered was given more than a year after January 6th. Recollections are not perfect, and the Committee expects that different accounts of the same events will naturally vary. Indeed, the lack of any inconsistencies in witness accounts would itself be suspicious. And many witnesses may simply recall different things than others.
    </Typography>

    <Typography className="indent">
      Many of the witnesses before this Committee had nothing at all to gain from their testimony, gave straightforward responses to the questions posted, and made no effort to downplay, deflect, or rationalize. Trump Administration Justice Department officials such as Attorney General Barr, Acting Attorney General Rosen, and Acting Deputy Attorney General Donoghue are good examples. Multiple members of President Trump’s White House staff were also suitably forthcoming, including Sarah Matthews, Matthew Pottinger, Greg Jacob, and Pat Philbin, as were multiple career White House, military and agency personnel whose names the Committee agreed not to disclose publicly; as were former Secretary of Labor Eugene Scalia, Bill Stepien, and certain other members of the Trump Campaign. The Committee very much appreciates the earnestness and bravery of Cassidy Hutchinson, Rusty Bowers, Shaye Moss, Ruby Freeman, Brad Raffensperger, Gabriel Sterling, Al Schmidt, and many others who provided important live testimony during the Committees hearings.
      <Citation number={708} />
    </Typography>

    <Typography className="indent">
      The Committee, along with our nation, offers particular thanks to Officers Caroline Edwards, Michael Fanone, Harry Dunn, Aquilino Gonell, and Daniel Hodges, along with hundreds of other members of law enforcement who defended the Capitol on that fateful day, all of whom should be commended for their bravery and sacrifice. We especially thank the families of Officer Brian Sicknick, Howard Liebengood and Jeffrey Smith, whose loss can never be repaid.
    </Typography>

    <Typography className="indent">
      The Committee very much appreciates the invaluable testimony of General Milley and other members of our military, Judge J. Michael Luttig, and the important contributions of Benjamin Ginsberg and Chris Stirewalt. This, of course is only a partial list, and the Committee is indebted to many others, as well.
    </Typography>

    <Typography className="indent">
      The Committee believes that White House Counsel Pat Cipollone gave a particularly important account of the events of January 6th, as did White House lawyer, Eric Herschmann. For multiple months, Cipollone resisted giving any testimony at all, asserting concerns about executive privilege and other issues, until after the Committee’s hearing with Hutchinson. When he did testify, Cipollone corroborated key elements of testimony given by several White House staff, including Hutchinson—most importantly, regarding what happened in the White House during the violence of January 6th—but also frankly recognized the limits on what he could say due to privilege: “Again, I’m not going to get into either my legal advice on matters, and the other thing I don’t want to do is, again, other witnesses have their own recollections of things.” Cipollone also told the Committee that, to the extent that other witnesses recall communications attributable to White House counsel that he does not, the communications might have been with his deputy Pat Philbin, or with Eric Herschmann, who had strong feelings and was particularly animated about certain issues.
      <Citation number={709} />
    </Typography>

    <Typography className="indent">
      Of course, that is not to say that all witnesses were entirely frank or forthcoming. Other witnesses, including certain witnesses from the Trump White House, displayed a lack of full recollection of certain issues, or were not otherwise as frank or direct as Cipollone. We cite two examples here, both relating to testimony played during the hearings.
    </Typography>

    <Typography className="indent">
      Kayleigh McEnany was President Trump’s Press Secretary on January 6th. Her deposition was taken early in the investigation. McEnany seemed to acknowledge that President Trump: (1) should have instructed his violent supporters to leave the Capitol earlier than he ultimately did on January 6th;
      <Citation number={710} />
      (2) should have respected the rulings of the courts;
      <Citation number={711} />
      and (3) was wrong to publicly allege that Dominion voting machines stole the election.
      <Citation number={712} />
      But a segment of McEnany’s testimony seemed evasive, as if she was testifying from pre-prepared talking points. In multiple instances, McEnany’s testimony did not seem nearly as forthright as that of her press office staff, who testified about what McEnany said.
    </Typography>

    <Typography className="indent">
      For example, McEnany disputed suggestions that President Trump was resistant to condemning the violence and urging the crowd at the Capitol to act peacefully when they crafted his tweet at 2:38 p.m. on January 6th.
      <Citation number={713} />
      Yet one of her deputies, Sarah Matthews, told the Select Committee that McEnany informed her otherwise: that McEnany and other advisors in the dining room with President Trump persuaded him to send the tweet, but that “...she said that he did not want to put that in and that they went through different phrasing of that, of the mention of peace, in order to get him to agree to include it, and that it was Ivanka Trump who came up with ‘stay peaceful’ and that he agreed to that phrasing to include in the tweet, but he was initially resistant to mentioning peace of any sort.”
      <Citation number={714} />
      When the Select Committee asked “Did Ms. McEnany describe in any way how resistant the President was to including something about being peaceful,” Matthews answered: “Just that he didn’t want to include it, but they got him to agree on the phrasing ‘stay peaceful.’”
      <Citation number={715} />
    </Typography>

    <Typography className="indent">
      The Committee invites the public to compare McEnany’s testimony with the testimony of Pat Cipollone, Sarah Matthews, Judd Deere, and others.
    </Typography>

    <Typography className="indent">
      Ivanka Trump is another example. Among other things, Ivanka Trump acknowledged to the Committee that: (1) she agreed with Attorney General Barr’s statements that there was no evidence of sufficient fraud to overturn the election; (2) the President and others are bound by the rulings of the courts and the rule of law; (3) President Trump pressured Vice President Pence on the morning of January 6th regarding his authorities at the joint session of Congress that day to count electoral votes; and (4) President Trump watched the violence on television as it was occurring.
      <Citation number={716} />
      But again, Ivanka Trump was not as forthcoming as Cipollone and others about President Trump’s conduct.
    </Typography>

    <Typography className="indent">
      Indeed, Ivanka Trump’s Chief of Staff Julie Radford had a more specific recollection of Ivanka Trump’s actions and statements. For example, Ivanka Trump had the following exchange with the Committee about her attendance at her father’s speech on January 6th that was at odds with what the Committee learned from Radford:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: It’s been reported that you ultimately decided to attend the rally because you hoped that you would calm the President and keep the event on an even keel. Is that accurate?
      </Typography>
      <Typography>
        <strong>Ivanka Trump</strong>: No. I don’t know who said that or where that came from.
        <Citation number={717} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      However, this is what Radford said about her boss’s decision:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: What did she share with you about why it was concerning that her father was upset or agitated after that call with Vice President Pence in relation to the Ellipse rally? Why did that matter? Why did he have to be calmed down, I should say.
      </Typography>
      <Typography>
        <strong>Radford</strong>: Well, she shared that he had called the Vice President a not—an expletive word. I think that bothered her. And I think she could tell based on the conversations and what was going on in the office that he was angry and upset and people were providing misinformation. And she felt like she might be able to help calm the situation down, at least before he went on stage.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: And the word that she relayed to you that the President called the Vice President—apologize for being impolite— but do you remember what she said her father called him?
      </Typography>
      <Typography>
        <strong>Radford</strong>: The “P” word.
        <Citation number={718} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      When the Committee asked Ivanka Trump whether there were “[a]ny particular words that you recall your father using during the conversation” that morning with Vice President Pence, she answered simply: “No.”
      <Citation number={719} />
    </Typography>

    <Typography className="indent">
      In several circumstances, the Committee has found that less senior White House aides had significantly better recollection of events than senior staff purported to have.
    </Typography>

    <Typography className="indent">
      The Select Committee also has concerns regarding certain other witnesses, including those who still rely for their income or employment on organizations linked to President Trump, such as the America First Policy Institute. Certain witnesses and lawyers were unnecessarily combative, answered hundreds of questions with variants of “I do not recall” in circumstances where that answer seemed unbelievable, appeared to testify from lawyer-written talking points rather than their own recollections, provided highly questionable rationalizations or otherwise resisted telling the truth. The public can ultimately make its own assessment of these issues when it reviews the Committee transcripts and can compare the accounts of different witnesses and the conduct of counsel.
    </Typography>

    <Typography className="indent">
      One particular concern arose from what the Committee realized early on were a number of intentional falsehoods in former White House Chief of Staff Mark Meadows’s December 7, 2021 book, The Chief’s Chief.
      <Citation number={720} />
      Here is one of several examples: Meadows wrote, “When he got offstage, President Trump let me know that he had been speaking metaphorically about going to the Capitol.”
      <Citation number={721} />
      Meadows goes on in his book to claim that it “was clear the whole time” President Trump didn’t intend to go to the Capitol.
      <Citation number={722} />
      This appeared to be an intentional effort to conceal the facts. Multiple witnesses directly contradicted Meadows’s account about President Trump’s desire to travel to the Capitol, including Kayleigh McEnany, Cassidy Hutchinson, multiple Secret Service agents, a White House employee with national security responsibilities and other staff in the White House, a member of the Metropolitan Police and others. This and several other statements in the Meadows book were false, and the Select Committee was concerned that multiple witnesses might attempt to repeat elements of these false accounts, as if they were the party line. Most witnesses did not, but a few did.
    </Typography>

    <Typography className="indent">
      President Trump’s desire to travel to the Capitol was particularly important for the Committee to evaluate because it bears on President Trump’s intent on January 6th. One witness account suggests that President Trump even wished to participate in the electoral vote count from the House floor, standing with Republican Congressmen, perhaps in an effort to apply further pressure to Vice President Mike Pence and others.
      <Citation number={723} />
    </Typography>

    <Typography className="indent">
      Mark Meadows’s former Deputy Chief of Staff for Operations Anthony Ornato gave testimony consistent with the false account in Meadows book. In particular, Ornato told the Committee that he was not aware of a genuine push by the President to go to the Capitol, suggesting instead that “it was one of those hypotheticals from the good idea fairy... [b]ecause it’s ridiculous to think that a President of the United States can travel especially with, you know, people around just on the street up to the Capitol and peacefully protest outside the Capitol....”
      <Citation number={724} />
      He told the Select Committee that the only conversation he had about the possibility of the President traveling to the Capitol was in a single meeting officials from the President’s advance team,
      <Citation number={725} />
      and his understanding is that this idea “wasn’t from the President.”
      <Citation number={726} />
      Two witnesses before the Committee, including a White House employee with national security responsibilities and Hutchinson, testified that Ornato related an account of President Trump’s “irate” behavior when he was told in the Presidential SUV on January 6th that he would not be driven to the Capitol.
      <Citation number={727} />
      Both accounts recall Ornato doing so from his office in the White House, with another member of the Secret Service present.
      <Citation number={728} />
      Multiple other witness accounts indicate that the President genuinely was “irate,” “heated,” “angry,” and “insistent” in the Presidential vehicle.
      <Citation number={729} />
      But Ornato professed that he did not recall either communication, and that he had no knowledge at all about the President’s anger.
      <Citation number={730} />
    </Typography>

    <Typography className="indent">
      Likewise, despite a significant and increasing volume of intelligence information in the days before January 6th showing that violence at the Capitol was indeed possible or likely, and despite other intelligence and law enforcement agencies similar conclusions,
      <Citation number={731} />
      Ornato claimed never to have reviewed or had any knowledge of that specific information
      <Citation number={732} />
      He testified that he was only aware of warnings that opposing groups might “clash on the Washington Monument” and that is what he “would have briefed to [Chief of Staff] Meadows.”
      <Citation number={733} />
      The Committee has significant concerns about the credibility of this testimony, including because it was Ornato’s responsibility to be aware of this information and convey it to decisionmakers.
      <Citation number={734} />
      The Committee will release Ornato’s November Transcript so the public can review his testimony on these topics.
    </Typography>
  </Page>
)
