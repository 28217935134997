import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet3'));

export const Chapter11 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      President Trump’s “Big Lie” on election night was based on simple differences in how Americans vote. In 2020, it was well-known that Democrats were much more likely to vote via mail-in ballots than in person in 2020. On the other hand, Republicans generally preferred to vote in person on election day.
      <Citation number={9} />
      In key swing States with tight margins between the candidates, the election day votes would favor President Trump and disproportionately be counted first. Mail-in ballots, which would favor former Vice President Biden, would disproportionately be counted later. In some States it would take days to process the remaining mail-in ballots.
    </Typography>

    <Typography className="indent">
      The timing of how votes are counted created the potential for what is known as a “Red Mirage”—or an illusion of a Republican (Red) victory in the early stages of vote counting. President Trump would appear to be in the lead on election night, but this was not the whole picture. Many mail-in votes for former Vice President Biden would not be counted on election day. Therefore, the actual winner would likely not be known on election night.
    </Typography>

    <Image
      src="/images/image-027.jpg"
      alt="unknown, testifying"
      credit="(Photo by House Creative Services)"
    />

    <Typography className="indent">
      The “Red Mirage” phenomenon was widely known prior to the 2020 presidential election. Chris Stirewalt was the head of the Fox News elections desk that correctly called Arizona for Biden. Stirewalt and his team tried to warn viewers of the Red Mirage. He testified that over the past 40 or 50 years, “Americans have increasingly chosen to vote by mail or early or absentee,” and that “Democrats prefer that method of voting more than Republicans do.”
      <Citation number={10} />
      In nearly “every election,” Stirewalt elaborated, “Republicans win Election Day and Democrats win the early vote, and then you wait and start counting.” It “[h]appens every time.”
      <Citation number={11} />
    </Typography>

    <Typography className="indent">
      President Trump’s campaign team made sure the President was briefed on the timing of vote tallying. Stepien, his campaign manager, told the Select Committee that President Trump was reminded on election day that large numbers of mail-in ballots would still remain to be counted over the coming days.
      <Citation number={12} />
      Stepien added that he personally reminded the President that while early returns may be favorable, the counting would continue: “I recounted back to 2016 when I had a very similar conversation with him on election day... I recounted back to that conversation with him in which I said, just like I said in 2016 was going to be a long night, I told him in 2020 that, you know, it was going to be a process again, as, you know, the early returns are going to be positive. Then we’re going to, you know, be watching the returns of ballots as, you know, they rolled in thereafter.”
      <Citation number={13} />
    </Typography>

    <Typography className="indent">
      Ordinarily, the “Red Mirage” anomaly does not create problems in the election process because candidates wait for the votes to be tallied before declaring victory or conceding. As Stirewalt emphasized, prior to President Trump, “no candidate had ever tried to avail themselves of this quirk in the election counting system.”
      <Citation number={14} />
    </Typography>

    <Typography className="indent">
      President Trump, however, made a different choice. In an extraordinary breach of the American democratic process, he decided to exploit the potential for confusion about the staggered timing of vote counting to deceive the American public about the election results. He and his allies foreshadowed this decision in their statements in the months leading up to the November 2020 election.
    </Typography>
  </Page>
)
