import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DarkMode from '@mui/icons-material/DarkMode';
import MenuIcon from '@mui/icons-material/Menu';
import TocIcon from '@mui/icons-material/Toc';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ToggleButton from '@mui/material/ToggleButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { DarkModeContext } from './AppThemeProvider';
import { PdfViewer } from './PdfViewer';
import { ReportDialog } from './ReportDialog';
import { Toc } from './Toc';

const StyledLink: any = styled(Link)(({ theme }) =>`
  color: ${theme.palette.common.white};
`);

const StyledLogo: any = styled(Typography)(({ theme }) =>`
  font-size: .75rem;
  line-height: 1em;

  ::after {
    content: "J6 Report";
    font-weight: bold;
  }
`);

const StyledBox: any = styled(Box)(({ theme }) =>`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
`);

const StyledToggleButton: any = styled(ToggleButton)(({ theme }) => `
  padding: ${theme.spacing(.5)};
  color: inherit;
`);

const StyledToggleLabel: any = styled(Typography)(({ theme }) => `
  margin: 0 ${theme.spacing(1)};
  font-size: .8em;

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`);

const NavTooltip = ({ route }: any) => (
  <Typography variant="caption">
    {
      route?.subtitle && (
        <>
          {route?.subtitle}
          <br />
        </>
      )
    }

    {route?.primary}
  </Typography>
)

export const Header = ({ prev, next, sourcePage }: any) => {
  const [darkMode, setDarkMode] = useContext(DarkModeContext);
  const [openIssueDialog, setOpenIssueDialog] = useState(false);
  const [openSourceDialog, setOpenSourceDialog] = useState(false);
  const [openToc, setOpenToc] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<any>(null);

  const closeMenu = () => setMenuAnchor(null);

  return (
    <AppBar position="static" color="secondary">
      <Container maxWidth="xl">
        <Toolbar variant="dense" style={{ justifyContent: 'space-between', minHeight: '40px', padding: '0' }}>
          <StyledBox>
            <StyledLogo component="span" />

            <StyledLink to={prev?.route} style={{ pointerEvents: !prev ? 'none' : 'auto'}}>
              <IconButton disabled={!prev} color="inherit" size="small">
                <Tooltip
                  title={<NavTooltip route={prev} />}
                  enterDelay={1000}
                  placement="right-end"
                  disableInteractive={true}
                  slotProps={{ tooltip: { style: { maxWidth: "none" }}}}
                >
                  <NavigateBeforeIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            </StyledLink>

            <Tooltip
              title="Table of Contents"
              enterDelay={1000}
              placement="right-end"
              disableInteractive={true}
            >
              <IconButton onClick={() => setOpenToc(s => !s)} color="inherit" size="small" >
                <TocIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <StyledLink to={next?.route} style={{ pointerEvents: !next ? 'none' : 'auto'}}>
              <IconButton color="inherit" disabled={!next} size="small">
                <Tooltip
                  title={<NavTooltip route={next} />}
                  enterDelay={1000}
                  placement="right-end"
                  disableInteractive={true}
                  slotProps={{ tooltip: { style: { maxWidth: "none" }}}}
                >
                  <NavigateNextIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            </StyledLink>
          </StyledBox>

          <StyledBox sx={(theme: any) => ({ gap: theme.spacing(2) })}>
            <StyledToggleButton
              size="small"
              selected={darkMode}
              value={!darkMode}
              onChange={(_: any, newMode: any) => setDarkMode(newMode)}
            >
              <StyledToggleLabel component="span">
                Dark Mode
              </StyledToggleLabel>

              <DarkMode fontSize="small" />
            </StyledToggleButton>

            <IconButton onClick={(e: any) => setMenuAnchor(e.currentTarget)} color="inherit" size="small" >
              <MenuIcon fontSize="small" />
            </IconButton>

            <Menu
              anchorEl={menuAnchor}
              open={!!menuAnchor}
              onClose={closeMenu}
              MenuListProps={{
                dense: true
              }}
            >
              <MenuItem onClick={() => {
                setOpenIssueDialog(true);
                closeMenu();
              }}>
                <ListItemText>Report an Issue</ListItemText>
              </MenuItem>
             <MenuItem onClick={() => {
                setOpenSourceDialog(true);
                closeMenu();
              }}>
                <ListItemText>View Source</ListItemText>
              </MenuItem>
            </Menu>

            <ReportDialog open={openIssueDialog} close={() => setOpenIssueDialog(false)} />
            <PdfViewer
              file="/source.pdf"
              external="https://www.govinfo.gov/content/pkg/GPO-J6-REPORT/pdf/GPO-J6-REPORT.pdf"
              page={sourcePage}
              open={openSourceDialog}
              close={() => setOpenSourceDialog(false)}
            />
          </StyledBox>
        </Toolbar>
      </Container>

      <Toc open={openToc} setOpen={setOpenToc} />
    </AppBar>
  );
}
