import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet5'));

export const Chapter34 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      The Trump team’s fake elector plan raised concerns not just for several senior officials but also for some of the Republican activists being recruited to be the fake electors. Findlay told the Select Committee that “there were definitely electors in probably most of the States that had concerns about thisprocess.”
      <Citation number={80} />
      After being tasked with reaching out to the potential fake electors, Findlay notified his colleagues on December 10th that “a lot of questions are arising” from them.
      <Citation number={81} />
      He also noted that an RNC staffer seconded to the Trump Victory Committee “requested a call with the PA electors and/or leadership to address concerns,” which “may be necessary to get people to appear.”
      <Citation number={82} />
    </Typography>

    <Typography className="indent">
      The Republican Party of Pennsylvania’s general counsel relayed several specific concerns to the Trump Campaign via email on December 13th. Warning that “[w]e’re all getting call [<i>sic</i>] from concerned Electors,” he elaborated as follows:
    </Typography>

    <Blockquote>
      <Typography>
        I’m told that on the call with the Electors they were told that the Ballot form would be conditioned upon ultimate certification by the Governor, indemnification by the campaign if someone gets sued or worse, (charged with something by the AG or someone else), and the receipt by the Electors of a legal opinion by a national firm and certified to be accurate by a Pa. lawyer.
      </Typography>
      <Typography>
        What was sent was a “memo” by Chesebro not addressed to the Electors, and no certification by a Pa. lawyer. To make it worse, Chesebro describes the Pa. plan as “dicey”. And there’s no indication by anyone with authority that there’s any indemnification authorized by the campaign.
        <Citation number={83} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Pennsylvania GOP Chairman Lawrence Tabas informed the Select Committee that his State’s fake electors never were indemnified by the Trump Campaign.
      <Citation number={84} />
    </Typography>

    <Typography className="indent">
      When Wisconsin Republican Party Chairman Andrew Hitt was notified in late November that “the campaign wants to [<i>sic</i>] list of electors,” he texted his executive director that “I am def concerned about their inquiry” and that “I hope they are not planning on asking us to do anything like try and say we are only the proper electors.”
      <Citation number={85} />
      On December 12th, after Hitt received a message about a phone call with Giuliani to discuss the fake elector issue, he texted a colleague: “These guys are up to no good and its [<i>sic</i>] gonna fail miserably.”
      <Citation number={86} />
      Despite such concerns, Hitt and many other fake electors participated anyway.
      <Citation number={87} />
    </Typography>

    <Typography className="indent">
      Even so, 14 of the original Republicans who had been listed as electoral college nominees on the November ballot bowed out when the fake Trump electors gathered in December.
      <Citation number={88} />
      Former Michigan Secretary of State Terri Lynn Land declined to attend, which the State’s GOP chair, Laura Cox, told the Select Committee was because “I think she just said she was uncomfortable with the whole thing” and that she “has her own beliefs.”
      <Citation number={89} />
      A senior advisor for the Pennsylvania GOP said that Chairman Tabas “did not serve as an elector because Joe Biden won the election and it was Biden’s electors that were certified.”
      <Citation number={90} />
      Former U.S. Representative Tom Marino (R-PA) said he backed out because “I’m a constitutionalist,” and “as a former prosecutor, when the attorney general says that he’s not finding anything there, that’s good enough for me.”
      <Citation number={91} />
      The other eleven dropouts included a Georgia State lawmaker, a former State party chair from New Mexico, two former State party chairs from Pennsylvania, and Pennsylvania’s RNC national committeewoman.
      <Citation number={92} />
    </Typography>

    <Typography className="indent">
      Other participants asserted that they would have had much greater concerns if the Trump team had been more forthcoming about how the fake electoral votes would be used.
      <Citation number={93} /> The Trump Campaign’s director of election day operations in Georgia told the Select Committee that “I absolutely would not have” wanted to participate in organizing the Trump team’s fake electors in Georgia “had I known that the three main lawyers for the campaign that I’d spoken to in the past and were leading up were not on board.”
      <Citation number={94} />
      He said he felt “angry” because “no one really cared if—if people were potentially putting themselves in jeopardy” by doing this, and “we were just... useful idiots or rubes at that point.”
      <Citation number={95} />
    </Typography>
  </Page>
)
