import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet9'));

export const Chapter711 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      After leaving the Rose Garden, the President returned to the dining room. At 6:01 p.m., he issued another tweet, the last of the day:
    </Typography>

    <Blockquote>
      <Typography>
       These are the things and events that happen when a sacred landslide election victory is so unceremoniously & viciously stripped away from great patriots who have been badly & unfairly treated for so long. Go home with love & in peace. Remember this day forever!
       <Citation number={287} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      He retired to his residence for the evening at 6:27 p.m.
      <Citation number={288} />
      A White House photographer captured the President walking back to the residence with an employee in tow, carrying personal items President Trump wished to bring home with him for the night.
      <Citation number={289} />
      In the employee’s hands are the gloves the President was wearing while addressing the crowd at the Ellipse.
      <Citation number={290} />
    </Typography>

    <Typography className="indent">
      The President had one parting comment to the employee—the thing that was evidently occupying his mind even after an afternoon of violence—before he retired to his home.
    </Typography>

    <Typography className="indent">
      “Mike Pence let me down,” the President concluded.
      <Citation number={291} />
    </Typography>
  </Page>
)
