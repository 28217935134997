import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../components/Blockquote';
import { Page } from '../../components/Page';

const Citation = lazy(() => import('../Citations/CitationSheet2'));

export const SummaryCreation = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      In the week after January 6th, House Republican Leader Kevin McCarthy initially supported legislation to create a bipartisan commission to investigate the January 6th attack on the United States Capitol, stating that “the President bears responsibility for Wednesday’s attack on Congress by mob rioters” and calling for creation of a “fact-finding commission.”
      <Citation number={735} />
      Leader McCarthy repeated his support for a bipartisan commission during a press conference on January 21st: “The only way you will be able to answer these questions is through a bipartisan commission.”
      <Citation number={736} />
    </Typography>

    <Typography className="indent">
      On February 15th, House Speaker Nancy Pelosi announced in a letter to the House Democratic Caucus her intent to establish the type of independent commission McCarthy had supported, to “investigate and report on the facts and causes relating to the January 6, 2021 domestic terrorist attack upon the United States Capitol Complex.”
      <Citation number={737} />
      A few days thereafter, Leader McCarthy provided the Speaker a wish list that mirrored “suggestions from the Co-Chairs of the 9/11 Commission” that he and House Republicans hoped would be included in the House’s legislation to establish the Commission.
      <Citation number={738} />
    </Typography>

    <Typography className="indent">
      In particular, Leader McCarthy requested an equal ratio of Democratic and Republican nominations, equal subpoena power for the Democratic Chair and Republican Vice Chair of the Commission, and the exclusion of predetermined findings or outcomes that the Commission itself would produce. Closing his letter, Leader McCarthy quoted the 9/11 Commission Co-Chairs, writing that a “bipartisan independent investigation will earn credibility with the American public.”
      <Citation number={739} />
      He again repeated his confidence in achieving that goal.
      <Citation number={740} />
      In April 2021, Speaker Pelosi agreed to make the number of Republican and Democratic Members of the Commission equal, and to provide both parties with an equal say in subpoenas, as McCarthy had requested.
      <Citation number={741} />
    </Typography>

    <Typography className="indent">
      In May 2021, House Homeland Security Committee Chairman Bennie G. Thompson began to negotiate more of the details for the Commission with his Republican counterpart, Ranking Member John Katko.
      <Citation number={742} />
      On May 14th, Chairman Thompson announced that he and Ranking Member Katko had reached an agreement on legislation to “form a bipartisan, independent Commission to investigate the January 6th domestic terrorism attack on the United States Capitol and recommend changes to further protect the Capitol, the citadel of our democracy.”
      <Citation number={743} />
    </Typography>

    <Typography className="indent">
      On May 18th, the day before the House’s consideration of the Thompson-Katko agreement, Leader McCarthy released a statement in opposition to the legislation.
      <Citation number={744} />
      Speaker Pelosi responded to that statement, saying: “Leader McCarthy won’t take yes for an answer.”
      <Citation number={745} />
      The Speaker referred to Leader McCarthy’s February 22nd letter where “he made three requests to be addressed in Democrats’ discussion draft.”
      <Citation number={746} />
      She noted that “every single one was granted by Democrats, yet he still says no.”
      <Citation number={747} />
    </Typography>

    <Typography className="indent">
      In the days that followed, Republican Ranking Member Katko defended the bipartisan nature of the bill to create the Commission:
    </Typography>

    <Blockquote>
      <Typography>
        As I have called for since the days just after the attack, an independent, 9/11-style review is critical for removing the politics around January 6 and focusing solely on the facts and circumstances of the security breach at the Capitol, as well as other instances of violence relevant to such a review. Make no mistake about it, Mr. Thompson and I know this is about facts. It’s not partisan politics. We would have never gotten to this point if it was about partisan politics.
        <Citation number={748} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      That evening, the House passed the legislation to establish a National Commission to Investigate the January 6th Attack on the United States Capitol Complex in a bipartisan fashion, with 35 Republicans joining 217 Democrats voting in favor and 175 Republicans voting against.
      <Citation number={749} />
      In the days thereafter, however, only six Senate Republicans joined Senate Democrats in supporting the legislation, killing the bill in the Senate.
      <Citation number={750} />
    </Typography>

    <Typography className="indent">
      On June 24th, Speaker Pelosi announced her intent to create a House select committee to investigate the attack.
      <Citation number={751} />
      On June 25th, Leader McCarthy met with DC Metropolitan Police Officer Michael Fanone, who was seriously injured on January 6th.
      <Citation number={752} />
      Officer Fanone pressed Leader McCarthy “for a commitment not to put obstructionists and the wrong people in that position.”
      <Citation number={753} />
    </Typography>

    <Typography className="indent">
      On June 30th, the House voted on H. Res. 503 to establish a 13-Member Select Committee to Investigate the January 6th Attack on the United States Capitol by a vote of 222 Yeas and 190 Nays with just two Republicans supporting the measure: Representative Liz Cheney and Representative Adam Kinzinger.
      <Citation number={754} />
      On July 1st, Speaker Pelosi named eight initial Members to the Select Committee, including one Republican: Representative Cheney.
      <Citation number={755} />
    </Typography>

    <Typography className="indent">
      On July 17th, Leader McCarthy proposed his selection of five members:
    </Typography>

    <Blockquote>
      <Typography>
        Representative Jim Jordan, Ranking Member of the House Judiciary Committee;
      </Typography>
      <Typography>
        Representative Kelly Armstrong of North Dakota; House Energy and Commerce Committee;
      </Typography>
      <Typography>
        Representative Troy Nehls, House Transportation & Infrastructure and Veterans’ Affairs Committees.
      </Typography>
      <Typography>
        Representative Jim Banks, Armed Services, Veterans’ Affairs and Education and Labor Committees;
      </Typography>
      <Typography>
        Representative Rodney Davis, Ranking Member of the Committee on House Administration.
        <Citation number={756} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Jordan was personally involved in the acts and circumstances of January 6th, and would be one of the targets of the investigation. By that point, Banks had made public statements indicating that he had already reached his own conclusions and had no intention of cooperating in any objective investigation of January 6th, proclaiming, for example, that the Select Committee was created “...solely to malign conservatives and to justify the Left’s authoritarian agenda.”
      <Citation number={757} />
    </Typography>

    <Typography className="indent">
      On July 21st, Speaker Nancy Pelosi exercised her power under H. Res. 503 not to approve the appointments of Representatives Jordan or Banks, expressing “concern about statements made and actions taken by these Members” and “the impact their appointments may have on the integrity of the investigation.”
      <Citation number={758} />
      However, she also stated that she had informed Leader McCarthy “...that I was prepared to appoint Representatives Rodney Davis, Kelly Armstrong and Troy Nehls, and requested that he recommend two other Members.”
      <Citation number={759} />
    </Typography>

    <Typography className="indent">
      In response, Leader McCarthy elected to remove all five of his Republican appointments, refusing to allow Representatives Armstrong, Davis and Nehls to participate on the Select Committee.
      <Citation number={760} />
      On July 26, 2021, Speaker Pelosi then appointed Republican Representative Adam Kinzinger.
      <Citation number={761} />
      In resisting the Committee’s subpoenas, certain litigants attempted to argue that the Commission’s Select Committee’s composition violated House Rules or H. Res. 503, but those arguments failed in court.
      <Citation number={762} />
    </Typography>
  </Page>
)
