import { useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { alpha } from "@mui/material";
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { styled, useTheme } from '@mui/material/styles';

import TOC_DATA from '../toc.json';

const StyledNavLink = styled(NavLink)(({ theme }) => `
  color: ${theme.palette.text.primary};
  width: 100%;
  text-decoration: none;
`);

export const Toc = ({ open, setOpen }: any) => (
  <>
    <Drawer
      anchor='left'
      open={open}
      onClose={() => setOpen(false)}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <List
        sx={{ width: 300, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <>
            <ListSubheader component="div" id="nested-list-subheader" sx={{ textAlign: 'center' }}>
              Table of Contents
            </ListSubheader>
            <Divider />
          </>
        }
      >
        {
          TOC_DATA.map((section, index) => <Section section={section} layer={0} href="" key={index} onClose={() => setOpen(false)} /> )
        }
      </List>
    </Drawer>
  </>
);

export const Section = ({ section: { title, content, expanded, slug }, layer, href, onClose }: any) => {
  const headerPad = (layer) * 4 || 2;
  const itemPad = (layer * 2) + 4;
  const hrefChain = `${href}/${slug}`;

  const location = useLocation();
  const [expand, setExpand] = useState<boolean>(location.pathname.includes(hrefChain));

  return (
    <>
      {
        !content && (
          <SectionLink primary={title} to={hrefChain} pad={headerPad} onClose={onClose} />
        )
      }

      {
        content && (
          <>
            <ListItemButton dense={true}  sx={{ pl: headerPad, pr: headerPad }} onClick={() => setExpand(s => !s)}>
              <ListItemText primary={title} />
              {
                expand ? <ExpandLess /> : <ExpandMore />
              }
            </ListItemButton>

            <Collapse in={expand} timeout="auto">
              <List dense={true} disablePadding>
                {
                  content.map((props: any, index: any) => (
                    props.content
                      ? <Section section={props} layer={layer+1} href={hrefChain} key={index} onClose={onClose} />
                      : (
                        <SectionLink primary={props.primary} secondary={props.secondary} to={`${hrefChain}/${props.slug}`} pad={itemPad} onClose={onClose} key={index} />
                      )
                  ))
                }
              </List>
            </Collapse>
          </>
        )
      }
    </>
  );
}

export const SectionLink = ({ primary, secondary, to, onClose, pad }: any) => {
  const location = useLocation();
  const isActive = useMemo(() => location.pathname.includes(to), [location, to]);
  const activeRef: any = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    activeRef.current?.scrollIntoView({ block: 'center' });
  }, [activeRef]);

  return (
    <ListItemButton dense={true} sx={{ pl: 0, pr: 0, pt: 0, pb: 0 }} ref={isActive ? activeRef : null}>
      <StyledNavLink onClick={onClose} to={to} style={({ isActive }) => ({
        background: isActive ? alpha(theme.palette.secondary.light, .5) : 'inherit',
      })}>
        <ListItemText sx={{ pl: pad, pr: pad, pt: 1, pb: 1 }} primary={primary} secondary={secondary} />
      </StyledNavLink>
    </ListItemButton>
  );
}
