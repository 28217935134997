import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet2'));

export const AccountabilityIsCritical = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      To date, the Justice Department has pursued prosecution of hundreds of individuals who planned and participated in the January 6th invasion of and attack on our Capitol. But the Department has not yet charged individuals who engaged in the broader plan to overturn the election through the means discussed in this Report. The Committee has concluded that it is critical to hold those individuals accountable as well, including those who worked with President Trump to create and effectuate these plans.
    </Typography>

    <Typography className="indent">
      In his speech from the Ellipse on January 6th, President Trump recited a host of election fraud allegations he knew to be false, and then told tens of thousands of his angry supporters this:
    </Typography>

    <Blockquote>
      <Typography>
        And fraud breaks up everything, doesn’t it? When you catch somebody in a fraud, you’re allowed to go by very different rules. So I hope Mike has the courage to do what he has to do. And I hope he doesn’t listen to the RINOs and the stupid people that he’s listening to.
        <Citation number={655} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The meaning of President Trump’s comments was sufficiently clear then, but he recently gave America an even more detailed understanding of his state of mind. Trump wrote that allegations of “massive fraud” related to the 2020 election “allow[] for the termination of all rules, regulations and articles, even those found in the Constitution.”
      <Citation number={656} />
      And President Trump considered pardoning those involved in the attack and has since expressed a desire to pardon them—and even give them an apology—if he returns to the Oval Office.
      <Citation number={657} />
    </Typography>

    <Typography className="indent">
      In the Committee’s judgment, based on all the evidence developed, President Trump believed then, and continues to believe now, that he is above the law, not bound by our Constitution and its explicit checks on Presidential authority. This recent Trump statement only heightens our concern about accountability. If President Trump and the associates who assisted him in an effort to overturn the lawful outcome of the 2020 election are not ultimately held accountable under the law, their behavior may become a precedent, and invitation to danger, for future elections. A failure to hold them accountable now may ultimately lead to future unlawful efforts to overturn our elections, thereby threatening the security and viability of our Republic.
    </Typography>
  </Page>
)
