import { lazy } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Page } from '../../components/Page';

const Citation = lazy(() => import('../Citations/CitationSheet11'));

export const Recommendations = ({ ...args }: any) => (
  <Page {...args}>
    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4" color="primary">
        1. Electoral Count Act.
      </Typography>
    </Box>

    <Typography className="indent">
      As our Report describes, Donald J. Trump, John Eastman, and others corruptly attempted to violate the Electoral Count Act of 1887 in an effort to overturn the 2020 Presidential Election. To deter other future attempts to overturn Presidential Elections, the House of Representatives has passed H.R. 8873, “The Presidential Election Reform Act,” and the Senate should act promptly to send a bill with these principles to the President. H.R. 8873 reaffirms that a Vice President has no authority or discretion to reject an official electoral slate submitted by the Governor of a state. It also reforms Congress’s counting rules to help ensure that objections in the joint session conform to Congress’s narrow constitutional role under Article II and the Twelfth Amendment. It provides that presidential candidates may sue in federal court to ensure that Congress receives the state’s lawful certification, and leaves no doubt that the manner for selecting presidential electors cannot be changed retroactively after the election is over.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4" color="primary">
        2. Accountability.
      </Typography>
    </Box>

    <Typography className="indent">
      The Select Committee has made criminal referrals to the Department of Justice, and both the Department of Justice and other prosecutorial authorities will now make their determinations on whether to prosecute individuals involved in the events resulting in an attack on the United States Congress on January 6, 2021. Additional steps may also be appropriate to ensure criminal or civil accountability for anyone engaging in misconduct described in this Report. Those courts and bar disciplinary bodies responsible for overseeing the legal profession in the states and the District of Columbia should continue to evaluate the conduct of attorneys described in this Report. Attorneys should not have the discretion to use their law licenses to undermine the constitutional and statutory process for peacefully transferring power in our government. The Department of Justice should also take appropriate action to prevent its attorneys from participating in campaign-related activities, or (as described in this report) activities aimed at subverting the rule of law and overturning a lawful election. This report also identifies specific attorney conflicts of interest for the Department to evaluate.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4" color="primary">
        3. Violent Extremism.
      </Typography>
    </Box>

    <Typography className="indent">
      Federal Agencies with intelligence and security missions, including the Secret Service, should (a) move forward on whole-of-government strategies to combat the threat of violent activity posed by all extremist groups, including white nationalist groups and violent anti-government groups while respecting the civil rights and First Amendment civil liberties of all citizens; and (b) review their intelligence sharing protocols to ensure that threat intelligence is properly prioritized and shared with other responsible intelligence and security agencies on a timely basis in order to combat the threat of violent activity targeting legislative institutions, government operations, and minority groups.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4" color="primary">
        4. Fourteenth Amendment, Section 3.
      </Typography>
    </Box>

    <Typography className="indent">
      Under Section 3 of the Constitution’s Fourteenth Amendment, an individual who previously took an oath to support the Constitution of the United States, but who has “engaged in an insurrection” against the same, or given “aid or comfort to the enemies of the Constitution” can be disqualified from holding future federal or state office. The Select Committee has referred Donald Trump and others for possible prosecution under 18 U.S.C. 2383, including for assisting and providing aid and comfort to an insurrection. The Committee also notes that Donald J. Trump was impeached by a majority of the House of Representatives for Incitement of an Insurrection, and there were 57 votes in the Senate for his conviction. Congressional committees of jurisdiction should consider creating a formal mechanism for evaluating whether to bar those individuals identified in this Report under Section 3 of the 14th Amendment from holding future federal or state office. The Committee believes that those who took an oath to protect and defend the Constitution and then, on January 6th, engaged in insurrection can appropriately be disqualified and barred from holding government office—whether federal or state, civilian or military—absent at least two-thirds of Congress acting to remove the disability pursuant to Section 3 of the Fourteenth Amendment. The Committee notes that Ms. Wasserman Schultz and Mr. Raskin have introduced H. Con. Res. 93 to declare the January 6 assault an insurrection and H.R. 7906 to establish specific procedures and standards for disqualification under section 3 of the Fourteenth Amendment in the United States district court for the District of Columbia.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4" color="primary">
        5. National Special Security Event.
      </Typography>
    </Box>

    <Typography className="indent">
      Until January 6th, 2021, the joint session of Congress for counting electoral votes was not understood to pose the same types of security risks as other major events on Capitol Hill. Both the inaugural and the State of the Union have long been designated as National Special Security Events, requiring specific security measures and significant advance planning and preparation. Given what occurred in 2021, Congress and the Executive Branch should work together to designate the joint session of Congress occurring on January 6th as a National Special Security Event.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4" color="primary">
        6. To the extent needed, consider reforming certain criminal statutes, including to add more severe penalties.
      </Typography>
    </Box>

    <Typography className="indent">
      As indicated in the Report, the Committee believes that 18 U.S.C. § 1512(c)2 and other existing provisions of law can be applied to efforts to obstruct, influence, or impede the joint session on January 6th, including to related planning efforts to overturn the lawful election results on that date. To the extent that any court or any other prosecutorial authorities ultimately reach any differing conclusion, Congress should amend those statutes to cover such conduct. Congress should also consider whether the severity of penalties under those statutes is sufficient to deter unlawful conduct threatening the peaceful transfer of power.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4" color="primary">
        7. House of Representatives Civil Subpoena Enforcement Authority.
      </Typography>
    </Box>

    <Typography className="indent">
      The current authority of the House of Representatives to enforce its subpoenas through civil litigation is unclear. Congressional committees of jurisdiction should develop legislation to create a cause of action for the House of Representatives to enforce its subpoenas in federal court, either following the statutory authority that exists for the Senate in 2 U.S.C. § 288d and 28 U.S.C. § 1365 or adopting a broad approach to facilitate timely oversight of the executive branch.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4" color="primary">
        8. Threats to Election Workers.
      </Typography>
    </Box>

    <Typography className="indent">
      Congressional committees of jurisdiction should consider enhancing federal penalties for certain types of threats against persons involved in the election process and expanding protections for personally identifiable information of election workers.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4" color="primary">
        9. Capitol Police Oversight.
      </Typography>
    </Box>

    <Typography className="indent">
      Congressional committees of jurisdiction should continue regular and rigorous oversight of the United States Capitol Police as it improves its planning, training, equipping, and intelligence processes and practices its critical incident response protocols, both internally and with law enforcement partners. Joint hearings with testimony from the Capitol Police Board should take place. Full funding for critical security measures should be assured.
      <Citation number={1} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4" color="primary">
        10. Role of the Media.
      </Typography>
    </Box>

    <Typography className="indent">
      The Committee’s investigation has identified many individuals involved in January 6th who were provoked to act by false information about the 2020 election repeatedly reinforced by legacy and social media. The Committee agrees that individuals remain responsible for their own actions, including their own criminal actions. But congressional committees of jurisdiction should continue to evaluate policies of media companies that have had the effect of radicalizing their consumers, including by provoking people to attack their own country.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4" color="primary">
        11. Discussion of the Insurrection Act.
      </Typography>
    </Box>

    <Typography className="indent">
      The Committee has been troubled by evidence that President Trump’s possible use of the Insurrection Act was discussed by individuals identified in this Report. Congressional Committees of jurisdiction should further evaluate all such evidence, and consider risks posed for future elections.
    </Typography>
  </Page>
)
