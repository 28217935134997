import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet10'));

export const Chapter81 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      During the early morning hours of January 6th, tens of thousands of Americans from around the country began to gather at the Ellipse and the Washington Monument. They had come to hear President Trump speak and, more importantly, for his “wild” protest.
    </Typography>

    <Typography className="indent">
      Nick Quested, a documentary filmmaker, captured the mood that morning. Jacob Chansley (a.k.a. the QAnon Shaman) proclaimed “this is our 1776,” vowing “Joe Biden is never getting in.”
      <Citation number={7} />
      An unnamed woman from Georgia, who said she hosted a podcast dedicated to a new so-called Patriot Party, also proclaimed January 6th to be the new 1776. She added an ominous warning. “I’m not allowed to say what’s going to happen today because everyone’s just going to have to watch. Something’s gonna happen, one way or the other.”
      <Citation number={8} />
    </Typography>

    <Typography className="indent">
      The Secret Service set up magnetometers to screen for weapons and other contraband, but many rally-goers chose to avoid the screening altogether.
    </Typography>

    <Typography className="indent">
      At 6:29 a.m., Stewart Rhodes, the leader of the Oath Keepers, reminded his group’s members that DC prohibited blades over “3 inches” and encouraged them to “[k]eep [the knives] low profile.”
      <Citation number={9} />
      Others were thinking along the same lines. At 7:25 a.m., the National Park Service reported that a significant number of attendees ditched their bags in trees, rather than have them inspected.
      <Citation number={10} />
      Cassidy Hutchinson told the Select Committee she heard that thousands of people refused to walk through magnetometers to enter the Ellipse because they did not want to be screened for weapons.
      <Citation number={11} />
      According to Hutchinson, the Deputy Chief of Staff for Operations whose responsibilities included security-related issues, Tony Ornato, told the President that the onlookers “don’t want to come in right now. They—they have weapons that they don’t want confiscated by the Secret Service.”
      <Citation number={12} />
      When he arrived at the Ellipse that morning, President Trump angrily said: “I don’t [fucking] care that they have weapons. They’re not here to hurt me. They can march to the Capitol from here.”
      <Citation number={13} />
    </Typography>

    <Typography className="indent">
      Approximately 28,000 rally-goers did pass through the magnetometers. The Secret Service confiscated a significant number of prohibited items from these people, including: 269 knives or blades, 242 cannisters of pepper spray, 18 brass knuckles, 18 tasers, 6 pieces of body armor, 3 gas masks, 30 batons or blunt instruments, and 17 miscellaneous items like scissors, needles, or screwdrivers.
      <Citation number={14} />
    </Typography>

    <Typography className="indent">
      At 8:07 a.m., Secret Service countersurveillance agents reported that “members of the crowd are wearing ballistic helmets, body armor and carrying radio equipment and military grade backpacks.”
      <Citation number={15} />
      By 9:45 a.m., the Secret Service noted people openly carrying pepper spray as they strolled the streets.
      <Citation number={16} />
    </Typography>

    <Typography className="indent">
      President Trump’s mob was itching for a fight. National Park Service officers arrested a man who had entered the restricted area around the Washington Monument. Immediately, about 100 people started forming a circle around the officer, “threaten[ing] law enforcement,” as the officer later recounted.
      <Citation number={17} />
      The officer retreated into the Washington Monument with the man in custody.
      <Citation number={18} />
      The crowd responded angrily, punching the Monument’s glass windows and continuing to threaten officers.
      <Citation number={19} />
      Law enforcement around the Washington Monument felt so unsafe that they “locked themselves in a security box by the mall.”
      <Citation number={20} />
      Rioters nevertheless “scaled the sides of the security box and climbed on top of the structure.”
      <Citation number={21} />
      It was a harbinger of things to come.
    </Typography>

    <Typography className="indent">
      MPD monitored and responded to a stream of threats that morning. Three men in fatigues from Broward County, Florida brandished AR-15s in front of MPD officers on 14th Street and Independence Avenue.
      <Citation number={22} />
      MPD advised over the radio that one individual was possibly armed with a “Glock” at Fourteenth Street and Constitution Avenue, and another was possibly armed with a “rifle” at Fifteenth Street and Constitution Avenue around 11:23 a.m.
      <Citation number={23} />
      The National Park Service detained an individual with a rifle between 12:00 and 1:00 p.m.
      <Citation number={24} />
    </Typography>

    <Typography className="indent">
      Far-right extremists brought guns into Washington or the surrounding area. Christopher Kuehne, a member of the Proud Boys, met up with friends on January 5th to discuss their plans for the following day. One person in attendance said he did not travel to Washington just to “march around” and asked, “do we have patriots here willing to take it by force?”
      <Citation number={25} />
      Kuehne told them he had guns, and he was ready to go.
      <Citation number={26} />
      During the attack, Kuehne helped prop open Capitol blast doors as besieged law enforcement retreated inside.
      <Citation number={27} />
      Guy Reffitt, a Three Percenter from Texas, attended the rally at the Ellipse, and then carried a loaded firearm onto Capitol grounds.
      <Citation number={28} />
      Jerod Thomas Bargar lost his gun—that he’d carried from the Ellipse in a ‘We the People’ holster
      <Citation number={29} />
      —while scuffling with police on the west side of the Capitol around 2:30 p.m.
      <Citation number={30} />
      Bargar wanted to be armed, he said, when he went into the “belly of the beast.”
      <Citation number={31} />
    </Typography>

    <Typography className="indent">
      Mark Andre Mazza drove from Indiana, bringing a Taurus revolver, a .45-caliber weapon that he loaded with both shotgun and hollow-point rounds.
      <Citation number={32} />
      After assaulting a police officer, he lost the weapon,
      <Citation number={33} />
      dropping it or losing it on the steps of the lower West Plaza leading to the Capitol’s West Front Terrace.
      <Citation number={34} />
      The Select Committee reviewed Mazza’s social media accounts before they were taken down, finding that he shared multiple conspiracy theories, including QAnon material.
      <Citation number={35} />
      Mazza later indicated that he intended to target House Speaker Nancy Pelosi, telling authorities that “you’d be here for another reason” if he had found the Speaker inside the Capitol.
      <Citation number={36} />
    </Typography>

    <Typography className="indent">
      Lonnie Leroy Coffman from Falkville, Alabama, parked by the Capitol building before walking nearly 2 miles to the Ellipse to hear the President speak.
      <Citation number={37} />
      In his car, he had stocked a handgun, a rifle, a shotgun, hundreds of rounds of ammunition, large-capacity ammunition-feeding devices, machetes, camouflage smoke devices, a bow and arrow, and 11 Mason jars filled with gasoline and styrofoam, as well as rags and a lighter (tools needed to make Molotov cocktails).
      <Citation number={38} />
      Police found two more handguns on Coffman when he was arrested later that day.
      <Citation number={39} />
    </Typography>

    <Typography className="indent">
      Many in attendance were aware of Washington’s prohibition on carrying a concealed weapon and made plans accordingly. The Oath Keepers left their guns stowed away in their cars or across State lines for easy access should they be needed.
      <Citation number={40} />
      The group staged a “quick reaction force” across the river in Virginia, amassing an arsenal to come to DC “by land” or “by sea,” as Florida State-chapter lead—and defendant convicted of seditious conspiracy—Kelly Meggs said.
      <Citation number={41} />
      Oath Keeper Jason Dolan testified at the seditious conspiracy trial that the “quick reaction force [was] ready to go get our firearms in order to stop the election from being certified within Congress.”
      <Citation number={42} />
      Dolan further testified that the Oath Keepers came to Washington, DC “to stop the certification of the election.... [b]y any means necessary. That’s why we brought our firearms.”
      <Citation number={43} />
    </Typography>

    <Typography className="indent">
      Garret Miller—a January 6th defendant who traveled from Richardson, Texas—posted on Facebook that “he was bringing guns with him but ‘might just keep 1 hidden one and store the rest in Virginia’” after learning about the DC law.
      <Citation number={44} />
      He also threatened to assassinate Congresswoman Alexandria Ocasio-Cortez and predicted a “civil war could start.”
      <Citation number={45} />
    </Typography>

    <Typography className="indent">
      Many members of the crowd decided against bringing firearms into the nation’s capital, and armed themselves in other ways. Alex Kirk Harkrider from Carthage, Texas, and his co-defendant, Ryan Nichols, left guns in a parked car just outside the district before attending the rally.
      <Citation number={46} />
      Harkrider still brought a tomahawk axe.
      <Citation number={47} />
      During the march to the Capitol, he yelled “[c]ut their fucking heads off!”
      <Citation number={48} />
      One rioter told the Select Committee he saw another carrying a “pitchfork.”
      <Citation number={49} />
    </Typography>

    <Typography className="indent">
      Members of the mob carried flags and turned the flagpoles into weapons. Michael Foy, from Wixom, Michigan, carried a hockey stick to the Ellipse—he draped a Trump flag over it.
      <Citation number={50} />
      Just hours later, Foy used that hockey stick to repeatedly beat police officers at the inaugural tunnel.
      <Citation number={51} />
      Former New York City police officer Thomas Webster carried a Marine flag, which he later used to attack an officer holding the rioters back at the lower West Plaza.
      <Citation number={52} />
      Another individual, Danny Hamilton, carried a flag with a sharpened tip, which he said was “for a certain person,” to which Trevor Hallgren(who had traveled with Hamilton to Washington, DC) responded: “it has begun.” Later, Hallgren commented that “[t]here’s no escape Pelosi, Schumer, Nadler. We’re coming for you.... Even you AOC. We’re coming to take you out. To pull you out by your hairs.” On January 5th, Hallgren took a tour of the Capitol with Representative Barry Loudermilk, during which he took pictures of hallways and staircases.
      <Citation number={53} />
    </Typography>

    <Typography className="indent">
      The mob President Trump summoned to Washington, DC, on January 6th, was prepared to fight.
    </Typography>
  </Page>
)
