import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet10'));

export const Chapter82 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      While tens of thousands of President Trump’s supporters attended the rally at the Ellipse, the Proud Boys had other plans. On the morning of January 6th, they gathered at the Washington Monument. At 10:30 a.m., the Proud Boys started their march down the National Mall towards the U.S. Capitol. In total, there were approximately 200–300 Proud Boys, as well as their associates, in the group.
      <Citation number={54} />
    </Typography>

    <Typography className="indent">
      Enrique Tarrio, the chairman of the Proud Boys, was not in attendance. As explained in Chapter 6, Tarrio had been arrested two days earlier and ordered to leave Washington. However, Tarrio continued to monitor events remotely from Baltimore, communicating with his men throughout the day. With Tarrio offsite, the Proud Boys were led by three other senior members of the group: Ethan Nordean, Joseph Biggs, and Zachary Rehl.
    </Typography>

    <Typography className="indent">
      Ethan Nordean (a.k.a. “Rufio Panman”) was a member of the Proud Boys’ Elders chapter and president of his local chapter in Seattle, Washington.
      <Citation number={55} />
      Nordean was regarded as the leader for January 6th after Tarrio was arrested.
      <Citation number={56} />
      In the days leading up to January 6th, Nordean made ominous comments on social media. In conversations with his fellow Proud Boys, he argued that the Presidential election was tainted by fraud and violence was a necessary remedy. For example, on January 4th, Nordean posted a video on social media with the title: “Let them remember the day they decided to make war with us.”
      <Citation number={57} />
      In another social media post on January 5th, Nordean warned “we are coming for them.”
      <Citation number={58} />
      He added a telling line: “You’ve chosen your side, black and yellow teamed with red, white and blue against everyone else.”
      <Citation number={59} />
      The “black and yellow” is a reference to the Proud Boys. And when Nordean wrote the “red, white and blue,” he likely meant the Trump supporters who would be in attendance for January 6th.
    </Typography>

    <Typography className="indent">
      Joseph Biggs (a.k.a. “Sergeant Biggs”) was a senior Proud Boys member and served as an event “organizer” for the group.
      <Citation number={60} />
      Biggs previously worked with Alex Jones and InfoWars.
      <Citation number={61} />
      In late December 2020, Biggs posted a message on Parler in which he explained that the Proud Boys “will not be attending DC in colors.”
      <Citation number={62} />
      That is, unlike at previous events, the Proud Boys would not wear their branded, black and yellow clothing, but instead seek to be inconspicuous. Biggs continued:
    </Typography>

    <Blockquote>
      <Typography>
        We will be blending in as one of you. You won’t see us. You’ll even think we are you... We are going to smell like you, move like you, and look like you. The only thing we’ll do that’s us is think like us! Jan 6th is gonna be epic.
        <Citation number={63} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Tarrio posted a similar message, saying the Proud Boys would go “incognito” on January 6th.
      <Citation number={64} />
      Consistent with this decision, Biggs was dressed in a plaid shirt, glasses, and dark hat as he led the march from the Washington Monument.
      <Citation number={65} />
      Other Proud Boys dressed in a similar fashion.
    </Typography>

    <Image
      src="/images/image-106.jpg"
      alt="Protestors, including a group of Proud Boys, gather at the Capitol on January 6, 2021."
      caption="Protestors, including a group of Proud Boys, gather at the Capitol on January 6, 2021."
      credit="(Photo by Jon Cherry/Getty Images)"
    />

    <Typography className="indent">
      Zachary Rehl (a.k.a. “Captain Trump”) was president of the local Philadelphia, Pennsylvania Proud Boys chapter.
      <Citation number={66} />
      Like his comrades, Rehl believed President Trump’s Big Lie about the 2020 Presidential election.
      <Citation number={67} />
      He raised more than $5,500 in funds for January 6th. Like Nordean, Biggs and others, Rehl was dressed “incognito” as he helped lead the group from the Washington Monument.
      <Citation number={68} />
    </Typography>

    <Typography className="indent">
      Shortly after 11:00 a.m., the Proud Boys arrived at the west side of the Capitol, near a reflecting pool. From there, they marched to the east front of the Capitol. Surveillance footage shows the Proud Boys passing Garfield Circle on the southwest corner of the Capitol at 11:15 a.m.
      <Citation number={69} />
      They walked north towards the Peace Circle next, and surveillance cameras captured them on video there at approximately 11:21 a.m.
      <Citation number={70} />
      There was just one USCP officer standing guard at the Peace Circle fence at the time.
      <Citation number={71} />
    </Typography>

    <Typography className="indent">
      As the Proud Boys paraded around the Capitol grounds, Nick Quested, a documentary filmmaker who spent time with the group, recalled them taunting USCP officers. One Proud Boy told the officers to “[r]emember your oath,”“[c]hoose aside, ”and“ [b]e on the right side of history.”
      <Citation number={72} />
      By 11:41 a.m., the Proud Boys made their way around to the east side of the Capitol, crossing along Constitution Avenue.
      <Citation number={73} />
      While on the east front, they posed for pictures with members of their Arizona delegation, who were clearly identifiable by their orange caps.
      <Citation number={74} />
      They then walked back across the north side of the Capitol towards the National Mall, where they stopped to eat at food trucks.
      <Citation number={75} />
      The Proud Boys stayed by the food trucks until they returned to the Peace Circle at approximately 12:49 p.m.
      <Citation number={76} />
    </Typography>
  </Page>
)
