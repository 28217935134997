import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet9'));

export const Chapter73 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      President Trump concluded his remarks at 1:10 p.m. Luna heard the President mention his intention to join the march to the Capitol “after he finished his remarks.”
      <Citation number={77} />
      Just before the President got into his vehicle, Meadows told him, “We’re going to work on it, sir.”
      <Citation number={78} />
      President Trump was seated in his motorcade vehicle by 1:17 p.m.
      <Citation number={79} />
    </Typography>

    <Typography className="indent">
      The Committee received information informally from current and former members of the Secret Service and former White House staff relevant to what happened next—what a number of witnesses have described as an “angry,” “irate,” or “furious” interaction in the Presidential vehicle between the President and the Secret Service.
      <Citation number={80} />
      That initial information, received informally, shaped the Committee’s questioning of witnesses. The Committee’s principal concern was that the President <i>actually intended</i> to participate personally in the January 6th efforts at the Capitol, leading the effort to overturn the election either from inside the Chamber or from a stage outside the Capitol. The Committee regarded those facts as important because they are relevant to President Trump’s intent on January 6th. But a book published by Mark Meadows in November 2021 made the categorical claim that the President <i>never</i> intended to travel to the Capitol that day.
      <Citation number={81} />
      Because the Meadows book conflicted sharply with information that was being received by the Committee, the Committee became increasingly wary that witnesses might intentionally conceal what happened.
    </Typography>

    <Typography className="indent">
      In our initial informal discussion with the lead of the President’s detail, Robert Engel confirmed that President Trump did wish to travel to the Capitol from the Ellipse, but stated that he did not recall many other details.
      <Citation number={82} />
      But the Committee also received information from Kayleigh McEnany and Cassidy Hutchinson that also directly contradicted Mark Meadows’s book and provided considerably more detail. McEnany testified that President Trump did indeed wish to travel to the Capitol on January 6th, and continued to have that goal even after returning from the Ellipse to the White House.
      <Citation number={83} />
      McEnany, who spoke with President Trump shortly after he returned to the White House, recalls him expressing a desire to go to the Capitol: “I recall him... saying that he wanted to physically walk and be a part of the march and then saying that he would ride the Beast if he needed to, ride in the Presidential limo.”
      <Citation number={84} />
      When asked, McEnany confirmed that “yes, he did seem sincere about wanting to do that.”
      <Citation number={85} />
      Hutchinson’s testimony was generally consistent with the information the Select Committee was receiving informally. Like McEnany, Hutchinson confirmed that the President did ask to be transported to Capitol Hill.
      <Citation number={86} />
      Many other White House witnesses would ultimately confirm that President Trump wished to travel to the Capitol on January 6th, comprehensively rebutting the false statements in Meadows’s book.
      <Citation number={87} />
    </Typography>

    <Typography className="indent">
      Part of Hutchinson’s account was a second-hand description of what occurred in the Presidential vehicle, which built upon and was consistent with information the Committee has received informally.
    </Typography>

    <Typography className="indent">
      Hutchinson testified that, when she returned from the Ellipse, Ornato was standing outside his office door when he “waved me down,” Hutchinson said. The two of them walked into Ornato’s office, and he shut the door behind them.
      <Citation number={88} />
      Engel was already there, sitting in a chair “looking down, kind of looking a little lost and kind of discombobulated.”
      <Citation number={89} />
    </Typography>

    <Typography className="indent">
      According to Hutchinson, Ornato then recounted a struggle in the President’s car.
      <Citation number={90} />
      At no point during Ornato’s telling—or at any point thereafter—did Engel indicate that what Ornato relayed was untrue.
      <Citation number={91} />
    </Typography>

    <Typography className="indent">
      Another witness, a White House employee with national security responsibilities, provided the Committee with a similar description: Ornato related the “irate” interaction in the presidential vehicle to this individual in Ornato’s White House office with Engel present.
      <Citation number={92} />
      And just as Hutchinson testified, this employee told the Select Committee that Engel listened to Ornato’s retelling of the episode and did not dispute it: “I don’t remember his specific body language, but... [h]e did not deny the fact that the President was irate.”
      <Citation number={93} />
      Engel testified that he does not recall either the conversation with Hutchinson or the similar conversation with the White House employee with national security responsibilities.
      <Citation number={94} />
    </Typography>

    <Image
      src="/images/image-094.jpg"
      alt="Cassidy Hutchinson describes a story relayed to her by Tony Ornato about President Trump’s desire to go to the Capitol after the Ellipse speech on January 6th during a January 6th Select Committee hearing."
      caption="Cassidy Hutchinson describes a story relayed to her by Tony Ornato about President Trump’s desire to go to the Capitol after the Ellipse speech on January 6th during a January 6th Select Committee hearing."
      credit="(Photo by Brandon Bell/Getty Images)"
    />

    <Typography className="indent">
      The Committee regarded both Hutchinson and the corroborating testimony by the White House employee with national security responsibilities national security official as earnest and has no reason to conclude that either had a reason to invent their accounts. A different Secret Service agent, who served on a protective detail at the White House and was present in the presidential motorcade at the Ellipse, provided this view:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: Just a couple of additional questions. Ms. Hutchinson has suggested to the Committee that you sympathized with her after her testimony, and believed her account. Is that accurate?
      </Typography>
      <Typography>
        <strong>Witness</strong>: I have no—yeah, that’s accurate. I have no reason—I mean, we—we became friends. We worked—I worked every day with her for 6 months. Yeah, she became a friend of mine. We had a good working relationship. I have no reason—she’s never done me wrong. She’s never lied that I know of. I don’t have any reason—I don’t—I don’t distrust Ms. Hutchinson.
        <Citation number={95} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Also, the White House employee with national security responsibilities indicated that knowledge of the angry altercation in the Presidential vehicle was known within the White House—and was “[water] cooler talk.”
      <Citation number={96} />
      In addition, Hutchinson has provided testimony to the Committee about efforts by her prior counsel, who was apparently paid by a Trump-funded organization, to suggest that Hutchinson did not need to testify about the issue in the presidential vehicle, could suggest that she “did not recall” it, or should downplay it.
      <Citation number={97} />
    </Typography>

    <Typography className="indent">
      To further corroborate the accounts received of President Trump’s intent to travel to the Capitol, the Committee interviewed a member of the Metropolitan Police who was also present in the motorcade, Officer Mark Robinson. Officer Robinson confirmed that he was aware contemporaneously of the “heated discussion” that took place in the Presidential vehicle:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: And was there any description of what was occurring in the car?
      </Typography>
      <Typography>
        <strong>Mr. Robinson</strong>: No. Only that—the only description I received was that the President was upset and that he was adamant about going to the Capitol, and there was a heated discussion about that.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: When you say “heated,” is that your word, or is that the word that was described by the TS agent?
      </Typography>
      <Typography>
        <strong>Mr. Robinson</strong>: No. The word described by the TS agent meaning that the President was upset, and he was saying there was a heated argument or discussion about going to the Capitol.
      </Typography>
      <Typography>
        ....
      </Typography>
      <Typography>
        <strong>Mr. Schiff</strong>: So about how many times would you say you’ve been part of that motorcade with the President?
      </Typography>
      <Typography>
        <strong>Mr. Robinson</strong>: Probably over a hundred times.
      </Typography>
      <Typography>
        <strong>Mr. Schiff</strong>: And, in that hundred times, have you ever witnessed another discussion of an argument or a heated discussion with the President where the President was contradicting where he was supposed to go or what the Secret Service believed was safe?
      </Typography>
      <Typography>
        <strong>Mr. Robinson</strong>: No.
        <Citation number={98} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The Committee also interviewed the Secret Service agent who was in the same car as Officer Robinson. That person shared a similar account, and confirmed that he did not take issue with Officer Robinson’s testimony: “[The driver of the Presidential car] said something to the effect of, ‘The President is pretty adamant that he wants to go to the Capitol,’” the agent said, recalling what he had heard on the 6th.
      <Citation number={99} />
    </Typography>

    <Typography className="indent">
      In addition, the Committee interviewed the USSS Press Secretary, who communicated with both Engel and with the driver in the presidential vehicle after Hutchinson appeared publicly. That witness indicated that Engel’s account of the events confirmed that the President was indeed angry, or furious.
      <Citation number={100} />
      In fact, when asked about a reporter’s tweet indicating that sources within the Secret Service confirmed that “Trump was furious about not being [able] to go to [the] Capitol with his supporters,” the Press Secretary said he “certainly corroborated it” with the reporter because “that’s what I had been told, you know, that [the President] was upset, he was agitated, about not being able to go[.]”
      <Citation number={101} />
    </Typography>

    <Typography className="indent">
      In addition to the testimony above, the Committee has reviewed hundreds of thousands of new Secret Service documents, including many demonstrating that the Secret Service had been informed of potential violence at the Capitol before the Ellipse rally on January 6th. (These documents were critical to our understanding of what the Secret Service and White House knew about the threat to the Capitol on January 6th.) The Committee has also more recently conducted additional interviews with Engel and Ornato, and has also interviewed the driver of the Presidential vehicle.
    </Typography>

    <Typography className="indent">
      Both Engel and the driver
      <Citation number={102} />
      testified that, within 30 seconds of getting into the vehicle, the President asked if he could travel to the Capitol.
      <Citation number={103} />
      This again is directly inconsistent with the account of events in Meadows’s book. According to Engel, he told the President immediately that the move wasn’t happening.
      <Citation number={104} />
      The President was unhappy with Engel’s response and began “pushing pretty hard to go.”
      <Citation number={105} />
      The President repeatedly asked why he could not go to the Capitol.
      <Citation number={106} />
      Engel replied that the Secret Service “didn’t have any people at the Capitol” to provide the President with appropriate security.
      <Citation number={107} />
      The President responded angrily, telling Engel and the driver “I’m the President and I’ll decide where I get to go.”
      <Citation number={108} />
      He reassured Engel that “it would essentially be fine and that the people there [meaning the people who were marching from the Ellipse to the Capitol at President Trump’s instruction] were [Trump] supporters or something to that effect,”
      <Citation number={109} />
      According to the Secret Service agent driving the vehicle, the President was “animated and irritated” about not going to the Capitol.
      <Citation number={110} />
    </Typography>

    <Typography className="indent">
      According to Mr. Engel, he ultimately told the President that they would “assess what our options were and wait until we can get a plan in place before we went down there.”
      <Citation number={111} />
      We note that the driver’s account acknowledged President Trump’s anger to a greater degree than either Engel’s initial account in Spring 2022, or his more recent account in November 2022. Engel did not characterize the exchange in the vehicle the way Hutchinson described the account she heard from Ornato, and indicated that he did not recall President Trump gesturing toward him.
      <Citation number={112} />
      Engel did not recall being present when Ornato gave either Hutchinson or the White House employee with national security responsibilities an accounting of the events.
      <Citation number={113} />
      The driver testified that he did not recall seeing what President Trump was doing and did not recall whether there was movement.
      <Citation number={114} />
    </Typography>

    <Typography className="indent">
      The Select Committee has great respect for the men and women of the Secret Service. That said, it is difficult to fully reconcile the accounts of several of the witnesses who provided information with what we heard from Engel and Ornato.
      <Citation number={115} />
      But the principal factual point here is clear and undisputed: President Trump specifically and repeatedly requested to be taken to the Capitol. He was insistent and angry, and continued to push to travel to the Capitol even after returning to the White House.
    </Typography>

    <Typography className="indent">
      The motorcade didn’t disband upon arriving to the White House, as they usually do. Instead, they were instructed to stand by in case the President’s move to the Capitol did indeed happen.
      <Citation number={116} />
      The Select Committee received a document from the Secret Service that reflects that at 1:25 p.m., “PPD IS ADVISING THAT [THE PRESIDENT] IS PLANNING ON HOLDING AT THE WHITE HOUSE FOR THE NEXT APPROXIMATE TWO HOURS, THEN MOVING TO THE CAPITOL.”
      <Citation number={117} />
      “They had not made a decision whether or not we were going to transport the President to the Capitol,” Robinson was told.
      <Citation number={118} />
    </Typography>

    <Typography className="indent">
      Engel testified that he went to Ornato’s office when he returned to the West Wing in order to discuss a possible move to the Capitol by President Trump.
      <Citation number={119} />
      Given the deteriorating security conditions at the Capitol, it was quickly determined that they could not safely transport the President there.
      <Citation number={120} />
      The motorcade waited on West Executive Drive approximately 40 minutes before finally receiving word from the Secret Service that the move had been officially nixed. Internal Secret Service communications bear this out: Not until 1:55 p.m. did Engel notify other agents via email that “[w]e are not doing an OTR to [the Capitol].”
      <Citation number={121} />
    </Typography>
  </Page>
)
