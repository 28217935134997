import { lazy } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Page } from '../../components/Page';
import { StyledListContainer } from '../../components/StyledListContainer';

const Citation = lazy(() => import('../Citations/CitationSheet14'));

export const Appendix3 = ({ ...args }: any) => (
  <Page {...args}>
    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h2" color="primary">
        INTRODUCTION
      </Typography>
    </Box>

    <Typography className="indent">
      This appendix will consider the extent to which President Trump’s Campaign and related entities raised an unprecedented amount of political donations using inflammatory messaging alleging that the 2020 U.S. Presidential election was fraudulent or stolen. It will review what tools and methods were used to produce, transmit and optimize these fundraising solicitations; who drafted and approved the messaging and what they knew about the accuracy of the messaging; who ultimately benefitted from these donations; and the impact of these messages on their recipients.
    </Typography>

    <Typography className="indent">
      The Select Committee’s investigation demonstrates that President Trump’s baseless claims of election fraud—the Big Lie—served a dual purpose, forming the foundation of his attempts to overturn the 2020 Presidential election and launching a fundraising effort to fund the former President’s other endeavors and to enrich his associates—the Big Rip-off.
    </Typography>

    <Typography className="indent">
      The false election fraud narrative embedded in fundraising emails and text messages amplified the Big Lie by perpetuating a belief that the 2020 election was stolen from President Trump and effectuated the Big Rip-off by misleading donors into thinking their donations could alter the election results.
    </Typography>

    <Typography className="indent">
      At the same time, the Big Lie helped President Trump and the Republican National Committee (RNC) raise more than $250 million after the election, much of it from small-dollar donors who were promised their money would “Stop the Steal.”
    </Typography>

    <Typography className="indent">
      Despite what they told their supporters, however, most of their money was not used to stop any purported steal—it was diverted to accomplish the Big Rip-off. Millions of dollars that were raised ostensibly for “election defense” and “fighting voter fraud” were not spent that way at all.
    </Typography>

    <Typography className="indent">
      Moreover, the Select Committee’s investigation shows that the <strong><i>RNC knew</i></strong> that President Trump’s claims about winning the election were baseless and that post-election donations would not help him secure an additional term in office. Yet, both the Trump Campaign and the RNC decided to continue fundraising after the election, <strong><i>a decision that would have come from President Trump himself.</i></strong>
    </Typography>

    <Typography className="indent">
      In short, President Trump and his Campaign ripped off supporters by raising more than $250 million by claiming they wanted to fight fraud they knew did not exist and to challenge an election they knew he lost.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h2" color="primary">
        DISCUSSION
      </Typography>
    </Box>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        THE TRUMP CAMPAIGN FUNDRAISING TEAM
      </Typography>
    </Box>

    <Typography className="indent">
      As detailed below, the Trump Campaign misled the American public and President Trump’s donors on how they planned to use, and did use, the donated funds while bombarding supporters with hundreds of emails, as many as twenty-five emails per day, stating the election had been stolen. In those emails, they used inflammatory language accusing Democrats of trying to “steal the election,” encouraged supporters to join the “Trump army”; “Defend” the election: and to “fight back” over, and over, and over again.
      <Citation number={1} />
      They sent these emails out because they knew they were effective at raising money.
      <Citation number={2} />
      This was made possible by the creation of a fundraising machine powered jointly by the Trump Campaign and the RNC.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        A. The TMAGAC Fundraising Machine
      </Typography>
    </Box>

    <Typography className="indent">
      During the 2020 election cycle, President Trump operated a structure under which the Trump reelection campaign and the RNC merged programs and raised money jointly through the Trump Make America Great Again Committee (internally referred to by its acronym TMAGAC, which RNC officials pronounced “T-Magic”).
      <Citation number={3} />
      TMAGAC was focused on raising money online through small-dollar donations.
      <Citation number={4} />
      Tim Murtaugh, the Trump Campaign’s communications director, described the TMAGAC fundraising operation as “an entity unto itself within the campaign.”
      <Citation number={5} />
    </Typography>

    <Typography className="indent">
      The individual charged with leading the digital operation in 2020 was Gary Coby.
      <Citation number={6} />
      Coby first started working with the RNC in 2016 even before President Trump became the nominee.
      <Citation number={7} />
      Coby explained that, as digital director, his role during the 2020 Presidential election cycle was to “oversee the digital operation.”
      <Citation number={8} />
      Similarly, senior staffers at the Trump Campaign and the RNC all made clear that Coby was the individual in charge of the TMAGAC digital team.
      <Citation number={9} />
      Both high-level staffers at the Trump Campaign and at the RNC confirmed that Coby had the trust of Jared Kushner, the President’s son-in-law.
      <Citation number={10} />
    </Typography>

    <Typography className="indent">
      Coby explained that during the 2020 Presidential election cycle, the TMAGAC digital team was a “big team with multiple organizations and vendor teams all working together as one, that include[d] RNC staff, [Donald J. Trump for President] staff, [and] maybe a half dozen vendor teams.”
      <Citation number={11} />
      The RNC digital team, a subset of the TMAGAC digital team, was led operationally by Kevin Zambrano, Chief Digital Officer at the RNC. In 2020, members of the digital staff of both the RNC and Trump Campaign merged in an office building in Arlington, Virginia, with some suites jointly hosting RNC and Trump Campaign staff on the digital team, and other suites hosting third-party companies, such as Opn Sesame and Direct Persuasion.
      <Citation number={12} />
      Thereafter, Zambrano assisted Coby in managing the TMAGAC digital team.
      <Citation number={13} />
      Zambrano explained, “The majority of the staff was at the RNC doing both RNC and TMAGAC work.”
      <Citation number={14} />
      This work was focused squarely on fundraising.
      <Citation number={15} />
    </Typography>

    <Typography className="indent">
      The RNC digital team included Austin Boedigheimer, who, starting in January 2019, was the RNC’s digital deputy director
      <Citation number={16} />
      and technically served as Zambrano’s deputy.
      <Citation number={17} />
      In reality, Boedigheimer reported to both Zambrano and Coby.
      <Citation number={18} />
      Boedigheimer also led the TMAGAC digital fundraising team, which was comprised of all online fundraising efforts, including fundraising emails and text messages.
      <Citation number={19} />
      At the end of the 2020 cycle, that team had 20 or 30 people within smaller teams, such as the copy team, the text message team, the data team, the advertising team, and the graphics team.
      <Citation number={20} />
    </Typography>

    <Typography className="indent">
      The RNC digital team also included a team of copywriters, who were responsible for writing the fundraising emails and text messages to solicit small-dollar donations through TMAGAC.
      <Citation number={21} />
      These copywriters reported to Hanna Allred, the RNC’s Chief Copywriter.
      <Citation number={22} />
      By mid-2020, there were three copywriters who reported to Allred: Alex Murglin,
      <Citation number={23} />
      Ethan Katz,
      <Citation number={24} />
      and Alex Blinkoff.
      <Citation number={25} />
      Blinkoff and Katz worked in that role from June 2020 until they were fired approximately three weeks after the 2020 election, while Murglin remains a copywriter at the RNC.
    </Typography>

    <Typography className="indent">
      Although the TMAGAC team consisted of both Trump Campaign and RNC staffers, TMAGAC operated as one entity working towards one goal— raising as much money as possible.
      <Citation number={26} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        B. The Fundraising Assembly Line
      </Typography>
    </Box>

    <Typography className="indent">
      The copywriting process worked like an assembly line, where different individuals performed a task and passed on the work product to someone else, including for internal approval.
      <Citation number={27} />
      To generate content for fundraising communications, Allred explained, the copywriting fundraising team was “watching the messaging coming out of the committee [RNC] and the campaign and from the President himself and what his family was talking about.”
      <Citation number={28} />
      For example, in a November 2020 email, Boedigheimer stated to Allred, “Good to include lines like [‘]we need the resources to make sure they don’t try to steal this election. We saw what happened on election night, we can’t let them take the senate too.[’]”
      <Citation number={29} />
    </Typography>

    <Typography className="indent">
      It was evident that the copywriters “would draft a lot of the content based on...what the President was saying.”
      <Citation number={30} />
      And there was no mistaking it, President Trump “was providing us [the copywriters] with a lot of content online.”
      <Citation number={31} />
      Allred said Boedigheimer was encouraging her to use this language because it would cause President Trump’s supporters to donate by “giving a purpose to their donation”
      <Citation number={32} />
      and that they used this repeatedly because it worked.
      <Citation number={33} />
      Boedigheimer did not dispute this, and reaffirmed that such language had been successful at fundraising.
      <Citation number={34} />
    </Typography>

    <Typography className="indent">
      Further, the emails that were signed by President Trump or “Team Trump” were intentionally drafted to capture President Trump’s voice, tone and messaging.
      <Citation number={35} />
      Boedigheimer explained, “[President Trump] obviously has a very aggressive[,] excitable tone, and we would try to incorporate that in our messaging as well.”
      <Citation number={36} />
      The goal was to make the millions of recipients of aggressive, hyperbolic fundraising emails believe that the emails were coming from President Trump himself. In Zambrano’s words, the purpose was to give recipients “red meat.”
      <Citation number={37} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        C. The Approvals Process
      </Typography>
    </Box>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        The Structure
      </Typography>
    </Box>

    <Typography className="indent">
      Draft emails were submitted for approval to a designated group that handled approvals of all TMAGAC fundraising copy (the “Approvals Group”).
      <Citation number={38} />
      Boedigheimer retained responsibility for ensuring that TMAGAC’s fundraising copy was approved before being sent to the public.
      <Citation number={39} />
    </Typography>

    <Typography className="indent">
      The Approvals Group consisted of three sets of stakeholders from the RNC and the Trump Campaign,
      <Citation number={40} />
      and included a variety of other interested staffers, including Zambrano, Boedigheimer, and Allred.
      <Citation number={41} />
      TMAGAC’s fundraising copy could not be sent without approval from the legal, communications and research departments.
      <Citation number={42} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        Perceived Responsibilities
      </Typography>
    </Box>

    <Typography className="indent">
      After election day, a small group of staffers in the Approvals Group actively reviewed and approved the numerous fundraising emails and text messages that spread false election fraud claims. These staffers included:
    </Typography>

    <StyledListContainer>
      <ul>
        <li>
          <Typography>
            <strong>RNC Legal</strong>: Justin Reimer, RNC Chief Counsel
            <Citation number={43} />
            and Jenna Kirsch, RNC Associate Counsel
            <Citation number={44} />
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>RNC Communications</strong>: Cassie Docksey, Deputy Communications Director
            <Citation number={45} />
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>RNC Research</strong>: Michael Reed, Deputy Chief of Staff for Communications
            <Citation number={46} />
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Trump Campaign Legal</strong>: Alex Cannon, Deputy General Counsel
            <Citation number={47} />
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Trump Campaign Communications and Research</strong>: Zach Parkinson, Deputy Director of Communications and Director of Research
            <Citation number={48} />
          </Typography>
        </li>
      </ul>
    </StyledListContainer>

    <Typography className="indent">
      Boedigheimer, as head of the fundraising team handling the drafting and propagation of fundraising messaging, told the Select Committee his understanding of the role the three components of the Approvals Group (legal, communications, and research) performed when reviewing emails and text messages.
    </Typography>

    <Typography className="indent">
      Regarding legal, Boedigheimer explained that he understood Alex Cannon’s role, as the Trump Campaign’s Deputy General Counsel, was to review the emails from a “legal perspective” by “essentially making sure that there’s no legal issues with the content.”
      <Citation number={49} />
    </Typography>

    <Typography className="indent">
      Regarding communications, Boedigheimer stated that the communications staffers in the Approvals Group were reviewing the content and “[m]aking sure that it’s on message and good from a comms perspective.”
      <Citation number={50} />
    </Typography>

    <Typography className="indent">
      Lastly, Boedigheimer explained that the research team was “looking for... things that are inaccurate.”
      <Citation number={51} />
    </Typography>

    <Typography className="indent">
      Although Boedigheimer provided only a vague explanation of the role of the Approvals Group, he emphasized that he had to “trust that the research, the comms, and the legal team are going to do their processes to make sure it’s [the TMAGAC fundraising copy was] accurate.”
      <Citation number={52} />
      He further noted, “[I]t was the approval chain’s job to see what the accuracy of the email is and whether it’s true or not.... We were sending information and then leaning on our approval chain to make sure that it’s accurate.”
      <Citation number={53} />
    </Typography>

    <Typography className="indent">
      The Select Committee’s investigation revealed that the Approvals Group did not operate that way, however. The Select Committee interviewed members of the Approvals Group handling the communications, research, and legal functions and confirmed that members of the Approvals Group typically engaged only in cursory reviews of the fundraising messages and did not review substantive claims of election fraud for accuracy.
    </Typography>

    <Typography className="indent">
      Zach Parkinson, the Trump Campaign’s Deputy Director of Communications and Research Director, represented the Trump Campaign’s communications and research functions in the Approvals Group. Parkinson made clear, “Generally, our role when it came to fundraising emails and texts was to approve them for the communications team.”
      <Citation number={54} />
      Parkinson noted that that he was typically the person who weighed in on behalf of the Trump Campaign’s communications and research team.
      <Citation number={55} />
      He added, “we would review them for messaging consistency, sometimes we would review them for factual accuracy, and then we would provide the communications approval for those.”
      <Citation number={56} />
    </Typography>

    <Typography className="indent">
      Parkinson clarified that the scope of review for “factual accuracy” was limited. Specifically, his review of fundraising emails and text messages for accuracy was limited to questions concerning items such as time and location.
      <Citation number={57} />
    </Typography>

    <Typography className="indent">
      Critically, Parkinson did not review statements regarding election fraud in the fundraising copy for accuracy because “most political text messages and fundraising emails are political rhetoric, and so a lot of them don’t necessarily require fact checking.”
      <Citation number={58} />
      He added that “political rhetoric,” such as “Democrats are trying to steal the election,” was not something he and his team were “necessarily tasked to say no to.”
      <Citation number={59} />
      Parkinson made clear that he thought the legal department, namely Alex Cannon, would handle reviewing for accuracy, noting “I deferred to the legal team on the legitimacy and the ability to substantiate claims that were made that were put through these approvals and whether or not we could, again, substantiate them or they were in line with our legal efforts.”
      <Citation number={60} />
      Parkinson, as the head of the research team, the very campaign team meant to fact-check and ensure accuracy in the Trump Campaign’s statements, said he was “simply looking for messaging consistency.”
      <Citation number={61} />
      Whether Democrats were engaged in fraud to steal the election was a “political argument” to Parkinson, which he did not review for accuracy.
      <Citation number={62} />
    </Typography>

    <Typography className="indent">
      Like Parkinson, Michael Reed, then the RNC’s Deputy Chief of Staff for Communications, was not reviewing the TMAGAC emails about election fraud for broader accuracy. Notably, Reed could not recall a single email that he researched to do a fact-check or follow up on to see if claims contained in the email were, in fact, true.
      <Citation number={63} />
    </Typography>

    <Typography className="indent">
      Boedigheimer and the copywriters believed the research staffers were looking for messages that they believed were inaccurate, but they were doing no such thing.
    </Typography>

    <Typography className="indent">
      Alex Cannon, the Trump Campaign’s legal representative in the Approvals Group, was no different—the TMAGAC fundraisers thought he was doing far more than he was in fact doing. The Select Committee received a November 4, 2020, email from Nathan Groth, counsel for the Trump Campaign, to Alex Cannon. This email reflected that Cannon was not tasked with substantively reviewing fundraising emails like Boedigheimer thought. Groth wrote to Cannon, “Matt [Morgan, Trump Campaign’s General Counsel] has instructed me to hand off all compliance matters, including approvals, to you.”
      <Citation number={64} />
      Cannon confirmed, “I saw myself as doing exactly what I was instructed to do here, which is do what Nathan had previously been doing. So it’s this. It’s compliance issues like disclaimers and typos.”
      <Citation number={65} />
      Therefore, when Cannon received emails that included claims such as “the Democrats are trying to steal the election,” he viewed reviewing the veracity of this statement as “outside the purview of what [he] was tasked.”
      <Citation number={66} /> When asked, Cannon stated that he did not know who was tasked with ensuring that fundraising emails were true and accurate.
      <Citation number={67} />
    </Typography>

    <Typography className="indent">
      Boedigheimer, and other members of the digital fundraising team he led, claimed to see the Approvals Group as a guardrail of sorts in the fundraising effort to protect from the dissemination of false messaging about the election, but the Approvals Group served no such role. The very staffers in the Approvals Group repeatedly told the Select Committee that they did not review the claims about election fraud to confirm whether they were even true.
    </Typography>

    <Typography className="indent">
      When all was said and done, no one in the Trump Campaign claimed to be responsible for confirming the accuracy of President Trump’s words, or other allegations of election fraud, before they were blasted to millions of Americans.
      <Citation number={68} />
    </Typography>

    <Typography className="indent">
      Thus, after the election, the TMAGAC team drafted emails filled with inflammatory and unfounded claims, and the members of the Approvals Group tasked with fact checking these claims did no such thing— effectively, President Trump’s claims were treated as true and blasted to millions of people with little to no scrutiny by those tasked with ensuring accuracy. This process was a fertile ground for the Big Lie to spread through hundreds of emails and text messages.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        D. Focus on Fundraising Metrics
      </Typography>
    </Box>

    <Typography className="indent">
      Boedigheimer spoke with Coby and Zambrano often about how much money TMAGAC was raising, and they provided feedback regarding fundraising goals.
      <Citation number={69} />
      Trump Campaign leadership was fully aware of postelection fundraising totals. According to Coby, President Trump’s son-in-law and senior advisor Jared Kushner “had the most interest in the digital program” and “would just check in on [fundraising] results,” and routinely received updates regarding fundraising from Coby.
      <Citation number={70} />
      Coby also made clear that Kushner was heavily involved in the Campaign’s budget process
      <Citation number={71} />
      and that he updated Kushner on TMAGAC’s post-election fundraising totals.
      <Citation number={72} />
    </Typography>

    <Typography className="indent">
      The Select Committee received documents confirming Kushner’s involvement. For example, on November 8, 2020, Kushner requested that a daily tracker be created showing the Trump Campaign’s financial position from election day forward.
      <Citation number={73} />
      In an email, Kushner noted that the tracker would allow the Campaign to consider its cash flow ahead of the creation of “a new entity for POTUS[’s] other political activities.”
      <Citation number={74} />
      Just days after the election, and after the Campaign had three of its four best fundraising days ever on November 4th, 5th, and 6th,
      <Citation number={75} />
      Kushner was preparing for the launch of President Trump’s new leadership PAC, Save America. Kushner stated that he needed this new daily tracker because the Trump Campaign was going to continue fundraising post-election.
      <Citation number={76} />
      Kushner continued to receive these detailed daily trackers, which included Save America’s fundraising hauls, through at least December 2020.
      <Citation number={77} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        2020 ELECTION: THE ROLE OF ELECTION FRAUD MESSAGING
      </Typography>
    </Box>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        A. The Decision to Continue Fundraising after Election Day
      </Typography>
    </Box>

    <Typography className="indent">
      Heading into election night of the 2020 Presidential race, as Americans across the country waited in line to vote on election day, the Trump Campaign and the RNC were planning what they would tell the American public about the results in the upcoming days. On election day, Boedigheimer and Darren Centinello, a Trump Campaign staffer, discussed the three message options that the Trump Campaign had on the table.
      <Citation number={78} />
    </Typography>

    <Typography className="indent">
      The first option was to send out copy claiming President Trump had won the 2020 election. But the Campaign knew this message was false, and Boedigheimer told Centinello that he could not get this messaging approved yet.
      <Citation number={79} />
      The Trump Campaign’s second option was an email stating they were still waiting on the election results. This message would have been <i>the truth</i>. The Campaign rejected this option.
    </Typography>

    <Typography className="indent">
      Instead, the Trump Campaign chose a third option. Boedigheimer confirmed that TMAGAC fundraisers had received approval for copy claiming that the Democrats are going to “try to steal the election” <i>before</i> election night.
      <Citation number={80} />
    </Typography>

    <Typography className="indent">
      Zambrano confirmed that it would not surprise him that TMAGAC was immediately claiming that Democrats were trying to steal the election, because President Trump has been pushing that message.
      <Citation number={81} />
      Zambrano added, “That was the President’s phrasing in the messaging that the team was sourcing from.”
      <Citation number={82} />
      Importantly, Boedigheimer confirmed that the TMAGAC copywriting team did not base its use of the “trying to steal” language on any awareness of actual fraud.
      <Citation number={83} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        B. Post-Election Fundraising Off the Big Lie
      </Typography>
    </Box>

    <Typography className="indent">
      Both the Trump Campaign and the RNC directed TMAGAC to continue fundraising after the election.
      <Citation number={84} />
      Justin Clark, the deputy campaign manager, explained that the decision to continue fundraising after the election would have come from President Trump himself.
      <Citation number={85} />
    </Typography>

    <Typography className="indent">
      Starting after the election and until January 6th, the Trump Campaign, along with the RNC, sent millions of emails to their supporters, with messaging such as claiming that the election was “RIGGED.”
      <Citation number={86} />
      The Trump Campaign viewed the TMAGAC emails as another avenue to get out President Trump’s post-election messaging about the alleged fraud.
      <Citation number={87} />
      These emails used false claims of voter fraud to create a sense of urgency that the election was being stolen. The Trump Campaign and the RNC told their supporters that their donations could stop Democrats from “trying to steal the election.” They consistently encouraged donors to give money to continue “uncovering” fraud that had not occurred. These emails were sent out after being reviewed and approved by the Approvals Group.
      <Citation number={88} />
    </Typography>

    <Typography className="indent">
      The TMAGAC fundraisers used inflammatory language and false election fraud claims after the election because it was both effective at fundraising and accurately captured President Trump’s ongoing tone and messaging.
      <Citation number={89} />
      When the digital fundraising team drafted emails claiming, for example, that “Democrats are trying to steal the election,” they did not bother to confirm whether or not those inflammatory statements were true, and instead they merely took President Trump’s words and made an effective fundraising email.
      <Citation number={90} />
      As Zambrano stated, “the President issuing statements or tweets would be the genesis of the copy that would then go into the approval process for edits, for checks. That is why the approval process worked.”
      <Citation number={91} />
      President Trump was the source of the lies. Not only was President Trump’s fundraising driven by his daily deluge of lies about the election, but these lies were also able to go unchallenged before being spread because TMAGAC had an ineffective process when it came to scrutinizing and correcting those lies.  
    </Typography>

    <Typography className="indent">
      The TMAGAC fundraising machine continued to churn out hundreds of fundraising emails and text messages regardless of external developments. For example, Zambrano said that, after former Vice President Biden was widely declared the winner of the election, TMAGAC’s fundraising efforts moved ahead the same way they had previously,
      <Citation number={92} />
      even though he “would say it wasn’t looking good” as soon as one week after the election.
      <Citation number={93} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        ALARMS RAISED ABOUT TMAGAC FUNDRAISING CONTENT
      </Typography>
    </Box>

    <Typography className="indent">
      A number of individuals and entities associated with the TMAGAC fundraising campaign raised concerns about the dangerous and inflammatory language used in the emails issued for this campaign.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        Concerns Raised in Internal RNC Review
      </Typography>
    </Box>

    <Typography className="indent">
      Evidence obtained by the Select Committee shows that the RNC knew that President Trump’s claims about winning the election were baseless and that additional donations would not help him secure an additional term in office. They walked as close to the line as they dared—making several changes to fundraising copy that seemingly protected the RNC from legal exposure while still spreading and relying on President Trump’s known lies and misrepresentations.
    </Typography>

    <Typography className="indent">
      The Select Committee did not interview a member of the RNC legal team due to concerns surrounding attorney-client privilege, but the Select Committee nonetheless got insight into their role from documents produced by Campaign and RNC staff, as well as interviews with staffers. As detailed below, the RNC lawyers were the only individuals who even attempted to walk back the fundraising emails.
    </Typography>

    <Typography className="indent">
      Allred and Katz both received direction from the RNC’s lawyers shortly after the election to not say “steal the election” and instead were told to use “try to steal the election.”
      <Citation number={94} />
      Allred also recalled that, at some point, the RNC legal team directed the copywriters not to use the term “rigged.”
      <Citation number={95} />
    </Typography>

    <Typography className="indent">
      After the media called the election for former Vice President Joe Biden on Saturday, November 7, 2020, the RNC began to quietly pull back from definitive language about President Trump having won the election and instead used language of insinuation. For example, on November 10, 2020, Justin Reimer, RNC’s then-chief counsel, revised a fundraising email sent to the Approvals Group to remove the sentence that “Joe Biden should not wrongfully claim the office of the President.”
      <Citation number={96} />
      Instead, Reimer indicated the email should read, “Joe Biden does not get to decide when this election ends. Only LEGAL ballots must be counted and verified.”
      <Citation number={97} />
      Both Alex Cannon and Zach Parkinson signed off on Reimer’s edits.
      <Citation number={98} />
    </Typography>

    <Typography className="indent">
      On November 11, 2020, Reimer again revised a fundraising email sent to the Approvals Group. This time, he revised a claim that “President Trump won this election by a lot” to instead state that “President Trump got 71 MILLION LEGAL votes.”
      <Citation number={99} /> Once again Cannon and Parkinson signed off on Reimer’s edits.
      <Citation number={100} />
    </Typography>

    <Typography className="indent">
      Also on November 11, 2020, Jenna Kirsch, associate counsel at the RNC, revised a fundraising email sent to the Approvals Group to, among other things, remove the request “to step up and contribute to our critical <strong>Election Defense Fund</strong> so that we can DEFEND the Election and secure FOUR MORE YEARS.”
      <Citation number={101} />
      Instead of “secure FOUR MORE YEARS,” Kirsch’s revised version stated a contribution would “finish the fight.”
      <Citation number={102} />
      Once again Cannon and Parkinson signed off on these edits for the Trump Campaign.
      <Citation number={103} />
      Regarding the change to finish the fight, Zambrano conceded, “I would say this a substantive change from the legal department.”
      <Citation number={104} />
      Kirsch made numerous edits like this, in which she removed assertions about “four more years.”
      <Citation number={105} />
      Such edits continued into late November 2020.
    </Typography>

    <Typography className="indent">
      Further, Boedigheimer stated that he took questions to RNC legal in the post-election period about TMAGAC fundraisers using the “steal the election” language.
      <Citation number={106} />
      The RNC was clearly aware that President Trump’s claims regarding the election were not true and tried to have it both ways.
    </Typography>

    <Typography className="indent">
      The private split between the RNC and the Trump Campaign became even more pronounced when President Trump decided to double down on his false election fraud claims and chose Rudolph Giuliani to lead his legal efforts to overturn the election.
      <Citation number={107} />
      On November 19, 2020, Giuliani held a press conference at the RNC’s headquarters in which he falsely suggested that the Biden Campaign orchestrated an elaborate nationwide voter-fraud scheme.
      <Citation number={108} />
      Cassie Docksey, a senior RNC staffer at the time, recalled that she spoke that day with Michael Ahrens, then the RNC’s communications director, about the diverging from the Trump Campaign.
      <Citation number={109} />
      Ahrens told her that the RNC would no longer automatically amplify or replicate statements from the Trump Campaign or President Trump’s legal team.
      <Citation number={110} />
      Docksey understood Ahrens to be relaying a decision made at the most senior levels of the RNC.
      <Citation number={111} />
    </Typography>

    <Typography className="indent">
      Ahrens asserted that the RNC was unwilling to adopt the wide-ranging, baseless assertions President Trump’s legal team was making and quietly decided to focus its communication strategy elsewhere.
      <Citation number={112} />
      Distancing the RNC from President Trump’s false statements was a “regular course of the job before the election,” and it “carried through after the election” in relation to President Trump’s false claims about the election.
      <Citation number={113} />
      Starting at or before the November 19, 2020, press conference, the RNC senior leadership was in agreement that they would not claim that President Trump had won the election,
      <Citation number={114} />
      although the RNC “frequently” had to have internal discussions about President Trump’s false statements about the election.
      <Citation number={115} />
    </Typography>

    <Typography className="indent">
      According to Michael Reed, then the RNC’s deputy chief of staff for communications, “there were conversations amongst [RNC] legal and comms and digital to ensure that anything that was being written by the digital team based off of something President Trump or the Campaign said was something we all were more comfortable with.”
      <Citation number={116} />
      RNC Chairwoman McDaniel was a part of these conversations.
      <Citation number={117} />
    </Typography>

    <Typography className="indent">
      RNC leadership knew that President Trump was lying to the American people. Yet, they did nothing to publicly distance themselves from his efforts to overturn the election. The RNC’s response was merely to tinker around the edges of the fundraising copy but never to fundamentally challenge the one message that remained present in TMAGAC’s post-election fundraising copy—President Trump’s Big Lie.
    </Typography>

    <Typography className="indent">
      In the end, multiple senior RNC staffers approved fundraising emails raising questions about the election results even though they did not know of any evidence about fraud impacting the winner of the 2020 Presidential election. For example, Cassie Docksey stated that she was not aware of any fraud that impacted the results of the Presidential election.
      <Citation number={118} />
      Ahrens conceded that “there was not evidence that we [the RNC] had seen that he [President Trump] won the election, that Biden had not won the election.”
      <Citation number={119} />
    </Typography>

    <Typography className="indent">
      Similarly, Justin Clark was “not aware of [fraudulent activity... to like defraud voters] by an individual or an entity that would have [changed the outcome of an election].”
      <Citation number={120} />
      Alex Cannon “did not find or see, in [his] limited ability as one individual... evidence that would be sufficient within the time period to change any sort of election results in any of the States.”
      <Citation number={121} />
    </Typography>

    <Typography className="indent">
      Nonetheless, the RNC and the Trump Campaign continued to send out hundreds of emails, spreading the Big Lie to and fundraising off of millions of supporters. Even though the RNC had closely held reservations about repeating the most extreme and unsupportable claims of fraud, the RNC stayed the course with a coordinated, single fundraising plan with the Trump Campaign. The RNC privately and quietly softened the most blatantly egregious claims written by its own copywriters but publicly stood shoulder to shoulder with President Trump and his Big Lie.
    </Typography>

    <Typography className="indent">
      This is clearly evidenced by multiple TMAGAC emails in late December 2020 that asserted that former Vice President Joe Biden would be an “illegitimate President” when he took office.
      <Citation number={122} />
      These emails came after December 14, 2020, the day electors from each State met to cast their votes for President and Vice President. These emails came after Senate Majority Leader Mitch McConnell made it clear that he accepted the electoral college’s certification of Biden’s victory. These emails came after President Trump and his allies had lost all but one lawsuit challenging the election.
      <Citation number={123} />
      None of this made a difference to TMAGAC. When asked why TMAGAC would repeatedly send these emails stating that former Vice President Biden would be an illegitimate President, Hanna Allred, the chief copywriter, stated that it would be because the emails were “effective” for fundraising.
      <Citation number={124} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        Trump Campaign Discussions
      </Typography>
    </Box>

    <Typography className="indent">
      Alex Cannon was so bothered by the emails he was reviewing as a member of the Approvals Group that he took his concerns to Justin Clark, the campaign’s deputy campaign manager. Cannon explained that he had discussions with Clark about the problematic tone of the post-election TMAGAC emails and noted to Clark that the emails “seemed a little over the top to [him].”
      <Citation number={125} />
      Cannon raised those concerns because, after spending weeks researching which fraud claims were verifiable and which were not, Cannon saw that the TMAGAC emails were inconsistent with the fact that systemic fraud did not exist.
      <Citation number={126} />
      Cannon also recalled that he may have expressed concern to Matt Morgan, the campaign’s general counsel, regarding the difference between claims of election fraud made in the TMAGAC fundraising emails and his conclusion that there was not fraud that impacted the election results.
      <Citation number={127} />
      Cannon was not aware of any actions taken to address the concerns he had with this inconsistency.
      <Citation number={128} />
    </Typography>

    <Typography className="indent">
      Justin Clark could not recall whether he looked at any fundraising emails after Cannon raised these concerns or whether Cannon spoke to Gary Coby about the substance of the fundraising emails.
      <Citation number={129} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        Challenges From Within the Digital Team
      </Typography>
    </Box>

    <Typography className="indent">
      In the days after the election, one junior copywriter presented senior Campaign staffers with a template for a more honest approach. Shortly after election night, Coby led a meeting of the entire Trump digital team, which included individuals from the Campaign, the RNC, Opn Sesame, Direct Persuasion, and others. In that meeting, as Coby addressed the staff and expressed that the digital team would continue to work, Ethan Katz, an RNC staffer in his early twenties, rose to ask a question:
      <Citation number={130} />
      How were staffers supposed to tell voters that the Trump Campaign wanted to keep counting votes in Arizona but stop counting votes in other States (like Pennsylvania, Georgia, and Michigan)?
      <Citation number={131} />
    </Typography>

    <Typography className="indent">
      Katz said that Coby provided an answer without substance, which caused Katz to reiterate his question. His question made clear that the Campaign’s position was wildly inconsistent.
      <Citation number={132} />
      Allred and Boedigheimer corroborated that Katz confronted leadership.
      <Citation number={133} />
    </Typography>

    <Typography className="indent">
      Katz also recalled that, shortly after the election, Allred directed him to write an email declaring that President Trump had won the State of Pennsylvania before anyone had called Pennsylvania for either party.
      <Citation number={134} />
      Katz believed the Trump Campaign wanted to send this email out to preempt a potential call that was likely to be in former Vice President Biden’s favor.
      <Citation number={135} />
      He refused to write the email. Allred was stunned, and instead assigned it to another copywriter.
      <Citation number={136} />
      Allred confirmed that Katz expressed discomfort at writing such an email and that she relied on another copywriter.
      <Citation number={137} />
      On November 4, 2020, the Trump Campaign sent out an email preemptively and falsely declaring that President Trump won Pennsylvania.
      <Citation number={138} />
    </Typography>

    <Typography className="indent">
      Katz was fired approximately three weeks after the election.
      <Citation number={139} />
      In an interview with the Select Committee, when Allred was asked why Katz, her direct report, was fired, she explained that she was not sure why because TMAGAC was raising more money than ever after the election, but that the decision was not hers to make.
      <Citation number={140} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        Concerns Raised by Trump Campaign Vendor Iterable
      </Typography>
    </Box>

    <Typography className="indent">
      The Trump Campaign knew that emails that the Approvals Group had blessed were being rejected by another email service provider. After the election, the Trump Campaign attempted to expand the reach of their false voter fraud emails. The Trump Campaign formed a company named DataPier, owned by Cannon and Sean Dollman.
      <Citation number={141} />
      DataPier hired an outside company named Iterable to deliver its emails.
      <Citation number={142} />
      Cannon tried to send “toned-down RNC emails,” through Iterable, but they still had to be “further toned [] down through [an] iterative process[.]”
      <Citation number={143} />
      For example, on November 7, 2020, Seth Charles, who was then Iterable’s principal email deliverability and industry relations manager, said that there was an issue with the TMAGAC copy and offered line edits.
      <Citation number={144} />
      Two days later, Charles recommended to the Trump Campaign staffers that they look for “modified copy there [from TMAGAC emails] to be a little less threatening.”
      <Citation number={145} />
      Charles claimed that some TMAGAC copy “obviously insinuates the so far unsubstantiated theory of voter fraud, as well as contributions and legal actions will result in some sort of different outcome.”
      <Citation number={146} />
    </Typography>

    <Typography className="indent">
      But Salesforce, TMAGAC’s original email service provider, continued sending millions of Trump Campaign emails up until January 6th.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        Internal Complaints at Salesforce
      </Typography>
    </Box>

    <Typography className="indent">
      The Trump Campaign knew that emails that the Approvals Group had blessed were being rejected by Iterable. However, the RNC continued to send millions of Trump Campaign emails through Salesforce, TMAGAC’s original email service provider, up until January 6th. Evidence uncovered by the Select Committee shows that there were internal concerns at Salesforce regarding the content of the TMAGAC emails.
    </Typography>

    <Typography className="indent">
      The Select Committee interviewed an individual (“J. Doe”) who worked at Salesforce during the post-election period during which TMAGAC was sending out the fundraising emails concerning false election fraud claims.
      <Citation number={147} />
      Doe worked for Salesforce’s privacy and abuse management team, colloquially known as the abuse desk.
      <Citation number={148} />
      An abuse desk is responsible for preventing fraud and abuse emanating from the provider’s user or subscriber network.
    </Typography>

    <Typography className="indent">
      Doe indicated to the Select Committee that, as soon as early 2020, they recalled issues arising with the RNC’s use of Salesforce’s services and that a “deluge of abuse would’ve started in June-ish.”
      <Citation number={149} />
      Doe noted that Salesforce received a high number of complaints regarding the RNC’s actions, which would have been primarily the fundraising efforts of TMAGAC.
      <Citation number={150} />
      In the latter half of 2020, Doe noticed that the emails coming from the RNC’s account included more and more violent and inflammatory rhetoric in violation of Salesforce’s Master Service Agreement (“MSA”) with the RNC, which prohibited the use of violent content.
      <Citation number={151} />
      Doe stated that, near the time of the election, they contacted senior individuals at Salesforce to highlight the “increasingly concerning” emails coming from the RNC’s account.
      <Citation number={152} />
      Doe explained that senior individuals at Salesforce effectively ignored their emails about TMAGAC’s inflammatory emails
      <Citation number={153} />
      and Salesforce ignored the terms of the MSA and permitted the RNC to continue to use its account in this problematic manner.
      <Citation number={154} />
      Doe said, “Salesforce very obviously didn’t care about anti-abuse.”
      <Citation number={155} />
    </Typography>

    <Typography className="indent">
      Ultimately, the Trump Campaign and the RNC let the Big Lie spread because they were making hundreds of millions of dollars from President Trump’s supporters who believed that lie. The Big Rip-off needed the Big Lie to motivate unsuspecting individuals to donate their money to a lost cause, and it worked.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        WHERE DID THE MONEY GO?
      </Typography>
    </Box>

    <Typography className="indent">
      The Trump Campaign and the RNC had three of their largest fundraising days of the 2020 election cycle immediately after the election.
      <Citation number={156} />
      Together, the Trump Campaign and the RNC raised more than one hundred million dollars in three days, telling people they were raising the money for the “Official Election Defense Fund.” According to the TMAGAC fundraising pitches, the Trump Campaign and RNC team had created a so-called “Official Election Defense Fund” to help pay for legal challenges to the election results.
      <Citation number={157} />
      But there was no “Official Election Defense Fund”—it was simply “a marketing tactic.”
      <Citation number={158} />
      The TMAGAC fundraisers did not know where the donated money was actually going.
      <Citation number={159} />
      The TMAGAC copywriting team simply took the lies that President Trump told them about the need to raise money to overturn the election results and put them into emails to his supporters.
    </Typography>

    <Typography className="indent">
      The false claims of election fraud and the “Official Election Defense Fund” were so successful President Trump and his allies raised more than $250 million <i>after</i> the election.
      <Citation number={160} />
      However, the Trump Campaign was raising too much money to spend solely on their legal efforts to overturn the results of the 2020 election. The Trump Campaign continued to publicly state the election had been stolen by “the Left,” while behind closed doors they prepared a new plan to spend their supporters’ money.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        A. The Creation of the Save America PAC
      </Typography>
    </Box>

    <Typography className="indent">
      On November 9, 2020, President Trump created a separate leadership PAC called Save America that allowed him to keep millions of dollars raised after the election and spend it with very few restrictions in the future. Jared Kushner worked with Alex Cannon, Deputy General Counsel for the Trump Campaign, in creating the entity.
      <Citation number={161} />
      Prior to the formation of Save America, any money raised by the Trump Campaign could effectively only be spent on recount and election-contest related expenses, and to pay off campaign debt.
      <Citation number={162} />
      But now the money raised into Save America could allow President Trump to pay for his personal expenses, such as travel or hotel stays. After Save America was formed, it was added to the TMAGAC joint fundraising agreement with the RNC, and the percentage of the proceeds allocated to the Trump Campaign began to flow to Save America.
      <Citation number={163} />
    </Typography>

    <Typography className="indent">
      Importantly, Save America, as a leadership PAC, was not even legally permitted to pay for recount and election-contest related expenses in excess of the Federal Election Campaign Act (“FECA”) limit of $5,000.
      <Citation number={164} />
      Save America never hit that limit in 2020, as it spent no money on recount and election-contest related expenses.
      <Citation number={165} />
    </Typography>

    <Typography className="indent">
      Several reporters noticed the switch and contacted the Campaign asking about the “bait and switch” and the “misleading” nature of the emails. One reporter said directly: “it’s misleading to raise money for a committee marked on the website as an ‘election defense fund’ if it’s going to a leadership PAC.”
      <Citation number={166} />
      Another reporter asked, “Why is the campaign telling its supporters they are contributing toward an ‘Election Defense Fund’ if only a small percentage of those funds are actually going toward funding legal efforts?” and “How can the campaign justify directing 75% of contributions intended for a 2020 legal fund toward the President’s political action committee?”
      <Citation number={167} />
    </Typography>

    <Typography className="indent">
      The Trump Campaign came up with a messaging plan about this tactic, which President Trump personally approved.
      <Citation number={168} />
      Tim Murtaugh, the Trump Campaign’s communications director, repeatedly asked Justin Clark, the deputy campaign manager, whether they should respond to the reporters.
      <Citation number={169} />
      When Murtaugh flagged that the communications team was not responding to the reporters, Justin Clark said, “Good. Don’t.”
      <Citation number={170} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        B. Outlays to Trump-Associated Individuals and Companies
      </Typography>
    </Box>

    <Typography className="indent">
      The Trump Campaign spent the money on President Trump, giving donations to his associates, and keeping it for himself in Save America. Hundreds of millions of dollars that were raised to go towards “election defense” and “fighting voter fraud” were not spent that way at all. To the contrary, most of the funds remain unspent, and millions have been paid to companies that are known affiliates of President Trump, or payments to entities associated with former Trump administration officials. Since the election, former Trump officials who are still working for President Trump’s PACs, and are publicly receiving salaries as FEC-reported “payroll,” are also associated with these companies.
    </Typography>

    <Typography className="indent">
      For example, from July 2021 to the present, Save America has been paying approximately $9,700 per month to Dan Scavino,
      <Citation number={171} />
      a political adviser who served in the Trump administration as White House Deputy Chief of Staff.
      <Citation number={172} />
      Save America was also paying $20,000 per month to an entity called Hudson Digital LLC. Hudson Digital LLC was registered in Delaware twenty days after the attack on the Capitol, on January 26, 2021,
      <Citation number={173} />
      and began receiving payments from Save America on the day it was registered.
      <Citation number={174} />
      Hudson Digital LLC has received payments totaling over $420,000, all described as “Digital consulting.”
      <Citation number={175} />
      No website or any other information or mention of Hudson Digital LLC could be found online.
      <Citation number={176} />
      Though Hudson Digital LLC is registered as a Delaware company, the FEC Schedule B listing traces back to an address belonging to Dan and Catherine Scavino.
      <Citation number={177} />
    </Typography>

    <Typography className="indent">
      Nick Luna, President Trump’s former personal assistant and “body man,” was being paid from April 2021 to December 2021 approximately $12,000 per month by Save America for “payroll.”
      <Citation number={178} />
      The Make America Great Again PAC (MAGA PAC)—formerly the authorized committee of President Trump’s reelection campaign, Donald J. Trump for President— paid $20,000 per month to a limited liability corporation called Red State Partners LLC from April 2021 through October 2021, and Save America paid Red State Partners LLC $20,000 in February 2022.
      <Citation number={179} />
      The company was registered in Delaware on March 11, 2021
      <Citation number={180} />
      and has received a total of $170,000.
      <Citation number={181} />
      Though it is registered in Delaware, disclosures filed with the Federal Election Committee (FEC) list Red State Partners at an address in Miami, Florida, that is an address for Nick Luna and his wife, Cassidy Dumbauld.
      <Citation number={182} />
    </Typography>

    <Typography className="indent">
      Further, Vince Haley, Taylor Swindle, and Ross Worthington are corporate officers of a company known as Pericles LLC.
      <Citation number={183} />
      Haley is a former policy advisor to President Trump,
      <Citation number={184} />
      Swindle is the Chief Financial Officer for Gingrich 360,
      <Citation number={185} />
      and Ross Worthington is the former White House speechwriter
      <Citation number={186} />
      who wrote the speech President Trump delivered on the Ellipse on January 6th.
      <Citation number={187} />
      Pericles LLC was registered on January 27, 2021,
      <Citation number={188} />
      the day after Scavino’s Hudson Digital LLC, and, since then, has received payments from Save America totaling at least $352,700.
      <Citation number={189} />
    </Typography>

    <Typography className="indent">
      Another former speechwriter for President Trump, Robert Gabriel, Jr., has also been receiving payments from Save America. Gabriel was involved in writing the speech President Trump delivered on the White House Ellipse on January 6th, and specifically told the speechwriters, including Worthington, to reinsert previously removed incendiary lines about Vice President Pence into the speech.
      <Citation number={190} />
      This direction came after Vice President Pence told President Trump that he would not try to change the outcome of the election.
      <Citation number={191} />
      In September 2021, Gabriel formed called Gabriel Strategies LLC,
      <Citation number={192} />
      which began receiving payments from Save America the following month.
      <Citation number={193} />
      Since October 2021, Save America has paid Gabriel Strategies LLC at least $167,674.00.
      <Citation number={194} />
      For both Pericles and Gabriel Strategies, the description of the payments is always for “consulting” in political strategy or communications, and some payments are purported to include travel expenses.
    </Typography>

    <Typography className="indent">
      Through October 2022, Save America has paid nearly $100,000 in “strategy consulting” payments to Herve Pierre Braillard,
      <Citation number={195} />
      a fashion designer who has been dressing Melania Trump for years.
      <Citation number={196} />
    </Typography>

    <Typography className="indent">
      From January 2021 to June 2022, Save America has also reported over $2.1 million in “legal consulting.” Many firms perform different kinds of practice, but more than 67% of those funds went to law firms that are representing witnesses involved in the Select Committee’s investigation who were subpoenaed or invited to testify.
    </Typography>

    <Typography className="indent">
      Additionally, Save America has reported other expenditures, like:
    </Typography>

    <StyledListContainer>
      <ul>
        <li>
          <Typography>
            $1,000,000 donation to America First Policy Institute, home to several former Trump officials and witnesses subpoenaed to testify before the Committee.
            <Citation number={197} />
          </Typography>
        </li>
        <li>
          <Typography>
            $1,000,000 donation to Conservative Partnership Institute, a conservative nonprofit organization where Mark Meadows is a senior partner.
            <Citation number={198} />
          </Typography>
        </li>
        <li>
          <Typography>
            More than $10.6 million to Event Strategies, Inc., the preferred staging company for President Trump that staged the January 6th rally.
            <Citation number={199} />
          </Typography>
        </li>
        <li>
          <Typography>
            More than $327,000 in payments to the Trump Hotel Collection and Mar-A-Lago Club since the 2020 election.
            <Citation number={200} />
          </Typography>
        </li>
        <li>
          <Typography>
            An “event sponsorship fee” of $165,937.50 to American Conservative Union,
            <Citation number={201} />
            the Chairman of which is Matt Schlapp. Schlapp and his wife have offered to pay the legal fees of witnesses called to testify before the January 6th Committee and have extensive ties with former President Trump.
          </Typography>
        </li>
        <li>
          <Typography>
            A little over $140,000 to National Public Affairs, LLC,
            <Citation number={202} />
            a consulting company started by former Trump Campaign Manager Bill Stepien and Deputy Campaign Manager Justin Clark.
            <Citation number={203} />
            Stepien testified that he knew the claims of voter fraud were false, that he didn’t think what was happening was necessarily honest, and that he was stepping away from the Trump Campaign.
            <Citation number={204} />
            However, he continues to work and receive hundreds of thousands of dollars consulting for President Trump and several other congressional candidates who continue to spread false voter fraud claims related to the 2020 election.
            <Citation number={205} />
          </Typography>
        </li>
      </ul>
    </StyledListContainer>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        C. Payments to 2M Management LLC
      </Typography>
    </Box>

    <Typography className="indent">
      As described above, the Trump Campaign, after paying off its general election debt, raised millions of dollars that flowed into a segregated recount account (“Recount Account”) by encouraging donors to help pay for legal challenges to the election results. Pursuant to the FECA, the Trump Campaign could only spend these funds on a few limited purposes (e.g., for actual recounts and election-contest expenses or, in the case of surplus funds, donations to charitable organizations or transferring the funds to a national party committee’s separate, segregated account for election recounts).
      <Citation number={206} />
    </Typography>

    <Typography className="indent">
      Justin Clark told the Select Committee that he understood that, “[a]fter election day.... you can raise money for a recount and to pay off debt,”
      <Citation number={207} />
      and that “[t]he money going into the campaign, principal campaign committee, at that point, [after the election] was dead money. It couldn’t be spent on things.”
      <Citation number={208} />
      Alex Cannon agreed.
      <Citation number={209} />
      That’s why, after the election, the Trump Campaign set up the Recount Account—“a segregated restricted account [held] by the campaign”
      <Citation number={210} />
      —and raised money for the Recount Account through TMAGAC.
      <Citation number={211} />
    </Typography>

    <Typography className="indent">
      In February 2021, the Trump Campaign was converted into MAGA PAC.
      <Citation number={212} />
      In March 2021, MAGA PAC began disclosing on required FEC forms that it was paying millions of dollars to an eDiscovery vendor called 2M Document Management & Imaging LLC (“2M Management”) for what MAGA PAC described as “recount” and “Recount: Research Consulting.”
      <Citation number={213} />
    </Typography>

    <Typography className="indent">
      Although the MAGA PAC reported that 2M Management was being paid for recount-related expenses, 2M Management was primarily processing and reviewing documents slated to be produced by the National Archives and Records Administration in response to subpoenas from (1) the House Select Subcommittee on the Coronavirus Crisis (“Covid Subcommittee”) and (2) the Select Committee to Investigate the January 6th Attack.
      <Citation number={214} />
    </Typography>

    <Typography className="indent">
      From just March 2021 to May 2021, MAGA PAC paid 2M Management almost $1 million from the Recount Account to review documents related solely to the Covid Subcommittee.
      <Citation number={215} />
      Alex Cannon confirmed that he understood these payments to 2M Management came from the Recount Account.
      <Citation number={216} />
      Federal campaign finance law requires committees to accurately report information related to expenditures, including the purpose of payments. FEC regulations provide that the “purpose” be described in relevant reports through a brief statement of why the disbursement was made and must be sufficiently specific to make the purpose of the disbursement clear.
      <Citation number={217} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        IMPACT OF THE TRUMP CAMPAIGN’S FALSE CLAIMS
      </Typography>
    </Box>

    <Typography className="indent">
      Between the election and January 6th, the Trump Campaign sent out hundreds of emails urging President Trump’s supporters to “fight the Liberal MOB” and “join the Trump army.” Users on the same extreme social media platforms used to plan the attack on the Capitol repeatedly shared the “Official Election Defense Fund” donation links in the week following election day.
      <Citation number={218} />
      Links to donate were often accompanied by mentions of voter fraud and calls to save the country, mirroring the language of the fundraising emails and the countless discussions being held by the President’s supporters of coming to Washington, DC, on January 6th to “Stop the steal.”
      <Citation number={219} />
    </Typography>

    <Typography className="indent">
      On January 6th, while President Trump was speaking at the Ellipse rally and directing his supporters to march to the Capitol, his Campaign was also sending fundraising emails inflaming people to “fight back.” One email stated, “100 Members of Congress.... Join them in the FIGHT to DEFEND the Election.... <i>This is our last line of defense</i>.”
      <Citation number={220} />
      Another email stated, “TODAY will be a historic day in our Nation’s history. Congress will either certify, or object to, the Election results. Every single Patriot from across the Country must step up RIGHT NOW if we’re going to successfully DEFEND the integrity of this Election.”
      <Citation number={221} />
      A third email stated, “TODAY. This is our LAST CHANCE... The stakes have NEVER been higher. President Trump needs YOU to make a statement and publicly stand with him and FIGHT BACK.”
      <Citation number={222} />
    </Typography>

    <Typography className="indent">
      Thirty minutes after the last fundraising email was sent, the Capitol was breached. It was then and only then that TMAGAC fundraisers decided to stop sending emails containing baseless claims of election fraud.
      <Citation number={223} />
      Boedigheimer explained, “And at some point during that time, I don’t know if it was right then, if it was a little after, maybe a little before, but either Gary or Kevin kind of directed us to stop sending fundraising messages out.”
      <Citation number={224} />
      Cannon stated, “[O]n January 6th, Gary called me and said, [‘]are you seeing what’s happening? I’m obviously turning everything off.[’]”
      <Citation number={225} />
    </Typography>

    <Typography className="indent">
      After raising $250 million dollars on false voter fraud claims, mostly from small-dollar donors, President Trump did not spend it on fighting an election he knew he lost. Instead, a significant portion of the money was deposited into the Save America account and not used for the purposes the Campaign claimed it would be. President Trump got a war chest with millions of dollars, and the American people were left with the U.S. Capitol under attack.
    </Typography>

    <Typography className="indent">
      There is evidence suggesting that numerous defendants charged with violations related to the January 6th attack on the U.S. Capitol and others present on the Capitol grounds that day were motivated by false claims about the election.
      <Citation number={226} />
    </Typography>

    <Typography className="indent">
      Further, J. Doe, the Salesforce employee interviewed by the Select Committee, provided insight into the action that Salesforce took after the attack. Doe explained that after they became aware of the ongoing attack, they (Doe) took unilateral action to block the RNC’s ability to send emails through Salesforce’s platform.
      <Citation number={227} />
      Doe noted that the shutdown lasted until January 11, 2021, when senior Salesforce leadership directed Doe to remove the block from RNC’s Salesforce account.
      <Citation number={228} />
      Doe stated that Salesforce leadership told Doe that Salesforce would now begin reviewing RNC’s email campaigns to “make sure this doesn’t happen again.”
      <Citation number={229} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h2" color="primary">
        CONCLUSION
      </Typography>
    </Box>

    <Typography className="indent">
      In the weeks after the 2020 election leading up to January 6, 2021, President Trump’s Campaign and his allies sent his supporters a barrage of emails and text messages pushing lies about a stolen election and asking for contributions to challenge the outcome of the election. In reality, the funds raised went primarily towards paying down the Trump Campaign’s outstanding 2020 debt, financing President Trump’s newly created Save America PAC, and raising money for the RNC.
    </Typography>

    <Typography className="indent">
      Overall, only a small amount of the contributions ever went to President Trump’s recount account or were otherwise obviously used in connection with post-election recounts or litigation. As President Trump used the Big Lie as a weapon to attack the legitimacy of the 2020 election, his Campaign used that same Big Lie to raise millions of dollars based on false claims and unkept promises.
    </Typography>

    <Typography className="indent">
      Not only did President Trump lie to his supporters about the election, but he also ripped them off.
    </Typography>
  </Page>
)
