import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet4'));

export const Chapter22 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      More than a month before the Presidential election, the media reported that the Trump Campaign was already developing a fallback plan that would focus on overturning certain election results at the State level. An article published on September 23, 2020, in <i>The Atlantic</i> explained, “[a]ccording to sources in the Republican Party at the State and national levels, the Trump Campaign is discussing contingency plans to bypass election results and appoint loyal electors in battleground States where Republicans hold the legislative majority.”
      <Citation number={15} />
      Ominously, the same reporting predicted, almost exactly, what would later come to pass: “With a justification based on claims of rampant fraud, Trump would ask State legislators to set aside the popular vote and exercise their power to choose a slate of electors directly.”
      <Citation number={16} />
    </Typography>

    <Typography className="indent">
      Numerous senior Trump Campaign advisors—including Campaign Manager William Stepien, Deputy Campaign Manager and Senior Counsel Justin Clark, and President Trump’s lead attorney Rudolph Giuliani—all told the Select Committee that there was, indeed, a State-focused “strategy” or “track” to challenge the outcome of the election, which included pressing State legislators to challenge results in key States and to appoint new electoral college electors.
      <Citation number={17} />
    </Typography>

    <Typography className="indent">
      “You know, in the days after election day, later in that first week, bleeding into the second, as our numbers and data looked bleaker, internally we knew that,” Stepien told the Select Committee.
      <Citation number={18} />
      “As the AP [Associated Press] called the race, I think some surrounding the President were looking for different avenues to pursue.” That’s when Stepien remembered the concept first coming up.
      <Citation number={19} />
    </Typography>

    <Typography className="indent">
      Those around President Trump were pushing this idea, and pushing it hard.
    </Typography>

    <Typography className="indent">
      Just two days after the election, President Trump’s son, Donald Trump, Jr., forwarded to White House Chief of Staff Mark Meadows a suggestion that “State Assemblies can step in and vote to put forward the electoral slate[,] Republicans control Pennsylvania, Wisconsin, Michigan, North Carolina, etc. we get Trump electors” and so “we either have a vote WE control and WE win OR it gets kicked to Congress 6 January...”
      <Citation number={20} />
      Chief of Staff Meadows responded: “Working on this for pa,ga and nc already.”
      <Citation number={21} />
    </Typography>

    <Typography className="indent">
      Within one week after the election, Meadows had also sent or received several other similar messages:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>“The state legislature can take over the electoral process”</strong>
        <br />
        —Mark Meadows’s text to Georgia State Senator Marty Harbin.
        <Citation number={22} />
      </Typography>
      <Typography>
        <strong>“Agreed”</strong>
        <br />
        —Mark Meadows’s text to a different sender, who suggested that the Trump Administration “should get that out there” if they were “seriously considering the state legislature strategy.”
        <Citation number={23} />
      </Typography>
      <Typography>
        <strong>“I will tell him”</strong>
        <br />
        —Mark Meadows’s text to a sender who suggested President Trump “[s]tart building momentum for the state legislatures.”
        <Citation number={24} />
      </Typography>
      <Typography>
        <strong>“I love it”</strong>
        <br />
        —Mark Meadows’s text to Representative Andy Biggs, who relayed what he acknowledged as a “highly controversial” idea to have “Republican legislature’s [<i>sic</i>]” “appoint a look doors [<i>sic</i>] [electors].”
        <Citation number={25} />
      </Typography>
      <Typography>
        <strong>“...Why can’t the states of GA NC PENN and other R controlled state houses declare this is BS (where conflicts and election not called that night) and just send their own electors... I wonder if POTUS knows this... ” </strong>
        <br />
        —former Secretary of Energy Rick Perry to Mark Meadows.
        <Citation number={26} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Another White House official exploring such a plan less than a week after the election was Vince Haley, Deputy Assistant to the President for Policy, Strategy and Speechwriting. He suggested:
    </Typography>

    <Blockquote>
      <Typography>
        “...Imagine if every red state legislature slated zero electors. It would reveal that we are a red country. To do this we would have to jack this to the nth degree as a battle of tribes....”
        <Citation number={27} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Haley pushed this strategy in several texts and emails, including to Assistant to the President and Director of Presidential Personnel Johnny McEntee,
      <Citation number={28} />
      an individual Haley characterized as “a very trusted lieutenant” for President Trump, “a direct conveyor to Boss with ideas,” and “[a]t his side almost all the time.”
      <Citation number={29} />
    </Typography>

    <Typography className="indent">
      For Haley, however, purported election fraud was a way to justify President Trump-friendly legislatures changing the outcome of the election, but there were other reasons for doing so, too. Election fraud was “only one rationale for slating Trump electors,” Haley told McEntee, and “[w]e should baldly assert” that State legislators “have the constitutional right to substitute their judgment for a certified majority of their constituents” if that prevents socialism.
      <Citation number={30} />
      Haley added, “[i]ndependent of the fraud—or really along with that argument—Harrisburg [Pennsylvania], Madison [Wisconsin], and Lansing [Michigan] do not have to sit idly by and submit themselves to rule by Beijing and Paris,” proposing that radio hosts “rally the grassroots to apply pressure to the weak kneed legislators in those states...”
      <Citation number={31} />
    </Typography>

    <Typography className="indent">
      McEntee replied “Yes!” and then: “Let’s find the contact info for all these people now.”
      <Citation number={32} />
      Hours later, Haley sent him names and—in most cases—cell phone numbers for top GOP legislators in six States, suggesting “...for POTUS to invite them down for a WH meeting...”
      <Citation number={33} />
      ThePresident would later call several named in that message, including Rusty Bowers and Karen Fann in Arizona; Lee Chatfield and Mike Shirkey in Michigan; and Jake Corman in Pennsylvania.
      <Citation number={34} />
    </Typography>

    <Typography className="indent">
      Others weighed in with the President about a State-focused plan, too. Some were already looking ahead to January 6th.
    </Typography>

    <Typography className="indent">
      On November 8th, former Speaker of the House Newt Gingrich met President Trump at the White House.
      <Citation number={35} />
      Two days later, he sent a follow-up note to the President’s executive assistant titled “please give to POTUS[,] newt.”
      <Citation number={36} />
      It suggested that “[t]he only way Trump loses is rigged system” and added that President Trump could encourage “GOP legislatures elect not to send in electors,” forcing a House vote by State delegations on January 6th that Gingrich expected President Trump would win.
      <Citation number={37} />
      Meadows replied: “Thanks Speaker.”
      <Citation number={38} />
    </Typography>

    <Typography className="indent">
      Newsmax CEO Christopher Ruddy had President Trump’s ear and reportedly spoke with him by phone at least four times before December.
      <Citation number={39} />
      He forwarded a memo to other close advisors of the President recommending that the Trump team persuade one or more Republican-led chambers in Arizona, Georgia, Michigan, Pennsylvania, Wisconsin, and even Minnesota to “pick a separate competitive State slate of Electors,” which the memo predicted might turn January 6th into “a cat-fight in Congress wherein VP Pence is Presiding.”
      <Citation number={40} />
    </Typography>

     <Image
      src="/images/image-037.jpg"
      alt="unknown, speaking"
      credit="(Photo by Alex Wong/Getty Images)"
    />

    <Typography className="indent">
      Attorney and conservative activist Cleta Mitchell was recruited by Mark Meadows immediately after the election to assist the Trump Campaign’s legal work.
      <Citation number={41} />
      By November 5th, she emailed Dr. John Eastman of Chapman University,
      <Citation number={42} />
      who would later play an outsized role pushing a theory about what Vice President Pence could or couldn’t do during the January 6th joint session of Congress that is detailed in Chapter 5 of this Report. In her email, Mitchell asked Eastman to write a memo justifying an idea that State legislators “reclaim” the power to pick electors and asked, rhetorically, “Am I crazy?”
      <Citation number={43} />
      Dr. Eastman wrote the memo, entitled “The Constitutional Authority of State Legislatures to Choose Electors,” and sent it along for sharing “widely.”
      <Citation number={44} />
    </Typography>

    <Typography className="indent">
      According to the Office of Presidential Scheduling, President Trump was scheduled to meet in the Oval Office on November 10th with Morgan Warstler and John Robison, Texas entrepreneurs close to former Governor Rick Perry.
      <Citation number={45} />
      The next day, Warstler tweeted that he “[w]as in Oval yesterday,”
      <Citation number={46} />
      and months later wrote that “I told whole Trump team in Oval” that “State legislatures can choose the electors-no matter what current state law OR state courts say.”
      <Citation number={47} />
    </Typography>

    <Typography className="indent">
      After this apparent meeting, John Robison sent the White House an email entitled “URGENT follow up to our Tuesday Meeting with POTUS,” that he asked be printed out for the President to “explain the move forward plan for what was discussed.”
      <Citation number={48} />
      The email stated that “[President Trump] liked the plan we presented to use a parallel path of state legislators,” and the attached memo proposed hundreds of briefings for State lawmakers by President Trump’s surrogates and members of the Freedom Caucus.
      <Citation number={49} />
      The email envisioned President Trump hosting “4+ MONSTER RALLY-TRIALS” with “[t]ens of thousands of Trump voters staring up at the GOP state legislators from their districts who ALONE control which slate of electors their state will submit,” a proposal that seemed to foreshadow the State hearings that Rudolph Giuliani and President Trump championed less than a month later.
      <Citation number={50} />
    </Typography>

    <Typography className="indent">
      Deputy White House Chief of Staff Dan Scavino called Robison’s message “Bat. Shit. Crazy,” but the President’s executive assistant, who was asked to print it for the President, wrote “Printed,” and may have shared it with the President anyway.
      <Citation number={51} />
    </Typography>

    <Typography className="indent">
      By then, President Trump was engaged. According to Stepien, his Campaign Manager, the State-focused strategy came up in a November 11th meeting among close advisors as “something to consider.”
      <Citation number={52} />
      At that point, the election had been called, but the President “was very interested in keeping pathways to victory open, so [Stepien] believe[d] [the President] found the concept intriguing.”
      <Citation number={53} />
      Then, the plan “just started happening” even though it was something Stepien, “honestly, kind of dismissed at hand,” characterizing it as one “of the crazy, crazier ideas that w[as] thrown out, in and around that time.”
      <Citation number={54} />
    </Typography>

    <Typography className="indent">
      But not everyone was convinced. On November 19th, the prior Republican Presidential nominee, Senator Mitt Romney (R-UT), issued a harsh public condemnation of President Trump’s open and notorious efforts to overturn the election:
    </Typography>

    <Blockquote>
      <Typography>
        Having failed to make even a plausible case of widespread fraud or conspiracy before a court of law, the President has now resorted to overt pressure on state and local officials to subvert the will of the people and overturn the election. It is difficult to imagine a worse, more undemocratic action by a sitting American President.
        <Citation number={55} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Senator Romney was right to identify and decry President Trump’s actions. And yet, in hindsight, it is clear that the effort to pressure State and local officials by the Trump team was only just getting started.
    </Typography>
  </Page>
)
