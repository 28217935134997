import { lazy } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet2'));

const StyledTable = styled('div')(({ theme }) => `
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.palette.primary.main};
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  padding: ${theme.spacing(2)};

  .group {
    border-bottom: 1px solid ${theme.palette.primary.main};
    margin-bottom: ${theme.spacing(2)};
  }

  .row {
    display: flex;
    justify-content: space-between;

    & > div {
      width: 48%;
    }

    &.who p {
      font-weight: bold;
    }

    &.text p {
      font-style: italic;
      color: ${theme.palette.primary.main};
    }
  }
`);      

export const TheBigLie = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      In the Committee’s hearings, we presented evidence of what ultimately became a multi-part plan to overturn the 2020 Presidential election. That evidence has led to an overriding and straight forward conclusion: the central cause of January 6th was one man, former President Donald Trump, whom many others followed. None of the events of January 6th would have happened without him.
    </Typography>

    <Typography className="indent">
      In the weeks before election day 2020, Donald Trump’s campaign experts, including his campaign manager Bill Stepien, advised him that the election results would not be fully known on election night.
      <Citation number={23} />
      This was because certain States would not begin to count absentee and other mail-in votes until election day or after election-day polls had closed.
      <Citation number={24} />
      Because Republican voters tend to vote in greater numbers on election day and Democratic voters tend to vote in greater numbers in advance of election day, it was widely anticipated that Donald Trump could initially appear to have a lead, but that the continued counting of mail-in, absentee and other votes beginning election night would erode and could overcome that perceived lead.
      <Citation number={25} />
      Thus, as President Trump’s campaign manager cautioned, understanding the results of the 2020 election would be a lengthy “process,” and an initial appearance of a Trump lead could be a “redmirage.”
      <Citation number={26} />
      This was not unique to the 2020 election; similar scenarios had played out in prior elections as well.
      <Citation number={27} />
    </Typography>

    <Typography className="indent">
      Prior to the 2020 election, Donald Trump’s campaign manager Bill Stepien, along with House Republican Leader Kevin McCarthy, urged President Trump to embrace mail-in voting as potentially beneficial to the Trump Campaign.
      <Citation number={28} />
      Presidential advisor and son-in-law Jared Kushner recounted others giving Donald Trump the same advice: “[M]ail in ballots could be a good thing for us if we looked at it correctly.”
      <Citation number={29} />
      Multiple States, including Florida, had successfully utilized mail-in voting in prior elections, and in 2020.
      <Citation number={30} />
      Trump White House Counselor Hope Hicks testified: “I think he [President Trump] understood that a lot of people vote via absentee ballot in places like Florida and have for a long time and that it’s worked fine.”
      <Citation number={31} />
      Donald Trump won in numerous States that allowed no-excuse absentee voting in 2020, including Alaska, Florida, Idaho, Iowa, Kansas, Montana, North Carolina, North Dakota, Ohio, Oklahoma, South Dakota, and Wyoming.
      <Citation number={32} />
    </Typography>

    <Typography className="indent">
      On election night 2020, the election returns were reported in almost exactly the way that Stepien and other Trump Campaign experts predicted, with the counting of mail-in and absentee ballots gradually diminishing President Trump’s perceived lead. As the evening progressed, President Trump called in his campaign team to discuss the results. Stepien and other campaign experts advised him that the results of the election would not be known for some time, and that he could not truthfully declare victory.
      <Citation number={33} />
      “It was far too early to be making any calls like that. Ballots—ballots were still being counted. Ballots were still going to be counted for days.”
      <Citation number={34} />
    </Typography>

    <Typography className="indent">
      Campaign Senior Advisor Jason Miller told the Select Committee that he argued against declaring victory at that time as well, because “it was too early to say one way [or] the other” who had won.
      <Citation number={35} />
      Stepien advised Trump to say that “votes were still being counted. It’s too early to tell, too early to call the race but, you know, we are proud of the race we run—we ran and we, you know, think we’re—think we’re in a good position” and would say more in the coming days.
      <Citation number={36} />
    </Typography>

    <Typography className="indent">
      President Trump refused, and instead said this in his public remarks that evening: “This is a fraud on the American public. This is an embarrassment to our country. We were getting ready to win this election. Frankly, we did win this election. We did win this election.... We want all votingtostop.”
      <Citation number={37} />
      And on the morning of November 5th,he tweeted“STOP THE COUNT!”
      <Citation number={38} />
      Halting the counting of votes at that point would have violated both State and Federal laws.
      <Citation number={39} />
    </Typography>

    <Typography className="indent">
      According to testimony received by the Select Committee, the only advisor present who supported President Trump’s inclination to declare victory was Rudolph Giuliani, who appeared to be inebriated.
      <Citation number={40} />
      President Trump’s Attorney General, William Barr, who had earlier left the election night gathering, perceived the President’s statement this way:
    </Typography>

    <Blockquote>
      <Typography>
        [R]ight out of the box on election night, the President claimed that there was major fraud underway. I mean, this happened, as far as I could tell, before there was actually any potential of looking at evidence. He claimed there was major fraud. And it seemed to be based on the dynamic that, at the end of the evening, a lot of Democratic votes came in which changed the vote counts in certain States, and that seemed to be the basis for this broad claim that there was major fraud. And I didn’t think much of that, because people had been talking for weeks and everyone understood for weeks that that was going to be what happened on election night....
        <Citation number={41} />
      </Typography>
    </Blockquote>

    <Image
      src="/images/image-009.jpg"
      alt="President Trump declares victory in a speech at an election night party."
      caption="President Trump declares victory in a speech at an election night party."
      credit="(Photo by Chip Somodevilla/Getty Images)"
    />

    <Typography className="indent">
      President Trump’s decision to declare victory falsely on election night and, unlawfully, to call for the vote counting to stop, was not a spontaneous decision. It was premeditated. The Committee has assembled a range of evidence of President Trump’s preplanning for a false declaration of victory. This includes multiple written communications on October 31 and November 3, 2020, to the White House by Judicial Watch President Tom Fitton.
      <Citation number={42} />
      This evidence demonstrates that Fitton was in direct contact with President Trump and understood that President Trump would falsely declare victory on election night and call for vote counting to stop. The evidence also includes an audio recording of President Trump’s advisor Steve Bannon, who said this on October 31, 2020, to a group of his associates from China:
    </Typography>

    <Blockquote>
      <Typography>
        And what Trump’s gonna do is just declare victory, right? He’s gonna declare victory. But that doesn’t mean he’s a winner. He’s just gonna say he’s a winner... The Democrats—more of our people vote early that count. Theirs vote in mail. And so they’re gonna have a natural disadvantage, and Trump’s going to take advantage of it—that’s our strategy. He’s gonna declare himself a winner. So when you wake up Wednesday morning, it’s going to be a firestorm.... Also, if Trump, if Trump is losing, by 10 or 11 o’clock at night, it’s going to be even crazier. No, because he’s gonna sit right there and say “They stole it. I’m directing the Attorney General to shut down all ballot places in all 50 states.” It’s going to be, no, he’s not going out easy. If Trump—if Biden’s winning, Trump is going to do some crazy shit.
        <Citation number={43} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Also in advance of the election, Roger Stone, another outside advisor to President Trump, made this statement:
    </Typography>

    <Blockquote>
      <Typography>
        I really do suspect it will still be up in the air. When that happens, the key thing to do is to claim victory. Possession is nine-tenths of the law. No, we won. Fuck you, Sorry. Over. We won. You’re wrong. Fuck you.
        <Citation number={44} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      On election day, Vice President Pence’s staff, including his Chief of Staff and Counsel, became concerned that President Trump might falsely claim victory that evening. The Vice President’s Counsel, Greg Jacob, testified about their concern that the Vice President might be asked improperly to echo such a false statement.
      <Citation number={45} />
      Jacob drafted a memorandum with this specific recommendation: “[I]t is essential that the Vice President not be perceived by the public as having decided questions concerning disputed electoral votes prior to the full development of all relevant facts.”
      <Citation number={46} />
    </Typography>

    <Typography className="indent">
      Millions of Americans believed that President Trump was telling the truth on election night—that President Trump actually had proof the election was stolen and that the ongoing counting of votes was an act of fraud.
    </Typography>

    <Typography className="indent">
      As votes were being counted in the days after the election, President Trump’s senior campaign advisors informed him that his chances of success were almost zero.
    </Typography>

    <Typography className="indent">
      Former Trump Campaign Manager Bill Stepien testified that he had come to this conclusion by November 7th, and told President Trump:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: What was your view on the state of the election at that point?
      </Typography>
      <Typography>
        <strong>Stepien</strong>: You know, very, very, very bleak. You know, I—we told him—the group that went over there outlined, you know, my belief and chances for success at this point. And then we pegged that at, you know, 5, maybe 10 percent based on recounts that were—that, you know, either were automatically initiated or could be—could be initiated based on, you know, realistic legal challenges, not all the legal challenges that eventually were pursued. But, you know, it was—you know, my belief is that it was a very, very—5 to 10 percent is not a very good optimistic outlook.
        <Citation number={47} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Trump Campaign Senior Advisor Jason Miller testified to the Committee about this exchange:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Miller</strong>: I was in the Oval Office. And at some point in the conversation Matt Oczkowski, who was the lead data person, was brought on, and I remember he delivered to the President in pretty blunt terms that he was going to lose.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: And that was based, Mr. Miller, on Matt and the data team’s assessment of this sort of county-by-county, State-byState results as reported?
      </Typography>
      <Typography>
        <strong>Miller</strong>: Correct.
        <Citation number={48} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      In one of the Select Committee’s hearings, former Fox News political editor Chris Stirewalt was asked what the chance President Trump had of winning the election after November 7th, when the votes were tallied and every news organization had called the race for now-President Biden. His response: “None.”
      <Citation number={49} />
    </Typography>

    <Typography className="indent">
      As the Committee’s hearings demonstrated, President Trump made a series of statements to White House staff and others during this time period indicating his understanding that he had lost.
      <Citation number={50} />
      President Trump also took consequential actions reflecting his understanding that he would be leaving office on January 20th. For example, President Trump personally signed a Memorandum and Order instructing his Department of Defense to withdraw all military forces from Somalia by December 31, 2020, and from Afghanistan by January 15, 2021.
      <Citation number={51} />
      General Keith Kellogg (ret.), who had been appointed by President Trump as Chief of Staff for the National Security Council and was Vice President Pence’s National Security Advisor on January 6th, told the Select Committee that “[a]n immediate departure that that memo said would have been catastrophic. It’s the same thing what President Biden went through. It would have been a debacle.”
      <Citation number={52} />
    </Typography>

    <Typography className="indent">
      In the weeks that followed the election, President Trump’s campaign experts and his senior Justice Department officials were informing him and others in the White House that there was no genuine evidence of fraud sufficient to change the results of the election. For example, former Attorney General Barr testified:
    </Typography>

    <Blockquote>
      <Typography>
        And I repeatedly told the President in no uncertain terms that I did not see evidence of fraud, you know, that would have affected the outcome of the election. And, frankly, a year and a half later, I haven’t seen anything to change my mind on that.
        <Citation number={53} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Former Trump Campaign lawyer Alex Cannon, who was asked to oversee incoming information about voter fraud and set up a voter fraud tip line, told the Select Committee about a pertinent call with White House Chief of Staff Mark Meadows in November 2020:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Cannon</strong>: So I remember a call with Mr. Meadows where Mr. Meadows was asking me what I was finding and if I was finding anything. And I remember sharing with him that we weren’t finding anything that would be sufficient to change the results in any of the key States.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: When was that conversation?
      </Typography>
      <Typography>
        <strong>Cannon</strong>: Probably in November. Mid to late November....
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: And what was Mr. Meadows’s reaction to that information?
      </Typography>
      <Typography>
        <strong>Cannon</strong>: I believe the words he used were: “So there is no there there?”
        <Citation number={54} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      President Trump’s Campaign Manager Bill Stepien recalled that President Trump was being told “wild allegations” and that it was the Campaign’s job to “track [the allegations] down”:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Committee Staff</strong>: You said that you were very confident that you were telling the President the truth in your dealings with [him]. And had your team been able to verify any of these allegations of fraud, would you have reported those to the President?
      </Typography>
      <Typography>
        <strong>Stepien</strong>: Sure.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: Did you ever have to report that—
      </Typography>
      <Typography>
        <strong>Stepien</strong>: One of my frustrations would be that, you know, people would throw out, you know, these reports, these allegations, these things that they heard or saw in a State, and they’d tell President Trump. And, you know, it would be the campaign’s job to track down the information, the facts. And, you know, President Trump, you know—if someone’s saying, hey, you know, all these votes aren’t counted or were miscounted, you know, if you’re down in a State like Arizona, you liked hearing that. It would be our job to track it down and come up dry because the allegation didn’t prove to be true. And we’d have to, you know, relay the news that, yeah, that tip that someone told you about those votes or that fraud or, you know, nothing came of it.
      </Typography>
      <Typography>
        That would be our job as, you know, the truth telling squad and, you know, not—not a fun job to be, you know, much—it’s an easier job to be telling the President about, you know, wild allegations. It’s a harder job to be telling him on the back end that, yeah, that wasn’t true.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: How did he react to those types of conversations where you [told] him that an allegation or another wasn’t true?
      </Typography>
      <Typography>
        <strong>Stepien</strong>: He was—he had—usually he had pretty clear eyes. Like, he understood, you know—you know, we told him where we thought the race was, and I think he was pretty realistic with our viewpoint, in agreement with our viewpoint of kind of the forecast and the uphill climb we thought he had.
        <Citation number={55} />
     </Typography>
    </Blockquote>

    <Typography className="indent">
      Trump Campaign Senior Advisor Jason Miller told the Committee that he informed President Trump “several” times that “specific to election day fraud and irregularities, there were not enough to overturn the election.”
      <Citation number={56} />
    </Typography>

    <Typography className="indent">
      Vice President Pence has also said publicly that he told President Trump there was no basis to allege that the election was stolen. When a reporter recently asked “Did you ever point blank say to the President [that] we lost this election?,” Pence responded that “I did... Many times.”
      <Citation number={57} />
      Pence has also explained:
    </Typography>

    <Blockquote>
      <Typography>
        There was never evidence of widespread fraud. I don’t believe fraud changed the outcome of the election. But the President and the Campaign had every right to have those examined in court. But I told the President that, once those legal challenges played out, he should simply accept the outcome of the election and move on.
        <Citation number={58} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The General Counsel of President Trump’s campaign, Matthew Morgan, informed members of the White House staff, and likely many others, of the Campaign’s conclusion that none of the allegations of fraud and irregularities could be sufficient to change the outcome of the election:
    </Typography>

    <Blockquote>
      <Typography>
        What was generally discussed on that topic was whether the fraud, maladministration, abuse, or irregularities, if aggregated and read most favorably to the campaign, would that be outcome determinative. And I think everyone’s assessment in the room, at least amongst the staff, Marc Short, myself, and Greg Jacob, was that it was not sufficient to be outcome determinative.
        <Citation number={59} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      In a meeting on November 23rd, Barr told President Trump that the Justice Department was doing its duty by investigating every fraud allegation “if it’s specific, credible, and could’ve affected the outcome,” but that “they’re just not meritorious. They’re not panning out.”
      <Citation number={60} />
    </Typography>

    <Typography className="indent">
      Barr then told the Associated Press on December 1st that the Department had “not seen fraud on a scale that could have effected a different out come in the election.”
      <Citation number={61} />
      Next, he reiterated this point in private meetings with the President both that afternoon and on December 14th, as well as in his final press conference as Attorney General later that month.
      <Citation number={62} />
      The Department of Homeland Security had reached a similar determination two weeks earlier: <strong>“There is no evidence that any voting system deleted or lost votes, changed votes,or was in any way compromised.”</strong>
      <Citation number={63} />
    </Typography>

    <Typography className="indent">
      In addition, multiple other high ranking Justice Department personnel appointed by President Trump also informed him repeatedly that the allegations were false. As January 6th drew closer, Acting Attorney General Rosen and Acting Deputy Attorney General Donoghue had calls with President Trump on almost a daily basis explaining in detail what the Department’s investigations showed.
      <Citation number={64} />
      Acting Deputy Attorney General Richard Donoghue told the Select Committee that he and Acting Attorney General Rosen tried “to put it in very clear terms to the President. And I said something to the effect of ‘Sir, we’ve done dozens of investigations, hundreds of interviews. The major allegations are not supported by the evidence developed. We’ve looked in Georgia, Pennsylvania, Michigan, Nevada. We’re doing our job.’”
      <Citation number={65} />
      On December 31st, Donoghue recalls telling the President that “people keep telling you these things and they turn out not to be true.”
      <Citation number={66} />
      And then on January 3rd, Donoghue reiterated this point with the President:
    </Typography>

    <Blockquote>
      <Typography>
        [A]s in previous conservations, we would say to him, you know, “We checked that out,and there’s nothing to it.”
        <Citation number={67} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Acting Attorney General Rosen testified before the Select Committee that “the common element” of all of his communications with President Trump was President Trump urging the Department to find widespread fraud that did not actually exist. None of the Department’s investigations identified any genuine fraud sufficient to impact the election outcome:
    </Typography>

    <Blockquote>
      <Typography>
        During my tenure as the Acting Attorney General, which began on December 24 of [2020], the Department of Justice maintained the position, publicly announced by former Attorney General William Barr, that the Department had been presented with no evidence of widespread voter fraud in a scale sufficient to change the outcome of the 2020 election.
        <Citation number={68} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      As President Trump was hearing from his campaign and his Justice Department that the allegations of widespread fraud were not supported by the evidence, his White House legal staff also reached the same conclusions, and agreed specifically with what Barr told President Trump. Both White House Counsel Pat Cipollone and White House Senior Advisor Eric Herschmann reinforced to President Trump that the Justice Department was doing its duty to investigate allegations of supposed voter fraud.
      <Citation number={69} />
    </Typography>

    <Typography className="indent">
      Cipollone told the Select Committee that he “had seen no evidence of massive fraud in the election” and that he “forcefully” made this point “over and over again.” For example, during a late-night group meeting with President Trump on December 18th, at which he and Herschmann urged Trump not to heed the advice of several election conspiracists at the meeting:
    </Typography>

    <Blockquote>
      <Typography>
        <strong>Cipollone</strong>: They didn’t think that we were, you know—they didn’t think we believed this, you know, that there had been massive fraud in the election, and the reason they didn’t think we believed it is because we didn’t.
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: And you articulated that forcefully to them during the meeting?
      </Typography>
      <Typography>
        <strong>Cipollone</strong>: I did, yeah. I had seen no evidence of massive fraud in the election.... At some point, you have to deliver with the evidence. And I—again, I just to go back to what [Barr] said, he had not seen and I was not aware of any evidence of fraud to the extent that it would change the results of the election. That was made clear to them, okay, over and over again.
        <Citation number={70} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Similarly, White House Attorney Eric Herschmann was also very clear about his views:
    </Typography>

    <Blockquote>
      <Typography>
        [T]hey never proved the allegations that they were making, and they were trying to develop.
        <Citation number={71} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      In short, President Trump was informed over and over again, by his senior appointees, campaign experts and those who had served him for years, that his election fraud allegations were nonsense.
    </Typography>

    <Typography className="indent">
      How did President Trump continue to make false allegations despite all of this unequivocal information? President Trump sought out those who were not scrupulous with the facts, and were willing to be dishonest. He found a new legal team to assert claims that his existing advisors and the Justice Department had specifically informed him were false. President Trump’s new legal team, headed by Rudolph Giuliani, and their allies ultimately lost dozens of election lawsuits in Federal and State courts.
    </Typography>

    <Typography className="indent">
      The testimony of Trump Campaign Manager Bill Stepien helps to put this series of events in perspective. Stepien described his interaction with Giuliani as an intentional “self-demotion,” with Stepien stepping aside once it became clear that President Trump intended to spread falsehoods.
    </Typography>

    <Typography className="indent">
      Stepien knew the President’s new team was relying on unsupportable accusations, and he refused to be associated with their approach:
    </Typography>

    <Blockquote>
      <Typography>
        There were two groups of family. We called them kind of my team and Rudy’s team. I didn’t mind being characterized as being part of “team normal,” as reporters, you know, kind of started to do around that point in time.
        <Citation number={72} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Having worked for Republican campaigns for over two decades, Stepien said, “I think along the way I’ve built up a pretty good -- I hope a good reputation for being honest and professional, and I didn’t think what was happening was necessarily honest or professional at that point in time.”
      <Citation number={73} />
    </Typography>

    <Typography className="indent">
      As Giuliani visited Campaign headquarters to discuss election litigation, the Trump Campaign’s professional staff began to view him as unhinged.
      <Citation number={74} />
      In addition, multiple law firms previously engaged to work for the Trump Campaign decided that they could not participate in the strategy being instituted by Giuliani. They quit. Campaign General Counsel Matthew Morgan explained that he had conversations with “probably all of our counsel who [we]re signed up to assist on election day as they disengaged with the campaign.”
      <Citation number={75} />
      The “general consensus was that the law firms were not comfortable making the arguments that Rudy Giuliani was making publicly.”
      <Citation number={76} />
      When asked how many outside firms expressed this concern, Morgan recalled having “a similar conversation with most all of them.”
      <Citation number={77} />
    </Typography>

    <Blockquote>
      <Typography>
        Stepien grew so wary of the new team that he locked Giuliani out of his office:
      </Typography>
      <Typography>
        <strong>Committee Staff</strong>: Yeah. I’m getting the sense from listening to you here for a few hours that you sort of chose to pull back, that you were uncomfortable with what Mr. Giuliani and others were saying and doing and, therefore, you were purposefully stepping back from a day-to-day role as the leader of the campaign. Is that—I don’t want to put words in your mouth. Is that accurate?
      </Typography>
      <Typography>
        <strong>Stepien</strong>: That’s accurate. That’s accurate. You know, I had my assistant -- it was a big glass kind of wall office in our headquarters, and I had my assistant lock my door. I told her, don’t let anyone in. You know, I’ll be around when I need to be around. You know, tell me what I need to know. Tell me what’s going on here, but, you know, you’re going to see less of me.
      </Typography>
      <Typography className="indent">
        And, you know, sure enough, you know, Mayor Giuliani tried to, you know, get in my office and ordered her to unlock the door, and she didn’t do that, you know. She’s, you know, smart about that. But your words are ones I agree with.
        <Citation number={78} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Over the weeks that followed, dozens of judges across the country specifically rejected the allegations of fraud and irregularities being advanced by the Trump team and their allies. For example, courts described the arguments as “an amalgamation of theories, conjecture, and speculation,” “allegations ... sorely wanting of relevant or reliable evidence,” “strained legal arguments without merit,” assertions that “did not prove by any standard of proof that any illegal votes were cast and counted,” and even a “fundamental and obvious misreading of the Constitution.”
      <Citation number={79} />
    </Typography>

    <Typography className="indent">
      Reflecting back on this period, Trump Campaign Communications Director Tim Murtaugh texted colleagues in January 2021 about a news report that the New York State Bar was considering expelling Rudolph Giuliani over the Ellipse rally: “Why wouldn’t they expel him based solely on the outrageous lies he told for 2 1/2 months?”
      <Citation number={80} />
    </Typography>

    <Typography className="indent">
      This is exactly what ultimately came to pass. When suspending his license, a New York court said that Giuliani “communicated demonstrably false and misleading statements to courts, lawmakers and the public at large in his capacity as lawyer for former President Donald J. Trump and the Trump campaign in connection with Trump’s failed effort at reelection in 2020.”
      <Citation number={81} />
      The court added that “[t]he seriousness of [Giuliani’s] uncontroverted misconduct cannot be overstated.”
      <Citation number={82} />
    </Typography>

    <Typography className="indent">
      Other Trump lawyers were sanctioned for making outlandish claims of election fraud without the evidence to back them up, including Sidney Powell, Lin Wood and seven other pro-Trump lawyers in a case that a Federal judge described as “a historic and profound abuse of the judicial process”:
    </Typography>

    <Blockquote>
      <Typography>
        It is one thing to take on the charge of vindicating rights associated with an allegedly fraudulent election. It is another to take on the charge of deceiving a federal court and the American people into believing that rights were infringed, without regard to whether any laws or rights were in fact violated. This is what happened here.
        <Citation number={83} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      A group of prominent Republicans have more recently issued a report— titled Lost, Not Stolen—examining “every count of every case brought in these six battleground states” by President Trump and his allies. The report concludes “that Donald Trump and his supporters had their day in court and failed to produce substantive evidence to make their case.”
      <Citation number={84} />
      President Trump and his legal allies “failed because of a lack of evidence and not because of erroneous rulings or unfair judges.... In many cases, after making extravagant claims of wrongdoing, Trump’s legal representatives showed up in court or state proceedings empty-handed, and then returned to their rallies and media campaigns to repeat the same unsupported claims.”
      <Citation number={85} />
    </Typography>

    <Typography className="indent">
      There is no reasonable basis for the allegation that these dozens of rulings by State and Federal courts were somehow politically motivated.
      <Citation number={86} />
      The outcome of these suits was uniform regardless of who appointed the judges. One of the authors of Lost, Not Stolen, longtime Republican election lawyer Benjamin Ginsberg, testified before the Select Committee that “in no instance did a court find that the charges of fraud were real,” without variation based on the judges involved.
      <Citation number={87} />
      Indeed, eleven of the judges who ruled against Donald Trump and his supporters were appointed by Donald Trump himself.
    </Typography>

    <Typography className="indent">
      One of those Trump nominees, Judge Stephanos Bibas of the U.S. Court of Appeals for the Third Circuit, rejected an appeal by the Trump Campaign claiming that Pennsylvania officials “did not undertake any meaningful effort” to fight illegal absentee ballots and uneven treatment of voters across counties.
      <Citation number={88} />
      Judge Bibas wrote in his decision that “calling an election unfair does not make it so. Charges require specific allegations and then proof. We have neither here.”
      <Citation number={89} />
      Another Trump nominee, Judge Brett Ludwig of the Eastern District of Wisconsin, ruled against President Trump’s lawsuit alleging that the result was skewed by illegal procedures that governed drop boxes, ballot address information, and individuals who claimed “indefinitely confined” status to vote from home.
      <Citation number={90} />
      Judge Ludwig wrote in his decision, that “[t]his Court has allowed plaintiff the chance to make his case and he has lost on the merits” because the procedures used “do not remotely rise to the level” of breaking Wisconsin’s election rules.
      <Citation number={91} />
    </Typography>

    <Typography className="indent">
      Nor is it true that these rulings focused solely on standing, or procedural issues. As Ginsberg confirmed in his testimony to the Select Committee, President Trump’s team “did have their day in court.”
      <Citation number={92} />
      Indeed,heand his co-authors determined in their report that 30 of these post-election cases were dismissed by a judge after an evidentiary hearing had been held, and many of these judges explicitly indicated in their decisions that the evidence presented by the plaintiffs was wholly insufficient on the merits.
      <Citation number={93} />
    </Typography>

    <Image
      src="/images/image-010.jpg"
      alt="Rudolph Giuliani, Bernard Kerik, and other hold a press conference at Four Seasons Total Landscaping on November 7, 2020 falsely claiming Donald Trump had won the state of Pennsylvania."
      caption="Rudolph Giuliani, Bernard Kerik, and other hold a press conference at Four Seasons Total Landscaping on November 7, 2020 falsely claiming Donald Trump had won the state of Pennsylvania."
      credit="(Photo by Chris McGrath/Getty Images)"
    />

    <Typography className="indent">
      Ultimately, even Rudolph Giuliani and his legal team acknowledged that they had no definitive evidence of election fraud sufficient to change the election outcome. For example, although Giuliani repeatedly had claimed in public that Dominion voting machines stole the election, he admitted during his Select Committee deposition that “I do not think the machines stole the election.”
      <Citation number={94} />
      An attorney representing his lead investigator, Bernard Kerik, declared in a letter to the Select Committee that “it was impossible for Kerik and his team to determine conclusively whether there was widespread fraud or whether that widespread fraud would have altered the outcome of the election.”
      <Citation number={95} />
      Kerik also emailed President Trump’s chief of staff on December 28, 2020, writing: “We can do all the investigations we want later, but if the president plans on winning, it’s the legislators that have to be moved and this will do just that.”
      <Citation number={96} />
      Other Trump lawyers and supporters, Jenna Ellis, John Eastman, Phil Waldron, and Michael Flynn, all invoked their Fifth Amendment privilege against self-incrimination when asked by the Select Committee what supposed proof they uncovered that the election was stolen.
      <Citation number={97} />
      Not a single witness--nor any combination of witnesses--provided the Select Committee with evidence demonstrating that fraud occurred on a scale even remotely close to changing the outcome in any State.
      <Citation number={98} />
    </Typography>

    <Typography className="indent">
      By mid-December 2020, Donald Trump had come to what most of his staff believed was the end of the line. The Supreme Court rejected a lawsuit he supported filed by the State of Texas in the Supreme Court, and Donald Trump had this exchange, according to Special Assistant to the President Cassidy Hutchinson:
    </Typography>

    <Blockquote>
      <Typography>
        The President was fired up about the Supreme Court decision. And so I was standing next to [Chief of Staff Mark] Meadows, but I had stepped back... The President [was] just raging about the decision and how it’s wrong, and why didn’t we make more calls, and just this typical anger outburst at this decision... And the President said I think—so he had said something to the effect of, “I don’t want people to know we lost, Mark. This is embarrassing. Figure it out. We need to figure it out. I don’t want people to know that we lost.”
        <Citation number={99} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      On December 14, 2020, the Electoral College met to cast and certify each State’s electoral votes. By this time, many of President Trump’s senior staff, and certain members of his family, were urging him to concede that he had lost.
    </Typography>

    <Typography className="indent">
      Labor Secretary Gene Scalia told the Committee that he called President Trump around this time and gave him such feedback quite directly:
    </Typography>

    <Blockquote>
      <Typography>
        [S]o, I had put a call in to the President—I might have called on the 13th; we spoke, I believe, on the 14th—in which I conveyed to him that I thought that it was time for him to acknowledge that President Biden had prevailed in the election.... But I communicated to the President that when that legal process is exhausted and when the electors have voted, that that’s the point at which that outcome needs to be expected.... And I told him that I did believe, yes, that once those legal processes were run, if fraud had not been established that had affected the outcome of the election, that, unfortunately, I believed that what had to be done was concede the outcome.
        <Citation number={100} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Deputy White House Press Secretary Judd Deere also told President Trump that he should concede. He recalled other staffers advising President Trump at some point to concede and that he “encouraged him to do it at least once after the electoral college met in mid-December.”
      <Citation number={101} />
      White House Counsel Pat Cipollone also believed that President Trump should concede: “[I]f your question is did I believe he should concede the election at a point in time, yes, I did.”
      <Citation number={102} />
    </Typography>

    <Typography className="indent">
      Attorney General Barr told the Select Committee this: “And in my view, that [the December 14 electoral college vote] was the end of the matter. I didn’t see—you know, I thought that this would lead inexorably to a new administration. I was not aware at that time of any theory, you know, why this could be reversed. And so I felt that the die was cast....”
      <Citation number={103} />
    </Typography>

    <Typography className="indent">
      Barr also told the Committee that he suggested several weeks earlier that the President’s efforts in this regard needed to come to an end soon, in conversation with several White House officials after his meeting with Trump on November 23rd:
    </Typography>

    <Blockquote>
      <Typography>
        [A]s I walked out of the Oval Office, Jared was there with Dan Scavino, who ran the President’s social media and who I thought was a reasonable guy and believe is a reasonable guy. And I said, how long is he going to carry on with this ‘stolen election’ stuff? Where is this going to go?
      </Typography>
      <Typography>
        And by that time, Meadows had caught up with me and—leaving the office, and caught up to me and said that—he said, look, I think that he’s becoming more realistic and knows that there’s a limit to how far he can take this. And then Jared said, you know, yeah, we’re working on this, we’re working on it.
        <Citation number={104} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Despite all that Donald Trump was being told, he continued to purposely and maliciously make false claims. To understand the very stark differences between what he was being told and what he said publicly and in fundraising solicitations, the Committee has assembled the following examples.
    </Typography>

    <StyledTable>
      <div className="group">
        <div className="row who">
          <div>
            <Typography>
              Then-Deputy Attorney General Jeffrey Rosen (12/15/20):
            </Typography>
          </div>
          <div>
            <Typography>
              President Trump one week later (12/22/20):
            </Typography>
          </div>
        </div>
        <div className="row text">
          <div>
            <Typography>
              “And so he said, ‘Well, what about this? I saw it on the videotape, somebody delivering a suitcase of ballots.’ And we said, ‘It wasn’t a suitcase. It was a bin. That’s what they use when they’re counting ballots. It’s benign.’”
              <Citation number={105} />
            </Typography>
          </div>
          <div>
            <Typography>
              “There is even security camera footage from Georgia that shows officials telling poll watchers to leave the room before pulling suitcases of ballots out from under the tables and continuing to count for hours.”
              <Citation number={106} />
            </Typography>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row who">
          <div>
            <Typography>
              Acting Deputy Attorney General Richard Donoghue (12/27 & 12/31/20):
            </Typography>
          </div>
          <div>
            <Typography>
              President Trump later that week (1/2/21):
            </Typography>
          </div>
        </div>
        <div className="row text">
          <div>
            <Typography>
              “I told the President myself that several times, in several conversations, that these allegations about ballots being smuggled in in a suitcase and run through the machine several times, it was not true, that we looked at it, we looked at the video, we interviewed the witnesses, that it was not true.... I believe it was in the phone call on December 27th. It was also in a meeting in the Oval Office on December 31st.”
              <Citation number={107} />
            </Typography>
          </div>
          <div>
            <Typography>
              “[S]he stuffed the machine. She stuffed the ballot. Each ballot went three times, they were showing: Here’s ballot number one. Here it is a second time, third time, next ballot.”
              <Citation number={108} />
            </Typography>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row who">
          <div>
            <Typography>
              GA Sec. State Brad Raffensperger (1/2/21):
            </Typography>
          </div>
          <div>
            <Typography>
              President Trump one day later (1/3/21):
            </Typography>
          </div>
        </div>
        <div className="row text">
          <div>
            <Typography>
              “You’re talking about the State Farm video. And I think it’s extremely unfortunate that Rudy Giuliani or his people, they sliced and diced that video and took it out of context....” “[W]e did an audit of that and we proved conclusively that they were not scanned three times.... Yes, Mr. President, we’ll send you the link from WSB.”
            </Typography>
            <Typography>
              <strong>[Trump]</strong>: “I don’t care about a link. I don’t need it.”
              <Citation number={109} />
            </Typography>
          </div>
          <div>
            <Typography>
              “I spoke to Secretary of State Brad Raffensperger yesterday about Fulton County and voter fraud in Georgia. He was unwilling, or unable, to answer questions such as the ‘ballots under table’ scam, ballot destruction, out of state ‘voters’, dead voters, and more. He has no clue!”
              <Citation number={110} />
            </Typography>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row who">
          <div>
            <Typography>
              Attorney General Barr (12/1/20):
            </Typography>
          </div>
          <div>
            <Typography>
              President Trump one day later (12/2/20):
            </Typography>
          </div>
        </div>
        <div className="row text">
          <div>
            <Typography>
              “Then he raised the ‘big vote dump,’ as he called it, in Detroit. And, you know, he said, people saw boxes coming into the counting station at all hours of the morning and so forth.... I said, ‘Mr. President, there are 630 precincts in Detroit, and unlike elsewhere in the State, they centralize the counting process, so they’re not counted in each precinct, they’re moved to counting stations, and so the normal process would involve boxes coming in at all different hours.’ And I said, ‘Did anyone point out to you—did all the people complaining about it point out to you, you actually did better in Detroit than you did last time? I mean, there’s no indication of fraud in Detroit.’
              <Citation number={111} />
            </Typography>
          </div>
          <div>
            <Typography>
              “I’ll tell you what’s wrong, voter fraud. Here’s an example. This is Michigan. At 6:31 in the morning, a vote dump of 149,772 votes came in unexpectedly. We were winning by a lot. That batch was received in horror.... In Detroit everybody saw the tremendous conflict... there were more votes than there were voters.”
              <Citation number={112} />
            </Typography>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row who">
          <div>
            <Typography>
              Acting Deputy Attorney General Richard Donoghue (12/27/20):
            </Typography>
          </div>
          <div>
            <Typography>
              President Trump ten days later (1/6/21):
            </Typography>
          </div>
        </div>
        <div className="row text">
          <div>
            <Typography>
              “The President then continued, there are ‘more votes than voters...’. But I was aware of that allegation, and I said, you know, that was just a matter of them ‘comparing the 2020 votes cast to 2016 registration numbers.’ That is ‘not a valid complaint.’”
             <Citation number={113} />
            </Typography>
          </div>
          <div>
            <Typography>
              “More votes than they had voters. And many other States also.”
              <Citation number={114} />
            </Typography>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row who">
          <div>
            <Typography>
              Acting Deputy Attorney General Richard Donoghue (1/3/21):
            </Typography>
          </div>
          <div>
            <Typography>
              President Trump three days later (1/6/21):
            </Typography>
          </div>
        </div>
        <div className="row text">
          <div>
            <Typography>
              “[W]e would say to him, you know, ‘We checked that out, and there’s nothing to it.... And we would cite to certain allegations. And so—like such as Pennsylvania, right. ‘No, there were not 250,000 more votes reported than were actually cast. That’s not true.’ So we would say things like that.”
              <Citation number={115} />
            </Typography>
          </div>
          <div>
            <Typography>
              “In Pennsylvania, you had 205,000 more votes than you had voters. And the number is actually much greater than that now. That was as of a week ago. And this is a mathematical impossibility unless you want to say it’s a total fraud.”
              <Citation number={116} />
            </Typography>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row who">
          <div>
            <Typography>
              GA Sec. State Brad Raffensperger (1/2/21):
            </Typography>
          </div>
          <div>
            <Typography>
              President Trump two days later (1/4/21):
            </Typography>
          </div>
        </div>
        <div className="row text">
          <div>
            <Typography>
              <strong>[Trump]</strong>: “[I]t’s 4,502 who voted, but they weren’t on the voter registration roll, which they had to be. You had 18,325 vacant address voters. The address was vacant, and they’re not allowed to be counted. That’s 18,325....”
            </Typography>
            <Typography>  
              <strong>[Raffensperger]</strong>: “Well, Mr. President, the challenge that you have is the data you have is wrong.”
              <Citation number={117} />
            </Typography>
          </div>
          <div>
            <Typography>
              “4,502 illegal ballots were cast by individuals who do not appear on the state’s voter rolls. Well, that’s sort of strange. 18,325 illegal ballots were cast by individuals who registered to vote using an address listed as vacant according to the postal service.”
              <Citation number={118} />
            </Typography>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row who">
          <div>
            <Typography>
              GA Sec. of State Brad Raffensperger (1/2/21):
            </Typography>
          </div>
          <div>
            <Typography>
              President Trump four days later (1/6/21):
            </Typography>
          </div>
        </div>
        <div className="row text">
          <div>
            <Typography>
              <strong>[Trump]</strong>: “So dead people voted, and I think the number is close to 5,000 people. And they went to obituaries. They went to all sorts of methods to come up with an accurate number, and a minimum is close to about 5,000 voters....”
            </Typography>
            <Typography>  
              <strong>[Raffensperger]</strong>: “The actual number were two. Two. Two people that were dead that voted. So that’s wrong.”
              <Citation number={119} />
            </Typography>
          </div>
          <div>
            <Typography>
              “[T]he number of fraudulent ballots that we've identified across the state is staggering. Over 10,300 ballots in Georgia were cast by individuals whose names and dates of birth match Georgia residents who died in 2020 and prior to the election.”
              <Citation number={120} />
            </Typography>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row who">
          <div>
            <Typography>
              GA Sec. State General Counsel Ryan Germany (1/2/21):
            </Typography>
          </div>
          <div>
            <Typography>
              President Trump four days later (1/6/21):
            </Typography>
          </div>
        </div>
        <div className="row text">
          <div>
            <Typography>
              <strong>[Trump]</strong>: “You had out-of-state voters. They voted in Georgia, but they were from out of state, of 4,925....”
            </Typography>
            <Typography>  
              <strong>[Germany]</strong>: “Every one we’ve been through are people that lived in Georgia, moved to a different state, but then moved back to Georgia legitimately....” “They moved back in years ago. This was not like something just before the election. So there’s something about that data that, it’s just not accurate.”
              <Citation number={121} />
            </Typography>
          </div>
          <div>
            <Typography>
              “And at least 15,000 ballots were cast by individuals who moved out of the state prior to November 3rd election. They say they moved right back.”
              <Citation number={122} />
            </Typography>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row who">
          <div>
            <Typography>
              White House Press Secretary Kayleigh McEnany (n.d.):
            </Typography>
          </div>
          <div>
            <Typography>
              President Trump:
            </Typography>
          </div>
        </div>
        <div className="row text">
          <div>
            <Typography>
              “[T]he one specific I remember referencing was I don’t agree with the Dominion track....” “I specifically referenced waving him off of the Dominion theory earlier in my testimony....”
            </Typography>
            <Typography>  
              <strong>[Q]</strong>: “Are you saying you think he still continued to tweet that after you waved him off of it?”
            </Typography>
            <Typography>  
              <strong>[A]</strong>: “Yeah...”
              <Citation number={123} />
            </Typography>
          </div>
          <div>
            <Typography>
              Between mid-November and January 5, 2021, President Trump tweeted or retweeted conspiracy theories about Dominion nearly three dozen times.
              <Citation number={124} />
            </Typography>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row who">
          <div>
            <Typography>
              Trump Campaign Senior Advisor Jason Miller:
            </Typography>
          </div>
          <div>
            <Typography>
              President Trump:
            </Typography>
          </div>
        </div>
        <div className="row text">
          <div>
            <Typography>
              “...the international allegations for Dominion were not valid.”
            </Typography>
            <Typography>  
              <strong>[Q]</strong>: “Okay. Did anybody communicate that to the President?”
            </Typography>
            <Typography>  
              <strong>[A]</strong>: “I know that that was—I know that was communicated. I know I communicated it”
              <Citation number={125} />
            </Typography>
          </div>
          <div>
            <Typography>
              “You have Dominion, which is very, very suspect to start off with. Nobody knows the ownership. People say the votes are counted in foreign countries and much worse...”
              <Citation number={126} />
            </Typography>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row who">
          <div>
            <Typography>
              Attorney General Barr (11/23/20):
            </Typography>
          </div>
          <div>
            <Typography>
              President Trump three days later (11/26/20):
            </Typography>
          </div>
        </div>
        <div className="row text">
          <div>
            <Typography>
              “I specifically raised the Dominion voting machines, which I found to be one of the most disturbing allegations— ‘disturbing’ in the sense that I saw absolutely zero basis for the allegations... I told him that it was crazy stuff and they were wasting their time on that and it was doing great, great disservice to the country.”
              <Citation number={127} />
            </Typography>
          </div>
          <div>
            <Typography>
              “[T]hose machines are fixed, they’re rigged. You can press Trump and the vote goes to Biden.... All you have to do is play with a chip, and they played with a chip, especially in Wayne County and Detroit.”
              <Citation number={128} />
            </Typography>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row who">
          <div>
            <Typography>
              Attorney General Barr (12/1/20):
            </Typography>
          </div>
          <div>
            <Typography>
              President Trump one day later (12/2/20):
            </Typography>
          </div>
        </div>
        <div className="row text">
          <div>
            <Typography>
              “I explained, I said, look, if you have a machine and it counts 500 votes for Biden and 500 votes for Trump, and then you go back later and you have a—you will have the 1,000 pieces of paper put through that machine, and you can see if there’s any discrepancy... there has been no discrepancy.”
              <Citation number={129} />
            </Typography>
          </div>
          <div>
            <Typography>
              “In one Michigan County, as an example, that used Dominion systems, they found that nearly 6,000 votes had been wrongly switched from Trump to Biden, and this is just the tip of the iceberg. This is what we caught. How many didn’t we catch?”
              <Citation number={130} />
            </Typography>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row who">
          <div>
            <Typography>
              Attorney General Barr (12/14/20):
            </Typography>
          </div>
          <div>
            <Typography>
              President Trump one day later (12/15/20):
            </Typography>
          </div>
        </div>
        <div className="row text">
          <div>
            <Typography>
              “‘I will, Mr. President. But there are a couple of things,’ I responded. ‘My understanding is that our experts have looked at the Antrim situation and are sure it was a human error that did not occur anywhere else. And, in any event, Antrim is doing a hand recount of the paper ballots, so we should know in a couple of days whether there is any real problem with the machines.’”
              <Citation number={131} />
            </Typography>
          </div>
          <div>
            <Typography>
              “This is BIG NEWS. Dominion Voting Machines are a disaster all over the Country. Changed the results of a landslide election. Can’t let this happen....”
              <Citation number={132} />
            </Typography>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row who">
          <div>
            <Typography>
              Then-Deputy Attorney General Jeffrey Rosen (12/15/20):
            </Typography>
          </div>
          <div>
            <Typography>
              President Trump one day later (12/16/20):
            </Typography>
          </div>
        </div>
        <div className="row text">
          <div>
            <Typography>
              “[O]ther people were telling him there was fraud, you know, corruption in the election. The voting machines were no good. And we were telling him that is inconsistent, by ‘we,’ I mean Richard Donoghue and myself, that that was not what we were seeing.” ...“There was this open issue as to the Michigan report. And—I think it was Mr. Cuccinelli, not certain, but had indicated that there was a hand recount. And I think he said, ‘That's the gold standard.’”
              <Citation number={133} />
            </Typography>
          </div>
          <div>
            <Typography>
              “ ‘Study: Dominion Machines shifted 2-3% of Trump Votes to Biden. Far more votes than needed to sway election.’ Florida, Ohio, Texas and many other states were won by even greater margins than projected. Did just as well with Swing States, but bad things happened. @OANN”
              <Citation number={134} />
            </Typography>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row who">
          <div>
            <Typography>
              National Security Adviser Robert O’Brien (12/18/20):
            </Typography>
          </div>
          <div>
            <Typography>
              President Trump one day later (12/19/20):
            </Typography>
          </div>
        </div>
        <div className="row text">
          <div>
            <Typography>
              “I got a call from, I think, Molly Michael in outer oval, the President’s assistant, and she said, ‘I’m connecting you to the Oval’ ...somebody asked me, was there—did I have any evidence of election fraud in the voting machines or foreign interference in our voting machines. And I said, no, we’ve looked into that and there’s no evidence of it.”
              <Citation number={135} />
            </Typography>
          </div>
          <div>
            <Typography>
              “...There could also have been a hit on our ridiculous voting machines during the election, which is now obvious that I won big, making it an even more corrupted embarrassment for the USA. @DNI_Ratcliffe @SecPompeo”
              <Citation number={136} />
            </Typography>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row who">
          <div>
            <Typography>
              Acting Deputy AG Richard Donoghue (12/31/20):
            </Typography>
          </div>
          <div>
            <Typography>
              President Trump two days later (1/2/21):
            </Typography>
          </div>
        </div>
        <div className="row text">
          <div>
            <Typography>
              “We definitely talked about Antrim County again. That was sort of done at that point, because the hand recount had been done and all of that. But we cited back to that to say, you know, this is an example of what people are telling you and what’s being filed in some of these court filings that are just not supported by the evidence.”
              <Citation number={137} />
            </Typography>
          </div>
          <div>
            <Typography>
              “Well, Brad. Not that there’s not an issue, because we have a big issue with Dominion in other states and perhaps in yours.... in other states, we think we found tremendous corruption with Dominion machines, but we’ll have to see.” ...“I won’t give Dominion a pass because we found too many bad things.”
              <Citation number={138} />
            </Typography>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row who">
          <div>
            <Typography>
              GA Sec. State Brad Raffensperger (1/2/21):
            </Typography>
          </div>
          <div>
            <Typography>
              President Trump four days later (1/6/21):
            </Typography>
          </div>
        </div>
        <div className="row text">
          <div>
            <Typography>
              “I don’t believe that you’re really questioning the Dominion machines. Because we did a hand re-tally, a 100 percent re-tally of all the ballots, and compared them to what the machines said and came up with virtually the same result. Then we did the recount, and we got virtually the same result.”
              <Citation number={139} />
            </Typography>
          </div>
          <div>
            <Typography>
              “In addition, there is the highly troubling matter of Dominion Voting Systems. In one Michigan county alone, 6,000 votes were switched from Trump to Biden and the same systems are used in the majority of states in our country.” ...“There is clear evidence that tens of thousands of votes were switched from President Trump to former Vice President Biden in several counties in Georgia.”
              <Citation number={140} />
            </Typography>
          </div>
        </div>
      </div>
    </StyledTable>

    <Image
      src="/images/image-011.jpg"
      alt="Taped footage of William Barr speaking to the January 6th Select Committee is shown at one of its hearings."
      caption="Taped footage of William Barr speaking to the January 6th Select Committee is shown at one of its hearings."
      credit="(Photo by Mandel Ngan-Pool/Getty Images)"
    />

    <Typography className="indent">
      Evidence gathered by the Committee indicates that President Trump raised roughly one quarter of a billion dollars in fundraising efforts between the election and January 6th.
      <Citation number={141} />
      Those solicitations persistently claimed and referred to election fraud that did not exist. For example, the Trump Campaign, along with the Republican National Committee, sent millions of emails to their supporters, with messaging claiming that the election was “rigged,” that their donations could stop Democrats from “trying to steal the election,” and that Vice President Biden would be an “illegitimate president” if he took office.
    </Typography>

    <Typography className="indent">
      Ultimately, Attorney General Barr suggested that the Department of Justice’s investigations disproving President Trump’s fraud claims may have prevented an even more serious series of events:
    </Typography>

    <Blockquote>
      <Typography>
        [F]rankly, I think the fact that I put myself in the position that I could say that we had looked at this and didn’t think there was fraud was really important to moving things forward. And I sort of shudder to think what the situation would have been if the position of the Department was, “We’re not even looking at this until after Biden’s in office.” I’m not sure we would’ve had a transition at all.
        <Citation number={142} />
      </Typography>
    </Blockquote>
  </Page>
)

