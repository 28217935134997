import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

import { App } from './App';
import { isProd } from './utils';
import { report } from './reportWebVitalsToGoogle';

import './index.css';

const root = createRoot(document.getElementById('root')!);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

if (isProd()) {
  reportWebVitals(report);
}
