import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet8'));

export const Chapter610 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Within three minutes of President Trump’s tweet, a user on TheDonald.win message board posted: “Trump Tweet. Daddy Says Be In DC on Jan. 6th.”
      <Citation number={338} />
      Moderators pinned the post to the top of the board from December 19th until January 6th. It garnered nearly 6,000 comments and more than 24,000 upvotes during that time.
      <Citation number={339} />
      Many of the site’s users quickly interpreted President Trump’s tweet as a call for violence. For example, one user wrote, “[Trump] can’t exactly openly tell you to revolt. This is the closest he’ll ever get.”
      <Citation number={340} />
      Jody Williams, the site’s then-owner, testified that while users had been talking about traveling to Washington, DC since the election, after the tweet “anything else was kind of shut out, and it just was going to be the 6th.”
      <Citation number={341} />
    </Typography>

    <Typography className="indent">
      In the days that followed, users on TheDonald.win discussed: surrounding and occupying the U.S. Capitol; cutting off access tunnels used by Members of Congress; the types of weapons they should bring; and even how to build a hangman’s gallows.
      <Citation number={342} />
      The parallels to what transpired on January 6th are obvious.
    </Typography>

    <Typography className="indent">
      TheDonald.win and its predecessor site was a website for some of its namesake’s most ardent fans. Even before President Trump was elected, his social media team monitored and interacted with the site’s users. In the summer of 2016, then-candidate Trump himself engaged in a written question and answer session on TheDonald, which at the time was a forum on Reddit.
      <Citation number={343} />
      This online community, which had upwards of 790,000 users, was banned by Reddit in mid-2020.
      <Citation number={344} />
      However, the site’s users migrated to another online location, becoming TheDonald.win.
      <Citation number={345} />
    </Typography>

    <Typography className="indent">
      Dan Scavino, the President’s social media guru, amplified content from this website. During the 2016 presidential campaign, “a team in the war room at Trump Tower was monitoring social media trends, including TheDonald subreddit... and privately communicating with the most active users to seed new trends.”
      <Citation number={346} />
      “Campaign staffers monitored Twitter and TheDonald subreddit, and pushed any promising trends up to social media director Dan Scavino, who might give them a boost with a tweet.”
      <Citation number={347} />
      In 2017, President Trump tweeted a video of himself attacking CNN.
      <Citation number={348} />
      The video had appeared on The Donald four days earlier.
      <Citation number={349} />
      In 2019, Politico reported that Scavino “regularly monitors Reddit, with a particular focus on the pro-Trump /r/The_Donald channel.”
      <Citation number={350} />
    </Typography>

    <Image
      src="/images/image-084.jpg"
      alt='White House social media director Dan Scavino Jr.'
      caption='White House social media director Dan Scavino Jr.'
      credit="(Photo by Chip Somodevilla/Getty Images)"
    />

    <Typography className="indent">
      The Select Committee sought to question Scavino about how he and others on President Trump’s social media team interacted with The Donald subreddit and then TheDonald.win. But Scavino refused to cooperate with the committee’s subpoena.
      <Citation number={351} />
    </Typography>

    <Typography className="indent">
      After President Trump’s December 19th tweet, users on the site posted simple maps of the U.S. Capitol and telegraphed their intent to invade the building.
      <Citation number={352} />
      “If we occupy the capitol building, there will be no vote,” one user wrote.
      <Citation number={353} />
      “The media will call us evil if we have to occupy the Capitol Building on January 6th. Let them,” another post read.
      <Citation number={354} />
      One user argued the goal should be to “surround the enemy” and “create [a] perimeter” around the Capitol on January 6th, such that no one was allowed to leave until President Trump was “re-admitted for another 4 years.”
      <Citation number={355} />
      This same user posted a diagram of the U.S. Capitol’s perimeter with arrows indicating where the “Capitol Access Tunnels” were located.
    </Typography>

    <Typography className="indent">
      On January 5th, another user on TheDonald.win encouraged President Trump’s supporters to “be prepared to secure the capitol building,” claiming that “there will be plenty of ex military to guide you.”
      <Citation number={356} />
      Multiple other posts made it clear that the U.S. Capitol was the target on January 6th, with one poster writing that people should bring “handcuffs and zip ties to DC,” so they could enact “citizen’s arrests” of those officials who certified the election’s results.
      <Citation number={357} />
      Another post highlighted the “most important map for January 6th. Form a TRUE LINE around the Capitol and the tunnels.”
      <Citation number={358} />
      That “post included a detailed schematic of Capitol Hill with the tunnels surrounding the complex highlighted.”
      <Citation number={359} />
    </Typography>

    <Typography className="indent">
      Other posts on TheDonald.win included specific plans to build gallows outside the U.S. Capitol. “Gallows are simpler and more cost effective, plus they’re an American old west tradition too,” one user wrote on December 22, 2020.
      <Citation number={360} />
      A week later, another wrote: “Let’s construct a Gallows outside the Capitol building next Wednesday so the Congressmen watching from their office windows shit their pants.”
      <Citation number={361} />
      Another said that “building a hanging platform in front of Congress on the 6 should send a strong message.”
      <Citation number={362} />
      The site hosted a diagram showing how to tie a hangman’s knot,
      <Citation number={363} />
      with one site member writing that they should build gallows “so the traitors know the stakes.”
      <Citation number={364} />
      On January 5, 2021, hours before the attack began, a user posted an image of gallows and titled it, “Election Fraud Repair Kit.”
      <Citation number={365} />
    </Typography>

    <Typography className="indent">
      Text messages between Trump Campaign Senior Advisor Jason Miller and White House Chief of Staff Mark Meadows show that these kinds of posts reached deep into the President’s inner circle. Miller sent Meadows a text on December 30th, declaring, “I got the base FIRED UP.”
      <Citation number={366} />
      The thread contained a link to a TheDonald.win comment thread filled with reactions to a post by Miller promoting January 6th.
      <Citation number={367} />
      Users in the thread made comments such as “gallows don’t require electricity,” and that millions will “bust in through the doors if they try to stop Pence from declaring Trump the winner,” all in response to Miller.
      <Citation number={368} />
    </Typography>

    <Typography className="indent">
      On December 19, 2020, the same day President Trump posted his inflammatory “be wild” tweet, he also tweeted a noteworthy video. The short clip was titled, “FIGHT FOR TRUMP!—SAVE AMERICA—SAVE THE WORLD.”
      <Citation number={369} />
      The video reportedly appeared on TheDonald.win two days earlier.
      <Citation number={370} />
      As with so much else on TheDonald.win, this refrain featured prominently on the day of the attack on the Capitol. During his speech at the Ellipse south of the White House on January 6th, the crowd broke out into a chant of “Fight for Trump! Fight for Trump!” President Trump thanked those in attendance.
      <Citation number={371} />
    </Typography>

    <Typography className="indent">
      In the two and a half weeks since he first announced the January 6th “protest,” extremists and conspiracy theorists plotted to make the unprecedented, presidentially announced protest against the peaceful transfer of power “wild” indeed. Meanwhile, event organizers and White House staffers prepared for the final rally of President Trump’s term.
    </Typography>
  </Page>
)
