import { lazy } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet7'));

export const Chapter52 = ({ ...args }: any) => (
  <Page {...args}>
    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        JANUARY 2, 2021: THE VICE PRESIDENT MEETS WITH HIS ADVISORS, CEMENTING HIS INTENDED PATH FOR THE JOINT SESSION.
      </Typography>
    </Box>

    <Typography className="indent">
      On January 2, 2021, Vice President Pence met with his counsel Greg Jacob, Chief of Staff Marc Short, and Matt Morgan to discuss the joint session.
      <Citation number={96} />
      Morgan was the Trump Campaign’s General Counsel and had previously served as counsel to Vice President Pence. At this point, the Vice President already had a clear understanding of what his role would be in the electoral count.
      <Citation number={97} />
      Vice President Pence was concerned that most people did not understand how the certification of the electoral votes worked. So Jacob began drafting a statement for the Vice President to issue on January 6th. The statement was intended to provide a “civic education” on the joint session, explaining to the American people his actions, including why the Vice President “didn’t have the authorities that others had suggested that he might.”
      <Citation number={98} />
    </Typography>

    <Typography className="indent">
      The men discussed the various points of pressure being applied to the Vice President, including Eastman’s theories, the <i>Gohmert</i> suit, Ellis’s arguments, as well as how the electoral count process should work. They also discussed allegations of irregularities and maladministration of the election, concluding that none of the allegations raised was sufficient to reverse President Trump’s defeat.
      <Citation number={99} />
    </Typography>

    <Typography className="indent">
      While Vice President Pence recognized Congress’s authority under the Electoral Count Act to raise objections to the certification, neither he nor his staff were aware of any evidence of fraud that would have had a material effect on the outcome of the election in any State.
      <Citation number={100} />
      Because of President Trump’s repetition of election fraud allegations, Jacob and the Vice President’s staff conducted their own evaluation of these claims. Jacob asked Morgan to send the campaign’s best evidence of election “fraud, maladministration, irregularities, [and] abuses in the system.”
      <Citation number={101} />
      The Vice President’s legal staff memorialized the review they conducted of these materials in a memo to Vice President Pence, which concluded: “most allegations of substantive voter fraud—defined to mean the casting of illegal ballots in violation of prevailing election laws—are either relatively small in number, or cannot be verified.”
      <Citation number={102} />
    </Typography>

    <Typography className="indent">
      Vice President Pence also discussed the Trump Campaign’s fake electors with his advisors. Both Jacob and Morgan assured Vice President Pence that there were not dual slates of electors. The electors organized by the Trump Campaign were not valid.
      <Citation number={103} />
      Morgan had already written a memo on the topic in December, concluding that the “alternate” electors—that is, fake— lacked a certificate of ascertainment issued by the State.
      <Citation number={104} />
      Without such an ascertainment, the Trump Campaign’s fake electors had no standing during the joint session. Jacob had also prepared a “flow chart” memo outlining each of the legal provisions implicated in the joint session on January 6th.
      <Citation number={105} />
      Jacob advised Vice President Pence that “none of the slates that had been sent in would qualify as an alternate slate within the meaning of the Electoral Count Act.”
      <Citation number={106} />
      Vice President Pence was still worried that the fake elector issue was sowing confusion, so he wanted his statement on January 6th to be as transparent as possible.
      <Citation number={107} />
    </Typography>

    <Typography className="indent">
      That same day, January 2nd, Marc Short released a brief statement on behalf of the Vice President. “Vice President Pence shares the concerns of millions of Americans about voter fraud and irregularities in the last election,” the statement read. “The vice president welcomes the efforts of members of the House and Senate to use the authority they have under the law to raise objections and bring forward evidence before the Congress and the American people on January 6th.”
      <Citation number={108} />
      Short testified that the statement was consistent with the Vice President’s view that he did not have the power to reject electors.
      <Citation number={109} />
      Short issued this statement because of the “swirl” regarding the question of “where [Vice President Pence] stood.”
      <Citation number={110} />
    </Typography>

    <Typography className="indent">
      Steve Bannon’s podcast, <i>War Room: Pandemic</i>, was one of the primary sources of this swirl.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        JANUARY 2, 2021: EASTMAN AND BANNON QUESTION THE “COURAGE AND SPINE” OF VICE PRESIDENT PENCE.
      </Typography>
    </Box>

    <Typography className="indent">
      Steve Bannon’s podcast, <i>War Room: Pandemic</i>, was one of the primary sources of this swirl. Eastman was a guest on a January 2nd episode of Bannon’s show. Much of their conversation focused on Vice President Pence, and the belief that he had the power to overturn the election.
    </Typography>

    <Typography className="indent">
      “[W]e are entering into one of the most, um, important constitutional crisis [<i>sic</i>] the country’s ever had,” Bannon said.
      <Citation number={111} />
      Bannon complained that Vice President Pence had “spit the bit,” meaning he had given up on efforts to keep President Trump in power.
      <Citation number={112} />
      Eastman claimed that the election had been “illegally conducted,” and so the certified votes now “devolved back to the [S]tate legislature[s], and the only other place where it devolved back to is to Congress and particularly the Vice President, who will sit in presiding over a Joint Session of Congress beginning on January 6 to count the ballots.”
      <Citation number={113} />
      Eastman claimed that the Vice President (and Congress) had the power to reject the certified electors from several States out-of-hand. “[T]hey’ve got multiple slates of ballots from seven states,” Eastman said. “And they’ve gotta decide [ ] which is the valid slate to count... I think they have that authority to make that determination on their own.”
      <Citation number={114} />
    </Typography>

    <Typography className="indent">
      Bannon claimed the Vice President of the United States is “hardwired in,” and an “actual decisionmaker.”
      <Citation number={115} />
      The Vice President’s role is not “ministerial,” Bannon declared.
      <Citation number={116} />
      Eastman agreed.
      <Citation number={117} />
      “Are we to assume that this is going to be a climactic battle that’s going to take place this week about the very question of the constitutionality of the Electoral Count Act of 1887?” Bannon asked.
      <Citation number={118} />
    </Typography>

    <Typography className="indent">
      Eastman replied, “I think a lot of that depends on the courage and the spine of the individuals involved.” Bannon asked Eastman if he meant Vice President Mike Pence. “Yes,” Eastman answered.
      <Citation number={119} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        JANUARY 3, 2021: EASTMAN DRAFTS ANOTHER “JANUARY 6 SCENARIO” MEMO “WAR GAMING” THE WAYS THAT VICE PRESIDENT PENCE COULD CHANGE THE OUTCOME OF THE ELECTION.
      </Typography>
    </Box>

    <Typography className="indent">
      The next day, January 3, 2021, Eastman drafted a six-page memo that imagined several scenarios for January 6th, only some of which led to President Trump’s victory.
      <Citation number={120} />
      In a section titled, “War Gaming the Alternatives,” Eastman set forth the ways he thought President Trump could remain in power.
      <Citation number={121} />
      Importantly, Eastman concluded that President Trump could remain president if—and only if—Vice President Pence followed Eastman’s illegal advice and determined which electoral college ballots were “valid.”
      <Citation number={122} />
      In another scenario, Eastman imagined that President Trump may somehow win re-election in January 2021 if Vice President Pence remanded the electoral votes to State legislatures, such that they could have ten days to investigate President Trump’s baseless claims of fraud. In that case, Eastman allowed that former Vice President Biden may still win, should the State legislatures determine that the evidence was “insufficient to alter the results of the election.”
      <Citation number={123} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        Eastman Knew that there Were No Valid “Alternate” Slates, But He Nonetheless Predicated His Advice to the Vice President and President on this Claim.
      </Typography>
    </Box>

    <Typography className="indent">
      In his six-page memo, consistent with the earlier two-page memo, Eastman states that “the Trump electors” met and transmitted votes, finding that “[t]here are thus dual slates of electors from 7 [S]tates.”
      <Citation number={124} />
      Even since January 6th, Eastman has continued to affirm and defend his assertion that there were dual slates of electors, writing: “Trump electors from seven [S]tates in which election challenges were still pending met (albeit of their own accord) on the date designated by Congress, cast their votes, and transmitted those votes to Congress.”
      <Citation number={125} />
    </Typography>

    <Typography className="indent">
      Eastman used these slates as a premise for his argument that the result of the election was disputed. However, Eastman acknowledged on multiple occasions—both before and after January 6th—that these “dual slates” had no legal significance. In an email sent on December 19, 2020, Eastman wrote that the seven Trump/Pence slates of electors “will be dead on arrival in Congress” “unless those electors get a certification from their State Legislators.”
      <Citation number={126} />
      Of course, this certification never came and there was no basis for any action on the “dual slates.”
      <Citation number={127} />
    </Typography>

    <Typography className="indent">
      Nevertheless, on December 23, 2020, Eastman used the existence of these slates as a justification for the Vice President to act, in an email to Boris Epshteyn, a Trump Campaign lawyer. “The fact that we have multiple slates of electors demonstrates the uncertainty of either. That should be enough.”
      <Citation number={128} />
    </Typography>

    <Typography className="indent">
      Again after January 6th, Eastman acknowledged in an email that the fake electors’ documents were invalid and irrelevant.
      <Citation number={129} />
      “Alas,” he said, “[T]hey had no authority” because “[n]o legislature certified them.”
      <Citation number={130} />
    </Typography>

    <Typography className="indent">
      Eastman concluded his memo by asserting that his plan was “BOLD, Certainly,” but he attempted to justify it, arguing that “this Election was Stolen by a strategic Democrat plan to systematically flout existing election laws for partisan advantage; we’re no longer playing by Queensbury Rules, therefore.”
      <Citation number={131} />
    </Typography>

    <Typography className="indent">
      Eastman repeated what he wrote in his earlier, shorter memo, claiming that Vice President Pence should act “without asking for permission— either from a vote of the joint session or from the Court.”
      <Citation number={132} />
      Eastman claimed “that the Constitution assigns this power to the Vice President as the ultimate arbiter.”
      <Citation number={133} />
      Eastman ended on an especially hyperbolic note. If the election’s results were not upended, “then the sovereign people no longer control the direction of their government, and we will have ceased to be a self-governing people. The stakes could not be higher.”
      <Citation number={134} />
    </Typography>

    <Typography className="indent">
      <i>January 4, 2021: President Trump and Eastman Meet with Pence and His Staff in the Oval Office.</i>
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        Eastman Argues in an Oval Office Meeting that the Vice President can Reject Electoral Votes or that He Can Delay the Certification, Sending the Electoral Votes Back to the States.
      </Typography>
    </Box>

    <Typography className="indent">
      Late in the afternoon of January 4, 2021, President Trump summoned Vice President Pence to the Oval Office for a showdown.
      <Citation number={135} />
      President Trump and Eastman sought to convince the Vice President that he had the power to refuse to count the certified electors from several States won by former Vice President Biden.
    </Typography>

    <Typography className="indent">
      Short and Jacob attended with the Vice President.
      <Citation number={136} />
      Trump’s chief of staff, Mark Meadows, was only briefly present, leaving as the meeting started.
      <Citation number={137} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        Eastman Argues in an Oval Office Meeting that the Vice President can Reject Electoral Votes or that He Can Delay the Certification, Sending the Electoral Votes Back to the States.
      </Typography>
    </Box>

    <Typography className="indent">
      The White House Counsel is Excluded from the Meeting.
    </Typography>

    <Typography className="indent">
      One key lawyer was conspicuously absent: Pat Cipollone, the White House Counsel. Cipollone and his deputy, Pat Philbin, were shooting down a series of “terrible” proposals at the time.
      <Citation number={138} />
      Philbin told the Select Committee that he considered resigning every day from approximately November 15 until the end of the administration.
      <Citation number={139} />
      Philbin had researched the Vice President’s role in the January 6th joint session and concluded that Vice President Pence had no power to affect the outcome.
      <Citation number={140} />
      Cipollone agreed and informed Short and Jacob that this was the opinion of White House Counsel’s Office.
      <Citation number={141} />
    </Typography>

    <Typography className="indent">
      Mark Meadows invited Cipollone to speak with Eastman prior to the Oval Office meeting.
      <Citation number={142} />
      Cipollone told Eastman that his scheme was “not something that is consistent with the appropriate reading of the law.”
      <Citation number={143} />
      After delivering this assessment directly to Eastman in Meadows’ office, Cipollone walked to the Oval Office with the intent to attend the meeting. However, by the time the Vice President and his staff arrived, Cipollone was gone.
      <Citation number={144} />
    </Typography>

    <Typography className="indent">
      Cipollone declined to testify as to what he told President Trump or why he did not attend the Oval Office meeting, but he was clear that he didn’t end up attending the meeting because of something that happened after he walked into the Oval Office.
      <Citation number={145} />
      Whatever happened, Cipollone maintained, was protected by executive privilege, suggesting that he was asked to leave by the President.
      <Citation number={146} />
      What is clear, however, is that Cipollone had already shared his view directly with Meadows and Eastman, i.e., that the proposal President Trump and Eastman were about to advocate to the Vice President was illegal.
      <Citation number={147} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        During this Oval Office Meeting, Eastman Admits that Both Paths are Based on the Same Legal Theory and Concedes His Plan Violates the Electoral Count Act.
      </Typography>
    </Box>

    <Typography className="indent">
      During the Oval Office meeting, Eastman claimed that there were two legally viable options.
      <Citation number={148} />
      First, Vice President Pence could reject outright the certified electors submitted by several States, and second, he could suspend the joint session and send the “disputed” electoral votes back to the States.
      <Citation number={149} />
      Eastman advised that the Vice President had the “raw authority to determine objections himself,” according to Jacob.
      <Citation number={150} />
      However, by the end of the meeting Eastman was emphasizing the second option that he argued would be “more politically palatable” than the “more aggressive” option of the Vice President rejecting electoral votes outright.
      <Citation number={151} />
      If Vice President Pence did not want to reject the electors, Eastman claimed, then the Vice President could send the certified electoral votes back to the States for further deliberation.
    </Typography>

    <Image
      src="/images/image-069.jpg"
      alt="Pat Cipollone is seen on a screen during a Select Committee hearing on July 12, 2022."
      caption="Pat Cipollone is seen on a screen during a Select Committee hearing on July 12, 2022."
      credit="(Photo by Sarah Silbiger-Pool/Getty Images)"
    />

    <Typography className="indent">
      Eastman later conceded that both actions were based on the same underlying legal theory of the Vice President’s power.
      <Citation number={152} />
      Eastman also admitted—during this meeting with the President and Vice President—that his proposal violated the Electoral Count Act.
      <Citation number={153} />
      Moreover, Eastman eventually acknowledged that the concept of the Vice President unilaterally rejecting electors was not supported by precedent and that the Supreme Court would never endorse it.
      <Citation number={154} />
    </Typography>

    <Typography className="indent">
      Jacob recorded his reflections on the January 4th meeting in a contemporaneous memo to the Vice President.
      <Citation number={155} />
      Jacob’s memo confirms that Eastman admitted that his proposal violated the law in the presence of President Trump.
    </Typography>

    <Typography className="indent">
      First, Jacob wrote, Eastman acknowledged that “his proposal violates several provisions of statutory law”—namely, the Electoral Count Act of 1887.
      <Citation number={156} />
      Jacob’s memo explains that the Electoral Count Act calls for all vote certificates to be “acted upon,” and any objections to a State’s certificates be “finally disposed of.” However, as Jacob wrote, Eastman was proposing instead that “no action be taken” on the certificates from the States Eastman asserted were “contested.” And, according to the Electoral Count Act, the Vice President (as President of the Senate) is to “call for objections.” But Eastman did not want the Vice President to “call for objections” for these States. As Jacob noted, this would have deprived Congress of the ability under the Act to make, debate, and vote on objections.
      <Citation number={157} />
    </Typography>

    <Typography className="indent">
      Additionally, the Electoral Count Act contains a provision that requires any “competing slates of electors” to be “submitted to the Senate and House for debate and disposition.” As Jacob noted, Eastman conceded that the “alternate” (fake) electors’ votes were not proper. But Eastman’s proposal still would have refused to count the real electors’ votes from those States and instead referred both the real and fake electors’ votes to State legislatures “for disposition.” Finally, in order for State legislatures to take action to determine which of the slates should be counted, Eastman’s proposal called for “an extended recess of the joint session.” But this too would have violated the Electoral Count Act, which provides only for very short delays.
      <Citation number={158} />
    </Typography>

    <Typography className="indent">
      There was another foundational problem with Eastman’s plan. There were no legitimate “competing” or “alternate” slates of electors. President Trump, Eastman and others had manufactured the conditions they needed in order to claim that the election result was “disputed” by convening fake electors who sent fake documents to Washington before January 6th. And their efforts to convince State legislatures to certify Trump electors had already failed.
    </Typography>

    <Typography className="indent">
      Jacob noted in his memo that in the Oval Office meeting, Eastman conceded “no legislature has appointed or certified any alternate slate of electors” and that the purported “alternate slates” (fake electors) were illegitimate without what Jacob described as “the imprimatur of approval by a State legislature.”
      <Citation number={159} />
      Moreover, Eastman acknowledged that “no Republican-controlled legislative majority in any disputed States has expressed an intention to designate an alternate slate of electors.”
      <Citation number={160} />
      In other words, Eastman acknowledged that the fake votes were invalid, that no State legislature had approved them, and no State legislature <i>would</i> approve them.
      <Citation number={161} />
      But President Trump and Eastman still pressed this unlawful scheme on the Vice President. Although Eastman started the January 4th Oval Office meeting maintaining that Vice President Pence had unilateral authority to reject electors, by the end of the meeting he conceded that he would “not recommend that the Vice President assert that he has the authority unilaterally to decide which of the competing slates of electors should be counted.”
      <Citation number={162} />
    </Typography>

    <Typography className="indent">
      Jacob ended his memo with a scathing summary. “If the Vice President implemented Professor Eastman’s proposal, he would likely lose in court,” Jacob wrote. “In a best-case scenario in which the courts refused to get involved, the Vice President would likely find himself in an isolated standoff against both houses of Congress, as well as most or all of the applicable State legislatures, with no neutral arbiter to break the impasse.”
      <Citation number={163} />
    </Typography>

    <Typography className="indent">
      Following the Oval Office meeting, during the evening of January 4, 2021, Jacob invited Eastman to send along “any written materials on electoral vote counting issues,” including a law review article by Laurence Tribe that Eastman had cited in the Oval Office meeting that day, for Jacob to review on the Vice President’s behalf.
      <Citation number={164} />
      Jacob reviewed everything that Eastman submitted; nothing changed the analysis he had already done for the Vice President, indeed much of it did not even support Eastman’s own arguments.
      <Citation number={165} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        The Vice President was Not Persuaded by Eastman’s Theory and Remained Convinced That His Role at the Joint Session would be Merely Ceremonial.
      </Typography>
    </Box>

    <Typography className="indent">
      Pence did not relent on January 4th, or at any point during the harrowing two days that followed. “[F]rom my very first conversation with the Vice President on the subject, his immediate instinct was that there is no way that one person could be entrusted by the Framers to exercise that authority,” Jacob testified. “And never once did I see him budge from that view, and the legal advice that I provided him merely reinforced it. So, everything that he said or did during [the January 4th meeting in the Oval Office] was consistent with his first instincts on this question.”
      <Citation number={166} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        JANUARY 4, 2021: PRESIDENT TRUMP PUBLICLY PRESSURES THE VICE PRESIDENT DURING A RALLY SPEECH IN GEORGIA.
      </Typography>
    </Box>

    <Typography className="indent">
      President Trump did not relent either. His instinct was to increase public pressure on Vice President Pence, despite the Vice President’s consistent message to President Trump about the limits of his authority. That evening, during a Senate campaign rally in Dalton, Georgia, President Trump made it seem as if the Presidential election hadn’t already been decided and projected his unhinged ambitions onto his opponents.
      <Citation number={167} />
      President Trump claimed that “there’s nothing the radical Democrats will not do to get power that they so desperately crave,” including “the outright stealing of elections, like they’re trying to do with us.”
      <Citation number={168} />
    </Typography>

    <Typography className="indent">
      “We’re not going to let it happen,” President Trump said, adding, “I hope Mike Pence comes through for us, I have to tell you.” President Trump called Vice President Pence a “great vice president,” a “great guy,” as well as a “wonderful” and “smart man.” But he alluded to the Vice President’s role, “he’s going to have a lot to say about it,” and added an ominous note. “Of course, if he doesn’t come through, I won’t like him quite as much,” President Trump said.
      <Citation number={169} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        JANUARY 5, 2021: EASTMAN PRESSURES PENCE’S STAFF IN A PRIVATE MEETING WHILE PRESIDENT TRUMP TWEETS.
      </Typography>
    </Box>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        In a Reversal of Where the Oval Office Meeting Ended the Day Before, Eastman Argues that Pence Should Reject Electors Outright.
      </Typography>
    </Box>

    <Typography className="indent">
      Eastman met with Jacob and Short again the following day.
      <Citation number={170} />
      During the Oval Office meeting the Vice President had made clear that he would not unilaterally reject electors, and, by pivoting to recommend the Vice President send the electors back to the States, Eastman seemed to recognize this. But the following morning, Eastman returned to pressing for the more “aggressive” path.
      <Citation number={171} />
    </Typography>

    <Typography className="indent">
      Jacob recorded Eastman’s request on January 5, 2021, in a handwritten note: “Requesting VP reject.”
      <Citation number={172} />
      Jacob later summarized Eastman’s remarks as follows: “I’m here asking you to reject the electors.”
      <Citation number={173} />
      This overnight reversal surprised Jacob because Eastman was returning to the more aggressive position he had seemed to abandon in the Oval Office meeting the day before.
      <Citation number={174} />
      President Trump’s tweets that morning may explain Eastman’s reversal. While Eastman was meeting with the Vice President’s staff, his client, President Trump, was pressing the argument publicly.
    </Typography>

    <Typography className="indent">
      At 11:06 a.m. on the morning of January 5th, President Trump tweeted: “The Vice President has the power to reject fraudulently chosen electors.” As his tweet made clear, President Trump would not be persuaded by reason—or the law. The President made this public statement despite the Vice President’s clear and consistent rejection of this theory including during an in-person meeting the day before. During that same meeting, Eastman conceded that this “aggressive” path of rejecting electors was not advisable.
    </Typography>

    <Typography className="indent">
      Herschmann briefly participated in the January 5th meeting, seeing it as “an opportunity... to just chew [Eastman] out.”
      <Citation number={175} />
      Herschmannhad already pushed back “brutal[ly]” on Eastman’s theory regarding the Vice President. In this conversation, he emphasized the need to fact check dubious claims of election fraud.
      <Citation number={176} />
      Herschmann told Eastman that “someone better make sure” that the allegations Eastman provided to members of Congress were accurate before they objected to the certification of the vote the next day.
      <Citation number={177} />
      “[N]othing should come out of someone’s mouth that [isn’t] independently verified and [] reliable.”
      <Citation number={178} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        At the End of the Morning Meeting, Eastman Concedes to Pence’s Counsel That His Theory Has No Historical Support.
      </Typography>
    </Box>

    <Typography className="indent">
      Jacob then had his own “Socratic” debate with Eastman over the legal merits of his position. According to Jacob, Eastman conceded much ground by the end of the session. Eastman “all but admitted that it [his plan] didn’t work.”
      <Citation number={179} />
    </Typography>

    <Typography className="indent">
      For example, Eastman had previously claimed to have found historical support in the actions of John Adams and Thomas Jefferson, who both presided over the counting of electoral votes when they were Vice President. Not so. Jacob told the Select Committee that Eastman conceded in private that the cases of Jefferson and Adams did not serve “as examples for the proposition that he was trying to support of a Vice Presidential assertion of authority to decide disputes[,] because no dispute was raised in either case during the joint session.” Jacob added: “And, moreover, there was no [question] as to the outcomes in those States.”
      <Citation number={180} />
    </Typography>

    <Typography className="indent">
      Eastman conceded that there was no historical support for the role that he and President Trump were pushing Vice President Pence to play. No Vice President—before or after the adoption of the Electoral Count Act—had ever exercised such authority. This included then-Vice President Richard Nixon’s handling of the electoral votes of Hawaii following the 1960 election. Though Eastman and other Trump lawyers used this Hawaii example to justify the theory that the Vice President could unilaterally choose which electors to count, Eastman admitted to Jacob that Vice President Nixon had not in fact done what Eastman was recommending Vice President Pence do.
      <Citation number={181} />
    </Typography>

    <Typography className="indent">
      Eastman also admitted that he would not grant the expansive powers he advocated for Vice President Pence to any other Vice President. Eastman did not think that Vice President Kamala Harris should have such power in 2025, nor did he think that Vice President Al Gore should have had such authority in 2001.
      <Citation number={182} />
      He also acknowledged that his theory would lose 9-0 at the Supreme Court.
      <Citation number={183} />
    </Typography>

    <Image
      src="/images/image-070.jpg"
      alt="Judge J. Michael Luttig testifies before the Select Committee on June 16, 2022."
      caption="Judge J. Michael Luttig testifies before the Select Committee on June 16, 2022."
      credit="(Photo by House Creative Services)"
    />

    <Typography className="indent">
      According to Jacob, Eastman “acknowledged by the end that, first of all, no reasonable person would actually want that clause [of the 12th Amendment] read that way because if indeed it did mean that the Vice President had such authority, you could never have a party switch thereafter.” If politicians followed Eastman’s advice, “[y]ou would just have the same party win continuously if indeed a Vice President had the authority to just declare the winner of every State.”
      <Citation number={184} />
    </Typography>

    <Typography className="indent">
      The Vice President’s office was unmoved by Eastman’s specious reasoning. As he left Marc Short’s office, Eastman was thinking of his client’s reaction. “They’re going to be really disappointed that I wasn’t able to persuade you,” Eastman said.
      <Citation number={185} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        Former Republican Officials with Executive, Legislative, and Judicial Experience All Agreed with Vice President Pence’s Conclusion about His Limited Role at the Joint Session.
      </Typography>
    </Box>

    <Typography className="indent">
      As President Trump’s pressure campaign intensified, the Vice President’s outside counsel, Richard Cullen, turned for support to John Michael Luttig, a conservative former judge of the U.S. Court of Appeals for the Fourth Circuit.
      <Citation number={186} />
      Eastman had clerked for Luttig—a man with impeccable legal and conservative credentials—more than two decades prior. Luttig rejected Eastman’s so-called legal analysis of the Vice President’s role in no uncertain terms. In a series of tweets, posted at 9:53 a.m. on January 5th, Luttig set forth his legal conclusions.
    </Typography>

    <Typography className="indent">
      “The only responsibility and power of the Vice President under the Constitution is to faithfully count the electoral college votes as they have been cast,” Judge Luttig wrote. “The Constitution does not empower the Vice President to alter in any way the votes that have been cast, either by rejecting certain of them or otherwise.”
      <Citation number={187} />
    </Typography>

    <Typography className="indent">
      Confusion in the media about where the Vice President stood on this issue prompted former Speaker of the House Paul Ryan to reach out to the Vice President to share his belief that the Vice President had no unilateral authority.
      <Citation number={188} /> Short also spoke with former Speaker Ryan and as he testified to the Select Committee, “I said to him, Mr. Speaker, you know Mike... you know he recognizes that. And we sort of laughed about it, and he said, I get it.”
      <Citation number={189} />
    </Typography>

    <Typography className="indent">
      The Vice President also consulted with former Vice President Dan Quayle, who reinforced and affirmed Vice President Pence’s consistent understanding of his role.
      <Citation number={190} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        JANUARY 5, 2021: PRESIDENT TRUMP AGAIN PRESSURES VICE PRESIDENT PENCE IN A ONE-ON-ONE MEETING AT THE WHITE HOUSE AND ANOTHER PHONE CALL WITH EASTMAN.
      </Typography>
    </Box>

    <Typography className="indent">
      President Trump demanded to see Vice President Pence again that same day. Vice President Pence had canceled a planned lunch with President Trump, intending to work on the statement he planned to issue on January 6th to explain publicly why he wouldn’t bow to the President’s pressure.
      <Citation number={191} />
      But Pence couldn’t avoid Trump. Vice President Pence had to delay a Coronavirus Task Force meeting later that same day when he was called to the Oval Office to meet with the President.
      <Citation number={192} />
    </Typography>

    <Typography className="indent">
      The two men met alone, without staff present. While we have not developed direct evidence of what was discussed during this one-on-one meeting between the President and Vice President, it did not change the fundamental disagreement between them about the limits of the Vice President’s authority during the joint session. Jacob said the Vice President left the meeting “determined.”
      <Citation number={193} />
      Vice President Pence did tell Marc Short what transpired during the meeting, but Short refused to tell the Select Committee what was said.
      <Citation number={194} />
      Short described Vice President Pence’s demeanor as “steady.”
      <Citation number={195} />
      Short testified that the below excerpt from the book <i>Peril</i> may have been sensationalized but was generally consistent with Short’s understanding of the discussion:
    </Typography>

    <Blockquote>
      <Typography>
        “If these people say you have the power, wouldn’t you want to?” Trump asked.
      </Typography>
      <Typography>
        “I wouldn’t want any one person to have that authority,” Pence said.
      </Typography>
      <Typography>
        “But wouldn’t it almost be cool to have that power?” Trump asked.
      </Typography>
      <Typography>
        “No,” Pence said. “Look, I’ve read this, and I don’t see a way to do it. We’ve exhausted every option. I’ve done everything I could and then some to find a way around this. It’s simply not possible. My interpretation is: No....
      </Typography>
      <Typography>
        “No, no, no!” Trump shouted. “You don’t understand, Mike. You can do this. I don’t want to be your friend anymore if you don’t do this.”
        <Citation number={196} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Later that day, Jacob and Short were both present for a call between President Trump and Vice President Pence.
      <Citation number={197} />
      Eastman and at least one other lawyer were with President Trump on the call as well.
      <Citation number={198} />
    </Typography>

    <Typography className="indent">
      Eastman recognized that Vice President Pence was not going to change his mind on rejecting electors outright, but he still asked if the Vice President would consider sending the electors back to the States.
      <Citation number={199} />
      “I don’t see it,” Vice President Pence responded, “but my counsel will hear out whatever Mr. Eastman has to say.”
      <Citation number={200} />
    </Typography>

    <Typography className="indent">
      Jacob received other calls from Eastman on January 5th.
      <Citation number={201} />
      Jacob told the Select Committee that he had a detailed discussion with Eastman concerning the ways his proposal would violate the Electoral Count Act.
      <Citation number={202} />
      Eastman resorted to a ridiculous argument—comparing their current situation to the crisis that faced President Abraham Lincoln during the Civil War. Eastman invoked President Lincoln’s suspension of the writ of habeas corpus.
      <Citation number={203} />
      He also told Jacob to “stay tuned” because “we” were trying to get some letters from State legislators indicating that they were interested in the Vice President sending the electors back to the States.
      <Citation number={204} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        JANUARY 5, 2021: AN ACCURATE NEW YORK TIMES ARTICLE ABOUT THE VICE PRESIDENT PROMPTS A FALSE STATEMENT IN RESPONSE BY PRESIDENT TRUMP.
      </Typography>
    </Box>

    <Typography className="indent">
      On the evening of January 5th, <i>The New York Times</i> published an article titled, “Pence Said to Have Told Trump He Lacks Power to Change Election Result.”
      <Citation number={205} />
      The <i>Times</i> reported on the tension brewing within the White House, citing “people briefed on the conversation” between President Trump and Vice President Pence that had taken place in the Oval Office the previous day. “Vice President Mike Pence told President Trump on Tuesday [January 4th] that he did not believe he had the power to block congressional certification of Joseph R. Biden, Jr.’s victory in the presidential election despite Mr. Trump’s baseless insistence that he did,” the <i>Times</i> reported.
      <Citation number={206} />
    </Typography>

    <Typography className="indent">
      The <i>Times’</i> report was published at approximately 7:36 that evening.
      <Citation number={207} />
      Jason Miller called President Trump to make sure he had seen it.
      <Citation number={208} />
      President Trump spoke to Miller at least twice, once at 8:18 p.m. and a second time at 9:22 p.m.
      <Citation number={209} />
      Immediately after concluding his second call with Jason Miller, President Trump asked to speak to the Vice President; President Trump and Vice President Pence spoke from 9:33 to 9:41 p.m.
      <Citation number={210} />
      President Trump also spoke with Steve Bannon and Eastman, among others.
      <Citation number={211} />
    </Typography>

    <Typography className="indent">
      At 9:58 p.m. on January 5th, President Trump issued a statement that he had dictated to Jason Miller disputing the <i>Times’</i> account.
      <Citation number={212} />
      President Trump lied—repeatedly—in his short statement.
      <Citation number={213} />
      The President claimed the article was “fake news.” It wasn’t. President Trump claimed he and Vice President Pence were “in total agreement that the Vice President has the power to act.” They weren’t. President Trump claimed the election “was illegal.” It wasn’t. President Trump then laid out Vice President Pence’s options for the next day, summarizing Eastman’s theory:
    </Typography>

    <Blockquote>
      <Typography>
        Our Vice President has several options under the U.S. Constitution. He can decertify the results or send them back to the [S]tates for change and certification. He can also decertify the illegal and corrupt results and send them to the House of Representatives for the one vote for one [S]tate tabulation.
        <Citation number={214} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      This was also a blatant attempt to mischaracterize the Vice President’s position in the hope that public opinion would somehow sway the resolute Vice President. President Trump knew full well at the time that he and Vice President Pence were <i>not</i> “in total agreement.” The Vice President’s counsel, Greg Jacob, was shocked by the statement.
      <Citation number={215} />
      “[T]he Vice President was not in agreement that the Vice President had the power to take the actions that were being asked of him that day,” Jacob later told the Select Committee.
      <Citation number={216} />
      Marc Short was furious as well and called Jason Miller to forcefully “express [his] displeasure that a statement could have gone out that misrepresented the Vice President’s viewpoint without consultation.”
      <Citation number={217} />
    </Typography>

    <Typography className="indent">
      The Vice President was “obviously irritated that a statement putting words in his mouth” was issued by the President and considered issuing his own statement contradicting President Trump’s.
      <Citation number={218} />
      Ultimately, Pence and Short concluded that it was not worthwhile since it was already late in the evening and they expected the question to be resolved by Vice President Pence’s “Dear Colleague” letter the next day.
      <Citation number={219} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        JANUARY 5, 2021: BANNON PUBLICLY AMPLIFIES THE PRESSURE ON VICE PRESIDENT PENCE.
      </Typography>
    </Box>

    <Typography className="indent">
      While President Trump misrepresented the Vice President’s agreement with Eastman’s theory, his on-again, off-again political advisor, Steve Bannon, pressed President Trump’s campaign against Vice President Pence in public. Bannon echoed the public pressure on Pence that the President continued to propagate by talking about his purported authority. The Select Committee learned from phone records that Bannon spoke to President Trump at least twice on January 5th.
      <Citation number={220} />
    </Typography>

    <Typography className="indent">
      During a January 5, 2021, episode of <i>War Room: Pandemic</i>, Bannon and his guests openly berated Vice President Pence. Bannon cited an erroneous news report claiming that Senator Grassley would preside over the certification of the electoral college vote—instead of Vice President Pence.
      <Citation number={221} />
      Bannon’s cohost, Raheem Kassam, took credit for the public pressure placed on Vice President Pence. “I want to remind people who has been sitting here, saying ‘Light Pence Up’ for the last couple of weeks. Right? That would be Raheem Kassam.” They then discussed President Trump’s speech in Georgia the previous evening. “I think the President of the United States took your advice last night, wrote a line in there,” Bannon said. To which Kasseem responded: “...and yours ...hold the line.”
    </Typography>

    <Typography className="indent">
      Jack Posobiec, an alt-right personality with a large Twitter following, chimed in quoting a member of their audience as saying that “Pence will betray Donald Trump.”
      <Citation number={222} />
      In response, Bannon stated: “Call the play. Run the play.”
      <Citation number={223} />
    </Typography>

    <Typography className="indent">
      The “play” was Bannon’s version of the “Green Bay Sweep”—a plan to subvert the transfer of power on January 6th named for a brutally effective power running play developed in the National Football League (NFL) in the 1960’s. Steve Bannon’s political version of the sweep was intended to undermine the legitimate results of the 2020 presidential election.
    </Typography>

    <Typography className="indent">
      One account of Bannon’s “Green Bay Sweep” comes from Peter Navarro, Director of the White House Office of Trade and Manufacturing Policy. Navarro refused to cooperate with the Select Committee and was subsequently indicted for contempt of Congress. Although he doesn’t fully explain in his book, <i>In Trump Time: A Journal of America’s Plague Year</i>, how Bannon’s sweep was intended to work, Navarro writes that Vice President Pence was envisioned as the “quarterback” who would “assert his constitutional power” to delay certification.
      <Citation number={224} />
      Navarro writes that his own role was to “carefully document the fraud and myriad election irregularities,” while Bannon’s “role was to figure out how to use this information—what he called the ‘receipts.’”
      <Citation number={225} />
    </Typography>

    <Typography className="indent">
      Navarro’s account helps explain why Trump and his loyalists became so fixated on Vice President Pence. They saw Vice President Pence as their last hope for keeping President Trump in office. Navarro writes of Pence’s supposed “betrayal.”
      <Citation number={226} />
      In a telling sentence, Navarro likens Vice President Pence to Brutus, a Roman politician and the most famous assassin of Julius Caesar. Navarro writes:
    </Typography>

    <Blockquote>
      <Typography>
        On this cold, momentous day, I shiver as I think to myself, “January 6 will be either Mike Pence’s finest hour or the traitorous ‘Et tu, Brute?’ end of both his and Donald Trump’s political careers.”
        <Citation number={227} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      The goal of these Trump allies was clear: to overturn the election result.
      <Citation number={228} />
      Statements by participants in this effort indicate there were several different endgame strategies in mind. One was to get the Vice President to unilaterally reject the Electoral College votes of Arizona, Georgia, Pennsylvania, and other States, then simply declare that Trump had won a majority of the electors actually submitted. The other major possibility was to reject or “return” the Electoral College votes of these States and then declare there was no majority in the Electoral College process, thereby triggering a so-called contingent election under the 12th Amendment.
      <Citation number={229} />
      This would have meant that the House of Representatives had chosen the president not on the basis of one-member-one-vote, but on the basis of one-State-one-vote, pursuant to the 12th Amendment. Donald Trump’s strategists emphasized repeatedly that the GOP had a 27-to-22 margin in control of the States’ Congressional delegations, with Pennsylvania being tied at 9-to-9, therefore presumably a non-factor.
    </Typography>
  </Page>
)
