import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet6'));

export const Chapter413 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      On Saturday, January 2nd, Rosen and Donoghue attempted, once again, to persuade Clark to stand down. The two reiterated that Clark should stop meeting with the President.
      <Citation number={265} />
      Donoghue reprimanded Clark, emphasizing that he was the boss and that Clark’s ongoing contacts with the President were a violation of DOJ’s White House contact policy.
      <Citation number={266} />
      Clark acknowledged that he had been briefed by the ODNI, as he had requested, and “that there was nothing in that briefing that would have supported his earlier suspicion about foreign involvement.”
      <Citation number={267} />
      Nevertheless, Clark still wanted to send his letter to Georgia and other contested States alleging voter fraud.
      <Citation number={268} />
    </Typography>

    <Typography className="indent">
      During the conversation, Clark confirmed President Trump had offered him the position of Acting Attorney General.
      <Citation number={269} />
      Clark told Rosen that he would decline the offer—if Rosen and Donoghue signed his dishonest letter to officials in Georgia.
      <Citation number={270} />
      The two refused once again, making it clear “that there was no way we were going to sign that letter.”
      <Citation number={271} />
      Rosen reiterated his decision in an email on the night of January 2nd, writing: “I confirmed again today that I am not prepared to sign such a letter.”
      <Citation number={272} />
    </Typography>

    <Typography className="indent">
      That same day, President Trump attempted to coerce Georgia Secretary of State Brad Raffensperger into manufacturing enough votes to steal the election in that State. That call is discussed in Chapter 2 of this report. But one part of it deserves mention here. During that same call, President Trump brought up BJay Pak, whom President Trump had appointed as the U.S. Attorney for the Northern District of Georgia. President Trump referred to Pak as “your never-Trumper U.S. attorney there.”
      <Citation number={273} />
      The implication was that Pak was not doing enough to validate President Trump’s fictitious claims of voter fraud. President Trump’s mention of Pak proved to be ominous.
    </Typography>
  </Page>
)
