import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet9'));

export const Chapter78 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      No one was getting through to the President.
    </Typography>

    <Typography className="indent">
      So Herschmann went to Ivanka Trump’s office, hoping she would come to the dining room and be “a calming influence” on her father.
      <Citation number={199} />
      Herschmann “just sort of barged in” and told her to turn on the television.
      <Citation number={200} />
      After taking in a few of the violent scenes together, Herschmann and Ivanka Trump left the room and walked to the dining room, where her father was holed up.
      <Citation number={201} />
    </Typography>

    <Typography className="indent">
      At 2:38 p.m., the President issued a tweet:
      <Citation number={202} />
    </Typography>

    <Blockquote>
      <Typography>
        Please support our Capitol Police and Law Enforcement. They are truly on the side of our Country. Stay peaceful!
        <Citation number={203} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Ivanka Trump told the Select Committee that the President “did not push back on [her] suggestion” to issue the tweet, and that it was either she or President Trump himself who suggested the last line, “Stay peaceful!”
      <Citation number={204} />
      She confirmed there may have been some tweaking of the wording.
      <Citation number={205} />
      McEnany, who was in the room at the time, wrote in her notes that “I say add ‘we support PEACEFUL protest.’ Ivanka add stay peaceful! Instead.”
      <Citation number={206} />
      To the Select Committee, McEnany echoed Ivanka Trump that the President wasn’t resistant in any way to putting out the message.
      <Citation number={207} />
    </Typography>

    <Typography className="indent">
      But in private, McEnany told a different story to her deputy Sarah Matthews.
    </Typography>

    <Typography className="indent">
      Back in the White House press office, Matthews told McEnany that the tweet did not go far enough in condemning the violence.
      <Citation number={208} />
      McEnany— noting that other staffers in the room were distracted—said “in a hushed tone... that the President did not want to include any sort of mention of peace in that tweet.”
      <Citation number={209} />
    </Typography>

    <Typography className="indent">
      That took “some convincing on their part,” McEnany said, and “it wasn’t until Ivanka Trump suggested the phrase ‘Stay peaceful!’ that he finally agreed to include it.”
      <Citation number={210} />
    </Typography>

    <Typography className="indent">
      Ivanka Trump repeatedly returned to the dining room to counsel her father throughout the day. It has been reported that each time Ivanka Trump “thought she had made headway” with her father, Meadows would call her “to say the [P]resident still needed more persuading”—a cycle that repeated itself over “several hours” that afternoon.
      <Citation number={211} />
      After one such trip, Ivanka Trump told the Select Committee she went to her husband’s office next door because she needed to “regroup” and collect herself.
      <Citation number={212} />
    </Typography>

    <Image
      src="/images/image-096.jpg"
      alt="Sarah Matthews testifies at a January 6th Select Committee hearing."
      caption="Sarah Matthews testifies at a January 6th Select Committee hearing."
      credit="(Photo by House Creative Services)"
    />

    <Typography className="indent">
      Several witnesses corroborated pieces of this account. General Kellogg said he saw Ivanka Trump coming and going from the dining room at least twice that afternoon.
      <Citation number={213} />
      Hutchinson said that it was “several times.”
      <Citation number={214} />
      Once, Ivanka Trump reportedly left her father with a look on her face as if “[s]he had just had a tough conversation.”
      <Citation number={215} />
      Radford, Ivanka Trump’s Chief of Staff, saw that she was “[v]isibly upset” but continued going “down there when people were asking her to be down there and trying to get action taken.”
      <Citation number={216} />
    </Typography>

    <Typography className="indent">
      Radford told the Select Committee that Ivanka Trump believed that “[s]omething should be said or put out that was even stronger.”
      <Citation number={217} />
    </Typography>

    <Typography className="indent">
      Hutchinson, too, recalled Ivanka Trump dropping by Meadows’s office alongside Cipollone and talking about trying to convince her father to say something “more direct than he had wanted to at that time and throughout the afternoon.”
      <Citation number={218} />
    </Typography>

    <Typography className="indent">
      “I remember her saying at various points,” Hutchinson said, “she wanted her dad to send them home. She wanted her dad to tell them to go home peacefully, and she wanted to include language that he necessarily wasn’t on board with at the time.”
      <Citation number={219} />
    </Typography>
  </Page>
)
