import { Navigate, Route, Routes } from 'react-router-dom';

import * as Content from '../content';
import * as Appendices from '../content/Appendices';
import TOC_DATA from '../toc.json';

// @TODO wtf???
const AllContent: any = {...Content, ...Appendices};
export const routes = getRoutes(TOC_DATA);

export const Router = () => (
  <Routes>
    {
      routes.map(({ route, component, primary, subtitle, sourcePage }: any, index: number) => {
        const Component = AllContent[component];
        const element = (
          <Component
            title={primary}
            subtitle={subtitle}
            next={routes[index+1]}
            prev={routes[index-1]}
            audio={`${component}.mp3`}
            sourcePage={sourcePage}
          />
        );

        return (
          <Route path={route} element={element} key={route} />
        )
      })
    }

    <Route path="*" element={<Navigate to={routes[0].route} replace={true} />} />
  </Routes>
);

function getRoutes(data: any, hrefChain: string = '', _subtitle: string = ''): any {
  return data.reduce((acc: any, { content, primary, slug, component, title, sourcePage }: any) => {
    let href = `${hrefChain}/${slug}`;
    let subtitle = _subtitle;

    if (content) {
      subtitle += (subtitle.length ? ' > ' : '') + title;

      acc = acc.concat(getRoutes(content, href, subtitle))
    } else {
      if (title) {
        subtitle += (subtitle.length ? ' > ' : '') + title;
      }

      acc.push({
        route: href,
        component,
        primary,
        subtitle,
        sourcePage,
      })
    }

    return acc;
  }, []);
}