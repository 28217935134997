import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';

const Citation = lazy(() => import('../../Citations/CitationSheet10'));

export const Chapter88 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      When rioters surrounded the perimeter of the Capitol, and reached the Senate and House Chambers, Members were forced to evacuate for safety. USCP officers responded to both Chambers and served as escorts. By the time the Capitol was breached, the Senate and House had split from the joint session, with Senators returning to their Chamber to debate the objection to Arizona’s electoral vote. The House remained in its Chamber to debate the objection.
      <Citation number={297} />
    </Typography>

    <Typography className="indent">
      Starting in the Senate, Vice President Pence was escorted off the floor at 2:12 p.m. and was taken to his Senate office. Between 2:12 p.m. and 2:25 p.m., Secret Service agents worked to identify potential threats and a route that could be used to transport Vice President Pence.
      <Citation number={298} />
      One of the issues for Vice President Pence’s evacuation was that the rioters were outside the Ohio Clock Tower, which was just feet away from the staircase that Vice President Pence could descend to evacuate.
      <Citation number={299} />
      Eventually, after the mob started filling the entire Capitol, the Secret Service made the decision to move Vice President Pence, and he was escorted from the Senate at 2:25 p.m.
      <Citation number={300} />
      By 2:27 p.m., the Vice President can be seen moving toward a secure location connected to the Capitol. The Vice President arrived at the secure location at 2:29 p.m.
      <Citation number={301} />
      Following the Vice President’s evacuation, Senators were evacuated at 2:30 p.m.
      <Citation number={302} />
    </Typography>

    <Image
      src="/images/image-112.jpg"
      alt="Members of Congress are evacuated from the House Chamber."
      caption="Members of Congress are evacuated from the House Chamber."
      credit="(Photo by Drew Angerer/Getty Images)"
    />

    <Typography className="indent">
      On the House side, Speaker Pelosi, House Majority Leader Steny Hoyer, and House Majority Whip James Clyburn were removed from the House floor at the same time as Vice President Pence. By 2:18 p.m., USCP surveillance showed Speaker Pelosi in the basement hallway headed towards the garage.
      <Citation number={303} />
      The surveillance footage also showed Leader Hoyer and Whip Clyburn in the same basement as Speaker Pelosi. At 2:23 p.m., Speaker Pelosi and Whip Clyburn were moved to an undisclosed location.
      <Citation number={304} />
    </Typography>

    <Typography className="indent">
      Minority Leader Kevin McCarthy was evacuated just after Speaker Pelosi left the Capitol. At 2:25 p.m., as rioters were moving through the Crypt and breaking through the east Rotunda door, Leader McCarthy and his staff hurriedly evacuated his office.
      <Citation number={305} />
      At approximately 2:38 p.m., the Members of Congress on the House floor began their evacuation.
      <Citation number={306} />
      Members of Congress can be seen evacuating through the Speaker’s Lobby when a USCP officer fatally shot Ashli Babbitt at 2:44 p.m.
      <Citation number={307} />
      Members and staffers were just feet away when Babbitt attempted to climb through a shattered glass door. USCP officers had barricaded the door with furniture to prevent the rioters from gaining direct access to elected officials.
    </Typography>

    <Typography className="indent">
      The congressional Members in the House Gallery were evacuated after the Members on the House floor. Congressional Members in the Gallery had to wait to be evacuated because rioters were still roaming the hallways right outside the Chamber. At 2:49 p.m., as Members were trying to evacuate the House Gallery, the USCP emergency response team cleared the hallways with long rifles so that the Members could be escorted to safety.
      <Citation number={308} />
      USCP surveillance footage shows several rioters lying on the ground, with long rifles pointed at them, as Members evacuate in the background.
      <Citation number={309} />
      By 3:00 p.m., the area had been cleared and Members were evacuated from the House gallery to a secure location.
      <Citation number={310} />
    </Typography>
  </Page>
)
