import { lazy } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Image } from '../../../components/Image';
import { Page } from '../../../components/Page';
import { StyledListContainer } from '../../../components/StyledListContainer';

const Citation = lazy(() => import('../../Citations/CitationSheet4'));

export const Chapter23 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      Just one day after the State-focused plan came up in the Oval Office with the President and his top lieutenants, President Trump started taking concrete steps aimed at State legislators. And in the weeks that followed, the President spearheaded outreach aimed at numerous officials in States he lost but that had GOP-led legislatures, including in Michigan, Pennsylvania, Georgia, and Arizona.
    </Typography>

    <Typography className="indent">
      The Select Committee estimates that in the two months between the November election and the January 6th insurrection, President Trump or his inner circle engaged in at least 200 apparent acts of public or private outreach, pressure, or condemnation, targeting either State legislators or State or local election administrators, to overturn State election results. This included at least:
    </Typography>

    <StyledListContainer>
      <ul>
        <li>
          <Typography>
            68 meetings, attempted or connected phone calls, or text messages, each aimed at one or more State or local officials;
          </Typography>
        </li>
        <li>
          <Typography>
            18 instances of prominent public remarks, with language targeting one or more such officials;
            <Citation number={56} />
            and
          </Typography>
        </li>
        <li>
          <Typography>
            125 social media posts by President Trump or senior aides targeting one or more such officials, either explicitly or implicitly, and mostly from his own account.
            <Citation number={57} />
          </Typography>
        </li>
      </ul>
    </StyledListContainer>

    <Typography className="indent">
      Furthermore, these efforts by President Trump’s team also involved two other initiatives that tried to enlist support from large numbers of State legislators all at once:
    </Typography>

    <StyledListContainer>
      <ul>
        <li>
          <Typography>
            The Trump Campaign contacted, or attempted to contact, nearly 200 State legislators from battleground States between November 30, 2020 and December 3, 2020, to solicit backing for possible Statehouse resolutions to overturn the election. At least some messages said they were “on behalf of the president.”
            <Citation number={58} />
          </Typography>
        </li>
        <li>
          <Typography>
            Nearly 300 State legislators from battleground States reportedly participated in a private briefing with President Trump, Rudolph Giuliani, John Eastman, and others on January 2nd. The President reportedly urged them to exercise what he called “the real power” to choose electoral votes before January 6th, because, as President Trump said on the call, “I don’t think the country is going to take it.”
            <Citation number={59} />
          </Typography>
        </li>
      </ul>
    </StyledListContainer>

    <Typography className="indent">
      It may be impossible to document each and every meeting, phone call, text message, or other contact that President Trump and his allies had with State and local officials in various battleground States. What follows is a summary that focuses on four States and that demonstrates the lengths to which President Trump would go in order to stay in power based on lies— the Big Lie—about the election.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        PRESIDENT TRUMP’S EARLY PRESSURE ON PUBLIC SERVANTS
      </Typography>
    </Box>

    <Typography className="indent">
      To carry out his plan, President Trump, Rudolph Giuliani, and other surrogates of President Trump publicly and privately sought assistance from State and local officials whom they assumed would help as Republicans on the same team with the “same goal.”
      <Citation number={60} />
      Some helped. Others didn’t.
    </Typography>

    <Typography className="indent">
      On November 12th, U.S. Representative Tim Walberg (R-MI) sent an email to President Trump’s Executive Assistant Molly Michael, describing a request he had received earlier that day:
    </Typography>

    <Blockquote>
      <Typography>
        During my conversation with the President this morning he asked me to check with key leadership in Michigan’s Legislature as to how supportive they could be in regards to pushing back on election irregularities and potential fraud. He wanted me to gauge their willingness to talk with him about efforts to bring about transparency and integrity in Michigan’s election and report back to him.
        <Citation number={61} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Representative Walberg added that he had already acted on this request: “I’ve had conversations with [Michigan] Speaker Lee Chatfield, Senate Majority Leader Mike Shirkey, and Senate President Pro Tempore Aric Nesbitt. They all assured me they would look forward to speaking with the President to report on their continuing efforts” related to overseeing the election “and receiving any suggestions from President Trump.”
      <Citation number={62} />
      The President would soon host Chatfield, Shirkey, Nesbitt, and four other Michigan State lawmakers at the White House.
      <Citation number={63} />
    </Typography>

    <Typography className="indent">
      In Arizona, on November 13, 2020, the day after officials finished counting ballots cast in Maricopa County, Chairwoman Kelli Ward, of the Arizona Republican Party, texted Mark Meadows that she had “[j]ust talked to POTUS” and that “[h]e may call the Chairman of the Maricopa Board of Supervisors,” Clint Hickman.
      <Citation number={64} /> Ward also left a message for Hickman that said, “I just talked to President Trump, and he would like me to talk to you and also see if he needs to give you a call to discuss what’s happening on the ground in Maricopa. Give me a call back when you can.”
      <Citation number={65} />
      According to Hickman, Ward was unusually active after the election, even for a party chair, and was the first person to pressure him. One of her first messages to Hickman before trying to connect him with President Trump was: “We need you to stop the counting.”
      <Citation number={66} />
    </Typography>

    <Typography className="indent">
      In Georgia, the President initially took a more public approach. After the Associated Press called the race there on November 12th, President Trump tweeted harsh criticisms of Governor Brian Kemp and Secretary of State Brad Raffensperger.
      <Citation number={67} />
      Often these tweets called for them to take specific actions that would have shifted the election results in his favor, such as rejecting a court settlement (which he referred to as a consent decree) that dictated the procedures for verifying signatures on absentee ballots. And he was relentless.
    </Typography>

    <Typography className="indent">
      In November alone, President Trump tweeted that Raffensperger was “a so-called Republican (RINO)” and asked “Where is @BrianKempGA,”
      <Citation number={68} />
      before suggesting that “They knew they were going to cheat.”
      <Citation number={69} />
      He called to “Break the unconstitutional Consent Decree!”
      <Citation number={70} /> and urged stricter signature matches with a demand to “Get it done! @BrianKempGA.”
      <Citation number={71} /> He called Kemp “hapless” and asked why he wouldn’t use emergency powers to overrule Raffensperger on the signature-verification procedures, declaring that “Georgia Republicans are angry.”
      <Citation number={72} /> President Trump also retweeted posts asking, “Who needs Democrats when you have Republicans like Brian Kemp,” and “why bother voting for Republicans if what you get is Ducey and Kemp?”
      <Citation number={73} />
    </Typography>

    <Typography className="indent">
      Pennsylvania was an early, but not unique, example of how President Trump’s State-pressure campaign affected the lives of the public servants running this country’s elections.
    </Typography>

    <Typography className="indent">
      On November 7th, Rudy Giuliani headlined a Philadelphia press conference in front of a landscaping business called Four Seasons Total Landscaping, near a crematorium and down the street from a sex shop.
      <Citation number={74} />
    </Typography>

    <Typography className="indent">
      Standing in front of former New York Police Commissioner and recently-pardoned convicted felon, Bernard Kerik, Giuliani gave opening remarks and handed the podium over to his first supposed eyewitness to election fraud, who turned out to be a convicted sex offender.
      <Citation number={75} />
      Giuliani claimed “at least 600,000 ballots are in question” in Pennsylvania and falsely suggested that large numbers of ballots in the State had been cast for dead people, including boxer Joe Frazier and actor Will Smith’s father.
      <Citation number={76} />
    </Typography>

    <Typography className="indent">
      Within days, Republican Philadelphia City Commissioner Al Schmidt and others publicly debunked Giuliani’s specific allegations of election fraud, including the claims about dead people voting in Pennsylvania elections.
      <Citation number={77} />
      In reaction, President Trump tweeted on the morning of November 11th that “[a] guy named Al Schmidt, a Philadelphia Commissioner and so-called Republican (RINO), is being used big time by the Fake News Media to explain how honest things were with respect to the Election in Philadelphia. He refuses to look at a mountain of corruption & dishonesty. We win!”
      <Citation number={78} />
    </Typography>

    <Typography className="indent">
      That statement targeting Schmidt led to a deluge of threatening and harassing phone calls and emails by people who heard President Trump and falsely held out hope that Schmidt or someone else could overturn the results of Pennsylvania’s election.
      <Citation number={79} />
    </Typography>

    <Typography className="indent">
      As a public official, Schmidt was no stranger to threats. But being targeted by the President of the United States was different. In Schmidt’s public testimony to the Select Committee, he described why. “[P]rior to that the threats were pretty general in nature. ‘Corrupt election officials in Philadelphia are going to get what’s coming to them’” and other similar threats.
      <Citation number={80} />
      “After the President tweeted at me by name, calling me out the way that he did,” Schmidt explained, “the threats became much more specific, much more graphic, and included not just me by name but included members of my family by name, their ages, our address, pictures of our home. Just every bit of detail that you could imagine.”
      <Citation number={81} />
    </Typography>

    <Typography className="indent">
      As the President continued to push the Big Lie and vilify public officials, such threats multiplied.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        EFFORTS TO PREVENT STATE AND LOCAL OFFICIALS FROM CERTIFYING THE ELECTION
      </Typography>
    </Box>

    <Typography className="indent">
      Some of President Trump’s early outreach was part of an effort to prevent State and local officials from certifying his loss. One example comes from Michigan, and the other from Arizona.
    </Typography>

    <Typography className="indent">
      Wayne County, Michigan, includes Detroit and its surrounding areas. On November 17th, the county’s Board of Canvassers met to certify election results, a process the Michigan Supreme Court described over a century ago as ministerial and clerical.
      <Citation number={82} />
    </Typography>

    <Typography className="indent">
      The meeting started at 6:00 p.m. and lasted over three hours.
      <Citation number={83} />
      Its two Republican members, Board Chair Monica Palmer and Board Member William Hartmann, first voted to block the certification of the election.
      <Citation number={84} />
      After a brief break, Palmer and Hartmann returned, changed their votes, and certified the election results.
      <Citation number={85} />
      Just over twenty minutes later, Palmer and Hartmann received a call from President Trump and RNC Chair Ronna McDaniel.
      <Citation number={86} />
    </Typography>

    <Typography className="indent">
      Palmer claimed that the call “was not pressure.” Rather, she said, “[i]t was genuine concern for my safety” and “there were general comments about different States, but we really didn’t discuss the details of the certification.”
      <Citation number={87} />
    </Typography>

    <Typography className="indent">
      The Select Committee doesn’t know exactly what President Trump privately said on that phone call.
      <Citation number={88} />
      By the next evening, however, Palmer and Hartmann had each issued signed affidavits reassuming their earlier position that Wayne County’s results should not be certified.
      <Citation number={89} />
      Palmer’s affidavit even declared that “I rescind my prior vote,” though rescinding wasn’t possible and her statement had no legal effect.
      <Citation number={90} />
      And, President Trump apparently knew before it was public that Hartmann and Palmer would try to change their votes; almost eight hours <i>before</i> either of these affidavits were publicly released, President Trump tweeted that these “two harassed patriot Canvassers refuse to sign the papers!”
      <Citation number={91} />
    </Typography>

    <Typography className="indent">
      Republicans in Arizona experienced similar treatment. In the most populous and electorally significant county in Arizona, Maricopa County’s Board of Supervisors met on November 20th to certify the county’s election results. Their Board, made up of four Republicans and one Democrat, carefully reviewed the official canvass, asked questions for approximately two hours, then unanimously voted to certify the results.
      <Citation number={92} />
    </Typography>

    <Typography className="indent">
      Earlier that day, Kelli Ward contacted two of the board’s members, Jack Sellers and Bill Gates, and asked them to delay the certification on the basis of supposed improprieties.
      <Citation number={93} />
      According to Sellers and Gates, however, Arizona law required certification that day and they had no information (neither then, nor ever) to doubt the county’s election results.
      <Citation number={94} />
    </Typography>

    <Typography className="indent">
      When Arizona certified its 2020 statewide election results on November 30th, it fell to Governor Doug Ducey, a Republican, to sign the certification. While on camera during the signing ceremony, Governor Ducey’s phone played a ringtone for the song “Hail to the Chief,” which he immediately silenced.
      <Citation number={95} />
    </Typography>

    <Typography className="indent">
      The Governor later confirmed it had been President Trump calling and that he returned the President’s call shortly afterwards, but declined to say what the two discussed other than saying that President Trump did not ask him to withhold certification.
      <Citation number={96} />
      The Select Committee does not know whether that is true, but that evening President Trump blasted Ducey on Twitter, accusing him of “rushing to put a Democrat in office,” and warning that “Republicans will long remember!”
      <Citation number={97} />
      The President also retweeted posts bashing Ducey and his Georgia counterpart Brian Kemp, which asked “Who needs Democrats when you have Republicans like Brian Kemp and Doug Ducey?”, “why bother voting for Republicans if what you get is Ducey and Kemp?”, and “Brian Kemp: ‘My state ran the most corrupt election in American history.’ Doug Ducey: ‘Hold my beer.’”
      <Citation number={98} />
      President Trump even commented “TRUE!” when retweeting a post that “Gov Ducey has betrayed the people of Arizona.”
      <Citation number={99} />
    </Typography>

    <Typography className="indent">
      Governor Ducey pushed back, writing on Twitter that, “I’ve been pretty outspoken about Arizona’s election system, and bragged about it quite a bit, including in the Oval Office... In Arizona, we have some of the strongest election laws in the country... The problems that exist in other states simply don’t apply here.”
      <Citation number={100} />
      Governor Ducey explained the law for certifying elections in Arizona and pointed out that the certification now triggered a “5-day window for any elector to bring a credible challenge to the election results in court. If you want to contest the results, now is the time. Bring your challenges.”
      <Citation number={101} />
      And, Governor Ducey referenced his oath of office: “That’s the law. I’ve sworn an oath to uphold it, and I take my responsibility seriously.”
      <Citation number={102} />
      President Trump and his allies never brought a credible challenge and, instead, lost every case they brought challenging the results in Arizona.
    </Typography>

    <Image
      src="/images/image-038.jpg"
      alt='unknown, testifying'
      credit="(Photo by Samuel Corum/Getty Images)"
    />

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        EFFORTS TO REPLACE ELECTORAL COLLEGE ELECTORS AND OVERTURN THE ELECTION
      </Typography>
    </Box>

    <Typography className="indent">
      Once counties and States certified the election, or when it was nearly certain that they would, President Trump and his team’s focus largely shifted. President Trump and his team encouraged State legislators to meet in special sessions, if necessary, and choose electoral college electors who would vote for the Trump/Pence ticket. Ultimately, no State legislature took that step, but it was the basis for pressuring State officials from November through January 6, 2021.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        MEETINGS WITH STATE LEGISLATORS — THE “HEARINGS”
      </Typography>
    </Box>

    <Typography className="indent">
      The concept of State legislators appointing their own electors featured prominently in a series of hastily arranged official and unofficial “hearings” with State legislators that the Trump team announced on November 24, 2020.
      <Citation number={103} />
    </Typography>

    <Typography className="indent">
      On November 25th, President Trump called in to an unofficial meeting with legislators in Gettysburg, Pennsylvania.
      <Citation number={104} />
      The meeting was set up to appear like an official hearing, but it was not. It took place in a hotel ballroom, and those presenting arguments or purported evidence, like Giuliani, Jenna Ellis, and others, were not placed under oath.
      <Citation number={105} />
      According to President <i>Pro Tempore</i> of the Pennsylvania Senate Jake Corman, he had initially been asked by State Senator Doug Mastriano to hold a hearing about the election. Corman responded that any formal hearing should be official, with sworn testimony, and open to both parties.
      <Citation number={106} />
      That was not what Senator Mastriano ultimately convened.
    </Typography>

    <Typography className="indent">
      President Trump had originally made plans to attend the Pennsylvania gathering in person, but he cancelled after several advisors tested positive for COVID–19.
      <Citation number={107} />
      When President Trump called in and spoke to those gathered in the hotel ballroom, his false claims were met with cheers, and he made his purpose clear: “this election has to be turned around... Why wouldn’t they overturn an election? Certainly overturn it in your State... We have to turn the election over.”
      <Citation number={108} />
    </Typography>

    <Typography className="indent">
      President Trump made the ask and Giuliani told the legislators how to carry it out. Giuliani told the assembled legislators that it was their “power” and “responsibility” to pick Pennsylvania’s presidential electors and that “[they] have to convince the rest of [their] members, Republican and Democrat, they owe that to the people of” Pennsylvania.
      <Citation number={109} />
      Jenna Ellis told them that although Pennsylvania law dictates that electors are chosen by popular vote, “[y]ou can take that power back at any time. You don’t need a court to tell you that.”
      <Citation number={110} />
    </Typography>

    <Typography className="indent">
      President Trump invited some of the lawmakers to come meet him at the White House that evening and, according to Giuliani, it was “a large group” that went.
      <Citation number={111} />
      Special Assistant to the President Cassidy Hutchinson’s text messages with Kerik included the guest list and descriptions of the vehicles that would need access to the White House grounds.
      <Citation number={112} />
      Pennsylvania State Senator Doug Mastriano drove one car, a hired driver drove a van with most of the State legislators, and Kerik drove an SUV with attorney Katherine Friess and election-conspiracy proponent Phil Waldron.
      <Citation number={113} />
      Hutchinson estimated that at least 29 visitors traveled from Pennsylvania to the White House that day, and she explained that their conversation with the President touched on holding a special session of the State legislature to appoint Trump electors.
      <Citation number={114} />
    </Typography>

    <Typography className="indent">
      Just a few days later, on November 30, 2020, President Trump also called into another one of Giuliani and Jenna Ellis’s hotel “hearings,” this time in Arizona. Several Arizona State lawmakers hosted the meeting at a Hyatt Regency in Phoenix after they did not receive permission to organize an official hearing at the State Capitol.
      <Citation number={115} /> Before the hearing started, State GOP Representative Mark Finchem “promised information to show that the state’s 11 electoral votes should not go to Democrat Joe Biden,” and argued that “the U.S. Constitution empowers lawmakers to decide, on their own, whether the election was valid and, if not, to select the electors of their choice.”
      <Citation number={116} />
    </Typography>

    <Typography className="indent">
      Giuliani told the assembled legislators that the officials certifying Arizona’s election results “have made no effort to find out” if the results of the election were accurate, “which seems to me gives the state legislature a perfect reason to take over the conduct of this election because it’s being conducted irresponsibly and unfairly.”
      <Citation number={117} />
      Likewise, Jenna Ellis said that it was “not just the choice, but the actual duty and obligation of the legislature to step in and to make sure that you don’t certify false results.”
      <Citation number={118} />
      During a recess, she also took to Twitter, writing, “[t]he certification of Arizona’s FALSE results is unethical and knowingly participating in the corruption that has disenfranchised AZ voters. BUT, this in no way impacts the state legislature’s ability to take back the proper selection of delegates.”
      <Citation number={119} />
    </Typography>

    <Typography className="indent">
      When it was President Trump’s turn to address this handful of lawmakers over the phone, he called them “legends for taking this on,” and used the opportunity to criticize Governor Ducey: “you’ll have to figure out what’s that all about with Ducey. He couldn’t [certify] fast enough” and “Arizona will not forget what Ducey just did. We’re not gonna forget.”
      <Citation number={120} />
      That night Giuliani joined President Trump in criticizing Governor Ducey, while at the same time making baseless allegations about voting machines in Arizona and calling for a special legislative session to change the outcome of the election: “Governor Ducey of Arizona refuses to meet with me. He doesn’t want to explain that he selected a foreign corrupt Voting Machine company to count the vote. I understand his reluctance, but [<i>sic</i>] just call a special session. Let’s find out how crooked your election really was?”
      <Citation number={121} />
    </Typography>

    <Typography className="indent">
      Michigan was next. Giuliani’s team announced that the Michigan legislature would hold a hearing on December 1st, but the relevant committee chair excluded Giuliani because it was only open to witnesses “with first hand knowledge.”
      <Citation number={122} />
      That chairman, Michigan State Senator Edward McBroom (R-Vulcan), had already held Senate Oversight hearings by then in an actual effort to evaluate claims of fraud in the 2020 election, which ultimately resulted in a comprehensive report that concluded that the Republican-led committee “found no evidence of widespread or systematic fraud” in Michigan’s election.
      <Citation number={123} />
    </Typography>

    <Typography className="indent">
      Michigan’s House Oversight Committee, however, did allow Giuliani to testify in a hearing on December 2nd. Before the hearing, Giuliani joined the State’s GOP chairwoman to give what was billed as a legal briefing. In the online presentation, Giuliani told the audience there’s “nothing wrong with putting pressure on your state legislators”
      <Citation number={124} />
      to pick new electors and that “you have got to get them to remember that their oath to the Constitution sometimes requires being criticized. Sometimes it even requires being threatened.”
      <Citation number={125} />
    </Typography>

    <Typography className="indent">
      When Giuliani appeared for the hearing in Michigan, he was not placed under oath, used his time to refer to Michigan’s election as a “con job,” and urged legislators to “have the courage to say that certification that was done by your state is a complete phony.”
      <Citation number={126} />
      The information presented was baseless—and sometimes racist—conspiracy theories. One witness brought to criticize Michigan’s voter verification even said: “I think Chinese all look alike. So how would you tell? If some Chow shows up, you can be anybody and you can vote.”
      <Citation number={127} />
      And, as he had promised in the legal briefing the day before, Giuliani then called on the legislators to do what the Trump Campaign had reportedly been discussing since before election day. He said that the State legislature could still singlehandedly decide the election result “anytime you want to. Anytime. You can take it back tonight. You can take it back the day before the electors go down to Washington.”
      <Citation number={128} />
      Jenna Ellis also participated, insisting “no honest person can hear these citizens of your own state today... and can let this proceed. What the Constitution obligates you to do is to take back your plenary power.”
      <Citation number={129} />
    </Typography>

    <Typography className="indent">
      Finally, Georgia. There, Giuliani and others appeared in multiple hearings, the first of which was held on December 3, 2020. In that hearing, Giuliani was direct and called on Georgia legislators to overturn the election results—“you are the final arbiter of who the electors should be”—based on the false premise that “there is more than ample evidence to conclude that this election was a sham.”
      <Citation number={130} />
      Then, at a separate hearing on December 10th, he told State legislators that Georgia’s Governor, Lieutenant Governor, and secretary of State were engaged in a “cover up” of “a crime in plain sight,” and that it fell to “the state legislature [ ] to vindicate the honor of the state.”
      <Citation number={131} />
      And, Giuliani used yet another appearance, on December 30th, to call the 2020 election “the most crooked election, the most manipulated election in American history,” and implore the Republican legislators to hold a special session to vote on appointing new electors, something he said that they could do “right up until the last moment” before January 6th.
      <Citation number={132} />
    </Typography>

    <Typography className="indent">
      More perniciously, Giuliani also used these hearings to advance conspiracy theories that falsely accused Fulton County election workers of rigging Georgia’s election results. His delegation to the December 3rd hearing played clips of election-night surveillance footage from the State Farm Arena that showed election workers scanning ballots, sometimes after partisan poll watchers had gone home.
      <Citation number={133} />
      Although the poll watchers should have been there the entire time while election workers counted the votes, there was nothing nefarious about the circumstances and no question about the end result. In fact, the FBI, Department of Justice, and Georgia Bureau of Investigation would determine that these ballots were legitimate ballots, that observers were not illegally ejected, and that the ballots were scanned and counted properly, contrary to claims by President Trump and his attorneys.
      <Citation number={134} />
      And yet Giuliani baselessly declared at the December 3rd hearing that, to him, the video was a “powerful smoking gun” proving that “those votes are not legitimate votes.”
      <Citation number={135} />
    </Typography>

    <Typography className="indent">
      But Giuliani’s claims took a more ominous turn during the December 10th hearing. There, he publicly named two of the election workers shown in the video, Ruby Freeman and her daughter, Wandrea ArShaye “Shaye” Moss, and accused them of vote-tampering and engaging in criminal conduct.
      <Citation number={136} />
      He seized on a clip of Freeman passing Moss a ginger mint, claiming that the two women, both Black, were smuggling USB drives “as if they’re vials of heroin or cocaine.” He also suggested that Freeman and Moss should be jailed and that they deserved to have their homes searched.
      <Citation number={137} />
      Not only were Giuliani’s claims about Freeman and Moss reckless, racist, and false, they had real-world consequences that turned both women’s lives upside down. And further heightening the personal impact of these baseless attacks, President Trump supported, and even repeated, them, as described later.
    </Typography>

    <Typography className="indent">
      In the end, the hearings were widely panned. In Michigan alone, current and former Republican lawmakers publicly questioned the hearings and implored President Trump and his team to stop. U.S. Representative Paul Mitchell (R-Mich.) implored on Twitter “Please JUST STOP!” and “wondered why Republican leaders allowed testimony he said was ‘driving the party into this ditch.’”
      <Citation number={138} />
      Similarly, former Michigan lawmaker Martin Howrylak (R-Oakland) said that he was “embarrassed” by the hearing, and former Michigan Senator Ken Sikkema (R-Grand Rapids) said that “the way the committee was run was atrocious.”
      <Citation number={139} />
      Later, the President promoted a tweet calling a Democratic lawmaker a “#pos” for speaking out at the Michigan hearing.
      <Citation number={140} />
      Months later, Giuliani’s license to practice law in New York was suspended for, among other reasons, the “false claims” he made on various dates, including during the hearings in Michigan, Pennsylvania, Arizona, and Georgia.
      <Citation number={141} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        THE TRUMP CAMPAIGN’S BARRAGE OF PHONE CALLS TO STATE LEGISLATORS
      </Typography>
    </Box>

    <Typography className="indent">
      Not only was replacing electors a theme during the official and unofficial State hearings, it was also a critical component of President Trump’s plan both before and after the hearings took place.
    </Typography>

    <Typography className="indent">
      In fact, while the hearings were happening, the Trump Campaign set up an operation to contact hundreds of State legislators and ask them to support an effort to appoint electoral college electors for the Trump/Pence ticket in States that President Trump had lost.
    </Typography>

    <Typography className="indent">
      On the same day as Giuliani’s hearing in Michigan, Trump Campaign staff contacted dozens of Republicans in Michigan’s State legislature. A Trump Campaign supervisor sent text messages to his team, directing them to reach out to lawmakers “to explain the process for legislative redress and tell them how to send representative[s] to th[e] electoral college.”
      <Citation number={142} />
      He added: “We’re gonna be lobbyists. Woot.”
      <Citation number={143} />
    </Typography>

    <Typography className="indent">
      According to a Campaign staffer’s spreadsheet produced to the Select Committee, the Trump Campaign apparently tried contacting over 190 Republican State legislators in Arizona, Georgia, and Michigan, alone.
      <Citation number={144} />
    </Typography>

    <Typography className="indent">
      One voicemail left as part of this initiative was leaked to the press on December 1, 2020. In it, a Trump Campaign staffer said, “I did want to personally reach out to you on behalf of the President.”
      <Citation number={145} />
      Her main point came later in the message: “we want to know when there is a resolution in the House to appoint electors for Trump if the President can count on you to join in support.”
      <Citation number={146} />
      Another message from this effort that reached reporters made the same ask and claimed that, “[a]fter a roundtable with the President, he asked us to reach out to you individually” to whip support for a “joint resolution from the State House and Senate” that would “allow Michigan to send electors for Donald J. Trump to the Electoral College and save our country.”
      <Citation number={147} />
    </Typography>

    <Typography className="indent">
      Soon after the voicemail leaked, the Campaign staffer who left this voicemail got a text message from one of her supervisors, who wrote: “Honest to god I’m so proud of this” because “[t]hey unwittingly just got your message out there.”
      <Citation number={148} />
      He elaborated: “you used the awesome power of the presidency to scare a state rep into getting a statewide newspaper to deliver your talking points.”
      <Citation number={149} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h3" color="primary">
        OUTREACH BY PRESIDENT TRUMP AND SENIOR AIDES
      </Typography>
    </Box>

    <Typography className="indent">
      While Campaign aides blanketed State officials with these calls, some State officials received more personalized outreach directly from President Trump, Giuliani, and their allies throughout the post-election period about this issue.
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        Michigan
      </Typography>
    </Box>

    <Typography className="indent">
      As discussed earlier, Rep. Walberg reached out to State legislators in Michigan at the President’s request in mid-November, including Senate Majority Leader Mike Shirkey and House Speaker Lee Chatfield. By November 18th, President Trump called Chatfield and Shirkey to invite them to what would become a meeting for a group of Michigan lawmakers in the Oval Office.
      <Citation number={150} />
      Although President Trump didn’t tell Shirkey what the meeting would be about, the President was focused on the election and asked Shirkey what he and others were doing to investigate election fraud.
      <Citation number={151} />
      The meeting happened on November 20th.
      <Citation number={152} />
    </Typography>

    <Typography className="indent">
      In Shirkey’s words, there “wasn’t a mystery” about why the group was at the White House once the meeting started.
      <Citation number={153} />
      When the President mentioned several baseless claims of election fraud in Wayne County, Shirkey told the President that he had lost the election and that it had nothing to do with Wayne County, where he had actually performed better than he had in 2016.
      <Citation number={154} />
    </Typography>

    <Typography className="indent">
      From the President’s body language, Shirkey concluded that wasn’t what he wanted to hear. But the meeting continued, and the President dialed in Giuliani, who delivered a “long monologue,” reciting a “litany” of allegations about supposed fraud that was short on substance.
      <Citation number={155} />
      Shirkey challenged Giuliani, asking “when are you going to... file a lawsuit in Michigan,” which he said Giuliani did not answer.
      <Citation number={156} />
      Although Shirkey says he did not recall the President making any precise “ask,” Chatfield recalled President Trump’s more generic directive for the group to “have some backbone and do the right thing.”
      <Citation number={157} />
      Chatfield understood that to mean they should investigate claims of fraud and overturn the election by naming electors for President Trump.
      <Citation number={158} />
      Shirkey told the President that he was not going to do anything that would violate Michigan law.
      <Citation number={159} />
    </Typography>

    <Typography className="indent">
      After the meeting ended, Shirkey and Chatfield issued a joint statement: “We have not yet been made aware of any information that would change the outcome of the election in Michigan and as legislative leaders, we will follow the law and follow the normal process regarding Michigan’s electors, just as we have said throughout this election.”
      <Citation number={160} />
    </Typography>

    <Typography className="indent">
      That was not the end, however. Chatfield and Shirkey received numerous calls from the President in the weeks following the election. Chatfield told the Select Committee that he received approximately five to ten phone calls from President Trump after the election, during which the President would usually ask him about various allegations of voter fraud.
      <Citation number={161} />
      Chatfield said that he repeatedly looked into the President’s claims but never found anything persuasive that could have changed the outcome of the election.
      <Citation number={162} />
    </Typography>

    <Typography className="indent">
      President Trump’s calls were not enough, so he turned to the public. On January 3, 2021, the Trump Campaign posted a tweet that urged supporters to “Contact Speaker Lee Chatfield & Senate Majority Leader Mike Shirkey” to “Demand [a] vote on decertification.”
      <Citation number={163} />
      Why President Trump thought the Michigan legislature would convene to decertify the election in a matter of hours when it had refused to do so since early November is not clear. But that didn’t stop the President from making things personal. The President’s January 3rd tweet included Shirkey’s personal cellphone number as well as a number for Chatfield that turned out to be wrong. As a result, Shirkey said he received nearly 4,000 text messages, and another private citizen reported being inundated with calls and texts intended for Chatfield.
      <Citation number={164} />
    </Typography>

    <Image
      src="/images/image-039.jpg"
      alt="unknown, speaking to crowd"
      credit="(Photo by Rey Del Rio/Getty Images)"
    />

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        Pennsylvania
      </Typography>
    </Box>

    <Typography className="indent">
      On November 21st, Mark Meadows texted a number apparently belonging to Representative Scott Perry (R–PA) and asked: “Can you send me the number for the speaker and the leader of the PA Legislature. POTUS wants to chat with them.”
      <Citation number={165} />
      Hours later, Meadows received a response of “Yes sir.”
      <Citation number={166} />
      At the time, the leader of the Pennsylvania Senate was Jake Corman and the Speaker of the Pennsylvania House was Bryan Cutler.
    </Typography>

    <Typography className="indent">
      Corman told the Select Committee that he received a call on Thanksgiving Day 2020 from Giuliani, urging him to call the legislature into a special session to replace Biden electors with Trump electors.
      <Citation number={167} />
      This idea wasn’t new to Corman. President Trump and his allies had gone public about their intentions before then, including during the Pennsylvania hotel hearing, but Corman had braced himself for this even before the election. Before election day in 2020, a reporter from The Atlantic interviewed Corman and other prominent Republicans in Pennsylvania about the possibility that President Trump would try to circumvent the popular vote in swing States by asking the legislatures to appoint Trump/Pence electors. After the article, Corman drafted an op-ed, making it clear that the Pennsylvania legislature did not have the legal authority to appoint Trump/Pence electors in contravention of the popular vote, a position that he would generally maintain through the 2020 Presidential election cycle.
      <Citation number={168} />
    </Typography>

    <Typography className="indent">
      During that call, Giuliani first tried “pumping [Corman] up as a patriot” before asking the Senator to call the Pennsylvania legislature into a special session. Corman told Giuliani that he did not have the authority to do that, a position with which his own lawyers agreed.
      <Citation number={169} />
      Giuliani’s reply was that Corman must have bad lawyers. Corman said he offered to connect Giuliani with his legal team. His legal team spoke with Giuliani and a lawyer working with him, Jenna Ellis, the following day, reiterating their view that such a move by the legislature would be illegal.
      <Citation number={170} />
      That same day, or possibly the next, Giuliani and Ellis called him back to renew their request for a special legislative session and to demean Corman’s attorneys, calling them “terrible,” “bad,” and “wrong.”
      <Citation number={171} />
      Corman, however, held his ground and ended the call.
      <Citation number={172} />
    </Typography>

    <Typography className="indent">
      While packing to return to Pennsylvania from his Thanksgiving visit to Florida, Corman says he received a call from an unknown number with a Washington, DC area code, which he let go to voicemail.
      <Citation number={173} />
      It turned out to be a White House operator calling on behalf of President Trump.
      <Citation number={174} />
    </Typography>

    <Typography className="indent">
      Corman called back and spoke to President Trump, who insisted that he had won the election in Pennsylvania and said something to the effect of, “Jake, this is a big issue. We need your help.”
      <Citation number={175} />
      Corman told the President that he couldn’t do what the Trump team was asking; President Trump replied, “I’m not sure your attorneys are very good.”
      <Citation number={176} />
      Corman wanted to end the call and offered to have his lawyers speak again with President Trump’s, but they never had another call with the President’s lawyers.
      <Citation number={177} />
    </Typography>

    <Typography className="indent">
      Pennsylvania House Speaker Bryan Cutler was another main target for the President’s team. He received voicemails in late November for four days in a row from Giuliani and/or Jenna Ellis, which he provided to the Select Committee.
      <Citation number={178} />
      Cutler explained that he did not feel comfortable talking with the President’s team in case he ended up having to preside over a legislative session about the election, and he had his attorneys relay that to the President’s team.
      <Citation number={179} />
      Giuliani received the message but continued to call Speaker Cutler nonetheless.
      <Citation number={180} />
    </Typography>

    <Typography className="indent">
      In the first of these voicemails, on November 26th, Giuliani asked to “get together, quietly” to discuss “the amount of fraud that went on in your State,” and said that Giuliani and Ellis had also just spoken to Pennsylvania House Majority Leader Kerry Benninghoff.
      <Citation number={181} />
      On November 27th, Ellis called and said in a second voicemail that they had just talked to Pennsylvania House Member Russ Diamond and were “very grateful” to the State’s legislature “for doing your Article II duty.”
      <Citation number={182} />
      On November 28th, Giuliani left a third voicemail and claimed to have “something important” that “really changes things,” and said that “the president wanted me to make sure I got it to you.”
      <Citation number={183} />
      And then on November 29th, Giuliani left a fourth message and said, “I understand that you don’t want to talk to me now” but still sought “the courtesy of being able to talk to you as the president’s lawyer” and a “fellow Republican” because “you’re certifying what is a blatantly false statement... I can’t imagine how that’s in your interests or in the interests of our party.”
      <Citation number={184} />
    </Typography>

    <Typography className="indent">
      Giuliani and Ellis didn’t get through, but the President did. “[I]f we wanted to do something, what were the options[?]” the President asked Cutler.
      <Citation number={185} />
      Cutler explained to President Trump that he could file a legal challenge contesting the election, and asked the President why his team had never requested a statewide recount.
      <Citation number={186} />
      Cutler was also clear about the “constitutional peculiarities” of Pennsylvania, where the State constitution specifically prohibits retroactive changes to how electors are chosen.
      <Citation number={187} />
    </Typography>

    <Typography className="indent">
      Practically, President Trump’s call achieved nothing. The President wasn’t getting what he wanted in his calls to leaders in Pennsylvania: a special session of the legislature to appoint Trump/Pence electors.
    </Typography>

    <Typography className="indent">
      Seemingly undeterred, President Trump invited several leaders of the Pennsylvania legislature to the White House for Christmas gatherings.
      <Citation number={188} />
      Senator Corman decided not to go, although Speaker Cutler did. President Trump spoke with Cutler on December 3rd, while Cutler, his chief of staff, and their wives were at that White House Christmas tour.
      <Citation number={189} />
      The issue of overturning the results of Pennsylvania’s election came up again, as did the possibility of a special session of the State legislature to appoint Trump electors.
      <Citation number={190} />
      Cutler told the President that the State legislature could not reconvene without an order from the Governor and a petition from a supermajority of legislators, neither of which was likely to happen.
      <Citation number={191} />
      Cutler also told the President that they could not appoint new electors without a court order. In Cutler’s opinion, President Trump “seemed to understand. And that was—that was clear.”
      <Citation number={192} />
      The President’s apparent understanding, however, did not result in any meaningful changes to his public rhetoric.
    </Typography>

    <Typography className="indent">
      On December 3rd, the same day that Cutler met with President Trump, Cutler, Corman, House Majority Leader Benninghoff, and Senate Majority Leader Kim Ward issued a three-page single-spaced joint statement asserting, in no uncertain terms, that Pennsylvania’s General Assembly “lacks the authority... to overturn the popular vote and appoint our own slate of electors,” since “[d]oing so would violate our Election Code and Constitution, particularly a provision that prohibits us from changing the rules for election contests of the President after the election.”
      <Citation number={193} />
      In response, President Trump retweeted a December 4th post by Bernard Kerik, which tagged all four of these State legislators with the hashtag “Traitors,” and declared that “These are the four cowardice [<i>sic</i>] Pennsylvania legislators that intend to allow the Democrat machine to #StealtheVote! #Cowards #Liars #Traitors.”
      <Citation number={194} />
    </Typography>

    <Typography className="indent">
      But five days later, President Trump publicly thanked Cutler for signing onto a December 4th letter that encouraged Members of Congress from Pennsylvania to object to their State’s electoral votes on January 6th. The President tweeted: “Thank you to Speaker Cutler and all others in Pennsylvania and elsewhere who fully understand what went on in the 2020 election. It’s called total corruption!”
      <Citation number={195} />
      When the Select Committee asked Cutler about this apparent change in his position, he said that he signed on to this letter not because of concerns that fraud or corruption meant the results of the election Pennsylvania were wrong, but rather because of concerns about “programmatic changes or areas for improvement” related to the election.
      <Citation number={196} />
      In fact, Cutler reiterated to the Committee that he “was not personally aware of” any widespread election fraud that would have changed the result of the election.
      <Citation number={197} />
    </Typography>

    <Typography className="indent">
      The pressure facing State legislators during this period was significant. On December 9th, the <i>New York Times</i> quoted Pennsylvania’s Senate Majority Leader Kim Ward, revealing that she too had received a call from President Trump in which he pushed his election fraud narrative.
      <Citation number={198} />
      Ward told the Times that she hadn’t been given enough time to sign the same December 4th letter that Cutler did, but commented that if she had taken a stand against it, “I’d get my house bombed tonight.”
      <Citation number={199} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        Arizona
      </Typography>
    </Box>

    <Typography className="indent">
      In late November, Arizona House Speaker Russell “Rusty” Bowers, a longtime Republican who served 17 years in the State legislature, received a call from President Trump and Giuliani.
      <Citation number={200} />
      Giuliani alleged that Arizona’s election results were skewed by illicit ballots, cast by non-citizens or on behalf of dead people.
      <Citation number={201} />
      Bowers demanded proof for these audacious claims on the call and President Trump told Giuliani to comply, but the evidence never came.
      <Citation number={202} />
      The point of the call, however, was different. Like in Michigan and Pennsylvania, President Trump and his allies were working the phones to get something. They wanted Bowers to hold a public hearing with the ultimate aim of replacing Presidential electors for former Vice President Joe Biden with electors for President Trump.
      <Citation number={203} />
    </Typography>

    <Image
      src="/images/image-040.jpg"
      alt="unknown, testifying"
      credit="(Photo by House Creative Services)"
    />

    <Typography className="indent">
      Bowers had never heard of anything like that before, and Giuliani acknowledged that it had never been done. Where President Trump and Giuliani saw a potential opportunity, however, Bowers saw a fundamental problem.
    </Typography>

    <Typography className="indent">
      As Bowers explained it, what they wanted him to do was “counter to my oath when I swore to the Constitution to uphold it.”
      <Citation number={204} />
      And he said that to the President and Giuliani: “you are asking me to do something against my oath, and I will not break my oath.”
      <Citation number={205} />
      Giuliani replied: “aren’t we all Republicans here? I mean, I would think you would listen a little more open to my suggestions, that we’re all Republicans.”
      <Citation number={206} />
    </Typography>

    <Typography className="indent">
      The pressure didn’t stop with that call. On December 1st, Giuliani and Ellis got an audience with some of the most powerful Republican lawmakers in Arizona, including Bowers, Senate President Karen Fann, Senate President Pro Tempore Vince Leach, House Majority Leader and SenatorElect Warren Petersen, Senate Majority Whip Sonny Borrelli, Senator Michelle Ugenti-Rita, and others.
      <Citation number={207} />
      The Select Committee was unable to get Giuliani and Ellis’ perspective on this outreach because Giuliani claimed that his communications with Bowers—who was not his client nor part of his legal team—were “privileged,” while Ellis invoked her Fifth Amendment rights against self-incrimination.
      <Citation number={208} />
    </Typography>

    <Typography className="indent">
      Bowers, on the other hand, told the Select Committee that Giuliani and Ellis asked the lawmakers to deliver Arizona’s electors for President Trump, despite the certified popular vote count.
      <Citation number={209} />
      To bolster their request, Giuliani and Ellis raised numerous allegations of election fraud at the meeting, though they never produced evidence in support of their claims. In live testimony before the Committee, Bowers recalled Giuliani saying in this meeting that “we’ve got lots of theories, we just don’t have the evidence.”
      <Citation number={210} />
      At the time, Bowers didn’t know whether it was a gaffe or an example of Giuliani not thinking through what he had just said.
      <Citation number={211} />
      In any event, Bowers said he and others in his group made particular note of that comment.
      <Citation number={212} />
      And it was borne out; Bowers testified that “No one provided me, ever, such evidence.”
      <Citation number={213} />
    </Typography>

    <Typography className="indent">
      In late December, in another phone call with President Trump, Bowers reiterated that he would not do anything illegal for him.
      <Citation number={214} />
      Afterward, John Eastman joined the chorus of Trump allies attempting to change his mind. In a call on January 4th that included the Speaker’s chief counsel as well as Arizona House Majority Leader-Elect Ben Toma, Eastman urged Bowers to hold a vote to decertify Arizona’s Presidential electors.
      <Citation number={215} />
      When Bowers told Eastman he couldn’t unilaterally reconvene the legislature, Eastman urged him to “just do it and let the court sort it out.”
      <Citation number={216} />
      Bowers refused and the Arizona legislature took no such action.
    </Typography>

    <Typography className="indent">
      Many of President Trump’s efforts in Arizona focused on State officials, but his team also continued to reach out to the Board of Supervisors for Maricopa County even after it certified the election. One focus was voting machines. According to the <i>Arizona Republic</i>, Giuliani left a voicemail in midto late-December for Board Member Steve Chucri that “I see we’re gonna get a chance to take a good look at those machines... give me a call as soon as you get a chance. The president also wanted me to pass on a few things to you, too.”
      <Citation number={217} />
      On December 4th, Giuliani also left a message for the Board’s Chairman Clint Hickman: “I was very happy to see that there’s gonna be a forensic audit of the machines. And I really wanted to talk to you about it a bit. The President wanted me to give you a call. All right? Thank you. Give me a call back.”
      <Citation number={218} />
      Hickman chose not to call back.
      <Citation number={219} />
    </Typography>

    <Typography className="indent">
      Then, on Christmas Eve, Giuliani left voicemails for Board Members Bill Gates and Jack Sellers, asking them to call him back. In his message for Gates, Giuliani said:
    </Typography>

    <Blockquote>
      <Typography>
        It’s Giuliani, President Trump’s lawyer. If you get a chance, would you please give me a call? I have a few things I’d like to talk over with you. Maybe we can get this thing fixed up. You know, I really think it’s a shame that Republicans sort of are both in this, kind of, situation. And I think there may be a nice way to resolve this for everybody.
        <Citation number={220} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      In his message for Sellers, Giuliani said “I’d like to see if there is a way that we can resolve this so that it comes out well for everyone. We’re all Republicans, I think we all have the same goal. Let’s see if... we can get this done outside of the court.”
      <Citation number={221} />
      Like Hickman, neither Gates nor Sellers returned Giuliani’s calls.
      <Citation number={222} />
    </Typography>

    <Typography className="indent">
      So President Trump made the call himself. On December 31st, Board Chair Clint Hickman received a voicemail from the White House switchboard, asking him to call back for President Trump. Hickman said that he did not return the call, in part because the county was still facing litigation over the election.
      <Citation number={223} />
      Another call from the White House came through on January 3rd with a request that Hickman call back for the President. But, by then, the President’s call with Georgia Secretary of State Brad Raffensperger, described below, had leaked, and Hickman “didn’t want to walk into that space.”
      <Citation number={224} />
    </Typography>

    <Box sx={theme => ({ paddingBottom: `${theme.spacing(2)}` })}>
      <Typography variant="h4">
        Georgia
      </Typography>
    </Box>

    <Typography className="indent">
      On December 5th, President Trump traveled to Georgia to headline a rally and mobilize voters in advance of a January Senate runoff. But the President’s day started with a morning call to Governor Brian Kemp during which they discussed reconvening the legislature in a special session.
      <Citation number={225} />
      After the call, Kemp took to Twitter. He acknowledged that he had spoken to the President and that he told the President that he supported the idea of, and had already called for, a signature audit in Georgia.
      <Citation number={226} />
      President Trump responded later that night by complaining that Georgia had not yet done a signature-verification audit and instead insisted that the Governor should “[a]t least immediately ask for a Special Session of the Legislature.”
      <Citation number={227} />
      The following day, Governor Kemp and Lieutenant Governor Geoff Duncan issued a definitive statement rejecting President Trump and his allies’ calls to overturn the results in Georgia:
    </Typography>

    <Blockquote>
      <Typography>
        While we understand four members of the Georgia Senate are requesting the convening of a special session of the General Assembly, doing this in order to select a separate slate of presidential electors is not an option that is allowed under state or federal law.
      </Typography>
      <Typography>
        State law is clear: the legislature could only direct an alternative method for choosing presidential electors if the election was not able to be held on the date set by federal law. In the 1960s, the General Assembly decided that Georgia’s presidential electors will be determined by the winner of the State’s popular vote. Any attempt by the legislature to retroactively change that process for the November 3rd election would be unconstitutional and immediately enjoined by the courts, resulting in a long legal dispute and no short-term resolution.
        <Citation number={228} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      President Trump responded by directing his ire at Georgia officials and, throughout the month of December, President Trump grew even more relentless in his social media attacks against Kemp than he had been the previous month. He retweeted attorney Lin Wood calling on Georgians to call and urge the FBI to focus more on election fraud and “[t]ell them to also investigate @BrianKempGA @GeoffDuncanGA & @GaSecofState.”
      <Citation number={229} />
      And he retweeted another post by Lin Wood that depicted Governor Kemp and Secretary Raffensperger wearing masks digitally altered to show the Chinese flag, and warned that they “will soon be going to jail.”
      <Citation number={230} />
      Even without his many retweets, President Trump posted an average of about one tweet per day in December 2020 either criticizing Governor Kemp or pressuring him explicitly or implicitly to take actions to help overturn the election.
      <Citation number={231} />
    </Typography>

    <Typography className="indent">
      President Trump seemed consumed with his plans to overturn the election and, based on documents obtained by the Select Committee, it appears that the President received input from many outside donors or advisors who had access to his staff’s email addresses. On December 7th, a Trump donor named Bill White emailed senior Trump advisors, including Dan Scavino and Rudolph Giuliani, to say that he “[j]ust spoke to [Georgia State] Senator [William Burton] Jones [who] asked if Potus can R[e]T[weet] this now pls,” along with a tweet by Senator Jones that read: “Georgia Patriot Call to Action... call your state Senate & House Reps & ask them to sign the petition for a special session.”
      <Citation number={232} />
      President Trump and Giuliani each retweeted Senator Jones’s tweet an hour later.
      <Citation number={233} />
    </Typography>

    <Typography className="indent">
      Bill White also emailed Molly Michael, Dan Scavino, and Giuliani, on December 8th with information that he said “POTUS asked me last night” to send right away.
      <Citation number={234} />
      He recommended a Presidential tweet criticizing Georgia’s Lt. Gov. Duncan as well as tweets to put pressure on Senate Majority Leader Mike Dugan and Senate President Pro Tempore Butch Miller.
      <Citation number={235} />
      He wrote that President Trump would be calling Dugan and Miller “to ask them to call special session and strategize with them why they are keeping this from happening.”
      <Citation number={236} />
      Dugan later confirmed that he had received a call from President Trump’s office but that the two of them were not able to connect.
      <Citation number={237} />
      And the following day, Steve Bannon revealed on his podcast that President Trump spoke to Georgia House Speaker Ralston and Speaker Pro Tempore Jan Jones.
      <Citation number={238} />
      Speaker Ralston confirmed that he spoke to President Trump on December 7th about the election, during which he told the President that Georgia law made a special legislative session “very much an uphill battle.”
      <Citation number={239} />
    </Typography>
  </Page>
)
