import { lazy } from 'react';
import Typography from '@mui/material/Typography';

import { Blockquote } from '../../../components/Blockquote';
import { Page } from '../../../components/Page';
import { StyledListContainer } from '../../../components/StyledListContainer';

const Citation = lazy(() => import('../../Citations/CitationSheet4'));

export const Chapter26 = ({ ...args }: any) => (
  <Page {...args}>
    <Typography className="indent">
      The efforts to overturn the election through State legislatures continued throughout the final two weeks before the joint session of Congress on January 6th. Based on actual events and documents obtained by the Select Committee, President Trump’s Campaign team, outside advisors, and motivated volunteers generally acted in accord with what was written down in a “Strategic Communications Plan” when engaging with, and sometimes demonizing, State officials. Activities that occurred thereafter were in accord with the plan.
    </Typography>

    <Typography className="indent">
      The “Plan” was explained in a document that was presented to the White House.
      <Citation number={277} />
      The plan contemplated pressuring Republican legislators both in Congress and in six key swing States. The document itself purports to be the product of the “GIULIANI PRESIDENTIAL LEGAL DEFENSE TEAM” and declared that “We Have 10 Days to Execute This Plan & Certify President Trump!”
      <Citation number={278} />
    </Typography>

    <Typography className="indent">
      Kerik told the Select Committee that pieces of the plan had been in place for some period of time before the document was actually created, and that he thought that the “catalyst” for actually memorializing the plan was the approaching deadline of January 6th.
      <Citation number={279} />
      In fact, the 10-day plan to help “certify president Trump” had been the subject of “continual discussions” for “6 weeks” and was “being discussed every day at some point prior to the 10 days that we’re talking about. So it was a continuous thing that went on.”
      <Citation number={280} />
    </Typography>

    <Typography className="indent">
      Ultimately, the Giuliani team shared the Strategic Communications Plan and urged its implementation. Kerik sent the plan to Mark Meadows via email on December 28th with this note, in part:
    </Typography>

    <Blockquote>
      <Typography>
        There is only one thing that’s going to move the needle and force the legislators to do what their [<i>sic</i>] constitutionally obligated to do, and that is apply pressure.... We can do all the investigations we want later, but if the president plans on winning, it’s the legislators that have to be moved, and this will do just that. We’re just running out of time.
        <Citation number={281} />
      </Typography>
    </Blockquote>

    <Typography className="indent">
      Neither Giuliani nor Kerik told the Select Committee that they recalled officially implementing the plan, and Giuliani said that he thought Meadows even rejected it, but there is no doubt that President Trump’s team took certain actions consistent with it.
      <Citation number={282} />
    </Typography>

    <Typography className="indent">
      The document described its goal as a “[n]ationwide communications outreach campaign to educate the public on the fraud numbers, and inspire citizens to call upon legislators and Members of Congress to disregard the fraudulent vote count and certify the duly-elected President Trump.”
      <Citation number={283} />
      The “FOCUS of CAMPAIGN” was “SWING STATE REPUBLICAN SENATORS” in Arizona, Georgia, Michigan, Nevada, Pennsylvania, and Wisconsin, “REPULBICAN [<i>sic</i>] MEMBERS OF THE HOUSE” and “REPUBLICAN MEMBERS OF THE SENATE.”
      <Citation number={284} />
      Among the steps that it recommended were “RALLIES AND PROTESTS” in six key swing States, including protests at “Governor’s Mansions,” “Lt. Governor’s home[s],” “Secretary of State’s homes,” and “weak Members’ homes.”
      <Citation number={285} />
    </Typography>

    <Typography className="indent">
      Although the plan did not mention specific individuals by name, an apparently related document produced to the Select Committee by Giuliani did, naming State legislative leaders as “TARGETS” under a header of “KEY TARGET STATE POINTS,” including Arizona House Speaker Rusty Bowers, Arizona Senate President Karen Fann (incorrectly described as the State Senate’s majority leader), Georgia House Speaker David Ralston, Georgia Senate Majority Leader Mike Dugan, Georgia Senate President Pro Tempore Butch Miller as a possible back up, Michigan House Speaker Lee Chatfield, Michigan Senate Majority leader Mike Shirkey, Pennsylvania House Speaker Brian Cutler, Pennsylvania House Majority Leader Kerry Benninghoff, Pennsylvania Senate President Pro Tempore Jake Corman, Pennsylvania Senate Majority Leader Kim Ward, Wisconsin State Assembly Speaker Robin Vos, and Wisconsin Senate Majority Leader Scott Fitzgerald.
      <Citation number={286} />
    </Typography>

    <Typography className="indent">
      Consistent with these proposals, Giuliani appeared as a guest on Steve Bannon’s podcast on New Year’s Eve and told him that “we have a weak element to our party... a cowardly element”
      <Citation number={287} />
      and, “[n]ow I think every Republican knows—maybe this is worse—this election was stolen. Now the question is: can they live up to their oath of office? ...We gotta start working on the leadership.”
      <Citation number={288} />
      Giuliani also described President Trump’s objective in this effort: “For the president, the way forward is really it’s in the
    </Typography>

    <Typography className="indent">
      hands of the leaders of those legislatures and the Members of Congress, and what our people can do is let them know what they think, and that they’re not gonna get away with pushing this aside. That the consequences of turning your back on a massive voter fraud are gonna be dire for them, and historically these people are gonna become enemies of the country.”
      <Citation number={289} />
    </Typography>

    <Typography className="indent">
      A key component of this plan was to call out Republican officials who rejected President Trump and his team’s efforts or claims of fraud. Kerik and numerous other members of the Campaign’s legal team did just that. On December 27th, Kerik suggested that Senator Pat Toomey (R-PA) was “corrupt” and said that “for any Pennsylvania official to certify their vote, it’s malfeasance and criminal.”
      <Citation number={290} />
      That was entirely consistent with Kerik’s past tweets about the election, one of which apparently called public officials “who betrayed” President Trump “spineless disloyal maggots.”
      <Citation number={291} />
      It wasn’t just rhetoric, however, because, as described below, people showed up outside certain officials’ home—sometimes menacingly—and, of course, showed up at the Capitol on January 6th.
    </Typography>

    <Typography className="indent">
      The pressure in those final days did not stop with the types of activities outlined in the Strategic Communications Plan. January 2, 2021, was a busy day for a Saturday at the Trump White House. That was the day President Trump called on Georgia Secretary of State Raffensperger to find enough votes for victory in Georgia and participated in a call with Lindsay Graham and Members of the Freedom Caucus to plan for the joint session on January 6th.
      <Citation number={292} />
    </Typography>

    <Typography className="indent">
      It was also the day that the President joined in a virtual briefing for nearly 300 Republican legislators from swing States.
      <Citation number={293} />
      The event was hosted by a short-lived organization called “Got Freedom?” that listed Jenna Ellis among its leadership team,
      <Citation number={294} />
      and included Giuliani, John Eastman, and Peter Navarro as the program’s “featured speakers.”
      <Citation number={295} />
      A press release by Got Freedom? said that the meeting was hosted by Phillip Kline, a former attorney general of Kansas, who was disbarred in 2013.
      <Citation number={296} />
      It indicated that purported proof of voter fraud “should serve as an important resource for state legislators as they make calls for state legislatures to meet to investigate the election and consider decertifying their state election results.”
      <Citation number={297} />
    </Typography>

    <Typography className="indent">
      According to the <i>Washington Examiner</i>, when President Trump joined the call he told the participants: “You know that we won the election, and you were also given false numbers to certify.” It quoted him saying “[y]ou are the real power” because “[y]ou’re more important than the courts. You’re more important than anything because the courts keep referring to you, and you’re the ones that are going to make the decision.” When asked about that quote, specifically, Giuliani, who was on the call, said he didn’t recall the exact words that the President used but told the Select Committee “that would be the sum or substance of what he had been saying and what he believed.”
      <Citation number={298} />
      During the call, the President reportedly “referenced the planned protests in Washington” just days later on January 6th, and told the group “I don’t think the country is going to take it.”
      <Citation number={299} />
    </Typography>

    <Typography className="indent">
      When reporting on the call, the <i>Washington Examiner</i> also provided details about what Giuliani told the assembled State legislators. Consistent with his team’s “Strategic Communications Plan,” Giuliani said, “[w]e need you to put excessive pressure on your leadership where the real weakness and cowardice is mostly located,” and the report quoted Navarro telling them that “Your job, I believe, is to take action, action, action.”
      <Citation number={300} />
      That evening, Navarro stated on <i>Fox News</i> that “these legislators—they are hot, they’re angry, they want action,” and “we explained exactly how the Democrat Party as a matter of strategy stole this election from Donald J. Trump.”
      <Citation number={301} />
    </Typography>

    <Typography className="indent">
      Organizers from Got Freedom? sent a follow-up email that evening to participants on behalf of Phill Kline, in which they described the event as “an important briefing for legislators who hold the power to decertify the results of their state elections.”
      <Citation number={302} />
      It emphasized the following:
    </Typography>

    <Blockquote>
      <Typography>
        As elected officials in the House and Senate of your respective States, Professor Eastman laid out the Constitutional imperatives for you:
      </Typography>
      <StyledListContainer blockquote={true}>
        <ul>
          <li>
            <Typography>
              Assert your plenary power
            </Typography>
          </li>
          <li>
            <Typography>
              Demand that your laws be followed as written
            </Typography>
          </li>
          <li>
            <Typography>
              Decertify tainted results unless and until your laws are followed
            </Typography>
          </li>
          <li>
            <Typography>
              Insist on enough time to properly meet, investigate, and properly certify results to ensure that all lawful votes (but only lawful votes) are counted.
              <Citation number={303} />
            </Typography>
          </li>
        </ul>
      </StyledListContainer>
    </Blockquote>

    <Typography className="indent">
      The email also recommended that they “...sign on to a joint letter from state legislators to Vice President Mike Pence to demand that he call for a 12-day delay on ratifying the election...” on January 6th.
      <Citation number={304} />
      The letter ultimately garnered more than 100 signatures by State legislators from Arizona, Georgia, Michigan, Pennsylvania, and Wisconsin.
      <Citation number={305} />
      Doug Mastriano forwarded a copy of the letter via email to President Trump’s executive assistant, and the National Archives produced to the Select Committee a printed version with a stamp at the top indicating, “THE PRESIDENT HAS SEEN.”
      <Citation number={306} />
    </Typography>

    <Typography className="indent">
      But this plan would fail to sway its intended audience. As discussed in Chapter 5, the Vice President rejected this and numerous other attempts to convince him to act unlawfully on January 6th. The election had been decided and certified by the States. It was the Vice President and Congress’s job to open and count the legitimate electoral college votes.
    </Typography>

    <Typography className="indent">
      And in the early morning hours of January 7th, after a day unlike any seen in American history, when a mob of angry insurrectionists attempted to violently upend a Presidential election, the Vice President and Members of Congress, shaken but steady, delayed but resolute, regrouped and reconvened and did their Constitutional duty to certify Joseph R. Biden as the next President of the United States.
    </Typography>

    <Typography className="indent">
      President Trump’s plot to pressure State legislators to overturn the vote of the electoral college failed—but only barely. Even so, the consequences of President Trump’s efforts to overturn State election results were significant.
    </Typography>
  </Page>
)
